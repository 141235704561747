import classNames from 'classnames';
import Empty from 'components/common/Empty';
import ExpandablePanel from 'components/common/ExpandablePanel';
import PropTypes from 'prop-types';
import RadioGroup from 'components/common/fields/radio_group';
import Search from '@material-ui/icons/Search';
import SearchBy from 'components/common/SearchBy';
import SearchFieldType from 'types/search_by';
import Table from 'components/common/table';
import TableProps from 'types/table';
import { all, defaultTo, isEmpty, isNil, propSatisfies, prepend } from 'ramda';
import { selector as recentPayersSelector } from 'selectors/recentPayer';
import { withViewService } from 'components/common/permissions';
import {
  compose,
  lifecycle,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import selector from 'selectors/payerSearch';
import { Grid, withStyles } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { decryptPayerSSN } from 'sdk/payer';
import { getAuth } from 'util/index';
import { load, LOAD } from 'model/payer';
import { update as updatePageSetting } from 'model/pageSetting';
import { pageConnect, pageHandlers } from 'util/page';
import { selectedFspIdSelector } from 'selectors/fsp';
import { withRouter, useHistory } from 'react-router-dom';
import { initialize } from 'components/common/searchByUtils';
import {
  businessColumnMeta,
  defaultPayerType,
  payerTypeMeta,
  personColumnMeta,
} from './config/payers';
import PayersWelcomeMessage from './PayersWelcomeMessage';
import { formatPayerTypeSearchBy, getSearchFields } from './consts';
import { getLocationFromPayer } from '../paths';

const styles = (theme) => ({
  empty: {
    position: 'relative',
    minHeight: 290,
  },
  hide: {
    display: 'none',
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(8),
  },
  text: {
    textAlign: 'center',
  },
  welcome: {
    width: '55%',
    margin: '12vh auto',
    textAlign: 'center',
  },
});

const Payers = ({
  classes,
  columnMeta,
  handleChange,
  handleSearchButtonPressed,
  handleTogglePayerType,
  isLoading,
  landing,
  order,
  orderBy,
  page,
  payerType,
  payers,
  rowsPerPage,
  rowsPerPageOptions,
  searchFields,
  settingType,
}) => {
  const history = useHistory();
  const recentPayers = useSelector((state) => recentPayersSelector(state));

  const handleRowClick = (payer) => {
    const recentPayer = recentPayers.find(
      ({ payerId }) => payerId === payer.id
    );
    history.push(recentPayer?.location || getLocationFromPayer(payer));
  };

  return (
    <ExpandablePanel
      expandable={false}
      fullHeight
      heightOffset={53}
      icon={Search}
      title="Payer Search"
    >
      <div>
        <Grid container justifyContent="center">
          <Grid item>
            <RadioGroup
              classes={{ root: classes.radioGroup }}
              label="Payer Type"
              onChange={handleTogglePayerType}
              radios={payerTypeMeta}
              value={payerType}
            />
          </Grid>
          <Grid item>
            <SearchBy
              expanded
              fields={searchFields}
              onSearch={handleSearchButtonPressed}
              priorityIndex={4}
              settingType={settingType}
            />
          </Grid>
        </Grid>

        <PayersWelcomeMessage in={landing} />

        {!landing && (
          <Table
            columnMeta={columnMeta}
            data={payers}
            idMeta="id"
            isLoading={isLoading}
            onChange={handleChange}
            onRowClick={handleRowClick}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}

        <div
          className={classNames(classes.empty, {
            [classes.hide]: isLoading || landing || !isEmpty(payers),
          })}
        >
          <Empty
            subheading="Try modifying the current search parameters"
            title="No Payers Found"
          />
        </div>
      </div>
    </ExpandablePanel>
  );
};

Payers.propTypes = {
  classes: PropTypes.object.isRequired,
  columnMeta: PropTypes.array.isRequired,
  handleSearchButtonPressed: PropTypes.func.isRequired,
  handleTogglePayerType: PropTypes.func.isRequired,
  landing: PropTypes.bool.isRequired,
  payerType: PropTypes.string.isRequired,
  payers: PropTypes.array.isRequired,
  settingType: PropTypes.string.isRequired,
  searchFields: PropTypes.arrayOf(SearchFieldType).isRequired,
  ...TableProps,
};

export default compose(
  withStyles(styles),
  setDisplayName('Payers'),
  connect(
    (state) => ({
      auth: getAuth(state),
      payers: selector(state),
      fspId: selectedFspIdSelector(state),
    }),
    {
      load,
      clearAction: () => ({
        type: LOAD.SUCCESS,
        data: [],
      }),
      updatePageSetting,
    }
  ),
  withState('landing', 'setLanding', ({ payers }) => payers.length === 0),
  withState('loadParams', 'setLoadParams', ({ fspId }) => ({ fspId })),
  withProps(({ load }) => ({
    actionType: LOAD.ACTION,
    loadAction: load,
    settingType: 'payer',
  })),
  pageConnect,
  withProps(({ payerType }) => ({
    payerType: defaultTo(defaultPayerType, payerType),
  })),
  withProps(({ payerType }) => ({
    searchFields: getSearchFields(payerType),
  })),
  pageHandlers,
  withHandlers({
    handleTogglePayerType:
      ({ pageSettings, settingType, updatePageSetting }) =>
      (payerType) => {
        const searchFields = getSearchFields(payerType);
        const { updatedValue } = initialize(4, searchFields, []);
        const searchBys = prepend(
          formatPayerTypeSearchBy(payerType),
          updatedValue
        );

        updatePageSetting({
          ...pageSettings,
          page: 0,
          payerId: 0,
          payerType,
          searchBys,
          type: settingType,
        });
      },
    handleSearchButtonPressed:
      ({ handleSearch, payerType, setLanding }) =>
      (args) => {
        const empty = all(
          propSatisfies((val) => isNil(val) || isEmpty(val), 'value'),
          args
        );

        setLanding(empty);

        if (!empty) {
          const searchBys = [...args];

          if (!searchBys.some(({ field }) => field === 'type')) {
            searchBys.push(formatPayerTypeSearchBy(payerType));
          }
          handleSearch(searchBys);
        }
      },
  }),
  withRouter,
  withProps(({ auth, fspId, payerType }) => ({
    columnMeta:
      payerType === 'person'
        ? personColumnMeta(fspId, decryptPayerSSN, auth)
        : businessColumnMeta(fspId, decryptPayerSSN, auth),
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { fspId } = this.props;

      if (prevProps.fspId !== fspId) {
        const { handleSearch, loadParams, searchBys, setLoadParams } =
          this.props;

        setLoadParams({ ...loadParams, fspId });
        setTimeout(() => {
          handleSearch(searchBys);
        });
      }
    },
  }),
  withViewService
)(Payers);
