import { isLoadingState } from 'reducers/loading';
import { LOAD_USER_FSP_CONFIG } from 'model/userFspConfig';
import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  makeUserFspConfigSelector,
  makeEBillEnabledSelector,
  makeSupportedTypesSelector,
  selectUserFspConfigByFspIdPayerId,
} from 'selectors/userFspConfig';

export const useUserFspConfigLoaded = ({ fspId, payerId }) => {
  const [loadingStarted, setLoadingStarted] = useState(false);
  const config = useSelector(
    (state) => selectUserFspConfigByFspIdPayerId(state, fspId, payerId),
    shallowEqual
  );
  const isLoading = useSelector((state) =>
    isLoadingState(state, LOAD_USER_FSP_CONFIG.ACTION)
  );

  useEffect(() => {
    if (!loadingStarted && isLoading) {
      setLoadingStarted(isLoading);
    }
  }, [isLoading, loadingStarted]);

  return Object.keys(config).length > 0 || (loadingStarted && !isLoading);
};

export const useUserFspConfigSelector = (fspId, payerId) => {
  const selectUserFspConfig = useMemo(makeUserFspConfigSelector, [
    fspId,
    payerId,
  ]);
  return useSelector(
    (state) => selectUserFspConfig(state, fspId, payerId),
    shallowEqual
  );
};

export const useEBillEnabledSelector = (fspId, payerId) => {
  const selectEBillEnabled = useMemo(makeEBillEnabledSelector, [
    fspId,
    payerId,
  ]);
  return useSelector((state) => selectEBillEnabled(state, fspId, payerId));
};

export const useSupportedTypesSelector = (fspId, payerId) => {
  const selectSupportedTypes = useMemo(makeSupportedTypesSelector, [
    fspId,
    payerId,
  ]);
  return useSelector((state) => selectSupportedTypes(state, fspId, payerId));
};
