import { LOAD, UPDATE } from 'model/payerUser';
import { load, update } from 'sdk/payerUser';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: () => 'Status has been successfully changed',
      type: UPDATE,
    })
  );
}
