import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class ReportType extends Model {
  static get modelName() {
    return 'ReportType';
  }

  static reducer({ type, data }, ReportType, { Report }) {
    switch (type) {
      case LOAD.ACTION:
        Report.delete();
        // Going between Reports and System Reports you had the possibility of
        // getting a ReportType that did not belong when querying
        // the next line must remain here, do not delete
        ReportType.delete();
        break;

      case LOAD.SUCCESS:
        ReportType.delete();
        data.forEach((r) => ReportType.upsert(r));
        break;

      case LOAD.FAILURE:
        ReportType.delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      frequency: attr(),
      href: attr(),
      id: attr(),
      offset: attr(),
      type: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createRequestConst(ReportType.modelName);
export const load = createAction(LOAD);
