import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(0.5),
  },
}));

const ExpansionPanelTitleEndAdornment = ({
  endAdornment,
  expanded,
  loaded,
}) => {
  const classes = useStyles();

  return endAdornment && loaded ? (
    <Grid className={classes.root} item xs>
      <Collapse in={expanded}>{endAdornment}</Collapse>
    </Grid>
  ) : null;
};

ExpansionPanelTitleEndAdornment.propTypes = {
  endAdornment: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

ExpansionPanelTitleEndAdornment.defaultProps = {
  endAdornment: null,
};

export default ExpansionPanelTitleEndAdornment;
