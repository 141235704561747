import { getMethods } from 'components/common/SelectDeliveryMethod/consts';

const getDisplayNameForMethods = (methods, deliver) => {
  return methods.find(({ value }) => value === deliver).name;
};

// eslint-disable-next-line import/prefer-default-export
export const addDerived = (r) => {
  const methods = getMethods();

  return {
    ...r,
    deliverDerived: getDisplayNameForMethods(methods, r.deliver),
  };
};
