import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst, createUpdateConst } from '../util';

export default class FspFraudConfig extends Model {
  static get modelName() {
    return 'FspFraudConfig';
  }

  static reducer({ type, data }, FspFraudConfig) {
    switch (type) {
      case LOAD.SUCCESS:
        data?.response?.forEach((r) => FspFraudConfig.upsert(r));
        break;

      case UPDATE.SUCCESS:
        FspFraudConfig.upsert(data?.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      fraudMonitor: attr(),
      fraudOffOn: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      offset: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }

  /**
   * Use fspId as key
   * */
  static options = {
    idAttribute: 'fspId',
  };
}

const name = FspFraudConfig.modelName;

export const LOAD = createRequestConst(name);
export const UPDATE = createUpdateConst(name);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
