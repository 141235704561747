import { attr, Model } from 'redux-orm';
import { omit } from 'ramda';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);

  return r;
};

export default class BillerSetAlias extends Model {
  static get modelName() {
    return 'BillerSetAlias';
  }

  static reducer({ type, data = [] }, BillerSetAlias, { Biller }) {
    switch (type) {
      case ADD.SUCCESS: {
        let biller = Biller.withId(data?.response?.billerId)?.ref;

        if (biller) {
          biller = {
            ...omit(['selected'], biller),
            billerSetId: data?.response?.billerSetId,
          };
        }
        BillerSetAlias.create(addDerived(biller || data.response));

        break;
      }

      case CHECK.ACTION: {
        const item = BillerSetAlias.withId(data.id);

        if (item) {
          item.selected = !item.selected;

          item.update(item);
        }
        break;
      }

      case CHECK_ALL.ACTION: {
        BillerSetAlias.update({ selected: data.selected });
        break;
      }

      case LOAD.SUCCESS: {
        BillerSetAlias.delete();
        (data?.response || []).forEach((r) => {
          BillerSetAlias.upsert(addDerived(r));
        });
        break;
      }

      case LOAD.FAILURE:
        BillerSetAlias.delete();
        break;

      case REMOVE.SUCCESS:
        BillerSetAlias.filter(({ selected }) => selected).delete();
        break;

      case UPDATE.SUCCESS:
        BillerSetAlias.upsert(addDerived(data?.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      alias: attr(),
      aliasType: attr(),
      billerset: attr(),
      createdAt: attr(),
      createdAtLabel: attr(),
      id: attr(),
      offset: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(),
    };
  }
}

const name = BillerSetAlias.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
