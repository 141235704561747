import { client } from 'util/sdk';
import { GET } from '../util/api';

const returnModel = { name: 'palAutopay' };

// eslint-disable-next-line import/prefer-default-export
export const loadPalAutopay = ({ fspId, payerId }) =>
  client.executeRequest(
    GET,
    `/v2/fsps/${fspId}/payers/${payerId}/palautopays`,
    {
      returnModel,
    }
  );
