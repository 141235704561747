import { createSelector } from 'redux-orm';
import { orm } from 'model';

// Because of the oneToOne relationship between Payer and PayerSearch,
// this selector returns ar array of Payers
export default createSelector(orm, ({ PayerSearch }) =>
  PayerSearch.all()
    .toModelArray()
    .map(({ id }) => id.ref)
);
