import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createRef, memo, useMemo, useState } from 'react';
import { always, dropLast, filter as rFilter, map, where } from 'ramda';
import { ASC, ROWS_PER_PAGE } from 'consts';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SCROLL_REF_KEY } from 'util/format';
import ExpansionListItem from './ExpansionListItem';
import ExpansionListSubheader from './ExpansionListSubheader';
import ExpansionListEmptyListItem from './ExpansionListItemEmpty';
import { ExpansionListItemProvider } from './ExpansionListItemContext';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    height: '100%',
    width: '100%',
  },
}));

const ExpansionList = ({
  actionConstant,
  ActionsRenderer,
  className,
  columnMeta,
  data,
  detailsRender,
  emptySubheading,
  emptyTitle,
  enableFilter,
  expandedIds,
  hover,
  idMeta,
  ListPaginationProps,
  ListRender,
  name,
  onChange,
  onListItemChange,
  order,
  orderBy,
  page,
  pageSettingsName,
  renderOptions,
  rowsPerPage,
  rowsPerPageOptions,
  style,
  usesPagination,
}) => {
  const [filter, setFilter] = useState({});
  const classes = useStyles();
  const dataPerPage = useMemo(() => {
    return data?.length > rowsPerPage
      ? dropLast(data.length - rowsPerPage, data)
      : data || [];
  }, [data, rowsPerPage]);
  const filteredData = useMemo(() => {
    return map(
      (row) => ({ [`${SCROLL_REF_KEY}`]: createRef(), ...row }),
      rFilter(where(filter), dataPerPage || [])
    );
  }, [dataPerPage, filter]);

  const total = page * rowsPerPage + data.length;

  return (
    <List
      className={classNames(classes.root, className)}
      component="div"
      disablePadding
      style={style}
    >
      <ExpansionListSubheader
        actionType={actionConstant?.ACTION ?? ''}
        columnMeta={columnMeta}
        data={dataPerPage}
        enableFilter={enableFilter}
        ListPaginationProps={ListPaginationProps}
        name={name}
        onChange={onChange}
        order={order}
        orderBy={orderBy}
        page={page}
        pageSettingsName={pageSettingsName}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        setFilter={setFilter}
        total={total}
        usesPagination={usesPagination}
      />
      {ListRender ? (
        ListRender({
          columnMeta,
          detailsRender,
          expandedIds,
          onListItemChange,
          name,
        })
      ) : (
        <>
          {filteredData.map((row) => (
            <ExpansionListItemProvider
              key={row[idMeta]}
              item={row}
              options={renderOptions}
            >
              <ExpansionListItem
                ActionsRenderer={ActionsRenderer}
                columnMeta={columnMeta}
                detailsRender={detailsRender}
                expanded={
                  Array.isArray(expandedIds) && expandedIds.includes(row.id)
                }
                hover={hover}
                idMeta={idMeta}
                onListItemChange={onListItemChange}
                renderOptions={renderOptions}
                row={row}
              />
            </ExpansionListItemProvider>
          ))}
          <ExpansionListEmptyListItem
            actionConstant={actionConstant}
            count={filteredData.length}
            subHeading={emptySubheading}
            title={emptyTitle}
          />
        </>
      )}
    </List>
  );
};

ExpansionList.propTypes = {
  actionConstant: PropTypes.object,
  ActionsRenderer: PropTypes.elementType,
  className: PropTypes.string,
  columnMeta: PropTypes.array.isRequired,
  data: PropTypes.array,
  detailsRender: PropTypes.func,
  emptySubheading: PropTypes.string,
  emptyTitle: PropTypes.string,
  enableFilter: PropTypes.bool,
  expandedIds: PropTypes.array,
  hover: PropTypes.bool,
  idMeta: PropTypes.string,
  ListPaginationProps: PropTypes.object,
  ListRender: PropTypes.elementType,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onListItemChange: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  pageSettingsName: PropTypes.string,
  renderOptions: PropTypes.object,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  style: PropTypes.object,
  usesPagination: PropTypes.bool,
};

ExpansionList.defaultProps = {
  actionConstant: {},
  ActionsRenderer: () => null,
  className: '',
  data: [],
  detailsRender: () => null,
  emptySubheading: '',
  emptyTitle: '',
  enableFilter: false,
  expandedIds: null,
  hover: false,
  idMeta: 'id',
  ListPaginationProps: null,
  ListRender: null,
  onChange: always,
  onListItemChange: always,
  order: ASC,
  orderBy: null,
  page: 0,
  pageSettingsName: '',
  renderOptions: {},
  rowsPerPage: ROWS_PER_PAGE[0],
  rowsPerPageOptions: ROWS_PER_PAGE,
  style: {},
  usesPagination: true,
};

export default memo(ExpansionList);
