export { default as EBillEnrollmentAction } from './EBillEnrollmentAction';

export const columnMeta = [
  {
    context: true,
    id: 'netUserIdStr',
    label: 'eBill User ID',
  },
  {
    id: 'ebillernetEbillerName',
    label: 'Biller Name',
  },
  {
    capitalize: true,
    id: 'status',
    label: 'Status',
  },
  {
    id: 'createdAtDerived',
    label: 'Date',
  },
];

export const defaultOrderBy = 'ebillernetEbillerName';
