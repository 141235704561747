import PropTypes from 'prop-types';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card as MuiCard,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import { always, isNil } from 'ramda';
import { isNilOrEmpty } from 'util/index';
import Param from './Param';
import { isParamValid } from '../../model';

const init = (param) => {
  return param ? { [param.field]: param.value } : null;
};

const useStyles = makeStyles((theme) => ({
  card: {
    width: 600,
  },
  grid: {
    marginTop: theme.spacing(1),
  },
}));

const Card = ({ onChange, onClose, param, searchParams, ...props }) => {
  const [disabled, setDisabled] = useState(true);
  const [updatedParam, setUpdatedParam] = useState(init(param));
  const classes = useStyles();

  const buttonLabel = `${param?.value ? 'Update' : 'Add'} & Search`;

  const handleChange = (param) => {
    setDisabled(!isParamValid(param));
    setUpdatedParam(param);
  };
  const handleKeyPress = (event) => {
    if (event?.which === 13 && !isNilOrEmpty(updatedParam?.value)) {
      onChange(updatedParam);
      onClose(event);
    }
  };
  const handleSearch = (event) => {
    onChange(updatedParam);
    onClose(event);
  };

  return (
    <MuiCard className={classes.card} elevation={0}>
      <CardContent>
        <Param
          {...props}
          disabled={!isNil(param)}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          param={param}
          searchParams={searchParams}
        />
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button onClick={onClose} size="small">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={disabled}
              onClick={handleSearch}
              size="small"
              variant="contained"
            >
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </MuiCard>
  );
};

Card.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  param: PropTypes.object,
  searchParams: PropTypes.arrayOf(PropTypes.object),
};

Card.defaultProps = {
  onClose: always,
  param: undefined,
  searchParams: null,
};

export default Card;
