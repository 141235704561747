import { ACTION, FAILURE, sep, SUCCESS } from 'util/actions';
import { put, takeEvery } from 'redux-saga/effects';
import {
  requestBegin,
  requestEnd,
  requestFailure,
  REQUEST,
} from 'reducers/request';

const getActionParts = (action) => {
  const lastUnderscorePosition = action.lastIndexOf(sep);
  let stem = '';
  let suffix = '';

  if (lastUnderscorePosition !== -1) {
    stem = action.substr(0, lastUnderscorePosition);
    suffix = action.substr(lastUnderscorePosition + 1);
  }

  return [stem, suffix];
};
const getId = (data) => {
  let id = data?.id ?? 0;

  if (id === 0) {
    const responseId = data?.props?.id;

    if (responseId) {
      id = responseId;
    }
  }

  return id;
};

function* worker({ data, error, type }) {
  const [request, status] = getActionParts(type);

  if (request !== REQUEST.ROOT) {
    const id = getId(data);
    const dataObj = {
      error,
      id,
      status,
      result: data?.response,
      request,
    };

    switch (status) {
      case ACTION:
        yield put(requestBegin({ ...dataObj, requestData: data }));
        break;

      case SUCCESS:
        yield put(requestEnd(dataObj));
        break;

      case FAILURE:
        yield put(requestFailure(dataObj));
        break;

      default:
        break;
    }
  }
}

export default function* watch() {
  yield takeEvery('*', worker);
}
