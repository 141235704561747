import MaskedInput from 'react-text-mask';
import { mapProps, toClass } from 'recompose';
import { phoneMask, phonePlaceholderChar } from '../../fields/consts';

export default toClass(
  mapProps(({ inputRef, ...props }) => ({
    ...props,
    mask: phoneMask,
    placeholderChar: phonePlaceholderChar,
    ref: (ref) => inputRef(ref ? ref.inputElement : null),
  }))(MaskedInput)
);
