import { takeEvery } from 'redux-saga/effects';
import { create, load, update } from 'sdk/researchReason';
import { CREATE, LOAD, UPDATE } from 'model/researchReason';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `${response.description} has been successfully created`,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: load, dataName: ORM, type: LOAD })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `${response.description} has been successfully updated`,
      type: UPDATE,
    })
  );
}
