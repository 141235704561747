import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { decimal } from 'util/format';
import { makeStyles } from '@material-ui/core/styles';
import LimitsTableEditCell from './LimitsTableEditCell';

const MAXIMUM = 100000000;
const MINIMUM = 0.01;

const useStyles = makeStyles((theme) => ({
  label: {
    paddingRight: theme.spacing(1),
  },
}));

const LimitsTableRow = ({ edit, payerId, row }) => {
  const [dailyMaximum, setDailyMaximum] = useState(MAXIMUM);
  const [dailyMinimum, setDailyMinimum] = useState(MINIMUM);
  const [monthlyMinimum, setMonthlyMinimum] = useState(MINIMUM);
  const [weeklyMaximum, setWeeklyMaximum] = useState(MAXIMUM);
  const [weeklyMinimum, setWeeklyMinimum] = useState(MINIMUM);
  const classes = useStyles();

  const handleChange = useCallback((value, id) => {
    switch (id) {
      case 'max':
        setDailyMinimum(decimal(value));
        break;

      case 'daily':
        setWeeklyMinimum(decimal(value));
        break;

      case 'weekly':
        setDailyMaximum(decimal(value));
        setMonthlyMinimum(decimal(value));
        break;

      case 'monthly':
        setWeeklyMaximum(decimal(value));
        break;

      default:
        break;
    }
  }, []);

  useEffect(() => {
    const { daily, max, monthly, weekly } = row;

    setDailyMaximum(weekly);
    setDailyMinimum(max);
    setWeeklyMaximum(monthly);
    setWeeklyMinimum(daily);
    setMonthlyMinimum(weekly);
  }, [row]);

  return (
    <TableRow hover>
      <TableCell classes={{ body: classes.label }}>{row.label}</TableCell>
      <LimitsTableEditCell
        edit={edit}
        id="min"
        maximum={MAXIMUM}
        minimum={MINIMUM}
        onChange={handleChange}
        payerId={payerId}
        product={row.product}
        value={row.min}
      />
      <LimitsTableEditCell
        edit={edit}
        id="max"
        maximum={MAXIMUM}
        minimum={MINIMUM}
        onChange={handleChange}
        payerId={payerId}
        product={row.product}
        value={row.max}
      />
      <LimitsTableEditCell
        edit={edit}
        id="daily"
        maximum={dailyMaximum}
        minimum={dailyMinimum}
        onChange={handleChange}
        payerId={payerId}
        product={row.product}
        value={row.daily}
      />
      <LimitsTableEditCell
        edit={edit}
        id="weekly"
        maximum={weeklyMaximum}
        minimum={weeklyMinimum}
        onChange={handleChange}
        payerId={payerId}
        product={row.product}
        value={row.weekly}
      />
      <LimitsTableEditCell
        edit={edit}
        id="monthly"
        maximum={MAXIMUM}
        minimum={monthlyMinimum}
        onChange={handleChange}
        payerId={payerId}
        product={row.product}
        value={row.monthly}
      />
    </TableRow>
  );
};

LimitsTableRow.propTypes = {
  edit: PropTypes.bool.isRequired,
  payerId: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
};

export default LimitsTableRow;
