import { createSelector } from 'redux-orm';
import { createSelector as createReselectSelector } from 'reselect';
import { orm } from 'model';
import { TRANSACTION_TYPE_BILL_PAY, ACCOUNT_TO_ACCOUNT_INBOUND } from 'consts';

export const selector = createSelector(orm.AutopayScheduled);

export const selectorPayerId = createSelector(
  orm,
  (state, payerId) => payerId,
  ({ AutopayScheduled }, payerId) => {
    const result = AutopayScheduled.filter(
      ({ payerId: autopayPayerId }) => autopayPayerId === payerId
    );

    return result?.toRefArray();
  }
);

export const selectorEBillAutopay = createReselectSelector(
  selectorPayerId,
  (scheduledTrans) =>
    (scheduledTrans || []).filter(
      ({ transactionType }) => transactionType === TRANSACTION_TYPE_BILL_PAY
    )
);

export const selectorCreditAutopay = createReselectSelector(
  selectorPayerId,
  (scheduledTrans) =>
    (scheduledTrans || []).filter(
      ({ transactionType }) => transactionType === ACCOUNT_TO_ACCOUNT_INBOUND
    )
);

export const selectorSchedId = createSelector(
  orm,
  (state, schedId) => schedId,
  ({ AutopayScheduled }, schedId) => {
    const result = AutopayScheduled.filter(
      ({ schedId: autopaySchedId }) => autopaySchedId === schedId
    ).first();

    return result?.ref || {};
  }
);
