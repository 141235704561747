import { useParams } from 'react-router-dom';

const useParamId = (canBeNan = true) => {
  const params = useParams();

  const value = Number.parseInt(params?.id, 10);

  if (canBeNan) {
    return value;
  }

  return Number.isNaN(value) ? undefined : value;
};

export default useParamId;
