import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReadOnlyInputLabel from './ReadOnlyInputLabel';
import ReadOnlyInput from './ReadOnlyInput';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 1,
    width: '100%',
  },
}));

const ReadOnlyFormControl = ({ helperText, label, value, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.root}
      disabled={value === undefined}
      t-i="readOnly"
    >
      <ReadOnlyInputLabel label={label} />
      <ReadOnlyInput label={label} value={value} {...props} />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

ReadOnlyFormControl.propTypes = {
  capitalize: PropTypes.bool,
  helperText: PropTypes.string,
  inputNode: PropTypes.node,
  isDate: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
};

ReadOnlyFormControl.defaultProps = {
  capitalize: false,
  helperText: '',
  inputNode: null,
  isDate: false,
  label: '',
  multiline: false,
  value: undefined,
};

export default ReadOnlyFormControl;
