import { client } from 'util/sdk';
import { GET } from '../util/api';

const ebillautopayModel = { name: 'ebillautopay' };

export const loadPayeeAutopay = ({ fspId, payeeId, payerId }) =>
  client.executeRequest(GET, `/fsps/${fspId}/payers/${payerId}/ebillautopays`, {
    returnModel: ebillautopayModel,
  });
export const loadAccountAutopay = ({ fspId, ddaId }) =>
  client.fsps(fspId).creditAutopays.query((_) => [_.ddaId(ddaId)]);
