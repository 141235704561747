import { params } from 'components/common/SearchTextField/Multiple/PopoverCard/Card/Param/__stories__';
import { FSP_AUDIT_LOGS_CODE, FSP_AUDIT_LOGS_URI, GET } from 'consts';
import { client } from 'util/sdk';

const returnModel = { name: FSP_AUDIT_LOGS_CODE };

export default ({ id, ...params }) =>
  client.executeRequest(GET, FSP_AUDIT_LOGS_URI, {
    queryParams: { fspId: id, ...params },
    returnModel,
  });
