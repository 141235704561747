import { FSP_DATABASE, FSP_DATABASES } from 'consts';
import { GET } from 'util/api';
import { client } from 'util/sdk';

const returnModel = { name: FSP_DATABASE };

// eslint-disable-next-line import/prefer-default-export
export const load = () => {
  return client.executeRequest(GET, `/${FSP_DATABASES}`, { returnModel });
};
