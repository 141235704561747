import {
  A2A_EXTERNAL_DDA,
  A2A_TRANSFER_FROM,
  A2A_TRANSFER_TO,
  GET_PRIV_OFF_ON,
  ALL_PRIV_OFF_ON,
  BP_PAYEE,
  BP_PAYMENT,
  OFF,
  ON,
  P2P_PAYMENT,
  P2P_RECIPIENT,
  PRIVILEGE_DERIVED,
  READ_ONLY,
  ROLE_CODE,
  WRITE,
} from 'consts';
import { isOn } from 'util/index';
import { omit } from 'ramda';

const keysToConvert = [
  A2A_EXTERNAL_DDA,
  A2A_TRANSFER_FROM,
  A2A_TRANSFER_TO,
  BP_PAYEE,
  BP_PAYMENT,
  P2P_PAYMENT,
  P2P_RECIPIENT,
];
const convertPrivObjectToString = (privObject) => {
  if (isOn(privObject?.[ALL_PRIV_OFF_ON])) {
    return WRITE;
  }

  return READ_ONLY;
};
const convertStringToPrivObject = (str, existingObj) => ({
  ...omit([PRIVILEGE_DERIVED], existingObj),
  [ALL_PRIV_OFF_ON]: str === WRITE ? ON : OFF,
  [GET_PRIV_OFF_ON]: str === READ_ONLY ? ON : OFF,
});

export const FormFields = {
  A2A_EXTERNAL_DDA,
  A2A_TRANSFER_FROM,
  A2A_TRANSFER_TO,
  BP_PAYEE,
  BP_PAYMENT,
  P2P_PAYMENT,
  P2P_RECIPIENT,
  ROLE: ROLE_CODE,
};

export const addDerived = (r) => {
  keysToConvert.forEach((key) => {
    r[key][PRIVILEGE_DERIVED] = convertPrivObjectToString(r[key]);
  });

  return r;
};

export const addOrUpdate = ({ role, ...r }, update = true) => {
  const convertedObj = { role };

  keysToConvert.forEach((key) => {
    convertedObj[key] = convertStringToPrivObject(
      r[key][PRIVILEGE_DERIVED],
      update ? r[key] : {}
    );
  });

  return convertedObj;
};
