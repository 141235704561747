import PayerContainer from 'containers/PayerContainer';
import { always } from 'ramda';
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  withProps,
  withState,
} from 'recompose';
import BaseLabel from './BaseLabel';

export default compose(
  setDisplayName('PayerLabel'),
  PayerContainer,
  withProps(({ payer }) => ({ LabelValue: payer.label })),
  withState('onChangeRaised', 'setOnChangeRaised', false),
  lifecycle({
    componentDidUpdate() {
      const { onChangeRaised, payer } = this.props;

      if (!onChangeRaised && !payer.isEmpty) {
        const { onChange = always, setOnChangeRaised } = this.props;

        setOnChangeRaised(true);

        // debounce
        setTimeout(onChange, 250);
      }
    },
  }),
  pure
)(BaseLabel);
