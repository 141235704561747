import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import usePayALoanTransaction from '../../usePayALoanTransaction';

const Amount = ({ transaction }) => {
  return (
    usePayALoanTransaction(transaction.transactionType) && (
      <Grid item xs={1}>
        <ReadOnlyFormControl label="Amount" value={transaction.amountDerived} />
      </Grid>
    )
  );
};

Amount.propTypes = {
  transaction: PropTypes.shape({
    amountDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};

export default Amount;
