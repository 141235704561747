import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { NA_VALUE } from 'consts';
import TextSubtitle from './TextSubtitle';
import TextTitle from './TextTitle';

const Text = ({ subTitle, title }) => {
  const loaded = typeof title !== 'object';

  return (
    <Grid item zeroMinWidth>
      <Grid container direction="column">
        <Grid item>
          <TextTitle loaded={loaded} title={title} />
        </Grid>
        <TextSubtitle subTitle={subTitle} />
      </Grid>
    </Grid>
  );
};

Text.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Text.defaultProps = {
  subTitle: undefined,
  title: NA_VALUE,
};

export default Text;
