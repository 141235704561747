import Loadable from 'react-loadable';
import { PATH as LOGIN_PATH } from 'components/Authentication/Login';
import { PATH as LOGOUT_PATH } from 'components/Authentication/Logout';
import { PATH as PASSWORD_PATH } from 'components/Authentication/Password';
import { PATH as PROFILE_PASSWORD_PATH } from 'components/Authentication/PasswordProfile';
import { PATH as UV_PATH } from 'components/Authentication/Validation';

const loading = () => <span />;

const Layout = Loadable({
  loader: () => import('components/Layout'),
  loading,
});
const Login = Loadable({
  loader: () => import('components/Authentication/Login'),
  loading,
});
const Logout = Loadable({
  loader: () => import('components/Authentication/Logout'),
  loading,
});
const Password = Loadable({
  loader: () => import('components/Authentication/Password'),
  loading,
});
const PasswordProfile = Loadable({
  loader: () => import('components/Authentication/PasswordProfile'),
  loading,
});
const Validation = Loadable({
  loader: () => import('components/Authentication/Validation'),
  loading,
});

export default [
  {
    key: 1,
    Component: Logout,
    path: LOGOUT_PATH,
    requiresAuth: false,
  },
  {
    key: 2,
    Component: Password,
    path: PASSWORD_PATH,
    requiresAuth: true,
  },
  {
    key: 3,
    Component: Validation,
    path: UV_PATH,
    requiresAuth: false,
  },
  {
    key: 4,
    Component: Login,
    path: LOGIN_PATH,
    requiresAuth: false,
  },
  {
    key: 5,
    Component: PasswordProfile,
    path: PROFILE_PASSWORD_PATH,
    requiresAuth: false,
  },
  /**
   * This route must go last - why? a '/' will always match...
   * "A <Switch> will iterate over all of its children <Route> elements and
   * only render the first one that matches the current location"
   */
  {
    key: 100,
    Component: Layout,
    path: '/',
    requiresAuth: true,
  },
];
