import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { update as updatePageSetting } from 'model/pageSetting';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selector as pageSettingSelector } from 'selectors/pageSetting';
import { derivedSelectorId } from 'selectors/transactionHistory';
import { isOn } from 'util/index';
import ScheduledAudit from '../../Scheduled/ScheduledAudit';
import HistoryAudit from '../HistoryAudit';

const pageSettingType = 'historyAuditButtonOptions';
const options = ['Payment Tracking', 'Scheduled Audit Details'];

const AuditButtonGroup = ({ item }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { auditButtonOptions } = useSelector(
    (state) => pageSettingSelector(state, item?.payerId, pageSettingType),
    shallowEqual
  );
  const anchorRef = useRef(null);

  const selectedOption = (auditButtonOptions || []).find(
    ({ id }) => id === item?.id
  );
  const selectedIndex = selectedOption?.value || 0;
  const converted = isOn(item?.conversionDataOffOn);

  const handleClose = () => {
    setOpen(false);
  };
  const handleMenuItemClick = (event, index) => {
    event.stopPropagation();
    setOpen(false);

    const buttonOptions = [...(auditButtonOptions || [])];
    const elementIndex = buttonOptions.findIndex(({ id }) => id === item?.id);

    const data = { id: item?.id, value: index };

    if (elementIndex > -1) {
      buttonOptions.splice(elementIndex, 1, data);
    } else {
      buttonOptions.push(data);
    }

    dispatch(
      updatePageSetting({
        payerId: item?.payerId,
        type: pageSettingType,
        auditButtonOptions: buttonOptions,
      })
    );
  };
  const handleToggle = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  return !converted ? (
    <>
      <ButtonGroup ref={anchorRef} variant="outlined">
        {selectedIndex === 0 ? (
          <HistoryAudit buttonLabel={options[0]} id={item?.id} />
        ) : (
          <ScheduledAudit
            buttonLabel={options[1]}
            item={item}
            selectorId={derivedSelectorId}
            selectorProps={{ id: item?.schedId }}
          />
        )}
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          size="small"
        >
          <ArrowDropDown fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        open={open}
        role={undefined}
        style={{ zIndex: 100 }}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      selected={index === selectedIndex}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  ) : null;
};

AuditButtonGroup.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AuditButtonGroup;
