import { ON } from 'consts';
import { client, formatQueryParams } from 'util/sdk';

export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).payees.query(formatQueryParams(params));

export const loadCount = ({ fspId, ...params }) =>
  client.fsps(fspId).payees.query(formatQueryParams(params));

export const loadById = ({ fspId, payerId, ...params }) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .payees.query(formatQueryParams({ allAttribs: ON, ...params }));

export const unmask = ({ fspId, id }, deletedOnly = undefined) =>
  client
    .fsps(fspId)
    .payees(id, deletedOnly === ON ? { deletedOnly: ON } : undefined)
    .decryptAccountNumber();

export const update = ({ fspId, payeeId, userId, ...params }, auth) =>
  client
    .fsps(fspId)
    .payees(payeeId)
    .update(params, { adminUserId: auth.id, userId });
