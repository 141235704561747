import { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT, logout } from 'reducers/authToken';
import { selector as busySelector } from 'selectors/busy';
import { getAuth } from 'util/index';
import Authentication from '../Authentication';

export const PATH = '/logout';

const Logout = () => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOGOUT));
  const { isAuthenticated } = useSelector(getAuth);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    dispatch(logout({ expired: query.has('expired') }));
  }, [dispatch, location.search]);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  return (
    <Authentication progress={isBusy}>
      {isBusy && (
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="subtitle1">Signing you out...</Typography>
          </Grid>
        </Grid>
      )}
    </Authentication>
  );
};

export default Logout;
