import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class EBillEnrollmentAudit extends Model {
  static get modelName() {
    return 'EBillEnrollmentAudit';
  }

  static reducer({ type, data }, EBillEnrollmentAudit) {
    if (type === LOAD.SUCCESS) {
      EBillEnrollmentAudit.delete();

      if (data.response) {
        data.response.forEach((r) => EBillEnrollmentAudit.upsert(r));
      }
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      byAdminUserId: attr(),
      bySystemUser: attr(),
      byUserId: attr(),
      changelog: attr(),
      id: attr(),
      logAt: attr(),
      logId: attr(),
      logOp: attr(),
      payerEbillernetEbillerId: attr(),
      status: attr(),
      time: attr(), // derived
      title: attr(), // derived
    };
  }
}

export const LOAD = createLoadConst(EBillEnrollmentAudit.modelName);

export const load = createAction(LOAD);
