import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { omit, pickBy } from 'ramda';
import { create, CREATE } from 'model/paymentResearch';
import { selector as busySelector } from 'selectors/busy';
import moment from 'moment';
import { isNilOrEmpty } from 'util/index';
import { decimal } from 'util/format';
import { handleStopPropagation } from 'consts';
import ContentBillType from './Content/ContentBillType';
import ContentReason from './Content/ContentReason';
import {
  DISPUTE_LATE_FEES,
  DUPLICATE_TRANSACTION_ERROR,
  INCORRECT_AMOUNT_POSTED,
  initialValues,
  POSTED_TO_THE_WRONG_BILLER,
  TRANSACTION_NOT_POSTED_OR_MISSING,
  USE_NEW,
  validationSchema,
} from './consts';
import {
  ContentAccountNew,
  ContentAccountNumber,
  ContentBillDueDate,
  ContentBillerAccountActive,
  ContentBillerContactEmail,
  ContentBillerContactName,
  ContentBillerContactPhone,
  ContentCorrectAccountNumber,
  ContentDescription,
  ContentMultiplePayment,
  ContentNameOnBill,
  ContentPayrailzIssue,
  ContentPostedAmount,
  ContentSsnLastFour,
} from './Content';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: 600,
    minHeight: 350,
    maxHeight: 350,
  },
  dialogContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  progress: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: 'calc(50% - 25px)',
    visibility: 'hidden',
  },
  progressVisible: {
    visibility: 'visible',
  },
}));

const CreateDialog = ({ fspId, id, onClose }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isBusy = useSelector((state) => busySelector(state, CREATE));

  const handleSubmit = ({
    billDueDate,
    billerContactPhone,
    postedAmount,
    reason,
    ...values
  }) => {
    const reasonId = reason?.id;
    const dueDate = billDueDate
      ? moment(billDueDate).format('YYYY-MM-DD')
      : null;
    const amount = postedAmount ? decimal(postedAmount) : null;
    const phone = billerContactPhone
      ? billerContactPhone.replace(/\D/g, '')
      : null;

    dispatch(
      create({
        fspId,
        transactionHistoryId: id,
        ...pickBy((value) => !isNilOrEmpty(value), {
          billDueDate: dueDate,
          billerContactPhone: phone,
          postedAmount: amount,
          reasonId,
          ...omit(['payrailzIssue'], values),
        }),
      })
    );
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  const handleConfirmClick = (submitForm) => (event) => {
    event.stopPropagation();
    submitForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ submitForm, values }) => {
        const {
          reason: { reason },
        } = values || {};

        return (
          <Dialog
            classes={{ paper: classes.dialogPaper }}
            fullWidth
            onClick={handleStopPropagation}
            open
          >
            <CircularProgress
              className={classNames(classes.progress, {
                [classes.progressVisible]: isBusy,
              })}
              size={50}
            />
            <DialogTitle>New Payment Research Ticket</DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }}>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs={12}>
                  <ContentReason />
                </Grid>
                {USE_NEW && (
                  <>
                    {reason === DISPUTE_LATE_FEES && (
                      <Grid item xs={12}>
                        <ContentPayrailzIssue />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <ContentBillerContactName />
                    </Grid>
                    <Grid item xs={6}>
                      <ContentBillerContactPhone />
                    </Grid>
                    <Grid item xs={6}>
                      <ContentBillerContactEmail />
                    </Grid>
                    <Grid item xs={6}>
                      <ContentBillType />
                    </Grid>
                    <Grid item xs={6}>
                      <ContentBillDueDate />
                    </Grid>
                    <Grid item xs={6}>
                      <ContentSsnLastFour />
                    </Grid>
                    <Grid item xs={6}>
                      <ContentNameOnBill />
                    </Grid>
                    {reason === DUPLICATE_TRANSACTION_ERROR && (
                      <Grid item xs={12}>
                        <ContentMultiplePayment />
                      </Grid>
                    )}
                    {reason === INCORRECT_AMOUNT_POSTED && (
                      <Grid item xs={6}>
                        <ContentPostedAmount />
                      </Grid>
                    )}
                    {[
                      DISPUTE_LATE_FEES,
                      TRANSACTION_NOT_POSTED_OR_MISSING,
                    ].includes(reason) && (
                      <>
                        <Grid item xs={12}>
                          <ContentCorrectAccountNumber />
                        </Grid>
                        <Grid item xs={12}>
                          <ContentAccountNew />
                        </Grid>
                        <Grid item xs={6}>
                          <ContentAccountNumber />
                        </Grid>
                      </>
                    )}
                    {reason === POSTED_TO_THE_WRONG_BILLER && (
                      <Grid item xs={12}>
                        <ContentBillerAccountActive />
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <ContentDescription />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelClick}>Cancel</Button>
              <Button color="primary" onClick={handleConfirmClick(submitForm)}>
                Create
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

CreateDialog.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateDialog;
