import { LOAN_STATEMENTS } from 'consts';
import { client } from 'util/sdk';
import { GET } from '../util/api';

const returnModel = { name: LOAN_STATEMENTS };
// eslint-disable-next-line import/prefer-default-export
export const load = ({ fspId, payerId }, auth) =>
  client.executeRequest(GET, `v2/fsps/${fspId}/payers/${payerId}/statements`, {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
