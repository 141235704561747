import { client } from 'util/sdk';
import { omit } from 'ramda';

const PROPS_TO_OMIT = ['name', 'roles', 'updatedAt', 'userStatus'];

export const load = (id) => client.admin.users(id).get();

export const update = ({ id, ...props }, auth) =>
  client.admin
    .users(id)
    .update(omit(PROPS_TO_OMIT, props), { adminUserId: auth.id });
