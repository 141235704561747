import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TableCell, withStyles } from '@material-ui/core';
import { date, formatCurrency } from 'util/index';

const NORMAL = 'normal';
const NONE = 'none';

const styles = () => ({
  capitalize: {
    textTransform: 'capitalize',
  },
});

const TableColumn = ({
  capitalize,
  classes,
  disablePadding,
  id,
  isCurrency,
  isDate,
  numeric,
  renderer,
  row,
}) => {
  const align = numeric ? 'right' : 'left';
  const dataToRender = row[id] ? row[id] : renderer ? row : '';
  const padding = disablePadding ? NONE : NORMAL;
  const data = renderer
    ? renderer(dataToRender, row.id, row)
    : isCurrency || (isDate && dataToRender)
    ? isDate
      ? date(dataToRender)
      : formatCurrency(dataToRender)
    : dataToRender;

  return (
    <TableCell
      align={align}
      className={classNames({
        [classes.capitalize]: capitalize,
      })}
      padding={padding}
    >
      {data}
    </TableCell>
  );
};

TableColumn.propTypes = {
  capitalize: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  disablePadding: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isCurrency: PropTypes.bool,
  isDate: PropTypes.bool,
  numeric: PropTypes.bool,
  renderer: PropTypes.func,
  row: PropTypes.object.isRequired,
};

TableColumn.defaultProps = {
  capitalize: false,
  disablePadding: false,
  isCurrency: false,
  isDate: false,
  numeric: false,
  renderer: null,
};

export default withStyles(styles)(TableColumn);
