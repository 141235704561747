import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class FspDenyListOfferings extends Model {
  static get modelName() {
    return 'FspDenyListOfferings';
  }

  static reducer({ type, data }, FspDenyListOfferings) {
    const id = data?.id;

    switch (type) {
      case LOAD_DENY_LIST_OFFERINGS.SUCCESS:
        FspDenyListOfferings.delete();
        data?.response?.forEach((r) => FspDenyListOfferings.upsert(r));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      denyOfferingName: attr(),
      globalDenyOffOn: attr(),
    };
  }
}

const name = FspDenyListOfferings.modelName;

export const LOAD_DENY_LIST_OFFERINGS = createLoadConst(`${name}_dl_offerings`);

export const loadDenyListOfferings = createAction(LOAD_DENY_LIST_OFFERINGS);
