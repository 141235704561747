import Close from '@material-ui/icons/Close';
import { useContext } from 'react';
import { AppBar, Grid, IconButton, Toolbar } from '@material-ui/core';
import AuditContext from 'components/common/Audit/AuditContext';

const AuditDrawerAppBar = () => {
  const { close, DrawerEndAdornment, Title } = useContext(AuditContext);

  return (
    <AppBar color="default" elevation={1} position="sticky" role="banner">
      <Toolbar>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Title />
          </Grid>
          <Grid item>
            <Grid alignItems="center" container wrap="nowrap">
              {DrawerEndAdornment && (
                <Grid item>
                  <DrawerEndAdornment />
                </Grid>
              )}
              <Grid item>
                <IconButton
                  aria-label="Close Audit Drawer"
                  color="inherit"
                  onClick={close}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AuditDrawerAppBar;
