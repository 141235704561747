import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  digitContainer: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(11),
    width: theme.spacing(8.5),
  },
}));

const Digit = ({ digit }) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classes.digitContainer}
      container
      justifyContent="center"
    >
      <Grid item>
        <Typography variant="h2">{digit}</Typography>
      </Grid>
    </Grid>
  );
};

Digit.propTypes = {
  digit: PropTypes.string,
};

Digit.defaultProps = {
  digit: '',
};

export default Digit;
