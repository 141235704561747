import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { STATES } from 'consts';
import { Field, useField } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';

const SelectState = ({ label, name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <Field
      {...props}
      component={Autocomplete}
      name={field.name}
      options={STATES}
      renderInput={(params) => (
        <TextField
          {...params}
          error={meta.touched && !!meta.error}
          helperText={meta.touched ? meta.error : ''}
          label={label}
        />
      )}
    />
  );
};

SelectState.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

SelectState.defaultProps = {
  label: undefined,
};

export default SelectState;
