import { LOAD_FLAGS } from 'model/flags';
import { loadFlags } from 'sdk/flags';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export const loadFlagsAction = callAction({
  api: loadFlags,
  dataName: ORM,
  type: LOAD_FLAGS,
});

export default function* saga() {
  yield takeLatest(LOAD_FLAGS.ACTION, loadFlagsAction);
}
