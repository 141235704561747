import { client, formatQueryParams } from 'util/sdk';

export const load = (params) => client.billers.query(formatQueryParams(params));

export const loadById = ({ id }) => client.billers(id).get();

export const loadSet = ({ id }) =>
  client.billerSetBillers.query(
    formatQueryParams({ billerId: id, expand: ['billerset'] })
  );

export const update = ({ id, ...data }, auth) =>
  client.billers(id).update(data, { adminUserId: auth.id });
