import { useFSPConfig } from 'hooks';
import { showRDFIInfo } from '../../consts';

const useRDFIInfo = (transaction) => {
  // ON-1708: RDFI Name is not loading properly for non-default filters (FSPs which are not in the current store)
  // By switching 'useFSPConfig' second parameter to true, the model/fspConfig will load the record to the store
  // making it available to use it later (fspConfig.fspName)
  // See ticket: https://payrailz.atlassian.net/browse/ON-1708
  const fspConfig = useFSPConfig(transaction.fspId, true);
  const isFIDirect = transaction.isFIDirectDerived;

  return [
    showRDFIInfo(transaction),
    isFIDirect
      ? transaction.coreAccountNumberMask
      : transaction.extDdaAccountNbrMask,
    isFIDirect ? fspConfig.fspName : transaction.extDdaFiName,
    isFIDirect ? transaction.coreRtn : transaction.extDdaAchRtn,
    isFIDirect,
  ];
};

export default useRDFIInfo;
