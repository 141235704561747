import { ADD, LOAD, REMOVE } from 'model/billerSetBiller';
import { add, load, remove } from 'sdk/billerSetBiller';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      formatSuccessMessage: () => 'The Biller Set was updated',
      type: ADD,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: () => 'The Biller Set was updated',
      type: REMOVE,
    })
  );
}
