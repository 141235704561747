import {
  includes,
  equals,
  find,
  indexOf,
  reject,
  slice,
  startsWith,
} from 'ramda';
import { ACTION, beginDelete, endDelete } from 'util/actions';

export const isDeletingState = (state, type) => includes(type, state.deleting);

export default (state = [], { type }) => {
  if (beginDelete(type) && !includes(type, state)) {
    return [...state, type];
  }
  if (endDelete(type)) {
    const foundItem = find(
      (item) => startsWith(slice(0, indexOf(ACTION, item), item), type),
      state
    );
    return reject(equals(foundItem), state);
  }
  return state;
};
