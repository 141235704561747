import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import { palTransactionTypeDerived } from 'model/util/scheduledTransaction';
import usePayALoanTransaction from '../../../../History/config/DetailsRender/usePayALoanTransaction';

const AppliedTo = ({ item }) => {
  return (
    usePayALoanTransaction(item.transactionType) && (
      <Grid item xs={1}>
        <ReadOnlyFormControl
          label="Applied To"
          value={palTransactionTypeDerived(item)}
        />
      </Grid>
    )
  );
};
AppliedTo.propTypes = {
  item: PropTypes.shape({
    transactionType: PropTypes.string,
  }).isRequired,
};
export default AppliedTo;
