import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthenticationBackButton from './AuthenticationBackButton';
import AuthenticationHeader from './AuthenticationHeader';
import AuthenticationProgress from './AuthenticationProgress';

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 445,
    [theme.breakpoints.up('md')]: {
      width: 450,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
    },
  },
  root: {
    backgroundColor: theme.palette.grey[300],
    [theme.breakpoints.up('md')]: {
      height: 100, // IE11 hack
      minHeight: '100vh',
    },
  },
}));

const Authentication = ({ children, progress, showBack, title }) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classes.root}
      container
      direction="column"
      justifyContent="center"
      spacing={1}
    >
      {showBack && (
        <Grid item>
          <AuthenticationBackButton />
        </Grid>
      )}
      <Grid item>
        <Card className={classes.card}>
          <AuthenticationProgress show={progress} />
          <AuthenticationHeader title={title} />
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Authentication.propTypes = {
  children: PropTypes.node,
  progress: PropTypes.bool,
  showBack: PropTypes.bool,
  title: PropTypes.string,
};

Authentication.defaultProps = {
  children: null,
  progress: false,
  showBack: false,
  title: '',
};

export default Authentication;
