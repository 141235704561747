import { isEmpty, isNil, omit, or, reject } from 'ramda';

const ddaParamsToOmit = [
  'accountAchNbrMask',
  'accountMicrNbrMask',
  'accountNbrMask',
  'accountTitle',
  'autopayRuleAt',
  'createdAt',
  'ddaStatus',
  'ddaStatusAt',
  'ddaStatusText',
  'displayAccountNbrMaskLong',
  'displayAccountNbrMaskShort',
  'fract_rtn',
  'fsp',
  'fspDdaIdMask',
  'fspId',
  'href',
  'id',
  'offset',
  'payer',
  'payerId',
  'updatedAt',
  'uspsAddress',
];
const payerParamsToOmit = [
  'offset',
  'href',
  'id',
  'zfspId',
  'status',
  'statusAt',
  'statusText',
  'serviceStartOn',
  'taxIdMask',
  'limitType',
  'createdAt',
  'updatedAt',
  'fsp',
  'fspPayerIdMask',
];

const isNilOrEmpty = (value) => or(isNil(value), isEmpty(value));
const clean = (params, fromParams) => {
  return reject(isNilOrEmpty, omit(params, fromParams));
};

export const MAX_OPTION = { limit: 100 };
export const PAYRAILZ_ADMIN_ORG_ID = 1000;
export const label = 'last updated by';
export const separator = ':';

export const accountTitleCheck = (dda = {}) => {
  return (
    !isNil(dda.accountTitle) &&
    !isNil(dda.uspsAddress) &&
    !isNil(dda.uspsAddress.street1) &&
    !isNil(dda.uspsAddress.city) &&
    !isNil(dda.uspsAddress.state) &&
    !isNil(dda.uspsAddress.zip)
  );
};
export const cleanDDA = (fromParams) => {
  return clean(ddaParamsToOmit, fromParams);
};
export const cleanPayer = (fromParams) => clean(payerParamsToOmit, fromParams);
export const getStatusText = (statusText) => {
  let status;

  try {
    status = statusText.split(separator)[1].trim();
  } catch (e) {
    status = statusText;
  }

  return status;
};
export const getUpdatedStatus = (
  status,
  { email, userLogin },
  prefix = 'status',
  options
) => ({
  [`${prefix}`]: status,
  [`${prefix}Text`]: `${label}${separator} ${email ?? userLogin}`,
  ...options,
});
export const getUpdatedStatusNoLabel = (
  status,
  { email, userLogin },
  prefix = 'status'
) => ({
  [`${prefix}`]: status,
  [`${prefix}Text`]: email ?? userLogin,
});
