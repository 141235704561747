import ClearOutlined from '@material-ui/icons/ClearOutlined';
import IconButton from '@material-ui/core/IconButton';

const EndAdornmentResetButton = (props) => {
  return (
    <IconButton {...props}>
      <ClearOutlined />
    </IconButton>
  );
};

export default EndAdornmentResetButton;
