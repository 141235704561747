import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { clearReset } from 'reducers/reset';
import { isNil } from 'ramda';
import { getAuth } from 'util/index';
import {
  IP_WHITELIST_FAILED_CODE,
  LOGIN_FORM_NAME,
  OTP_ACCT_LOCKED_ERROR,
  OTP_CANNOT_SEND,
  OTP_REQUIRED_ERROR,
  TITLE,
} from 'consts';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Authentication from '../Authentication';
import OneTimePasscode from '../OneTimePasscode';
import PasswordReset from '../Password/PasswordReset';
import PasswordResetPost from '../Password/PasswordReset/PasswordResetPost';
import PasswordVerify from '../Password/PasswordVerify';
import useNavigateAfterLogin from './useNavigateAfterLogin';
import {
  otpPanel,
  passwordPanel,
  resetPasswordPanel,
  postResetPasswordPanel,
} from './consts';
import LoginTabs from './LoginTabs';

const Login = () => {
  const [activePanelId, setActivePanelId] = useState(passwordPanel.id);
  const [authenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [title, setTitle] = useState(TITLE);
  const dispatch = useDispatch();
  const { adminOrg, error, expired } = useSelector(getAuth, shallowEqual);
  const ipLoadBusy = useNavigateAfterLogin(authenticated, adminOrg);

  const handleAuthenticated = () => {
    setAuthenticated(true);
  };
  const handleProgress = (value) => {
    setShowProgress(value);
  };
  const handleReset = ({ email = '' }) => {
    setEmail(email);
    setActivePanelId(postResetPasswordPanel.id);
  };
  const handleViewReset = () => {
    setActivePanelId(resetPasswordPanel.id);
  };
  const handleViewVerify = () => {
    setTitle(TITLE);
    setActivePanelId(passwordPanel.id);
  };

  useEffect(() => {
    if (activePanelId === passwordPanel.id) {
      dispatch(clearReset());
    }
  }, [activePanelId, dispatch]);

  useEffect(() => {
    if (error?.number) {
      let id;
      const { number } = error;

      switch (number) {
        case OTP_REQUIRED_ERROR:
          id = otpPanel.id;
          break;

        case IP_WHITELIST_FAILED_CODE:
        case OTP_ACCT_LOCKED_ERROR:
        case OTP_CANNOT_SEND:
          id = passwordPanel.id;
          break;

        default:
          break;
      }

      if (!isNil(id)) {
        setActivePanelId(id);
      }
    }
  }, [error]);

  return (
    <Authentication progress={showProgress || ipLoadBusy} title={title}>
      <LoginTabs activePanelId={activePanelId} />
      <SwipeableViews index={activePanelId}>
        <OneTimePasscode
          onAuthenticated={handleAuthenticated}
          onProgress={handleProgress}
          visible={activePanelId === otpPanel.id}
        />
        <PasswordVerify
          form={LOGIN_FORM_NAME}
          onAuthenticated={handleAuthenticated}
          onProgress={handleProgress}
          onViewChange={handleViewReset}
          tokenExpired={expired}
          visible={activePanelId === passwordPanel.id}
        />
        <PasswordReset
          onProgress={handleProgress}
          onReset={handleReset}
          onViewChange={handleViewVerify}
          visible={activePanelId === resetPasswordPanel.id}
        />
        <PasswordResetPost
          email={email}
          onGo={handleViewVerify}
          visible={activePanelId === postResetPasswordPanel.id}
        />
      </SwipeableViews>
    </Authentication>
  );
};

export default Login;
