import { ON } from 'consts';
import { isOff } from 'util/index';

// If specifically set to 'off', it is off otherwise it is on because by default it is on
const setOffOn = (value) => (isOff(value) ? value : ON);

// Doing this because if there is no any record in xotp_fsp_override for any otp event flag, api returns fspotpevent as {}, however,
// ontrac should show as checked because by default all otp events are required. Created the following api ticket so it returns flags as on
// https://payrailz.atlassian.net/browse/DBAPI-1070

// eslint-disable-next-line import/prefer-default-export
export const derived = (eventConfig) => ({
  ...eventConfig,
  addA2aExternalDdaOffOn: setOffOn(eventConfig.addA2aExternalDdaOffOn),
  addBpCheckPayeeOffOn: setOffOn(eventConfig.addBpCheckPayeeOffOn),
  addBpElecPayeeOffOn: setOffOn(eventConfig.addBpElecPayeeOffOn),
  createP2pContactOffOn: setOffOn(eventConfig.createP2pContactOffOn),
  highRiskA2aTransferOffOn: setOffOn(eventConfig.highRiskA2aTransferOffOn),
  highRiskBillPaymentOffOn: setOffOn(eventConfig.highRiskBillPaymentOffOn),
  highRiskP2pPaymentOffOn: setOffOn(eventConfig.highRiskP2pPaymentOffOn),
  updateP2pContactEmailPhoneOffOn: setOffOn(
    eventConfig.updateP2pContactEmailPhoneOffOn
  ),
});
