import { Formik } from 'formik';
import { useParamId } from 'hooks';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selector } from 'selectors/request';
import Dialog from './ImportDialog';

const getInitialValues = (section) => {
  return { [section]: [] };
};

const ImportFormik = ({
  ExpansionListProps,
  IMPORT,
  importAction,
  load,
  onImport,
  onRequestClose,
  open,
  processFileImportData,
  section,
  title,
  validationSchema,
}) => {
  const initialValue = useMemo(() => getInitialValues(section), [section]);
  const [initialValues, setInitialValues] = useState(initialValue);
  const [completed, failed] = useSelector((state) => selector(state, IMPORT));
  const dispatch = useDispatch();
  const fspId = useParamId();
  const setSubmittingRef = useRef();

  const handleClose = () => {
    setInitialValues(initialValue);
    onRequestClose();
  };
  const handleImport = (data = {}) => {
    const sectionData = processFileImportData
      ? processFileImportData(data)
      : { [section]: data[section] };

    if (sectionData) {
      setInitialValues(sectionData);
    }

    onImport(data);
  };
  const handleSubmit = useCallback(
    (values, formik) => {
      setSubmittingRef.current = formik.setSubmitting;
      dispatch(importAction({ fspId, ...values }));
    },
    [dispatch, fspId, importAction]
  );

  useEffect(() => {
    if (completed) {
      setInitialValues(initialValue);
    }

    if ((completed || failed) && setSubmittingRef.current) {
      setSubmittingRef.current(false);
      setSubmittingRef.current = null;
    }
  }, [completed, failed, initialValue]);

  return load ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={validationSchema}
    >
      <Dialog
        ExpansionListProps={ExpansionListProps}
        IMPORT={IMPORT}
        onImport={handleImport}
        onRequestClose={handleClose}
        open={open}
        section={section}
        title={title}
      />
    </Formik>
  ) : null;
};

ImportFormik.propTypes = {
  ExpansionListProps: PropTypes.object.isRequired,
  IMPORT: PropTypes.object.isRequired,
  importAction: PropTypes.func.isRequired,
  load: PropTypes.bool.isRequired,
  onImport: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  processFileImportData: PropTypes.func,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

ImportFormik.defaultProps = {
  onImport: identity,
  processFileImportData: undefined,
};

export default ImportFormik;
