import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useMemo, useState } from 'react';
import EndAdornment from './EndAdornment';
import ParamsChips from './ParamChips';
import PopoverCard from './PopoverCard';
import StartAdornment from './StartAdornment';
import useSearchBys from '../useSearchBys';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(4),
    minWidth: theme.spacing(50),
  },
}));

const Multiple = ({
  InputProps: InputPropsParam,
  inputProps: inputPropsParam,
  disabled,
  pageSettingsId,
  pageSettingsName,
  placeholder,
  searchParams,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [param, setParam] = useState();
  const [appliedParams, handleChange, handleDelete, handleReset] = useSearchBys(
    pageSettingsName,
    searchParams
  );
  const classes = useStyles(props);
  const InputProps = useMemo(() => {
    const handleOpenCard = (event, param) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);

      if (param) {
        setParam(param);
      }
    };
    const inputComponent = appliedParams?.length
      ? () => (
          <ParamsChips
            disabled={disabled}
            onClick={handleOpenCard}
            onDelete={handleDelete}
            params={appliedParams}
          />
        )
      : undefined;

    return {
      ...InputPropsParam,
      endAdornment: (
        <EndAdornment
          disabled={disabled}
          onAdd={handleOpenCard}
          onReset={handleReset}
          show={appliedParams?.length >= 1}
          showReset={appliedParams?.length > 1}
        />
      ),
      inputComponent,
      startAdornment: <StartAdornment />,
    };
  }, [InputPropsParam, appliedParams, disabled, handleDelete, handleReset]);
  const inputProps = useMemo(() => {
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    return { ...inputPropsParam, onClick: handleClick };
  }, [inputPropsParam]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setParam(null);
  };

  return (
    <>
      <TextField
        className={classes.root}
        disabled={disabled}
        inputProps={inputProps}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={InputProps}
        placeholder={placeholder}
        value={appliedParams}
        {...props}
      />
      <PopoverCard
        anchorEl={anchorEl}
        onChange={handleChange}
        onClose={handleClose}
        open={open}
        pageSettingsName={pageSettingsName}
        param={param}
        searchParams={searchParams}
      />
    </>
  );
};

Multiple.propTypes = {
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  pageSettingsName: PropTypes.string.isRequired,
  pageSettingsId: PropTypes.number,
  placeholder: PropTypes.string,
  searchParams: PropTypes.arrayOf(PropTypes.object),
};

Multiple.defaultProps = {
  disabled: false,
  InputProps: {},
  inputProps: {},
  pageSettingsId: 0,
  placeholder: 'Search',
  searchParams: null,
};

export default Multiple;
