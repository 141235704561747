import { useSelector } from 'react-redux';
import { isOn } from '../util';
import { selector } from '../selectors/fspConfig';

const usePayALoanOffOn = (fspId) => {
  const fspConfig = useSelector((state) => selector(state, fspId));

  return isOn(fspConfig?.palOffOn);
};

export default usePayALoanOffOn;
