import PropTypes from 'prop-types';
import { memo } from 'react';
import TransactAsButton from 'components/modules/service/PayerDetail/TransactAsButton';
import { AccountBalance } from '@material-ui/icons';
import { List } from '@material-ui/core';
import { transactOnBehalfA2AOnSelector } from 'selectors/userFspConfig';
import { LOAD as LOAD_EXTERNAL } from 'model/externalAccount';
import { LOAD as LOAD_EXTERNAL_IAV } from 'model/externalAccountIAV';
import { LOAD as LOAD_INTERNAL } from 'model/internalAccount';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import External from './External';
import Internal from './Internal';
import ExternalIAV from './ExternalIAV';
import PayerDetailPanel from '../PayerDetailPanel';

export const HEIGHT_OFFSET = 68;
export const SCROLL_TOP_OFFSET = 28;

const Accounts = ({ fspId, payerId }) => {
  const isBusyExternal = useSelector((state) => selector(state, LOAD_EXTERNAL));
  const isBusyExternalIAV = useSelector((state) =>
    selector(state, LOAD_EXTERNAL_IAV)
  );
  const isBusyInternal = useSelector((state) => selector(state, LOAD_INTERNAL));
  const transactA2AOn = useSelector((state) =>
    transactOnBehalfA2AOnSelector(state, fspId, payerId)
  );

  return (
    <PayerDetailPanel
      endAdornment={
        transactA2AOn ? (
          <TransactAsButton payerId={payerId} fspIdValue={fspId} />
        ) : null
      }
      fspId={fspId}
      heightOffset={HEIGHT_OFFSET}
      icon={AccountBalance}
      id="accounts"
      payerId={payerId}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusyExternal || isBusyExternalIAV || isBusyInternal}
      title="Accounts"
    >
      <List>
        <Internal fspId={fspId} payerId={payerId} />
        <External fspId={fspId} payerId={payerId} />
        <ExternalIAV fspId={fspId} payerId={payerId} />
      </List>
    </PayerDetailPanel>
  );
};

Accounts.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(Accounts);
