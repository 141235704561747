export const PATH = '/login';

export const otpPanel = {
  id: 0,
  label: 'One Time Passcode Panel',
};
export const passwordPanel = {
  id: 1,
  label: 'Password Panel',
};
export const resetPasswordPanel = {
  id: 2,
  label: 'Reset Password Panel',
};
export const postResetPasswordPanel = {
  id: 3,
  label: 'Post Reset Password Panel',
};
