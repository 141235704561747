import CardHeader from '@material-ui/core/CardHeader';
import PropTypes from 'prop-types';
import usePasswordVerifyHeader from './usePasswordVerifyHeader';

const subheaderTypographyProps = {
  color: 'textSecondary',
  variant: 'subtitle1',
};
const titleTypographyProps = {
  color: 'textPrimary',
  variant: 'h5',
};

const PasswordVerifyHeader = ({ tokenExpired, unauthorized }) => {
  const { tokenValue, unauthorizedValue, title, subheader } =
    usePasswordVerifyHeader(tokenExpired, unauthorized);

  return tokenValue || unauthorizedValue ? (
    <CardHeader
      subheader={subheader}
      subheaderTypographyProps={subheaderTypographyProps}
      title={title}
      titleTypographyProps={titleTypographyProps}
    />
  ) : null;
};

PasswordVerifyHeader.propTypes = {
  tokenExpired: PropTypes.bool.isRequired,
  unauthorized: PropTypes.bool,
};

PasswordVerifyHeader.defaultProps = {
  unauthorized: false,
};

export default PasswordVerifyHeader;
