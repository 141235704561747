import PropTypes from 'prop-types';
import { compose, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import { getAuth } from 'util/index';
import { withRouter } from 'react-router-dom';
import { hasPermission } from 'util/rbac';
import { List, withStyles } from '@material-ui/core';
import MenuItem from './menu_item';

const styles = () => ({
  root: {
    display: 'flex',
    borderBottom: '1px solid #ddd',
    padding: 0,
    marginRight: 35,
  },
  menuItem: {},
  selected: {},
  selectedIcon: {},
});

const Menu = ({ allowedMenuItems, classes, location, menuItemClasses }) => {
  return (
    <List className={classes.root} component="div">
      {allowedMenuItems.map(({ iconComponent, name, path, text }) => (
        <MenuItem
          key={name}
          classes={menuItemClasses}
          iconComponent={iconComponent}
          location={location}
          name={name}
          path={path}
          text={text}
        />
      ))}
    </List>
  );
};

Menu.propTypes = {
  allowedMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      iconComponent: PropTypes.component,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  menuItemClasses: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  withRouter,
  connect((state) => ({
    auth: getAuth(state),
  })),
  withProps(({ auth, classes, menuItems }) => ({
    allowedMenuItems: menuItems.filter((menuItem) =>
      hasPermission(auth, ...menuItem.permissions)
    ),
    menuItemClasses: {
      root: classes.menuItem,
      selected: classes.selected,
      selectedIcon: classes.selectedIcon,
    },
  })),
  pure
)(Menu);
