import { client } from 'util/sdk';

export const load = () => client.researchReasons.getAll();

export const create = (
  { fspId, transactionHistoryId, reasonId, description },
  auth
) =>
  client
    .fsps(fspId)
    .transactionHistory(transactionHistoryId)
    .transactionHistoryResearchRequests.create(
      {
        reasonId,
        description,
        assignedAdminUserId: auth.id,
      },
      {
        adminUserId: auth.id,
      }
    );

export const update = (
  { fspId, transactionHistoryId, reasonId, description },
  auth
) =>
  client
    .fsps(fspId)
    .transactionHistory(transactionHistoryId)
    .transactionHistoryResearchRequests.update(
      {
        reasonId,
        description,
        assignedAdminUserId: auth.id,
      },
      {
        adminUserId: auth.id,
      }
    );
