import { createRequestConst, createAction } from 'util/actions';
import { saga } from 'util/saga';

const DB = 'db';
export const LOAD = createRequestConst(DB);

// Creating actions
export const loadDbs = createAction(LOAD);

export default saga({ LOAD });
