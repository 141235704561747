import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  body: {
    fontSize: theme.typography.body1.fontSize,
  },
  head: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const PaymentSeriesTableCell = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <TableCell
      classes={{ body: classes.body, head: classes.head, root: classes.root }}
      {...props}
    >
      {children}
    </TableCell>
  );
};

PaymentSeriesTableCell.propTypes = {
  children: PropTypes.node,
};

PaymentSeriesTableCell.defaultProps = {
  children: null,
};

export default PaymentSeriesTableCell;
