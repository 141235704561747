import PropTypes from 'prop-types';
import { handleStopPropagation } from 'consts';
import { Menu } from '@material-ui/core';
import { selector } from 'selectors/viewAs';
import { useSelector } from 'react-redux';
import Product from './Product';

const anchorOrigin = { horizontal: 'left', vertical: 'center' };
const transformOrigin = { horizontal: 'right', vertical: 'center' };

const Products = ({ anchorEl, onClose, payerId, submenu }) => {
  const { urls = [] } = useSelector((_) => selector(_, payerId)) ?? {};

  return urls.length > 1 ? (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={submenu ? anchorOrigin : undefined}
      getContentAnchorEl={null}
      onClick={handleStopPropagation}
      onClose={onClose}
      open={Boolean(anchorEl)}
      transformOrigin={submenu ? transformOrigin : undefined}
    >
      {urls.map(({ id, name, url }) => (
        <Product key={id} name={name} onClick={onClose} url={url} />
      ))}
    </Menu>
  ) : null;
};

Products.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  payerId: PropTypes.number.isRequired,
  submenu: PropTypes.bool,
};

Products.defaultProps = {
  anchorEl: null,
  submenu: false,
};

export default Products;
