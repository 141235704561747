import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReadOnlyFormControl, ReadOnlyPhone } from 'components/common/fields';
import StatusSelector from './StatusSelector';

const InputProps = { disableUnderline: true, readOnly: true };

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({
  email,
  fspId,
  homePhone,
  id,
  limitType,
  mobilePhone,
  name,
  payerId,
  profileLabelDerived,
  role,
  serviceStartOn,
  status,
  statusAtDerived,
  statusText,
  user,
  userId,
  userType,
  workPhone,
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid item xs={3}>
        <ReadOnlyFormControl label="Ontrac User ID" value={userId} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl label="First Name" value={user.firstName} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl label="Middle Name" value={user.middleName} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl label="Last Name" value={user.lastName} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl capitalize label="User Type" value={userType} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl capitalize label="Role" value={role} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl capitalize label="Limit Type" value={limitType} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl
          label="Service Started On"
          value={serviceStartOn}
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl label="Email" value={email} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyPhone
          defaultValue={mobilePhone}
          fullWidth
          InputProps={InputProps}
          label="Mobile Phone"
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyPhone
          defaultValue={homePhone}
          fullWidth
          InputProps={InputProps}
          label="Home Phone"
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyPhone
          defaultValue={workPhone}
          fullWidth
          InputProps={InputProps}
          label="Work Phone"
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl
          inputNode={
            <StatusSelector
              currentStatus={status}
              fspId={fspId}
              id={id}
              label={profileLabelDerived}
              name={name}
              payerId={payerId}
            />
          }
          label="Status"
          value={status}
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl
          label="Status Last Updated"
          value={statusAtDerived}
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyFormControl label="Status Updated By" value={statusText} />
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  email: PropTypes.string,
  fspId: PropTypes.number.isRequired,
  homePhone: PropTypes.string,
  id: PropTypes.number.isRequired,
  limitType: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  mobilePhone: PropTypes.string,
  name: PropTypes.string.isRequired,
  payerId: PropTypes.number.isRequired,
  profileLabelDerived: PropTypes.string,
  role: PropTypes.string.isRequired,
  serviceStartOn: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusAtDerived: PropTypes.string.isRequired,
  statusText: PropTypes.string,
  user: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
  workPhone: PropTypes.string,
};

DetailsRender.defaultProps = {
  email: null,
  homePhone: null,
  middleName: null,
  mobilePhone: null,
  profileLabelDerived: null,
  statusText: null,
  workPhone: null,
};

export default DetailsRender;
