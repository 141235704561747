import PropTypes from 'prop-types';
import Rule from './Rule';

const SpecialRule = ({ password, ...props }) => {
  return (
    <Rule
      {...props}
      enabled={/[!@#$%^&*(<>)]/.test(password)}
      text="special !@#$%^&*(<>)"
    />
  );
};

SpecialRule.propTypes = {
  password: PropTypes.string.isRequired,
};

export default SpecialRule;
