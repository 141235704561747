import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import InternationalAddress from './AddressInternational';

const AddressInternationalCard = ({ internationalAddress }) => {
  return (
    <Card elevation={0}>
      <CardHeader subheader="International Address" />
      <CardContent>
        <InternationalAddress card {...internationalAddress} />
      </CardContent>
    </Card>
  );
};

AddressInternationalCard.propTypes = {
  internationalAddress: PropTypes.object.isRequired,
};

export default AddressInternationalCard;
