import { attr, fk, Model } from 'redux-orm';
import Biller from 'model/biller';
import { createAction, createLoadConst, dateAndTime } from '../util';

export const derived = (billerAddress) => {
  return {
    ...billerAddress,
    createdAtDerived: dateAndTime(billerAddress?.createdAt),
    updatedAtDerived: dateAndTime(billerAddress?.updatedAt),
    zipDerived: billerAddress.zip.replace(/(\d{5})(\d{4})/, '$1-$2'),
  };
};

export default class BillerAddress extends Model {
  static get modelName() {
    return 'BillerAddress';
  }

  static reducer({ type, data }, BillerAddress, { Biller }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Biller.idExists(data?.props?.billerId)) {
          Biller.withId(data.props.billerId).billerAddresses.delete();
        }
        (data?.response || []).forEach((billerAddress) => {
          BillerAddress.upsert(derived(billerAddress));
        });
        break;

      case LOAD.FAILURE:
        BillerAddress.delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      biller: attr(),
      billerAddressCount: attr(),
      billerId: fk(Biller.modelName, 'billerAddresses'),
      city: attr(),
      createdAt: attr(),
      createdAtDerived: attr(),
      href: attr(),
      id: attr(),
      insBillernetId: attr(),
      netIdStr: attr(),
      offset: attr(),
      state: attr(),
      street1: attr(),
      street2: attr(),
      updBillernetId: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(),
      uspsstd: attr(),
      zip: attr(),
      zipDerived: attr(),
    };
  }
}

const name = BillerAddress.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
