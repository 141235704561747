import { ON } from 'consts';
import { client, formatQueryParams } from 'util/sdk';
import { getUpdatedStatus } from './index';

export const load = ({ fspId, payerId, ...restOfData }) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .externalDdas.query(formatQueryParams(restOfData));

export const loadById = ({ fspId, id }) =>
  client.fsps(fspId).externalDdas(id).get();

export const update = ({ account, options, status }, auth) =>
  client
    .fsps(options.fspId)
    .externalDdas(account.id)
    .updateStatus(getUpdatedStatus(status, auth, 'externalDdaStatus'), {
      adminUserId: auth.id,
    });

export const unmask = ({ id, fspId }, deletedOnly = undefined) =>
  client
    .fsps(fspId)
    .externalDdas(id, deletedOnly === ON ? { deletedOnly: ON } : undefined)
    .decryptAccountNumber();
