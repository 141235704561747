import { Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
} from '@material-ui/core';
import { OFF, ON } from 'consts';

const radio = <Radio color="primary" />;

const ContentCorrectAccountNumber = () => {
  return (
    <FormControl>
      <FormLabel>
        Did the user confirm that they entered the correct account number?
      </FormLabel>
      <Field component={RadioGroup} name="correctAccountNumberEnteredOffOn" row>
        <FormControlLabel control={radio} label="Yes" value={ON} />
        <FormControlLabel control={radio} label="No" value={OFF} />
      </Field>
    </FormControl>
  );
};

export default ContentCorrectAccountNumber;
