import moment from 'moment';
import {
  ASC,
  DEFAULT_MODEL_REF_ARRAY,
  IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS,
  PAYER_PREFIX,
} from 'consts';

const getLimitRuleName = (monthsInService, previousMonthsInService) => {
  const monthSuffix = monthsInService === 1 ? '' : 's';
  const previousMonthSuffix = previousMonthsInService === 1 ? '' : 's';
  return monthsInService === 9999
    ? previousMonthsInService
      ? `for after ${previousMonthsInService} month${previousMonthSuffix} of service`
      : 'always applies'
    : `for ${monthsInService} month${monthSuffix} service or less`;
};

export const addLimitRulesToLimits = (Limit, LimitRule) => {
  const limits = Limit.toRefArray() ?? DEFAULT_MODEL_REF_ARRAY;

  return limits.map((limit) => {
    const previousMonthsInService = '';

    const limitrule = LimitRule.filter(byLimitId(limit.id))
      .orderBy(IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS, ASC)
      .toRefArray()
      .map(addNameToLimitRule(previousMonthsInService));

    return { ...limit, limitrule };
  });
};

export const addNameToLimitRule = (previousMonthsInService) => (limitRule) => {
  const { [IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS]: monthsInService } = limitRule;

  limitRule.nameDerived = getLimitRuleName(
    monthsInService,
    previousMonthsInService
  );

  previousMonthsInService = monthsInService;

  return limitRule;
};

export const byFSPIdAndType = (fspId, type) => (r) =>
  r.fspId === fspId && r.type === type;

export const byFSPIdPayerPrefix = (fspId) => (r) =>
  r.fspId === fspId && !r.type.startsWith(PAYER_PREFIX);

export const byLimitId = (limitId) => (r) => r.limitId === limitId;

export const getProductLimits = (rule, { label, product }, index) => ({
  daily: rule.dailyLimits[product],
  id: index,
  label,
  max: rule.maximumTransaction[product],
  min: rule.minimumTransaction[product],
  monthly: rule.monthlyLimits[product],
  product,
  ruleId: rule.id,
  weekly: rule.weeklyLimits[product],
});

export const getRuleBasedOnServiceStart = (LimitRule, Payer, payerId) => {
  const payer = Payer.idExists(payerId) ? Payer.withId(payerId).ref : {};
  const now = moment();
  const limitType = payer.limitType || '';
  const serviceStartOn = moment(payer.serviceStartOn);
  const monthsInServiceMax = moment().diff(serviceStartOn, 'months');
  const monthsInService = monthsInServiceMax > 9999 ? 9999 : monthsInServiceMax;

  const rule = LimitRule.all()
    .toRefArray()
    .find((rule) => rule.id === 1);

  if (rule) {
    rule.nowDerived = now;

    if (
      rule.inServiceLessThanEqualToMonths + 1 && // it is a zero based index so 0 === 1 month
      isCustomLimitType(limitType)
    ) {
      if (rule.inServiceLessThanEqualToMonths === 9999) {
        rule.expireDateDisplayDerived = 'Never';
      } else {
        const monthsToAdd = rule.inServiceLessThanEqualToMonths;
        const expires = serviceStartOn.add(monthsToAdd, 'month').endOf('month');

        // make sure to keep undefined check
        if (rule.expireMonth === undefined && rule.expireYear === undefined) {
          rule.expireMonth = expires.month() + 1; // API doesn't set this, but needed for create/update
          rule.expireYear = expires.year(); // API doesn't set this, but needed for create/update
        }

        rule.expireDateDerived = expires;
        rule.expireDateDisplayDerived = expires.format('MMMM Do, YYYY');
      }
    }
  }

  return rule;
};

export const getPayerLimit = ({
  id,
  dailyLimits,
  monthlyLimits,
  weeklyLimits,
}) => ({
  daily: dailyLimits.amount,
  id: 0,
  label: 'Payer',
  max: undefined, // doesn't apply
  min: undefined, // doesn't apply
  monthly: monthlyLimits.amount,
  product: 'amount',
  ruleId: id,
  weekly: weeklyLimits.amount,
});

export const isCustomLimitType = (type) => type.startsWith(PAYER_PREFIX);
