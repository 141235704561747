import { isLoadingState } from 'reducers/loading';
import { useSelector } from 'react-redux';

const useIsLoading = (actionConst) => {
  const actionType = actionConst?.ACTION ?? actionConst;

  return useSelector((state) => isLoadingState(state, actionType));
};

export default useIsLoading;
