import { client, formatQueryParams } from 'util/sdk';
import { ON } from 'consts';

// eslint-disable-next-line import/prefer-default-export
export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).billSavingOffers.query(
    formatQueryParams({
      ...params,
      orderById: 'desc',
      allAttribs: ON,
    })
  );
