import { useState } from 'react';

const expiredSubheader = 'Please sign in again';
const expiredTitle = 'Your session has expired';
const unauthorizedSubheader = 'Check your VPN or contact an Administrator';
const unauthorizedTitle = 'Access Restricted';

const usePasswordVerifyHeader = (tokenExpired, unauthorized) => {
  const [tokenValue, setExpiredToken] = useState(tokenExpired);
  const [unauthorizedValue, setUnauthorizedToken] = useState(unauthorized);

  if (tokenExpired !== tokenValue && tokenExpired) {
    setExpiredToken(tokenExpired);
  }

  if (unauthorized !== unauthorizedValue && unauthorized) {
    setUnauthorizedToken(unauthorized);
  }

  const subheader = tokenValue
    ? expiredSubheader
    : unauthorizedValue
    ? unauthorizedSubheader
    : undefined;
  const title = tokenValue
    ? expiredTitle
    : unauthorizedValue
    ? unauthorizedTitle
    : undefined;

  return { tokenValue, unauthorizedValue, title, subheader };
};

export default usePasswordVerifyHeader;
