import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCountChanged = (selector, effect) => {
  const itemsOrCount = useSelector(selector);

  const value =
    typeof itemsOrCount === 'number' ? itemsOrCount : itemsOrCount.length;
  const [count, setCount] = useState(value);
  const [previousCount, setPreviousCount] = useState(value);

  useEffect(() => {
    setCount(value);
  }, [selector, value]);

  useEffect(() => {
    if (previousCount !== count) {
      let possibleLastElement;

      if (Array.isArray(itemsOrCount) && itemsOrCount.length) {
        possibleLastElement = itemsOrCount[itemsOrCount.length - 1];
      }

      setPreviousCount(count);
      effect(count, possibleLastElement);
    }
  }, [count, effect, itemsOrCount, previousCount, selector]);

  return itemsOrCount;
};

export default useCountChanged;
