import { LOAD, UPDATE, REMOVE, ADD } from 'model/fspProcessingFees';
import { load, update, remove, add } from 'sdk/fspProcessingFees';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      type: REMOVE,
      formatSuccessMessage: () => `Fee successfully removed`,
    })
  );

  yield takeLatest(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      type: UPDATE,
      formatSuccessMessage: () => `Fee successfully updated`,
    })
  );

  yield takeLatest(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      type: ADD,
      formatSuccessMessage: () => `Fee successfully added`,
    })
  );
}
