import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { convertToDays } from '../util';

const selector = createSelector(
  orm,
  (state, props) => props,
  ({ ScheduledTransactionAudit }, { id }) =>
    ScheduledTransactionAudit.filter(
      ({ schedId }) => schedId === id
    ).toModelArray()
);

export const selectorCount = createSelector(
  orm,
  (state, props) => props,
  ({ ScheduledTransactionAudit }, { id }) =>
    ScheduledTransactionAudit.filter(({ schedId }) => schedId === id).count()
);

export const selectorGroupedByDays = createReselectSelector(selector, (items) =>
  items.reduce(convertToDays, [])
);
