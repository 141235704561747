import PropTypes from 'prop-types';
import { MenuItem as MuiMenuItem, Typography } from '@material-ui/core';

const MenuList = ({ anchorEl, displayAsMenuItem, onClick, pos, text }) => {
  const style = { width: anchorEl.offsetWidth };

  const handleClick = (event) => {
    onClick(event, pos);
  };

  return displayAsMenuItem ? (
    <MuiMenuItem button onClick={handleClick} style={style}>
      <Typography variant="button">{text}</Typography>
    </MuiMenuItem>
  ) : null;
};

MenuList.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  displayAsMenuItem: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  pos: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default MenuList;
