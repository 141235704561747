import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class DisburseType extends Model {
  static get modelName() {
    return 'DisburseType';
  }

  static reducer({ type, data }, DisburseType) {
    switch (type) {
      case LOAD.SUCCESS:
        DisburseType.delete();
        data.response.forEach((r) => DisburseType.create(r));
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      href: attr(),
      disburseType: attr(),
      description: attr(),
      createdAt: attr(),
      insUserId: attr(),
      insUser: attr(),
      insAdminUserId: attr(),
      updatedAt: attr(),
      updUserId: attr(),
      updUser: attr(),
      updAdminUserId: attr(),
    };
  }

  static options = { idAttribute: 'disburseType' };
}

const name = DisburseType.modelName;

export const LOAD = createLoadConst(name);
export const load = createAction(LOAD);
