import { parse as parseHash } from 'query-string';

const hash = '#';
const parseOptions = {
  parseBooleans: true,
  parseNumbers: true,
  skipEmptyString: true,
  skipNull: true,
  sort: false,
};

const getInitParams = (param, location, nextLocation) => {
  let initParams = param ? location?.hash : nextLocation?.hash;

  if (initParams && initParams[0] === hash) {
    initParams = initParams.slice(1);
  }

  return initParams;
};

export const getUpdatedUrlHashParams = ({
  location,
  name,
  nextLocation,
  param,
}) => {
  const initParams = getInitParams(param, location, nextLocation);
  const params = new URLSearchParams(initParams);

  params.delete(name);

  if (param !== 0) {
    params.append(name, param);
  }

  // remove any empty equal signs
  return params.toString().replace(/=$|=(?=&)/g, '');
};

export const isPathnameStillCurrent = (location, nextLocation) => {
  return (
    nextLocation?.pathname === undefined ||
    location?.pathname === nextLocation?.pathname
  );
};

export const parse = (hash, name) => {
  if (hash) {
    const parsed = parseHash(hash, parseOptions);

    return parsed[name];
  }

  return undefined;
};

export const wouldUrlBeDifferent = (location, to) => {
  if (location.pathname !== to.pathname) {
    return true;
  }

  const removeLeadingChar = (char, key) => {
    const current =
      location[key].charAt(0) === char ? location[key].slice(1) : location[key];
    const proposed = to[key].charAt(0) === char ? to[key].slice(1) : to[key];

    return [current, proposed];
  };

  const [currentSearch, proposedSearch] = removeLeadingChar('?', 'search');

  if (currentSearch !== proposedSearch) {
    return true;
  }

  const [currentHash, proposedHash] = removeLeadingChar('#', 'hash');

  return currentHash !== proposedHash;
};
