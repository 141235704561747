import { client } from 'util/sdk';

export const create = ({ routeId, ...data }, auth) =>
  client.route(routeId).create(data, { adminUserId: auth.id });

export const load = (params) =>
  client.executeRequest('GET', `/routes`, { queryParams: { ...params } });

export const loadId = (id) =>
  client.executeRequest('GET', `/routes/${id}`, { queryParams: {} });

export const remove = ({ id }, auth) =>
  client.routes(id).delete({ adminUserId: auth.id });

export const update = ({ routeId, description, name }, auth) =>
  client.route(routeId).update({ name, description }, { adminUserId: auth.id });
