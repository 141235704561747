import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
  },
  grid: {
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({ item }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Passcode" value={item?.otp} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Created" value={item?.createdAtLabel} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Expires" value={item?.expiresAtLabel} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Validated"
            value={item?.validatedAtLabel}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Denied" value={item?.deniedAtLabel} />
        </Grid>

        <Grid item xs={4}>
          <ReadOnlyFormControl label="Status" value={item?.otpStatus} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            capitalize
            label="Notification Method"
            value={item?.notificationMethodLabel}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Sent To" value={item?.sentToLabel} />
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DetailsRender;
