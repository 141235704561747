import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  withStyles,
} from '@material-ui/core';

const styles = (theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
});

const NoteEditorErrorSnackBar = ({ buttonText, classes, onClose, open }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={null}
      ContentProps={{ variant: 'body1' }}
      message="Could NOT create Note"
      open={open}
    >
      <SnackbarContent
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <Close className={classes.icon} />
          </IconButton>,
        ]}
        className={classes.error}
        message={`Could NOT ${buttonText} Note`}
      />
    </Snackbar>
  );
};

NoteEditorErrorSnackBar.propTypes = {
  buttonText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(NoteEditorErrorSnackBar);
