import { useMemo } from 'react';
import { useWindowResize } from 'hooks';

const usePanelDetailStyles = (fullHeight, heightOffset) => {
  const windowSize = useWindowResize();

  return useMemo(
    () => ({
      height: fullHeight ? windowSize.height - 100 - heightOffset : 'auto',
      overflowY: fullHeight ? 'auto' : 'initial',
      paddingTop: 0,
    }),
    [fullHeight, heightOffset, windowSize.height]
  );
};

export default usePanelDetailStyles;
