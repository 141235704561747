import { DDA_ACCOUNT_NUMBER } from 'consts';
import { LOAD as LOAD_INTERNAL_ACCOUNT } from 'model/internalAccount';
import { load as loadInternalAccount } from 'sdk/internalAccount';
import {
  DECRYPT,
  LOAD,
  LOAD_ID,
  MERGE,
  THROTTLED_LOAD,
  UPDATE,
} from 'model/payer';
import { decryptFspPayerId, load, loadById, merge, update } from 'sdk/payer';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { callAction, getLabel, getStatus, ORM } from '../util';

const expand = ['payer'];
const formatSuccessMessage = ({ response }) => {
  return `${getLabel(response)} has been ${getStatus(response)}`;
};
const formatSuccessMessageMerge = ({ response }) => {
  return `${getLabel(response)} has been merged`;
};

export function* loadDDAOrPayer(action) {
  const { data = {}, form } = action;
  const ddaAccountNumber = data[DDA_ACCOUNT_NUMBER];

  /**
   * If a ddaId is specified we first must make a call to internal accounts
   * and search by accountNbr, if that succeeds than we grab the payerId from
   * that call and use it for the actual call to payers
   */
  if (ddaAccountNumber) {
    delete data[DDA_ACCOUNT_NUMBER]; // No longer needed

    const loadDDAsByAccountNumber = callAction({
      api: loadInternalAccount,
      dataName: ORM,
      type: LOAD_INTERNAL_ACCOUNT,
    });

    yield loadDDAsByAccountNumber({
      data: { accountNbr: ddaAccountNumber, expand, fspId: data.fspId },
      form,
    });

    yield put({
      type: LOAD.SUCCESS,
      data: { props: { keepExistingPayers: true } },
    });
  } else {
    const loadPayers = callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    });

    yield loadPayers({ data, form });
  }
}

export default function* saga() {
  yield takeEvery(
    DECRYPT.ACTION,
    callAction({
      api: decryptFspPayerId,
      dataName: ORM,
      type: DECRYPT,
    })
  );

  yield takeEvery(LOAD.ACTION, loadDDAOrPayer);

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    MERGE.ACTION,
    callAction({
      api: merge,
      dataName: ORM,
      formatSuccessMessageMerge,
      type: MERGE,
    })
  );

  yield takeLatest(
    THROTTLED_LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: THROTTLED_LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage,
      type: UPDATE,
    })
  );
}
