import { client, formatQueryParams } from 'util/sdk';
import { GET } from 'util/api';

const returnModel = { name: 'histtran' };
const histTransURL = (fspId) => `/fsps/${fspId}/histtrans`;

const formatQueryOperations = {
  offset: (value) => ({ offset: value }),
  limit: (value) => ({ limit: value }),
  orderByTransactionType: (value) => ({ order: `transactiontype:${value}` }),
  orderBySendOn: (value) => ({ order: `sendon:${value}` }),
  orderByFundingAccount: (value) => ({ order: `fundingaccount:${value}` }),
  orderByDestination: (value) => ({ order: `destination:${value}` }),
  orderByAmount: (value) => ({ order: `amount:${value}` }),
  orderByStatus: (value) => ({ order: `status:${value}` }),
  sendOnRange: (props) => {
    const [value1, value2] = props;
    return {
      'startwith(sendon)': value1,
      'endwith(sendon)': value2,
    };
  },
  transactionType: (value) => ({ transactionType: value }),
  destinationLike: (value) => ({ 'like(destination)': value }),
  confirmationNumber: (value) => ({ confirmnbr: value }),
  status: (value) => ({ status: value }),
  destinationMask: (value) => ({ destinationmask: value }),
  fundingMask: (value) => ({ fundingmask: value }),
  amount: (value) => ({ amount: value }),
  loanNameLike: (value) => ({ 'like(loanname)': value }),
  loanNumber: (value) => ({ loannumber: value }),
  autopayOffOn: (value) => ({ autopayoffon: value }),
  a2atracenumber: (value) => ({ a2atracenumber: value }),
};

const getQueryParams = (params) => {
  return params.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const load = async ({ fspId, payerId, ...params }) => {
  let result;
  const formatQueryArray = formatQueryParams(params);
  try {
    result = await client.executeRequest(GET, histTransURL(fspId), {
      queryParams: {
        ...getQueryParams(formatQueryArray(formatQueryOperations)),
        payerid: payerId,
      },
      returnModel,
    });
  } catch (error) {
    return [];
  }
  return result;
};

export const loadById = ({ fspId, id }) =>
  client.fsps(fspId).transactionHistory(id).get();

export const loadByQueryParams = ({ fspId, ...params }) =>
  client.fsps(fspId).transactionHistory.query(formatQueryParams(params));

export const decryptRDFIAccountNumber = ({ fspId, id }) =>
  client.fsps(fspId).transactionHistory.query(
    formatQueryParams({
      attributes: ['coreAccountNumber', 'extDdaAccountNbr'],
      id,
    })
  );

export const decryptDestination = (transaction) =>
  client
    .fsps(transaction.fspId)
    .transactionHistory(transaction.id)
    .decryptDestinationAccountNumber(transaction);

export const decryptFunding = (transaction) =>
  client
    .fsps(transaction.fspId)
    .transactionHistory(transaction.id)
    .decryptFundingAccountNumber(transaction);

export const updateReturnedStatus = ({ fspId, id, ...params }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(id)
    .updateReturnedStatus(params, { adminUserId: auth.id });

export const updatePaymentNor = ({ fspId, id, returnedCode }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(id)
    .updateReturnedA2as({ returnedCode }, { adminUserId: auth.id });

export const updatePaymentNoc = ({ fspId, id, ...data }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(id)
    .updateReturnedA2aNOCs(data, { adminUserId: auth.id });
