import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { profileSelector } from 'selectors/payer';
import { useSelector } from 'react-redux';
import AddressInternational from './AddressInternational';
import AddressInternationalCard from './AddressInternationalCard';

const Address = ({ id }) => {
  const item = useSelector((state) => profileSelector(state, id));

  const {
    address,
    cityState,
    code,
    hasInternationalAddress,
    internationalAddress,
    onlyInternational,
  } = item;

  const internationalAddressNode = hasInternationalAddress && (
    <ReadOnlyInputWithMenu
      label={address}
      menuNode={
        <AddressInternationalCard internationalAddress={internationalAddress} />
      }
      tooltip="International Address"
    />
  );

  return onlyInternational ? (
    <AddressInternational {...internationalAddress} />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          inputNode={internationalAddressNode}
          label="Address"
          value={address}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="City, State" value={cityState} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="ZipCode" value={code} />
      </Grid>
    </Grid>
  );
};

Address.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Address;
