import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { TRANSACT_AS_PAYER, selector } from 'reducers/transactAs';
import { selector as busySelector } from 'selectors/busy';
import ButtonWithCircularProgress from 'components/common/ButtonWithCircularProgress';
import EmbeddedSso from './EmbeddedSso';

const useStyle = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const SsoContent = ({ payerName, onCancel }) => {
  const classes = useStyle();

  return (
    <>
      <DialogTitle>
        Transact As {payerName}
        <IconButton
          aria-label="Close Transact As Dialog"
          className={classes.closeButton}
          color="inherit"
          onClick={onCancel}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <EmbeddedSso exitSso={onCancel} />
    </>
  );
};

SsoContent.propTypes = {
  payerName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const ConfirmContent = ({ onCancel, onConfirm, payerName }) => {
  const [acceptedConfirm, setAcceptedConfirm] = useState(false);
  const isBusy = useSelector((state) => busySelector(state, TRANSACT_AS_PAYER));

  const toggleAcceptedConfirm = () => {
    setAcceptedConfirm(!acceptedConfirm);
  };

  const handleCancel = useCallback(() => {
    onCancel();
    setAcceptedConfirm(false);
  }, [onCancel, setAcceptedConfirm]);

  return (
    <>
      <DialogTitle>Confirm transact as payer</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptedConfirm}
              color="primary"
              onClick={toggleAcceptedConfirm}
            />
          }
          label={`By checking this box, you have confirmed with the user, ${payerName}, that he/she has authorized you to take this action on his/her behalf.`}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCancel}>
          Cancel
        </Button>
        <ButtonWithCircularProgress
          busy={isBusy}
          color="primary"
          disabled={!acceptedConfirm}
          onClick={onConfirm}
        >
          Continue
        </ButtonWithCircularProgress>
      </DialogActions>
    </>
  );
};

ConfirmContent.propTypes = {
  payerName: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmContent.defaultProps = {
  payerName: null,
};

const ConfirmDialog = ({ payerName, open, onConfirm, onCancel }) => {
  const transactAsData = useSelector(selector) || { success: false };
  const displaySso = transactAsData.success;

  const Content = displaySso ? SsoContent : ConfirmContent;
  return (
    <Dialog
      maxWidth={displaySso ? 'xl' : undefined}
      onClick={(event) => event.stopPropagation()}
      open={open}
    >
      <Content
        onCancel={onCancel}
        onConfirm={onConfirm}
        payerName={payerName}
      />
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  payerName: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  payerName: null,
};

export default ConfirmDialog;
