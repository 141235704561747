import PropTypes from 'prop-types';
import { load, LOAD } from 'model/internalAccount';
import { selector } from 'selectors/internalAccount';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useIsBusy, useIsLoading } from 'hooks';
import AccountList from '../AccountsList';
import {
  ActionsRenderer,
  columnMeta,
  defaultOrderBy,
  detailsRender,
} from './config';

const Internal = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useIsBusy(LOAD);
  const isLoading = useIsLoading(LOAD);
  const items = useSelector((state) => selector(state, payerId));

  const loadAction = (props) => {
    dispatch(load({ fspId, payerId, ...props }));
  };

  useEffect(() => {
    dispatch(load({ fspId, payerId }));
  }, [dispatch, fspId, payerId]);

  return (
    <AccountList
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      columnMeta={columnMeta}
      deletedOnly="off"
      detailsRender={detailsRender}
      fspId={fspId}
      isBusy={isBusy}
      isLoading={isLoading}
      items={items}
      loadAction={loadAction}
      name="internal"
      orderBy={defaultOrderBy}
      payerId={payerId}
      settingType="internalAccount"
      title="Internal"
    />
  );
};

Internal.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default Internal;
