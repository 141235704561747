import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from 'util/actions';
import { addDerived } from './util/paymentResearch';

export default class PaymentResearch extends Model {
  static get modelName() {
    return 'PaymentResearch';
  }

  static reducer({ type, data }, PaymentResearch, { FSP }) {
    switch (type) {
      case LOAD.SUCCESS:
      case LOAD_ASSIGNED.SUCCESS:
      case LOAD_HIST_ID.SUCCESS:
        PaymentResearch.delete();
        data.response.forEach((r) => {
          FSP.upsert(r.fsp);
          PaymentResearch.upsert(addDerived(r));
        });
        break;

      case LOAD_ID.SUCCESS:
        PaymentResearch.upsert(addDerived(data.response));
        break;

      case CREATE.SUCCESS:
        PaymentResearch.create(addDerived(data.response));
        break;

      case UPDATE.SUCCESS:
        PaymentResearch.withId(data.response.id).update(
          addDerived(data.response)
        );
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      amount: attr(),
      accountNumberOnBill: attr(),
      accountNumberOnBillMask: attr(),
      accountNumberOnBillMaskLabel: attr(), // derived
      assignedAdminFirstName: attr(),
      assignedAdminLastName: attr(),
      assignedAdminUserId: attr(),
      assignedAt: attr(),
      assignedAtLabel: attr(), // Derived
      assignedTo: attr(), // Derived
      billDueDate: attr(),
      billDueDateLabel: attr(), // derived
      billerContactEmail: attr(),
      billerContactName: attr(),
      billerContactPhone: attr(),
      billerContactPhoneLabel: attr(), // derived
      billType: attr(),
      closedAdminFirstName: attr(),
      closedAdminLastName: attr(),
      closedAdminUserId: attr(),
      closedAt: attr(),
      closedAtLabel: attr(), // Derived
      closedBy: attr(), // Derived
      confirmationNumber: attr(),
      correctAccountNumberEnteredOffOn: attr(),
      correctAccountNumberEnteredOffOnLabel: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // Derived
      createdByLabel: attr(), // Derived
      description: attr(),
      execId: attr(),
      fsp: attr(),
      fspId: attr(),
      fspNameDerived: attr(),
      histId: attr(),
      histtran: attr(),
      href: attr(),
      id: attr(),
      idLabel: attr(), // Derived
      insUserId: attr(),
      isEmpty: attr(), // Derived
      isHighlighted: attr(), // Derived
      nameOnBill: attr(),
      newAccountNumberOffOn: attr(),
      newAccountNumberOffOnLabel: attr(), // derived
      openAt: attr(),
      openAtLabel: attr(), // Derived
      payeeName: attr(), // Derived
      payeeSendOnLabel: attr(), // Derived
      payerId: attr(),
      payerLabel: attr(), // Derived
      paymentPostedToActiveBillerOffOn: attr(),
      paymentPostedToActiveBillerOffOnLabel: attr(), // derived
      paymentSentMultipleTimesOffOn: attr(),
      paymentSentMultipleTimesOffOnLabel: attr(), // derived
      pendingAt: attr(),
      postedAmount: attr(),
      reasonId: attr(),
      reasonName: attr(),
      solvedAdminFirstName: attr(),
      solvedAdminLastName: attr(),
      solvedAdminUserId: attr(),
      solvedAt: attr(),
      solvedAtLabel: attr(), // Derived
      solvedBy: attr(), // Derived
      ssnLast4: attr(),
      status: attr(),
      statusAt: attr(),
      statusAtLabel: attr(), // Derived
      statusText: attr(),
      updatedAt: attr(),
      updatedAtHumanize: attr(), // Derived
      updatedAtLabel: attr(), // Derived
      updUserId: attr(),

      /**
       * Important! - relational entries you can't see here
       *
       * researchNotes: See ResearchNote.Model trrId fk()
       */
    };
  }
}

export const CLAIM = createUpdateConst(`${PaymentResearch.modelName}_claim`);
export const CREATE = createAddConst(PaymentResearch.modelName);
export const LOAD = createLoadConst(PaymentResearch.modelName);
export const LOAD_ASSIGNED = createRequestConst(
  `${PaymentResearch.modelName}_assigned`
);
export const LOAD_HIST_ID = createRequestConst(
  `${PaymentResearch.modelName}_histId`
);
export const LOAD_ID = createRequestConst(`${PaymentResearch.modelName}_id`);
export const UPDATE = createUpdateConst(PaymentResearch.modelName);

export const claim = createAction(CLAIM);
export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const loadAssigned = createAction(LOAD_ASSIGNED);
export const loadByHistId = createAction(LOAD_HIST_ID);
export const loadById = createAction(LOAD_ID);
export const update = createAction(UPDATE);
