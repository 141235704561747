import PropTypes from 'prop-types';

const actionConst = PropTypes.shape({
  ACTION: PropTypes.string.isRequired,
  BUSY: PropTypes.string.isRequired,
  FAILURE: PropTypes.string.isRequired,
  ROOT: PropTypes.string.isRequired,
  SUCCESS: PropTypes.string.isRequired,
});

export default actionConst;
