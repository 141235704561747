import PropTypes from 'prop-types';
import { OFF, ON } from 'consts';
import {
  Checkbox as MuiCheckBox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import { identity, mergeLeft } from 'ramda';
import { useFormikField } from '../hooks';

const defaultProps = { arrow: true, placement: 'top' };

const Checkbox = ({
  classes,
  color,
  label,
  onChange,
  TooltipProps,
  ...props
}) => {
  const [formikProps, field, helpers] = useFormikField(props);

  const { disabled, error, helperText } = formikProps;
  const checked = field.value === ON;
  const derivedTooltipProps = mergeLeft(TooltipProps, defaultProps);

  const handleChange = (event) => {
    const value = event.target.value === ON ? OFF : ON;

    helpers.setValue(value);
    onChange(event);
  };

  return (
    <FormControl className={classes?.checkboxForm} error={error} required>
      <Tooltip {...derivedTooltipProps}>
        <FormControlLabel
          control={
            <MuiCheckBox
              {...props}
              {...field}
              checked={checked}
              color={color}
              disabled={disabled}
              onChange={handleChange}
            />
          }
          label={label}
        />
      </Tooltip>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

Checkbox.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  TooltipProps: PropTypes.shape({
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    title: PropTypes.string,
  }),
};

Checkbox.defaultProps = {
  classes: undefined,
  color: 'primary',
  onChange: identity,
  TooltipProps: {
    arrow: true,
    placement: 'top',
    title: '',
  },
};

export default Checkbox;
