import { useSelector } from 'react-redux';
import { selector } from 'selectors/fspFraudConfig';
import useDispatchLoadEffect from './useDispatchLoadEffect';
import useSelectedFSPId from '../useSelectedFSPId';

const useFSPFraudConfig = (load = false) => {
  const fspId = useSelectedFSPId();
  const fspFraudConfig = useSelector((state) => selector(state, fspId));

  useDispatchLoadEffect(fspId, load);

  return fspFraudConfig;
};

export default useFSPFraudConfig;
