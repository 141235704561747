import { createRequestConst, createAction } from 'util/actions';

export const CLEAR_RESET = createRequestConst('clearReset');
export const RESET = createRequestConst('reset');

export const clearReset = createAction(CLEAR_RESET);
export const reset = createAction(RESET);

const initialState = {
  data: {},
  error: { message: '', number: 0 },
  ran: false,
  success: false,
};

export default (state = initialState, { type, data, error }) => {
  switch (type) {
    case CLEAR_RESET.ACTION: {
      return initialState;
    }

    case RESET.SUCCESS: {
      return {
        data,
        ran: true,
        success: true,
      };
    }

    case RESET.FAILURE:
      return {
        data: {},
        error,
        ran: true,
        success: false,
      };

    default:
      return state;
  }
};
