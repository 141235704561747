import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import usePayALoanTransaction from '../../usePayALoanTransaction';

const FeeAmount = ({ transaction }) => {
  return (
    usePayALoanTransaction(transaction.transactionType) && (
      <Grid item xs={1}>
        <ReadOnlyFormControl
          label="Fee Amount"
          value={transaction.feeAmountDerived}
        />
      </Grid>
    )
  );
};

FeeAmount.propTypes = {
  transaction: PropTypes.shape({
    feeAmountDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};

export default FeeAmount;
