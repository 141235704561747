import { takeEvery } from 'redux-saga/effects';
import { loadId } from 'sdk/schedTranFraud';
import { LOAD_ID } from 'model/schedTranFraud';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadId,
      dataName: ORM,
      type: LOAD_ID,
    })
  );
}
