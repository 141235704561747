import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/common/AlertImproved';
import { useDispatch } from 'react-redux';
import { Grid, ListSubheader, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    backgroundColor: theme.palette.background.paper,
  },
  recentMenuButton: {
    marginRight: theme.spacing(1),
  },
}));

const RecentListSubheader = ({
  clear,
  clearActionType,
  clearMessage,
  items,
  subheaderText,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClear = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    dispatch(clear());
    setOpen(false);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListSubheader classes={{ root: classes.listSubheader }}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>{subheaderText}</Grid>
          <Grid item>
            <Button disabled={!items.length} onClick={handleClear} size="small">
              Clear
            </Button>
          </Grid>
        </Grid>
      </ListSubheader>
      {open && (
        <Alert
          actionType={clearActionType}
          cancelLabel="No"
          confirmLabel="Yes"
          maxWidth="xs"
          onClose={handleDialogClose}
          onConfirm={handleConfirm}
          open
          title={clearMessage}
        />
      )}
    </>
  );
};

RecentListSubheader.propTypes = {
  clear: PropTypes.func.isRequired,
  clearActionType: PropTypes.object.isRequired,
  clearMessage: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  subheaderText: PropTypes.string.isRequired,
};

export default memo(RecentListSubheader);
