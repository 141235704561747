import { and, endsWith, equals, or } from 'ramda';

const ADD_ACTION = 'ADD';
const DELETE_ACTION = 'DELETE';
const LOAD_ACTION = 'LOAD';
const REQUEST_ACTION = 'REQUEST';
const SELECT_ACTION = 'SELECT';
const UPDATE_ACTION = 'UPDATE';
const IMPORT_ACTION = 'IMPORT';
const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

export const sep = '_';

export const ACTION = 'ACTION';
export const BUSY = 'BUSY';
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';
export const UNAUTH = 'unauth';

const createConst = (model, action) => {
  const modelAction = `${model.toUpperCase()}${model ? sep : ''}${action}`;

  // check model for reserved words - ACTION, BUSY, SUCCESS, FAILURE

  return {
    ACTION: `${modelAction}${sep}${ACTION}`,
    BUSY: `${modelAction}${sep}${BUSY}`,
    SUCCESS: `${modelAction}${sep}${SUCCESS}`,
    FAILURE: `${modelAction}${sep}${FAILURE}`,
    ROOT: modelAction,
  };
};

export const addAction = `${ADD_ACTION}${sep}${ACTION}`;
const addActionSuccess = `${ADD_ACTION}${sep}${SUCCESS}`;

export const requestAction = `${REQUEST_ACTION}${sep}${ACTION}`;
const requestActionSuccess = `${REQUEST_ACTION}${sep}${SUCCESS}`;
const requestActionFailure = `${REQUEST_ACTION}${sep}${FAILURE}`;

export const loadAction = `${LOAD_ACTION}${sep}${ACTION}`;
const loadActionFailure = `${LOAD_ACTION}${sep}${FAILURE}`;
const loadActionSuccess = `${LOAD_ACTION}${sep}${SUCCESS}`;

export const updateAction = `${UPDATE_ACTION}${sep}${ACTION}`;
const updateActionFailure = `${UPDATE_ACTION}${sep}${FAILURE}`;
const updateActionSuccess = `${UPDATE_ACTION}${sep}${SUCCESS}`;

export const deleteAction = `${DELETE_ACTION}${sep}${ACTION}`;
export const deleteActionFailure = `${DELETE_ACTION}${sep}${FAILURE}`;
export const deleteActionSuccess = `${DELETE_ACTION}${sep}${SUCCESS}`;

export const importAction = `${IMPORT_ACTION}${sep}${ACTION}`;

export const startLoading = (type) =>
  or(endsWith(loadAction, type), endsWith(requestAction, type));
export const endsWithLoadSuccess = endsWith(loadActionSuccess);

export const endLoading = (type) =>
  or(
    or(endsWithLoadSuccess(type), endsWith(loadActionFailure, type)),
    or(
      endsWith(requestActionSuccess, type),
      endsWith(requestActionFailure, type)
    )
  );

export const end = (type) =>
  or(endsWith(SUCCESS, type), endsWith(FAILURE, type));

export const actionStart = (type) => endsWith(ACTION, type);
export const actionEnd = end;

export const busyStart = (type) => endsWith(BUSY, type);
export const busyEnd = end;

export const beginDelete = (type) =>
  and(!equals(DELETE_MESSAGE.ACTION, type), endsWith(deleteAction, type));

export const endDelete = (type) =>
  or(endsWith(deleteActionSuccess, type), endsWith(deleteActionFailure, type));

export const beginUpdate = (type) => endsWith(updateAction, type);

export const endUpdate = (type) =>
  or(endsWith(updateActionSuccess, type), endsWith(updateActionFailure, type));

export const addOrUpdateSuccessful = (type) =>
  or(endsWith(addActionSuccess, type), endsWith(updateActionSuccess, type));

export const deleteSuccessful = (type) => endsWith(deleteActionSuccess, type);

export const createLoadConst = (model) => createConst(model, LOAD_ACTION);

export const createAddConst = (model) => createConst(model, ADD_ACTION);

export const createUpdateConst = (model) => createConst(model, UPDATE_ACTION);

export const createDeleteConst = (model) => createConst(model, DELETE_ACTION);

export const createRequestConst = (model) => createConst(model, REQUEST_ACTION);

export const createSelectConst = (model) => createConst(model, SELECT_ACTION);

export const createImportConst = (model) => createConst(model, IMPORT_ACTION);

export const createAction =
  (action) =>
  (data = {}, form = null, meta = undefined) => ({
    busyType: action.BUSY,
    data,
    form,
    meta,
    type: action.ACTION,
  });

export const createActionSuccess =
  (action) => (data, reduxActionData, reduxActionMeta) => ({
    type: action.SUCCESS,
    data,
    reduxActionData,
    ...(reduxActionMeta && { reduxActionMeta }),
  });

export const createActionFailure = (action) => (data, reduxActionData) => ({
  type: action.FAILURE,
  data,
  reduxActionData,
});

export const ADD_MESSAGE = createAddConst(SUCCESS_MESSAGE);
export const DELETE_MESSAGE = createDeleteConst(SUCCESS_MESSAGE);
export const UNAUTHORIZED = createRequestConst(UNAUTH);
