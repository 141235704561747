import { client } from 'util/sdk';
import { GET } from 'util/api';
import { LOAN_STATEMENT_AUDIT_LOGS_CODE } from 'consts';

const returnModel = { name: LOAN_STATEMENT_AUDIT_LOGS_CODE };
// eslint-disable-next-line import/prefer-default-export
export const load = ({ fspId, stmtId, id, payerId }, auth) =>
  client.executeRequest(
    GET,
    `v2/fsps/${fspId}/payers/${payerId}/ddas/${id}/statements/${stmtId}/auditlogs`,
    {
      queryParams: { adminUserId: auth.id },
      returnModel,
    }
  );
