import { attr, Model } from 'redux-orm';
import { LOAD } from './switch';

export default class SwitchAddress extends Model {
  static get modelName() {
    return 'SwitchAddress';
  }

  static reducer({ type, data }, SwitchAddress) {
    switch (type) {
      case LOAD.SUCCESS:
        SwitchAddress.delete();
        if (data.response.switchbilleraddress) {
          data.response.switchbilleraddress.forEach((r) =>
            SwitchAddress.upsert(r)
          );
        }
        break;

      case LOAD.FAILURE:
        SwitchAddress.delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      billerId: attr(),
      billerIds: attr(), // derived
      city: attr(),
      fullZip: attr(), // derived
      id: attr(),
      name: attr(), // derived
      offset: attr(),
      state: attr(),
      street1: attr(),
      street2: attr(),
      zip: attr(),
    };
  }
}
