import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { selectorPayerId } from 'selectors/payerUser';
import { Groups } from '@material-ui/icons';
import { load, LOAD } from 'model/payerUser';
import { selector as busySelector } from 'selectors/busy';
import { selectorId } from 'selectors/payer';
import { PERSON } from 'consts';
import { useDispatch, useSelector } from 'react-redux';
import PayerDetailPanel from '../PayerDetailPanel';
import UsersList from './UsersList';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';

const Users = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const payer = useSelector((state) => selectorId(state, payerId));
  const payerUsers = useSelector((state) => selectorPayerId(state, payerId));

  useEffect(() => {
    dispatch(load({ fspId, payerId }));
  }, [dispatch, fspId, payerId]);

  return payer?.payerType !== PERSON ||
    (payer?.payerType === PERSON && payerUsers.length > 1) ? (
    <PayerDetailPanel
      fspId={fspId}
      heightOffset={HEIGHT_OFFSET}
      icon={Groups}
      id="users"
      payerId={payerId}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title="Users"
    >
      <UsersList fspId={fspId} payerId={payerId} />
    </PayerDetailPanel>
  ) : null;
};

Users.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(Users);
