import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ActionConstType } from 'types';
import { makeStyles } from '@material-ui/core/styles';
import useBusy from './useBusy';

const useStyles = makeStyles(() => ({
  root: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
}));

const Busy = ({
  CircularProgressProps,
  actionConst,
  className,
  classes: classesProp,
}) => {
  const [busy0, busy1] = useBusy(actionConst);
  const classesLocal = useStyles();
  const classes = { ...classesLocal, ...classesProp };

  return busy0 || busy1 ? (
    <CircularProgress
      className={classNames(classes.root, className)}
      {...CircularProgressProps}
    />
  ) : null;
};

Busy.propTypes = {
  CircularProgressProps: PropTypes.object,
  actionConst: PropTypes.oneOfType([
    ActionConstType,
    PropTypes.arrayOf(ActionConstType),
  ]).isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
};

Busy.defaultProps = {
  CircularProgressProps: null,
  className: null,
  classes: null,
};

export default Busy;
