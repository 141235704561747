import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import PaymentSeriesTableCell from './PaymentSeriesTableCell';

const PaymentSeriesTableRow = ({ index, item }) => {
  return (
    <TableRow data-testid="row" hover>
      {/* eslint-disable-next-line no-plusplus */}
      <PaymentSeriesTableCell>{++index}</PaymentSeriesTableCell>
      <PaymentSeriesTableCell>{item.sendOnDerived}</PaymentSeriesTableCell>
      <PaymentSeriesTableCell>{item.deliverOnDerived}</PaymentSeriesTableCell>
      <PaymentSeriesTableCell>{item.amountDerived}</PaymentSeriesTableCell>
    </TableRow>
  );
};

PaymentSeriesTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default PaymentSeriesTableRow;
