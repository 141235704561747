import PropTypes from 'prop-types';
import {
  compose,
  withState,
  withHandlers,
  branch,
  renderComponent,
  withProps,
  lifecycle,
} from 'recompose';
import {
  always,
  compose as rCompose,
  defaultTo,
  filter,
  find,
  flip,
  equals,
  includes,
  isNil,
  map,
  not,
  prop,
  propEq,
} from 'ramda';
import {
  Input,
  MenuItem,
  Select as MuiSelect,
  Checkbox,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import ReadOnlyField from './ReadOnly';

const isEmpty = (value) => value === [] || value === '';

const ConnectedReadonlyField = withProps(
  ({ data, renderDisplay, valueAttr, value }) => ({
    value: renderDisplay(
      !value || isEmpty(value)
        ? Array.isArray(data) && data.length === 1
          ? data[0][valueAttr]
          : null
        : value
    ),
  })
)(ReadOnlyField);

const styles = () => ({
  root: {
    width: 400,
  },
  empty: {
    color: '#777',
    fontStyle: 'italic',
  },
  listItemTextPrimary: {
    // because we overrode it in theme/index
    fontSize: 16,
  },
});

const Select = ({
  classes,
  data,
  disabled,
  getDisplay,
  handleChange,
  handleClick,
  handleClose,
  handleOpen,
  id,
  isMulti,
  name,
  open,
  placeholder,
  renderDisplay,
  selectedValue,
  valueAttr,
}) => {
  return (
    <MuiSelect
      disabled={disabled}
      displayEmpty={placeholder && isEmpty(selectedValue)}
      input={<Input id={id} name={name} />}
      multiple={isMulti}
      onChange={handleChange}
      onClick={handleClick}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      renderValue={renderDisplay}
      t-i="MuiSelect"
      value={selectedValue}
    >
      {placeholder && isEmpty(selectedValue) && (
        <MenuItem value="">
          <span className={classes.empty}>{placeholder}</span>
        </MenuItem>
      )}
      {data.map((item) => (
        <MenuItem key={item.key} value={item[valueAttr]}>
          {isMulti && (
            <Checkbox
              checked={selectedValue.indexOf(item[valueAttr]) > -1}
              color="primary"
            />
          )}
          <ListItemText primary={getDisplay(item)} />
        </MenuItem>
      ))}
    </MuiSelect>
  );
};

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  getDisplay: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  id: PropTypes.string,
  isMulti: PropTypes.bool.isRequired,
  name: PropTypes.string,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  renderDisplay: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  valueAttr: PropTypes.string,
};

Select.defaultProps = {
  disabled: false,
  id: '',
  open: false,
  name: '',
  placeholder: null,
  valueAttr: null,
};

export default compose(
  withProps(({ data, onClose = always(), onOpen = always(), type }) => ({
    data: data && data.length > 0 && data[0] !== null ? data : [],
    isMulti: equals(type, 'multiple'),
    onClose,
    onOpen,
  })),
  withState('open', 'setOpen', ({ open }) => open),
  withState('selectedValue', 'setSelectedValue', ({ value, isMulti }) =>
    isMulti ? (Array.isArray(value) ? value : []) : defaultTo('', value)
  ),
  withHandlers({
    handleChange:
      ({ onChange, setSelectedValue }) =>
      (event) => {
        event.stopPropagation();
        setSelectedValue(event.target.value);
        if (onChange) onChange(event);
      },
    handleClick: () => (event) => event.stopPropagation(),
    handleClose:
      ({ onClose, setOpen }) =>
      (event) => {
        setOpen(false);
        onClose(event);
      },
    handleOpen:
      ({ onOpen, setOpen }) =>
      (event) => {
        setOpen(true);
        onOpen(event);
      },
    getDisplay:
      ({ displayAttr, renderer }) =>
      (item) =>
        isNil(item)
          ? ''
          : displayAttr
          ? item[displayAttr]
          : renderer
          ? renderer(item)
          : '',
  }),
  withHandlers({
    renderDisplay:
      ({ getDisplay, isMulti, valueAttr, data }) =>
      (selected) =>
        selected
          ? isMulti
            ? map(
                getDisplay,
                filter(
                  rCompose(flip(includes)(selected), prop(valueAttr)),
                  data
                )
              ).join(', ')
            : getDisplay(find(propEq(valueAttr, selected), data))
          : '',
  }),
  branch(
    ({ data, selectOnly, readOnly }) =>
      readOnly || (data.length === 1 && !selectOnly),
    renderComponent(ConnectedReadonlyField)
  ),
  withStyles(styles),
  lifecycle({
    componentDidMount() {
      const { onOpen, open } = this.props;

      if (open) {
        onOpen();
      }
    },
    UNSAFE_componentWillReceiveProps({ value }) {
      const { value: oldValue, setSelectedValue, isMulti } = this.props;

      if (not(equals(value, oldValue))) {
        if (isMulti) {
          setSelectedValue(defaultTo([], value));
        } else {
          setSelectedValue(defaultTo('', value));
        }
      }
    },
  })
)(Select);
