import AutocompleteChannel from 'components/common/Autocomplete/Channel';
import AutocompleteDistributor from 'components/common/Autocomplete/Distributor';
import AutocompleteFsp from 'components/common/Autocomplete/Fsp';
import { CHANNEL_CODE, DISTRIBUTOR_CODE, FSP_CODE } from 'consts';

const useAutocompleteComponent = ({ type, ...props }) => {
  switch (type) {
    case FSP_CODE:
      return <AutocompleteFsp required {...props} />;
    case CHANNEL_CODE:
      return <AutocompleteChannel required {...props} />;
    case DISTRIBUTOR_CODE:
      return <AutocompleteDistributor required {...props} />;
    default:
      return null;
  }
};

export default useAutocompleteComponent;
