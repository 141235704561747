import { client } from 'util/sdk';
import { omit, pick } from 'ramda';
import { GET, POST } from 'util/api';
import {
  DENYLIST_OFF_ON,
  FRAUD_MONITOR_CODE,
  FRAUD_OFF_ON,
  FSPS,
  FSP_FRAUD_CONFIGS_CODE,
  FSP_FRAUD_CONFIG_CODE,
} from 'consts';
import {
  CANCEL_SCORE_OFF_ON,
  CANCEL_SUSPEND_SCORE_OFF_ON,
} from '../components/modules/implementation/Fsp/Detail/FraudMonitor/ClientFacing/ScoreThresholds/consts';

const keys = [FRAUD_MONITOR_CODE, FRAUD_OFF_ON];
const omittedKeys = [CANCEL_SCORE_OFF_ON, CANCEL_SUSPEND_SCORE_OFF_ON];
const returnModel = { name: FSP_FRAUD_CONFIG_CODE };

const dataToUpdate = (data) => {
  return pick(keys, {
    ...data,
    [FRAUD_MONITOR_CODE]: omit(omittedKeys, data[FRAUD_MONITOR_CODE]),
  });
};

const loadURL = (fspId) => `/${FSPS}/${fspId}/${FSP_FRAUD_CONFIGS_CODE}`;
const updateURL = (fspId, id) => `${loadURL(fspId)}/${id}`;

export const load = (fspId) =>
  client.executeRequest(GET, loadURL(fspId), { returnModel });

export const update = ({ fspId, id, ...data }, auth) =>
  client.executeRequest(POST, updateURL(fspId, id), {
    data: dataToUpdate(data),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
