import { CHANNEL_ID, CHANNEL_NAME_CODE } from 'consts';

export const filters = [
  {
    key: 0,
    highlight: false,
    label: 'CHANNEL ID',
    value: CHANNEL_ID,
    placeholder: 'Type in the full ID',
  },
  {
    key: 1,
    highlight: false,
    label: 'CHANNEL NAME',
    value: CHANNEL_NAME_CODE,
    placeholder: 'Type in the channel',
  },
];
export const defaultFilter = filters[0];

export const getOptionLabel = (channel) => {
  const id = channel && channel?.id ? `(${channel.id})` : '';

  return channel ? `${channel.name ?? ''} ${id}` : '';
};
