import { find, findIndex, flatten, isNil, propEq } from 'ramda';
import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import addDerived from './util/limitRule';

export const fields = {
  availableDailyLimits: attr(),
  availableMonthlyLimits: attr(),
  availableWeeklyLimits: attr(),
  createdAt: attr(),
  dailyLimits: attr(),
  description: attr(),
  expireDateDerived: attr(), // derived
  expireDateDisplayDerived: attr(), // derived
  expireMonth: attr(), // only for create/update (POST) - value is not returned - we calculate from inServiceLessThanEqualToMonths
  expireYear: attr(), // only for create/update (POST) - value is not returned - we calculate from inServiceLessThanEqualToMonths
  formChangedDerived: attr(), // derived
  fsp: attr(),
  fspId: attr(),
  href: attr(),
  id: attr(),
  inServiceLessThanEqualToMonths: attr(),
  limit: attr(),
  limitId: attr(),
  limitTypeDerived: attr(), // derived
  maximumTransaction: attr(),
  minimumTransaction: attr(),
  monthlyLimits: attr(),
  offset: attr(),
  rowCountDerived: attr(), // derived
  updatedAt: attr(),
  updatedAtDerived: attr(), // derived
  weeklyLimits: attr(),
};

export default class LimitRule extends Model {
  static get modelName() {
    return 'LimitRule';
  }

  static reducer({ type, data }, LimitRule, { Limit }) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        LimitRule.create(addDerived(data?.response));
        break;

      case ADD_CUSTOM.SUCCESS: {
        const limit = Limit.first();
        const limitRule = data.response;

        limit.set('newIdDerived', limitRule.limitId);
        limit.set('refreshDerived', true);

        Limit.update(limit);
        LimitRule.upsert(limitRule);
        break;
      }

      case REMOVE.SUCCESS:
        if (LimitRule.idExists(id)) {
          LimitRule.withId(id).delete();
        }
        break;

      case REMOVE_CUSTOM.SUCCESS: {
        const limit = Limit.first();

        limit.set('refreshDerived', true);

        Limit.update(limit);
        LimitRule.delete();
        break;
      }

      case LOAD.SUCCESS:
      case IMPORT.SUCCESS:
        if (data?.response) {
          LimitRule.delete();
          data.response.forEach((r) => LimitRule.create(addDerived(r)));
        }
        break;

      case LOAD_CUSTOM.SUCCESS: {
        const limit = Limit.first();

        limit.set('newIdDerived', undefined);
        limit.set('refreshDerived', undefined);
        limit.set('rowCountDerived', data?.response || 0);

        Limit.update(limit);
        LimitRule.delete();
        LimitRule.upsert({ id: 1, ...data.response });
        break;
      }

      case UPDATE.SUCCESS:
        LimitRule.upsert(addDerived(data?.response));
        break;

      case UPDATE_CUSTOM.ACTION:
        if (LimitRule.idExists(data.id)) {
          const limitRule = LimitRule.withId(data.id);
          const { formChangedDerived, fieldError } = data;

          // We use the LimitRule object as a state machine for the form
          // Handle that logic here until we convert this to use formik
          // https://payrailz.atlassian.net/browse/ON-577
          if (
            formChangedDerived === undefined ||
            formChangedDerived === false
          ) {
            limitRule.set('formChangedDerived', true);
          } else if (formChangedDerived === null) {
            limitRule.set('formChangedDerived', false);
          }

          if (!isNil(fieldError)) {
            const { error = {} } = limitRule;
            const { field: newField, product, invalid } = fieldError;

            const productErrors = error[product] || [];
            const index = findIndex(propEq('field', newField), productErrors);
            if (index === -1) {
              productErrors.push({ field: newField, invalid });
            } else {
              productErrors.splice(index, 1, { field: newField, invalid });
            }
            error[product] = productErrors;
            limitRule.set('error', error);

            const invalidField = find(
              propEq('invalid', true),
              flatten(Object.values(error))
            );
            limitRule.set('formError', !isNil(invalidField));
          }

          LimitRule.withId(data.id).update(limitRule);
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return fields;
  }
}

const name = LimitRule.modelName;

export const ADD = createAddConst(name);
export const ADD_CUSTOM = createAddConst(`${name}_CUSTOM`);
export const LOAD = createRequestConst(name);
export const LOAD_CUSTOM = createRequestConst(`${name}_CUSTOM`);
export const REMOVE = createDeleteConst(name);
export const REMOVE_CUSTOM = createDeleteConst(`${name}_CUSTOM`);
export const UPDATE = createUpdateConst(name);
export const UPDATE_CUSTOM = createUpdateConst(`${name}_CUSTOM`);
export const IMPORT = createImportConst(name);

export const add = createAction(ADD);
export const addCustom = createAction(ADD_CUSTOM);
export const load = createAction(LOAD);
export const loadCustom = createAction(LOAD_CUSTOM);
export const remove = createAction(REMOVE);
export const removeCustom = createAction(REMOVE_CUSTOM);
export const update = createAction(UPDATE);
export const updateCustom = createAction(UPDATE_CUSTOM);
export const importAction = createAction(IMPORT);
