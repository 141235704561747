import { Grid } from '@material-ui/core';
import { useOpen } from 'hooks';
import ErrorMessageButton from './ErrorMessageButton';
import ErrorMessageCollapse from './ErrorMessageCollapse';

const text = "Sorry, but we've had an error";

const ErrorMessage = () => {
  const [expanded, onClick] = useOpen();

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>{text}</Grid>
          <Grid item>
            <ErrorMessageButton expanded={expanded} onClick={onClick} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ErrorMessageCollapse in={expanded} />
      </Grid>
    </Grid>
  );
};

export default ErrorMessage;
