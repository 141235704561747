import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, payerId) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).externalAccountsIAV.all().toRefArray()
      : []
);

export const selectorId = createSelector(orm.ExternalAccountIAV);
