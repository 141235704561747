import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { selector } from 'selectors/busy';

const useSubmitButtonDisabled = (actionType = {}) => {
  const isBusy = useSelector((state) => selector(state, actionType));
  const { dirty, isSubmitting } = useFormikContext();

  return isBusy || isSubmitting || !dirty;
};

export default useSubmitButtonDisabled;
