import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  progress: {
    height: 2,
    left: 0,
    top: 1,
    position: 'absolute',
    width: '100%',
  },
}));

const Progress = ({ expanded, showProgress }) => {
  const classes = useStyles();

  return expanded && showProgress ? (
    <LinearProgress
      classes={{ root: classes.progress }}
      data-testid="progress"
    />
  ) : null;
};

Progress.propTypes = {
  expanded: PropTypes.bool.isRequired,
  showProgress: PropTypes.bool.isRequired,
};

export default Progress;
