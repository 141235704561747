import {
  CLAIM,
  CREATE,
  LOAD,
  LOAD_ASSIGNED,
  LOAD_HIST_ID,
  LOAD_ID,
  UPDATE,
} from 'model/paymentResearch';
import {
  claim,
  create,
  load,
  loadAssigned,
  loadByHistId,
  loadById,
  update,
} from 'sdk/paymentResearch';
import { takeEvery, put } from 'redux-saga/effects';
import { ADD_MESSAGE } from 'util/actions';
import { callAction, ORM } from '../util';

const claimViewDetails = function* named({ data }) {
  yield put({
    type: ADD_MESSAGE.ACTION,
    data: `Payment research ${data?.response?.id} claimed`,
  });
};

const claimShowError = function* named() {
  yield put({
    type: ADD_MESSAGE.ACTION,
    data: 'There are no unassigned Payment Research Requests',
  });
};

export default function* saga() {
  yield takeEvery(
    CLAIM.ACTION,
    callAction({
      api: claim,
      dataName: ORM,
      type: CLAIM,
    })
  );

  yield takeEvery(CLAIM.SUCCESS, claimViewDetails);

  yield takeEvery(CLAIM.FAILURE, claimShowError);

  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Payment Research #${response.id} has been created`,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: load, dataName: ORM, type: LOAD })
  );

  yield takeEvery(
    LOAD_ASSIGNED.ACTION,
    callAction({ api: loadAssigned, dataName: ORM, type: LOAD_ASSIGNED })
  );

  yield takeEvery(
    LOAD_HIST_ID.ACTION,
    callAction({ api: loadByHistId, dataName: ORM, type: LOAD_HIST_ID })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({ api: loadById, dataName: ORM, type: LOAD_ID })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Payment Research #${response.id} has been updated`,
      type: UPDATE,
    })
  );
}
