import { ACTIVE, DEFAULT_MODEL_REF, DEFAULT_MODEL_REF_ARRAY, ON } from 'consts';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, payerId) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).internalAccounts.all().toRefArray()
      : DEFAULT_MODEL_REF_ARRAY
);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId, id }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId)
          .internalAccounts.filter((item) => item.id === id)
          .first()?.ref ?? DEFAULT_MODEL_REF
      : DEFAULT_MODEL_REF
);

export const selectorIdNoPayer = createSelector(
  orm,
  (state, props) => props,
  ({ InternalAccount }, id) =>
    InternalAccount.idExists(id)
      ? InternalAccount.withId(id).ref
      : DEFAULT_MODEL_REF
);

export const selectorDDAId = createSelector(orm.InternalAccount);

export const selectorMergePayerBillPay = createReselectSelector(
  selector,
  (items) =>
    items.filter(
      (item) => item.ddaBillPayFromOffOn === ON && item.ddaStatus === ACTIVE
    )
);

export const selectorMergePayerP2P = createReselectSelector(selector, (items) =>
  items.filter(
    (item) => item.ddaP2PPayFromOffOn === ON && item.ddaStatus === ACTIVE
  )
);

export const selectorMergePayerA2AFrom = createReselectSelector(
  selector,
  (items) =>
    items.filter(
      (item) => item.ddaTransferFromOffOn === ON && item.ddaStatus === ACTIVE
    )
);

export const selectorMergePayerA2ATo = createReselectSelector(
  selector,
  (items) =>
    items.filter(
      (item) => item.ddaTransferToOffOn === ON && item.ddaStatus === ACTIVE
    )
);

export const selectorLoans = createReselectSelector(selector, (items) => {
  return items.filter((item) => item.ddaType === 'loan');
});

export const selectorCount = createSelector(orm, ({ InternalAccount }) =>
  InternalAccount.count()
);
