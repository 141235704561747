import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AuditButtonGroup from '../AuditButtonGroup';
import PaymentResearchCreate from '../PaymentResearchCreate';
import PaymentResearchView from '../PaymentResearchView';
import UpdatePayment from '../UpdatePayment';
import UpdatePaymentStatus from '../UpdatePaymentStatus';

const ActionsRenderer = ({ item }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Grid container wrap="nowrap">
          <Grid item>
            <UpdatePaymentStatus id={item.id} />
          </Grid>
          {(item.trrCount || 0) > 0 ? (
            <Grid item>
              <PaymentResearchView id={item.id} />
            </Grid>
          ) : (
            <Grid item>
              <PaymentResearchCreate transaction={item} />
            </Grid>
          )}
          <Grid item>
            <UpdatePayment id={item.id} />
          </Grid>
          <Grid item>
            <AuditButtonGroup item={item} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
