import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  slide: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.25),
  },
}));

const MultipleSelectedSlider = ({
  children,
  onEntering,
  onExit,
  onExited,
  show,
}) => {
  const classes = useStyles();

  return (
    <Slide
      className={classes.slide}
      in={show}
      mountOnEnter={false}
      onEntering={onEntering}
      onExit={onExit}
      onExited={onExited}
      unmountOnExit
    >
      {children}
    </Slide>
  );
};

MultipleSelectedSlider.propTypes = {
  children: PropTypes.node.isRequired,
  onEntering: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default MultipleSelectedSlider;
