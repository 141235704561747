import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Box, Button, makeStyles } from '@material-ui/core';
import { ButtonWithCircularProgress } from 'components/common';
import { updateStatement, UPDATE_STATEMENT } from 'model/eBillAccount';
import { useDispatch } from 'react-redux';
import { decimal } from 'util/format';
import { useItemUpdatedEffect } from 'hooks';
import TextFieldCurrency from 'components/common/formik/TextFieldCurrency';
import DateField from 'components/common/formik/DateField';
import useSubmitButtonDisabled from 'components/common/formik/hooks/useSubmitButtonDisabled';
import useIsBusy from 'hooks/useIsBusy';
import { ReadOnlyFormControl } from 'components/common/fields';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  field: {
    width: '100%',
    maxWidth: 'none',
    marginBottom: theme.spacing(2),
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.amount) {
    errors.amount = 'This field is required';
  }

  if (!values.dueOn) {
    errors.dueOn = 'This field is required';
  }
  return errors;
};

const EditStatementForm = ({
  onClose,
  amountFieldConfig,
  isCreditCardAccountType,
  amountDue,
}) => {
  const classes = useStyles();
  const saveDisabled = useSubmitButtonDisabled(UPDATE_STATEMENT);
  const isBusy = useIsBusy(UPDATE_STATEMENT);

  return (
    <Form>
      <DateField className={classes.field} label="Due On" name="dueOn" />
      <TextFieldCurrency
        className={classes.field}
        disabled={isBusy}
        label={amountFieldConfig.label}
        name="amount"
      />
      <Grid container item xs={12}>
        {isCreditCardAccountType && (
          <Grid item sm={6} xs={12}>
            <ReadOnlyFormControl label="Balance" value={amountDue} />
          </Grid>
        )}
      </Grid>

      <Box display="flex">
        <Button fullWidth onClick={onClose}>
          Cancel
        </Button>
        <ButtonWithCircularProgress
          busy={isBusy}
          color="primary"
          disabled={saveDisabled}
          fullWidth
          type="submit"
          variant="contained"
        >
          Save
        </ButtonWithCircularProgress>
      </Box>
    </Form>
  );
};
EditStatementForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  amountFieldConfig: PropTypes.object.isRequired,
  isCreditCardAccountType: PropTypes.bool.isRequired,
  amountDue: PropTypes.string.isRequired,
};

const DetailStatementEdit = ({
  isCreditCardAccountType,
  fspId,
  statement,
  onClose,
}) => {
  const dispatch = useDispatch();

  useItemUpdatedEffect(statement, onClose, statement);

  const amountFieldConfig = isCreditCardAccountType
    ? {
        fieldName: 'minimumAmount',
        label: 'Minimum Amount',
      }
    : {
        fieldName: 'amountDue',
        label: 'Amount due',
      };

  const statementId = statement.id;

  const initialValues = {
    dueOn: statement.dueOn,
    amount: statement[amountFieldConfig.fieldName].toFixed(2),
  };

  const handleSubmit = (values) => {
    const changes = {
      dueOn: values.dueOn,
      [amountFieldConfig.fieldName]: decimal(values.amount),
    };
    dispatch(updateStatement({ fspId, statementId, ...changes }));
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      <EditStatementForm
        amountFieldConfig={amountFieldConfig}
        onClose={onClose}
        isCreditCardAccountType={isCreditCardAccountType}
        amountDue={statement.amountDueView}
      />
    </Formik>
  );
};

DetailStatementEdit.propTypes = {
  isCreditCardAccountType: PropTypes.bool.isRequired,
  fspId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  statement: PropTypes.object.isRequired,
};

export default DetailStatementEdit;
