import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';
import {
  GENERATED_FRACTIONAL_RTN,
  FSPRTN,
  FSPRTNS,
  FSPS,
  RESET_FSP_RTNS,
} from 'consts';
import { pick } from 'ramda';

const fractionalRtnKeys = ['micrRtn', 'city', 'state'];

// Do not change the order of fields because the api requires that fields are sent
// in this particular order when it comes to reset endpoints
const keys = ['achRtn', 'micrRtn', 'fractRtn', 'wireRtn'];
const returnModel = { name: FSPRTN };

export const add = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${FSPRTNS}`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const load = ({ fspId }) =>
  client.executeRequest(GET, `/${FSPS}/${fspId}/${FSPRTNS}`, {
    returnModel,
  });

export const loadFractionalNumber = (data) => {
  return client.executeRequest(POST, `/generatefractionalrtn`, {
    data: pick(fractionalRtnKeys, data),
    returnModel: {
      name: GENERATED_FRACTIONAL_RTN,
    },
  });
};

export const remove = ({ id, fspId }, auth) =>
  client.executeRequest(DELETE, `/${FSPS}/${fspId}/${FSPRTNS}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const update = ({ id, fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${FSPRTNS}/${id}`, {
    data: pick(keys, data),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const importAndReset = ({ fspId, fsprtn }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${RESET_FSP_RTNS}`, {
    data: { [FSPRTN]: fsprtn.map((r) => pick(keys, r)) },
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
