import PropTypes from 'prop-types';
import { Card, CardHeader } from '@material-ui/core';
import CardAddressContent from './CardAddressContent';

const CardAddress = ({ subheader, title, uspsAddress }) => {
  return (
    <Card elevation={0}>
      <CardHeader subheader={subheader} />
      <CardAddressContent title={title} uspsAddress={uspsAddress} />
    </Card>
  );
};

CardAddress.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
  uspsAddress: PropTypes.object,
};

CardAddress.defaultProps = {
  subheader: '',
  title: 'Address',
  uspsAddress: null,
};

export default CardAddress;
