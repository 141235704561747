import PaymentResearch from 'model/paymentResearch';
import { attr, fk, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

export const sortableFields = [
  {
    name: 'Updated At',
    value: 'updatedAt',
    query: ['orderByUpdatedAt', 'desc'],
  },
  {
    name: 'Created At',
    value: 'createdAt',
    query: ['orderByCreatedAt', 'desc'],
  },
];

export default class ResearchNote extends Model {
  static get modelName() {
    return 'ResearchNote';
  }

  static reducer({ type, data }, ResearchNote, { PaymentResearch }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (PaymentResearch.idExists(data.props.payerId)) {
          PaymentResearch.withId(data.props.payerId).researchNotes.delete();
        }
        if (data.response) {
          data.response.forEach((r) => {
            r.createdAtDerived = dateAndTime(r.createdAt);

            ResearchNote.upsert(r);
          });
        }
        break;

      case CREATE.SUCCESS:
        ResearchNote.create(data.response);
        break;

      case UPDATE.SUCCESS:
        ResearchNote.withId(data.response.id).update(data.response);
        break;

      case REMOVE.SUCCESS:
        ResearchNote.withId(data.response.id).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      createdAtDerived: attr(), // derived
      fsp: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insAdminUserLogin: attr(),
      insAdminUserName: attr(),
      insUserId: attr(),
      insUserName: attr(),
      note: attr(),
      noteType: attr(),
      payer: attr(),
      payerId: attr(),
      transactionresearch: attr(),
      trrId: fk(PaymentResearch.modelName, 'researchNotes'),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const CREATE = createAddConst(ResearchNote.modelName);
export const LOAD = createRequestConst(ResearchNote.modelName);
export const REMOVE = createDeleteConst(ResearchNote.modelName);
export const UPDATE = createUpdateConst(ResearchNote.modelName);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
