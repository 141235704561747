import PropTypes from 'prop-types';
import { Grow, Popper as MuiPopper } from '@material-ui/core';
import PopperPaper from './PopperPaper';

const popperStyle = { zIndex: 1200 };

const Popper = ({ anchorEl, onClose, open, PopperProps, ...props }) => {
  return (
    <MuiPopper
      {...PopperProps}
      anchorEl={anchorEl}
      disablePortal
      open={open}
      style={popperStyle}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <PopperPaper {...props} anchorEl={anchorEl} onClickAway={onClose} />
        </Grow>
      )}
    </MuiPopper>
  );
};

Popper.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  PopperProps: PropTypes.object.isRequired,
};

Popper.defaultProps = {
  anchorEl: null,
};

export default Popper;
