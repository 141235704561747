import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { ListItemText, MenuItem } from '@material-ui/core';
import { NEW_TAB } from 'consts';

const Product = forwardRef(({ name, onClick, url }, ref) => {
  const handleClick = (event) => {
    window.open(url, NEW_TAB);
    onClick(event);
  };

  return (
    <MenuItem ref={ref} onClick={handleClick}>
      <ListItemText primary={name} />
    </MenuItem>
  );
});

Product.displayName = 'Product';

Product.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default Product;
