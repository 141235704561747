import { client } from 'util/sdk';
import { pick } from 'ramda';
import { GET, POST, DELETE } from 'util/api';
import {
  FSPS,
  FRAUD_FSP_OVERRIDES,
  FRAUD_FSP_OVERRIDE,
  TRANSACTION_TYPE,
  DELIVER_CODE,
} from 'consts';
import {
  CANCEL_SCORE_VALUE,
  SCHEDULE_SCORE_VALUE,
  CANCEL_SUSPEND_SCORE_VALUE,
  FINAL_CANCEL_SCORE_VALUE,
} from '../components/modules/implementation/Fsp/Detail/FraudMonitor/ClientFacing/ScoreThresholds/consts';
import {
  MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW,
  MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW,
} from '../components/modules/implementation/Fsp/Detail/FraudMonitor/ClientFacing/ManualReviewDurations/consts';

const keys = [
  TRANSACTION_TYPE,
  DELIVER_CODE,
  MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW,
  CANCEL_SCORE_VALUE,
  SCHEDULE_SCORE_VALUE,
  CANCEL_SUSPEND_SCORE_VALUE,
  FINAL_CANCEL_SCORE_VALUE,
  MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW,
];
const returnModel = { name: FRAUD_FSP_OVERRIDE };

const loadURL = (fspId) => `/${FSPS}/${fspId}/${FRAUD_FSP_OVERRIDES}`;
const removeOrUpdateURL = (fspId, id) => `${loadURL(fspId)}/${id}`;

export const load = (fspId) =>
  client.executeRequest(GET, loadURL(fspId), { returnModel });

export const update = ({ fspId, id, ...data }, auth) =>
  client.executeRequest(POST, removeOrUpdateURL(fspId, id), {
    data: pick(keys, data),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const create = ({ fspId, id, ...data }, auth) =>
  client.executeRequest(POST, loadURL(fspId), {
    data: pick(keys, data),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const remove = ({ id, fspId }, auth) => {
  return client.executeRequest(DELETE, removeOrUpdateURL(fspId, id), {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
};
