import {
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UpdatePaymentStatusContentControl from './UpdatePaymentStatusContentControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
  grid: {
    marginTop: theme.spacing(1),
  },
}));

const UpdatePaymentStatusContent = (props) => {
  const classes = useStyles();

  return (
    <>
      <DialogContentText>
        Once the Payment Status is updated it&apos;s final.
      </DialogContentText>
      <Grid className={classes.grid} container justifyContent="center">
        <Grid item>
          <FormControl className={classes.formControl} required>
            <InputLabel htmlFor="reason" id="status-label">
              Status
            </InputLabel>
            <UpdatePaymentStatusContentControl {...props} />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdatePaymentStatusContent;
