import PropTypes from 'prop-types';
import { memo } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(2),
  },
  typography: {
    color: theme.palette.action.active,
    whiteSpace: 'pre-wrap',
  },
}));

const Disclaimer = ({ content }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.typography} noWrap variant="body2">
        <sup>1</sup> {content}
      </Typography>
    </Paper>
  );
};

Disclaimer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default memo(Disclaimer);
