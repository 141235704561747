import Select from 'components/common/fields/Select';
import { DDA_ACCOUNT_NUMBER } from 'consts';
import { Mask, MaskPopover } from 'components/common';
import {
  equalOperators,
  equalPhoneOperators,
  stringOperatorsSdk,
  ssnMaskOperators,
} from 'types/search_by';
import { formatPhone, formatSSN, formatTaxId } from 'util/format';
import { path } from 'ramda';
import { withProps } from 'recompose';
import { decryptFspPayerId } from 'sdk/payer';

export const defaultPayerType = 'person';

const cityMeta = {
  id: 'city',
  label: 'City',
  numeric: false,
  disablePadding: false,
  sortId: 'city',
};

const ddaAccountNumber = {
  field: { name: 'Internal Account Number', value: DDA_ACCOUNT_NUMBER },
  operators: equalOperators,
};

const payerIdFSP = {
  field: { name: 'FSP Payer Id', value: 'fspPayerId' },
  operators: equalOperators,
};

const payerIdOntrac = {
  field: { name: 'Ontrac Payer Id', value: 'id' },
  operators: equalOperators,
};

const statusField = {
  field: { name: 'Status', value: 'status' },
  operators: [
    {
      name: 'is equal to',
      value: 'field',
      component: withProps(() => ({
        displayAttr: 'name',
        valueAttr: 'value',
        data: [
          { name: 'none', value: '' },
          { name: 'Active', value: 'active' },
          { name: 'Inactive', value: 'inactive' },
          { name: 'Suspended', value: 'suspended' },
        ],
      }))(Select),
    },
  ],
};

const stateMeta = {
  id: 'state',
  label: 'State',
  numeric: false,
  disablePadding: false,
  sortId: 'state',
};

const statusMeta = {
  id: 'status',
  label: 'Status',
  numeric: false,
  disablePadding: false,
  width: 100,
  sortId: 'status',
};

const renderTaxId = (fspId, payerId, taxId, auth, api, format) => {
  if (typeof taxId === 'string') {
    return (
      <Mask
        api={() => api({ fspId, payerId }, auth)}
        format={format}
        icon={false}
        value={taxId}
      />
    );
  }
  return '';
};

const renderFSPPayerId = (fspId, payerId, value, auth) =>
  typeof payerId === 'number' ? (
    <MaskPopover
      api={() => decryptFspPayerId({ fspId, payerId }, auth)}
      icon={false}
      value={value}
    />
  ) : (
    ''
  );

export const personColumnMeta = (fspId, api, auth) => [
  {
    id: 'fspPayerIdMask',
    label: 'FSP Payer Id',
    renderer: (value, id) => renderFSPPayerId(fspId, id, value, auth),
  },
  {
    id: 'firstName',
    label: 'First Name',
    numeric: false,
    disablePadding: false,
    renderer: (data) => path(['person', 'firstName'], data),
    sortId: 'firstName',
  },
  {
    id: 'lastName',
    label: 'Last Name',
    numeric: false,
    disablePadding: false,
    renderer: (data) => path(['person', 'lastName'], data),
    sortId: 'lastName',
  },
  {
    id: 'taxIdMask',
    label: 'SSN',
    sortId: 'taxIdMask',
    renderer: (taxId, id) =>
      renderTaxId(fspId, id, taxId, auth, api, formatSSN),
    disablePadding: false,
  },
  cityMeta,
  stateMeta,
  {
    id: 'payerMobilePhone',
    label: 'Mobile #',
    numeric: false,
    disablePadding: false,
    sortId: 'payerMobilePhone',
    renderer: (data) => formatPhone(path(['person', 'mobilePhone'], data)),
  },
  {
    id: 'payerEmail',
    label: 'Email',
    numeric: false,
    disablePadding: false,
    sortId: 'payerEmail',
    renderer: (data) => path(['person', 'email'], data),
  },
  statusMeta,
];

export const businessColumnMeta = (fspId, api, auth) => [
  {
    id: 'fspPayerIdMask',
    disablePadding: false,
    label: 'FSP Payer Id',
    renderer: (value, id) => renderFSPPayerId(fspId, id, value, auth),
  },
  {
    id: 'orgName',
    label: 'Org Name',
    numeric: false,
    disablePadding: false,
    sortId: 'orgName',
    renderer: (data) => data.organization.name,
  },
  {
    id: 'taxIdMask',
    label: 'Tax Id',
    sortId: 'taxIdMask',
    renderer: (taxId, id) =>
      renderTaxId(fspId, id, taxId, auth, api, formatTaxId),
    disablePadding: false,
  },
  cityMeta,
  stateMeta,
  {
    id: 'orgPhone',
    label: 'Phone #',
    numeric: false,
    disablePadding: false,
    sortId: 'orgPhone',
    renderer: (data) => formatPhone(path(['organization', 'phone'], data)),
  },
  {
    id: 'orgEmail',
    label: 'Email',
    numeric: false,
    disablePadding: false,
    sortId: 'orgEmail',
    renderer: (data) => path(['organization', 'email'], data),
  },
  statusMeta,
];

export const personSearchFields = [
  payerIdFSP,
  payerIdOntrac,
  {
    field: { name: 'Person First Name', value: 'firstName' },
    operators: stringOperatorsSdk,
  },
  {
    field: { name: 'Person Last Name', value: 'lastName' },
    operators: stringOperatorsSdk,
  },
  {
    field: { name: 'Mobile #', value: 'mobilePhone' },
    operators: equalPhoneOperators,
  },
  {
    field: { name: 'SSN Mask', value: 'SSNMask' },
    operators: ssnMaskOperators,
  },
  {
    field: { name: 'Email Address', value: 'allEmail' },
    operators: equalOperators,
  },
  statusField,
  ddaAccountNumber,
];

export const businessSearchFields = [
  payerIdFSP,
  payerIdOntrac,
  {
    field: { name: 'FSP User Id', value: 'userId' },
    operators: equalOperators,
  },
  {
    field: { name: 'Organization Name', value: 'orgName' },
    operators: stringOperatorsSdk,
  },
  {
    field: { name: 'Tax Id Mask', value: 'SSNMask' },
    operators: ssnMaskOperators,
  },
  {
    field: { name: 'Email Address', value: 'allEmail' },
    operators: equalOperators,
  },
  statusField,
  ddaAccountNumber,
];

export const payerTypeMeta = [
  { label: 'Person', value: 'person' },
  { label: 'Business', value: 'business' },
];
