import LinearProgress from '@material-ui/core/LinearProgress';
import { ActionConstType } from 'types';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBusy } from 'hooks';

const useStyles = makeStyles((theme) => ({
  div: {
    height: theme.spacing(0.5),
  },
  progress: {
    width: '100%',
  },
}));

const Busy = ({ actionConst }) => {
  const busy = useIsBusy(actionConst);
  const classes = useStyles();

  return busy ? (
    <LinearProgress className={classes.progress} />
  ) : (
    <div className={classes.div} />
  );
};

Busy.propTypes = {
  actionConst: ActionConstType.isRequired,
};

export default Busy;
