import { client } from 'util/sdk';
import { GET } from 'util/api';

const returnModel = { name: 'aievents' };

const loadURL = () => `/aievents`;

// eslint-disable-next-line import/prefer-default-export
export const load = () =>
  client.executeRequest(GET, loadURL(), {
    queryParams: { eventtype: 'insight' },
    returnModel,
  });
