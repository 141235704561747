import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useOpenCloseRequestComplete } from 'hooks';
import AddDialog from './AddDialog';

const AddButton = ({ actionType, ...props }) => {
  const { handleReset } = useFormikContext();
  const [open, handleOpenClose] = useOpenCloseRequestComplete(
    actionType,
    handleReset
  );

  return (
    <>
      <Button onClick={handleOpenClose} variant="outlined">
        Add
      </Button>
      <AddDialog
        actionType={actionType}
        onClose={handleOpenClose}
        open={open}
        {...props}
      />
    </>
  );
};

AddButton.propTypes = {
  actionType: PropTypes.object.isRequired,
};

export default AddButton;
