import Payer from 'model/payer';
import ModelUser, { derived as userDerived } from 'model/user';
import { attr, fk, Model } from 'redux-orm';
import {
  createAction,
  createLoadConst,
  createUpdateConst,
  dateAndTime,
  fullName,
} from '../util';

export const derived = (payerUser) => {
  const retValue = {
    statusAtDerived: dateAndTime(payerUser?.statusAt),
  };

  // Only add the expanded user data if it is present
  if (payerUser && payerUser.user && payerUser.user.fspUserIdMask) {
    retValue.name = fullName(payerUser.user);
    retValue.userFirstNameDerived = payerUser.user.firstName;
    retValue.userFSPUserIdMaskDerived = payerUser.user.fspUserIdMask;
    retValue.userLastNameDerived = payerUser.user.lastName;
  } else {
    // This is a update, expand was not sent but a user object of href and id
    // is returned. We do not want this to wipe our existing user object.
    delete payerUser.user;
  }

  return { ...payerUser, ...retValue };
};

export default class PayerUser extends Model {
  static get modelName() {
    return 'PayerUser';
  }

  static reducer(
    { type, data = { response: [] } },
    PayerUser,
    { Payer, User }
  ) {
    switch (type) {
      case LOAD.SUCCESS: {
        let payer = {};
        if (Payer.idExists(data.props.payerId)) {
          payer = Payer.withId(data.props.payerId);
          payer.payerUsers.delete();
        }

        data.response.payeruser.forEach((r) => {
          PayerUser.upsert(derived(r));

          if (r?.user) {
            User.upsert(userDerived(r.user));
          }
        });
        break;
      }

      case UPDATE.SUCCESS:
        PayerUser.upsert(derived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      email: attr(),
      emailAt: attr(),
      fsp: attr(),
      fspId: attr(),
      homePhone: attr(),
      href: attr(),
      id: attr(),
      language: attr(),
      limitType: attr(),
      mobilePhone: attr(),
      mobilePhoneAt: attr(),
      name: attr(), // derived
      notifyByEmailOffOn: attr(),
      notifyByTextMsgOffOn: attr(),
      notifyByVoiceOffOn: attr(),
      notifyOffOn: attr(),
      notifyScope: attr(),
      offset: attr(),
      ontracEmail: attr(),
      ontracEmailAt: attr(),
      ontracMobilePhone: attr(),
      ontracMobilePhoneAt: attr(),
      payerId: fk(Payer.modelName, 'payerUsers'),
      role: attr(),
      serviceStartOn: attr(),
      ssoEmail: attr(),
      ssoEmailAt: attr(),
      ssoMobilePhone: attr(),
      ssoMobilePhoneAt: attr(),
      status: attr(),
      statusAt: attr(),
      statusText: attr(),
      updatedAt: attr(),
      user: attr(),
      userFirstNameDerived: attr(), // derived
      userFSPUserIdMaskDerived: attr(), // derived
      userLastNameDerived: attr(), // derived
      userEmail: attr(),
      userEmailAt: attr(),
      userId: fk(ModelUser.modelName, 'payerUsers'),
      userMobilePhone: attr(),
      userMobilePhoneAt: attr(),
      userType: attr(),
      workPhone: attr(),
    };
  }
}

export const LOAD = createLoadConst(PayerUser.modelName);
export const UPDATE = createUpdateConst(PayerUser.modelName);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
