import PropTypes from 'prop-types';
import {
  CircularProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { LOAD_MODEL_ID } from 'model/scheduledTransaction';
import { makeStyles } from '@material-ui/core/styles';
import { modelIdSelector } from 'selectors/scheduledTransaction';
import { useIsBusy } from 'hooks';
import { useSelector } from 'react-redux';
import PaymentSeriesTableCell from './PaymentSeriesTableCell';
import PaymentSeriesTableRow from './PaymentSeriesTableRow';

const useStyles = makeStyles((theme) => ({
  head: {
    borderBottomWidth: 0,
  },
  progress: {
    left: `calc(50% - ${theme.spacing(3)}px)`,
    position: 'absolute',
    top: '50%',
  },
}));

const PaymentSeriesTable = ({ modelId }) => {
  const busy = useIsBusy(LOAD_MODEL_ID);
  const classes = useStyles();
  const items = useSelector((state) => modelIdSelector(state, modelId));

  return (
    <>
      {busy && <CircularProgress className={classes.progress} size={50} />}
      <Table style={{ height: 300 }}>
        <TableHead>
          <TableRow classes={{ head: classes.head }}>
            <PaymentSeriesTableCell align="right" />
            <PaymentSeriesTableCell align="left">
              Send On
            </PaymentSeriesTableCell>
            <PaymentSeriesTableCell align="left">
              Deliver On
            </PaymentSeriesTableCell>
            <PaymentSeriesTableCell align="left">Amount</PaymentSeriesTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <PaymentSeriesTableRow key={item.id} index={index} item={item} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

PaymentSeriesTable.propTypes = {
  modelId: PropTypes.number.isRequired,
};

export default PaymentSeriesTable;
