import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ParamChipLabel from './ParamChipLabel';

const useStyles = makeStyles((theme) => ({
  chipLabel: {
    whiteSpace: 'initial',
  },
  chipRoot: {
    height: 'initial',
  },
  root: {
    margin: theme.spacing(0.5),
    maxWidth: 500,
    minHeight: theme.spacing(4),
    minWidth: 140,
  },
}));

const ParamChip = ({ disabled, onDelete, onClick, param, ...props }) => {
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();

    onClick(event, param);
  };
  const handleDelete = () => {
    onDelete(param);
  };

  return (
    <Chip
      {...props}
      classes={{ label: classes.chipLabel, root: classes.chipRoot }}
      className={classes.root}
      clickable
      disabled={disabled}
      label={<ParamChipLabel param={param} />}
      onClick={handleClick}
      onDelete={onDelete ? handleDelete : undefined}
      variant="outlined"
    />
  );
};

ParamChip.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  param: PropTypes.object.isRequired,
};

export default ParamChip;
