import { FSP_ID, FSP_NAME_CODE } from 'consts';

export const filters = [
  {
    key: 0,
    highlight: false,
    label: 'FSP ID',
    value: FSP_ID,
    placeholder: 'Type in the full ID',
  },
  {
    key: 1,
    highlight: false,
    label: 'FSP Name',
    value: FSP_NAME_CODE,
    placeholder: 'Type in the name',
  },
];
export const defaultFilter = filters[0];

export const getOptionLabel = (fsp) => {
  const id = fsp && fsp?.id ? `(${fsp.id})` : '';

  return fsp ? `${fsp?.name ?? fsp?.title ?? ''} ${id}` : '';
};
