import { takeEvery, takeLatest } from 'redux-saga/effects';
import { create, load, remove, update } from 'sdk/coreProcessor';
import { ADD, LOAD, THROTTLED_LOAD, REMOVE, UPDATE } from 'model/coreProcessor';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      type: LOAD,
      api: load,
      dataName: ORM,
    })
  );

  yield takeLatest(
    THROTTLED_LOAD.ACTION,
    callAction({
      type: THROTTLED_LOAD,
      api: load,
      dataName: ORM,
    })
  );

  yield takeEvery(
    ADD.ACTION,
    callAction({
      type: ADD,
      api: create,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `${
          data?.response?.processor ?? ''
        } Core Processors has been successfully created`,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      type: UPDATE,
      api: update,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `${
          data?.response?.processor ?? ''
        } Core Processor has been successfully updated`,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      dataName: ORM,
      type: REMOVE,
      api: remove,
      formatSuccessMessage: () =>
        'One or more Core Processor have been successfully removed',
    })
  );
}
