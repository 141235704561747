import { DESC } from 'consts';
import DetailsRender from './DetailsRender';

export { default as ActionsRenderer } from './ActionsRenderer';

export const columnMeta = [
  {
    id: 'createdAtLabel',
    label: 'Created',
    sortId: 'createdAt',
    xs: 3,
  },
  {
    label: 'OTP',
    id: 'otp',
    sortId: 'otp',
    xs: 3,
  },
  {
    context: true,
    label: 'Reason',
    id: 'otpReason',
    sortId: 'otpReason',
    xs: 3,
  },
  {
    capitalize: true,
    id: 'notificationMethodLabel',
    label: 'Notification Method',
    sortId: 'sendTo',
    xs: 3,
  },
];

export const defaultOrderBy = 'createdAt';

export const pageSettingsName = 'otp';

export const defaultPageSettings = {
  order: DESC,
  orderBy: defaultOrderBy,
};

export const detailsRender = (item) => <DetailsRender item={item} />;
