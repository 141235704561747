import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { isEmpty } from 'ramda';
import { b64toBlob } from 'util/index';
import { getEBillBlob } from 'sdk/eBill';
import DetailStatementButton from '../DetailStatementButton';
import DetailSummary from '../DetailSummary';
import PdfDialog from './PdfDialog';

const pdfMimeType = 'application/pdf';

const DetailStatementView = ({
  isCreditCardAccountType,
  billerAccountNumberMask,
  fspId,
  name,
  statement,
  payerId,
}) => {
  const [billBlob, setBillBlob] = useState(null);
  const [loading, setLoading] = useState(false);

  const [viewBillOpen, setViewBillOpen] = useState(false);

  const {
    id: statementId,
    documentKey,
    billOnView,
    minimumAmountView,
    dueOnView,
    amountDueView,
  } = statement;

  const handleClose = () => {
    setViewBillOpen(false);
  };

  const handleViewBill = useCallback(() => {
    if (billBlob) {
      setViewBillOpen(true);
    } else {
      const loadEBillBlob = async () => {
        setLoading(true);

        const data = await getEBillBlob({
          fspId,
          payeeEbillId: statementId,
          payerId,
        });

        setBillBlob(b64toBlob(data.downloadData), pdfMimeType);
        setLoading(false);
        setViewBillOpen(true);
      };
      loadEBillBlob();
    }
  }, [billBlob, fspId, statementId, payerId]);

  return (
    <>
      <DetailSummary
        amountDue={amountDueView}
        dueOn={dueOnView}
        isCreditCardAccountType={isCreditCardAccountType}
        minimumAmount={minimumAmountView}
      />
      {documentKey && !isEmpty(documentKey) && (
        <Grid item sm={4} xs={12}>
          <DetailStatementButton
            loading={loading}
            onViewBill={handleViewBill}
          />
        </Grid>
      )}
      {billBlob && (
        <PdfDialog
          accountNumberMask={billerAccountNumberMask}
          billOn={billOnView}
          blob={billBlob}
          name={name}
          onClose={handleClose}
          open={viewBillOpen}
        />
      )}
    </>
  );
};

DetailStatementView.propTypes = {
  isCreditCardAccountType: PropTypes.bool.isRequired,
  billerAccountNumberMask: PropTypes.string.isRequired,
  fspId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  statement: PropTypes.object.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default DetailStatementView;
