import { LOAD_PAYER, LOAD_PAYER_USER } from 'model/viewAs';
import { takeEvery } from 'redux-saga/effects';
import { registerPayer } from 'sdk/payer';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD_PAYER.ACTION,
    callAction({
      api: registerPayer,
      dataName: ORM,
      type: LOAD_PAYER,
    })
  );

  yield takeEvery(
    LOAD_PAYER_USER.ACTION,
    callAction({
      api: registerPayer,
      dataName: ORM,
      type: LOAD_PAYER_USER,
    })
  );
}
