import { attr, fk, Model } from 'redux-orm';
import {
  ACTIVITY,
  ACTIVITY_LABEL,
  DISBURSE_TYPE_P2P_DIRECT_CODE,
  VENMO_DIRECT,
} from 'consts';
import { createAction, createLoadConst, isOn } from '../util';
import Payer from './payer';

const getDisplayName = (type) => {
  switch (type) {
    case ACTIVITY:
      return ACTIVITY_LABEL;

    case DISBURSE_TYPE_P2P_DIRECT_CODE:
      return VENMO_DIRECT;

    default:
      return type?.toUpperCase() ?? ACTIVITY_LABEL;
  }
};
const getURL = ({ props, response }) => {
  const { fspId, payerId } = props;
  const { sso } = response;
  const { ssoToken, uri } = sso;

  return `${uri}?ssoToken=${ssoToken}&fspId=${fspId}&payerId=${payerId}`;
};
const getURLs = (data) => {
  const url = getURL(data);
  const { sso } = data.response;
  const { links } = sso;

  const urls = links
    .filter(({ defaultUri }) => isOn(defaultUri))
    .map(({ type }, id) => ({
      id,
      name: getDisplayName(type),
      url: `${url}&type=${type}`,
    }));

  if (urls.length === 0) {
    const defaultURL = {
      id: 0,
      name: getDisplayName(),
      url: `${url}&type=${ACTIVITY}`,
    };

    urls.push(defaultURL);
  }

  return urls;
};

export default class ViewAs extends Model {
  static get modelName() {
    return 'ViewAs';
  }

  static reducer({ type, data }, ViewAs) {
    switch (type) {
      case LOAD_PAYER.SUCCESS:
      case LOAD_PAYER_USER.SUCCESS: {
        const { payerId } = data?.props ?? {};
        const urls = getURLs(data);

        ViewAs.upsert({ payerId, urls });
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      payerId: fk(Payer.modelName, 'viewAs'),
      urls: attr(),
    };
  }

  /**
   * Use payerId as key
   * */
  static options = {
    idAttribute: 'payerId',
  };
}

export const LOAD_PAYER = createLoadConst(ViewAs.modelName);
export const LOAD_PAYER_USER = createLoadConst(`${ViewAs.modelName}_USER`);

export const loadPayer = createAction(LOAD_PAYER);
export const loadPayerUser = createAction(LOAD_PAYER_USER);
