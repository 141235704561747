import { takeLatest } from 'redux-saga/effects';
import { cancel, load, review } from 'sdk/fspSchedTrans';
import { CANCEL, LOAD, REVIEW } from 'model/fspSchedTrans';
import { callAction, ORM } from '../util';

export const formatSuccessMessage = (suffix) => (data) => {
  const count = data?.response?.length;

  if (count === 1) {
    return `The transaction was ${suffix}`;
  }

  if (count > 1) {
    return `${count} transactions were ${suffix}`;
  }

  // We should never get here
  return 'No transactions were updated';
};

export default function* saga() {
  yield takeLatest(
    CANCEL.ACTION,
    callAction({
      api: cancel,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage('canceled'),
      type: CANCEL,
    })
  );

  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    REVIEW.ACTION,
    callAction({
      api: review,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage('allowed'),
      type: REVIEW,
    })
  );
}
