import PropTypes from 'prop-types';
import { Grid, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ASC = 'asc';
const DESC = 'desc';

const useStyles = makeStyles(() => ({
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const ExpansionListSubheaderSort = ({
  data,
  label,
  onChange,
  order,
  orderBy,
  page,
  rowsPerPage,
  sortId,
  xs,
}) => {
  const classes = useStyles();

  const handleSort = (event) => {
    if (data.length) {
      onChange(event, {
        orderBy: sortId,
        order: orderBy === sortId ? (order === ASC ? DESC : ASC) : ASC,
        page,
        rowsPerPage,
      });
    }
  };

  return (
    <Grid className={classes.noWrap} item xs={xs} zeroMinWidth>
      {sortId && data.length ? (
        <TableSortLabel
          active={orderBy === sortId}
          direction={order}
          onClick={handleSort}
        >
          {label}
        </TableSortLabel>
      ) : (
        <span className={classes.noWrap}>{label}</span>
      )}
    </Grid>
  );
};

ExpansionListSubheaderSort.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortId: PropTypes.string,
  xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

ExpansionListSubheaderSort.defaultProps = {
  label: null,
  orderBy: null,
  sortId: null,
  xs: false,
};

export default ExpansionListSubheaderSort;
