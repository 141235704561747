import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { ROWS_PER_PAGE } from 'consts';
import { endsWithLoadSuccess } from 'util/actions';
import { createAction, createRequestConst, isNilOrEmpty } from '../util';

export const defaultPayerId = 0;
export const relatedName = 'pageSettings';

const idAttribute = 'payerId';

export const defaults = {
  clear: false,
  hasMoreItems: false,
  page: 0,
  params: {},
  rowsPerPage: ROWS_PER_PAGE[0],
  rowsPerPageOptions: ROWS_PER_PAGE,
  search: null,
  searchBys: [],
};

export default class PageSetting extends Model {
  static get modelName() {
    return 'PageSetting';
  }

  static reducer(
    { type, data, meta, reduxActionMeta = {}, reduxActionData = {} },
    PageSetting
  ) {
    switch (type) {
      case UPDATE.ACTION: {
        const { payerId = defaultPayerId, type, ...newValues } = data;
        let existingRecord = { payerId };
        let existingData = defaults;

        if (PageSetting.idExists(payerId)) {
          existingRecord = PageSetting.withId(payerId).ref;
          existingData = existingRecord[type];
        }

        const record = {
          ...existingRecord,
          [type]: { ...existingData, ...newValues },
        };

        PageSetting.upsert(record);
        break;
      }

      default:
        break;
    }

    if (endsWithLoadSuccess(type)) {
      const { pageSettingsName } = reduxActionMeta;
      const hasPageSettingsName = !isNilOrEmpty(pageSettingsName);

      if (hasPageSettingsName) {
        const id =
          (reduxActionData.id || reduxActionData[idAttribute]) ??
          defaultPayerId;
        const pageSetting = PageSetting.idExists(id)
          ? PageSetting.withId(id).ref
          : { [idAttribute]: id };

        if (pageSetting[pageSettingsName] === undefined) {
          pageSetting[pageSettingsName] = {};
        }

        pageSetting[pageSettingsName].hasMoreItems = meta.hasMoreItems;
        PageSetting.upsert(pageSetting);
      }
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      clear: attr(),
      hasMoreItems: attr(),
      order: attr(),
      orderBy: attr(),
      page: attr(),
      params: attr(),
      payerId: fk(Payer.modelName, relatedName),
      refreshedAt: attr(),
      rowsPerPage: attr(),
      rowsPerPageOptions: attr(),
      search: attr(),
      type: attr(),
    };
  }

  static options = { idAttribute };
}

export const UPDATE = createRequestConst(PageSetting.modelName);
export const update = createAction(UPDATE);
