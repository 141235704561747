import { BP_MISSED_PAYMENT } from 'consts';
import { load, LOAD } from 'model/actionInsight';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingState } from 'reducers/loading';
import { selectorMissedPayments } from 'selectors/actionInsight';
import { selectorFspId as insightEventSelectorFspId } from 'selectors/actionInsightEvent';
import { selector as busySelector } from 'selectors/busy';
import ActionInsightList from '../ActionInsightList';
import { columns, pageSettingsName } from './consts';
import MissedPaymentDetailsRenderer from './MissedPaymentDetailsRenderer';

const DetailsRender = (item) => <MissedPaymentDetailsRenderer item={item} />;

const MissedPayment = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const isLoading = useSelector((state) => isLoadingState(state, LOAD.ACTION));
  const items = useSelector((state) =>
    selectorMissedPayments(state, { fspId, payerId })
  );
  const actionInsightEvents =
    useSelector((state) => insightEventSelectorFspId(state, fspId)) || [];

  const missedPaymentEvent = actionInsightEvents.find(
    ({ eventName }) =>
      eventName.toUpperCase() === BP_MISSED_PAYMENT.toUpperCase()
  );

  const loadAction = (props) => {
    dispatch(
      load({
        fspId,
        insightEventId: missedPaymentEvent.insightEventdIdStr,
        payerId,
        ...props,
      })
    );
  };

  useEffect(() => {
    dispatch(
      load({
        fspId,
        insightEventId: missedPaymentEvent.insightEventdIdStr,
        payerId,
      })
    );
  }, [dispatch, fspId, payerId, missedPaymentEvent]);

  return (
    <ActionInsightList
      actionConstant={LOAD}
      columnMeta={columns}
      detailsRender={DetailsRender}
      fspId={fspId}
      isBusy={isBusy}
      isLoading={isLoading}
      items={items}
      loadAction={loadAction}
      name={pageSettingsName}
      payerId={payerId}
      settingType={pageSettingsName}
      title="Miss a Payment"
    />
  );
};

MissedPayment.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default MissedPayment;
