import { attr, Model } from 'redux-orm';
import {
  createAction,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);

  return r;
};

export default class Biller extends Model {
  static get modelName() {
    return 'Biller';
  }

  static reducer({ type, data }, Biller) {
    switch (type) {
      case CHECK.ACTION: {
        const biller = Biller.withId(data.id);
        if (biller) {
          biller.selected = !biller.selected;

          biller.update(biller);
        }
        break;
      }

      case CHECK_ALL.ACTION:
        Biller.update({ selected: data.selected });
        break;

      case LOAD.SUCCESS: {
        Biller.delete();
        (data?.response || []).forEach((biller) => {
          Biller.create(addDerived(biller));
        });
        break;
      }

      case LOAD.FAILURE:
        Biller.delete();
        break;

      case LOAD_ID.SUCCESS:
        Biller.upsert(addDerived(data.response));
        break;

      case LOAD_SET.SUCCESS: {
        // Only take first set because there should only be one set per biller
        const biller = (data?.response || [])[0];
        if (biller && Biller.idExists(biller.billerId)) {
          Biller.upsert(
            addDerived({
              ...Biller.withId(biller.billerId).ref,
              billerSet: biller.billerset,
            })
          );
        }
        // This is to hide skeleton
        else if (Biller.idExists(data?.props?.id)) {
          Biller.upsert({
            ...Biller.withId(data?.props?.id).ref,
            billerSet: { id: '', name: '' },
          });
        }
        break;
      }

      case UPDATE.SUCCESS: {
        const biller = data?.response;

        if (biller) {
          Biller.withId(biller.id).update(addDerived(biller));
        }
        break;
      }

      default:
        break;
    }
  }

  static get fields() {
    return {
      billernetName: attr(),
      billerTypeId: attr(),
      billerLogoId: attr(),
      billerSet: attr(),
      billerStatus: attr(),
      createdAt: attr(),
      createdAtLabel: attr(),
      href: attr(),
      id: attr(),
      insBillernetId: attr(),
      insAliasForBillerNameOffOn: attr(),
      name: attr(),
      netIdStr: attr(),
      note: attr(),
      offset: attr(),
      rppsBillerId: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(),
      updBillernetId: attr(),
      zipMatchNeededOffOn: attr(),
    };
  }
}

const name = Biller.modelName;

export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const LOAD_ID = createLoadConst(`${name}_id`);
export const LOAD_SET = createLoadConst(`${name}_set`);
export const UPDATE = createUpdateConst(name);

export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const loadSet = createAction(LOAD_SET);
export const update = createAction(UPDATE);
