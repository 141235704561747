import PropTypes from 'prop-types';
import { TableContainerProgress } from 'components/common/TableContainer';
import { identity } from 'ramda';
import { usePagination } from 'hooks';

const ExpansionListPaginationEnhanced = ({
  ListPaginationProps,
  pageSettingsName,
}) => {
  usePagination(
    ListPaginationProps.action,
    null,
    null,
    null,
    false,
    pageSettingsName,
    ListPaginationProps.selectorCount,
    ListPaginationProps.selectorCountProps
  );

  return (
    <TableContainerProgress
      actionType={ListPaginationProps.actionType}
      actionTypeUpdate={ListPaginationProps.actionTypeUpdate}
      showProgress={ListPaginationProps.showProgress}
    />
  );
};

ExpansionListPaginationEnhanced.propTypes = {
  ListPaginationProps: PropTypes.object,
  pageSettingsName: PropTypes.string.isRequired,
};

ExpansionListPaginationEnhanced.defaultProps = {
  ListPaginationProps: {
    selectorCount: identity,
    showProgress: false,
  },
};

export default ExpansionListPaginationEnhanced;
