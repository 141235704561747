import { memo } from 'react';
import PropTypes from 'prop-types';
import { DialogActions, Button, makeStyles } from '@material-ui/core';
import { ChevronRightOutlined, ChevronLeftOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { selector } from 'selectors/busy';
import {
  UPDATE_PAYMENT_NOC,
  UPDATE_PAYMENT_NOR,
} from 'model/transactionHistory';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const UpdatePaymentDialogActions = ({
  activeStep,
  disabled,
  onBack,
  onNext,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const nocBusy = useSelector((state) => selector(state, UPDATE_PAYMENT_NOC));
  const norBusy = useSelector((state) => selector(state, UPDATE_PAYMENT_NOR));

  return (
    <DialogActions>
      <Button
        className={classes.root}
        color="default"
        disabled={activeStep === 0}
        onClick={onBack}
        size="small"
      >
        <ChevronLeftOutlined />
        Back
      </Button>
      <Button
        color="default"
        disabled={activeStep === 2 || disabled}
        onClick={onNext}
        size="small"
      >
        Next
        <ChevronRightOutlined />
      </Button>
      <Button color="default" onClick={onClose} variant="contained">
        Cancel
      </Button>
      <Button
        color="primary"
        disabled={activeStep !== 2 || nocBusy || norBusy}
        onClick={onSubmit}
        type="button"
        variant="contained"
      >
        Update
      </Button>
    </DialogActions>
  );
};

UpdatePaymentDialogActions.propTypes = {
  activeStep: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

UpdatePaymentDialogActions.defaultProps = {
  disabled: false,
};

export default memo(UpdatePaymentDialogActions);
