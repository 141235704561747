import PropTypes from 'prop-types';
import { DialogContent as MuiDialogContent, Grid } from '@material-ui/core';
import Digits from './Digits';
import Info from './Info';
import Reasons from './Reasons';

const DialogContent = ({ fspId, onChange, payerId, userId }) => {
  return (
    <MuiDialogContent>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Info fspId={fspId} payerId={payerId} userId={userId} />
        </Grid>
        <Grid item>
          <Reasons onChange={onChange} payerId={payerId} />
        </Grid>
        <Grid item>
          <Digits fspId={fspId} payerId={payerId} />
        </Grid>
      </Grid>
    </MuiDialogContent>
  );
};

DialogContent.propTypes = {
  fspId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  payerId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default DialogContent;
