import { DESC } from 'consts';

export const HEIGHT_OFFSET = 60;
export const SCROLL_TOP_OFFSET = 32;

export const columns = [
  { id: 'id', label: 'ID', sort: true },
  { id: 'createdAt', label: 'Created', sort: true },
  { id: 'startAt', label: 'Started', sort: false },
  { id: 'doneAt', label: 'Finished', sort: false },
  { id: 'cancelAt', label: 'Cancelled', sort: false },
  { id: 'type', label: 'Type', sort: false },
  { id: 'name', label: 'Template', sort: false },
  { id: 'status', label: 'Status', sort: false },
  { id: 'actions', label: 'Actions', align: 'center', sort: false },
];

export const defaultPageSettings = {
  order: DESC,
  orderBy: 'id',
};

export const pageSettingsName = 'automatchJobsComplete';
