import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { replace, always } from 'ramda';
import { toClass } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { placeholderChar } from './consts';
import Field from './Field';

const useStyles = makeStyles({
  root: {},
});

const amountMask = createNumberMask({
  prefix: '$',
  allowDecimal: true,
});

const RenderAmount = toClass(({ inputRef, ...props }) => (
  <MaskedInput {...props} mask={amountMask} placeholderChar={placeholderChar} />
));

const Amount = ({ onChange, ...props }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    if (onChange) {
      onChange(replace(/[$,]/g, '', event.target.value));
    }
  };
  return (
    <Field
      classes={{ root: classes.root }}
      component={RenderAmount}
      {...props}
      onChange={handleChange}
    />
  );
};

Amount.propTypes = {
  onChange: PropTypes.func,
};

Amount.defaultProps = {
  onChange: always,
};

export default Amount;
