import { oneToOne, Model } from 'redux-orm';
import PaymentResearch, {
  LOAD,
  LOAD_ASSIGNED,
  LOAD_HIST_ID,
} from 'model/paymentResearch';

export default class PaymentResearchSearch extends Model {
  static get modelName() {
    return 'PaymentResearchSearch';
  }

  static reducer({ type, data = {} }, PaymentResearchSearch) {
    switch (type) {
      case LOAD.SUCCESS:
      case LOAD_ASSIGNED.SUCCESS:
      case LOAD_HIST_ID.SUCCESS:
        PaymentResearchSearch.delete();
        if (data.response) {
          data.response.forEach(({ id }) =>
            PaymentResearchSearch.create({ id })
          );
        }
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      id: oneToOne(PaymentResearch.modelName),
    };
  }
}
