import { Select } from 'components/common/fields';
import STATUS_SEARCH_VALUES from './consts';

const StatusSearchSelect = (props) => {
  return (
    <Select
      data={STATUS_SEARCH_VALUES}
      displayAttr="name"
      valueAttr="value"
      {...props}
    />
  );
};

export default StatusSearchSelect;
