import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LimitsTableHeadCell from './LimitsTableHeadCell';

const tableCells = [
  {
    key: 0,
    className: 'min',
    title: 'Minimum Amount',
    title2: 'Per Transaction',
    tooltip: 'The transaction cannot be lower than this dollar amount',
  },
  {
    key: 1,
    title: 'Maximum Amount',
    title2: 'Per Transaction',
    tooltip: 'The transaction cannot be higher than this dollar amount',
  },
  {
    key: 2,
    title: 'Daily Limit',
    tooltip:
      'The user cannot send payments totaling greater than this amount in a day',
  },
  {
    key: 3,
    title: 'Weekly Limit',
    tooltip:
      'The user cannot send payments totaling greater than this amount in a week',
  },
  {
    key: 4,
    title: 'Monthly Limit',
    tooltip:
      'The user cannot send payments totaling greater than this amount in a month',
  },
];

const useStyles = makeStyles(() => ({
  min: {
    width: 150,
  },
}));

const LimitsTableHead = ({ edit }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {tableCells.map(({ className, key, title, title2, tooltip }) => (
          <LimitsTableHeadCell
            key={key}
            className={classes[className]}
            edit={edit}
            title={title}
            title2={title2}
            tooltip={tooltip}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

LimitsTableHead.propTypes = {
  edit: PropTypes.bool.isRequired,
};

export default LimitsTableHead;
