import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class SwitchMatch extends Model {
  static get modelName() {
    return 'SwitchMatch';
  }

  static reducer({ data, error, type }, SwitchMatch) {
    switch (type) {
      case LOAD.ACTION:
        SwitchMatch.delete();
        break;

      case LOAD.SUCCESS:
        SwitchMatch.create(data.response);
        break;

      case LOAD.FAILURE:
        SwitchMatch.delete();
        SwitchMatch.create({ error });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      biller: attr(),
      billers: attr(), // derived
      error: attr(),
      matchpayeestat: attr(), // derived
      matchpayeestats: attr(),
    };
  }
}

export const LOAD = createRequestConst(SwitchMatch.modelName);
export const load = createAction(LOAD);
