import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class TransactionHistoryAudit extends Model {
  static get modelName() {
    return 'TransactionHistoryAudit';
  }

  static reducer({ type, data }, TransactionHistoryAudit) {
    switch (type) {
      case LOAD.SUCCESS:
        TransactionHistoryAudit.delete();
        TransactionHistoryAudit.upsert({
          audit: data.response,
          id: data.props.id,
        });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      deliver: attr(), // Added w/ ?
      histId: attr(),
      id: attr(),
      responseCode: attr(), // Added w/ API-1039
      responseCodeStr: attr(), // Added w/ API-1039
      schedId: attr(),
      source: attr(),
      subTitle: attr(), // Derived
      title: attr(), // Derived
      tooltip: attr(), // Derived
      transactionExecutedAt: attr(),
      transactionState: attr(),
      transactionType: attr(),
    };
  }
}

export const LOAD = createLoadConst(TransactionHistoryAudit.modelName);
export const load = createAction(LOAD);
