import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuditDetailRowRecordTitlePrefix from 'components/common/Audit/config/shared/AuditDetailRowRecordTitlePrefix';

const useStyles = makeStyles(() => ({
  nowrap: {
    whiteSpace: 'nowrap',
  },
}));

const AuditDetailRowRecordTitle = ({
  byAdminUserId,
  bySystemUser,
  byUserId,
  status,
  time,
  title,
}) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      wrap="nowrap"
    >
      <Grid item>
        <AuditDetailRowRecordTitlePrefix
          byAdminUserId={byAdminUserId}
          bySystemUser={bySystemUser}
          byUserId={byUserId}
          status={status}
          time={time}
          title={title}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.nowrap} variant="body2">
          {time}
        </Typography>
      </Grid>
    </Grid>
  );
};

AuditDetailRowRecordTitle.propTypes = {
  byAdminUserId: PropTypes.number,
  bySystemUser: PropTypes.string,
  byUserId: PropTypes.number,
  status: PropTypes.string,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

AuditDetailRowRecordTitle.defaultProps = {
  byAdminUserId: 0,
  bySystemUser: '',
  byUserId: 0,
  status: null,
};

export default AuditDetailRowRecordTitle;
