import { ADD, LOAD, REMOVE, UPDATE } from 'model/billerSetAlias';
import { add, load, remove, update } from 'sdk/billerSetAlias';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

const formatSuccessMessage = () => 'The Biller Set Alias was updated';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Biller Set Alias "${response.alias}" was successfully added`,
      type: ADD,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage,
      type: REMOVE,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage,
      type: UPDATE,
    })
  );
}
