import PropTypes from 'prop-types';
import { DialogContentText, Typography } from '@material-ui/core';
import { VERSION } from 'consts';

const UpdateRequiredVersion = ({ versionRequired }) => {
  return (
    <DialogContentText>
      <Typography variant="caption">
        {versionRequired} is required, you have {VERSION}
      </Typography>
    </DialogContentText>
  );
};

UpdateRequiredVersion.propTypes = {
  versionRequired: PropTypes.string.isRequired,
};

export default UpdateRequiredVersion;
