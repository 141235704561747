import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spacer: {
    minWidth: theme.spacing(1),
  },
}));

const Spacer = () => {
  const classes = useStyles();

  return <span className={classes.spacer}>&nbsp;</span>;
};

export default Spacer;
