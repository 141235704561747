import {
  IMPORT_FSP_PROFILE,
  LOAD_FSP_PROFILE,
  UPDATE_FSP_PROFILE,
} from 'model/export';
import { selector as fspImportDataSelector } from 'reducers/fspImport';
import { put, select, takeEvery } from 'redux-saga/effects';
import { createFspConfig } from 'sagas/fspConfig';
import { importLimits } from 'sagas/limit';
import { importPayerTypes } from 'sagas/payerType';
import { importPaynets } from 'sagas/fspPaynet';
import { importRtn } from 'sagas/rtn';
import { importSSOURIs } from 'sagas/ssoUri';
import { importUserRoles } from 'sagas/userRole';
import { loadFSPProfile } from 'sdk/export';
import { sanitizeForImport } from 'model/util/fspConfig';
import { updateFSPCoreConfig } from 'sagas/fspCoreConfig';
import { updateFsp } from 'sagas/fsp';
import { updateOneTimePasscodeEventConfig } from 'sagas/oneTimePasscodeEventConfig';
import { callAction, createActionSuccess, ORM } from '../util';

export const loadFSPProfileAction = callAction({
  api: loadFSPProfile,
  dataName: ORM,
  type: LOAD_FSP_PROFILE,
});

function* updateFSPProfile({ data }) {
  yield put(createActionSuccess(UPDATE_FSP_PROFILE)(data));
}

export function* importFSPProfile({ data: fspId }) {
  const {
    fsp,
    fspconfig,
    fspcoreconfig,
    fspotpevent,
    fsppayertype,
    fsppaynets,
    fsprtn,
    limit,
    limitrule,
    ssouri,
    userrole,
  } = yield select(fspImportDataSelector);

  const sanitizedData = { data: sanitizeForImport(fspconfig) };
  const fspConfig = yield createFspConfig()(sanitizedData);

  if (fspConfig) {
    const id = fspConfig.response.id;

    yield updateFsp()({ data: fsp });

    if (fsprtn?.length) {
      yield importRtn()({ data: { fspId, fsprtn } });
    }
    if (userrole?.length) {
      yield importUserRoles()({ data: { fspId, userrole } });
    }
    if (limit?.length) {
      yield importLimits()({ data: { fspId, limit, limitrule } });
    }
    if (fsppayertype?.length) {
      yield importPayerTypes()({ data: { fspId, fsppayertype } });
    }
    if (ssouri?.length) {
      yield importSSOURIs()({ data: { fspId, ssouri } });
    }
    if (fsppaynets?.length) {
      yield importPaynets()({ data: { fspId, fsppaynets } });
    }
    yield updateOneTimePasscodeEventConfig()({
      data: { fspId, ...fspotpevent },
    });
    yield updateFSPCoreConfig()({
      data: { ...fspcoreconfig, fspId, id },
    });
    yield put({ type: IMPORT_FSP_PROFILE.SUCCESS });
  } else {
    yield put({ type: IMPORT_FSP_PROFILE.FAILURE });
  }
}

export default function* saga() {
  yield takeEvery(LOAD_FSP_PROFILE.ACTION, loadFSPProfileAction);

  yield takeEvery(UPDATE_FSP_PROFILE.ACTION, updateFSPProfile);

  yield takeEvery(IMPORT_FSP_PROFILE.ACTION, importFSPProfile);
}
