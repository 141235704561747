import { LOAD, load } from 'model/internalAccount';
import { selectorCount } from 'selectors/internalAccount';
import { unmask } from 'sdk/internalAccount';
import { Mask } from 'components/common';
import ExpandedRow from './ExpandedRow';

export { default as ActionsRenderer } from './ActionsRenderer';
export const defaultOrderBy = 'id';

const MaskRenderer = (
  value,
  { accountSuffix, id, displayAccountNbrMaskLong, fspId },
  displayedAsContext,
  { deletedOnly }
) => {
  const handleAPI = () => unmask({ fspId, id }, deletedOnly);

  return (
    <Mask
      api={handleAPI}
      icon={displayedAsContext}
      unmaskSuffix={accountSuffix}
      value={displayAccountNbrMaskLong || value}
    />
  );
};

export const columnMeta = [
  {
    xs: 2,
    id: 'accountNameDerived',
    label: 'Name',
  },
  {
    xs: 2,
    id: 'descriptionDerived',
    label: 'Description',
  },
  {
    xs: 2,
    id: 'accountAchNbrMask',
    label: 'Loan #',
    renderer: MaskRenderer,
  },
  {
    xs: 2,
    id: 'ddaStatus',
    label: 'Status',
  },
  {
    xs: 2,
    isDate: true,
    id: 'updatedAt',
    label: 'Updated At',
  },
];
export const TablePaginationProps = {
  action: load,
  actionType: LOAD,
  hasFspId: true,
  selectorCount,
};
export const TableProps = { stickyHeader: true };

export const detailsRender = (item) => <ExpandedRow item={item} />;
