import { LOAD } from 'model/actionInsightReduceMyBill';
import { load } from 'sdk/actionInsightReduceMyBill';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );
}
