import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  withStyles,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

const styles = () => ({
  circularProgress: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: 'calc(50% - 25px)',
  },
});

// See https://material.io/design/components/dialogs.html#anatomy
const Alert = ({
  cancelLabel,
  classes,
  confirmLabel,
  isBusy,
  onClose,
  onConfirm,
  open,
  text: Text,
  title,
  ...props
}) => {
  const [disabled, setDisabled] = useState(false);
  const [userClicked, setUserClicked] = useState(false);

  const handleClick = (event) => {
    setDisabled(true);
    setUserClicked(true);
    onConfirm(event);
  };

  useEffect(() => {
    if (!userClicked) {
      setDisabled(isBusy);
    }
  }, [isBusy, userClicked]);

  return (
    <Dialog
      {...props}
      aria-describedby={`${Text}`}
      aria-labelledby={title}
      fullWidth
      onClose={onClose}
      open={open}
    >
      {isBusy && (
        <CircularProgress className={classes.circularProgress} size={50} />
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {typeof Text === 'string' ? Text : <Text />}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button
          autoFocus
          color="primary"
          disabled={disabled}
          onClick={handleClick}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Alert.propTypes = {
  cancelLabel: PropTypes.string,
  classes: PropTypes.object.isRequired,
  confirmLabel: PropTypes.string,
  isBusy: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  title: PropTypes.string,
};

Alert.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'OK',
  isBusy: false,
  title: null,
};

export default withStyles(styles)(Alert);
