import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export default class CorsDomain extends Model {
  static get modelName() {
    return 'CorsDomain';
  }

  static reducer({ type, data }, CorsDomain) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        CorsDomain.create(data?.response);
        break;

      case LOAD.SUCCESS:
        CorsDomain.delete();
        data?.response?.forEach((r) => CorsDomain.upsert(r));
        break;

      case LOAD.FAILURE:
        CorsDomain.delete();
        break;

      case REMOVE.SUCCESS:
        if (CorsDomain.idExists(id)) {
          CorsDomain.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        CorsDomain.upsert(data.response);
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      domainName: attr(),
      fspId: attr(),
      id: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

const name = CorsDomain.modelName;

export const ADD = createAddConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
