import { takeLatest } from 'redux-saga/effects';
import { loadPalAutopay } from 'sdk/autopayPal';
import { LOAD } from 'model/autopayPal';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: loadPalAutopay,
      dataName: ORM,
      type: LOAD,
    })
  );
}
