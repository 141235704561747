import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    color: theme.palette.text.primary,
    fontSize: '0.775rem',
    fontWeight: 500,
    letterSpacing: '0.0857em',
    textTransform: 'uppercase',
    '&$disabled': { opacity: 1 },
  },
  disabled: {},
}));

const AuditDetailRowRecordStatus = ({ status }) => {
  const classes = useStyles();

  return status ? (
    <Chip
      classes={{ disabled: classes.disabled, root: classes.root }}
      disabled
      label={status}
      size="small"
    />
  ) : null;
};

AuditDetailRowRecordStatus.propTypes = {
  status: PropTypes.string,
};

AuditDetailRowRecordStatus.defaultProps = {
  status: null,
};

export default AuditDetailRowRecordStatus;
