import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { memo } from 'react';
import { usePagination } from 'hooks';

const Pagination = ({
  action,
  actionParams,
  actionType,
  defaultPageSettings,
  hasFspId,
  name,
  selector,
  selectorProps,
  ...props
}) => {
  const paginationProps = usePagination(
    action,
    actionParams,
    actionType,
    defaultPageSettings,
    hasFspId,
    name,
    selector,
    selectorProps
  );

  return <TablePagination {...paginationProps} {...props} />;
};

Pagination.propTypes = {
  action: PropTypes.func.isRequired,
  actionParams: PropTypes.object,
  actionType: PropTypes.string.isRequired,
  defaultPageSettings: PropTypes.object,
  hasFspId: PropTypes.bool,
  selector: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
  name: PropTypes.string.isRequired,
};

Pagination.defaultProps = {
  actionParams: null,
  defaultPageSettings: null,
  hasFspId: true,
  selectorProps: null,
};

export default memo(Pagination);
