import PropTypes from 'prop-types';
import { always } from 'ramda';
import {
  ExpansionListItem,
  ExpansionListItemProvider,
} from 'components/common/ExpansionList';
import { FieldArray, useFormikContext } from 'formik';
import ImportDeleteAction from './ImportDeleteAction';

const ImportListRender = ({
  columnMeta,
  detailsRender,
  expandedIds,
  name,
  onListItemChange,
}) => {
  const { values } = useFormikContext();
  const items = values[name] ?? [];

  return (
    <FieldArray
      name={name}
      render={({ remove }) => (
        <>
          {items.map((routing, index) => (
            <ExpansionListItemProvider
              key={routing?.id}
              item={routing}
              itemIndex={index}
            >
              <ExpansionListItem
                ActionsRenderer={ImportDeleteAction}
                columnMeta={columnMeta}
                detailsRender={detailsRender}
                expanded={
                  Array.isArray(expandedIds) &&
                  expandedIds.includes(routing?.id)
                }
                idMeta="id"
                onListItemChange={onListItemChange}
                renderOptions={{ itemIndex: index, removeAction: remove }}
                row={routing}
              />
            </ExpansionListItemProvider>
          ))}
        </>
      )}
    />
  );
};

ImportListRender.propTypes = {
  columnMeta: PropTypes.array.isRequired,
  detailsRender: PropTypes.func.isRequired,
  expandedIds: PropTypes.array,
  name: PropTypes.string.isRequired,
  onListItemChange: PropTypes.func,
};

ImportListRender.defaultProps = {
  expandedIds: [],
  onListItemChange: always,
};

export default ImportListRender;
