import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { handleStopPropagation } from 'consts';

const EndAdornment = ({ endAdornment, expanded }) => {
  return endAdornment ? (
    <Grid item onClick={handleStopPropagation}>
      <Collapse in={expanded}>{endAdornment}</Collapse>
    </Grid>
  ) : null;
};

EndAdornment.propTypes = {
  expanded: PropTypes.bool.isRequired,
  endAdornment: PropTypes.object,
};

EndAdornment.defaultProps = {
  endAdornment: null,
};

export default EndAdornment;
