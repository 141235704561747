import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { makeStyles } from '@material-ui/core/styles';
import OfferStatus from './OfferStatus';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
  },
  card: {
    maxWidth: 400,
  },
  typography: {
    whiteSpace: 'pre-wrap',
  },
}));

const ReduceMyBillDetailsRenderer = ({ item }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Offer Date" value={item?.activeAtLabel} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Offer End Date"
            value={item?.offerExpiresOnLabel}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Biller" value={item?.payeeName} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Total Savings"
            value={item?.totalSavingsLabel}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            inputNode={
              item?.offerStatusDates && (
                <OfferStatus
                  label={item?.statusLabel}
                  offerStatusDates={item?.offerStatusDates}
                />
              )
            }
            label="Status"
            value={item?.statusLabel}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            inputNode={
              <div className={classes.card}>
                <ReadOnlyInputWithMenu
                  capitalize={false}
                  label={item?.statusDetailLabel}
                  menuNode={
                    <Card className={classes.card} elevation={0}>
                      <CardContent>
                        <Typography
                          className={classes.typography}
                          variant="body2"
                        >
                          {item?.statusDetailLabel}
                        </Typography>
                      </CardContent>
                    </Card>
                  }
                  tooltip="Show More"
                />
              </div>
            }
            label="Status Detail"
            value={item?.statusDetailLabel}
          />
        </Grid>
      </Grid>
      {item?.expiredAt && (
        <Grid xs={4}>
          <ReadOnlyFormControl
            label="Expiry Reason"
            value={item.expiredReason}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <ReadOnlyFormControl label="View Count" value={item?.viewCount} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="First View Date"
          value={item?.firstViewOnLabel}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Last View Date"
          value={item?.lastViewOnLabel}
        />
      </Grid>
    </Grid>
  );
};

ReduceMyBillDetailsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ReduceMyBillDetailsRenderer;
