import PropTypes from 'prop-types';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Field, useField } from 'formik';
import { FormControl, FormHelperText, Tooltip } from '@material-ui/core';
import { isNilOrEmpty } from 'util/index';
import { mergeLeft } from 'ramda';

const defaultProps = { arrow: true, placement: 'top' };

const Checkbox = ({ color, label, name, TooltipProps, ...props }) => {
  const [, meta] = useField(name);
  const derivedTooltipProps = mergeLeft(TooltipProps, defaultProps);
  const hasError = meta?.touched && !isNilOrEmpty(meta?.error);

  return (
    <Tooltip {...derivedTooltipProps}>
      <FormControl error={hasError}>
        <Field
          color={color}
          component={CheckboxWithLabel}
          Label={{ label }}
          name={name}
          type="checkbox"
          {...props}
        />
        {hasError && <FormHelperText>{meta?.error}</FormHelperText>}
      </FormControl>
    </Tooltip>
  );
};

Checkbox.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  TooltipProps: PropTypes.shape({
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    title: PropTypes.string,
  }),
};

Checkbox.defaultProps = {
  color: 'primary',
  TooltipProps: {
    arrow: true,
    placement: 'top',
    title: '',
  },
};

export default Checkbox;
