import LoadingSkeleton from 'react-loading-skeleton';
import TextSkeleton from 'components/common/TextSkeleton';
import {
  CLOSED,
  OPEN,
  SOLVED,
} from 'components/modules/paymentResearch/Status';
import { WAITING } from 'components/common/fields';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { NA_VALUE } from 'consts';
import { date, dateAndTime, durationHumanized } from '../util';

export const PREFIX = 'P';
const SKELETON_0 = <TextSkeleton width={0} />;
const SKELETON_27 = <TextSkeleton width="27%" />;
const SKELETON_63 = <TextSkeleton width="63%" />;
const SKELETON_75 = <TextSkeleton width="75%" />;
const SKELETON_TITLE = <LoadingSkeleton width="60%" />;

const SKELETONS = {
  assignedTo: SKELETON_0,
  atLabel: `${WAITING} At`,
  atValue: SKELETON_63,
  byLabel: `${WAITING} By/To`,
  byValue: SKELETON_63,
  createdAtLabel: SKELETON_75,
  createdByLabel: SKELETON_63,
  description: SKELETON_63,
  idLabel: SKELETON_27,
  isEmpty: true,
  payerLabel: SKELETON_63,
  reasonName: SKELETON_TITLE,
  status: SKELETON_0,
  updatedAtLabel: SKELETON_75,
  updatedBy: SKELETON_63,
};

const formatName = (first, last) =>
  first && last ? `${first} ${last}` : NA_VALUE;

const setDisplayItems = (item) => {
  if (item) {
    const obj = {};

    switch (item.status) {
      case CLOSED:
        obj.atLabel = 'Closed At';
        obj.atValue = item.closedAtLabel;
        obj.byLabel = 'Closed By';
        obj.byValue = item.closedBy;
        break;

      case OPEN:
        obj.atLabel = 'Assigned At';
        obj.atValue = item.openAtLabel;
        obj.byLabel = 'Assigned To';
        obj.byValue = item.assignedTo;
        break;

      case SOLVED:
        obj.atLabel = 'Solved At';
        obj.atValue = item.solvedAtLabel;
        obj.byLabel = 'Solved By';
        obj.byValue = item.solvedBy;
        break;

      default:
        obj.atLabel = 'At';
        obj.atValue = null;
        obj.byLabel = 'By/To';
        obj.byValue = null;
        break;
    }

    item.set('atLabel', obj.atLabel);
    item.set('atValue', obj.atValue);
    item.set('byLabel', obj.byLabel);
    item.set('byValue', obj.byValue);
    item.set('idLabel', `${PREFIX}${item.id}`);
  }
};

export const getDerivedData = (item = {}) => {
  let retVal = SKELETONS;

  if (item && !item.isEmpty) {
    const {
      assignedAt,
      closedAt,
      createdAdminFirstName,
      createdAdminLastName,
      createdAdminUserId,
      createdAt,
      fsp,
      histtran,
      openAt,
      solvedAt,
      updatedAt,
    } = item;

    item.set('assignedAtLabel', dateAndTime(assignedAt));
    item.set('closedAtLabel', dateAndTime(closedAt));
    item.set('createdAtLabel', dateAndTime(createdAt));
    item.set(
      'createdByLabel',
      `${createdAdminFirstName} ${createdAdminLastName} (${createdAdminUserId})`
    );
    item.set('fspNameDerived', fsp.name);
    item.set('payeeSendOnLabel', date(histtran.sendOn));
    item.set('payeeName', histtran.payeeName);
    item.set('paymentStatusDerived', histtran.status);
    item.set('openAtLabel', dateAndTime(openAt));
    item.set('solvedAtLabel', dateAndTime(solvedAt));
    item.set('updatedAtLabel', dateAndTime(updatedAt));

    item.set(
      'assignedTo',
      formatName(item.assignedAdminFirstName, item.assignedAdminLastName)
    );
    item.set(
      'closedBy',
      formatName(item.closedAdminFirstName, item.closedAdminLastName)
    );
    item.set(
      'solvedBy',
      formatName(item.solvedAdminFirstName, item.solvedAdminLastName)
    );

    item.set('updatedAtHumanize', durationHumanized(item.updatedAt));

    setDisplayItems(item);

    retVal = item.ref;
  }

  return retVal;
};

const selectorIdModel = createSelector(
  orm,
  (state, props) => props.id,
  ({ PaymentResearch }, id) => {
    let item;

    if (PaymentResearch.idExists(id)) {
      item = PaymentResearch.withId(id);
      item.set('isEmpty', false);
    } else {
      item = PaymentResearch.upsert({ id });
      item.set('isEmpty', true);
    }

    item.set('createdAtLabel', dateAndTime(item.createdAt));
    item.set('statusAtLabel', dateAndTime(item.statusAt));

    return item;
  }
);

const selectorPayerIdInner = createSelector(
  orm,
  (state, props) => props,
  ({ PaymentResearch }, id) =>
    PaymentResearch.filter(({ payerId }) => payerId === id).toModelArray()
);

export const selectorHistId = createSelector(
  orm,
  (state, historyId) => historyId,
  ({ PaymentResearch }, historyId) =>
    PaymentResearch.filter(({ histId }) => histId === historyId).toRefArray()
);

export const selectorPayerId = createReselectSelector(
  selectorPayerIdInner,
  (items) =>
    items.map((item) => {
      item.set('sendOnLabel', date(item.histtran?.sendOn));
      item.set('destination', item.histtran?.destination);

      return item.ref;
    })
);

export const selectorId = createSelector(orm.PaymentResearch);

export const derivedSelectorId = createReselectSelector(
  selectorIdModel,
  (item) => getDerivedData(item)
);
