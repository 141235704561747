import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { getValue } from '../consts';
import { useFormikField } from '../hooks';

const TextField = ({ onChange, ...props }) => {
  const [formikProps, field, helpers] = useFormikField(props);

  const handleChange = (event) => {
    const value = event?.target?.value === '' ? null : event.target.value;

    helpers.setValue(value);
    onChange(event);
  };

  return (
    <MuiTextField
      {...props}
      {...formikProps}
      onBlur={field.onBlur}
      onChange={handleChange}
      value={getValue(field)}
    />
  );
};

TextField.propTypes = {
  onChange: PropTypes.func,
};

TextField.defaultProps = {
  onChange: identity,
};

export default TextField;
