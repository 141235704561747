import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';

const CORE_PROCESSORS = 'coreprocessors';

export const create = (data, auth) =>
  client.executeRequest(POST, `/${CORE_PROCESSORS}`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'coreprocessor' },
  });

export const load = (params) =>
  client.executeRequest(GET, `/${CORE_PROCESSORS}`, {
    queryParams: params,
    returnModel: { name: 'coreprocessor' },
  });

export const remove = ({ id }, auth) =>
  client.executeRequest(DELETE, `/${CORE_PROCESSORS}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'coreprocessor' },
  });

export const update = ({ id, ...data }, auth) =>
  client.executeRequest(POST, `/${CORE_PROCESSORS}/${id}`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'coreprocessor' },
  });
