import LoadingSkeleton from 'react-loading-skeleton';
import { createSelector } from 'redux-orm';
import { createSelector as createReselectSelector } from 'reselect';
import { orm } from 'model';
import { withStyles } from '@material-ui/core';
import { dateAndTime, getDdaTypeDerived, trimmedDisplayValue } from '../util';
import { ACTIVE, DEFAULT_MODEL_REF_ARRAY } from '../consts';

const IS_EMPTY = 'isEmpty';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
});
const Skeleton = withStyles(styles)(({ classes, ...props }) => (
  <p className={classes.root}>
    <LoadingSkeleton {...props} />
  </p>
));
const SKELETON_50 = <Skeleton width="50%" />;
const SKELETONS = {
  ddaTypeDerived: SKELETON_50,
  achRtn: SKELETON_50,
  [IS_EMPTY]: true,
};

const getDerivedData = (item) => {
  let data = SKELETONS;

  if (item && !item.isEmpty) {
    item.set('ddaTypeDerived', getDdaTypeDerived(item.ddaType));
    item.set('descriptionDerived', trimmedDisplayValue(item.description));
    item.set(
      'externalDdaIdStrDerived',
      trimmedDisplayValue(item.externalDdaIdStr)
    );
    item.set('externalDdaStatusAtLabel', dateAndTime(item.externalDdaStatusAt));
    item.set('nickName', trimmedDisplayValue(item.nickName));

    data = item.ref;
  }

  return data;
};

const selector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, payerId) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).externalAccounts.all().toModelArray()
      : DEFAULT_MODEL_REF_ARRAY
);

export const selectorCountByPayerId = createReselectSelector(
  selector,
  (items) => items.filter((item) => item.externalDdaStatus === ACTIVE).length
);

const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ ExternalAccount }, id) => {
    let item;
    if (ExternalAccount.idExists(id)) {
      item = ExternalAccount.withId(id);
      item.set(IS_EMPTY, false);
    } else {
      item = ExternalAccount.upsert({ id });
      item.set(IS_EMPTY, true);
    }
    return item;
  }
);

export const derivedSelector = createReselectSelector(selector, (items) =>
  items.map(getDerivedData)
);

export const derivedSelectorId = createReselectSelector(
  selectorId,
  getDerivedData
);

export const selectorCount = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, payerId) => {
    if (Payer.idExists(payerId)) {
      return Payer.withId(payerId).externalAccounts.all().count();
    }
    return 0;
  }
);
