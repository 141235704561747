import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import { add, selector } from 'reducers/tableEmptyMessage';
import { isLoadingState } from 'reducers/loading';
import { useDispatch, useSelector } from 'react-redux';

const EmptyMessageRow = ({
  actionConstant,
  colSpan,
  rowCount,
  subHeading,
  title,
}) => {
  const dispatch = useDispatch();
  const actions = useSelector(selector);
  const executing = useSelector((state) =>
    isLoadingState(state, actionConstant.ACTION)
  );

  const hasNotBeenHereYet = !actions.includes(actionConstant.ACTION);

  useEffect(() => {
    if (executing && hasNotBeenHereYet) {
      dispatch(add(actionConstant.ACTION));
    }
  }, [actionConstant.ACTION, dispatch, hasNotBeenHereYet, executing]);

  return hasNotBeenHereYet || rowCount !== 0 || executing ? null : (
    <TableRow>
      <TableCell align="center" colSpan={colSpan}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{subHeading}</Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

EmptyMessageRow.propTypes = {
  actionConstant: PropTypes.object.isRequired,
  colSpan: PropTypes.number,
  rowCount: PropTypes.number,
  subHeading: PropTypes.string,
  title: PropTypes.string,
};

EmptyMessageRow.defaultProps = {
  colSpan: 0,
  rowCount: 0,
  subHeading: '',
  title: '',
};

export default memo(EmptyMessageRow);
