import { selectorPayerId } from 'selectors/payerUser';
import PropTypes from 'prop-types';
import { BUSINESS_CODE } from 'consts';
import { useSelector } from 'react-redux';
import PayerButton from './PayerButton';
import PayerUserButton from './PayerUserButton';

const PayerOrPayerUserButton = ({
  fspId,
  onPayerClick,
  onPayerUserClick,
  payerAction,
  payerButtonText,
  payerId,
  payerType,
  payerUser,
  payerUserAction,
  payerUserButtonText,
  selector,
  startIcon,
  submenu,
  transactAs,
}) => {
  const items = useSelector((state) => selectorPayerId(state, payerId));

  return items.length <= 1 && payerType !== BUSINESS_CODE ? (
    <PayerButton
      action={payerAction}
      onClick={onPayerClick}
      payerId={payerId}
      selector={selector}
      startIcon={startIcon}
      text={payerButtonText}
      transactAs={transactAs}
    />
  ) : (
    <PayerUserButton
      action={payerUserAction}
      fspId={fspId}
      onClick={onPayerUserClick}
      payerId={payerId}
      payerUser={payerUser}
      selector={selector}
      startIcon={startIcon}
      submenu={submenu}
      text={payerUserButtonText}
      transactAs={transactAs}
    />
  );
};

PayerOrPayerUserButton.propTypes = {
  fspId: PropTypes.number,
  onPayerClick: PropTypes.func.isRequired,
  onPayerUserClick: PropTypes.func.isRequired,
  payerAction: PropTypes.object,
  payerButtonText: PropTypes.string.isRequired,
  payerId: PropTypes.number.isRequired,
  payerType: PropTypes.string,
  payerUser: PropTypes.object,
  payerUserAction: PropTypes.object,
  payerUserButtonText: PropTypes.string.isRequired,
  selector: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  submenu: PropTypes.bool,
  transactAs: PropTypes.bool,
};

PayerOrPayerUserButton.defaultProps = {
  fspId: undefined,
  payerAction: {},
  payerType: null,
  payerUser: null,
  payerUserAction: {},
  startIcon: undefined,
  submenu: false,
  transactAs: false,
};

export default PayerOrPayerUserButton;
