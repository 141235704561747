import moment from 'moment';
import {
  BUSINESS_CODE,
  NA_VALUE,
  PAYER_STATUS_ACTIVE,
  PAYER_STATUS_SUSPENDED,
  PERSON_CODE,
} from 'consts';
import { Business, Person } from '@material-ui/icons';
import { isNil, not } from 'ramda';
import { dateAndTime } from './dateAndTime';
import { getZipForDisplay } from './address';
import { getPhoneForDisplay } from './phone';

const INTERNATIONAL_KEYS = [
  'address1',
  'address2',
  'address3',
  'address4',
  'adminArea',
  'country',
  'locality',
  'postalCode',
];
const USPS_KEYS = ['city', 'state', 'street1', 'street2', 'zip'];

export const isPayerActive = (payer) => payer?.status === PAYER_STATUS_ACTIVE;

export const isPerson = ({ payerType }) => payerType === PERSON_CODE;

export const getAddress = ({ internationalAddress = {}, uspsAddress = {} }) => {
  let address;
  let cityState;
  let code;
  const hasInternationalAddress =
    internationalAddress &&
    INTERNATIONAL_KEYS.some((key) => not(isNil(internationalAddress[key])));
  let onlyInternational = false;

  if (uspsAddress && USPS_KEYS.some((key) => not(isNil(uspsAddress[key])))) {
    const { city, state, street1, street2, zip } = uspsAddress;

    address = `${street1 ? street1.trim() : NA_VALUE}${
      street2 ? `, ${street2.trim()}` : ''
    }`;
    cityState = `${city ? city.trim() : NA_VALUE}, ${
      state ? state.trim() : NA_VALUE
    }`;
    code = zip ? getZipForDisplay(zip) : NA_VALUE;
  } else {
    onlyInternational = true;
  }

  return {
    address,
    cityState,
    code,
    hasInternationalAddress,
    onlyInternational,
  };
};

export const getLabel = (payer = {}) => {
  const profile = getProfile(payer);
  let retVal = NA_VALUE;

  if (isPerson(payer)) {
    const { firstName, lastName, middleName } = profile;

    retVal = `${firstName || NA_VALUE}${middleName ? ` ${middleName} ` : ' '}${
      lastName || NA_VALUE
    }`;
  } else if (profile.name) {
    retVal = profile.name;
  }

  return retVal;
};

export const getStatus = ({ status }) => {
  if (status !== PAYER_STATUS_SUSPENDED) {
    return `made ${status}`;
  }

  return status;
};

export const getLabelNoId = (payer, label) => {
  return `${label || getLabel(payer)}`;
};

export const getLabelWithId = (payer, label) => {
  return `${label || getLabel(payer)} (${payer.id})`;
};

export const getProfile = (payer = {}) => {
  return isPerson(payer) ? payer.person || {} : payer.organization || {};
};

export const addDerivedData = (item) => {
  item.payerType = item.payerType?.toLowerCase();
  const isPersonProp = isPerson(item);
  const label = getLabel(item);
  const profile = getProfile(item);
  const payerIcon = item.payerType === BUSINESS_CODE ? Business : Person;
  const numberToUse = profile.mobilePhone ? profile.mobilePhone : profile.phone;
  const moreNumbers = [];
  const phone = getPhoneForDisplay(numberToUse);

  if (isPersonProp) {
    if (profile.homePhone) {
      moreNumbers.push({
        key: `${item.id}payerHome`,
        label: 'Home',
        value: getPhoneForDisplay(profile.homePhone),
      });
    }

    if (profile.workPhone) {
      moreNumbers.push({
        key: `${item.id}payerWork`,
        label: 'Work',
        value: getPhoneForDisplay(profile.workPhone),
      });
    }
  }

  const {
    address,
    cityState,
    code,
    hasInternationalAddress,
    onlyInternational,
  } = getAddress(item);

  item.address = address;
  item.cityState = cityState;
  item.code = code;
  item.email = profile.email;
  item.hasInternationalAddress = hasInternationalAddress;
  item.idLabel = isPersonProp ? 'SSN' : 'Tax ID';
  item.idValue = item.taxIdMask;
  item.isPerson = isPersonProp;
  item.label = label;
  item.labelWithId = getLabelWithId(item, label);
  item.labelNoId = getLabelNoId(item, label);
  item.moreNumbers = moreNumbers;
  item.name = getLabel(item);
  item.numberToUse = numberToUse;
  item.onlyInternational = onlyInternational;
  item.payerIcon = payerIcon;
  item.payerId = item.id;
  item.phone = phone;
  item.phoneLabel = isPersonProp ? 'Mobile' : 'Phone';
  item.primary = getLabel(item);
  item.serviceStartOnLabel = moment(item.serviceStartOn).format();
  item.statusAtLabel = dateAndTime(item.statusAt);

  return item;
};
