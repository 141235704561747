import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  passcodeLength,
  pastePasscode,
  removeDigit,
  updateActivePosition,
  updateDigit,
  useOneTimePasscode,
} from '../OneTimePasscodeContext';

const isNumber = /^\d+$/;

const useStyles = makeStyles((theme) => ({
  active: {
    borderColor: theme.palette.primary.light,
    borderStyle: 'solid',
    borderWidth: 2,
  },
  container: {
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(9.9),
    width: theme.spacing(7.65),
  },
  hasValue: {
    borderColor: theme.palette.grey[600],
    borderStyle: 'solid',
    borderWidth: 2,
  },
  text: {
    ...theme.typography.h3,
    background: 'transparent',
    border: 0,
    caretColor: 'transparent',
    color: theme.palette.text.primary,
    height: '100%',
    outline: 'none',
    textAlign: 'center',
    width: '100%',
  },
}));

const Digit = ({ position }) => {
  const classes = useStyles();
  const inputRef = useRef();
  const { dispatch, state, login } = useOneTimePasscode();

  const { activePosition, digitCount, inputDisabled, passcode } = state;
  const active = activePosition === position;
  const ariaLabel = `passcode-input-${position + 1}`;
  const value = passcode[position];

  const focus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleClick = () => {
    dispatch(updateActivePosition(position));
  };
  const handleChange = (event) => {
    dispatch(updateDigit(event, position));
  };
  const handleKeyDown = (event) => {
    // Order here matters
    if (event.key === 'Enter') {
      if (digitCount === passcodeLength || digitCount === passcodeLength - 1) {
        login();
      }
      return;
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      dispatch(removeDigit(position));
    }
    if (event.metaKey) {
      return;
    }
    if (!isNumber.test(event.key)) {
      event.preventDefault();
    }
  };
  const handlePaste = (event) => {
    dispatch(pastePasscode(event));
  };

  useEffect(() => {
    if (active) {
      focus();
    }
  }, [active]);

  return (
    <Grid
      alignItems="center"
      className={classNames(classes.container, {
        [classes.active]: active,
        [classes.hasValue]: !active && value,
      })}
      container
      justifyContent="center"
      onClick={handleClick}
    >
      <Grid item>
        <input
          ref={inputRef}
          aria-label={ariaLabel}
          className={classes.text}
          disabled={inputDisabled}
          inputMode="numeric"
          onChange={handleChange}
          onFocus={focus}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

Digit.propTypes = {
  position: PropTypes.number.isRequired,
};

export default memo(Digit);
