import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { addDerived, DATA_KEY_DIRECT } from './util/recipient';
import { createAction, createRequestConst, createUpdateConst } from '../util';

export default class RecipientDirect extends Model {
  static get modelName() {
    return 'RecipientDirect';
  }

  static reducer({ type, data }, RecipientDirect, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId)[DATA_KEY_DIRECT].delete();
        }

        if (data.response) {
          data.response.forEach((r) => RecipientDirect.upsert(addDerived(r)));
        }
        break;

      case UPDATE.SUCCESS:
        RecipientDirect.withId(data.response.id).update(
          addDerived(data.response)
        );
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      accountType: attr(),
      createdAt: attr(),
      dcAccountMask: attr(),
      dcAccountToken: attr(),
      dcAccountType: attr(),
      dcExpireMonth: attr(),
      dcExpireYear: attr(),
      dcFeeAmount: attr(),
      dcHolderName: attr(),
      ddaAchRtn: attr(),
      ddaNbrMask: attr(),
      deliver: attr(),
      deliverElecNowOffOn: attr(),
      deliverElecStdOffOn: attr(),
      deliverMethod: attr(),
      disburseType: attr(),
      elecName: attr(),
      email: attr(),
      failReason: attr(),
      firstName: attr(),
      fsp: attr(),
      fspId: attr(),
      group: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      lastName: attr(),
      lastScheduleCreatedAt: attr(),
      lastTryCnt: attr(),
      lastTryCntAt: attr(),
      maxTries: attr(),
      microService: attr(),
      mobilePhone: attr(),
      nickName: attr(),
      notifyEmailOffOn: attr(),
      notifyMobilePhoneOffOn: attr(),
      offset: attr(),
      p2pDirectOffOn: attr(),
      payer: attr(),
      payerId: fk(Payer.modelName, DATA_KEY_DIRECT),
      previousRecipientStatus: attr(),
      recipientStatus: attr(),
      recipientStatusAt: attr(),
      recipientStatusText: attr(),
      rtnFiName: attr(),
      securityAnswer: attr(),
      securityQuestion: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      validEmail: attr(),
      validFirstName: attr(),
      validLastName: attr(),
      validMobilePhone: attr(),
      validNotifyEmailOffOn: attr(),
      validNotifyMobilePhoneOffOn: attr(),
      venmoReceiver: attr(),
      venmoRecipientType: attr(),

      // derived fields
      notificationMethodDerived: attr(),
      mobileDisplay: attr(),
      name: attr(),
      status: attr(),
    };
  }
}

export const LOAD = createRequestConst(RecipientDirect.modelName);
export const UPDATE = createUpdateConst(RecipientDirect.modelName);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
