import { memo } from 'react';
import { LOAD } from 'model/notification';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import NotificationList from './NotificationList';
import PayerDetailPanel from '../PayerDetailPanel';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';

const Notifications = (props) => {
  const showProgress = useSelector((state) => selector(state, LOAD));

  return (
    <PayerDetailPanel
      heightOffset={HEIGHT_OFFSET}
      icon={NotificationsIcon}
      id="notifications"
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={showProgress}
      title="Notifications And Alerts"
      {...props}
    >
      <NotificationList {...props} />
    </PayerDetailPanel>
  );
};

export default memo(Notifications);
