import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1.5),
  },
}));

const TextSkeleton = ({ className, height, width, ...props }) => {
  const classes = useStyles();

  return (
    <Skeleton
      animation="wave"
      className={className || classes.root}
      height={height}
      variant="text"
      width={width}
      {...props}
    />
  );
};

TextSkeleton.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextSkeleton.defaultProps = {
  className: null,
  height: 24,
  width: '50%',
};

export default TextSkeleton;
