import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class SchedTranFraud extends Model {
  static get modelName() {
    return 'SchedTranFraud';
  }

  static reducer({ type, data }, SchedTranFraud) {
    switch (type) {
      case LOAD_ID.SUCCESS:
        SchedTranFraud.upsert(data?.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      fraudMonitor: attr(),
      fspId: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUserId: attr(),
      schedId: attr(),
      updAdminUserId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = SchedTranFraud.modelName;

export const LOAD_ID = createLoadConst(`${name}_id`);

export const loadId = createAction(LOAD_ID);
