import { createSelector } from 'reselect';
import {
  createDeleteConst,
  createAction,
  deleteSuccessful,
} from 'util/actions';

const REFRESH = 'refresh';

export const CLEAR = createDeleteConst(REFRESH);

export const clearRefresh = createAction(CLEAR);

const getRefresh = (state) => state.refresh;
export const getRefreshType = (state, props) => props.refreshType;

export const isRefresh = createSelector(
  [getRefresh, getRefreshType],
  (refresh, refreshType) => refresh === refreshType
);

const initialState = '';

export default (state = initialState, { type }) => {
  switch (type) {
    case CLEAR.ACTION:
      return initialState;
    default:
      if (deleteSuccessful(type)) {
        return type;
      }
      return state;
  }
};
