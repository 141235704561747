import PropTypes from 'prop-types';
import { branch, compose, pure, renderComponent } from 'recompose';
import { prop } from 'ramda';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = () => ({
  progress: {
    left: '50%',
    position: 'absolute',
    top: '50%',
  },
});

const Loading = ({ children, classes, isLoading, show }) => {
  return (
    <>
      {(isLoading || show) && (
        <CircularProgress className={classes.progress} size={50} />
      )}
      {children}
    </>
  );
};

Loading.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  show: PropTypes.bool,
};

Loading.defaultProps = {
  children: null,
  isLoading: false,
  show: false,
};

const ConnectedLoading = compose(withStyles(styles), pure)(Loading);

export const LoadingBranch = branch(
  prop('isLoading'),
  renderComponent(ConnectedLoading)
);

export default ConnectedLoading;
