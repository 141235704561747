import { CardHeader } from '@material-ui/core';
import { useOneTimePasscode } from './OneTimePasscodeContext';

const subheader = 'Enter the code sent to ';
const subheaderTypographyProps = { color: 'textSecondary', variant: 'body1' };
const titleTypographyProps = { color: 'textPrimary', variant: 'h5' };
const title = '2-Step Verification';

const OneTimePasscodeHeader = () => {
  const { username } = useOneTimePasscode();

  return (
    <CardHeader
      subheader={`${subheader}${username ?? ''}`}
      subheaderTypographyProps={subheaderTypographyProps}
      title={title}
      titleTypographyProps={titleTypographyProps}
    />
  );
};

export default OneTimePasscodeHeader;
