import {
  date,
  dateAndTime,
  formatCurrency,
  getFirstEntryInArrayAsInteger,
  durationHumanized,
} from 'util/index';
import { getType } from 'components/modules/service/PayerDetail/Transactions/config/transactions';
import { resultReasons } from 'components/modules/review/Fraud/Reason/consts';
import { NA_VALUE } from 'consts';
import {
  getFrequency,
  getP2PDisbursementType,
  getDeliveryMethod,
} from './transaction';
import { convertToStartCase } from '../../util/format';

const wasAndNextButtonPressed = (data) => {
  return (
    data?.response?.length === 1 &&
    data?.props?.buttonText?.toLowerCase().includes('next')
  );
};

const weightedEntries = [
  {
    key: 0,
    primary: 'Transaction Notes',
    score: 55,
    secondary: [
      {
        key: 0,
        text: 'Real-time delivery speed',
      },
      {
        key: 1,
        text: 'Transaction amount exceeds P2P low threshold of $400',
      },
    ],
  },
  {
    key: 1,
    primary: 'Behavior Notes',
    score: 33,
    secondary: [
      {
        key: 0,
        text: 'Transaction has not been validated through OTP',
      },
    ],
  },
  {
    key: 2,
    primary: 'User Profile Notes',
    score: 12,
    secondary: [
      {
        key: 0,
        text: 'Multiple payers sent payments to a single bank',
      },
    ],
  },
];

const getReasonDisplayName = (reasonId) => {
  return resultReasons.find(({ id }) => id === reasonId)?.name ?? reasonId;
};

export const derived = (item) => {
  return {
    ...item,
    accountCreatedAtDerived: dateAndTime(item?.accountCreatedAt),
    accountCreatedOnDerived: date(item?.accountCreatedAt),
    amountDerived: formatCurrency(item?.amount),
    canceledAtDerived: dateAndTime(item?.canceledAt),
    createdAtDerived: dateAndTime(item?.createdAt),
    deliverDerived: getDeliveryMethod(item, true),
    deliverOnDerived: dateAndTime(item?.deliverOn),
    deliveryCountDerived: item?.deliveryCount ?? NA_VALUE,
    disburseTypeDerived: getP2PDisbursementType(item?.disburseType),
    frequencyLabel: getFrequency(item?.frequency),
    isNextDerived: false,
    onholdMaxMinutesAtDateDerived: dateAndTime(item?.onholdMaxMinutesAt),
    onholdMaxMinutesAtDerived: durationHumanized(item?.onholdMaxMinutesAt),
    reviewedAtDerived: dateAndTime(item?.reviewedAt),
    resultReasonDerived: getReasonDisplayName(item?.resultReason),
    sendOnDerived: date(item?.sendOn),
    statusDerived: convertToStartCase(item.status),
    transactionTypeDerived: getType(item.transactionType),
    ...weightedEntries,
  };
};

export const markAsNext = (data, FSPFraudTrans) => {
  if (wasAndNextButtonPressed(data)) {
    const id = getFirstEntryInArrayAsInteger(data?.response);
    const items = FSPFraudTrans.all().toRefArray();
    const pos = items.findIndex((item) => item.id === id);
    const nextId = items[pos + 1]?.id;

    if (nextId) {
      FSPFraudTrans.withId(nextId).update({ isNextDerived: true });
    }
  }
};
