import { connect } from 'react-redux';
import { Redirect as RedirectRoute } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose, lifecycle } from 'recompose';
import { clearRedirect } from 'reducers/redirect';

const Redirect = ({ redirectUrl }) => {
  return <RedirectRoute to={redirectUrl} />;
};

Redirect.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
};

export default compose(
  connect(null, { clearRedirect }),
  lifecycle({
    componentDidMount() {
      this.props.clearRedirect();
    },
  })
)(Redirect);
