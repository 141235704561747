import { date, dateAndTime, formatCurrency } from 'util/index';
import { NA_VALUE, OFF, ON, REFUNDED, RETURNED } from 'consts';
import { formatPhone } from 'util/format';

const formatOffOnAsYesNo = (value) =>
  value === ON ? 'Yes' : value === OFF ? 'No' : NA_VALUE;

export const isHighlightedItem = (status) => {
  return [REFUNDED, RETURNED].includes(status);
};

export const addDerived = (item) => {
  item.accountNumberOnBillMaskLabel = item?.accountNumberOnBillMask
    ? `****${item?.accountNumberOnBillMask}`
    : NA_VALUE;
  item.billerContactPhoneLabel = formatPhone(
    item?.billerContactPhone,
    NA_VALUE
  );
  item.primary = item.reasonName;
  item.secondary = `P${item.id} - ${item.payerElecName || item.payerCheckName}`;
  item.payerLabel = item.histtran?.payerName;
  item.statusAtLabel = dateAndTime(item?.statusAt);
  item.postedAmountLabel = formatCurrency(item?.postedAmount);
  item.paymentSentMultipleTimesOffOnLabel = formatOffOnAsYesNo(
    item?.paymentSentMultipleTimesOffOn
  );
  item.correctAccountNumberEnteredOffOnLabel = formatOffOnAsYesNo(
    item?.correctAccountNumberEnteredOffOn
  );
  item.newAccountNumberOffOnLabel = formatOffOnAsYesNo(
    item?.newAccountNumberOffOn
  );
  item.paymentPostedToActiveBillerOffOnLabel = formatOffOnAsYesNo(
    item?.paymentPostedToActiveBillerOffOn
  );
  item.billDueDateLabel = date(item.billDueDate);
  item.isHighlighted = isHighlightedItem(item.histtran?.status);

  return item;
};
