import { isEmpty } from 'ramda';
import { isNilOrEmpty } from './consts';

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$%#@!^&*()}{[];:.,<>/?|~';
export const emptyAsNull = (value) => (isEmpty(value) ? null : value);

export const convertToNumber = (value, emptyAsNull = false) => {
  if (isEmpty(value) && emptyAsNull) return null;

  if (typeof value === 'number' || isNilOrEmpty(value)) return value;

  return Number((value || '').replace(/[^0-9.]/g, ''));
};

export const convertToNumberEmptyAsNull = (value) =>
  convertToNumber(value, true);

export const generateRandomString = (length = 15) => {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const convertStringToKebabCase = (value) => {
  if (!value) return '';
  return value.replace(/\s+/g, '-').toLowerCase();
};
