import { takeEvery, takeLatest } from 'redux-saga/effects';
import { create, load, loadById, update } from 'sdk/fsp';
import { LOAD, LOAD_ID, THROTTLED_LOAD, CREATE, UPDATE } from 'model/fsp';
import { callAction, ORM } from '../util';

export const updateFsp = () =>
  callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: (data) =>
      `FSP "${data?.response?.name || ''}" has been successfully updated`,
    type: UPDATE,
  });

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `FSP "${data?.response?.name || ''}" has been successfully created`,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeLatest(
    THROTTLED_LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: THROTTLED_LOAD,
    })
  );

  yield takeEvery(UPDATE.ACTION, updateFsp());
}
