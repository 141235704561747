import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { convertToDays } from '../util';

export const selectorCount = createSelector(
  orm,
  (state, props) => props,
  ({ InternalAccountAudit }, { id }) =>
    InternalAccountAudit.filter(({ ddaId }) => ddaId === id).count()
);

const selector = createSelector(
  orm,
  (state, props) => props,
  ({ InternalAccountAudit }, { id }) =>
    InternalAccountAudit.filter(({ ddaId }) => ddaId === id).toModelArray()
);

export const selectorGroupedByDays = createReselectSelector(selector, (items) =>
  items.reduce(convertToDays, [])
);
