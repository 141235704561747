import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({ item }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Account ID" value={item.accountId} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Account Verification Status"
          value={item.accountVerificationStatus}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Created At" value={item.createdAtLabel} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Institution Name"
          value={item.institutionName}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Request ID" value={item.requestId} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Request Type" value={item.requestType} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="External DDA ID"
          value={item.externalDdaId}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="ID" value={item.id} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Institution ID"
          value={item.institutionId}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Error Code" value={item.errorCode} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Error Message" value={item.errorMessage} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Error Type" value={item.errorType} />
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DetailsRender;
