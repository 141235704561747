import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class FinancialInstitution extends Model {
  static get modelName() {
    return 'FinancialInstitution';
  }

  static reducer({ type, data }, FinancialInstitution) {
    switch (type) {
      case LOAD.SUCCESS:
        FinancialInstitution.delete();
        data.forEach((r) => FinancialInstitution.upsert(r));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      channel: attr(),
      createdAt: attr(),
      database: attr(),
      description: attr(),
      distributor: attr(),
      erouteID: attr(),
      fspStatus: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      routeID: attr(),
      shortName: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createRequestConst(FinancialInstitution.modelName);
export const load = createAction(LOAD);
