import PropTypes from 'prop-types';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { Field } from 'redux-form';
import { Password, renderTextField } from 'components/common/fields/render';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
}));

const PasswordVerifyContent = ({ isChange, submitting }) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Field
            autoComplete="username"
            autoFocus={!isChange}
            classes={{ root: classes.input }}
            component={renderTextField}
            disabled={submitting || isChange}
            fullWidth
            label="Email"
            name="username"
          />
        </Grid>
        {isChange && (
          <Grid item>
            <Typography variant="subtitle2">
              To continue, first verify it&#39;s you
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Field
            autoComplete="new-password"
            autoFocus={isChange}
            component={Password}
            disabled={submitting}
            fullWidth
            label="Password"
            name="password"
            type="password"
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

PasswordVerifyContent.propTypes = {
  isChange: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default PasswordVerifyContent;
