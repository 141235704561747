import { useField } from 'formik';

const useFieldWithErrors = (name) => {
  const [field, meta, helpers] = useField(name);
  const errorProps =
    meta.touched && meta.error ? { error: true, helperText: meta.error } : {};
  return [field, meta, helpers, errorProps];
};

export default useFieldWithErrors;
