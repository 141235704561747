import PropTypes from 'prop-types';
import { ClickAwayListener, Paper } from '@material-ui/core';
import MenuList from './MenuList';

const PopperPaper = ({ anchorEl, onClickAway, ...props }) => {
  return (
    <Paper>
      <ClickAwayListener onClickAway={onClickAway}>
        <MenuList anchorEl={anchorEl} {...props} />
      </ClickAwayListener>
    </Paper>
  );
};

PopperPaper.propTypes = {
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func.isRequired,
};

PopperPaper.defaultProps = {
  anchorEl: null,
};

export default PopperPaper;
