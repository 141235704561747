import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { completed } from 'selectors/request';
import { clear } from 'reducers/request';

const useRequestCompleteEffect = (requestConst, callback, id = 0) => {
  const complete = useSelector((state) => completed(state, requestConst, id));
  const dispatch = useDispatch();

  useEffect(() => {
    if (complete) {
      callback();
    }

    return () => {
      dispatch(clear(requestConst));
    };
  }, [callback, complete, dispatch, requestConst]);
};

export default useRequestCompleteEffect;
