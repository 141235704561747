import PropTypes from 'prop-types';
import { memo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { defaultTo, isEmpty } from 'ramda';
import { Grid } from '@material-ui/core';

export const FORMAT = 'YYYY-MM-DD';

const formatChange = ({ start, end }) =>
  isEmpty(start) && isEmpty(end) ? [] : [start, end];

const Range = ({
  component: InputComponent,
  endPlaceholder,
  id,
  onChange,
  startPlaceholder,
  className,
  value,
  direction,
  classes,
}) => {
  const [range, setRange] = useState({ start: '', end: '' });

  useEffect(() => {
    setRange(
      value
        ? {
            start: defaultTo('', value[0]),
            end: defaultTo('', value[1]),
          }
        : { start: '', end: '' }
    );
  }, [value, setRange]);

  const handleEndChange = (event) => {
    range.end =
      event && event.target
        ? event.target.value
        : event && event.format
        ? event.format(FORMAT)
        : '';
    setRange(range);
    onChange(formatChange(range));
  };
  const handleStartChange = (event) => {
    range.start =
      event && event.target
        ? event.target.value
        : event && event.format
        ? event.format(FORMAT)
        : '';
    setRange(range);
    onChange(formatChange(range));
  };

  return (
    <Grid
      container
      direction={direction}
      spacing={direction === 'row' ? 1 : undefined}
    >
      <Grid item>
        <InputComponent
          className={classNames(classes.root, className)}
          id={`start${id}`}
          onChange={handleStartChange}
          placeholder={startPlaceholder}
          value={range.start}
        />
      </Grid>
      <Grid item>
        <InputComponent
          className={classNames(classes.root, className)}
          id={`end${id}`}
          onChange={handleEndChange}
          placeholder={endPlaceholder}
          value={range.end}
        />
      </Grid>
    </Grid>
  );
};

Range.propTypes = {
  component: PropTypes.elementType.isRequired,
  endPlaceholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  startPlaceholder: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  direction: PropTypes.oneOf(['column', 'row']),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

Range.defaultProps = {
  endPlaceholder: null,
  startPlaceholder: null,
  className: undefined,
  classes: { root: undefined },
  direction: 'column',
  value: null,
};

export default memo(Range);
