import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ } from './consts';
import { convertToNumber } from '../../../util/general';

const useStyles = makeStyles(() => ({
  text: {
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
}));

const NoOptionsText = ({ idSearch, text, value, isNumberCheck }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-restricted-globals
  const isNotNumber = isNaN(value);

  return idSearch && value.length < NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ ? (
    `A minimum of ${NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ} characters are needed`
  ) : isNumberCheck && isNotNumber ? (
    'only numeric values allowed.'
  ) : (
    <span>
      {text}
      <span className={classes.text}>{value}</span>
    </span>
  );
};

NoOptionsText.propTypes = {
  idSearch: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.string,
  isNumberCheck: PropTypes.bool,
};

NoOptionsText.defaultProps = {
  idSearch: false,
  text: '',
  value: '',
  isNumberCheck: false,
};

export default NoOptionsText;
