import { takeEvery } from 'redux-saga/effects';
import { load, update } from 'sdk/recipient';
import { LOAD, UPDATE } from 'model/recipientDirect';
import { getName } from 'model/util/recipient';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `${getName(response)} has been successfully updated`,
      type: UPDATE,
    })
  );
}
