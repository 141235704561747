import { ParamSearchType } from 'components/common/SearchTextField/Multiple/consts';

export const params = [
  {
    displayName: 'Biller Name',
    field: 'billerName',
    type: ParamSearchType.String,
  },
  {
    displayName: 'Biller Id External',
    field: 'billerIdExternal',
    type: ParamSearchType.String,
  },
  {
    displayName: 'Biller Id Internal',
    field: 'billerIdInternal',
    type: ParamSearchType.Number,
  },
  {
    displayName: 'Network',
    field: 'network',
    type: ParamSearchType.Option,
    valueOptions: [{ displayName: 'RPPS', id: 'rpps' }],
  },
  {
    displayName: 'Delivery Count',
    field: 'deliveryCount',
    type: ParamSearchType.NumberRange,
  },
];

export const searchParam = {
  displayName: 'Score Range',
  field: 'fraudScore',
  operator: 'fieldRange',
  props: {
    from: { helperText: 'min 0', label: 'Minimum Score' },
    to: { helperText: 'max 100', label: 'Maximum Score' },
  },
  type: ParamSearchType.NumberRange,
};
