import { compose, pure, withProps } from 'recompose';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core';
import IconButton from './icon_button';

const styles = () => ({
  root: {},
  iconComponent: {},
  label: {},
});

export default withProps(() => ({ IconComponent: EditIcon }))(
  compose(withStyles(styles), pure)(IconButton)
);
