import {
  add,
  importAndReset,
  load,
  loadFractionalNumber,
  remove,
  update,
} from 'sdk/rtn';
import {
  ADD,
  LOAD,
  LOAD_FRACTIONAL_NUMBER,
  REMOVE_FRACTIONAL_NUMBER,
  REMOVE,
  UPDATE,
  IMPORT,
} from 'model/rtn';
import { callAction, ORM } from 'util/index';
import { put, takeEvery } from 'redux-saga/effects';
import { loadFSPProfileAction } from './export';

function* addAndPopulateExport(action) {
  const addAction = callAction({
    api: add,
    dataName: ORM,
    formatSuccessMessage: () => 'Routing Numbers were successfully added',
    type: ADD,
  });

  if (yield addAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export const importRtn = () =>
  callAction({
    api: importAndReset,
    dataName: ORM,
    formatSuccessMessage: () => 'Routing Numbers were imported',
    type: IMPORT,
  });

function* importAndPopulateExport(action) {
  const importAndResetAction = importRtn();

  if (yield importAndResetAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* removeAndPopulateExport(action) {
  const removeAction = callAction({
    api: remove,
    dataName: ORM,
    formatSuccessMessage: () => 'Routing Number was removed successfully',
    type: REMOVE,
  });

  if (yield removeAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: () => 'Routing Number was updated',
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

function* removeGeneratedFractionalNumber({ data }) {
  yield put({
    type: REMOVE_FRACTIONAL_NUMBER.SUCCESS,
    data: { props: { id: data?.id } },
  });
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_FRACTIONAL_NUMBER.ACTION,
    callAction({
      api: loadFractionalNumber,
      dataName: ORM,
      type: LOAD_FRACTIONAL_NUMBER,
    })
  );

  yield takeEvery(ADD.ACTION, addAndPopulateExport);

  yield takeEvery(IMPORT.ACTION, importAndPopulateExport);

  yield takeEvery(REMOVE.ACTION, removeAndPopulateExport);

  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);

  yield takeEvery(
    REMOVE_FRACTIONAL_NUMBER.ACTION,
    removeGeneratedFractionalNumber
  );
}
