import { takeEvery, takeLatest } from 'redux-saga/effects';
import { create, loadId, load, remove, update } from 'sdk/channel';
import {
  ADD,
  LOAD,
  LOAD_ID,
  THROTTLED_LOAD,
  REMOVE,
  UPDATE,
} from 'model/channel';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      type: ADD,
      api: create,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `${data?.response?.name ?? ''} Channel has been successfully created.`,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      type: LOAD,
      api: load,
      dataName: ORM,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      type: LOAD_ID,
      api: loadId,
      dataName: ORM,
    })
  );

  yield takeLatest(
    THROTTLED_LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: THROTTLED_LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      type: UPDATE,
      api: update,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `${data?.response?.name ?? ''} Channel has been successfully updated.`,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      type: REMOVE,
      api: remove,
      formatSuccessMessage: () =>
        'One or more Channels have been successfully removed.',
    })
  );
}
