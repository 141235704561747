import { client } from 'util/sdk';
import { GET } from 'util/api';
import { DESC } from 'consts';

const MODEL_NAME = 'ssoerror';
const DEFAULT_ORDER = `createdat:${DESC}`;

export default ({ fspId, id, ...params }) =>
  client.executeRequest(GET, `/fsps/${fspId}/payers/${id}/ssoerrors`, {
    queryParams: { ...params, order: DEFAULT_ORDER },
    returnModel: {
      name: MODEL_NAME,
    },
  });
