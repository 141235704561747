import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(
  orm,
  (state, props) => props,
  ({ PayerUser }, id) => {
    return PayerUser.idExists(id)
      ? PayerUser.withId(id).serviceNotes.toRefArray()
      : [];
  }
);

export const selectorId = createSelector(orm.ResearchNote);
