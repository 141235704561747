import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import MaskPhone from './MaskPhone';

const TextFieldPhone = ({ InputProps, name, ...props }) => {
  const [field] = useField(name);
  return (
    <TextField
      {...props}
      {...field}
      InputProps={{ ...InputProps, inputComponent: MaskPhone }}
    />
  );
};

TextFieldPhone.propTypes = {
  InputProps: PropTypes.object,
  name: PropTypes.string.isRequired,
};

TextFieldPhone.defaultProps = {
  InputProps: undefined,
};

export default TextFieldPhone;
