const BASE64_TAG = 'base64';
const TEXT_HTML = 'text/html';
const domParser = new DOMParser();
const xmlSerializer = new XMLSerializer();

/**
 * This helper function is to be used with notifications
 * It will take an html string and convert any/all
 * <base64>{base64 data here}</base64> tags to its equivalent
 *
 * So it will turn this:
 *   <base64>dGVzdCBlbW9qaSDwn5iA8J+Ygw==</base64>
 * into this:
 *   <base64>test emoji 😀😃</base64>
 *
 * @param htmlAsString - html string with base64 encoded elements
 * @returns htmlAsString - html string with base64 decoded elements
 */
const decodeEmoji = (htmlAsString) => {
  try {
    const document = domParser.parseFromString(htmlAsString, TEXT_HTML);
    const base64HtmlTags = document.getElementsByTagName(BASE64_TAG);
    const base64TagCount = base64HtmlTags.length;

    for (let i = 0; i < base64TagCount; i++) {
      const element = base64HtmlTags.item(i);
      const decoded = atob(element.textContent);

      element.textContent = decodeURIComponent(escape(decoded));
    }

    return base64TagCount
      ? xmlSerializer.serializeToString(document)
      : htmlAsString;
  } catch (e) {
    // It is possible the data from database has an issue, let iframe handle
    return htmlAsString;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const addDerived = (r) => {
  r.renderedMsgDecoded = decodeEmoji(r.renderedMsg);

  return r;
};
