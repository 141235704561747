import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { DollarSign } from 'components/common/fields/TextFieldCurrency/consts';

const ContentPostedAmount = () => {
  return (
    <Field
      autoComplete="off"
      component={TextField}
      disabled={false}
      fullWidth
      id="postedAmount"
      InputProps={{ startAdornment: <DollarSign /> }}
      label="Payment Amount Posted"
      name="postedAmount"
    />
  );
};

export default ContentPostedAmount;
