import { client, formatQueryParams } from 'util/sdk';

export const create = (params, auth) =>
  client.billerLogos.create(params, { adminUserId: auth.id });

export const load = (params) =>
  client.billerLogos.query(formatQueryParams(params));

export const loadById = (id) => client.billerLogos(id).get();

export const remove = (id, auth) =>
  client.billerLogos(id).delete({ adminUserId: auth.id });

export const update = ({ id, ...params }, auth) =>
  client.billerLogos(id).update(params, { adminUserId: auth.id });
