import { useRef } from 'react';

/**
 * Keeps the last not null value.
 * Useful for keeping an entity after it has been deleted.
 */
const useKeepValue = (value) => {
  const ref = useRef(value);
  ref.current = value || ref.current;
  return ref.current;
};

export default useKeepValue;
