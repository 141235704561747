import PropTypes from 'prop-types';
import Select from 'components/common/fields/Select';
import { makeStyles } from '@material-ui/core/styles';
import {
  ParamSearchType,
  SearchParamPropType,
} from 'components/common/SearchTextField/Multiple/consts';
import { useOpen } from 'hooks';

const useStyles = makeStyles({ root: { minWidth: 200 } });

const ParamValueSelect = ({ onChange, searchParam, options, value }) => {
  const [open, toggleOpen] = useOpen(true);
  const classes = useStyles();

  const isMultiple = searchParam.type === ParamSearchType.MultiOption;

  return (
    <Select
      classes={{ root: classes.root }}
      data={options}
      displayAttr="displayName"
      fullWidth
      label="Value"
      onChange={onChange}
      onClose={toggleOpen}
      onOpen={toggleOpen}
      open={open}
      selectOnly
      type={isMultiple ? 'multiple' : undefined}
      value={value}
      valueAttr="id"
    />
  );
};

ParamValueSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  searchParam: SearchParamPropType.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

ParamValueSelect.defaultProps = {
  options: [],
  value: '',
};

export default ParamValueSelect;
