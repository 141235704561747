import { takeEvery, put } from 'redux-saga/effects';
import { TRANSACT_AS_PAYER, saveTransactAsData } from 'reducers/transactAs';
import { registerPayer } from '../sdk/payer';
import { callAction } from '../util';

function* transactAsPayer(data) {
  const registerData = yield registerPayer(data);
  yield put(saveTransactAsData(registerData, data));
}

export default function* saga() {
  yield takeEvery(
    TRANSACT_AS_PAYER.ACTION,
    callAction({
      api: transactAsPayer,
      type: TRANSACT_AS_PAYER,
    })
  );
}
