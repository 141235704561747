import { useEffect } from 'react';
import { Field, useField } from 'formik';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useDispatch, useSelector } from 'react-redux';
import { load } from 'model/researchReason';
import { selectorTransactionType } from 'selectors/researchReason';
import { TRANSACTION_TYPE_BILL_PAY } from 'consts';

const fieldName = { name: 'reason' };

const ContentReason = () => {
  const dispatch = useDispatch();
  const reasons = useSelector((state) =>
    selectorTransactionType(state, TRANSACTION_TYPE_BILL_PAY)
  );
  const [field] = useField(fieldName);

  useEffect(() => {
    if (!reasons?.length) {
      dispatch(load());
    }
  }, [dispatch, reasons]);

  return (
    <Field
      {...field}
      component={TextField}
      disabled={false}
      fullWidth
      id="reason"
      label="Reason"
      required
      select
    >
      {reasons.map((reason) => (
        <MenuItem key={reason?.id} value={reason}>
          {reason?.reason}
        </MenuItem>
      ))}
    </Field>
  );
};

export default ContentReason;
