import Grid from '@material-ui/core/Grid';
import InputRefType from 'types/inputRef';
import PropTypes from 'prop-types';
import TextFieldNumber from 'components/common/fields/TextFieldNumber';
import { update } from 'ramda';

const createOnChangeHandler = (index, onChange, value) => (event) => {
  const list = update(index, event?.target?.value, value);

  onChange({ target: { value: list } });
};

const ParamValueNumberRange = ({
  inputRef,
  onChange,
  searchParam,
  value,
  ...props
}) => {
  const handleFromOnChange = createOnChangeHandler(0, onChange, value);
  const handleToOnChange = createOnChangeHandler(1, onChange, value);

  return (
    <Grid container spacing={1}>
      <Grid item>
        <TextFieldNumber
          label="From"
          {...props}
          {...(searchParam?.props?.from ?? undefined)}
          inputRef={inputRef}
          onChange={handleFromOnChange}
          value={value[0]}
        />
      </Grid>
      <Grid item>
        <TextFieldNumber
          label="To"
          {...props}
          {...(searchParam?.props?.to ?? undefined)}
          onChange={handleToOnChange}
          value={value[1]}
        />
      </Grid>
    </Grid>
  );
};

ParamValueNumberRange.propTypes = {
  inputRef: InputRefType,
  onChange: PropTypes.func.isRequired,
  searchParam: PropTypes.object.isRequired,
  value: PropTypes.array,
};

ParamValueNumberRange.defaultProps = {
  inputRef: undefined,
  value: ['', ''],
};

export default ParamValueNumberRange;
