import {
  FULL_ACCESS_SERVICE,
  VO_ACCESS_SERVICE,
} from 'components/common/permissions';
import { DETAIL_PATH, SEARCH_PATH } from './paths';

const permissions = [FULL_ACCESS_SERVICE, VO_ACCESS_SERVICE];

export default [
  {
    key: 0,
    path: SEARCH_PATH,
    permissions,
    showFSP: true,
    text: 'Search',
  },
  {
    key: 1,
    path: DETAIL_PATH,
    permissions,
    text: 'Recent Payers',
  },
];
