import { createAction, createAddConst } from 'util/actions';

const NAME = 'tableEmptyMessage';

export const ADD = createAddConst(NAME);
export const add = createAction(ADD);

export const selector = (state) => state[NAME];

export default (state = [], { data = '', type }) => {
  switch (type) {
    case ADD.ACTION:
      return [...state, data];

    default:
      return state;
  }
};
