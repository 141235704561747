import PropTypes from 'prop-types';
import { useState } from 'react';
import { Add } from '@material-ui/icons';
import { load, selector } from 'reducers/otpGenerateButton';
import { selectorId } from 'selectors/payer';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../Dialog';
import PayerOrPayerUserButton from '../../PayerOrPayerUserButton';

const buttonText = 'Generate New OTP';

const GenerateButton = ({ payerId, ...props }) => {
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState();
  const dispatch = useDispatch();
  const payer = useSelector((state) => selectorId(state, payerId));

  const handleExited = () => {
    setOpen(false);
  };
  const handlePayerClick = () => {
    setOpen(true);
    setUserId(null);
  };
  const handlePayerUserClick = (event, payerUser) => {
    dispatch(load());
    setOpen(true);
    setUserId(payerUser?.userId);
  };

  return (
    <>
      <PayerOrPayerUserButton
        onPayerClick={handlePayerClick}
        onPayerUserClick={handlePayerUserClick}
        payerButtonText={buttonText}
        payerId={payerId}
        payerType={payer?.payerType}
        payerUserButtonText={buttonText}
        selector={selector}
        startIcon={<Add fontSize="small" />}
      />
      {open && (
        <Dialog
          {...props}
          onExited={handleExited}
          payerId={payerId}
          userId={userId}
        />
      )}
    </>
  );
};

GenerateButton.propTypes = {
  payerId: PropTypes.number.isRequired,
};

export default GenerateButton;
