import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { NA_VALUE } from 'consts';
import { ReadOnlyFormControl } from 'components/common/fields';
import { compose, pure, withProps } from 'recompose';

const AddressInternational = ({
  address1,
  address2,
  address3and4,
  country,
  localityAndAdminArea,
  postalCode,
  prefix,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReadOnlyFormControl label={`${prefix}Address 1`} value={address1} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label={`${prefix}Address 2`} value={address2} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label={`${prefix}Address 3, 4`}
            value={address3and4}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Locality, Admin Area "
            value={localityAndAdminArea}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Postal Code" value={postalCode} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Country" value={country} />
        </Grid>
      </Grid>
    </>
  );
};

AddressInternational.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3and4: PropTypes.string.isRequired,
  country: PropTypes.string,
  localityAndAdminArea: PropTypes.string.isRequired,
  postalCode: PropTypes.string,
  prefix: PropTypes.string.isRequired,
};

AddressInternational.defaultProps = {
  address1: NA_VALUE,
  address2: NA_VALUE,
  country: NA_VALUE,
  postalCode: NA_VALUE,
};

export default compose(
  withProps(({ address3, address4, adminArea, card = false, locality }) => ({
    address3and4: `${address3 || NA_VALUE}${address4 ? `, ${address4}` : ''}`,
    prefix: !card ? 'International ' : '',
    localityAndAdminArea: `${locality || NA_VALUE}${
      adminArea ? `, ${adminArea}` : ''
    }`,
  })),
  pure
)(AddressInternational);
