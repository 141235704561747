import {
  date,
  dateAndTime,
  formatCurrency,
  getFirstEntryInArrayAsInteger,
} from 'util/index';
import { convertToStartCase } from 'util/format';
import { getType } from 'components/modules/service/PayerDetail/Transactions/config/transactions';
import { NA_VALUE } from 'consts';
import {
  getDeliveryMethod,
  getFrequency,
  getP2PDisbursementType,
} from './transaction';

const wasAndNextButtonPressed = (data) => {
  return (
    data?.response?.length === 1 &&
    data?.props?.buttonText?.toLowerCase().includes('next')
  );
};

export const derived = (item) => {
  return {
    ...item,
    accountCreatedAtDerived: dateAndTime(item?.accountCreatedAt),
    accountCreatedOnDerived: date(item?.accountCreatedAt),
    amountDerived: formatCurrency(item?.amount),
    canceledAtDerived: dateAndTime(item?.canceledAt),
    createdAtDerived: dateAndTime(item?.createdAt),
    deliverDerived: getDeliveryMethod(item, true),
    deliverOnDerived: dateAndTime(item?.deliverOn),
    deliveryCountDerived: item?.deliveryCount ?? NA_VALUE,
    disburseTypeDerived: getP2PDisbursementType(item?.disburseType),
    frequencyLabel: getFrequency(item?.frequency),
    isNextDerived: false,
    reviewedAtDerived: dateAndTime(item?.reviewedAt),
    sendOnDerived: date(item?.sendOn),
    statusDerived: convertToStartCase(item.status),
    transactionTypeDerived: getType(item.transactionType),
  };
};
export const markAsNext = (data, FSPSchedTrans) => {
  if (wasAndNextButtonPressed(data)) {
    const id = getFirstEntryInArrayAsInteger(data?.response);
    const items = FSPSchedTrans.all().toRefArray();
    const pos = items.findIndex((item) => item.id === id);
    const nextId = items[pos + 1]?.id;

    if (nextId) {
      FSPSchedTrans.withId(nextId).update({ isNextDerived: true });
    }
  }
};
