import selector from 'selectors/authToken';
import {
  ADD,
  CLEAR,
  CLEAR_LOCATION,
  LOAD,
  REMOVE,
  UPDATE,
} from 'model/recentTicket';
import { LOGIN } from 'reducers/authToken';
import { put, select, takeEvery } from 'redux-saga/effects';
import { createActionSuccess, localStorage } from '../util';

export const KEY = 'trrId';
export const NAME = 'recentTicket';

export default function* saga() {
  yield takeEvery(ADD.ACTION, add);
  yield takeEvery(CLEAR.ACTION, clear);
  yield takeEvery(LOGIN.SUCCESS, clearLocation);
  yield takeEvery(LOAD.ACTION, load);
  yield takeEvery(REMOVE.ACTION, remove);
  yield takeEvery(UPDATE.ACTION, upsert);

  function* add({ data }) {
    const user = yield select(selector);

    yield put(
      createActionSuccess(ADD)(localStorage.add(data, NAME, user?.id, KEY))
    );
  }

  function* clear() {
    const user = yield select(selector);

    yield put(createActionSuccess(CLEAR)(localStorage.clear(NAME, user?.id)));
  }

  function* clearLocation() {
    const user = yield select(selector);
    const items = yield localStorage.get(NAME, user?.id);

    yield put(
      createActionSuccess(CLEAR_LOCATION)(
        items.map((data) => {
          // We don't want any page state to be persisted on the URL
          // between sessions except the fspId
          if (data && data.location) {
            data.location.hash = '';
            data.location.state = {};
          }

          return localStorage.upsert(data, NAME, user?.id, KEY, false);
        })
      )
    );
  }

  function* load() {
    const user = yield select(selector);

    yield put(createActionSuccess(LOAD)(localStorage.get(NAME, user?.id)));
  }

  function* remove({ data }) {
    const user = yield select(selector);

    yield put(
      createActionSuccess(REMOVE)(
        localStorage.remove(data, NAME, user?.id, KEY)
      )
    );
  }

  function* upsert({ data }) {
    const user = yield select(selector);

    yield put(
      createActionSuccess(UPDATE)(
        localStorage.upsert(data, NAME, user?.id, KEY)
      )
    );
  }
}
