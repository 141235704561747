import { client, formatQueryParams } from 'util/sdk';
import { omit } from 'ramda';

export const addRole = (data, auth) =>
  client.admin.roles.create(data, { adminUserId: auth.id });

export const deleteRole = ({ id }, auth) =>
  client.admin.roles(id).delete({ adminUserId: auth.id });

export const loadRoles = (params) =>
  client.admin.roles.query(formatQueryParams(params));

export const updateRole = ({ id, createdAt, updatedAt, ...data }, auth) =>
  client.admin
    .roles(id)
    .update(...omit(['offset'], data), { adminUserId: auth.id });
