import PropTypes from 'prop-types';
import { Button, CardActions, Grid } from '@material-ui/core';

const PasswordVerifyActions = ({ isChange, onViewChange, submitting }) => {
  const text = isChange ? 'Continue' : 'Sign In';

  return (
    <CardActions>
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Button onClick={onViewChange}>Forgot password?</Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            data-testid="loginAction"
            disabled={submitting}
            size="large"
            type="submit"
            variant="contained"
          >
            {text}
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  );
};

PasswordVerifyActions.propTypes = {
  isChange: PropTypes.bool.isRequired,
  onViewChange: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default PasswordVerifyActions;
