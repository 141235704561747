import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import Search from '@material-ui/icons/Search';
import SearchBy from 'components/common/SearchBy';
import classNames from 'classnames';
import { Button, Collapse, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { ToggleCurrentDeleted } from 'components/modules/service/PayerDetail';
import { deletedOnlyField } from 'util/page';
import { handleStopPropagation } from 'consts';
import { makeStyles } from '@material-ui/core/styles';
import { selector as pageSettingSelector } from 'selectors/pageSetting';
import { update as updatePageSetting } from 'model/pageSetting';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import DaysFilter from './DaysFilter';
import { initialize } from '../searchByUtils';

const useStyles = makeStyles((theme) => {
  const transition = {
    duration: theme.transitions.duration.standard,
  };

  return {
    expandMore: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', transition),
    },
    expandMoreExpanded: {
      transform: 'rotate(180deg)',
    },
    searchButton: {
      marginLeft: theme.spacing(3),
    },
    searchButtonText: {
      minWidth: 58,
    },
    searchIcon: {
      marginRight: theme.spacing(1),
    },
    emptyPlaceHolder: {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  };
});

const SearchBar = ({
  action,
  countBack,
  daysField,
  defaultExpanded,
  deletedId,
  handleSearch,
  hasData,
  isBusy,
  isLoading,
  priorityIndex,
  searchFields,
  supportsDeleted,
  settingId,
  settingType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pageSetting = useSelector(
    (state) => pageSettingSelector(state, settingId || 0, settingType),
    shallowEqual
  );

  const { daysFilter, deletedOnly, showSearch } = pageSetting;

  const settingUpdate = (settings) => {
    const data = { payerId: settingId || 0, type: settingType, ...settings };

    dispatch(updatePageSetting(data));
  };

  const handleSearchExpanded = () => {
    const { updatedValue } = initialize(priorityIndex, searchFields, []);
    settingUpdate({ showSearch: !showSearch, searchBys: updatedValue });
  };

  useEffect(() => {
    if (showSearch) {
      handleSearch(null, null, null, true);
    } else if (daysFilter) {
      handleSearch(deletedOnly ? deletedOnlyField : null, null, {
        field: daysField,
        value: (countBack && daysFilter > 0 ? -1 : 1) * daysFilter,
      });
    }
  }, [countBack, daysField, handleSearch, daysFilter, deletedOnly, showSearch]);

  return (
    <>
      <Grid container wrap="nowrap">
        <Grid item xs>
          <Grid container justifyContent="flex-end" spacing={1}>
            {supportsDeleted && (
              <Grid item>
                <Collapse in={!showSearch} onClick={handleStopPropagation}>
                  <ToggleCurrentDeleted
                    action={action}
                    id={deletedId}
                    settingId={settingId}
                    settingType={settingType}
                  />
                </Collapse>
              </Grid>
            )}
            <Grid item onClick={handleStopPropagation}>
              <Collapse in={!showSearch} onClick={handleStopPropagation}>
                <DaysFilter
                  busy={isBusy}
                  hasData={hasData}
                  loading={isLoading}
                  settingId={settingId}
                  settingType={settingType}
                />
              </Collapse>
            </Grid>
            <Grid item onClick={handleStopPropagation}>
              <Collapse in={!defaultExpanded}>
                <Button
                  className={classes.searchButton}
                  disabled={isLoading}
                  disableRipple
                  onClick={handleSearchExpanded}
                  t-i="search"
                  variant="outlined"
                >
                  <Search className={classes.searchIcon} />
                  <span className={classes.searchButtonText}>
                    {showSearch ? 'Close' : 'Search'}
                  </span>
                  <ExpandMore
                    className={classNames(classes.expandMore, {
                      [classes.expandMoreExpanded]: showSearch,
                    })}
                  />
                </Button>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.emptyPlaceHolder} />
        </Grid>
      </Grid>
      <Collapse in={showSearch} onClick={handleStopPropagation}>
        {showSearch && (
          <SearchBy
            fields={searchFields}
            onSearch={handleSearch}
            priorityIndex={priorityIndex}
            settingId={settingId}
            settingType={settingType}
          />
        )}
      </Collapse>
    </>
  );
};

SearchBar.propTypes = {
  action: PropTypes.object,
  countBack: PropTypes.bool,
  daysField: PropTypes.string.isRequired,
  defaultExpanded: PropTypes.bool,
  deletedId: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  hasData: PropTypes.bool,
  isBusy: PropTypes.bool,
  isLoading: PropTypes.bool,
  priorityIndex: PropTypes.number,
  searchFields: PropTypes.arrayOf(PropTypes.object),
  settingId: PropTypes.number,
  settingType: PropTypes.string.isRequired,
  supportsDeleted: PropTypes.bool,
};

SearchBar.defaultProps = {
  action: {},
  countBack: false,
  defaultExpanded: false,
  deletedId: '',
  hasData: false,
  isBusy: false,
  isLoading: false,
  priorityIndex: undefined,
  searchFields: [{}],
  settingId: undefined,
  supportsDeleted: false,
};

export default memo(SearchBar);
