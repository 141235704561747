import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOpen } from 'hooks';
import NoOptionsText from '../NoOptionsText';
import renderOption from './RenderOption';
import useRenderInput from '../RenderInput';
import useTypeAhead from './useTypeAhead';
import { defaultFilter, filters, getOptionLabel } from './consts';
import { REASON_CLEAR, NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ } from '../consts';

const label = 'Find Distributor By';
const text = 'No distributors matches found for ';

const Distributor = ({
  autoFocus,
  disabled,
  onChange,
  required,
  value: valueProp,
  ...props
}) => {
  const [filterParam, setFilterParam] = useState(defaultFilter);
  const [open, toggle, setOpen] = useOpen();
  const [value, setValue] = useState(null);
  const [loading, options, throttledDispatch] = useTypeAhead(filterParam);
  const onChangeFilter = (event, value) => {
    setFilterParam(value);
    setOpen(false);
  };
  const renderInput = useRenderInput({
    autoFocus,
    filters,
    label,
    loading,
    open,
    onChangeFilter,
    placeholder: filterParam.placeholder,
    required,
  });
  const inputValueRef = useRef('');

  const handleChange = useCallback(
    (event, distributor) => {
      const optionLabel = getOptionLabel(distributor);

      inputValueRef.current = optionLabel;
      setValue(distributor);
      onChange(event, {
        ...distributor,
        filterParam,
        optionLabel,
      });
    },
    [filterParam, onChange]
  );
  const handleInputChange = useCallback(
    (event, newInputValue, reason) => {
      if (reason === 'clear' || reason === 'input') {
        inputValueRef.current = newInputValue;
        setValue(newInputValue);

        if (reason === 'input') {
          throttledDispatch(newInputValue);
        }
      }
    },
    [throttledDispatch]
  );
  const handleOnOpen = useCallback(
    (event) => {
      if (
        filterParam.highlight ||
        event.currentTarget.type === 'button' ||
        inputValueRef.current?.length >= NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ
      ) {
        setOpen(true);
      }
    },
    [filterParam, setOpen]
  );

  useEffect(() => {
    if (valueProp && valueProp !== REASON_CLEAR) {
      // Intentionally this section is a little loose with comparisons
      // Why? an id can be a string or number
      // eslint-disable-next-line eqeqeq
      const distributor = options.find(({ id }) => id == valueProp);

      // eslint-disable-next-line eqeqeq
      if (distributor && distributor.id != value?.id) {
        inputValueRef.current = getOptionLabel(distributor);
        setValue(distributor);
      }
    }
  }, [filterParam, options, value, valueProp]);

  return (
    <Autocomplete
      {...props}
      autoComplete
      autoHighlight
      clearOnEscape
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      inputValue={inputValueRef.current}
      loading={loading}
      noOptionsText={
        <NoOptionsText
          idSearch={!filterParam.highlight}
          text={text}
          value={inputValueRef.current}
        />
      }
      onChange={handleChange}
      onClose={toggle}
      onInputChange={handleInputChange}
      onOpen={handleOnOpen}
      open={open}
      options={options}
      renderInput={renderInput}
      renderOption={
        filterParam.highlight ? renderOption(filterParam) : undefined
      }
      value={value}
    />
  );
};

Distributor.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Distributor.defaultProps = {
  autoFocus: true,
  disabled: false,
  onChange: always,
  required: false,
  value: null,
};

export default Distributor;
