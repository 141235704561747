import { takeEvery } from 'redux-saga/effects';
import { load, loadById, update } from 'sdk/externalAccount';
import { LOAD, LOAD_ID, UPDATE } from 'model/externalAccount';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `${response.description} has been successfully updated`,
      type: UPDATE,
    })
  );
}
