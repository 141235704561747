import { client } from 'util/sdk';
import { FSP_OTP_EVENT, FSPS } from 'consts';
import { GET, POST } from 'util/api';

const returnModel = { name: FSP_OTP_EVENT };

const loadOrUpdateURL = (fspId) => `/${FSPS}/${fspId}/${FSP_OTP_EVENT}s`;

export const load = ({ fspId }) =>
  client.executeRequest(GET, loadOrUpdateURL(fspId), { returnModel });

export const update = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, loadOrUpdateURL(fspId), {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
