import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { Grid, List, Paper, Slide, Tooltip } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { prop, propEq, always, omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { viewHeightWithoutAppbar } from 'util/index';
import ListItem from './RecentListItem';
import ListSubheader from './RecentListSubheader';
import ListFooter from './RecentListFooter';

const initialTimeout = { enter: 0, exit: 0 };
const maxEntries = 50;
const slideWidth = 260;
const tooltipSuffix = ' recent panel';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    flexDirection: 'column',
    height: 50,
    justifyContent: 'center',
    position: 'relative',
    right: 2,
    top: theme.spacing(1),
    width: 25,
    zIndex: 1000,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  grid: {
    height: '100%',
  },
  paper: {
    ...viewHeightWithoutAppbar(theme),
    minHeight: 200,
    overflowY: 'auto',
  },
  scroll: {
    minHeight: 200,
  },
}));

const RecentList = ({
  add,
  clear,
  clearActionType,
  clearMessage,
  customData,
  getIcon,
  getShowTertiary,
  keyIdName,
  load,
  recentItemSelector,
  remove,
  selector,
  subheaderText,
  subject,
  theme,
  toggle,
  update,
}) => {
  const [Icon, setIcon] = useState(ChevronLeft);
  const [enterDelay] = useState(theme.transitions.duration.standard * 1.5);
  const [timeout, setTimeout] = useState(initialTimeout);
  const [tooltip, setTooltip] = useState(`Collapse${tooltipSuffix}`);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [width, setWidth] = useState(slideWidth);
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector(recentItemSelector);
  const location = useLocation();
  const { open } = useSelector(selector);

  const selectedId = customData.id;
  const showTertiary = getShowTertiary(items);
  const position =
    items.findIndex((item) => propEq(keyIdName, selectedId, item)) + 1;

  const handleClick = () => {
    setTimeout({
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    });
    setTooltipOpen(false);

    if (!open) {
      setWidth(slideWidth);
    }

    window.setTimeout(() => dispatch(toggle(!open)));
  };
  const handleEntered = useCallback(() => {
    setIcon(ChevronLeft);
    setTooltip(`Collapse${tooltipSuffix}`);
  }, []);
  const handleExited = useCallback(() => {
    setIcon(ChevronRight);
    setTooltip(`Expand${tooltipSuffix}`);
    setWidth(0);
  }, []);
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  useEffect(() => {
    dispatch(load());
  }, [dispatch, load]);

  useEffect(() => {
    if (prop(keyIdName, customData)) {
      const data = {
        ...omit(['id'], customData),
        location: {
          hash: location.hash,
          pathname: location.pathname,
          search: location.search,
        },
      };

      if (location.state?.add) {
        dispatch(add(data));
      } else {
        dispatch(update(data));
      }
    }
  }, [add, customData, dispatch, keyIdName, location, update]);

  useEffect(() => {
    // list of recent items is a FIFO limited to maxEntries
    if (items.length > maxEntries) {
      dispatch(remove(items[items.length - 1]));
    }
  }, [dispatch, remove, items]);

  useEffect(() => {
    return () => {
      setTimeout(initialTimeout);
    };
  }, []);

  return (
    <Grid container>
      <Grid item>
        <Slide
          direction="right"
          in={open}
          mountOnEnter
          onEntered={handleEntered}
          onExited={handleExited}
          timeout={timeout}
        >
          <Paper classes={{ root: classes.paper }} style={{ width }}>
            <Grid
              className={classes.grid}
              container
              direction="column"
              wrap="nowrap"
            >
              <Grid className={classes.scroll} item xs>
                <List component="nav">
                  <ListSubheader
                    clear={clear}
                    clearActionType={clearActionType}
                    clearMessage={clearMessage}
                    items={items}
                    subheaderText={subheaderText}
                  />
                  {items.map((item) => (
                    <ListItem
                      key={prop(keyIdName, item)}
                      icon={getIcon ? getIcon(item) : null}
                      item={item}
                      remove={remove}
                      selected={propEq(keyIdName, selectedId, item)}
                      showTertiary={showTertiary}
                    />
                  ))}
                </List>
              </Grid>
            </Grid>
            <ListFooter
              count={items.length}
              position={position}
              style={{ width }}
              subject={subject}
            />
          </Paper>
        </Slide>
      </Grid>
      <Grid item>
        <Tooltip
          disableFocusListener
          enterDelay={enterDelay}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          open={tooltipOpen}
          title={tooltip}
        >
          <Paper className={classes.button} onClick={handleClick}>
            <Icon fontSize="small" />
          </Paper>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

RecentList.propTypes = {
  add: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  clearActionType: PropTypes.object.isRequired,
  clearMessage: PropTypes.string.isRequired,
  customData: PropTypes.object.isRequired,
  getIcon: PropTypes.func,
  getShowTertiary: PropTypes.func,
  keyIdName: PropTypes.string.isRequired,
  load: PropTypes.func.isRequired,
  recentItemSelector: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  subheaderText: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

RecentList.defaultProps = {
  getIcon: null,
  getShowTertiary: always(true),
};

export default withTheme(memo(RecentList));
