import { always } from 'ramda';
import {
  Activate,
  ActivateDisabled,
  Close,
  Disable,
  Suspend,
} from './messages';

export const statusConfig = {
  active: {
    suspended: {
      message: Suspend,
      label: 'Suspend',
      requiredPermission: always(true),
    },
    inactive: {
      message: Disable,
      label: 'Disable',
      requiredPermission: always(true),
    },
    closed: {
      message: Close,
      label: 'Close',
      requiredPermission: always(true),
    },
  },
  suspended: {
    active: {
      message: Activate,
      label: 'Activate',
      requiredPermission: always(true),
    },
    inactive: {
      message: Disable,
      label: 'Disable',
      requiredPermission: always(true),
    },
    closed: {
      message: Close,
      label: 'Close',
      requiredPermission: always(true),
    },
  },
  inactive: {
    active: {
      message: ActivateDisabled,
      label: 'Activate',
      requiredPermission: always(true),
    },
    closed: {
      message: Close,
      label: 'Close',
      requiredPermission: always(true),
    },
  },
  closed: {
    active: {
      message: ActivateDisabled,
      label: 'Activate',
      requiredPermission: always(true),
    },
  },
};

export const statusList = [
  { key: 'accountActive', name: 'Active', value: 'active' },
  { key: 'accountDeleted', name: 'Deleted', value: 'deleted' }, // Added 12/12/2019 https://payrailz.atlassian.net/browse/ON-526
  { key: 'accountDisabled', name: 'Disabled', value: 'inactive' },
  { key: 'accountClosed', name: 'Closed', value: 'closed' },
  { key: 'accountSuspended', name: 'Suspended', value: 'suspended' },
];
