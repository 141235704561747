import { LOAD, UPDATE } from 'model/fspFraudRuleThresholds';
import { formatSuccessMessage, UPDATED } from 'consts';
import { load, update } from 'sdk/fspFraudRuleThresholds';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

const keyName = { key: '', name: 'FSP Fraud Rule Threshold' };

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage(keyName, UPDATED, false),
      type: UPDATE,
    })
  );
}
