import { selector } from 'selectors/request';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * Why this hook?
 *
 * Because this selector can really benefit from having a "shallowEqual" and
 * everyone forgets, or doesn't check if it is needed
 */
const useRequestComplete = (requestConst) => {
  return useSelector((state) => selector(state, requestConst), shallowEqual);
};

export default useRequestComplete;
