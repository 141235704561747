/* eslint-disable react/jsx-no-duplicate-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    // Hide the spinner, *nobody* likes it
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      margin: 0,
      marginRight: theme.spacing(0.5),
    },
  },
  root: {},
}));

const TextFieldNumber = ({
  inputProps,
  InputProps,
  TextField,
  label,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      inputProps={{
        ...inputProps,
        'aria-label': (inputProps && inputProps['aria-label']) ?? label,
        type: 'number',
      }}
      InputProps={{
        ...InputProps,
        classes: {
          ...InputProps?.classes,
          input: classNames(classes.input, {
            [InputProps?.classes?.input]:
              InputProps?.classes?.input !== undefined,
          }),
        },
      }}
      label={label}
    />
  );
};

TextFieldNumber.propTypes = {
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  TextField: PropTypes.elementType,
};

TextFieldNumber.defaultProps = {
  inputProps: undefined,
  InputProps: undefined,
  label: undefined,
  TextField,
};

export default TextFieldNumber;
