import { Select } from 'components/common/fields';

const data = [
  { name: 'Yes', value: 'on' },
  { name: 'No', value: 'off' },
];

const TransactionsAutopaySelect = (props) => {
  return <Select data={data} displayAttr="name" valueAttr="value" {...props} />;
};

export default TransactionsAutopaySelect;
