import { attr, fk, Model } from 'redux-orm';
import Payer from 'model/payer';
import { createAction, createLoadConst, dateAndTime } from '../util';

export const derived = (r) => {
  r.updatedAt = dateAndTime(r.updatedAt);
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.startAtLabel = dateAndTime(r.startAt);

  return r;
};

export default class ExternalAccountIAV extends Model {
  static get modelName() {
    return 'ExternalAccountIAV';
  }

  static reducer({ type, data }, ExternalAccountIAV, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).externalAccountsIAV.delete();
        }

        data.response.forEach((r) => {
          ExternalAccountIAV.upsert(derived(r));
        });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      accessToken: attr(),
      accountId: attr(),
      accountVerificationStatus: attr(),
      createdAt: attr(),
      errorCode: attr(),
      errorMessage: attr(),
      errorType: attr(),
      externalDdaId: attr(),
      fspId: attr(),
      href: attr(),
      iavStatus: attr(),
      id: attr(),
      insUserId: attr(),
      institutionId: attr(),
      institutionName: attr(),
      itemId: attr(),
      linkSessionId: attr(),
      offset: attr(),
      payerId: fk(Payer.modelName, 'externalAccountsIAV'),
      publicToken: attr(),
      requestId: attr(),
      requestType: attr(),
      startAt: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      userId: attr(),
    };
  }
}

export const LOAD = createLoadConst(ExternalAccountIAV.modelName);

export const load = createAction(LOAD);
