import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Grid,
  ListSubheader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionListPagination from './ExpansionListPagination';
import ExpansionListSubheaderSort from './ExpansionListSubheaderSort';

const useStyles = makeStyles((theme) => {
  const border = `1px solid ${theme.palette.grey[300]}`;

  return {
    accordionRoot: {
      backgroundColor: theme.palette.grey['100'],
      borderLeft: border,
      borderRight: border,
      borderBottom: border,
    },
    accordionSummaryContent: {
      margin: 0,
      cursor: 'default',
    },
    accordionSummaryRoot: {
      minHeight: 'unset',
    },
    gutters: {
      padding: 0,
    },
    listSubHeader: {
      lineHeight: theme.spacing(0.33),
      fontSize: theme.typography.fontSize + 1,
    },
  };
});

const ExpansionListSubheader = ({ columnMeta, ...props }) => {
  const classes = useStyles();

  return (
    <ListSubheader
      classes={{ root: classes.listSubHeader, gutters: classes.gutters }}
    >
      <ExpansionListPagination columnMeta={columnMeta} {...props} />
      <Accordion
        classes={{ root: classes.accordionRoot }}
        elevation={0}
        expanded={false}
        square
      >
        <AccordionSummary
          classes={{
            content: classes.accordionSummaryContent,
            root: classes.accordionSummaryRoot,
          }}
        >
          <Grid container spacing={1}>
            {columnMeta.map(({ id, ...column }) => (
              <ExpansionListSubheaderSort key={id} {...props} {...column} />
            ))}
          </Grid>
        </AccordionSummary>
      </Accordion>
    </ListSubheader>
  );
};

ExpansionListSubheader.propTypes = {
  columnMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExpansionListSubheader;
