import PropTypes from 'prop-types';
import { useEffect } from 'react';
import srcDoc from 'srcdoc-polyfill';
import { ALERT, NOTIFICATION, handleStopPropagation } from 'consts';
import { Card, CardHeader } from '@material-ui/core';
import { isNil } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import NotificationCardContent from './NotificationCardContent';

const useStyles = makeStyles(() => ({
  header: {
    paddingBottom: 0,
  },
}));

const NotificationCard = ({ row }) => {
  const classes = useStyles();
  const isEmail = !isNil(row.toEmail);
  const message = row.renderedMsgDecoded;
  const title = row.msgType === 'notify' ? NOTIFICATION : ALERT;

  useEffect(() => {
    const iframes = document.getElementsByTagName('iframe');
    let n = iframes.length;
    // eslint-disable-next-line no-plusplus
    while (n--) srcDoc.set(iframes[n]);
  }, [isEmail, message]);

  return (
    <Card onClick={handleStopPropagation}>
      <CardHeader classes={{ root: classes.header }} title={title} />
      <NotificationCardContent isEmail={isEmail} message={message} />
    </Card>
  );
};

NotificationCard.propTypes = {
  row: PropTypes.object.isRequired,
};

export default NotificationCard;
