import { attr, fk } from 'redux-orm';
import { head, join, pluck, trim } from 'ramda';
import Org from 'model/org';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
  getPhoneForDisplay,
} from '../util/index';
import BaseModel from './baseModel';

const addDerived = (r) => {
  r.adminOrgId = r.adminOrg.id;
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.groupIds = pluck('id')(r.groups || []);
  r.groupsLabel = join(', ', pluck('name')(r.groups || []));
  r.lastLoginAtLabel = dateAndTime(r.lastLoginAt);
  r.mobilePhoneLabel = getPhoneForDisplay(r.mobilePhone);
  r.name = `${trim(r.firstName || '')} ${trim(r.lastName || '')}`;
  r.organizationName = r.adminOrg.name;
  r.updatedAtLabel = dateAndTime(r.updatedAt);
  r.workPhoneLabel = getPhoneForDisplay(r.workPhone);

  return r;
};

export default class OntracUser extends BaseModel {
  static get modelName() {
    return 'OntracUser';
  }

  static reducer({ type, data }, OntracUser, { Group }) {
    switch (type) {
      case LOAD.SUCCESS:
      case LOAD_BY_ORG_ID.SUCCESS:
        if (data?.length) {
          const orgId = head(data)?.adminOrg?.id;

          if (orgId) {
            OntracUser.filter(
              ({ organizationId }) => organizationId === orgId
            ).delete();
          } else {
            OntracUser.delete();
          }

          data.forEach((r) => OntracUser.upsert(addDerived(r)));
        }
        break;

      case LOAD_TYPE_AHEAD.SUCCESS:
        data.forEach((r) => OntracUser.upsert(addDerived(r)));
        break;

      case LOAD_USER.SUCCESS: {
        const user = head(data);

        if (user) {
          OntracUser.upsert(addDerived(user));
          user.groups.forEach((group) => Group.upsert(group));
        }
        break;
      }

      case ADD.SUCCESS:
        OntracUser.upsert(addDerived(data));
        break;

      case DELETE.SUCCESS:
        OntracUser.withId(data.id).delete();
        break;

      case UPDATE.SUCCESS:
      case UPDATE_STATUS.SUCCESS:
        OntracUser.update(addDerived(data));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      actions: attr(),
      adminOrg: attr(),
      adminOrgId: fk(Org.modelName, 'users'), // derived
      adminUserGroupList: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      email: attr(),
      firstName: attr(),
      fsps: attr(),
      groups: attr(),
      groupIds: attr(), // derived
      groupsLabel: attr(), // derived
      href: attr(),
      id: attr(),
      lastLoginAt: attr(),
      lastLoginAtLabel: attr(), // derived
      lastName: attr(),
      lastOtpAuthAt: attr(),
      lastOtpAuthVia: attr(),
      middleName: attr(),
      mobilePhone: attr(),
      mobilePhoneLabel: attr(), // derived,
      offset: attr(),
      organizationId: attr(),
      organizationName: attr(), // derived
      otpGoodUntilAt: attr(),
      otpMaxSendTries: attr(),
      roles: attr(),
      type: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
      userLogin: attr(),
      userStatus: attr(),
      workPhone: attr(),
      workPhoneLabel: attr(), // derived
    };
  }
}

export const FORM_NAME = 'setupUser';

const modelName = OntracUser.modelName;

export const ADD = createAddConst(modelName);
export const DELETE = createDeleteConst(modelName);
export const LOAD = createRequestConst(modelName);
export const LOAD_BY_ORG_ID = createLoadConst(`${modelName}_ORG`);
export const LOAD_TYPE_AHEAD = createLoadConst(`${modelName}_type_ahead`);
export const LOAD_USER = createLoadConst(modelName);
export const UPDATE = createUpdateConst(modelName);
export const UPDATE_STATUS = createUpdateConst(`${modelName}_STATUS`);

export const addUser = createAction(ADD);
export const deleteUser = createAction(DELETE);
export const loadByOrgId = createAction(LOAD_BY_ORG_ID);
export const loadTypeAhead = createAction(LOAD_TYPE_AHEAD);
export const loadUser = createAction(LOAD_USER);
export const loadUsers = createAction(LOAD);
export const update = createAction(UPDATE);
export const updateStatus = createAction(UPDATE_STATUS);

const bob = {};
