import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { ExpansionListByState } from 'components/common/ExpansionList';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/busy';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import DialogActions from './ImportDialogActions';
import DialogTitle from './ImportDialogTitle';

const useStyles = makeStyles({
  circularProgress: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: 'calc(50% - 25px)',
    visibility: 'hidden',
  },
  paper: {
    height: 750,
  },
  visible: {
    visibility: 'visible',
  },
});

const ImportDialog = ({
  ExpansionListProps,
  IMPORT,
  onImport,
  onRequestClose,
  open,
  section,
  title,
}) => {
  const { isDirty, resetForm, values } = useFormikContext();
  const busy = useSelector((state) => selector(state, IMPORT));
  const classes = useStyles();

  const data = values?.[section];

  const handleClose = () => {
    if (!isDirty) {
      // Don't accidentally close a dirty dialog
      onRequestClose();
    }
  };
  const handleImport = (value) => {
    // why do we need to reset? because a user may delete an entry
    // and reimport the same data, if this happens initialValues is the same
    // and the form is not updated
    resetForm();
    onImport(value);
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle onImport={handleImport} title={title} />
      <DialogContent>
        {data?.length ? (
          <ExpansionListByState {...ExpansionListProps} name={section} />
        ) : null}
      </DialogContent>
      <CircularProgress
        className={classNames(classes.circularProgress, {
          [classes.visible]: busy,
        })}
        size={50}
      />
      <DialogActions onCancel={onRequestClose} section={section} />
    </Dialog>
  );
};

ImportDialog.propTypes = {
  ExpansionListProps: PropTypes.object.isRequired,
  IMPORT: PropTypes.object.isRequired,
  onImport: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ImportDialog;
