import PropTypes from 'prop-types';
import { memo } from 'react';
import { Features } from 'components/common';
import { ListAlt } from '@material-ui/icons';
import { isEqual, NA_VALUE, PERSON_TO_PERSON, VENMO_DIRECT } from 'consts';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { isNil, not } from 'ramda';
import { isOn } from 'util/index';

const features = [{ checked: true, key: 1, label: 'Invoice' }];

const Type = ({ item }) => {
  const { invoices, p2pDirectOffOn, transactionType, transactionTypeDerived } =
    item;
  const hasInvoice = not(isNil(invoices)) && invoices.length > 0;
  const isP2P = isEqual(transactionType, PERSON_TO_PERSON);
  const isVenmoDirect = isP2P && isOn(p2pDirectOffOn);
  const value = isVenmoDirect
    ? VENMO_DIRECT
    : transactionTypeDerived || NA_VALUE;

  return (
    <ReadOnlyFormControl
      inputNode={
        hasInvoice && (
          <ReadOnlyInputWithMenu
            label={value}
            menuNode={
              <Features
                checkedIcon={<ListAlt />}
                disabled
                features={features}
                title="Payment is part of an"
              />
            }
          />
        )
      }
      label="Type"
      value={value}
    />
  );
};

Type.propTypes = {
  item: PropTypes.object.isRequired,
};

export default memo(Type);
