import { LAST_SNAPSHOT } from 'model/automatchJob';
import { head } from 'ramda';
import { dateAndTime } from '../util';

const NAME = 'automatchLastSnapshot';

export const selector = (state) => state[NAME];

const defaultState = 'Calculating...';

export default (state = defaultState, { type, data }) => {
  switch (type) {
    case LAST_SNAPSHOT.SUCCESS: {
      let label = 'Never';

      if (data?.response?.length >= 1) {
        const doneAt = head(data.response).doneAt;

        label = doneAt ? dateAndTime(doneAt) : 'Never';
      }

      return label;
    }

    case LAST_SNAPSHOT.FAILURE:
      return 'Error';

    default:
      return state;
  }
};
