import { update } from 'model/pageSetting';
import { useDispatch } from 'react-redux';

const useRefresh = (pageSettingsName) => {
  const dispatch = useDispatch();

  return (pageSetting = undefined) => {
    const data = {
      refreshedAt: Date.now(),
      type: pageSettingsName,
      ...pageSetting,
    };

    dispatch(update(data));
  };
};

export default useRefresh;
