import { takeEvery } from 'redux-saga/effects';
import { load, loadBySchedId } from 'sdk/autopayScheduled';
import { LOAD, LOAD_SCHED_ID } from 'model/autopayScheduled';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_SCHED_ID.ACTION,
    callAction({
      api: loadBySchedId,
      dataName: ORM,
      type: LOAD_SCHED_ID,
    })
  );
}
