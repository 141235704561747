import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import SearchFieldType from 'types/search_by';
import Select from 'components/common/fields/Select';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { selector as pageSettingSelector } from 'selectors/pageSetting';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { update as updatePageSetting } from 'model/pageSetting';
import { deletedOnlyField } from 'util/page';
import { DDA_ACCOUNT_NUMBER } from 'consts';
import {
  append,
  assoc,
  compose as rCompose,
  equals,
  find,
  isEmpty,
  isNil,
  last,
  length,
  lensPath,
  not,
  propEq,
  reject,
  view,
} from 'ramda';
import { Clear } from '@material-ui/icons';
import {
  applySearchBy,
  getSelectedField,
  getValueComponent,
  hasItemWithoutField,
  hasNoValue,
  initialize,
} from './searchByUtils';
import SearchByButtons from './SearchByButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  operatorInput: {
    minHeight: 48,
  },
  searchFields: {
    minWidth: 600,
  },
  valueInput: {
    width: 200,
    minHeight: 48,
  },
  hidden: {
    visibility: 'hidden',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

const SearchBy = ({
  fields,
  onSearch,
  priorityIndex,
  settingId,
  settingType,
}) => {
  const [disabledPayerFields, setDisabledPayerFields] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const pageSetting = useSelector(
    (state) => pageSettingSelector(state, settingId, settingType),
    shallowEqual
  );

  const { searchBys, deletedOnly } = pageSetting;
  const filteredSearchBys = searchBys.filter(({ hide }) => !hide);
  const { selectionFields } = initialize(
    priorityIndex,
    fields,
    searchBys || []
  );
  const selectionFieldsNotInUse = selectionFields?.filter(
    (selectionField) =>
      !searchBys.some(({ field }) => selectionField.field.value === field)
  );

  const savePageSettings = useCallback(
    (searchBys) => {
      const data = {
        payerId: settingId || 0,
        type: settingType,
        searchBys,
        subSearchBy: deletedOnly
          ? {
              ...deletedOnlyField[0],
              id: searchBys.length + 1,
              hide: true,
            }
          : null,
      };

      dispatch(updatePageSetting(data));
    },
    [dispatch, settingId, settingType, deletedOnly]
  );

  const handleFieldChange = (id) => (event) => {
    if (isEmpty(event.target.value)) {
      return;
    }

    let newSearchBys = applySearchBy(
      id,
      searchBys,
      assoc('field', event.target.value),
      assoc(
        'operator',
        view(
          lensPath(['operators', 0, 'value']),
          find(
            rCompose(
              equals(event.target.value),
              view(lensPath(['field', 'value']))
            )
          )(fields)
        )
      )
    );

    if (length(filteredSearchBys) < length(fields)) {
      const id =
        (last(newSearchBys).id || newSearchBys[newSearchBys.length - 2].id) + 1;

      newSearchBys = append({ id }, newSearchBys);
    }

    savePageSettings(newSearchBys);
  };
  const handleOperatorChange = (id) => (event) => {
    const newSearchBys = applySearchBy(
      id,
      searchBys,
      assoc('operator', event.target.value)
    );

    savePageSettings(newSearchBys);
  };
  const handleValueChange = (id) => (event) => {
    const newSearchBys = applySearchBy(
      id,
      searchBys,
      assoc('value', event.target ? event.target.value : event)
    );
    savePageSettings(newSearchBys);
  };
  const handleRemove = (id) => () => {
    let newSearchBys = reject(propEq('id', id), searchBys);

    if (hasItemWithoutField(newSearchBys)) {
      newSearchBys = append({ id: last(newSearchBys).id + 1 }, newSearchBys);
    }

    setDisabledPayerFields(false);
    savePageSettings(newSearchBys);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (not(hasNoValue(searchBys))) {
        onSearch(searchBys);
      }
    }
  };

  useEffect(() => {
    if (!searchBys?.length) {
      const { updatedValue } = initialize(priorityIndex, fields, []);

      savePageSettings(updatedValue);
    }
  }, [fields, priorityIndex, searchBys, savePageSettings]);

  useEffect(() => {
    const foundDda = filteredSearchBys.find(
      ({ field }) => field === DDA_ACCOUNT_NUMBER
    );

    setDisabledPayerFields(foundDda !== undefined);
  }, [filteredSearchBys]);

  return (
    <Grid
      alignItems="center"
      classes={{ root: classes.root }}
      container
      spacing={2}
    >
      <Grid className={classes.searchFields} item xs>
        {filteredSearchBys?.map(({ id, field, operator, value }, index) => {
          const selectedField = getSelectedField(field, fields);
          const fieldOperators = selectedField?.operators;
          const ValueComponent = getValueComponent(operator, fieldOperators);
          const secondarySearchFields = selectionFieldsNotInUse.map(
            ({ field }) => field
          );
          const notDdaField = selectedField?.field.value !== DDA_ACCOUNT_NUMBER;
          const disabledBecauseDdaSearch = disabledPayerFields && notDdaField;

          return (
            <Grid key={id} container spacing={1}>
              <Grid item xs={4}>
                {selectedField ? (
                  <Typography
                    className={classNames({
                      [classes.disabled]: disabledBecauseDdaSearch,
                    })}
                  >
                    {selectedField.field.name}
                  </Typography>
                ) : (
                  <Select
                    data={secondarySearchFields}
                    disabled={disabledBecauseDdaSearch}
                    displayAttr="name"
                    fullWidth
                    id={`field${id}`}
                    name={`field${id}`}
                    onChange={handleFieldChange(id)}
                    placeholder={isNil(field) ? 'Select criteria' : null}
                    selectOnly
                    value={field}
                    valueAttr="value"
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Select
                  classes={{ root: classes.operatorInput }}
                  data={fieldOperators}
                  disabled={
                    !(field || length(fields) === 1) || disabledBecauseDdaSearch
                  }
                  displayAttr="name"
                  fullWidth
                  id={`operator${id}`}
                  name={`operator${id}`}
                  onChange={handleOperatorChange(id)}
                  placeholder={isNil(operator) ? 'Select operator' : null}
                  value={operator}
                  valueAttr="value"
                />
              </Grid>
              <Grid item xs={4}>
                <ValueComponent
                  classes={{ root: classes.valueInput }}
                  disabled={!operator || disabledBecauseDdaSearch}
                  fullWidth
                  id={`value${id}`}
                  inputProps={{
                    'aria-label': `value${id}`,
                    autoComplete: 'off',
                  }}
                  name={`value${id}`}
                  onChange={handleValueChange(id)}
                  onKeyPress={handleKeyPress}
                  value={value || ''}
                />
              </Grid>
              <Grid item xs={1}>
                {field && index >= priorityIndex && (
                  <IconButton aria-label="Remove" onClick={handleRemove(id)}>
                    <Clear />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item>
        <SearchByButtons
          fields={fields}
          onSearch={onSearch}
          priorityIndex={priorityIndex}
          searchBys={searchBys}
          updateSearchBys={savePageSettings}
        />
      </Grid>
    </Grid>
  );
};

SearchBy.propTypes = {
  fields: PropTypes.arrayOf(SearchFieldType).isRequired,
  onSearch: PropTypes.func.isRequired,
  priorityIndex: PropTypes.number,
  settingId: PropTypes.number,
  settingType: PropTypes.string.isRequired,
};

SearchBy.defaultProps = {
  priorityIndex: 3,
  settingId: 0,
};

export default SearchBy;
