const DETAIL = 'detail';
const NEW_TICKETS = 'new';
const OPEN_TICKETS = 'open';
const SEARCH = 'search';

export const BASE_PATH = '/paymentResearch/';
export const DETAIL_PATH = `${BASE_PATH}${DETAIL}`;
export const NEW_TICKETS_PATH = `${BASE_PATH}${NEW_TICKETS}`;
export const OPEN_TICKETS_PATH = `${BASE_PATH}${OPEN_TICKETS}`;
export const SEARCH_PATH = `${BASE_PATH}${SEARCH}`;
export const QUEUE_PATH = 'queue';

export const getLocationFromTicket = (id, fspId) => {
  const pathname = `${DETAIL_PATH}/${id}`;

  return {
    pathname,
    search: `?fspId=${fspId}`,
    state: { add: true },
  };
};
