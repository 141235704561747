import ButtonPopover from 'components/common/ButtonPopover';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import LimitsCard from './LimitsCard';
import { LimitsProvider } from './LimitsContext';

const anchorOrigin = { horizontal: 'center', vertical: 'top' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

const Limits = ({ fspId, id, limitType }) => {
  const [appendCard, setAppendCard] = useState(false);

  const handleClick = () => {
    if (appendCard === false) {
      setAppendCard(true);
    }
  };

  return limitType ? (
    <ButtonPopover
      anchorOrigin={anchorOrigin}
      onClick={handleClick}
      title="Payer Limits"
      transformOrigin={transformOrigin}
      variant="outlined"
    >
      {appendCard && (
        <LimitsProvider fspId={fspId} limitType={limitType} payerId={id}>
          <LimitsCard />
        </LimitsProvider>
      )}
    </ButtonPopover>
  ) : null;
};

Limits.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  limitType: PropTypes.string,
};

Limits.defaultProps = {
  limitType: null,
};

export default memo(Limits);
