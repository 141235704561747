import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';
import { addDerivedData } from './util/payALoan';

export default class PayALoan extends Model {
  static get modelName() {
    return 'PayALoan';
  }

  static reducer({ type, data }, PayALoan, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        data.response?.forEach(({ ...props }) => {
          PayALoan.upsert(addDerivedData(props));
        });
        break;
      case LOAD.FAILURE:
        PayALoan.delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      amtDue: attr(),
      amtDueDerived: attr(), // derived
      id: attr(),
      description: attr(),
      dueOn: attr(),
      href: attr(),
      minAmt: attr(),
      minAmtDerived: attr(), // derived
      outstandingBalance: attr(),
      outstandingBalanceDerived: attr(), // derived
      stmtOn: attr(),
      createdAt: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(), // derived
    };
  }
}

const name = PayALoan.modelName;
export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
