import { createRequestConst } from 'util/actions';
import { saga } from 'util/saga';

const SYS = 'sys';
export const LOAD = createRequestConst(SYS);
// Selectors
export const getSystems = (state) => state.systems;

const initialState = [{ id: 1000, name: 'Payrailz' }];
export default saga({
  initialState,
  LOAD,
});
