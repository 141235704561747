import Skeleton from 'react-loading-skeleton';
import { createSelector } from 'redux-orm';
import { orm } from 'model';

const SKELETON_75 = <Skeleton width="75%" />;

export default createSelector(
  orm,
  (_, props) => props,
  ({ EBillUser }, id) => {
    const item = EBillUser.idExists(id) ? EBillUser.withId(id) : null;

    if (!item) {
      return { netUserIdStr: SKELETON_75 };
    }
    return item.ref;
  }
);
