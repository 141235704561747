import { add, clear, CLEAR, load, remove, update } from 'model/recentPayer';
import { BUSINESS_CODE } from 'consts';
import { Business, Person } from '@material-ui/icons';
import { prop, uniq } from 'ramda';
import { RecentList as CommonRecentList } from 'components/common';
import { selector as recentPayerSelector } from 'selectors/recentPayer';
import { selector, toggle } from 'reducers/recentPayers';
import { selectorId } from 'selectors/payer';
import { usePayerId } from 'hooks';
import { useSelector } from 'react-redux';

const getIcon = (payer) => {
  return payer.payerType === BUSINESS_CODE ? <Business /> : <Person />;
};

const getShowTertiary = (items) => {
  return uniq(items.map(prop('fspId'))).length > 1;
};

const RecentList = () => {
  const id = usePayerId();
  const payer = useSelector((state) => selectorId(state, id)) || {};

  const {
    fsp,
    id: payerId,
    payerType,
    primary,
    phone: secondary,
    zfspId: fspId,
  } = payer;
  const customData = {
    id,
    payerId,
    fspId,
    payerType,
    primary,
    secondary,
    tertiary: fsp?.name,
  };

  return (
    <CommonRecentList
      add={add}
      clear={clear}
      clearActionType={CLEAR}
      clearMessage="Clear your recent payer list?"
      customData={customData}
      getIcon={getIcon}
      getShowTertiary={getShowTertiary}
      keyIdName="payerId"
      load={load}
      recentItemSelector={recentPayerSelector}
      remove={remove}
      selector={selector}
      subheaderText="Recent Payers"
      subject="Payer"
      toggle={toggle}
      update={update}
    />
  );
};

export default RecentList;
