import DialogContentText from '@material-ui/core/DialogContentText';
import { useEffect, useState } from 'react';

const useContent = (Content) => {
  const [dialogContent, setDialogContent] = useState();

  useEffect(() => {
    switch (typeof Content) {
      case 'string':
        setDialogContent(() => (
          <DialogContentText>{Content}</DialogContentText>
        ));
        break;

      case 'function':
        setDialogContent((props) => <Content {...props} />);
        break;

      case 'object':
        setDialogContent(Content);
        break;

      default:
        break;
    }
  }, [Content]);

  return dialogContent;
};

export default useContent;
