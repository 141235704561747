import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class ResearchReason extends Model {
  static get modelName() {
    return 'ResearchReason';
  }

  static reducer({ type, data }, ResearchReason) {
    switch (type) {
      case LOAD.SUCCESS:
        ResearchReason.delete();
        data.response.forEach((r) => ResearchReason.create(r));
        break;

      case CREATE.SUCCESS:
        ResearchReason.create(data.response);
        break;

      case UPDATE.SUCCESS:
        ResearchReason.withId(data.response.id).update(data.response);
        break;

      case REMOVE.SUCCESS:
        ResearchReason.withId(data.response.id).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      offset: attr(),
      reason: attr(),
      transactionType: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const CREATE = createAddConst(ResearchReason.modelName);
export const REMOVE = createRequestConst(ResearchReason.modelName);
export const LOAD = createRequestConst(ResearchReason.modelName);
export const UPDATE = createUpdateConst(ResearchReason.modelName);

export const create = createAction(CREATE);
export const remove = createAction(REMOVE);
export const load = createAction(LOAD);
export const update = createAction(UPDATE);
