import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class RecentTicket extends Model {
  static get modelName() {
    return 'RecentTicket';
  }

  static reducer({ data, type }, RecentTicket) {
    switch (type) {
      case ADD.SUCCESS:
        RecentTicket.filter(({ trrId }) => trrId === data.trrId).delete();
        RecentTicket.create(data);
        break;

      case CLEAR.SUCCESS:
        RecentTicket.delete();
        break;

      case LOAD.SUCCESS:
        RecentTicket.delete();

        data.forEach((r) => RecentTicket.upsert(r));
        break;

      case REMOVE.SUCCESS:
        RecentTicket.withId(data.id).delete();
        break;

      case UPDATE.SUCCESS:
        RecentTicket.upsert(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      fspId: attr(),
      location: attr(),
      primary: attr(),
      secondary: attr(),
      tertiary: attr(),
      trrId: attr(),
    };
  }
}

export const ADD = createAddConst(RecentTicket.modelName);
export const CLEAR = createDeleteConst(`${RecentTicket.modelName}_clear`);
export const CLEAR_LOCATION = createDeleteConst(
  `${RecentTicket.modelName}_clearLocation`
);
export const LOAD = createRequestConst(RecentTicket.modelName);
export const REMOVE = createDeleteConst(RecentTicket.modelName);
export const UPDATE = createUpdateConst(RecentTicket.modelName);

export const add = createAction(ADD);
export const clear = createAction(CLEAR);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
