import MuiTablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { usePagination } from 'hooks';

const TablePagination = ({
  action,
  actionParams,
  actionType,
  actionTypeUpdate,
  defaultPageSettings,
  hasFspId,
  pageSettingsName,
  selectorCount,
  selectorProps,
  showPagination,
  ...props
}) => {
  const paginationProps = usePagination(
    action,
    actionParams,
    actionType,
    defaultPageSettings,
    hasFspId,
    pageSettingsName,
    selectorCount,
    selectorProps
  );

  if (!showPagination) {
    return null;
  }

  return <MuiTablePagination {...paginationProps} {...props} />;
};

export const propTypes = {
  action: PropTypes.func.isRequired,
  actionParams: PropTypes.object,
  actionType: PropTypes.object.isRequired,
  actionTypeUpdate: PropTypes.object,
  defaultPageSettings: PropTypes.object,
  hasFspId: PropTypes.bool,
  pageSettingsName: PropTypes.string.isRequired,
  selectorCount: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
  showPagination: PropTypes.bool,
};

TablePagination.propTypes = propTypes;

TablePagination.defaultProps = {
  actionParams: null,
  actionTypeUpdate: {},
  defaultPageSettings: null,
  hasFspId: true,
  selectorProps: null,
  showPagination: true,
};

export default memo(TablePagination);
