import PropTypes from 'prop-types';
import { memo } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  typography: {
    color: theme.palette.primary.contrastText,
  },
}));

const AuditDetailDay = ({ title }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.typography} noWrap variant="subtitle1">
        {title}
      </Typography>
    </Paper>
  );
};

AuditDetailDay.propTypes = {
  title: PropTypes.string.isRequired,
};

export default memo(AuditDetailDay);
