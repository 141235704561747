import { head, last } from 'ramda';
import { client } from '../util/sdk';

const endWith = 'endwith';
const format = 'format';
const displayName = 'displayname';
const reportOn = 'reporton';
const secondarySort = (order = displayName) => `,${order}:asc`;
const sort = secondarySort();
const startWith = 'startwith';

/**
 * All the extra order work is because the SDK normally handles combining
 * the multiple named orderBy* clauses into just one order clause, but,
 * the SDK only works for API requests, not Ontrac server requests which
 * is what this method calls
 */
export default (
  {
    id,
    orderByDisplayName,
    orderByFormat,
    orderByReportOn,
    reportOnRange,
    type,
    ...restOfData
  },
  auth
) => {
  let order;

  if (reportOnRange) {
    if (Array.isArray(reportOnRange)) {
      restOfData[`${startWith}(${reportOn})`] = head(reportOnRange);
      restOfData[`${endWith}(${reportOn})`] = last(reportOnRange);
    } else {
      restOfData[`${startWith}(${reportOn})`] = reportOnRange;
    }
  }

  if (orderByDisplayName) {
    order = `${displayName}:${orderByDisplayName}${secondarySort(reportOn)}`;
  } else if (orderByReportOn) {
    order = `${reportOn}:${orderByReportOn}${sort}`;
  } else if (orderByFormat) {
    order = `${format}:${orderByFormat}${sort}`;
  }

  restOfData.order = order;

  return client.executeRequest('GET', `/reportTypes/${id}/reports`, {
    queryParams: {
      ...restOfData,
      adminUserId: auth.id,
    },
  });
};
