import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import {
  CircularProgress,
  MenuItem as MuiMenuItem,
  Tooltip,
} from '@material-ui/core';
import { isLoadingState } from 'reducers/loading';
import { isNilOrEmpty } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';
import { PAYER_USER_STATUS_ACTIVE } from 'consts';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import MenuItemText from './MenuItemText';
import { PAYER_USER_INACTIVE_MESSAGE } from '../../consts';

const useStyles = makeStyles(() => ({
  progress: {
    left: 'calc(50% - 11px)',
    position: 'absolute',
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
}));

const MenuItem = forwardRef(
  (
    { action, item, payerUser, onClick, submenu, transactAs, ...props },
    ref
  ) => {
    const [userIdClicked, setUserIdClicked] = useState(0);
    const [isBusyAndUserIdClicked, setIsBusyAndUserIdClicked] = useState(false);
    const classes = useStyles();
    const isBusy = useSelector((state) => selector(state, action));
    const isLoading = useSelector((state) =>
      isLoadingState(state, action.ACTION)
    );
    const active = payerUser?.status === PAYER_USER_STATUS_ACTIVE;
    const disabled =
      isNilOrEmpty(item) && (isLoading || (transactAs && !active));

    const handleClick = (event) => {
      setUserIdClicked(payerUser.userId);
      onClick(event, payerUser);
    };

    useEffect(() => {
      const idsMatch = payerUser?.userId === userIdClicked;

      setIsBusyAndUserIdClicked(isBusy && idsMatch);

      return () => {
        if (isBusy && idsMatch) {
          setUserIdClicked(0);
        }
      };
    }, [isBusy, payerUser, userIdClicked]);

    return (
      <Tooltip
        arrow
        title={transactAs && !active ? PAYER_USER_INACTIVE_MESSAGE : ''}
      >
        <div>
          <MuiMenuItem
            ref={ref}
            disabled={disabled}
            onClick={handleClick}
            {...props}
          >
            <CircularProgress
              className={classNames(classes.progress, {
                [classes.visible]:
                  isNilOrEmpty(item) && isBusy && isBusyAndUserIdClicked,
              })}
              size={22}
              thickness={5}
            />
            <MenuItemText payerUser={payerUser} submenu={submenu} />
          </MuiMenuItem>
        </div>
      </Tooltip>
    );
  }
);

MenuItem.displayName = 'MenuItem';

MenuItem.propTypes = {
  action: PropTypes.object.isRequired,
  item: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  payerUser: PropTypes.object,
  submenu: PropTypes.bool,
  transactAs: PropTypes.bool,
};

MenuItem.defaultProps = {
  item: null,
  payerUser: null,
  submenu: false,
  transactAs: false,
};

export default MenuItem;
