import PropTypes from 'prop-types';
import { memo } from 'react';
import { NA_VALUE } from 'consts';
import { TextField } from '@material-ui/core';
import MaskPhone from './TextFieldPhone/MaskPhone';

const ReadOnlyPhone = ({ defaultValue, InputProps, ...props }) => {
  return (
    <TextField
      {...props}
      defaultValue={defaultValue || NA_VALUE}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        inputComponent: defaultValue ? MaskPhone : undefined,
        readOnly: true,
      }}
    />
  );
};

ReadOnlyPhone.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  InputProps: PropTypes.object,
};

ReadOnlyPhone.defaultProps = {
  defaultValue: null,
  InputProps: null,
};

export default memo(ReadOnlyPhone);
