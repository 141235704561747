import { createSelector } from 'redux-orm';
import { defaults, relatedName } from 'model/pageSetting';
import { head } from 'ramda';
import { orm } from 'model';

/**
 * PageSettings can be stored on a per payerId basis
 *   or
 * they are stored generically in payerId 0
 */
// eslint-disable-next-line import/prefer-default-export
export const selector = createSelector(
  orm,
  orm.Payer[relatedName],
  (state, id, type, defaultPageSettings) => ({ defaultPageSettings, id, type }),
  ({ PageSetting }, payerPageSettings, { defaultPageSettings, id, type }) => {
    let settings = {};

    if (id && payerPageSettings?.length) {
      settings = head(payerPageSettings);
    } else if (PageSetting.idExists(id)) {
      settings = PageSetting.withId(id).ref;
    }

    return { ...defaults, ...defaultPageSettings, ...settings[type] };
  }
);
