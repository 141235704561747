import { Model, attr } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class Flags extends Model {
  static get modelName() {
    return 'Flags';
  }

  static reducer({ type, data }, Flags) {
    switch (type) {
      case LOAD_FLAGS.SUCCESS:
      case LOAD_FLAGS.ACTION:
        Flags.delete();
        data?.response?.data?.forEach((item) => Flags.upsert(item));
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    // todo flags - update the fields list to match the API response
    return {
      FlagValue: attr(),
    };
  }
}

const name = Flags.modelName;

export const LOAD_FLAGS = createLoadConst(name);
export const loadFlags = createAction(LOAD_FLAGS);
