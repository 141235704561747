import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const transition = {
    duration: theme.transitions.duration.standard,
  };

  return {
    button: {
      marginLeft: theme.spacing(3),
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', transition),
    },
    expanded: {
      transform: 'rotate(180deg)',
    },
  };
});

const ErrorMessageButton = ({ expanded, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      aria-label="Details"
      className={classes.button}
      color="primary"
      {...props}
    >
      Details
      <KeyboardArrowDown
        className={classNames(classes.expand, {
          [classes.expanded]: expanded,
        })}
      />
    </Button>
  );
};

ErrorMessageButton.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default ErrorMessageButton;
