import { Button, CardActions, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useOneTimePasscode } from './OneTimePasscodeContext';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: theme.typography.fontSize - 3,
  },
}));

const OneTimePasscodeAction = () => {
  const classes = useStyles();
  const { login, sendCode, state } = useOneTimePasscode();

  const { buttonDisabled } = state;

  const handleVerifyClick = () => {
    login();
  };
  const handleSendCodeClick = () => {
    sendCode();
  };

  return (
    <CardActions>
      <Grid alignItems="center" container direction="column" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            disabled={buttonDisabled}
            onClick={handleVerifyClick}
            size="large"
            variant="contained"
          >
            Verify Code
          </Button>
        </Grid>
        <Grid item>
          <Button
            classes={{ textSizeSmall: classes.button }}
            onClick={handleSendCodeClick}
            size="small"
          >
            Send New code
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  );
};

export default OneTimePasscodeAction;
