import PropTypes from 'prop-types';
import { memo, useCallback, useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  Dialog,
  DialogContent,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { selector } from 'selectors/rtnInfo';
import { LOAD } from 'model/rtnInfo';
import { unmask } from 'sdk/externalAccount';
import {
  derivedSelectorId,
  updateSucceededSelector,
} from 'selectors/transactionHistory';
import { derivedSelectorId as derivedSelectorIdExternalAccount } from 'selectors/externalAccount';
import { isLoadingState } from 'reducers/loading';
import { isNilOrEmpty } from 'util/index';
import { keys, pickBy, has } from 'ramda';
import { updatePaymentNoc, updatePaymentNor } from 'model/transactionHistory';
import { useSelector, useDispatch } from 'react-redux';
import { handleStopPropagation } from 'consts';
import StepChangeType from './Step/StepChangeType';
import StepConfirmation from './Step/StepConfirmation';
import StepUpdate from './Step/StepUpdate';
import UpdatePaymentDialogActions from './UpdatePaymentDialogActions';
import UpdatePaymentDialogTitle from './UpdatePaymentDialogTitle';
import { NOC, NOR } from './consts';
import {
  INCORRECT_ACCOUNT_NUMBER,
  INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
  INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
  INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
  INCORRECT_ROUTING_NUMBER,
  INCORRECT_ACCOUNT_TYPE,
} from '../config';

const initialValues = {
  accountType: '',
  accountNumber: '',
  nocCode: '',
  routingNumber: '',
  returnedCode: '',
};
const steps = [
  { label: 'Change Type', index: 0 },
  { label: 'Update', index: 1 },
  { label: 'Confirmation', index: 2 },
];

const useStyles = makeStyles((theme) => {
  return {
    dialogPaper: {
      maxHeight: '70h',
      minHeight: '70vh',
    },
    slideContainer: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    slide: {
      overflow: 'hidden !important',
    },
  };
});

const accountNumberRequired = (nocCode) =>
  [
    INCORRECT_ACCOUNT_NUMBER,
    INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
    INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
    INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
  ].includes(nocCode);

const routingNumberRequired = (nocCode) =>
  [
    INCORRECT_ROUTING_NUMBER,
    INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
    INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
  ].includes(nocCode);

const accountTypeRequired = (nocCode) =>
  [
    INCORRECT_ACCOUNT_TYPE,
    INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
    INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
  ].includes(nocCode);

const NO_CHANGE = 'No change detected';

const UpdatePaymentDialog = ({ id, onClose, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => derivedSelectorId(state, { id }));
  const externalAccount = useSelector((state) =>
    derivedSelectorIdExternalAccount(state, transaction.externalDdaId)
  );

  const updateSucceeded = useSelector((state) =>
    updateSucceededSelector(state, { id: transaction.id })
  );
  const routingNumbers = useSelector(selector) || [];
  const isRoutingNumberLoading = useSelector((state) =>
    isLoadingState(state, LOAD.ACTION)
  );

  const [activeStep, setActiveStep] = useState(0);
  const [changeType, setChangeType] = useState(NOR);

  const handleEnter = (handleReset) => () => {
    setActiveStep(0);
    setChangeType(NOC);
    handleReset();
  };
  const handleChangeTypeChange = (type) => {
    setChangeType(type);
  };
  const handleNext = (validateForm, setFieldTouched) => async () => {
    if (activeStep !== 1) {
      setActiveStep((prev) => prev + 1);
      return;
    }

    const errors = await validateForm();
    if (keys(errors).length === 0) {
      setActiveStep((prev) => prev + 1);
    } else {
      /*
        validate doesn't touch fields, however, in order to show
        error messages, the code checks for touched flag
       */
      if (has('nocCode', errors)) {
        setFieldTouched('nocCode');
      }
      if (has('accountType', errors)) {
        setFieldTouched('accountType');
      }
      if (has('routingNumber', errors)) {
        setFieldTouched('routingNumber');
      }
      if (has('accountNumber', errors)) {
        setFieldTouched('accountNumber');
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleStepClick = (index) => () => {
    setActiveStep(index);
  };

  const validate = async (values) => {
    const errors = {};

    // No validation on confirmation page
    if (activeStep === 2) return errors;

    if (changeType === NOC) {
      if (isNilOrEmpty(values.nocCode)) {
        errors.nocCode = 'NOC Code is required';
      }

      if (accountTypeRequired(values.nocCode)) {
        const accountType = values.accountType;
        if (isNilOrEmpty(accountType)) {
          errors.accountType = 'Account Type is required';
        } else if (accountType === externalAccount.ddaType) {
          errors.accountType = NO_CHANGE;
        }
      }

      if (accountNumberRequired(values.nocCode)) {
        const accountNumber = values.accountNumber;
        if (isNilOrEmpty(accountNumber)) {
          errors.accountNumber = 'Bank Account Number is required';
        } else {
          const originalAccountNumber = await unmask({
            fspId: transaction.fspId,
            id: externalAccount.id,
          });
          if (accountNumber === originalAccountNumber)
            errors.accountNumber = NO_CHANGE;
        }
      }

      if (routingNumberRequired(values.nocCode)) {
        const routingNumber = values.routingNumber;

        if (isNilOrEmpty(routingNumber)) {
          errors.routingNumber = 'Routing Number is required';
        } else if (routingNumber.length < 9) {
          errors.routingNumber = 'Routing Number should be nine digits';
        } else if (routingNumber === externalAccount.achRtn) {
          errors.routingNumber = NO_CHANGE;
        } else {
          const validatedRoutingNumber = routingNumbers.find(
            ({ routingNumber: existingRoutingNumber }) =>
              existingRoutingNumber === routingNumber
          );

          if (validatedRoutingNumber && validatedRoutingNumber.error) {
            errors.routingNumber = validatedRoutingNumber.error;
          }
        }
      }
    } else if (changeType === NOR && isNilOrEmpty(values.returnedCode)) {
      errors.returnedCode = 'NOR Code is required';
    }

    return errors;
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    const { accountType, accountNumber, nocCode, routingNumber, returnedCode } =
      values;

    if (changeType === NOC) {
      dispatch(
        updatePaymentNoc(
          pickBy((value) => !isNilOrEmpty(value), {
            fspId: transaction.fspId,
            payerId: transaction.payerId,
            id: transaction.id,
            nocCode,
            accountType: accountTypeRequired(nocCode) ? accountType : null,
            accountNumber: accountNumberRequired(nocCode)
              ? accountNumber
              : null,
            routingNumber: routingNumberRequired(nocCode)
              ? routingNumber
              : null,
          })
        )
      );
    } else {
      dispatch(
        updatePaymentNor({
          fspId: transaction.fspId,
          id: transaction.id,
          returnedCode,
        })
      );
    }

    setSubmitting(false);
  };

  const handleClose = useCallback(
    (event, reason) => {
      handleStopPropagation(event);

      if (reason !== 'backdropClick') {
        onClose(event);
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (updateSucceeded) {
      onClose();
    }
  }, [onClose, updateSucceeded]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validate}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        handleSubmit,
        setFieldTouched,
        touched,
        validateForm,
      }) => (
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          fullWidth
          onClick={handleStopPropagation}
          onClose={handleClose}
          TransitionProps={{ onEntered: handleEnter(handleReset) }}
          open={open}
        >
          <UpdatePaymentDialogTitle id={id} />
          <DialogContent>
            <Stepper activeStep={activeStep}>
              {steps.map(({ label, index }) => (
                <Step key={label}>
                  <StepButton onClick={handleStepClick(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <SwipeableViews
              className={classes.slideContainer}
              index={activeStep}
              slideClassName={classes.slide}
            >
              <StepChangeType
                onChange={handleChangeTypeChange}
                value={changeType}
              />
              <StepUpdate
                changeType={changeType}
                className={classes.slide}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                values={values}
              />
              <StepConfirmation changeType={changeType} {...values} />
            </SwipeableViews>
          </DialogContent>
          <UpdatePaymentDialogActions
            activeStep={activeStep}
            disabled={isRoutingNumberLoading}
            onBack={handleBack}
            onClose={handleClose}
            onNext={handleNext(validateForm, setFieldTouched)}
            onSubmit={handleSubmit}
          />
        </Dialog>
      )}
    </Formik>
  );
};

UpdatePaymentDialog.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default memo(UpdatePaymentDialog);
