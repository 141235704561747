import { useSelector } from 'react-redux';
import { getAuth } from 'util/index';
import { hasPermission } from 'util/rbac';

const useHasPermission = (actions = []) => {
  const auth = useSelector(getAuth);
  return hasPermission(auth, ...actions);
};

export default useHasPermission;
