import {
  add,
  addCustom,
  importAndReset,
  load,
  remove,
  removeCustom,
  update,
} from 'sdk/limitRule';
import {
  ADD,
  ADD_CUSTOM,
  LOAD,
  LOAD_CUSTOM,
  IMPORT,
  REMOVE,
  REMOVE_CUSTOM,
  UPDATE,
} from 'model/limitRule';
import { takeEvery } from 'redux-saga/effects';
import {
  ADDED,
  formatSuccessMessage,
  IMPORTED,
  REMOVED,
  UPDATED,
  LIMIT_RULE,
} from 'consts';
import { callAction, ORM } from '../util';
import { loadFSPProfileAction } from './export';

const keyName = { key: 'limitTypeDerived', name: LIMIT_RULE };

function* addAndPopulateExport(action) {
  const addAction = callAction({
    api: add,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, ADDED),
    type: ADD,
  });

  if (yield addAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

function* importAndPopulateExport(action) {
  const importAndResetAction = callAction({
    api: importAndReset,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, IMPORTED, true),
    type: IMPORT,
  });

  if (yield importAndResetAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

function* removeAndPopulateExport(action) {
  const removeAction = callAction({
    api: remove,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, REMOVED),
    type: REMOVE,
  });

  if (yield removeAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: () => `${LIMIT_RULE} was successfully ${UPDATED}`,
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeEvery(
    ADD_CUSTOM.ACTION,
    callAction({
      api: addCustom,
      dataName: ORM,
      formatSuccessMessage: () => `${LIMIT_RULE} was successfully ${ADDED}`,
      type: ADD_CUSTOM,
    })
  );

  yield takeEvery(ADD.ACTION, addAndPopulateExport);

  yield takeEvery(IMPORT.ACTION, importAndPopulateExport);

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_CUSTOM.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD_CUSTOM,
    })
  );

  yield takeEvery(REMOVE.ACTION, removeAndPopulateExport);

  yield takeEvery(
    REMOVE_CUSTOM.ACTION,
    callAction({
      api: removeCustom,
      dataName: ORM,
      formatSuccessMessage: () => `${LIMIT_RULE} was successfully ${REMOVED}`,
      type: REMOVE_CUSTOM,
    })
  );

  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);
}
