import { TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLimits } from '../LimitsContext';

const useStyles = makeStyles(() => ({
  cell: {
    borderBottom: 0,
    fontSize: 'large',
    padding: 0,
  },
}));

const LimitsTableNoResultsRow = () => {
  const classes = useStyles();
  const { limit } = useLimits();

  return limit?.rowCountDerived === 0 ? (
    <TableRow>
      <TableCell align="center" classes={{ root: classes.cell }} colSpan={6}>
        No Limits Found
      </TableCell>
    </TableRow>
  ) : null;
};

export default LimitsTableNoResultsRow;
