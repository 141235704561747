import { CardHeader } from '@material-ui/core';
import { isNil } from 'ramda';
import { isNilOrEmpty } from 'util/index';
import LimitsCardHeaderActions from './LimitsCardHeaderActions';
import { useLimits } from '../LimitsContext';

const LimitsCardHeader = () => {
  const { expired, limit, limitRule } = useLimits();

  const custom = !expired && !isNil(limitRule.expireDateDisplayDerived);
  const subheader = custom
    ? `Expires: ${limitRule.expireDateDisplayDerived || ''}`
    : '';
  const description = custom ? 'Custom' : limit?.description;
  const title = `Payer Limits${
    !isNilOrEmpty(description) ? ` (${description})` : ''
  }`;

  return (
    <CardHeader
      action={<LimitsCardHeaderActions />}
      subheader={subheader}
      title={title}
    />
  );
};

export default LimitsCardHeader;
