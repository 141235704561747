import { attr, Model } from 'redux-orm';
import { LOAD as LOAD_USER } from 'model/ontracUser';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Group extends Model {
  static get modelName() {
    return 'Group';
  }

  static reducer({ type, data = [] }, Group) {
    switch (type) {
      case LOAD.SUCCESS:
      case LOAD_BY_ORG_ID.SUCCESS:
        Group.delete();
        data.forEach((group) => Group.upsert(group));
        break;

      case LOAD_USER.SUCCESS:
        data.forEach(({ groups = [] }) =>
          groups.forEach((group) => Group.upsert(group))
        );
        break;

      case ADD.SUCCESS:
        Group.upsert(data);
        break;

      case DELETE.SUCCESS:
        Group.withId(data.id).delete();
        break;

      case UPDATE.SUCCESS:
        Group.update(data);
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      fsps: attr(),
      groupAdmins: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      organizationId: attr(),
      organizationName: attr(),
      roles: attr(),
      updatedAt: attr(),
      // TODO: this should be a many to many relation with groups, as described below.
      // Leaving as a plain attr() for now, since I have load order issues with this relation.
      /*
      groupAdmins: many({
        to: Group.modelName,
        through: GroupAdmin.modelName,
        relatedName: 'adminBy',
        throughFields: ['adminGroupId', 'groupId'],
      }), */
    };
  }
}

export const FORM_NAME = 'setupGroup';

export const ADD = createAddConst(Group.modelName);
export const DELETE = createDeleteConst(Group.modelName);
export const LOAD = createRequestConst(Group.modelName);
export const LOAD_BY_ORG_ID = createLoadConst(Group.modelName);
export const UPDATE = createUpdateConst(Group.modelName);

export const add = createAction(ADD);
export const deleteGroup = createAction(DELETE);
export const load = createAction(LOAD);
export const loadByOrgId = createAction(LOAD_BY_ORG_ID);
export const update = createAction(UPDATE);
