import { has, not } from 'ramda';
import { client, formatQueryParams } from 'util/sdk';

export const add = (data, auth) =>
  client.billerSetBillers.create(data, { adminUserId: auth.id });

export const load = (params) => {
  if (not(has('billerSetId', params))) {
    return [];
  }
  return client.billers.query(formatQueryParams(params));
};

export const remove = ({ billerSetId, billerId }, auth) =>
  client
    .billerSets(billerSetId)
    .billers(billerId)
    .delete({ adminUserId: auth.id });
