/* eslint-disable react/no-array-index-key */
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { DISTRIBUTOR_ID, NA_VALUE } from 'consts';
import RenderOptionPart from '../../RenderOptionPart';

const options = { insideWords: true };

const RenderOption =
  ({ value }) =>
  (distributor, { inputValue }) => {
    const name = distributor?.name ?? NA_VALUE;
    const distributorDisplayValue =
      value === DISTRIBUTOR_ID
        ? `${distributor?.id} `
        : `${distributor?.name} `;
    const text = `${distributorDisplayValue} ${name} (${distributor?.id})`;
    const matches = match(distributorDisplayValue, inputValue, options);
    const parts = parse(text, matches);

    return parts.map(({ highlight, text }, index) => (
      <RenderOptionPart key={index} highlight={highlight} text={text} />
    ));
  };

export default RenderOption;
