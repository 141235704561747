import { useEffect } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { handleStopPropagation } from 'consts';
import { loadCustom as loadRules } from 'model/limitRule';
import { load } from 'model/limit';
import { loadById } from 'model/payer';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import LimitsCardHeader from './LimitsCardHeader';
import LimitsTable from '../LimitsTable';
import { useLimits } from '../LimitsContext';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 1100,
    minWidth: 500,
  },
  content: {
    minHeight: 337,
    paddingTop: 0,
  },
}));

const LimitsCard = () => {
  const { fspId, limit, limitType, payerId } = useLimits();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(load({ fspId, type: limitType }));
  }, [dispatch, fspId, limitType]);

  useEffect(() => {
    if (limit.refreshDerived && limit.id !== limit.newIdDerived) {
      // The limitType prop is a property of the payer and it may have changed
      // We can't determine it, so refresh it. (If the value does change,
      // limitType will change which causes a new limitId to be returned which
      // refreshes all
      dispatch(loadById({ fspId, id: payerId }));
    }
  }, [dispatch, fspId, limit, payerId]);

  useEffect(() => {
    if (limit.id && limit.fspId === fspId) {
      dispatch(loadRules({ fspId, id: limit.id, payerId }));
    }
  }, [dispatch, fspId, limit.fspId, limit.id, payerId]);

  useEffect(() => {
    if (limit.newIdDerived && limit.fspId === fspId) {
      dispatch(loadRules({ fspId, id: limit.newIdDerived, payerId }));
    }
  }, [dispatch, fspId, limit.fspId, limit.newIdDerived, payerId]);

  return (
    <Card classes={{ root: classes.card }} onClick={handleStopPropagation}>
      <LimitsCardHeader />
      <CardContent className={classes.content}>
        <LimitsTable />
      </CardContent>
    </Card>
  );
};

export default LimitsCard;
