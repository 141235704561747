import { attr, fk, Model } from 'redux-orm';
import Payer from 'model/payer';
import { createAction, createRequestConst, date } from '../util';
import { getInsightStatusDates, insightStatus } from './util/actionInsight';

const derived = (item) => {
  return {
    ...item,
    activeAtLabel: date(item?.activeAt),
    expiredAtLabel: date(item?.expiredAt),
    firstViewOnLabel: date(item?.firstViewOn),
    lastViewOnLabel: date(item?.lastViewOn),
    insightStatusDates: getInsightStatusDates(item),
    insightStatusLabel: insightStatus[item?.insightStatus],
  };
};

export default class ActionInsight extends Model {
  static get modelName() {
    return 'ActionInsight';
  }

  static reducer({ type, data }, ActionInsight, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS: {
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).actionInsights.delete();
        }

        if (data?.response) {
          data.response.forEach((r) => ActionInsight.upsert(derived(r)));
        }
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      acceptedAt: attr(),
      activeAt: attr(),
      clickCnt: attr(),
      clickedAt: attr(),
      createdAt: attr(),
      ddaId: attr(),
      declinedAt: attr(),
      expiredAt: attr(),
      expiredReason: attr(),
      externalDdaId: attr(),
      firstViewOn: attr(),
      firstViewOnLabel: attr(), // derived
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insightEventId: attr(),
      insightEventName: attr(),
      insightStatus: attr(),
      insightStatusDates: attr(), // derived
      insightStatusDetailLabel: attr(), // derived
      insightStatusLabel: attr(), // derived
      insUser: attr(),
      insUserId: attr(),
      lastViewOn: attr(),
      lastViewOnLabel: attr(), // derived
      modelId: attr(),
      offset: attr(),
      payeeId: attr(),
      payeeName: attr(),
      payerId: fk(Payer.modelName, 'actionInsights'),
      recipientId: attr(),
      renderedMessage: attr(),
      renderedTitle: attr(),
      schedId: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
      updUser: attr(),
      updUserId: attr(),
      viewCnt: attr(),
      viewMax: attr(),
    };
  }
}

export const LOAD = createRequestConst(ActionInsight.modelName);
export const load = createAction(LOAD);
