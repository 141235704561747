import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  DELIVERED,
  PAL_ESCROW_DERIVED,
  PAL_PRINCIPAL_DERIVED,
  PROCESSED,
  TRANSACTION_TYPE_PAL,
} from 'consts';

export const NOC = 'NOC';
export const NOR = 'NOR';

export const isNOCOrNOA = ({ status, transactionType }) =>
  [
    ACCOUNT_TO_ACCOUNT_INBOUND,
    ACCOUNT_TO_ACCOUNT_NEW,
    ACCOUNT_TO_ACCOUNT_OUTBOUND,
    PAL_ESCROW_DERIVED,
    PAL_ESCROW_DERIVED,
    PAL_PRINCIPAL_DERIVED,
    TRANSACTION_TYPE_PAL,
  ].includes(transactionType) && [DELIVERED, PROCESSED].includes(status);
