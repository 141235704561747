import {
  equals,
  find,
  includes,
  indexOf,
  reject,
  slice,
  startsWith,
} from 'ramda';
import { SUCCESS, actionStart, actionEnd } from 'util/actions';

export const selector = ({ busy = '' }, { BUSY }) => includes(BUSY, busy);

export default (state = [], { type }) => {
  if (actionStart(type) && !includes(type, state)) {
    return [...state, type];
  }
  if (actionEnd(type)) {
    const foundItem = find(
      (item) => startsWith(slice(0, indexOf(SUCCESS, item), item), type),
      state
    );
    return reject(equals(foundItem), state);
  }
  return state;
};
