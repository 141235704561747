import { LOGIN, LOGOUT, UPDATE, CLEAR_UPDATE } from './authToken';

const initialState = { passwordExpired: false };

export default (state = initialState, { type, data = {} }) => {
  switch (type) {
    case LOGIN.SUCCESS:
      return { passwordExpired: data.passwordExpired };

    case UPDATE.SUCCESS:
    case LOGIN.FAILURE:
    case LOGOUT.FAILURE:
    case LOGOUT.SUCCESS:
    case CLEAR_UPDATE.ACTION:
      return initialState;
    default:
      break;
  }

  return state;
};
