import { attr } from 'redux-orm';
import { createAction, createRequestConst, createUpdateConst } from '../util';
import BaseModel from './baseModel';

export default class UserProfile extends BaseModel {
  static get modelName() {
    return 'UserProfile';
  }

  static reducer({ type, data }, UserProfile) {
    switch (type) {
      case LOAD.SUCCESS:
      case UPDATE.SUCCESS:
        UserProfile.upsert(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      actions: attr(),
      adminOrg: attr(),
      createdAt: attr(),
      email: attr(),
      firstName: attr(),
      fsps: attr(),
      groups: attr(),
      id: attr(),
      lastLoginAt: attr(),
      lastName: attr(),
      lastOtpAuthAt: attr(),
      lastOtpAuthVia: attr(),
      middleName: attr(),
      mobilePhone: attr(),
      organizationId: attr(),
      otpGoodUntilAt: attr(),
      otpMaxSendTries: attr(),
      roles: attr(),
      type: attr(),
      updatedAt: attr(),
      userLogin: attr(),
      userStatus: attr(),
      workPhone: attr(),
    };
  }
}

export const FORM_NAME = 'userProfile';

export const LOAD = createRequestConst(UserProfile.modelName);
export const UPDATE = createUpdateConst(UserProfile.modelName);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
