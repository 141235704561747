import { createAction, createUpdateConst } from 'util/actions';

const initialState = {
  fromPayerId: '',
  toPayerId: '',
  toUserId: '',
  payeesOffOn: undefined, // default will get set to ON in component
  bpTransactionsOffOn: undefined, // required if PayeesOffOn = on
  bpDdaId: undefined, // required if PayeesOffOn = on
  recipientsOffOn: undefined, // default will get set to ON in component
  p2pTransactionsOffOn: undefined, // required if RecipientsOffOn = on
  p2pDdaId: undefined, // required if RecipientsOffOn = on
  externalDdasOffOn: undefined, // a2aIn and a2a out only - no need to support internalXfer or a2aNew
  a2aTransactionsOffOn: undefined, // required if ExternalDDAsOffOn = on
  a2aFromDdaId: undefined, // required if ExternalDDAsOffOn = on
  a2aToDdaId: undefined, // required if ExternalDDAsOffOn = on
};
const name = 'mergePayer';

export const UPDATE = createUpdateConst(name);
export const update = createAction(UPDATE);
export const selector = (state) => state[name];

export default (state = initialState, { type, data }) => {
  switch (type) {
    case UPDATE.ACTION:
      return { ...state, ...data };

    default:
      return state;
  }
};
