import classNames from 'classnames';
import LoadingSkeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Grid, IconButton, Popover, Typography } from '@material-ui/core';
import { isEmpty, isNil } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { NA_VALUE, handleStopPropagation } from 'consts';
import { useDispatch } from 'react-redux';
import { useTimeout } from 'hooks';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { getValue, DECRYPT, EMPTY, SECONDS_TO_SHOW_MASK } from '../Mask';
import MaskPopoverProgress from './MaskPopoverProgress';

const anchorOrigin = { horizontal: 'left', vertical: 'center' };
const transformOrigin = { vertical: 'center', horizontal: 'left' };
const TIMEOUT_VALUE = SECONDS_TO_SHOW_MASK * 1000;

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    position: 'relative',
  },
  content: {
    padding: theme.spacing(2),
    minHeight: 22 + theme.spacing(4),
    minWidth: 160,
  },
  label: {
    color: theme.palette.text.primary,
    font: 'inherit',
    fontFamily: theme.typography.fontFamily,
    userSelect: 'auto',
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  prefix: {
    marginRight: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  progress: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  noIcon: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
  displayNone: {
    display: 'none',
  },
}));

const MaskPopover = ({
  api,
  format,
  icon,
  PopoverProps,
  prefix,
  suffix,
  value,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const [unmaskedValue, setUnmaskedValue] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const hide = isNil(value);
  const maskSuffix = isEmpty(suffix) || isNil(suffix) ? EMPTY : `-${suffix}`;
  const inlineWithValue = !icon && !isNil(value);
  const maskClassName = classNames(classes.label, {
    [classes.noIcon]: !icon && !isNil(value),
  });
  const maskValue = getValue(value, maskSuffix);
  const prefixClassName = classNames(classes.label, classes.prefix, {
    [classes.displayNone]: isEmpty(prefix) || isNil(prefix),
  });
  const showNA =
    !isNil(value) && typeof value !== 'string' && typeof value !== 'number';
  const timeoutValue = unmaskedValue ? TIMEOUT_VALUE : null;
  const unMaskClassName = classNames(classes.iconButton, {
    [classes.displayNone]: hide || !icon,
  });

  const unmask = async () => {
    let value = NA_VALUE;

    try {
      const data = await api();

      if (data) {
        value = `${format ? format(data) : data}${maskSuffix}`;
      }

      setUnmaskedValue(value);
    } catch (e) {
      dispatch({
        type: DECRYPT.FAILURE,
        error: { message: 'Unable to decrypt', e },
      });
    }
  };
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    unmask();
  };
  const handleClosePopover = (event) => {
    if (event) {
      event.stopPropagation();
      setAnchorEl(null);
    }

    setOpen(false);
    setUnmaskedValue(null);
  };
  const handleOnClick = inlineWithValue ? handleClick : undefined;

  useTimeout(handleClosePopover, timeoutValue);

  return showNA ? (
    <Typography variant="body2">{NA_VALUE}</Typography>
  ) : (
    <>
      <Grid
        alignItems="center"
        className={classes.root}
        container
        wrap="nowrap"
      >
        <Grid className={prefixClassName} item t-i="maskTextPrefix">
          {prefix}
        </Grid>
        <Grid
          className={maskClassName}
          item
          onClick={handleOnClick}
          t-i="maskText"
        >
          {maskValue}
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Visibility On/Off"
            className={unMaskClassName}
            disabled={hide}
            onClick={handleClick}
          >
            {unmaskedValue ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Grid>
      </Grid>
      <Popover
        {...PopoverProps}
        anchorEl={anchorEl}
        onClick={handleStopPropagation}
        onClose={handleClosePopover}
        open={open}
      >
        <Grid
          alignItems="center"
          className={classes.content}
          container
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Typography>
              {unmaskedValue || <LoadingSkeleton width={60} />}
            </Typography>
          </Grid>
          <Grid item>
            <MaskPopoverProgress show={!isNil(unmaskedValue)} />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

MaskPopover.propTypes = {
  api: PropTypes.func.isRequired,
  format: PropTypes.func,
  icon: PropTypes.bool,
  PopoverProps: PropTypes.object,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

MaskPopover.defaultProps = {
  format: null,
  icon: true,
  PopoverProps: { anchorOrigin, transformOrigin },
  prefix: null,
  suffix: null,
  value: null,
};

export default MaskPopover;
