import { defaultPayerId, update } from 'model/pageSetting';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { omit } from 'ramda';
import usePageSettings from '../usePageSettings';
import usePaginationData from './usePaginationData';
import useIconButtonProps from './useIconButtonProps';
import useCount from './useCount';

const refreshedAt = ['refreshedAt'];

/**
 * Considerations: The API does not return the amount of rows that a given query can return,
 * hence the upper limit cannot be known. To work around this issue, all query requests one more
 * item than the user asked for. With this information one can tell whether there are
 * more items than the current page. Though it is not possible to know how many more pages. The
 * only thing that can be displayed to the user is "row N of M+", where M+ is the current page limit.
 *
 * The framework keeps track of this information as a flag (hasMoreItems) that is stored together
 * with the PageSettings
 */
const usePagination = (
  action,
  actionParams,
  actionType,
  defaultPageSettings,
  hasFspId = true,
  name,
  countSelector,
  countSelectorProps
) => {
  const dispatch = useDispatch();
  const itemsCount = useCount(countSelector, countSelectorProps);
  const payerId = countSelectorProps?.id || defaultPayerId;
  const pageSettings = usePageSettings(name, payerId, defaultPageSettings);
  const updatePageSettings = useCallback(
    (settings) => {
      const data = {
        payerId,
        type: name,
        ...settings,
      };

      dispatch(update(data));
    },
    [dispatch, payerId, name]
  );
  const paginationData = usePaginationData({
    countSelectorProps,
    hasFspId,
    actionParams,
    ...pageSettings,
  });
  const [backIconButtonProps, nextIconButtonProps, isLoading] =
    useIconButtonProps(
      actionType,
      pageSettings.hasMoreItems,
      pageSettings.page
    );

  const { hasMoreItems, page, rowsPerPage, rowsPerPageOptions } = pageSettings;

  useEffect(() => {
    if (action) {
      // All API calls would fail if we included refreshedAt
      const data = omit(refreshedAt, paginationData);
      const params = { pageSettingsName: name, payerId };

      dispatch(action(data, null, params));
    }
  }, [action, dispatch, name, paginationData, payerId]);

  return useMemo(() => {
    const count =
      page * rowsPerPage + (hasMoreItems ? rowsPerPage + 1 : itemsCount);
    const labelDisplayedRows = ({ count, from }) => {
      const currentPageItemCount = page * rowsPerPage + itemsCount;
      const labelRoot = `${from}-${currentPageItemCount} of `;
      const offset = (page + 1) * rowsPerPage;
      let label = `${labelRoot}${count}`;

      if (hasMoreItems) {
        label = `${labelRoot}${offset}+`;
      }

      return label;
    };
    const onPageChange = (event, page) => {
      updatePageSettings({ page });
    };
    const onRowsPerPageChange = ({ target }) => {
      updatePageSettings({ rowsPerPage: target.value });
    };

    return {
      backIconButtonProps,
      count,
      labelDisplayedRows,
      nextIconButtonProps,
      onPageChange,
      onRowsPerPageChange,
      page,
      rowsPerPage,
      rowsPerPageOptions,
      SelectProps: { disabled: isLoading },
    };
  }, [
    backIconButtonProps,
    isLoading,
    itemsCount,
    hasMoreItems,
    nextIconButtonProps,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    updatePageSettings,
  ]);
};

export default usePagination;
