import { createRequestConst, createAction } from 'util/actions';

export const VALIDATE = createRequestConst('validate');

export const validate = createAction(VALIDATE);

export const CHANGED = 'Ontrac User Password Changed';
export const NEW = 'Ontrac New Admin User';
export const RESET = 'Ontrac User Reset Password';

const initialState = {
  data: {},
  error: { message: '', number: 0 },
  ran: false,
  success: false,
};

export default (state = initialState, { type, data, error }) => {
  switch (type) {
    case VALIDATE.SUCCESS: {
      const is404 = Array.isArray(data);
      return {
        data: is404 ? {} : data,
        error: is404 ? { message: 'Invalid link', number: 404 } : {},
        ran: true,
        success: !is404,
      };
    }

    case VALIDATE.FAILURE:
      return { data: {}, error, ran: true, success: false };

    default:
      return state;
  }
};
