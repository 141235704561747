import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { always } from 'ramda';
import {
  IconButton,
  Table,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionListPaginationEnhanced from './ExpansionListPaginationEnhanced';
import useIsLoading from '../../../hooks/useIsLoading';

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  label: {
    display: 'inline-block',
    minWidth: 80,
  },
  tablePaginationRoot: {
    borderBottom: 0,
  },
  tablePaginationToolbar: {
    paddingBottom: theme.spacing(1),
  },
  tableRow: {
    borderBottom: 0,
  },
  visible: {
    visibility: 'visible',
  },
}));

const ExpansionListPagination = ({
  actionType,
  columnMeta,
  enableFilter,
  ListPaginationProps,
  onChange,
  order,
  orderBy,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  setFilter,
  total,
  usesPagination,
  pageSettingsName,
}) => {
  const [showFilter, setShowFilter] = useState();
  const classes = useStyles();
  const isLoading = useIsLoading(actionType);

  const colSpan = columnMeta.length - (enableFilter ? 1 : 0);

  const handleChangePage = (event, page) => {
    onChange(event, {
      orderBy,
      order,
      page,
      rowsPerPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    onChange(event, {
      orderBy,
      order,
      page,
      rowsPerPage: event.target.value,
    });
  };
  const handleShowFilter = () => {
    if (showFilter) {
      setFilter({});
    }
    setShowFilter(!showFilter);
  };
  const handleLabelDisplayedRows = ({ count, from, to }) => {
    let label;
    const offset = (page + 1) * rowsPerPage;

    if (Number.isNaN(count)) {
      label = `0-0 of 0`;
    } else if (count > offset) {
      label = `${from}-${to} of ${offset}+`;
    } else {
      label = `${from}-${to} of ${count}`;
    }

    return <span className={classes.label}>{label}</span>;
  };

  return usesPagination ? (
    <>
      <Table>
        <TableFooter>
          <TableRow className={classes.tableRow}>
            {enableFilter && (
              <TableCell>
                <IconButton
                  aria-label="Filter list"
                  onClick={handleShowFilter}
                  t-i="filter"
                >
                  <FilterListIcon />
                </IconButton>
              </TableCell>
            )}
            <TablePagination
              classes={{
                root: classes.tablePaginationRoot,
                toolbar: classes.tablePaginationToolbar,
              }}
              colSpan={colSpan}
              count={total}
              labelDisplayedRows={handleLabelDisplayedRows}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              SelectProps={{ disabled: isLoading }}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {ListPaginationProps?.showProgress && (
        <ExpansionListPaginationEnhanced
          ListPaginationProps={ListPaginationProps}
          pageSettingsName={pageSettingsName}
        />
      )}
    </>
  ) : null;
};

ExpansionListPagination.propTypes = {
  actionType: PropTypes.string,
  columnMeta: PropTypes.array.isRequired,
  enableFilter: PropTypes.bool.isRequired,
  ListPaginationProps: PropTypes.object,
  onChange: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  setFilter: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  usesPagination: PropTypes.bool.isRequired,
  pageSettingsName: PropTypes.string,
};

ExpansionListPagination.defaultProps = {
  actionType: null,
  ListPaginationProps: null,
  onChange: always,
  orderBy: null,
  pageSettingsName: '',
};

export default ExpansionListPagination;
