import storage from 'redux-persist/lib/storage';
import { KEY as selectedFspId } from 'sagas/selectedFspId';
import { KEY as selectedOrgId } from 'sagas/selectedOrgId';
import { LOGOUT } from 'reducers/authToken';
import { NAME as audit } from 'reducers/audit';
import { UNAUTHORIZED } from 'util/actions';
import { clear } from 'util/token';
import { name as persistedState } from 'reducers/persistedState';
import { name as recentPayers } from 'reducers/recentPayers';
import { name as recentTickets } from 'reducers/recentTickets';
import { persistCombineReducers } from 'redux-persist';
import { reducer as form } from 'redux-form';

export const ORM = 'orm';

const whitelist = [
  audit,
  persistedState,
  recentPayers,
  recentTickets,
  selectedFspId,
  selectedOrgId,
];

const persistConfig = {
  key: 'Ontrac',
  storage,
  version: 1,
  whitelist,
};

export default (reducers = {}, orm = {}) => {
  const appReducer = persistCombineReducers(persistConfig, {
    ...reducers,
    form,
    orm,
  });

  // rootReducer
  return (state, action) => {
    switch (action.type) {
      case LOGOUT.ACTION: // We don't care if logout fails or succeeds - when the action is first executed, clear out state/session data
      case UNAUTHORIZED.ACTION: {
        // clear the token from session storage
        clear();

        /**
         * _persist - Why is this here?
         *
         * redux-persist needs to keep a state key around otherwise it will not
         * continue to update the persisted state
         */
        state = {
          // eslint-disable-next-line no-underscore-dangle
          _persist: { ...state._persist },
          authToken: action.data?.authToken ? action.data.authToken : undefined,
        };
        break;
      }

      default:
        break;
    }

    return appReducer(state, action);
  };
};
