import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { DialogTitle, Grid, Typography, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { unmask } from 'sdk/externalAccount';
import { derivedSelectorId } from 'selectors/transactionHistory';
import { ReadOnlyFormControl } from 'components/common/fields';
import { loadById as loadExternalAccountById } from 'model/externalAccount';
import { derivedSelectorId as derivedSelectorIdExternalAccount } from 'selectors/externalAccount';
import { Mask } from 'components/common';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 0,
  },
}));

const UpdatePaymentDialogTitle = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => derivedSelectorId(state, { id }));
  const externalAccount = useSelector((state) =>
    derivedSelectorIdExternalAccount(state, transaction.externalDdaId)
  );

  useEffect(() => {
    const fspId = transaction.fspId;
    const extId = transaction.externalDdaId;

    if (typeof fspId === 'number' && typeof extId === 'number') {
      dispatch(
        loadExternalAccountById({ fspId, id: transaction.externalDdaId })
      );
    }
  }, [dispatch, transaction.fspId, transaction.externalDdaId]);

  return (
    <DialogTitle classes={{ root: classes.root }} disableTypography>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5">Update Payment</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}>
              <ReadOnlyFormControl
                label="Amount"
                value={transaction.amountDerived}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyFormControl
                label="Type"
                value={transaction.transactionTypeDerived}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyFormControl
                capitalize
                label="Account Type"
                value={externalAccount.ddaTypeDerived}
              />
            </Grid>
            <Grid item xs={4}>
              <ReadOnlyFormControl
                label="Routing Number"
                value={externalAccount.achRtn}
              />
            </Grid>
            <Grid item xs={8}>
              <ReadOnlyFormControl
                inputNode={
                  <Mask
                    api={() =>
                      unmask({
                        fspId: transaction.fspId,
                        id: externalAccount.id,
                      })
                    }
                    value={externalAccount.accountNbrMask}
                  />
                }
                label="Account Number"
                value={externalAccount.accountNbrMask}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

UpdatePaymentDialogTitle.propTypes = {
  id: PropTypes.number.isRequired,
};

export default memo(UpdatePaymentDialogTitle);
