import moment from 'moment';
import { dateAndTime, getLabelWithId, isOn, isNilOrEmpty } from 'util/index';
import {
  CHECK,
  ELECTRONIC,
  ELECTRONIC_VC,
  IS_EMPTY,
  NA_VALUE,
  OFF,
  ON,
} from 'consts';

export const getAddress1 = ({ street1, street2 }) => {
  return `${street1 ?? ''}${street2 ? `, ${street2}` : ''}`.trim();
};
export const getAddress2 = ({ city, state, zip }) => {
  const zipcode =
    zip?.length > 5 ? `${zip.slice(0, 5)}-${zip.slice(5)}` : zip ?? '';

  return `${city ?? ''}${city ? ',' : ''} ${state ?? ''} ${zipcode}`.trim();
};

export const isVirtualCard = (value) => {
  return !!value.ipayNetBillerId;
};

export const getDisbursementOptions = (item) => {
  const { deliverCheckExpedite, deliverCheckStd, deliverElecStd, id } = item;
  const disbursementOptions = [];
  const isVc = isVirtualCard(item);

  if (deliverElecStd === ON) {
    if (isVc) {
      disbursementOptions.push({
        checked: true,
        key: `${id}deliverElecVc`,
        label: ELECTRONIC_VC,
      });
    } else {
      disbursementOptions.push({
        checked: true,
        key: `${id}deliverElecStd`,
        label: ELECTRONIC,
      });
    }
  }

  if (deliverCheckStd === ON) {
    disbursementOptions.push({
      checked: true,
      key: `${id}deliverCheckStd`,
      label: CHECK,
    });
  }

  if (deliverCheckExpedite === ON) {
    disbursementOptions.push({
      checked: true,
      key: `${id}deliverCheckExpedite`,
      label: 'Check Expedited',
    });
  }
  return disbursementOptions;
};
const getEbillStatus = ({ ebillEligibleOffOn, ebillEnrollAt }) => {
  return ebillEnrollAt
    ? 'enabled'
    : isOn(ebillEligibleOffOn)
    ? 'eligible'
    : NA_VALUE;
};
const getEnrollmentDate = ({ ebillEligibleOffOn, ebillEnrollAt }) => {
  let enrollmentDate = 'Not Supported';

  if (ebillEligibleOffOn === ON) {
    enrollmentDate = 'Not Enrolled';

    if (ebillEnrollAt) {
      enrollmentDate = moment(ebillEnrollAt).format();
    }
  }

  return enrollmentDate;
};

export const addDerived = (item) => {
  const {
    createdAt,
    nickName,
    payeeStatusAt,
    payer,
    updatedAt,
    uspsAddress = {},
  } = item;
  const address1 = getAddress1(uspsAddress);
  const address2 = getAddress2(uspsAddress);
  const emptyAddress = isNilOrEmpty(address1) && isNilOrEmpty(address2);
  const fullAddress = !emptyAddress ? `${address1} ${address2}` : NA_VALUE;

  return {
    ...item,
    [IS_EMPTY]: false,
    address1,
    address2,
    createdAtLabel: dateAndTime(createdAt),
    disbursementOptions: getDisbursementOptions(item),
    ebillStatus: getEbillStatus(item),
    enrollmentDate: getEnrollmentDate(item),
    fullAddress,
    nickNameLabel: nickName ?? NA_VALUE,
    payeeStatusAtLabel: dateAndTime(payeeStatusAt),
    payerLabelWithId: getLabelWithId(payer),
    updatedAtLabel: dateAndTime(updatedAt),
    deliverElecVc: isVirtualCard(item) ? ON : OFF,
  };
};
