import PasswordExpire from 'components/Authentication/PasswordExpire';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { useEffect } from 'react';
import { PATH as pathname } from 'components/Authentication/Login';
import { Redirect, Route } from 'react-router-dom';
import { client } from 'util/sdk';
import { get, getSDKTokenHeader } from 'util/token';
import { getAuthNotPersisted } from 'util/auth';
import { useAuth } from 'hooks';
import { useSelector } from 'react-redux';
import UserAfterRefresh from './UserAfterRefresh';

/**
 * All "root" <Route />'s use this component; /login, /logout, /password, /uv and /
 *
 * @prop requiresAuth
 *   if true, a valid auth token is required to traverse this route otherwise you'll
 *   be directed to /login
 */
const RootRoute = ({ Component, requiresAuth, ...props }) => {
  const { hasRan, isAuthenticated, redirectLocation } = useAuth();
  const { passwordExpired } = useSelector(getAuthNotPersisted);
  const authToken = get();
  const authenticated = hasRan && isAuthenticated;

  const render = ({ location, ...props }) => {
    if (passwordExpired) {
      return <PasswordExpire />;
    }
    if (authenticated || !requiresAuth) {
      return <Component location={location} {...props} />;
    }
    if (authToken && isNil(redirectLocation)) {
      return <UserAfterRefresh />;
    }

    const state = { redirectLocation: redirectLocation || location };

    return <Redirect to={{ pathname, state }} />;
  };

  useEffect(() => {
    if (authenticated) {
      client.setExtraRequestHttpOptions(getSDKTokenHeader());
    }
  }, [authenticated]);

  return <Route {...props} render={render} />;
};

RootRoute.propTypes = {
  Component: PropTypes.func.isRequired,
  requiresAuth: PropTypes.bool.isRequired,
};

export default RootRoute;
