import 'moment-timezone/moment-timezone';
import moment from 'moment';

// See https://github.com/kalmecak/moment-business-days/blob/master/index.js

const defaultWorkingWeekdays = [1, 2, 3, 4, 5]; // Monday - Friday

moment.fn.isBusinessDay = function isBusinessDay() {
  return defaultWorkingWeekdays.includes(this.day());
};

moment.fn.businessDiff = function businessDiff(param, relative = true) {
  const d1 = this.clone();
  const d2 = param.clone();
  const positive = d1 >= d2;
  const start = d1 < d2 ? d1 : d2;
  const end = d2 > d1 ? d2 : d1;

  let daysBetween = 0;

  if (start.isSame(end, 'days')) {
    return daysBetween;
  }

  while (start < end) {
    if (start.isBusinessDay()) {
      daysBetween += 1;
    }
    start.add(1, 'd');
  }

  if (relative && daysBetween !== 0) {
    return positive ? daysBetween : -daysBetween;
  }

  return daysBetween;
};

moment.fn.addBusinessDays = function addBusinessDays(days) {
  if (days < 0) {
    throw new Error('days should be a positive number');
  }

  let daysLefts = days;

  while (daysLefts > 0) {
    this.add(1, 'days');
    if (this.isBusinessDay()) {
      daysLefts -= 1;
    }
  }

  return this;
};
