export const placeholderChar = '\u2000';

export const phoneMask = [
  '(',
  /[1-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[1-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const phoneMaskBusiness = [
  '(',
  /[1-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[1-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ' ',
  /[x]/,
  /[1-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const phonePlaceholderChar = '\u000A';

export const unmask = (mask) => mask.replace(/\D+/g, '');
