import Close from '@material-ui/icons/Close';
import { DialogTitle, Grid, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteMessage } from 'reducers/errorMessages';

const UpdateRequiredTitle = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(deleteMessage());
  };

  return (
    <DialogTitle>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid item>Action Required</Grid>
        <Grid item>
          <IconButton onClick={handleClick} size="small">
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default UpdateRequiredTitle;
