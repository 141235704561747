import { BASE_PATH } from 'components/modules/service';
import { isLoadingState } from 'reducers/loading';
import { isNil } from 'ramda';
import { isOn } from 'util/index';
import { load, LOAD } from 'model/org';
import { selector } from 'selectors/org';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PAYRAILZ_ADMIN_ORG_ID } from '../../../sdk';

const loadSelector = (state) => isLoadingState(state, LOAD.ACTION);
const baseParam = { limit: 1 };
const params = (id) => {
  return id === PAYRAILZ_ADMIN_ORG_ID
    ? {
        ...baseParam,
        attributes: ['id', 'name'], // only support by /adminorgs not /adminusers/{id}/organizations
        id,
      }
    : baseParam;
}; // Make the IP check request return as little data as possible
const navigate = ({ history, location }) => {
  let to = { pathname: BASE_PATH };
  const redirectLocation = location?.state?.redirectLocation;

  if (redirectLocation && redirectLocation?.pathname !== '/') {
    const { state, ...rest } = redirectLocation;

    to = rest;
  }

  history.push(to);
};

/**
 * Handles navigation upon login success including
 * the request (if needed) for IP address checking
 *
 * @param authenticated
 * @param adminOrg
 * @returns {number|(function(*=): (number))|*}
 */
const useNavigateAfterLogin = (authenticated, adminOrg) => {
  const orgId = adminOrg?.id ?? 0;
  const checkIPAddress = isOn(adminOrg?.ipWhitelistOffOn);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const ipCheckOrg = useSelector((_) => selector(_, orgId), shallowEqual);
  const isLoading = useSelector(loadSelector);

  const ipTestSucceeded = Boolean(checkIPAddress && orgId && ipCheckOrg?.id);
  const normalTestSucceeded = Boolean(!checkIPAddress && orgId);
  const mustLoadIP = Boolean(checkIPAddress && orgId && isNil(ipCheckOrg?.id));

  useEffect(() => {
    if (mustLoadIP) {
      // We need to make a request from the browser so that
      // the API can check users IP address. We happened to
      // choose this call as the victim, err... candidate.
      // If this call succeeds, proceed with moving the user
      // to the next screen. If not, display an error to the user
      dispatch(load(params(orgId)));
    }
  }, [dispatch, mustLoadIP, orgId]);

  useEffect(() => {
    if (authenticated && (ipTestSucceeded || normalTestSucceeded)) {
      navigate({ history, location });
    }
  }, [authenticated, history, ipTestSucceeded, location, normalTestSucceeded]);

  return isLoading;
};

export default useNavigateAfterLogin;
