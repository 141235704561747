import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import {
  JOB_STATUSES_ACTIVE,
  JOB_STATUSES_COMPLETE,
  addDerived,
} from './util/automatchJob';

export default class AutomatchJob extends Model {
  static get modelName() {
    return 'AutomatchJob';
  }

  static reducer({ type, data = [] }, AutomatchJob) {
    switch (type) {
      case CHECK.ACTION: {
        const job = AutomatchJob.withId(data.id);

        job.selected = !job.selected;

        AutomatchJob.update(job);
        break;
      }

      case CHECK_ALL_ACTIVE.ACTION:
        AutomatchJob.filter(({ status }) =>
          JOB_STATUSES_ACTIVE.includes(status)
        ).update({
          selected: data.selected,
        });
        break;

      case CHECK_ALL_COMPLETE.ACTION:
        AutomatchJob.filter(({ status }) =>
          JOB_STATUSES_COMPLETE.includes(status)
        ).update({
          selected: data.selected,
        });

        break;

      case CREATE.SUCCESS:
      case CREATE_SNAPSHOT.SUCCESS:
        AutomatchJob.create(addDerived(data.response));
        break;

      case LOAD_ACTIVE.SUCCESS:
      case LOAD_COMPLETE.SUCCESS: {
        // preserve selected ones
        const selected = AutomatchJob.filter(
          ({ selected }) => selected
        ).toRefArray();

        if (type === LOAD_ACTIVE.SUCCESS) {
          AutomatchJob.filter(({ status }) =>
            JOB_STATUSES_ACTIVE.includes(status)
          ).delete();
        } else {
          AutomatchJob.filter(({ status }) =>
            JOB_STATUSES_COMPLETE.includes(status)
          ).delete();
        }
        data.response.forEach((r) => {
          AutomatchJob.upsert(
            addDerived({
              ...r,
              selected: selected.some(({ id }) => id === r?.id),
            })
          );
        });
        break;
      }

      case LOAD_ACTIVE.FAILURE:
      case LOAD_COMPLETE.FAILURE: {
        if (type === LOAD_ACTIVE.SUCCESS) {
          AutomatchJob.filter(({ status }) =>
            JOB_STATUSES_ACTIVE.includes(status)
          ).delete();
        } else {
          AutomatchJob.filter(({ status }) =>
            JOB_STATUSES_COMPLETE.includes(status)
          ).delete();
        }
        break;
      }

      case REMOVE.SUCCESS:
        AutomatchJob.filter(({ selected }) => selected).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      cancelAt: attr(),
      cancelAtLabel: attr(), // derived
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      doneAt: attr(),
      finishAtLabel: attr(), // derived
      id: attr(),
      name: attr(),
      startAt: attr(),
      selected: attr(), // derived
      startAtLabel: attr(), // derived
      status: attr(),
      templateDescription: attr(),
      templateName: attr(),
      type: attr(),
      typeLabel: attr(), // derived
      updatedAt: attr(),
      waitAt: attr(),
    };
  }
}

export const CANCEL = createUpdateConst(AutomatchJob.modelName);
export const CHECK = createRequestConst(AutomatchJob.modelName);
export const CHECK_ALL_ACTIVE = createRequestConst(
  `${AutomatchJob.modelName}_check_all_active`
);
export const CHECK_ALL_COMPLETE = createRequestConst(
  `${AutomatchJob.modelName}_check_all_complete`
);
export const CREATE = createAddConst(AutomatchJob.modelName);
export const CREATE_SNAPSHOT = createAddConst(
  `${AutomatchJob.modelName}_snapshot`
);
export const LAST_SNAPSHOT = createLoadConst(
  `${AutomatchJob.modelName}_lastSnapshot`
);
export const LOAD_ACTIVE = createLoadConst(`${AutomatchJob.modelName}_active`);
export const LOAD_COMPLETE = createLoadConst(
  `${AutomatchJob.modelName}_complete`
);
export const REMOVE = createDeleteConst(AutomatchJob.modelName);

export const cancel = createAction(CANCEL);
export const check = createAction(CHECK);
export const checkAllActive = createAction(CHECK_ALL_ACTIVE);
export const checkAllComplete = createAction(CHECK_ALL_COMPLETE);
export const create = createAction(CREATE);
export const createSnapshot = createAction(CREATE_SNAPSHOT);
export const lastSnapshot = createAction(LAST_SNAPSHOT);
export const loadActive = createAction(LOAD_ACTIVE);
export const loadComplete = createAction(LOAD_COMPLETE);
export const remove = createAction(REMOVE);
