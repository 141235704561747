import { client, formatQueryParams } from 'util/sdk';

export const generate = ({ data, fspId, payerId, userId }, auth) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .otpGenerate(data, { adminuserid: auth.id, userid: userId });

export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).otps.query(formatQueryParams(params));
