import {
  findIndex,
  head,
  identity,
  propEq,
  reject,
  update as rUpdate,
  useWith,
} from 'ramda';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from 'util/actions';

const APPLIED_BILLER_FILTERS = 'appliedBillerFilters';

// eslint-disable-next-line react-hooks/rules-of-hooks
const matchId = useWith(findIndex, [propEq('id'), identity]);

export const CREATE = createAddConst(APPLIED_BILLER_FILTERS);
export const REMOVE = createDeleteConst(APPLIED_BILLER_FILTERS);
export const REMOVE_ALL = createRequestConst(APPLIED_BILLER_FILTERS);
export const UPDATE = createUpdateConst(APPLIED_BILLER_FILTERS);

export const create = createAction(CREATE);
export const remove = createAction(REMOVE);
export const removeAll = createAction(REMOVE_ALL);
export const update = createAction(UPDATE);

export const selector = (state) => state[APPLIED_BILLER_FILTERS];

export const enabledFiltersSelector = (state) =>
  state[APPLIED_BILLER_FILTERS].filter(({ disabled }) => !disabled);

export const selectorId = (state, { name }) =>
  head(state[APPLIED_BILLER_FILTERS].filter((filter) => name === filter.name));

export default (state = [], { data = {}, type }) => {
  switch (type) {
    case CREATE.ACTION:
      return [...state, data];

    case REMOVE.ACTION:
      return reject(({ id }) => id === data.id, state);

    case REMOVE_ALL.ACTION:
      return [];

    case UPDATE.ACTION:
      return rUpdate(matchId(data.id, state), data, state);

    default:
      return state;
  }
};
