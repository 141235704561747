import PropTypes from 'prop-types';
import Rule from './Rule';

const NumberRule = ({ password, ...props }) => {
  return <Rule {...props} enabled={/[0-9]/.test(password)} text="number 0-9" />;
};

NumberRule.propTypes = {
  password: PropTypes.string.isRequired,
};

export default NumberRule;
