import createBrowserClient from 'payrailz-ontrac-client-sdk';
import { compose, map, toPairs } from 'ramda';
import { formatUserControlledOutput } from 'components/common/searchByUtils';

export const client = createBrowserClient({ authHost: '/auth' });

export const formatQueryParams = (params) => (op) => {
  return compose(
    map(([name, value]) => {
      if (!op[name]) {
        throw new Error(`querying invalid parameter ${name}`);
      }

      return op[name](formatUserControlledOutput(value));
    }),
    toPairs
  )(params);
};
