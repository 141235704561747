import { Collapse, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
  },
  text: {
    textAlign: 'center',
  },
}));

const PayersWelcomeMessage = (props) => {
  const classes = useStyles();

  return (
    <Collapse {...props} className={classes.root}>
      <Grid alignItems="center" container direction="column">
        <Grid item xs>
          <Typography gutterBottom variant="h6">
            Welcome to Ontrac
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.text} gutterBottom variant="subtitle1">
            Your portal to servicing your Payers and their transactions. To
            begin, enter a search criteria for a Payer from the options above.
          </Typography>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default PayersWelcomeMessage;
