import { Button, makeStyles } from '@material-ui/core';
import { withUpdatePaymentStatus } from 'components/common/permissions';
import { handleStopPropagation } from 'consts';
import { loadUpdatePayment } from 'model/transactionHistory';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { derivedSelectorId } from 'selectors/transactionHistory';
import { isOn } from 'util/index';
import { isNOCOrNOA } from './consts';
import UpdatePaymentDialog from './UpdatePaymentDialog';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const UpdatePayment = ({ id }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => derivedSelectorId(state, { id }));
  const converted = isOn(transaction?.conversionDataOffOn);
  const show = !converted && isNOCOrNOA(transaction);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleOpen = (event) => {
    handleStopPropagation(event);
    setOpen(true);
    dispatch(loadUpdatePayment({ id: transaction.id }));
  };

  return show ? (
    <>
      <Button
        className={classes.button}
        onClick={handleOpen}
        role="button"
        variant="outlined"
      >
        Update Payment
      </Button>
      {open && (
        <UpdatePaymentDialog
          id={id}
          onClick={handleStopPropagation}
          onClose={handleClose}
          open
        />
      )}
    </>
  ) : null;
};

UpdatePayment.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withUpdatePaymentStatus(UpdatePayment);
