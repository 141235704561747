import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { StatusGridNoContainer } from 'components/common/StatusGrid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import StatusSelector from './StatusSelector';

const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
  },
  grid: {
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({ recipient }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Nickname" value={recipient.nickName} />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl label="Ontrac Contact ID" value={recipient.id} />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Delivery Method"
            value={recipient.deliveryMethodDerived}
          />
        </Grid>
        <Grid item xs={2}>
          <ReadOnlyFormControl
            label="Delivery Type"
            value={recipient.disburseTypeDerived}
          />
        </Grid>
        <Grid container>
          <StatusGridNoContainer
            sizes={[4, 3, 3]}
            status={recipient.status}
            statusNode={<StatusSelector recipient={recipient} />}
            statusText={recipient.recipientStatusText}
            statusUpdatedAt={recipient.recipientStatusAtDerived}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Mobile" value={recipient.mobileDisplay} />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl label="Email" value={recipient.email} />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Notification Method"
            value={recipient.notificationMethodDerived}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  recipient: PropTypes.object.isRequired,
};

export default DetailsRender;
