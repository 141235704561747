import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createUpdateConst,
  createRequestConst,
  dateAndTime,
} from '../util';
import { getLogoSrc } from '../consts';

export const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);
  r.base64 = getLogoSrc(r.base64);

  return r;
};

export default class BillerLogo extends Model {
  static get modelName() {
    return 'BillerLogo';
  }

  static reducer({ type, data = [] }, BillerLogo) {
    switch (type) {
      case CREATE.SUCCESS:
        BillerLogo.create(addDerived(data.response));
        break;

      case LOAD.SUCCESS:
        BillerLogo.delete();
        data.response.forEach((r) => BillerLogo.upsert(addDerived(r)));
        break;

      case LOAD.FAILURE:
        BillerLogo.delete();
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        BillerLogo.upsert(addDerived(data?.response));
        break;

      case REMOVE.SUCCESS:
        BillerLogo.filter(({ id }) => id === data?.response?.id).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      base64: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      href: attr(),
      id: attr(),
      name: attr(),
      selected: attr(), // derived
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
    };
  }
}

export const CREATE = createAddConst(BillerLogo.modelName);
export const LOAD = createLoadConst(BillerLogo.modelName);
export const LOAD_ID = createLoadConst(`${BillerLogo.modelName}_id`);
export const REMOVE = createDeleteConst(BillerLogo.modelName);
export const UPDATE = createUpdateConst(BillerLogo.modelName);
export const UPSERT = createRequestConst(BillerLogo.modelName);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
export const upsert = createAction(UPSERT);
