import { currencyNoSymbolNoNA } from 'util/format';
import { dateAndTime } from 'util/index';
import { evolve } from 'ramda';
import {
  OTP_HIGH_RISK_BILL_PAY_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_TO_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT,
  OTP_HIGH_RISK_P2P_AMOUNT,
} from 'consts';

const twoDecimals = (r) => r?.toFixed(2) ?? r;

const transform = {
  bpExpediteFeeAmount: currencyNoSymbolNoNA(),
  p2pRealtimeFeeAmount: currencyNoSymbolNoNA(),
  [OTP_HIGH_RISK_BILL_PAY_AMOUNT]: twoDecimals,
  [OTP_HIGH_RISK_P2P_AMOUNT]: twoDecimals,
  [OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT]: twoDecimals,
  [OTP_HIGH_RISK_TRANSFER_TO_AMOUNT]: twoDecimals,
};

// eslint-disable-next-line import/prefer-default-export
export const addDerived = (r) => {
  r.fspId = r.zfspId;
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);

  return evolve(transform, r);
};
