import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class ScheduledTransactionAudit extends Model {
  static get modelName() {
    return 'ScheduledTransactionAudit';
  }

  static reducer({ type, data }, ScheduledTransactionAudit) {
    switch (type) {
      case LOAD.SUCCESS:
        ScheduledTransactionAudit.delete();
        if (data.response) {
          data.response.forEach((r) => ScheduledTransactionAudit.upsert(r));
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      byAdminUserId: attr(),
      bySystemUser: attr(),
      byUserId: attr(),
      changelog: attr(),
      id: attr(),
      logAt: attr(),
      logOp: attr(),
      schedId: attr(),
      time: attr(), // derived
      title: attr(), // derived
    };
  }
}

export const LOAD = createLoadConst(ScheduledTransactionAudit.modelName);
export const load = createAction(LOAD);
