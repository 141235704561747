import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { dateAndTime, getPhoneForDisplay } from '../util';

const STATUS_MAP = {
  deleted: 'Deleted',
  expired: 'Expired',
  failed: 'Failed',
  inProgress: 'In Progress',
  renderedError: 'Error Rendering',
  responded: 'Responded',
  responsePending: 'Response Pending',
  retryPending: 'Pending Retry',
  sendPending: 'Pending Send',
  sent: 'Sent',
  unAuthorized: 'Not Authorized',
};

const selectorModel = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).notifications.all().toModelArray()
      : []
);

export default createReselectSelector(selectorModel, (items) =>
  items.map((item) => {
    const {
      createdAt,
      deliverMethod,
      eventName,
      msgStatus,
      renderedSubject,
      toEmail,
      toSms,
    } = item;

    item.set('createdAtDerived', dateAndTime(createdAt));
    item.set('msgStatusDerived', STATUS_MAP[msgStatus] || msgStatus);
    item.set(
      'deliverMethodDerived',
      deliverMethod === 'email' ? 'Email' : 'SMS'
    );
    item.set(
      'deliverToDerived',
      deliverMethod === 'email' ? toEmail : getPhoneForDisplay(toSms)
    );
    item.set('descriptionDerived', renderedSubject || eventName);

    return item.ref;
  })
);
