import moment from 'moment';
import { last } from 'ramda';
import { date, dateAndTime, isSameDay, time } from './dateAndTime';

const DATE_COLUMN_SUFFIX = '_at';
const KEY = 'logAt';

const getTitle = ({ logOp }) => {
  let title = 'Error';

  switch (logOp) {
    case 'del':
      title = 'Removed';
      break;

    case 'ins':
      title = 'Created';
      break;

    case 'upd':
      title = 'Updated';
      break;

    default:
      break;
  }

  return title;
};

// eslint-disable-next-line import/prefer-default-export
export const convertToDays = (days, item = {}, index) => {
  item.set('time', time(item[KEY]));
  item.set('title', getTitle(item));

  if (item.changelog) {
    item.changelog.forEach((entry) => {
      entry.newValueDerived = entry.newValue;
      entry.oldValueDerived = entry.oldValue;

      if (entry.columnName.endsWith(DATE_COLUMN_SUFFIX)) {
        if (moment(entry.newValue).isValid()) {
          entry.newValueDerived = dateAndTime(entry.newValue);
        }
        if (moment(entry.oldValue).isValid()) {
          entry.oldValueDerived = dateAndTime(entry.oldValue);
        }
      }
    });
  }

  if (index === 0 || !days.some((day) => isSameDay(day.value, item[KEY]))) {
    days.push({
      id: index,
      items: item && item.ref ? [item.ref] : [],
      title: date(item[KEY]),
      value: item[KEY],
    });
  } else {
    last(days).items.push(item && item.ref ? item.ref : {});
  }

  return days;
};
