import { DISTRIBUTOR_ID, DISTRIBUTOR_NAME_CODE } from 'consts';

export const filters = [
  {
    key: 0,
    highlight: false,
    label: 'DISTRIBUTOR ID',
    value: DISTRIBUTOR_ID,
    placeholder: 'Type in the full ID',
  },
  {
    key: 1,
    highlight: false,
    label: 'DISTRIBUTOR NAME',
    value: DISTRIBUTOR_NAME_CODE,
    placeholder: 'Type in the name',
  },
];
export const defaultFilter = filters[0];

export const getOptionLabel = (distributor) => {
  const id = distributor && distributor?.id ? `(${distributor.id})` : '';

  return distributor ? `${distributor.name ?? ''} ${id}` : '';
};
