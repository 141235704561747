import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ParamChip from './ParamChip';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
}));

const ParamsChips = ({ params, ...props }) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {params.map((param) => (
        <span key={param.field}>
          <ParamChip param={param} {...props} />
        </span>
      ))}
    </span>
  );
};

ParamsChips.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
};

ParamsChips.defaultProps = {
  params: [],
};

export default ParamsChips;
