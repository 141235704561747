export const CURRENCY = /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
export const NUMERIC = /[0-9]/;

export const currency = (value = '') => CURRENCY.test(value);

export const isEmptyObject = (obj = {}) => Object.keys(obj).length === 0;

export const numeric = (value = '') => NUMERIC.test(value);

export const handleKeyPressAllowNumericOnly = (event) => {
  if (!numeric(event.key)) {
    event.preventDefault();
  }
};
