import PropTypes from 'prop-types';
import { memo } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  progress: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const ButtonWithCircularProgress = ({
  busy,
  children,
  CircularProgressProps,
  hasData,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button {...props}>
      {busy && hasData && (
        <CircularProgress
          className={classes.progress}
          {...CircularProgressProps}
        />
      )}
      {children}
    </Button>
  );
};

ButtonWithCircularProgress.propTypes = {
  busy: PropTypes.bool,
  children: PropTypes.node.isRequired,
  CircularProgressProps: PropTypes.object,
  hasData: PropTypes.bool,
};

ButtonWithCircularProgress.defaultProps = {
  busy: false,
  CircularProgressProps: {
    color: 'inherit',
    disableShrink: true,
    size: 26,
    thickness: 4,
  },
  hasData: true,
};

export default memo(ButtonWithCircularProgress);
