import PropTypes from 'prop-types';
import { StatusSelectionAction } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { update } from 'model/payerUser';
import { selectorId } from 'selectors/payer';
import { statusConfig, statusList } from './consts';

const StatusSelector = ({
  currentStatus,
  fspId,
  id,
  name,
  payerId,
  ...props
}) => {
  const payer = useSelector((state) => selectorId(state, payerId));
  const dispatch = useDispatch();

  const handleOnChange = (status) => {
    dispatch(update({ fspId, id, status }));
  };

  return (
    <StatusSelectionAction
      confirmMessageExtras={{ name, payerLabel: payer?.label }}
      currentStatus={currentStatus}
      onChange={handleOnChange}
      statusConfig={statusConfig}
      statusList={statusList}
      {...props}
    />
  );
};

StatusSelector.propTypes = {
  currentStatus: PropTypes.string,
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  name: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

StatusSelector.defaultProps = {
  currentStatus: '',
  name: null,
};

export default StatusSelector;
