/* eslint-disable react/display-name */
export const Activate = (extras) => () => {
  return (
    <span>
      Any transactions previously scheduled for processing during the suspension
      period have been marked as
      <strong>not processed due to suspension</strong>.
      <br />
      Any remaining scheduled transactions will continue to process during the
      regular processing window.
      <br />
      All payees, recipients, and external accounts remain.
      <br />
      Activate {extras}
    </span>
  );
};

export const ActivateDisabled = (extras) => () => {
  return (
    <span>
      Activating will allow the payer to access and transact against the
      account.
      <br />
      Activate {extras}
    </span>
  );
};

export const Close = (extras) => () => {
  return (
    <span>
      Closing this account will result in the account not being displayed to the
      Payer and removing access to transact against this account.
      <br />
      Any and all existing scheduled transactions against this account will be
      cancelled.
      <br />
      Notifications will be sent to the Payer.
      <br />
      Close {extras}
    </span>
  );
};

export const Disable = (extras) => () => {
  return (
    <span>
      Disabling this account will result in the account not being displayed to
      the Payer and removing access to transact against this account.
      <br />
      Any and all existing scheduled transactions against this account will be
      cancelled.
      <br />
      Notifications will be sent to the Payer.
      <br />
      Disable {extras}
    </span>
  );
};

export const Suspend = (extras) => () => {
  return (
    <span>
      Any transactions scheduled for processing during the suspension period
      will be marked as <strong>not processed due to suspension</strong>.
      <br />
      Any scheduled transactions that fall outside the suspension period will be
      processed after the account is re-activated.
      <br />
      All payees, recipients, and external accounts will remain.
      <br />
      Suspend {extras}
    </span>
  );
};
