import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useReducer } from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import { head } from 'ramda';
import { login as loginAction } from 'reducers/authToken';
import { LOGIN_FORM_NAME, PASSWORD, USERNAME } from 'consts';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

const ACTIVE_POSITION = 'activePosition';
const CLEAR = 'clear';
const LOGIN_ACTION_EXECUTING = 'verifyOTPActionExecuting';
const PASSCODE = 'passcode';
const PASTE_PASSCODE = 'pastePasscode';
const REMOVE_DIGIT = 'removeDigit';
const Context = createContext(undefined);
const formOptions = {
  form: LOGIN_FORM_NAME,
  destroyOnUnmount: false,
};
const passcodeLength = 6;
const initialPasscode = Array.from({ length: passcodeLength }, () => '');
const initialValues = {
  activePosition: 0,
  buttonDisabled: true,
  digitCount: 0,
  inputDisabled: false,
  passcode: initialPasscode,
  pasteEvent: false,
};

// Actions
const clear = () => ({
  type: CLEAR,
});
const loginActionExecuting = () => ({
  type: LOGIN_ACTION_EXECUTING,
});
const pastePasscode = (event) => ({
  type: PASTE_PASSCODE,
  value: event.clipboardData?.getData('Text') ?? '',
});
const removeDigit = (position) => ({
  type: REMOVE_DIGIT,
  position,
});
const updateDigit = (event, position) => ({
  type: PASSCODE,
  position,
  value: event.target.value.slice(-1),
});
const updateActivePosition = (position) => ({
  type: ACTIVE_POSITION,
  position,
});

// Helpers
const buttonDisabled = (passcode = '') => {
  return passcode.join('').length !== passcodeLength;
};
const getDigitCount = (state) => state.passcode.join('').length;
const getUpdatedPasscode = (action, state) => {
  const { position, value } = action;
  const updatedPasscode = [...state.passcode];

  updatedPasscode.splice(position, 1, value);

  return updatedPasscode;
};
const getFirstSixNumbers = (value) => {
  const numbersOnly = head(value.trim().match(/\d+/g) ?? []) ?? '';
  const digitCount = numbersOnly.length;
  let correctedValue = numbersOnly;

  if (digitCount > 6) {
    correctedValue = numbersOnly.substring(0, 6);
  } else if (digitCount < 6) {
    correctedValue = numbersOnly.padEnd(6, '');
  }

  return correctedValue;
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIVE_POSITION: {
      const digitCount = getDigitCount(state);

      const activePosition =
        action.position <= digitCount ? action.position : state.activePosition;

      return {
        ...state,
        activePosition,
      };
    }

    case CLEAR:
      return { ...initialValues, previousPasscode: state.passcode.join('') };

    case LOGIN_ACTION_EXECUTING:
      return {
        ...state,
        buttonDisabled: true,
      };

    case PASSCODE: {
      const { position } = action;
      const digitCount = getDigitCount(state);
      const updatedPasscode = getUpdatedPasscode(action, state);

      return state.pasteEvent
        ? { ...state, pasteEvent: false }
        : {
            ...state,
            activePosition: position + 1,
            digitCount,
            buttonDisabled: buttonDisabled(updatedPasscode),
            passcode: updatedPasscode,
            pasteEvent: false,
          };
    }

    case PASTE_PASSCODE: {
      const value = getFirstSixNumbers(action.value);
      const digitCount = value.length || 1;
      const passcode = Array.from({ length: passcodeLength }, (_, index) => {
        return value.length > index - 1 ? value[index] ?? '' : '';
      });

      return {
        ...state,
        activePosition: digitCount - 1,
        digitCount,
        buttonDisabled: buttonDisabled(passcode),
        passcode,
        pasteEvent: true,
      };
    }

    case REMOVE_DIGIT: {
      const { position } = action;
      const digitCount = getDigitCount(state);
      const updatedPasscode = getUpdatedPasscode(
        { position, value: '' },
        state
      );

      return {
        ...state,
        activePosition: position > 0 ? position - 1 : position,
        digitCount,
        buttonDisabled: buttonDisabled(updatedPasscode),
        passcode: updatedPasscode,
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const OneTimePasscodeProvider = reduxForm(formOptions)(({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValues, undefined);
  const storeDispatch = useDispatch();
  const loginForm = useSelector(
    (state) => formValueSelector(formOptions.form)(state, USERNAME, PASSWORD),
    shallowEqual
  );
  const value = useMemo(() => {
    const login = (sendOTP = false) => {
      const args = sendOTP
        ? { ...loginForm, sendOTP: true }
        : { ...loginForm, otp: state.passcode.join('') };

      dispatch(loginActionExecuting());
      storeDispatch(loginAction(args));
    };
    const sendCode = () => {
      login(true);
    };
    const { username } = loginForm;

    return { dispatch, username, login, sendCode, state };
  }, [dispatch, loginForm, state, storeDispatch]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
});

OneTimePasscodeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useOneTimePasscode = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useOneTimePasscode must be used within a OneTimePasscodeProvider'
    );
  }

  return context;
};

export {
  clear,
  OneTimePasscodeProvider,
  passcodeLength,
  pastePasscode,
  removeDigit,
  updateActivePosition,
  updateDigit,
  useOneTimePasscode,
};
