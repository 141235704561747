import Date from 'components/common/fields/Date';
import InputRefType from 'types/inputRef';
import PropTypes from 'prop-types';
import { always, update } from 'ramda';

const ParamValueDate = ({
  disabled,
  inputRef,
  onChange,
  onKeyPress,
  value,
}) => {
  const createOnChangeHandler = (valueIndex) => (date) => {
    if (!date) {
      const newValue = update(valueIndex, undefined, value);

      onChange({ target: { value: newValue } });
    } else if (date.isValid()) {
      const newValue = update(valueIndex, date.format('YYYY-MM-DD'), value);

      onChange({ target: { value: newValue } });
    }
  };

  const handleFromOnChange = createOnChangeHandler(0);
  const handleToOnChange = createOnChangeHandler(1);

  return (
    <>
      <Date
        disabled={disabled}
        inputRef={inputRef}
        label="Value / From"
        onChange={handleFromOnChange}
        onKeyPress={onKeyPress}
        value={value[0]}
      />
      <Date
        disabled={disabled}
        label="To"
        onChange={handleToOnChange}
        onKeyPress={onKeyPress}
        value={value[1]}
      />
    </>
  );
};

ParamValueDate.propTypes = {
  disabled: PropTypes.bool,
  inputRef: InputRefType,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

ParamValueDate.defaultProps = {
  disabled: false,
  inputRef: undefined,
  onKeyPress: always,
  value: ['', ''],
};

export default ParamValueDate;
