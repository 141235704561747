import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import usePayALoanTransaction from '../../../../History/config/DetailsRender/usePayALoanTransaction';

const TotalAmount = ({ item }) => {
  return (
    usePayALoanTransaction(item.transactionType) && (
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Total Amount"
          value={item?.totalAmountDerived}
        />
      </Grid>
    )
  );
};
TotalAmount.propTypes = {
  item: PropTypes.shape({
    totalAmountDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};
export default TotalAmount;
