import { attr, Model } from 'redux-orm';
import { getLogoSrc } from '../consts';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);
  r.billerLogo = getLogoSrc(r.billerLogo);

  return r;
};

export default class BillerSet extends Model {
  static get modelName() {
    return 'BillerSet';
  }

  static reducer({ type, data = [] }, BillerSet) {
    switch (type) {
      case CHECK.ACTION: {
        BillerSet.filter(({ selected }) => selected)
          .toModelArray()
          .forEach((set) => {
            set.selected = !set.selected;
            set.update(set);
          });

        const set = BillerSet.withId(data.id);
        if (set) {
          set.selected = !set.selected;

          set.update(set);
        }
        break;
      }

      case CREATE.SUCCESS:
        BillerSet.create(addDerived(data.response));
        break;

      case LOAD.SUCCESS:
        BillerSet.delete();
        data.response.forEach((r) => {
          BillerSet.create(addDerived(r));
        });
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        BillerSet.upsert(addDerived(data?.response));

        break;

      case LOAD.FAILURE:
        BillerSet.delete();
        break;

      case REMOVE.SUCCESS:
        BillerSet.filter(({ id }) => id === data?.response?.id).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      billerCount: attr(),
      billerLogo: attr(),
      billerLogoId: attr(),
      billerTopId: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      ebillernetEbillerId: attr(),
      explodeOffOn: attr(),
      href: attr(),
      id: attr(),
      insAliasForSetNameOffOn: attr(),
      name: attr(),
      offset: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
    };
  }
}

const name = BillerSet.modelName;

export const CHECK = createRequestConst(`${name}_check`);
export const CREATE = createAddConst(name);
export const LOAD = createLoadConst(name);
export const LOAD_ID = createLoadConst(`${name}_id`);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const check = createAction(CHECK);
export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
