import { takeEvery } from 'redux-saga/effects';
import { create, load, remove, update } from 'sdk/billerAlias';
import { CREATE, LOAD, REMOVE, UPDATE } from 'model/billerAlias';
import { callAction, ORM } from '../util';

const name = 'Biller Alias';
const createMessage = () => `${name} created successfully`;
const removeMessage = () =>
  'One or more Biller Aliases were successfully deleted';
const updateMessage = ({ response }) =>
  `${name} ${response?.alias} updated successfully`;

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      type: CREATE,
      formatSuccessMessage: createMessage,
    })
  );
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );
  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      type: REMOVE,
      formatSuccessMessage: removeMessage,
    })
  );
  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      type: UPDATE,
      formatSuccessMessage: updateMessage,
    })
  );
}
