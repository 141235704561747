import Payer, { LOAD } from 'model/payer';
import { head } from 'ramda';
import { LOAD as LOAD_INTERNAL_ACCOUNT } from 'model/internalAccount';
import { NA_VALUE } from 'consts';
import { oneToOne, Model } from 'redux-orm';

const createPayerSearchObject = (id, PayerSearch) => {
  const payerSearch = PayerSearch.create({ id });
  const payer = payerSearch.id;

  const { city, state } = payer?.uspsAddress ?? {};

  payer.set('city', city || NA_VALUE);
  payer.set('state', state || NA_VALUE);
};
const ddaHasPayerObject = ({ expand, accountNbr }) => {
  return accountNbr && Array.isArray(expand) && head(expand) === 'payer';
};

export default class PayerSearch extends Model {
  static get modelName() {
    return 'PayerSearch';
  }

  static reducer({ type, data = {} }, PayerSearch) {
    switch (type) {
      case LOAD.SUCCESS:
        if (data.props?.keepExistingPayers === undefined) {
          PayerSearch.delete();
        }

        if (data.response) {
          data.response.forEach(({ id }) => {
            createPayerSearchObject(id, PayerSearch);
          });
        }
        break;

      case LOAD_INTERNAL_ACCOUNT.SUCCESS:
        if (ddaHasPayerObject(data.props)) {
          PayerSearch.delete();

          if (data.response) {
            data.response.forEach(({ payer }) => {
              createPayerSearchObject(payer.id, PayerSearch);
            });
          }
        }
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      id: oneToOne(Payer.modelName),
    };
  }
}
