import { client, formatQueryParams } from 'util/sdk';
import { omit } from 'ramda';
import { PAYRAILZ_ADMIN_ORG_ID } from './index';

export const addUser = (data, auth) => {
  // Ontrac defines userLogin === email
  data.email = data.userLogin;
  return client.admin.users.create(data, { adminUserId: auth.id });
};

export const deleteUser = ({ id }, auth) =>
  client.admin.users(id).delete({ adminUserId: auth.id });

export const loadUsers = (params, auth) => {
  if (auth.adminOrg.id === PAYRAILZ_ADMIN_ORG_ID) {
    return client.admin.users.query(formatQueryParams(params));
  }
  return client.admin
    .users(auth.id)
    .users.query(formatQueryParams(omit(['id'], params)));
};

export const loadTypeAhead = (params) =>
  client.admin.users.query(formatQueryParams(params));

export const loadByOrgId = (params) =>
  client.admin.users.query(formatQueryParams(params));

export const loadUser = (params) =>
  client.admin.users.query(formatQueryParams(params));

export const updateUser = ({ id, ...data }, auth) =>
  client.admin.users(id).update(data, { adminUserId: auth.id });

export const updateUserStatus = ({ id, status }, auth) =>
  client.admin
    .users(id)
    .updateStatus({ userStatus: status }, { adminuserid: auth.id });
