import ExpandablePanel from 'components/common/ExpandablePanel';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { LOAD } from 'model/oneTimePasscode';
import { VpnKey } from '@material-ui/icons';
import { otpEnabled } from 'selectors/userFspConfig';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import { pageSettingsName } from './config';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from './consts';
import EndAdornment from './EndAdornment';
import OneTimePasscodeList from './OneTimePasscodeList';

const OneTimePasscode = ({ fspId, payerId }) => {
  const isBusy = useSelector((state) => selector(state, LOAD));
  const isEnabled = useSelector((state) => otpEnabled(state, fspId, payerId));

  return isEnabled ? (
    <ExpandablePanel
      endAdornment={<EndAdornment fspId={fspId} payerId={payerId} />}
      heightOffset={HEIGHT_OFFSET}
      icon={VpnKey}
      id={pageSettingsName}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title="One Time Passcodes"
    >
      <OneTimePasscodeList fspId={fspId} payerId={payerId} />
    </ExpandablePanel>
  ) : null;
};

OneTimePasscode.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(OneTimePasscode);
