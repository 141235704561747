import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { compose, pure, setDisplayName, withProps } from 'recompose';

export const DIALOG_URI = '/setup';

const DialogRoutes = ({ Component, Dialog, dialogPath, path }) => {
  return (
    <>
      <Route component={Component} path={path} />
      <Route component={Dialog} exact path={dialogPath} />
    </>
  );
};

DialogRoutes.propTypes = {
  Component: PropTypes.func.isRequired,
  Dialog: PropTypes.func.isRequired,
  dialogPath: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('DialogRoutes'),
  withProps(({ match }) => ({
    dialogPath: `${match.url}${DIALOG_URI}`,
    path: match.url,
  })),
  pure
)(DialogRoutes);
