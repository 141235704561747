import AlertImproved from 'components/common/AlertImproved';
import IconButtonWithTooltip from 'components/common/IconButtonWithTooltip';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@material-ui/icons';
import { deleteStatement, DELETE_STATEMENT } from 'model/eBillAccount';
import { useDispatch } from 'react-redux';
import { useOpenCloseRequestComplete } from 'hooks';
import { withEBillAdmin } from 'components/common/permissions';

const AdminActions = ({ fspId, openEditMode, statementId }) => {
  const [open, toggle] = useOpenCloseRequestComplete(DELETE_STATEMENT);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteStatement({ fspId, statementId }));
  };

  return (
    <>
      <IconButtonWithTooltip
        Icon={Edit}
        onClick={openEditMode}
        tip="Edit statement"
      />
      <IconButtonWithTooltip
        Icon={Delete}
        onClick={toggle}
        tip="Delete statement"
      />
      <AlertImproved
        actionType={DELETE_STATEMENT}
        cancelLabel="No"
        confirmLabel="Yes"
        maxWidth="sm"
        onClose={toggle}
        onConfirm={handleDelete}
        open={open}
        title="Are you sure you want to delete the current Statement?"
      />
    </>
  );
};

AdminActions.propTypes = {
  fspId: PropTypes.number.isRequired,
  openEditMode: PropTypes.func.isRequired,
  statementId: PropTypes.number.isRequired,
};

export default withEBillAdmin(AdminActions);
