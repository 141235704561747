import { createAddConst, createAction } from 'util/actions';

const METRIC = 'metric';

const finish = (saga, startTime) => (status) => {
  const endTime = currentTime();
  const duration = timeDiff(startTime, endTime);

  return addMetric({
    type: SAGA,
    saga,
    status,
    startTime,
    endTime,
    duration,
  });
};

export const ADD = createAddConst(METRIC);
export const addMetric = createAction(ADD);

// Metric types
export const SAGA = 'SAGA';
export const ROUTE_CHANGE = 'ROUTE_CHANGE';

export const currentTime = () => new Date();
export const timeDiff = (start, end) => end.getTime() - start.getTime();

export const startRequestTimer = (saga) => ({
  finish: finish(saga, currentTime()),
});
