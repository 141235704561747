import { withProps } from 'recompose';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { withStyles } from '@material-ui/core';
import IconButton from './icon_button';

const styles = () => ({
  root: {},
  label: { marginLeft: 10 },
  iconComponent: {},
});

export default withProps(() => ({ IconComponent: KeyboardBackspaceIcon }))(
  withStyles(styles)(IconButton)
);
