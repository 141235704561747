import { usePageSettings } from 'hooks';
import { useState, useCallback, useEffect } from 'react';
import { update } from 'model/pageSetting';
import { useDispatch } from 'react-redux';

const onlyOurs = (pageSettings = {}, searchParams = []) => {
  // We only want the searchBy entries that are also passed in as available
  // search parameters
  return pageSettings.searchBys?.filter((searchBy) => {
    return searchParams.some(({ field }) => field === searchBy?.field);
  });
};
const filterOutOurs = (pageSettings = {}, searchParams = []) => {
  // We want to remove only our search params from the list, leave the rest
  return pageSettings.searchBys?.filter((searchBy) => {
    return (
      searchParams.find(({ field }) => field === searchBy?.field) === undefined
    );
  });
};
const getParams = (pageSettings, allowedParameters, param, remove = false) => {
  const params = onlyOurs(pageSettings, allowedParameters);
  const index = params.findIndex(({ field }) => field === param?.field);

  if (remove) {
    if (index > -1) {
      params.splice(index, 1);
    }
  } else if (index === -1) {
    params.push(param);
  } else {
    params.splice(index, 1, param);
  }

  return params;
};

const useSearchBys = (name, allowedParameters) => {
  const dispatch = useDispatch();
  const pageSettings = usePageSettings(name);
  const [searchBysState, setSearchBysState] = useState([]);

  const updateSettings = useCallback(
    (searchBys) => {
      dispatch(update({ searchBys, page: 0, type: name }));
    },
    [dispatch, name]
  );
  const changeOrRemove = useCallback(
    (param, remove = false) => {
      const otherSearchBys = filterOutOurs(pageSettings, allowedParameters);
      // ourSearchBys are the searchBys displayed in the SearchTextField
      const ourSearchBys = getParams(
        pageSettings,
        allowedParameters,
        param,
        remove
      );
      const pageSettingSearchBys = [...otherSearchBys, ...ourSearchBys];

      updateSettings(pageSettingSearchBys);

      return setSearchBysState(ourSearchBys);
    },
    [allowedParameters, pageSettings, updateSettings]
  );
  const change = useCallback(
    (param) => changeOrRemove(param),
    [changeOrRemove]
  );
  const remove = useCallback(
    (param) => changeOrRemove(param, true),
    [changeOrRemove]
  );
  const reset = useCallback(() => {
    const otherSearchBys = filterOutOurs(pageSettings, allowedParameters);

    updateSettings(otherSearchBys);

    return setSearchBysState([]);
  }, [allowedParameters, pageSettings, updateSettings]);

  useEffect(() => {
    const newSearchParams = onlyOurs(pageSettings, allowedParameters);

    setSearchBysState(newSearchParams);
  }, [allowedParameters, pageSettings]);

  return [searchBysState, change, remove, reset];
};

export default useSearchBys;
