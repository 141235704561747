import { compose, pure, setDisplayName, withProps } from 'recompose';
import { TextField } from '@material-ui/core';
import MaskPhone from './MaskPhone';

export const withMaskPhone = compose(
  setDisplayName('TextFieldPhone'),
  withProps(({ InputProps }) => ({
    InputProps: { inputComponent: MaskPhone, ...InputProps },
  })),
  pure
);

export default withMaskPhone(TextField);
