import { omit } from 'ramda';
import {
  load,
  loadById,
  loadByQueryParams,
  updatePaymentNoc,
  updatePaymentNor,
  updateReturnedStatus,
} from 'sdk/transactionHistory';
import { takeEvery, put } from 'redux-saga/effects';
import {
  LOAD,
  LOAD_ID,
  LOAD_QUERY_PARAMS,
  UPDATE_PAYMENT_NOC,
  UPDATE_PAYMENT_NOR,
  UPDATE_RETURN_STATUS,
  loadById as refresh,
} from 'model/transactionHistory';
import { load as loadExternalAccounts } from 'model/externalAccount';
import { OFF } from 'consts';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    LOAD_QUERY_PARAMS.ACTION,
    callAction({
      api: loadByQueryParams,
      dataName: ORM,
      type: LOAD_QUERY_PARAMS,
    })
  );

  yield takeEvery(UPDATE_RETURN_STATUS.ACTION, function* named({ data, form }) {
    yield callAction({
      api: updateReturnedStatus,
      dataName: ORM,
      type: UPDATE_RETURN_STATUS,
      formatSuccessMessage: () => 'Payment status was updated',
    })({ data, form });

    yield put(refresh(data));
  });

  yield takeEvery(UPDATE_PAYMENT_NOR.ACTION, function* named({ data, form }) {
    yield callAction({
      api: updatePaymentNor,
      dataName: ORM,
      type: UPDATE_PAYMENT_NOR,
      formatSuccessMessage: () => 'Update succeeded',
    })({ data, form });

    yield put(refresh(data));
  });

  yield takeEvery(UPDATE_PAYMENT_NOC.ACTION, function* named({ data, form }) {
    const result = yield callAction({
      api: updatePaymentNoc,
      dataName: ORM,
      type: UPDATE_PAYMENT_NOC,
      formatSuccessMessage: () => 'Update succeeded',
    })({ data: omit(['payerId'], data), form });

    if (result) {
      yield put(refresh(data));

      const { fspId, payerId } = data;
      yield put(loadExternalAccounts({ fspId, payerId, deletedOnly: OFF }));
    }
  });
}
