import { client } from 'util/sdk';
import { GET } from 'util/api';
import { PAYNETS_CODE } from 'consts';

const returnModel = { name: PAYNETS_CODE };

const loadURL = () => `/${PAYNETS_CODE}`;

// eslint-disable-next-line import/prefer-default-export
export const load = () => {
  return client.executeRequest(GET, loadURL(), { returnModel });
};
