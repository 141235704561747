import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import {
  getStatusCodeDisplayValue,
  getStatusCodeDisplayDescription,
} from 'model/util/transactionHistory';
import { prop, sortBy } from 'ramda';
import { orm } from 'model';
import moment from 'moment';
import { isCheckPayment } from 'components/modules/service/PayerDetail/Transactions/History/UpdatePaymentStatus/consts';
import { DELIVERED, PROCESSED } from 'consts';
import { dateToMoment, formatSortedDate } from 'util/dateAndTime';
import { date, time } from '../util/index';

const KEY = 'transactionExecutedAt';

const completeAuditRecordMutation = (item, { time }) => {
  let responseCodeDisplay = null;

  item.time = time;
  item.title = getStatusCodeDisplayValue(item.transactionState);
  item.tooltip = getStatusCodeDisplayDescription(item.transactionState);

  if (item.responseCode || item.responseCodeStr) {
    if (item.responseCode && item.responseCodeStr) {
      responseCodeDisplay = `${item.responseCodeStr} (${item.responseCode})`;
    } else {
      responseCodeDisplay = item.responseCodeStr || item.responseCode;
    }
  }
  item.subheader = responseCodeDisplay;
};

const getDatetimeComponents = (item) => {
  if (isCheckPayment(item.deliver)) {
    if (item.transactionState === PROCESSED) {
      return {
        date: formatSortedDate(moment(item.sendOn).addBusinessDays(1)),
        time: '',
      };
    }
    if (item.transactionState === DELIVERED) {
      return {
        date: formatSortedDate(item.deliverOn),
        time: '',
      };
    }
  }

  const transactionDate = dateToMoment(item[KEY], true);
  return {
    date: formatSortedDate(transactionDate),
    time: time(item[KEY], true),
  };
};

const convertToDays = (days, item, index) => {
  const datetime = getDatetimeComponents(item);
  completeAuditRecordMutation(item, datetime);
  const groupKey = datetime.date;

  if (days[groupKey]) {
    days[groupKey].items.push(item);
  } else {
    days[groupKey] = {
      id: index,
      items: [item],
      title: date(groupKey, true),
      value: groupKey,
    };
  }

  return days;
};

export const selectorCount = createSelector(
  orm,
  (state, props) => props,
  ({ TransactionHistoryAudit }, { id }) =>
    TransactionHistoryAudit.idExists(id) &&
    TransactionHistoryAudit.withId(id).audit
      ? TransactionHistoryAudit.withId(id).audit.length
      : 0
);

const selector = createSelector(
  orm,
  (state, props) => props,
  ({ TransactionHistoryAudit }, { id }) =>
    TransactionHistoryAudit.idExists(id) &&
    TransactionHistoryAudit.withId(id).audit
      ? TransactionHistoryAudit.withId(id).audit
      : []
);

export const groupAuditRecords = (items) => {
  const groups = items.reduce(convertToDays, {});
  return sortBy(prop('value'), Object.values(groups));
};
export const selectorGroupedByDays = createReselectSelector(
  selector,
  groupAuditRecords
);
