import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { useOpen } from 'hooks';
import useMenuItems from './useMenuItems';

const toMenuItem = ({ disabled, displayName, field }) => (
  <MenuItem key={field} disabled={disabled} value={field}>
    {displayName}
  </MenuItem>
);

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 200,
  },
}));

const ParamField = ({
  disabled,
  pageSettingsName,
  onChange,
  openComplete,
  searchParams,
  value,
  ...props
}) => {
  const [open, toggleOpen] = useOpen(!disabled);
  const classes = useStyles();
  const menuItems = useMenuItems(pageSettingsName, searchParams);

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel id="ParameterFieldLabel">Parameter</InputLabel>
      <Select
        {...props}
        disabled={disabled}
        labelId="ParameterFieldLabel"
        onChange={onChange}
        onClose={toggleOpen}
        onOpen={toggleOpen}
        open={open && openComplete}
        value={value}
      >
        {menuItems.map(toMenuItem)}
      </Select>
    </FormControl>
  );
};

ParamField.propTypes = {
  onChange: PropTypes.func,
  openComplete: PropTypes.bool.isRequired,
  pageSettingsName: PropTypes.string.isRequired,
  searchParams: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      maxLength: PropTypes.number,
      type: PropTypes.string,
      valueOptions: PropTypes.array,
    })
  ),
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

ParamField.defaultProps = {
  onChange: always,
  searchParams: null,
  value: '',
  disabled: false,
};

export default ParamField;
