import { createRequestConst, createAction } from 'util/actions';

export const VERIFY = createRequestConst('verify');
export const verify = createAction(VERIFY);

const initialState = {
  isAuthenticated: false,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case VERIFY.SUCCESS:
      return {
        isAuthenticated: true,
        ...data,
      };

    case VERIFY.FAILURE:
      return initialState;

    default:
      break;
  }
  return state;
};
