import {
  PAL_INTEREST_DERIVED,
  PAL_PRINCIPAL_DERIVED,
  PAL_ESCROW_DERIVED,
  TRANSACTION_TYPE_PAL,
} from 'consts';

// eslint-disable-next-line import/prefer-default-export
export const palTransactionTypeDerived = (item) => {
  return item.transactionType === PAL_PRINCIPAL_DERIVED
    ? 'PAL Principal'
    : item.transactionType === PAL_ESCROW_DERIVED
    ? 'PAL Escrow'
    : item.transactionType === PAL_INTEREST_DERIVED
    ? 'PAL Interest'
    : item.transactionType === TRANSACTION_TYPE_PAL
    ? 'PAL'
    : item.transactionType;
};
