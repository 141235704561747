import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const DATA_KEY = 'users';

export const selector = createSelector(orm.User);

export const selectorId = createSelector(
  orm,
  (state, id) => id,
  ({ User }, id) =>
    User.idExists(id) ? User.withId(id) : User.upsert({ id: id || 0 })
);

export const selectorFirstByPayerId = createSelector(
  orm,
  (state, payerId) => payerId,
  ({ User }, payerId) =>
    User.filter((user) => user.payerId === payerId).first()?.ref ?? {}
);
