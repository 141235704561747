import Loading from 'components/common/loading';
import PropTypes from 'prop-types';
import { createRef, Fragment } from 'react';
import classNames from 'classnames';
import scrollIntoView from 'scroll-into-view';
import { ExpandLess, FilterList } from '@material-ui/icons';
import { ROWS_PER_PAGE } from 'consts';
import { SCROLL_REF_KEY, SCROLL_DELAY } from 'util/format';
import {
  always,
  defaultTo,
  dropLast,
  filter as ramdaFilter,
  forEach,
  isEmpty,
  isNil,
  mergeRight,
  omit,
  where,
} from 'ramda';
import {
  compose,
  nest,
  withHandlers,
  withProps,
  withPropsOnChange,
  withState,
} from 'recompose';
import {
  Collapse,
  IconButton,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  withStyles,
} from '@material-ui/core';
import TableColumns from './tableColumns';
import EmptyMessageRow from './EmptyMessageRow';

const scroll = (ref) => ref && scrollIntoView(ref.current);

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  rowBody: {
    borderBottom: '1px solid #d3d4d8',
  },
  highlight: {
    backgroundColor: '#fdd835',
  },
  expanded: {
    borderBottom: 'none',
  },
  toolbars: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
  },
  toolbarItem: {
    marginRight: 10,
  },
  sortLabelIcon: {
    width: 16,
    height: 16,
    margin: '0px 2px',
  },
  filter: {
    height: 30,
  },
  footer: {
    borderBottom: 0,
  },
  details: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  collapsedRow: {
    height: 0,
  },
  collapsedCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  expandedCell: {
    paddingTop: 4,
    paddingBottom: 2,
  },
  noPadding: {
    '&:last-child': {
      padding: 0,
    },
    width: 24,
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
});

const Table = ({
  actionConstant,
  actions,
  classes,
  columnMeta,
  data,
  detailsRender,
  emptySubheading,
  emptyTitle,
  enableFilter,
  expanded,
  filterData,
  handleChangePage,
  handleChangeRowsPerPage,
  handleFilterChange,
  handleLabelDisplayedRows,
  handleOnRowClick,
  handleShowFilter,
  handleSort,
  hasDetails,
  hasRowClick,
  highlight,
  idMeta,
  onChange,
  order,
  orderBy,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  showFilter,
  total,
  visible,
}) => {
  const items = filterData(data);

  return (
    <MaterialTable className={classes.root}>
      <TableHead>
        {onChange && (
          <TableRow className={classes.footer}>
            {enableFilter && (
              <TableCell>
                <IconButton
                  aria-label="Filter list"
                  onClick={handleShowFilter}
                  t-i="filter"
                >
                  <FilterList />
                </IconButton>
              </TableCell>
            )}
            {!showFilter ? (
              <TablePagination
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                colSpan={
                  columnMeta.length -
                  (enableFilter ? 1 : 0) +
                  (actions ? actions.length : 0) +
                  (hasDetails ? 1 : 0)
                }
                count={total}
                labelDisplayedRows={handleLabelDisplayedRows}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            ) : (
              <TableCell
                colSpan={columnMeta.length + (actions ? actions.length : 0)}
                padding="none"
              />
            )}
          </TableRow>
        )}
        <TableRow>
          {columnMeta.map(({ id, sortId, label, numeric, disablePadding }) => (
            <TableCell
              key={id}
              align={numeric ? 'right' : 'left'}
              padding={disablePadding ? 'none' : 'normal'}
            >
              {sortId ? (
                <TableSortLabel
                  active={orderBy === sortId}
                  classes={{ icon: classes.sortLabelIcon }}
                  direction={order}
                  onClick={handleSort(sortId)}
                >
                  {label}
                </TableSortLabel>
              ) : (
                `${label}`
              )}
            </TableCell>
          ))}
          {actions && <TableCell colSpan={actions.length} padding="none" />}
          {hasDetails && <TableCell padding="none" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {showFilter && (
          <TableRow
            key="tableFilter"
            className={classes.rowBody}
            hover
            t-i="tableRow"
          >
            {columnMeta.map(({ id, numeric, disablePadding }) => (
              <TableCell
                key={`filter${id}`}
                align={numeric ? 'right' : 'left'}
                padding={disablePadding ? 'none' : 'default'}
              >
                <TextField
                  className={classes.filter}
                  onChange={handleFilterChange(id)}
                />
              </TableCell>
            ))}
          </TableRow>
        )}
        {items.map((row) => {
          const id = row[idMeta];
          const key = `key${id}`;

          return (
            <Fragment key={key}>
              <TableRow
                aria-expanded={expanded[id]}
                className={classNames(classes.rowBody, {
                  [classes.expanded]: expanded[id],
                  [classes.details]: hasDetails || hasRowClick,
                  [classes.highlight]: highlight && highlight(row),
                })}
                hover
                onClick={handleOnRowClick(row, id)}
                t-i="tableRow"
                tabIndex={-1}
              >
                <TableColumns columns={columnMeta} row={row} />
                {actions &&
                  actions.map((Action) => (
                    <Action key={Action.displayName} id={id} row={row} />
                  ))}
                {hasDetails && (
                  <TableCell className={classes.noPadding}>
                    <IconButton
                      aria-label="Collapse"
                      className={classNames(classes.hidden, {
                        [classes.visible]: expanded[id],
                      })}
                    >
                      <ExpandLess />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
              {hasDetails && (
                <TableRow
                  className={classNames(classes.collapsedRow, {
                    [classes.rowBody]: expanded[id],
                  })}
                >
                  <TableCell
                    className={classNames(classes.collapsedCell, {
                      [classes.expandedCell]: expanded[id],
                    })}
                    colSpan={columnMeta.length + 1}
                  >
                    <div ref={row[SCROLL_REF_KEY]} />
                    <Collapse in={expanded[id]}>
                      {visible && detailsRender(row, id, handleOnRowClick)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          );
        })}
        <EmptyMessageRow
          actionConstant={actionConstant}
          colSpan={columnMeta.length + 1}
          rowCount={items.length}
          subHeading={emptySubheading}
          title={emptyTitle}
        />
      </TableBody>
    </MaterialTable>
  );
};

Table.propTypes = {
  actionConstant: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object])
  ),
  classes: PropTypes.object.isRequired,
  columnMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.array.isRequired,
  detailsRender: PropTypes.func.isRequired,
  emptySubheading: PropTypes.string,
  emptyTitle: PropTypes.string,
  enableFilter: PropTypes.bool,
  expanded: PropTypes.object.isRequired,
  filterData: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleLabelDisplayedRows: PropTypes.func.isRequired,
  handleOnRowClick: PropTypes.func.isRequired,
  handleShowFilter: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  hasDetails: PropTypes.bool.isRequired,
  hasRowClick: PropTypes.bool.isRequired,
  highlight: PropTypes.func,
  idMeta: PropTypes.string,
  onChange: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  showFilter: PropTypes.bool.isRequired,
  total: PropTypes.number,
  visible: PropTypes.object.isRequired,
};

Table.defaultProps = {
  actionConstant: {},
  emptySubheading: '',
  emptyTitle: '',
  enableFilter: false,
  onChange: null,
  order: null,
  orderBy: null,
  total: 0,
  actions: null,
  idMeta: 'id',
  highlight: null,
};

export default compose(
  withState('expanded', 'setExpanded', {}),
  withState('filter', 'setFilter', {}),
  withState('hasDetails', null, ({ detailsRender }) => !isNil(detailsRender)),
  withState('hasRowClick', null, ({ onRowClick }) => !isNil(onRowClick)),
  withState('showFilter', 'setShowFilter', false),
  withState('visible', 'setVisible', {}),
  withProps(({ rowsPerPageOptions }) => ({
    rowsPerPageOptions: rowsPerPageOptions || ROWS_PER_PAGE,
  })),
  withProps(({ order, page, rowsPerPage, rowsPerPageOptions }) => ({
    order: defaultTo('asc', order),
    page: defaultTo(0, page),
    rowsPerPage: defaultTo(rowsPerPageOptions[0], rowsPerPage),
  })),
  withProps(({ data, page, rowsPerPage }) => ({
    data:
      data.length > rowsPerPage
        ? dropLast(data.length - rowsPerPage, data)
        : data,
    total: page * rowsPerPage + data.length,
  })),
  withPropsOnChange(
    ['detailsRender', 'onRowClick'],
    ({ detailsRender, onRowClick }) => ({
      detailsRender: defaultTo(always(), detailsRender),
      onRowClick: defaultTo(always(), onRowClick),
    })
  ),
  withHandlers({
    handleChangePage:
      ({ orderBy, order, rowsPerPage, onChange }) =>
      (event, page) => {
        onChange(event, {
          orderBy,
          order,
          page,
          rowsPerPage,
        });
      },
    handleChangeRowsPerPage:
      ({ orderBy, order, page, onChange }) =>
      (event) => {
        onChange(event, {
          orderBy,
          order,
          page,
          rowsPerPage: event.target.value,
        });
      },
    handleSort:
      ({ orderBy, order, onChange, page, rowsPerPage }) =>
      (property) =>
      (event) => {
        const newOrder =
          orderBy === property ? (order === 'asc' ? 'desc' : 'asc') : 'asc';
        onChange(event, {
          orderBy: property,
          order: newOrder,
          page,
          rowsPerPage,
        });
      },
    handleShowFilter:
      ({ setFilter, showFilter, setShowFilter }) =>
      () => {
        if (showFilter) {
          setFilter({});
        }
        setShowFilter(!showFilter);
      },
    handleFilterChange:
      ({ filter, setFilter, enableFilter }) =>
      (columnId) =>
      (event) => {
        const { value } = event.target;
        if (enableFilter) {
          setFilter(
            isEmpty(value)
              ? omit([columnId], filter)
              : mergeRight(filter, {
                  [columnId]: (dataValue) =>
                    dataValue &&
                    `${dataValue}`.toLowerCase().indexOf(value.toLowerCase()) >=
                      0,
                })
          );
        }
      },
    filterData:
      ({ filter, hasDetails }) =>
      (data) => {
        if (hasDetails) {
          // Caller has specified they want details - need to add a ref to each row
          // so we can scroll to it when they click on it to see details
          // eslint-disable-next-line no-return-assign
          forEach((row) => (row[SCROLL_REF_KEY] = createRef()), data);
        }
        return isEmpty(filter) ? data : ramdaFilter(where(filter), data);
      },
    handleLabelDisplayedRows:
      ({ page, rowsPerPage }) =>
      ({ from, to, count }) => {
        const offset = (page + 1) * rowsPerPage;
        if (count > offset) {
          return `${from}-${to} of ${offset}+`;
        }
        return `${from}-${to} of ${count}`;
      },
    handleOnRowClick:
      ({ onRowClick, expanded, setExpanded, visible, setVisible }) =>
      (row, id) =>
      (event) => {
        const expandedObj = { ...expanded };
        const visibleObj = { ...visible };
        expandedObj[id] = !expandedObj[id];
        visibleObj[id] = !visibleObj[id];

        event.stopPropagation();
        setExpanded(expandedObj);
        setVisible(visibleObj);
        onRowClick(row, event);
        setTimeout(() => scroll(row[SCROLL_REF_KEY]), SCROLL_DELAY);
      },
  })
)(nest(Loading, withStyles(styles)(Table)));
