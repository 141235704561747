import { takeEvery } from 'redux-saga/effects';
import { getEBillAccount, deleteStatement, updateStatement } from 'sdk/eBill';
import { LOAD, DELETE_STATEMENT, UPDATE_STATEMENT } from 'model/eBillAccount';
import { date } from 'util/dateAndTime';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: getEBillAccount, dataName: ORM, type: LOAD })
  );

  yield takeEvery(
    DELETE_STATEMENT.ACTION,
    callAction({
      api: deleteStatement,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `eBill statement on "${date(
          response.billOn
        )}" was successfully deleted`,
      type: DELETE_STATEMENT,
    })
  );

  yield takeEvery(
    UPDATE_STATEMENT.ACTION,
    callAction({
      api: updateStatement,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `eBill statement on "${date(
          response.billOn
        )}" was successfully updated`,
      type: UPDATE_STATEMENT,
    })
  );
}
