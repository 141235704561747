import { load } from 'model/org';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useLoadOrgsEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(load());
  }, [dispatch]);
};

export default useLoadOrgsEffect;
