import PropTypes from 'prop-types';
import authTokenSelector from 'selectors/authToken';
import email from 'email-validator';
import { RESET, reset } from 'reducers/reset';
import { always } from 'ramda';
import {
  branch,
  compose,
  lifecycle,
  pure,
  renderNothing,
  setDisplayName,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { submit as submitAction } from 'util/index';
import { propTypes, reduxForm, Field } from 'redux-form';
import { renderTextField } from 'components/common/fields/render';
import { resetSelector } from 'selectors/security';
import { selector } from 'selectors/busy';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@material-ui/core';

export const validate = ({ userName = '' }) => {
  const errors = {};

  if (!userName.length) {
    errors.userName = 'Enter your email address';
  }

  if (userName && !email.validate(userName)) {
    errors.userName = 'Enter a valid email address';
  }

  return errors;
};

const formProps = {
  form: 'reset',
  validate,
};
const subheaderTypographyProps = { color: 'textSecondary', variant: 'body2' };
const titleTypographyProps = { color: 'textPrimary', variant: 'subtitle1' };

const PasswordReset = ({ invalid, onViewChange, submit, submitting }) => {
  return (
    <form onSubmit={submit}>
      <CardHeader
        subheader="Enter your email address and we'll send you a link to reset your password"
        subheaderTypographyProps={subheaderTypographyProps}
        title="Forgot Your Password?"
        titleTypographyProps={titleTypographyProps}
      />
      <CardContent>
        <Field
          autoComplete="off"
          autoFocus
          component={renderTextField}
          disabled={submitting}
          fullWidth
          label="Email Address"
          name="userName"
        />
      </CardContent>
      <CardActions>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Button onClick={onViewChange} size="large">
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={invalid || submitting}
              size="large"
              type="submit"
              variant="contained"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

PasswordReset.propTypes = {
  onViewChange: PropTypes.func,
  ...propTypes,
};

PasswordReset.defaultProps = {
  onViewChange: always,
};

export default compose(
  setDisplayName('PasswordReset'),
  branch(({ visible }) => !visible, renderNothing),
  connect((state) => ({
    authToken: authTokenSelector(state),
    isBusy: selector(state, RESET),
    emailReset: resetSelector(state),
  })),
  reduxForm(formProps),
  withProps(({ handleSubmit, form }) => ({
    submit: handleSubmit(submitAction(reset, form)),
  })),
  lifecycle({
    componentDidMount() {
      const { authToken, initialize } = this.props;

      if (authToken.isAuthenticated && authToken.userLogin) {
        initialize({ userName: authToken.userLogin });
      }
    },
    componentDidUpdate(prevProps) {
      const {
        emailReset: { data, ran, success },
        isBusy,
      } = this.props;

      if (prevProps.isBusy !== isBusy) {
        const { onProgress = always } = this.props;

        onProgress(isBusy);
      }

      if (ran && success) {
        const { onReset = always } = this.props;

        onReset(data);
      }
    },
  }),
  pure
)(PasswordReset);
