import { attr, Model } from 'redux-orm';
import { addDerived } from './util/userRole';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export default class UserRole extends Model {
  static get modelName() {
    return 'UserRole';
  }

  static reducer({ type, data }, UserRole) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        UserRole.create(addDerived(data?.response));
        break;

      case LOAD.SUCCESS:
        UserRole.delete();
        if (data?.response) {
          data.response.forEach((r) => UserRole.create(addDerived(r)));
        }
        break;

      case REMOVE.SUCCESS:
        if (UserRole.idExists(id)) {
          UserRole.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        UserRole.upsert(addDerived(data?.response));
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      a2aExternalDda: attr(),
      a2aTransferFrom: attr(),
      a2aTransferTo: attr(),
      bpPayee: attr(),
      bpPayment: attr(),
      createdAt: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      offset: attr(),
      p2pPayment: attr(),
      p2pRecipient: attr(),
      role: attr(),
      updatedAt: attr(),
    };
  }
}

const name = UserRole.modelName;

export const ADD = createAddConst(name);
export const IMPORT = createImportConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const importAction = createAction(IMPORT);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
