import { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

/**
 * Icon vector graphics found here: https://iconify.design/icon-sets/mdi/venmo.html
 */
export const dPath =
  'M19.5 3c.64 1.08.94 2.19.94 3.6c0 4.48-3.83 10.31-6.94 14.4H6.41L3.56 4l6.21-.61l1.51 12.11c1.41-2.29 3.14-5.89 3.14-8.34c0-1.35-.23-2.26-.59-3.01L19.5 3z';

const VenmoIcon = forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref}>
    <path d={dPath} />
  </SvgIcon>
));

VenmoIcon.displayName = 'VenmoIcon';

export default VenmoIcon;
