import PropTypes from 'prop-types';
import { always } from 'ramda';

import { Button, CardActions, CardHeader, Grid } from '@material-ui/core';

const PasswordResetPost = ({ email, onGo }) => {
  const subheader = `An email has been sent to ${email} to reset your password.  If you do not receive an email, please contact your administrator.`;
  const subheaderTypographyProps = { color: 'textSecondary', variant: 'body2' };

  return (
    <>
      <CardHeader
        subheader={subheader}
        subheaderTypographyProps={subheaderTypographyProps}
      />
      <CardActions>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              onClick={onGo}
              size="large"
              variant="contained"
            >
              Go to Login
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </>
  );
};

PasswordResetPost.propTypes = {
  email: PropTypes.string.isRequired,
  onGo: PropTypes.func,
};

PasswordResetPost.defaultProps = {
  onGo: always,
};

export default PasswordResetPost;
