import { useCallback, useEffect, useState } from 'react';
import { BUSY_DELAY } from 'sagas';
import { CHANGED, NEW, RESET } from 'reducers/validate';
import { CardHeader, Popper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { validateSelector } from 'selectors/security';
import PasswordRules from '../Password/PasswordUpdate/PasswordRules';
import Update from '../Password/PasswordUpdate';
import { PATH as LOGIN_PATH } from '../Login';

const titleTypographyProps = { color: 'textPrimary', variant: 'subtitle1' };

const getTitles = (data = {}) => {
  const { linkEventName, userName } = data;

  const retVal = {
    buttonText: 'Change Password',
    passwordTitle: '',
    title: 'Ontrac',
  };

  switch (linkEventName) {
    case CHANGED:
      retVal.buttonText = 'Update Password';
      retVal.passwordTitle = 'Update password';
      retVal.title = 'Your password was changed';
      break;

    case NEW:
      retVal.buttonText = 'Create Password';
      retVal.passwordTitle = `Create your password for ${userName}`;
      retVal.title = 'Welcome to Ontrac';
      break;

    case RESET:
      retVal.buttonText = 'Reset Password';
      retVal.passwordTitle = `Enter a new password for ${userName}`;
      retVal.title = 'Reset your password';
      break;

    default:
      break;
  }

  return retVal;
};

const PostValidation = () => {
  const [byRuleDisabled, setByRuleDisabled] = useState(false);
  const [openPasswordRules, setOpenPasswordRules] = useState(false);
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { ran, success, data } = useSelector(validateSelector);

  const buttonText = getTitles(data).buttonText;
  const passwordTitle = getTitles(data).passwordTitle;
  const anchorEl = document.getElementById('popper-anchor');

  const handleOnTimedOut = useCallback(() => {
    history.push(LOGIN_PATH);
  }, [history]);
  const handleChange = (event) => {
    if (event.target) {
      setPassword(event.target.value);
    }
  };
  const handleRuleChange = (enabled) => {
    if (byRuleDisabled === enabled) {
      setByRuleDisabled(!enabled);
    }
  };
  const handleUpdated = () => {
    history.push(LOGIN_PATH);
  };

  useEffect(() => {
    let timeoutId = 0;

    if (!openPasswordRules) {
      timeoutId = setTimeout(() => {
        setOpenPasswordRules(true);
      }, BUSY_DELAY);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openPasswordRules]);

  return ran && success ? (
    <>
      <CardHeader
        title={passwordTitle}
        titleTypographyProps={titleTypographyProps}
      />
      <Update
        buttonText={buttonText}
        byRuleDisabled={byRuleDisabled}
        onChange={handleChange}
        onTimedOut={handleOnTimedOut}
        onUpdated={handleUpdated}
        post
        visible
      />
      <Popper anchorEl={anchorEl} open placement="right">
        <PasswordRules onRuleChange={handleRuleChange} password={password} />
      </Popper>
    </>
  ) : null;
};

export default PostValidation;
