import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose, mapProps, pure } from 'recompose';
import {
  IconButton as MuiIconButton,
  Typography,
  withStyles,
} from '@material-ui/core';

const styles = () => ({
  root: {},
  iconComponent: {},
  label: {},
});

const IconButton = ({ IconComponent, label, classes, ...restOfProps }) => {
  return (
    <MuiIconButton className={classes.root} {...restOfProps}>
      <IconComponent className={classes.iconComponent} />
      {label && (
        <Typography className={classes.label} variant="body2">
          {label}
        </Typography>
      )}
    </MuiIconButton>
  );
};

IconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  IconComponent: PropTypes.object.isRequired,
};

IconButton.defaultProps = {
  url: null,
  onClick: null,
  label: null,
};

export default compose(
  withStyles(styles),
  mapProps(({ url, onClick, ...restOfProps }) =>
    url
      ? { component: Link, to: url, ...restOfProps }
      : { onClick, ...restOfProps }
  ),
  pure
)(IconButton);
