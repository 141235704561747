const DETAIL = 'detail';
const SEARCH = 'search';

export const BASE_PATH = '/service';
export const DETAIL_PATH = `${BASE_PATH}/${DETAIL}`;
export const SEARCH_PATH = `${BASE_PATH}/${SEARCH}`;

export const getLocationFromPayer = ({ zfspId, id }) => {
  return {
    pathname: `${DETAIL_PATH}/${zfspId}/${id}`,
    state: { add: true },
  };
};
