import { capitalizeFirstLetter, isTrueObject } from 'consts';

/**
 * This method is designed to flatten out the body for reset* api methods only
 * This method is not meant to be recursive, it does one level only, on purpose!
 * @param nestedObject
 * @returns {{}}
 */
const flattenObjects = (nestedObject = {}) => {
  const flatObject = {};

  Object.entries(nestedObject).forEach(([key, value]) => {
    if (isTrueObject(value)) {
      Object.entries(value).forEach(([subKey, subValue]) => {
        if (!subKey.includes('Derived')) {
          flatObject[key + capitalizeFirstLetter(subKey)] = subValue;
        }
      });
    } else {
      flatObject[key] = value;
    }
  });

  return flatObject;
};

export default (objectsToFlatten) => objectsToFlatten.map(flattenObjects);
