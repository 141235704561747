import { client, formatQueryParams } from 'util/sdk';
import { isNil } from 'ramda';
import { ROWS_PER_PAGE_MAX } from 'consts';
import { PAYRAILZ_ADMIN_ORG_ID } from './index';

export const addOrg = (data, auth) =>
  client.admin.organizations.create(data, { adminUserId: auth.id });

export const deleteOrg = ({ id }, auth) =>
  client.admin.organizations(id).delete({ adminUserId: auth.id });

export const load = (params, auth) => {
  /** 12/12/2019 - https://payrailz.atlassian.net/browse/ON-516 */

  if (auth.adminOrg?.id === PAYRAILZ_ADMIN_ORG_ID) {
    return client.admin.organizations.query(formatQueryParams(params));
  }

  return client.admin
    .users(auth?.id)
    .organizations.query(formatQueryParams(params));
};

export const updateOrg = ({ description, id, name }, auth) =>
  client.admin
    .organizations(id)
    .update({ description, name }, { adminUserId: auth.id });
