import { CREATE, LOAD, REMOVE, UPDATE } from 'model/fspAiEvents';
import { formatSuccessMessage, UPDATED, FSP_FRAUD_CONFIG_LABEL } from 'consts';
import { create, load, update, remove } from 'sdk/fspAiEvents';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

const keyName = { key: '', name: FSP_FRAUD_CONFIG_LABEL };

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      type: CREATE,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage(keyName, UPDATED, false),
      type: UPDATE,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      type: REMOVE,
    })
  );
}
