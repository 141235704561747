import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { load } from 'model/payALoan';
import { selector } from 'selectors/payALoan';
import { Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { useDispatch, useSelector } from 'react-redux';
import { filterByDDA } from 'consts';

const ExpandedRow = ({ item = {} }) => {
  const dispatch = useDispatch();
  const fspId = item.fspId;
  const payerId = item.payerId;
  const stmtItems = useSelector((state) => selector(state));
  const mappedList = filterByDDA(item, stmtItems);

  useEffect(() => {
    dispatch(load({ fspId, payerId }));
  }, [dispatch, fspId, payerId]);

  return mappedList.length > 0 ? (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ReadOnlyFormControl
              isDate
              label="Date of Loan"
              value={mappedList[0].createdAt}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormControl
              label="Statement Date"
              value={mappedList[0].stmtOn}
            />
          </Grid>
          <Grid item xs={3}>
            <ReadOnlyFormControl
              isDate
              label="Payment Due Date"
              value={mappedList[0].dueOn}
            />
          </Grid>
          <Grid>
            <Grid item xs={5}>
              <ReadOnlyFormControl
                label="Amount Due"
                value={mappedList[0].amtDueDerived}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} />
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Min Due"
                value={mappedList[0].minAmtDerived}
              />
            </Grid>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Updated At"
                value={mappedList[0].updatedAtDerived}
              />
            </Grid>

            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Due Date"
                value={mappedList[0].dueOn}
              />
            </Grid>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Balance"
                value={mappedList[0].outstandingBalanceDerived}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

ExpandedRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default memo(ExpandedRow);
