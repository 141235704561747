import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { ButtonPopover } from 'components/common';
import TicketsContent from './TicketsContent';

const anchorOrigin = { horizontal: 'center', vertical: 'top' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

const Tickets = ({ fspId, id }) => {
  const [appendContent, setAppendContent] = useState(false);

  const handleClick = () => {
    if (appendContent === false) {
      setAppendContent(true);
    }
  };

  return fspId && id ? (
    <ButtonPopover
      anchorOrigin={anchorOrigin}
      onClick={handleClick}
      title="Tickets"
      transformOrigin={transformOrigin}
      variant="outlined"
    >
      {appendContent && <TicketsContent fspId={fspId} payerId={id} />}
    </ButtonPopover>
  ) : null;
};

Tickets.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default memo(Tickets);
