import PropTypes from 'prop-types';
import privilege from './general';

const AuthType = PropTypes.shape({
  isAuthenticated: PropTypes.bool.isRequired,
  csrf: PropTypes.string,
  organizationId: PropTypes.number,
  userLogin: PropTypes.string,
  type: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mfaQuestion: PropTypes.string,
  mobilePhone: PropTypes.string,
  workPhone: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  fsps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      ...privilege,
    })
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      ...privilege,
    })
  ),
});

export default AuthType;
