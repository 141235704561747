import { Model, attr } from 'redux-orm';
import { TRANSACTION_TYPE, DELIVER_CODE as DELIVER } from 'consts';
import { addDerived } from './util/fspFraudOverride';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export const Fields = {
  TRANSACTION_TYPE,
  DELIVER,
};

export default class FraudFSPOverride extends Model {
  static get modelName() {
    return 'FraudFSPOverride';
  }

  static reducer({ type, data }, FraudFSPOverride) {
    const id = data?.props?.id;
    switch (type) {
      case LOAD.ACTION:
        FraudFSPOverride.delete();
        break;

      case LOAD.SUCCESS:
        if (data?.response) {
          data.response?.forEach((r) => FraudFSPOverride.upsert(addDerived(r)));
        }
        break;

      case CREATE.SUCCESS:
        if (data?.response) {
          FraudFSPOverride.upsert(addDerived(data.response));
        }
        break;

      case REMOVE.SUCCESS:
        if (FraudFSPOverride.idExists(id)) {
          FraudFSPOverride.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        FraudFSPOverride.upsert(addDerived(data?.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      offset: attr(),
      href: attr(),
      id: attr(),
      fspId: attr(),
      fspConfigId: attr(),
      transactionType: attr(),
      deliver: attr(),
      deliverDerived: attr(), // derived
      onholdMinutesMax: attr(),
      cancelScoreGreaterThanEqual: attr(),
      scheduleScoreLessThanEqual: attr(),
      suspendPayerScoreGreaterThanEqual: attr(),
      finalCancelScoreGreaterThanEqual: attr(),
      createdAt: attr(),
      insUserId: attr(),
      insUser: attr(),
      insAdminUserId: attr(),
      updatedAt: attr(),
      updUserId: attr(),
      updUser: attr(),
      updAdminUserId: attr(),
    };
  }
}

const name = FraudFSPOverride.modelName;

export const CREATE = createAddConst(name);
export const LOAD = createRequestConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
