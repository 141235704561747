import { createCachedSelector } from 're-reselect';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { getSecondArgument } from 'consts';
import {
  combinerProducts,
  combinerSelectorArray,
  filterByFSPId,
  productEnabledA2A,
  productEnabledBillPay,
  productEnabledP2P,
  productEnabledPal,
} from './utils/fspConfig';

const baseSelector = createSelector(orm, getSecondArgument, filterByFSPId);

export const selector = createCachedSelector(
  baseSelector,
  combinerSelectorArray
)(getSecondArgument);

export const selectorA2AEnabled = createReselectSelector(
  baseSelector,
  productEnabledA2A
);

export const selectorBillPayEnabled = createReselectSelector(
  baseSelector,
  productEnabledBillPay
);

export const selectorP2PEnabled = createReselectSelector(
  selector,
  productEnabledP2P
);

export const selectorPalEnabled = createReselectSelector(
  selector,
  productEnabledPal
);

export const selectorProducts = createReselectSelector(
  selector,
  combinerProducts
);
