import { useSelector } from 'react-redux';
import { selectorFraudEnabled } from 'selectors/fspFraudConfig';
import useDispatchLoadEffect from './useDispatchLoadEffect';
import useSelectedFSPId from '../useSelectedFSPId';

const useFraudEnabled = () => {
  const fspId = useSelectedFSPId();
  const fraudEnabled = useSelector((state) =>
    selectorFraudEnabled(state, fspId)
  );

  useDispatchLoadEffect(fspId, fraudEnabled === undefined);

  return fraudEnabled;
};

export default useFraudEnabled;
