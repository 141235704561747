import { loadBySchedId } from 'model/autopayScheduled';
import { selectorSchedId as selector } from 'selectors/autopayScheduled';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const useLoad = (transaction) => {
  const autopay = useSelector((state) => selector(state, transaction.schedId));
  const dispatch = useDispatch();

  const { autopayRuleId, fspId, schedId } = transaction;
  const { statusDerived } = autopay;

  useEffect(() => {
    if (!statusDerived && autopayRuleId) {
      dispatch(loadBySchedId({ fspId, schedId }));
    }
  }, [autopayRuleId, dispatch, fspId, schedId, statusDerived]);
};

export default useLoad;
