import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';

const IPayMerchantId = ({ transaction }) => {
  return transaction.ipayNetBillerId ? (
    <Grid item xs={2}>
      <ReadOnlyFormControl
        label="iPay Merchant ID"
        value={transaction.ipayNetBillerId}
      />
    </Grid>
  ) : null;
};

IPayMerchantId.propTypes = {
  transaction: PropTypes.shape({
    ipayNetBillerId: PropTypes.number,
  }).isRequired,
};

export default IPayMerchantId;
