import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import { derivedSelector } from 'selectors/externalAccount';
import { handleStopPropagation, OFF, ON } from 'consts';
import { isLoadingState } from 'reducers/loading';
import { load, LOAD } from 'model/externalAccount';
import { selector as busySelector } from 'selectors/busy';
import { useDispatch, useSelector } from 'react-redux';
import AccountList from '../AccountsList';
import ToggleCurrentDeleted from '../../ToggleCurrentDeleted';
import {
  ActionsRenderer,
  columnMeta,
  defaultOrderBy,
  detailsRender,
} from './config';

const settingType = 'externalAccount';

const External = ({ fspId, payerId }) => {
  const [deletedOnly, setDeletedOnly] = useState(OFF);
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const isLoading = useSelector((state) => isLoadingState(state, LOAD.ACTION));
  const items = useSelector((state) => derivedSelector(state, payerId));

  const loadAction = useCallback(
    (props) => {
      dispatch(load({ deletedOnly, fspId, payerId, ...props }));
    },
    [deletedOnly, dispatch, fspId, payerId]
  );

  const handleChange = useCallback(
    (event) => {
      handleStopPropagation(event);
      setDeletedOnly(deletedOnly === ON ? OFF : ON);
    },
    [deletedOnly]
  );

  useEffect(() => {
    dispatch(load({ deletedOnly, fspId, payerId }));
  }, [deletedOnly, dispatch, fspId, payerId]);

  return (
    <AccountList
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      columnMeta={columnMeta}
      deletedOnly={deletedOnly}
      detailsRender={detailsRender}
      endAdornment={
        <ToggleCurrentDeleted
          action={LOAD}
          onChange={handleChange}
          settingId={payerId}
          settingType={settingType}
        />
      }
      fspId={fspId}
      isBusy={isBusy}
      isLoading={isLoading}
      items={items}
      loadAction={loadAction}
      name="external"
      orderBy={defaultOrderBy}
      payerId={payerId}
      settingType={settingType}
      title="External"
    />
  );
};

External.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(External);
