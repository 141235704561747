import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { TITLE } from 'consts';
import { Tab, Tabs } from '@material-ui/core';
import { VALIDATE, validate } from 'reducers/validate';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/busy';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { validateSelector } from 'selectors/security';
import Authentication from '../Authentication';
import PostValidation from './PostValidation';
import ValidationErrors from './ValidationErrors';
import { PATH as LOGIN_PATH } from '../Login';

export const PATH = '/uv/:id';

const PROBLEM = 'Problem with your link';

const useStyles = makeStyles(() => ({
  tab: {
    display: 'none',
  },
}));

const Validation = () => {
  const [postTitle, setPostTitle] = useState('');
  const [value, setValue] = useState(0);
  const busy = useSelector((state) => selector(state, VALIDATE));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const link = useSelector(validateSelector);
  const match = useRouteMatch();

  const { ran, success, updateBusy } = link;
  const progress = busy || updateBusy || false;
  const ready = ran && success;
  const title = ran && success ? postTitle : ran ? PROBLEM : TITLE;

  const handleTitleChange = (value) => {
    setPostTitle(value);
  };
  const handleUpdated = () => {
    history.push(LOGIN_PATH);
  };

  useEffect(() => {
    if (!ran) {
      dispatch(validate(match.params));
    }
  }, [dispatch, match.params, ran]);

  useEffect(() => {
    if (ready && value === 0) {
      setValue(1);
    }
  }, [ready, value]);

  return (
    <Authentication progress={progress} title={title}>
      <Tabs classes={{ root: classes.tab }} value={value}>
        <Tab />
        <Tab />
      </Tabs>
      <SwipeableViews id="popper-anchor" index={value}>
        <ValidationErrors />
        <PostValidation
          onTitleChange={handleTitleChange}
          onUpdated={handleUpdated}
        />
      </SwipeableViews>
    </Authentication>
  );
};

export default Validation;
