import { AUTOPAY } from '../../Autopay';
import { AUTOPAY_RULE_STATUS } from '../../../../../../consts';

import CreditDetail from './CreditDetail';

export const columnMeta = [
  {
    context: true,
    id: 'ddaAccountName',
    label: 'Account Name',
    xs: 2,
  },
  {
    id: 'statementPeriodDerived',
    label: 'Statement Period',
    xs: 2,
  },
  {
    id: 'dueOnDerived',
    label: 'Due Date',
    xs: 2,
  },
  {
    id: 'amountDueDerived',
    label: 'Due Amount',
    xs: 2,
  },
  {
    capitalize: true,
    id: 'autopayStatusDerived',
    label: `${AUTOPAY} Status`,
    xs: 2,
  },
  {
    capitalize: true,
    id: 'autopayRuleStatusDerived',
    label: `${AUTOPAY_RULE_STATUS}`,
    xs: 2,
  },
];

export const defaultPageSettings = { order: 'desc', orderBy: 'id' };

export const detailsRender = (autopayScheduled) => (
  <CreditDetail autopayScheduled={autopayScheduled} />
);
