import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { always, head } from 'ramda';
import {
  LOAD_PAYER,
  LOAD_PAYER_USER,
  loadPayer,
  loadPayerUser,
} from 'model/viewAs';
import { NEW_TAB } from 'consts';
import { selector } from 'selectors/viewAs';
import { useDispatch, useSelector } from 'react-redux';
import { useFSPId } from 'hooks';
import { withViewAsPayer } from 'components/common/permissions';
import PayerUserButton, {
  usePayerUserButton,
} from '../../PayerOrPayerUserButton';
import Products from './Products';

const ViewAs = ({ fspId: fspIdProp, payerId, payerType, payerUser }) => {
  const [anchorElLocal, setAnchorElLocal] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [submenu, setSubmenu] = useState(false);
  const [tabOpen, setTabOpen] = useState(true);
  const { setAnchorEl = always, setDisabled = always } = usePayerUserButton();
  const dispatch = useDispatch();
  const fspId = useFSPId(fspIdProp);
  const { urls = [] } = useSelector((_) => selector(_, payerId)) ?? {};

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setAnchorElLocal(null);
    setCurrentTarget(null);
    setDisabled(false);
    setTabOpen(true);
  }, [setAnchorEl, setDisabled]);
  const sharedClickHandler = (event) => {
    setCurrentTarget(event.currentTarget);
    setSubmenu(false);
    setTabOpen(false);
  };
  const handlePayerClick = (event) => {
    dispatch(loadPayer({ fspId, payerId }));
    sharedClickHandler(event);
  };
  const handlePayerUserClick = (event, payerUser) => {
    const data = { fspId, payerId, userId: payerUser.userId, payerUser };

    dispatch(loadPayerUser(data));
    sharedClickHandler(event);
  };

  useEffect(() => {
    if (urls.length === 1 && !tabOpen) {
      window.open(head(urls).url, NEW_TAB);
      handleClose();
    } else if (urls.length > 1) {
      setAnchorElLocal(currentTarget);
    }
  }, [currentTarget, handleClose, tabOpen, urls]);

  return (
    <>
      <PayerUserButton
        fspId={fspId}
        onPayerClick={handlePayerClick}
        onPayerUserClick={handlePayerUserClick}
        payerAction={LOAD_PAYER}
        payerButtonText="View As Payer"
        payerId={payerId}
        payerType={payerType}
        payerUser={payerUser}
        payerUserAction={LOAD_PAYER_USER}
        payerUserButtonText="View As User"
        selector={selector}
        submenu={urls.length > 1}
      />
      <Products
        anchorEl={anchorElLocal}
        onClose={handleClose}
        payerId={payerId}
        submenu={submenu}
      />
    </>
  );
};

ViewAs.propTypes = {
  fspId: PropTypes.number,
  payerId: PropTypes.number.isRequired,
  payerType: PropTypes.string,
  payerUser: PropTypes.object,
};

ViewAs.defaultProps = {
  fspId: undefined,
  payerType: null,
  payerUser: null,
};

export default withViewAsPayer(ViewAs);
