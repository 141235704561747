import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

const CurrencyTextFieldStartAdornment = ({ disabled }) => {
  const classes = useStyles();

  return (
    <InputAdornment
      classes={{
        positionStart: classNames({
          [classes.disabled]: disabled,
        }),
      }}
      disableTypography
      position="start"
    >
      $
    </InputAdornment>
  );
};

CurrencyTextFieldStartAdornment.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default CurrencyTextFieldStartAdornment;
