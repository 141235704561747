import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  description: {
    fontSize: 'small',
  },
  label: {
    fontSize: 'large',
  },
}));

const StepChangeTypeLabel = ({ description, label }) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid className={classes.label} item>
        {label}
      </Grid>
      <Grid className={classes.description} item>
        {description}
      </Grid>
    </Grid>
  );
};

StepChangeTypeLabel.propTypes = {
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default StepChangeTypeLabel;
