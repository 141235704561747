import { LOAD_ID, LOAD_PAYER_ID, UPDATE } from 'model/user';
import { loadById, loadByPayerId, update } from 'sdk/user';
import { takeEvery } from 'redux-saga/effects';
import { callAction, fullName, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    LOAD_PAYER_ID.ACTION,
    callAction({
      api: loadByPayerId,
      dataName: ORM,
      type: LOAD_PAYER_ID,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `${fullName(response)} has been successfully updated`,
      type: UPDATE,
    })
  );
}
