import { addRequiredFields } from 'model/util/paynet';
import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';
import {
  DISBURSE_TYPE,
  DISPLAY_SEQUENCE,
  FSPS,
  FSP_PAYER_TYPE,
  FSP_PAYER_TYPES,
  PAYNET_ID_CODE,
  REQUIRED_OFF_ON,
  TRANSACTION_TYPE,
} from 'consts';
import { pick } from 'ramda';

const keys = [
  PAYNET_ID_CODE,
  DISBURSE_TYPE,
  TRANSACTION_TYPE,
  REQUIRED_OFF_ON,
  DISPLAY_SEQUENCE,
];
const returnModel = { name: FSP_PAYER_TYPE };

const addOrLoadURL = (fspId) => `/${FSPS}/${fspId}/${FSP_PAYER_TYPES}`;
const queryParams = ({ id }) => ({ adminUserId: id });
const removeOrUpdateURL = (id, fspId) => {
  return `${addOrLoadURL(fspId)}/${id}`;
};

export const add = ({ fspId, ...data }, auth) => {
  return client.executeRequest(POST, addOrLoadURL(fspId), {
    data: addRequiredFields(data),
    queryParams: queryParams(auth),
    returnModel,
  });
};

export const load = ({ fspId }) => {
  return client.executeRequest(GET, addOrLoadURL(fspId), { returnModel });
};

export const remove = ({ id, fspId }, auth) => {
  return client.executeRequest(DELETE, removeOrUpdateURL(id, fspId), {
    queryParams: queryParams(auth),
    returnModel,
  });
};

export const update = ({ id, fspId, ...data }, auth) => {
  return client.executeRequest(POST, removeOrUpdateURL(id, fspId), {
    data: pick(keys, data),
    queryParams: queryParams(auth),
    returnModel,
  });
};
