import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, List } from '@material-ui/core';
import { always } from 'ramda';
import LowercaseRule from './LowercaseRule';
import MinRule from './MinRule';
import NumberRule from './NumberRule';
import PasswordRulesAvatar from './PasswordRulesAvatar';
import SpecialRule from './SpecialRule';
import UppercaseRule from './UppercaseRule';

const PasswordRules = ({ onRuleChange, password }) => {
  const [conditions, setConditions] = useState({});
  const [enabled, setEnabled] = useState(false);

  const handleChange = (enabled, text) => {
    conditions[text] = enabled;
    setConditions(conditions);
  };

  useEffect(() => {
    const matchingConditions = Object.values(conditions).filter(
      (value) => value
    );

    setEnabled(matchingConditions.length === 5);
    onRuleChange(matchingConditions.length === 5);
  }, [conditions, onRuleChange]);

  return (
    <Card>
      <CardHeader
        avatar={<PasswordRulesAvatar enabled={enabled} />}
        subheader="meet all of the following:"
        title="Password Requirements"
      />
      <CardContent>
        <List>
          <MinRule onChange={handleChange} password={password} />
          <LowercaseRule onChange={handleChange} password={password} />
          <UppercaseRule onChange={handleChange} password={password} />
          <NumberRule onChange={handleChange} password={password} />
          <SpecialRule onChange={handleChange} password={password} />
        </List>
      </CardContent>
    </Card>
  );
};

PasswordRules.propTypes = {
  onRuleChange: PropTypes.func,
  password: PropTypes.string,
};

PasswordRules.defaultProps = {
  onRuleChange: always,
  password: '',
};

export default PasswordRules;
