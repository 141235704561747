import PropTypes from 'prop-types';
import { memo, useCallback, useState } from 'react';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover + $secondaryAction': {
      display: 'block',
    },
  },
  secondaryAction: {
    display: 'none',
    '&:hover': {
      display: 'block',
    },
  },
}));

const RecentListItem = ({ icon, item, remove, selected, showTertiary }) => {
  const [replace, setReplace] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    setReplace(true);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(remove(item));
  }, [dispatch, remove, item]);

  const { location, primary, secondary, tertiary } = item;

  return (
    <ListItem
      button
      classes={{ root: classes.root }}
      component={Link}
      onClick={handleClick}
      replace={replace}
      selected={selected}
      to={location}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={primary}
        secondary={
          <>
            <Typography component="div" variant="body1">
              {secondary}
            </Typography>
            {showTertiary && tertiary && (
              <Typography component="div" variant="caption">
                {tertiary}
              </Typography>
            )}
          </>
        }
        secondaryTypographyProps={{ component: 'span' }}
      />
      <ListItemSecondaryAction classes={{ root: classes.secondaryAction }}>
        <IconButton
          aria-label="Delete"
          edge="end"
          onClick={handleDelete}
          size="small"
        >
          <Close fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

RecentListItem.propTypes = {
  icon: PropTypes.object,
  item: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  showTertiary: PropTypes.bool.isRequired,
};

RecentListItem.defaultProps = {
  icon: null,
};

export default memo(RecentListItem);
