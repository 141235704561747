import Alert from 'components/common/Alert';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { profileSelector } from 'selectors/payer';
import { removeCustom, REMOVE_CUSTOM } from 'model/limitRule';
import { selector as busySelector } from 'selectors/busy';
import { isCustomLimitType } from 'selectors/utils/limit';
import { useDispatch, useSelector } from 'react-redux';
import { useFSPId } from 'hooks';
import { useLimits } from '../LimitsContext';

const LimitsCardHeaderActionsRemove = ({ disabled }) => {
  const { expired, limitType, payerId } = useLimits();
  const [alertOpen, setAlertOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(isCustomLimitType(limitType));
  const dispatch = useDispatch();
  const fspId = useFSPId();
  const isBusy = useSelector((state) => busySelector(state, REMOVE_CUSTOM));
  const profile = useSelector((state) => profileSelector(state, payerId));

  const handleClick = () => {
    setAlertOpen(true);
  };
  const handleClose = () => {
    setAlertOpen(false);
  };
  const handleRemove = () => {
    dispatch(removeCustom({ fspId, payerId }));
  };

  useEffect(() => {
    setIsCustom(isCustomLimitType(limitType));
  }, [limitType]);

  return !expired && isCustom ? (
    <>
      <Button disabled={disabled} onClick={handleClick} variant="outlined">
        Remove Custom Limits
      </Button>
      {alertOpen && (
        <Alert
          confirmLabel="Remove"
          disableBackdropClick
          isBusy={isBusy}
          onClose={handleClose}
          onConfirm={handleRemove}
          open={alertOpen}
          text="By choosing 'remove', the Payer limit will return to the default values and the custom limit will no longer apply"
          title={`Remove Custom Limits for ${profile.label}?`}
        />
      )}
    </>
  ) : null;
};

LimitsCardHeaderActionsRemove.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default LimitsCardHeaderActionsRemove;
