import PropTypes from 'prop-types';
import { isNilOrEmpty } from 'util/index';
import { TextFieldNumeric as CommonTextFieldNumeric } from 'components/common/fields';
import { useField, useFormikContext } from 'formik';
import { always } from 'ramda';
import { getDisabled, getValue, getValueAsNumberOrString } from '../consts';

const TextFieldNumeric = ({
  autoComplete,
  disabled,
  helperText: helperTextProp,
  inputProps,
  name,
  onChange,
  required,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const formikContext = useFormikContext();
  const helperText =
    helperTextProp || (meta?.touched && meta?.error ? meta.error : '');

  const error = meta?.touched && !isNilOrEmpty(meta?.error);
  const value = getValue(field);

  const handleChange = (event) => {
    const updatedEvent = { ...event };
    const value = getValueAsNumberOrString(event, inputProps ?? {});

    if (value !== undefined) {
      updatedEvent.target.value = value;

      helpers.setValue(value);
      onChange(updatedEvent);
    }
  };

  return (
    <CommonTextFieldNumeric
      {...props}
      autoComplete={autoComplete}
      disabled={getDisabled(disabled, field, meta, formikContext)}
      error={error}
      helperText={helperText}
      inputProps={inputProps}
      name={name}
      onBlur={field.onBlur}
      onChange={handleChange}
      required={required}
      value={value}
    />
  );
};

TextFieldNumeric.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

TextFieldNumeric.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  helperText: undefined,
  inputProps: null,
  onChange: always,
  required: false,
};

export default TextFieldNumeric;
