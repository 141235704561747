import { attr, fk, Model } from 'redux-orm';
import Payer from 'model/payer';
import {
  createAction,
  createLoadConst,
  createUpdateConst,
  createRequestConst,
} from '../util';

export default class ExternalAccount extends Model {
  static get modelName() {
    return 'ExternalAccount';
  }

  static reducer({ type, data }, ExternalAccount, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).externalAccounts.delete();
        }
        if (data.response) {
          data.response.forEach((r) => ExternalAccount.upsert(r));
        }
        break;

      case LOAD_ID.SUCCESS:
        ExternalAccount.upsert(data.response);
        break;

      case UPDATE.SUCCESS:
        ExternalAccount.withId(data.response.id).update(data.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      accountNbrMask: attr(),
      accountSuffix: attr(),
      achRtn: attr(),
      createdAt: attr(),
      ddaType: attr(),
      ddaTypeDerived: attr(), // Derived
      description: attr(),
      descriptionDerived: attr(), // Derived
      externalDdaIdStr: attr(),
      externalDdaIdStrDerived: attr(), // Derived
      externalDdaStatus: attr(),
      externalDdaStatusAt: attr(),
      externalDdaStatusAtLabel: attr(), // Derived
      externalDdaStatusText: attr(),
      fsp: attr(),
      fspId: attr(),
      href: attr(),
      iavAccessToken: attr(),
      iavAccountId: attr(),
      iavProvider: attr(),
      id: attr(),
      insUserId: attr(),
      microService: attr(),
      nickName: attr(),
      offset: attr(),
      payer: attr(),
      payerId: fk(Payer.modelName, 'externalAccounts'),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createLoadConst(ExternalAccount.modelName);
export const LOAD_ID = createRequestConst(`${ExternalAccount.modelName}_id`);
export const UPDATE = createUpdateConst(ExternalAccount.modelName);

export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const update = createAction(UPDATE);
