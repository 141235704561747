import PropTypes from 'prop-types';
import { memo, useState, useEffect } from 'react';
import { always } from 'ramda';
import { Button } from '@material-ui/core';
import { isLoadingState } from 'reducers/loading';
import { usePageSettings } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { update as updatePageSetting } from 'model/pageSetting';

const currentButtonProps = {
  colorCurrent: 'primary',
  colorDeleted: 'default',
  variantCurrent: 'contained',
  variantDeleted: 'text',
};
const deletedButtonProps = {
  colorCurrent: 'default',
  colorDeleted: 'primary',
  variantCurrent: 'text',
  variantDeleted: 'contained',
};

const ToggleCurrentDeleted = ({ action, onChange, settingId, settingType }) => {
  const [buttonProps, setButtonProps] = useState(currentButtonProps);
  const dispatch = useDispatch();
  const { deletedOnly } = usePageSettings(settingType, settingId || 0);
  const isLoading = useSelector((state) =>
    isLoadingState(state, action.ACTION)
  );

  const updateSetting = (deletedOnly) => {
    const data = {
      payerId: settingId || 0,
      type: settingType,
      deletedOnly,
      subSearchBy: null,
    };

    dispatch(updatePageSetting(data));
  };

  const handleCurrent = (event) => {
    event.stopPropagation();
    updateSetting(false);
    onChange(event, false);
  };
  const handleDeleted = (event) => {
    event.stopPropagation();
    updateSetting(true);
    onChange(event, true);
  };

  useEffect(() => {
    setButtonProps(deletedOnly ? deletedButtonProps : currentButtonProps);
  }, [deletedOnly]);

  return (
    <>
      <Button
        aria-label="Show current"
        color={buttonProps.colorCurrent}
        disabled={isLoading}
        onClick={handleCurrent}
        role="button"
        variant={buttonProps.variantCurrent}
      >
        Current
      </Button>
      <Button
        aria-label="Show deleted"
        color={buttonProps.colorDeleted}
        disabled={isLoading}
        onClick={handleDeleted}
        role="button"
        variant={buttonProps.variantDeleted}
      >
        Deleted
      </Button>
    </>
  );
};

ToggleCurrentDeleted.propTypes = {
  action: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  settingId: PropTypes.number,
  settingType: PropTypes.string.isRequired,
};

ToggleCurrentDeleted.defaultProps = {
  onChange: always,
  settingId: 0,
};

export default memo(ToggleCurrentDeleted);
