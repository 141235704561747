import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class EBillernet extends Model {
  static get modelName() {
    return 'EBillernet';
  }

  static reducer({ type, data }, EBillernet) {
    switch (type) {
      case LOAD.SUCCESS:
        (data?.response || []).forEach((eBillernet) => {
          EBillernet.upsert(eBillernet);
        });
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      name: attr(),
      offset: attr(),
      reRoute: attr(),
      status: attr(),
      updatedAt: attr(),
      updUserId: attr(),
    };
  }
}

const name = EBillernet.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
