import { GET } from 'consts';
import { client } from 'util/sdk';

export const create = (data, auth) =>
  client.channels.create(data, { adminUserId: auth.id });

export const loadId = (id) => client.channels(id).get();

export const load = (params) =>
  client.executeRequest(GET, 'channels', {
    queryParams: params,
    returnModel: { name: 'channel' },
  });

export const remove = ({ id }, auth) =>
  client.channels(id).delete({ adminUserId: auth.id });

export const update = ({ description, id, name }, auth) =>
  client.channels(id).update({ description, name }, { adminUserId: auth.id });
