import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';

const StartAdornment = () => {
  return (
    <InputAdornment position="start">
      <Search fontSize="small" />
    </InputAdornment>
  );
};

export default StartAdornment;
