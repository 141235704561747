import { Model, attr } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import { addDerived } from './util/fspPayerFraudOverride';

export default class FSPPayerFraudOverride extends Model {
  static get modelName() {
    return 'FSPPayerFraudOverride';
  }

  static reducer({ type, data }, FSPPayerFraudOverride) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        FSPPayerFraudOverride.create(addDerived(data?.response));
        break;

      case CHECK.ACTION: {
        const item = FSPPayerFraudOverride.withId(data.id);

        if (item) {
          item.selected = !item.selected;

          item.update(item);
        }
        break;
      }

      case CHECK_ALL.ACTION:
        FSPPayerFraudOverride.update({ selected: data.selected });
        break;

      case LOAD.SUCCESS:
      case IMPORT.SUCCESS:
        FSPPayerFraudOverride.delete();
        data?.response?.forEach((r) =>
          FSPPayerFraudOverride.create(addDerived(r))
        );
        break;

      case LOAD.FAILURE:
        FSPPayerFraudOverride.delete();
        break;

      case REMOVE.SUCCESS:
        if (FSPPayerFraudOverride.idExists(id)) {
          FSPPayerFraudOverride.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        FSPPayerFraudOverride.upsert(addDerived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      createdAtDerived: attr(), // derived
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      maximumTransactionAmount: attr(),
      maximumTransactionAmountDerived: attr(), // derived
      offset: attr(),
      overrideExpiresAt: attr(),
      overrideExpiresAtDerived: attr(), // derived
      payerId: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(), // derived
    };
  }
}

const name = FSPPayerFraudOverride.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const IMPORT = createImportConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const importAction = createAction(IMPORT);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
