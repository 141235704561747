import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@material-ui/core';
import AddButton from './EndAdornmentAddButton';
import ResetButton from './EndAdornmentResetButton';

const EndAdornment = ({ onAdd, onReset, show, showReset, ...props }) => {
  return show ? (
    <InputAdornment position="end">
      <Grid alignItems="center" container wrap="nowrap">
        {showReset && (
          <Grid item>
            <ResetButton onClick={onReset} {...props} />
          </Grid>
        )}
        <Grid item>
          <AddButton onClick={onAdd} {...props} />
        </Grid>
      </Grid>
    </InputAdornment>
  ) : null;
};

EndAdornment.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  showReset: PropTypes.bool.isRequired,
};

export default EndAdornment;
