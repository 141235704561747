import { client, formatQueryParams } from 'util/sdk';
import { GET, POST } from 'util/api';
import { evolve, pick } from 'ramda';
import {
  DAILY_LIMITS,
  DESCRIPTION_CODE,
  FSPS,
  IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS,
  LIMIT_RULE_CODE,
  PAYER_LIMITS_CODE,
  MAXIMUM_TRANSACTION,
  MINIMUM_TRANSACTION,
  MONTHLY_LIMITS,
  RESET_LIMIT_RULES,
  WEEKLY_LIMITS,
} from 'consts';
import { convertToNumberEmptyAsNull } from 'util/general';

const props = [
  DESCRIPTION_CODE,
  DAILY_LIMITS,
  IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS,
  MAXIMUM_TRANSACTION,
  MINIMUM_TRANSACTION,
  MONTHLY_LIMITS,
  WEEKLY_LIMITS,
];
const returnModel = { name: LIMIT_RULE_CODE };
const returnModelPayerLimts = { name: PAYER_LIMITS_CODE };

const importURL = (fspId) => `/${FSPS}/${fspId}/${RESET_LIMIT_RULES}`;

const common = {
  billPayAmount: convertToNumberEmptyAsNull,
  p2pAmount: convertToNumberEmptyAsNull,
  transferFromAmount: convertToNumberEmptyAsNull,
  transferToAmount: convertToNumberEmptyAsNull,
  palDdaAmount: convertToNumberEmptyAsNull,
  palExternalDdaAmount: convertToNumberEmptyAsNull,
  palExternalCardAmount: convertToNumberEmptyAsNull,
};

const transformation = {
  dailyLimits: {
    amount: convertToNumberEmptyAsNull,
    transferAmount: convertToNumberEmptyAsNull,
    ...common,
  },
  maximumTransaction: {
    transferAmount: convertToNumberEmptyAsNull,
    ...common,
  },
  minimumTransaction: {
    transferAmount: convertToNumberEmptyAsNull,
    ...common,
  },
  monthlyLimits: {
    amount: convertToNumberEmptyAsNull,
    transferAmount: convertToNumberEmptyAsNull,
    ...common,
  },
  weeklyLimits: {
    amount: convertToNumberEmptyAsNull,
    transferAmount: convertToNumberEmptyAsNull,
    ...common,
  },
};

const transform = (data) => {
  return evolve(transformation, data);
};

export const add = ({ fspId, id, ...data }, auth) =>
  client
    .fsps(fspId)
    .limits(id)
    .limitRules.create(pick(props, transform(data)), {
      adminUserId: auth.id,
    });

export const addCustom = ({ fspId, payerId, limitRule }, auth) => {
  return client.executeRequest(
    POST,
    `/fsps/${fspId}/payers/${payerId}/customlimitrules`,
    {
      data: transform(limitRule),
      queryParams: { adminUserId: auth.id },
      returnModel,
    }
  );
};

export const importAndReset = ({ fspId, limitrule }, auth) =>
  client.executeRequest(POST, importURL(fspId), {
    data: transform(limitrule),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const load = async ({ fspId, id, payerId, ...params }) => {
  return client.executeRequest(
    GET,
    `/fsps/${fspId}/payers/${payerId}/payerlimits`,
    {
      queryParams: { ...params },
      returnModel: returnModelPayerLimts,
    }
  );
};

export const remove = ({ fspId, id }, auth) =>
  client.fsps(fspId).limitRules(id).delete({ adminUserId: auth.id });

export const removeCustom = ({ fspId, payerId }, auth) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .customLimitRules.reset({ adminUserId: auth.id });

export const updateCustom = ({ fspId, id }, auth) =>
  client.fsps(fspId).limitRules(id).update({ adminUserId: auth.id });

export const update = ({ fspId, id, ...data }, auth) =>
  client
    .fsps(fspId)
    .limitRules(id)
    .update(pick(props, transform(data)), {
      adminUserId: auth.id,
    });
