import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class Billernet extends Model {
  static get modelName() {
    return 'Billernet';
  }

  static reducer({ type, data }, Billernet) {
    switch (type) {
      case LOAD.SUCCESS:
        (data?.response || []).forEach((billernet) => {
          Billernet.upsert(billernet);
        });
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      billerNetStatus: attr(),
      createdAt: attr(),
      electOrCheck: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      rerouteOffOn: attr(),
      updatedAt: attr(),
    };
  }
}

const name = Billernet.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
