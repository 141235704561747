import moment from 'moment';
import { dateAndTime, formatCurrency } from '../../util';
import { dateToMoment } from '../../util/dateAndTime';

const format = 'YYYY-MM-DD hh:mm A';

// eslint-disable-next-line import/prefer-default-export
export const addDerived = (r) => {
  return {
    ...r,
    createdAtDerived: dateAndTime(r.createdAt),
    isEmpty: false,
    maximumTransactionAmount: r.maximumTransactionAmount.toFixed(2),
    maximumTransactionAmountDerived: formatCurrency(r.maximumTransactionAmount),
    overrideExpiresAt: dateToMoment(r.overrideExpiresAt),
    overrideExpiresAtDerived: moment
      .parseZone(r.overrideExpiresAt)
      .format(format),
    updatedAtDerived: dateAndTime(r.updatedAt),
  };
};
