import { IS_EMPTY } from 'consts';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { omit } from 'ramda';
import { orm } from 'model';

const omittedFields = [
  'adminOrg',
  'createdAt',
  'href',
  'isEmpty',
  'lastLoginAt',
];

export const selectorOrm = createSelector(orm.UserProfile);

const userProfileSelector = ({ UserProfile }, id) =>
  UserProfile.idExists(id)
    ? UserProfile.withId(id)
    : UserProfile.upsert({ id: id || 0 });

const selectorModel = createSelector(
  orm,
  ({ authToken = {} }) => authToken.id,
  userProfileSelector
);

const selectorModelIdAsProp = createSelector(
  orm,
  (state, props) => props,
  userProfileSelector
);

export const selector = createReselectSelector(selectorModel, (item) => {
  const { firstName, lastName, ref } = item;
  let profile = {};

  if (!ref.isEmpty) {
    const { groups } = ref;

    profile = omit(omittedFields, ref);

    profile.groupIds = groups.map(({ id }) => id);
    profile.name = `${firstName} ${lastName}`;
  }

  return profile;
});

export const selectorAllFields = createReselectSelector(
  selectorModelIdAsProp,
  (item) => {
    if (!item[IS_EMPTY]) {
      const { firstName, groups, lastName } = item;

      item.set(
        'groupIds',
        groups.map(({ id }) => id)
      );
      item.set('name', `${firstName} ${lastName}`);
    }

    return item.ref;
  }
);
