import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import {
  otpPanel,
  passwordPanel,
  resetPasswordPanel,
  postResetPasswordPanel,
} from './consts';

const useStyles = makeStyles(() => ({
  tab: {
    display: 'none',
  },
}));

const LoginTabs = ({ activePanelId }) => {
  const classes = useStyles();

  return (
    <Tabs
      classes={{ root: classes.tab }}
      value={activePanelId}
      variant="fullWidth"
    >
      <Tab aria-controls={otpPanel.label} id={otpPanel.id} />
      <Tab aria-controls={passwordPanel.label} id={passwordPanel.id} />
      <Tab
        aria-controls={resetPasswordPanel.label}
        id={resetPasswordPanel.id}
      />
      <Tab
        aria-controls={postResetPasswordPanel.label}
        id={postResetPasswordPanel.id}
      />
    </Tabs>
  );
};

LoginTabs.propTypes = {
  activePanelId: PropTypes.number.isRequired,
};

export default LoginTabs;
