import {
  alive,
  keepAlive,
  login,
  logout,
  reset,
  update,
  userAfterRefresh,
  validate,
  verifyUser,
} from 'sdk/security';
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import { callAction, getError } from 'util/saga';
import {
  CHECK_VERSION_EVERY_HOUR,
  KEEP_ALIVE,
  LOGIN,
  LOGOUT,
  UPDATE,
  USER_AFTER_REFRESH,
} from 'reducers/authToken';
import {
  HTTP_STATUS_CODE_426,
  OTP_CANNOT_SEND,
  OTP_REQUIRED_ERROR,
} from 'consts';
import { LOAD } from 'model/userProfile';
import { RESET } from 'reducers/reset';
import { VALIDATE } from 'reducers/validate';
import { VERIFY } from 'reducers/verify';
import { getAuth } from 'util/index';

const NEW_CODE_MSG = 'A new code was emailed to ';
const LOCKED_MSG = 'Too many new codes requested, your account has been locked';
export const KEEP_ALIVE_INTERVAL = 60 * 1000; // 1 minute

const formatErrorMessage = (error, { sendOTP, username }) => {
  // Requests for new OTP codes are 400 errors
  const errorNumber = error.response?.data?.error?.number;

  if (sendOTP && errorNumber === OTP_REQUIRED_ERROR) {
    return `${NEW_CODE_MSG}${username}`;
  }

  if (sendOTP && errorNumber === OTP_CANNOT_SEND) {
    return { error: true, message: LOCKED_MSG };
  }

  return '';
};
const formatSuccessMessage = () => 'Your password was changed';

// Generator functions
function* checkVersionUsingAlive() {
  try {
    yield delay(5000);
    const result = yield call(alive);
    yield put({ data: result, type: CHECK_VERSION_EVERY_HOUR.SUCCESS });
    yield delay(KEEP_ALIVE_INTERVAL * 60);
    yield put({ type: CHECK_VERSION_EVERY_HOUR.ACTION });
  } catch (e) {
    if (e?.status === HTTP_STATUS_CODE_426) {
      yield put({
        error: getError(e),
        type: CHECK_VERSION_EVERY_HOUR.FAILURE,
      });
    }
  }
}
function* loginAndPopulateUserProfile(action) {
  const loginAction = callAction({
    api: login,
    formatErrorMessage,
    type: LOGIN,
  });
  const data = yield loginAction(action);

  if (data) {
    yield put({
      type: LOAD.SUCCESS,
      data,
    });
  }
}
function* keepAliveWorker(action) {
  const auth = yield select(getAuth);

  // triple check that the user is still authenticated before making the request
  if (auth.isAuthenticated) {
    const keepAliveAction = callAction({
      api: keepAlive,
      type: KEEP_ALIVE,
    });

    yield keepAliveAction(action);
  }
}

export default function* saga() {
  yield throttle(KEEP_ALIVE_INTERVAL, KEEP_ALIVE.ACTION, keepAliveWorker);

  yield takeEvery(LOGIN.ACTION, loginAndPopulateUserProfile);

  yield takeEvery(
    LOGOUT.ACTION,
    callAction({
      api: logout,
      type: LOGOUT,
    })
  );

  yield takeEvery(
    RESET.ACTION,
    callAction({
      api: reset,
      type: RESET,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: 'adminUser',
      formatSuccessMessage,
      type: UPDATE,
    })
  );

  yield takeEvery(
    USER_AFTER_REFRESH.ACTION,
    callAction({
      api: userAfterRefresh,
      dataName: 'adminUser',
      type: USER_AFTER_REFRESH,
    })
  );

  yield takeEvery(
    VALIDATE.ACTION,
    callAction({
      api: validate,
      type: VALIDATE,
    })
  );

  yield takeEvery(
    VERIFY.ACTION,
    callAction({
      api: verifyUser,
      type: VERIFY,
    })
  );

  yield takeLatest(CHECK_VERSION_EVERY_HOUR.ACTION, checkVersionUsingAlive);
}
