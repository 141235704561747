import { client } from 'util/sdk';
import { GET } from 'util/api';
import { REASON_CODE, REASONS_CODE } from 'consts';

const returnModel = { name: REASON_CODE };

const loadURL = `/${REASONS_CODE}`;

// eslint-disable-next-line import/prefer-default-export
export const load = () => {
  return client.executeRequest(GET, loadURL, { returnModel });
};
