import PropTypes from 'prop-types';
import { CardContent, Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { getZipForDisplay } from 'util/address';

const CardAddressContent = ({ title, uspsAddress }) => {
  const { city, state, street1, street2, zip } = uspsAddress;

  return (
    <CardContent>
      <Grid container direction="column">
        <Grid item xs={12}>
          <ReadOnlyFormControl label="Name" value={title} />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <ReadOnlyFormControl label="Street" value={street1} />
            </Grid>
            {street2 && (
              <Grid item xs={4}>
                <ReadOnlyFormControl label="Street 2" value={street2} />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <ReadOnlyFormControl label="City" value={city} />
            </Grid>
            <Grid item xs={street2 ? 4 : 2}>
              <ReadOnlyFormControl label="State" value={state} />
            </Grid>
            <Grid item xs>
              <ReadOnlyFormControl
                label="ZIP Code"
                value={getZipForDisplay(zip)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  );
};

CardAddressContent.propTypes = {
  title: PropTypes.string.isRequired,
  uspsAddress: PropTypes.object,
};

CardAddressContent.defaultProps = {
  uspsAddress: {},
};

export default CardAddressContent;
