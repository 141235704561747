import Renderer from './DetailsRender';
import MaskFSPUserId from './MaskFSPUserId';
import StatusSelector from './StatusSelector';

export { defaultOrderBy, statusConfig, statusList } from './consts';
export { default as ActionsRenderer } from './ActionsRenderer';

export const detailsRender = (props) => <Renderer {...props} />;

export const columnMeta = [
  {
    context: true,
    id: 'userFSPUserIdMaskDerived',
    label: 'FSP User ID',
    xs: 2,
    renderer: (value, item, icon, props) => (
      <MaskFSPUserId icon={icon} item={item} value={value} {...props} />
    ),
  },
  {
    id: 'userFirstNameDerived',
    label: 'First Name',
    // Turn back on after ON-1109
    // sortId: 'firstName',
    xs: 2,
  },
  {
    id: 'userLastNameDerived',
    label: 'Last Name',
    // Turn back on after ON-1109
    // sortId: 'lastName',
    xs: 2,
  },
  {
    capitalize: true,
    id: 'role',
    label: 'Role',
    // Turn back on after ON-1109
    // sortId: 'role',
    xs: 2,
  },
  {
    capitalize: true,
    id: 'status',
    // Turn back on after ON-1109
    // sortId: 'status',
    label: 'Status',
    renderer: (status, props) => (
      <StatusSelector currentStatus={status} {...props} />
    ),
    xs: 2,
  },
  {
    id: 'email',
    label: 'Email',
    // Turn back on after ON-1109
    // sortId: 'email',
    xs: 2,
  },
];
