import { BOTH_CODE, FSPS, GET } from 'consts';
import { client } from 'util/sdk';

// eslint-disable-next-line import/prefer-default-export
export const load = ({ fspId, type }) =>
  client.executeRequest(GET, `/${FSPS}/${fspId}/ddamasks`, {
    queryParams: { ddamasktype: type ?? BOTH_CODE },
    returnModel: {
      name: 'ddamask',
    },
  });
