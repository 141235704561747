import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BrowserRouter, Switch } from 'react-router-dom';
import {
  CssBaseline,
  ThemeProvider as ThemeProviderV4,
} from '@material-ui/core';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import {
  SnackbarError,
  SnackbarOffline,
  SnackbarSuccess,
} from 'components/Snackbars';
import ErrorBoundary from './ErrorBoundary';
import RootRoute from './RootRoute';
import rootRoutes from './rootRoutes';
import RouteTracker from './RouteTracker';
import { themeV4, themeV5 } from './theme';

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeProviderV4 theme={themeV4}>
        <CssBaseline />
        <SnackbarError />
        <SnackbarOffline />
        <SnackbarSuccess />
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <RouteTracker>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProviderV5 theme={themeV5}>
                  <Switch>{rootRoutes.map(RootRoute)}</Switch>
                </ThemeProviderV5>
              </LocalizationProvider>
            </RouteTracker>
          </QueryParamProvider>
        </BrowserRouter>
      </ThemeProviderV4>
    </ErrorBoundary>
  );
};

export default App;
