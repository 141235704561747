/* eslint-disable react/display-name */
const allowAll = () => true;

const ACTIVE_TO_INACTIVE_MSG =
  ({ payerLabel }) =>
  () => {
    return (
      <>
        This will prevent from having access to scheduled payments, payment
        history, billers, etc for the &quot;{payerLabel}&quot; profile.
      </>
    );
  };

const INACTIVE_TO_ACTIVE_MSG =
  ({ payerLabel }) =>
  () => {
    return (
      <>
        This will allow to have access to scheduled payments, payment history,
        billers, etc for the &quot;{payerLabel}&quot; profile.
      </>
    );
  };

export const defaultOrderBy = 'id';

export const statusConfig = {
  active: {
    inactive: {
      label: 'Inactivate',
      message: ACTIVE_TO_INACTIVE_MSG,
      requiredPermission: allowAll,
    },
  },
  inactive: {
    active: {
      label: 'Activate',
      message: INACTIVE_TO_ACTIVE_MSG,
      requiredPermission: allowAll,
    },
  },
};

export const statusList = [
  { key: 'payerUserActive', name: 'Active', value: 'active' },
  { key: 'payerUserInactive', name: 'Inactive', value: 'inactive' },
];
