import { createAction, createLoadConst } from 'util/actions';

const initialState = null;
const name = 'transactAs';

export const TRANSACT_AS_PAYER = createLoadConst(`TRANSACT_AS_PAYER`);
export const SAVE_TRANSACT_AS_PAYER = createLoadConst(`SAVE_TRANSACT_AS_PAYER`);

export const transactAsPayer = createAction(TRANSACT_AS_PAYER);
export const SAVE_TRANSACT_AS_DATA = 'SAVE_TRANSACT_AS_DATA';
export const saveTransactAsData = (registerData, data) => ({
  type: SAVE_TRANSACT_AS_DATA,
  registerData,
  data,
});

export const CLEAR_TRANSACT_AS_DATA = 'CLEAR_TRANSACT_AS_DATA';
export const clearTransactAsData = () => ({
  type: CLEAR_TRANSACT_AS_DATA,
});

export const selector = (state) => state[name];

export default (state = initialState, { type, ...data }) => {
  switch (type) {
    case TRANSACT_AS_PAYER.BUSY:
      return { ...state, success: false };

    case TRANSACT_AS_PAYER.SUCCESS:
      return { ...state, success: true };

    case SAVE_TRANSACT_AS_DATA:
      return { ...state, ...data };

    case TRANSACT_AS_PAYER.FAILURE:
      return { error: 'ssoClientUrl Error' };

    case CLEAR_TRANSACT_AS_DATA:
      return initialState;

    default:
      return state;
  }
};
