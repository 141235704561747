import PropTypes from 'prop-types';

const RenderOptionPart = ({ highlight, text }) => {
  return <span style={{ fontWeight: highlight ? 600 : 400 }}>{text}</span>;
};

RenderOptionPart.propTypes = {
  highlight: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

RenderOptionPart.defaultProps = {
  text: '',
};

export default RenderOptionPart;
