import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import { isP2P } from '../consts';

const DeliveryType = ({ transaction }) => {
  return isP2P(transaction.transactionType) ? (
    <Grid item xs={2}>
      <ReadOnlyFormControl
        label="Delivery Type"
        value={transaction.disburseTypeDerived}
      />
    </Grid>
  ) : null;
};

DeliveryType.propTypes = {
  transaction: PropTypes.shape({
    disburseTypeDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};

export default DeliveryType;
