import { useCallback, useEffect, useState, useMemo } from 'react';
import { defaultPayerId } from 'model/pageSetting';
import { usePageSettings } from 'hooks';

const useToggleCurrentDeleted = (
  name = '',
  payerId = defaultPayerId,
  defaultPageSettings = {}
) => {
  const { deletedOnly } = usePageSettings(name, payerId, defaultPageSettings);
  const [showDeleted, setShowDeleted] = useState(false);

  const handleChange = useCallback(
    (event, isDeletedAction) => {
      setShowDeleted(isDeletedAction);
    },
    [setShowDeleted]
  );

  useEffect(() => {
    setShowDeleted(deletedOnly === true);
  }, [deletedOnly]);

  return useMemo(() => {
    return [showDeleted, handleChange];
  }, [showDeleted, handleChange]);
};

export default useToggleCurrentDeleted;
