import PropTypes from 'prop-types';
import {
  ROUTE_CHANGE,
  addMetric,
  currentTime,
  timeDiff,
} from 'reducers/metrics';
import { useDispatch } from 'react-redux';
import { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * ToDo: This methodology also tracks anytime we redirect to a default route,
 *       it is a little flawed, so we will need another way of tracking
 */
const RouteTracker = ({ children }) => {
  const [previousPathname, setPreviousPathname] = useState();
  const [startTime, setStartTime] = useState();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setStartTime(currentTime());
    setPreviousPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (
      startTime &&
      previousPathname &&
      location.pathname !== previousPathname
    ) {
      const endTime = currentTime();
      const duration = timeDiff(startTime, endTime);

      dispatch(
        addMetric({
          duration,
          endTime,
          route: location.pathname,
          startTime,
          type: ROUTE_CHANGE,
        })
      );
    }
  }, [dispatch, location.pathname, previousPathname, startTime]);

  return children;
};

RouteTracker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(RouteTracker);
