import { client, formatQueryParams } from 'util/sdk';

export const load = ({ fspId, ...params }) =>
  client
    .fsps(fspId)
    .autopayScheduledTransactions.query(formatQueryParams(params));

export const loadBySchedId = ({ fspId, schedId }) =>
  client
    .fsps(fspId)
    .scheduledTransactions(schedId)
    .autopayScheduledTransactions.query(() => []);
