import { NA_VALUE } from 'consts';
import { defaultTo, replace } from 'ramda';
import { isNilOrEmpty } from './consts';

const currencyCode = 'USD';
const LOCALE = 'en-US';
const currencyOptions = {
  currency: currencyCode,
  style: 'currency',
};
const currencyNoSymbolOptions = {
  minimumFractionDigits: 2,
  style: 'decimal',
};

export const SCROLL_DELAY = 200;
export const SCROLL_OPTIONS = { align: { top: 0.13 } };
export const SCROLL_REF_KEY = '__ref';

export const formatPhone = (phone, defaultValue) =>
  replace(
    /^\(?\s?(\d{3})\s?\)?\s?-?\s?(\d{3})\s?-?\s?(\d{4})$/,
    '($1) $2-$3',
    defaultTo(defaultValue || '', phone)
  );

export const formatSSN = (ssn) =>
  replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3', defaultTo('', ssn));

export const formatSSNMask = (ssn) =>
  replace(/^(\d{4})$/, '$1', defaultTo('', ssn));

export const formatTaxId = (taxId) =>
  replace(/^(\d{2})(\d{7})$/, '$1-$2', defaultTo('', taxId));

export const decimal = (value, emptyAsZero = true) => {
  let decimalValue = value === '' && emptyAsZero ? 0 : value;

  if (typeof value === 'string' && value !== '') {
    decimalValue = Number.parseFloat(value.replace(/[,$]/g, ''));
  }

  return decimalValue;
};

export const formatCurrency = (
  amount,
  nilLabel = NA_VALUE,
  options = currencyOptions,
  emptyAsZero = true
) => {
  let currency = nilLabel;
  let value = amount;

  if (typeof value === 'string') {
    // We need the value as Number to use .toLocaleString()
    value = decimal(value, emptyAsZero);
  }

  if (!isNilOrEmpty(value)) {
    currency = value.toLocaleString(LOCALE, options);
  }

  return currency;
};

export const formatURI = (value) => {
  if (typeof value === 'string') {
    return value.replace(/[^a-z0-9-_.~]/gi, '');
  }

  return value;
};

export const formatGetPhoneDigits = (value) => {
  if (typeof value === 'string') {
    return value.replace(/[^\d]+/gi, '');
  }

  return value;
};

export const currencyNoSymbol = (
  amount,
  nilLabel = NA_VALUE,
  emptyAsZero = true
) => formatCurrency(amount, nilLabel, currencyNoSymbolOptions, emptyAsZero);

export const currencyNoSymbolNoNA =
  (emptyAsZero = false) =>
  (amount) =>
    currencyNoSymbol(amount, '', emptyAsZero);

export const cents = (currency = 0) => {
  let retValue = '00';
  let stringValue = currency;

  if (typeof stringValue === 'number') {
    stringValue = stringValue.toString();
  }

  if (stringValue.includes('.')) {
    const digits = stringValue.split('.')[1];
    let twoDigits = digits.slice(0, 2);

    if (twoDigits.length === 0) {
      twoDigits = retValue;
    } else if (twoDigits.length === 1) {
      twoDigits = `${twoDigits}0`;
    }

    retValue = twoDigits;
  }

  return retValue;
};

export const trimmedDisplayValue = (value) => {
  // https://payrailz.atlassian.net/browse/ON-322
  const displayValue =
    value && typeof value === 'string' ? value.trim() : value;

  return displayValue || NA_VALUE;
};

export const getDdaTypeDerived = (ddaType) => {
  // Correct the display under the Account details panel for Account Type
  // https://payrailz.atlassian.net/browse/ON-145
  // https://payrailz.atlassian.net/browse/ON-337
  let retVal;

  switch (ddaType) {
    case 'moneymarket':
      retVal = 'Money Market';
      break;

    case 'creditcard':
      retVal = 'Credit Card';
      break;

    default:
      retVal = ddaType;
  }

  return retVal;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const convertToStartCase = (string) => {
  if (!string || typeof string !== 'string') {
    return string; // should be null or undefined, need correct value for skeleton
  }
  return capitalizeFirstLetter(string.replace(/([A-Z]+)/g, ' $1'));
};
