import PropTypes from 'prop-types';
import { memo } from 'react';
import { LOAD_ID } from 'model/payee';
import { MailOutline } from '@material-ui/icons';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import BillersList, { settingType } from './BillersList';
import PayerDetailPanel from '../PayerDetailPanel';
import ToggleCurrentDeleted from '../ToggleCurrentDeleted';
import useToggleCurrentDeleted from '../useToggleCurrentDeleted';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';

const Billers = ({ fspId, payerId }) => {
  const [showDeleted, onHandleChange] = useToggleCurrentDeleted(
    settingType,
    payerId
  );

  const isBusy = useSelector((state) => selector(state, LOAD_ID));

  return (
    <PayerDetailPanel
      endAdornment={
        <ToggleCurrentDeleted
          action={LOAD_ID}
          onChange={onHandleChange}
          settingId={payerId}
          settingType={settingType}
        />
      }
      fspId={fspId}
      heightOffset={HEIGHT_OFFSET}
      icon={MailOutline}
      id="billers"
      payerId={payerId}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title="Billers"
    >
      <BillersList fspId={fspId} payerId={payerId} showDeleted={showDeleted} />
    </PayerDetailPanel>
  );
};

Billers.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(Billers);
