// This line must be the first import
// https://github.com/facebook/create-react-app/blob/master/CHANGELOG.md#polyfills-for-ie-9-ie-10-and-ie-11-are-no-longer-included-by-default-but-you-can-opt-in
import 'react-app-polyfill/ie11';
import 'srcdoc-polyfill';
import 'core-js';
import 'react-loading-skeleton/dist/skeleton.css';
import orm from 'model';
import { createReducer } from 'redux-orm';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import './wdyr';
import * as reducers from './reducers';
import App from './App';
import createPersistedStore from './configureStore';
import sagas from './sagas';
import { unregister } from './serviceWorker';

unregister();

const { persistor, store } = createPersistedStore({
  customOrmReducers: createReducer(orm),
  reducers,
  sagas,
});

const container = document.getElementById('payrailz-ontrac');
const root = createRoot(container);
const renderComponent = (Component = App) => {
  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Component />
      </PersistGate>
    </Provider>
  );
};

renderComponent();

if (module.hot) {
  // eslint-disable-next-line global-require
  module.hot.accept('./App', () => renderComponent(require('./App').default));
}
