import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import CurrencyTextFieldMasked from './CurrencyTextFieldMasked';
import CurrencyTextFieldStartAdornment from './CurrencyTextFieldStartAdornment';

const useStyles = makeStyles({
  currency: ({ align }) => ({
    textAlign: `${align}`,
  }),
});

const CurrencyTextField = ({
  align,
  disabled,
  InputProps,
  inputProps,
  value,
  ...props
}) => {
  const classes = useStyles({ align });

  const memoizedInputProps = useMemo(
    () => ({
      classes: {
        ...InputProps?.classes,
        input: classNames(classes.currency, {
          [InputProps?.classes?.input]:
            InputProps?.classes?.input !== undefined,
        }),
      },
      inputComponent: CurrencyTextFieldMasked,
      startAdornment: <CurrencyTextFieldStartAdornment disabled={disabled} />,
      ...InputProps,
    }),
    [InputProps, classes.currency, disabled]
  );

  const mergedInputProps = {
    'aria-label': props.label,
    ...inputProps,
  };

  return (
    <TextField
      {...props}
      autoComplete="off"
      disabled={disabled}
      inputProps={mergedInputProps}
      /* eslint-disable-next-line react/jsx-no-duplicate-props */
      InputProps={memoizedInputProps}
      value={value}
    />
  );
};

CurrencyTextField.propTypes = {
  align: PropTypes.string,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.number,
    PropTypes.string,
  ]),
};

CurrencyTextField.defaultProps = {
  align: 'left',
  disabled: false,
  inputProps: undefined,
  InputProps: undefined,
  label: undefined,
  value: '',
};

export default CurrencyTextField;
