import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  DELIVER_ELEC_NOW,
  DELIVER_ELEC_STD,
  DISBURSE_TYPE_P2P_DEBIT,
  DISBURSE_TYPE_P2P_PAYOUT,
  MANUAL_REFUND,
  PERSON_TO_PERSON,
  REFUNDED,
  REFUND_FAILED,
  REFUND_SENT,
  TRANSACTION_TYPE_BILL_PAY,
  isEqual,
} from 'consts';

const isDeliverElecNow = (deliver) => {
  return isEqual(deliver, DELIVER_ELEC_NOW);
};

const isDeliverElecStd = (deliver) => {
  return isEqual(deliver, DELIVER_ELEC_STD);
};

const isDisburseTypeP2pPayout = (disburseType) => {
  return isEqual(disburseType, DISBURSE_TYPE_P2P_PAYOUT);
};

/**
 * Created 07/22/2022 - https://payrailz.atlassian.net/browse/ON-1622
 *
 * Ravi asked that this is how we identify FI Direct transactions
 */
export const isFIDirect = (deliver, disburseType) => {
  return isDeliverElecNow(deliver) && isDisburseTypeP2pPayout(disburseType);
};

/**
 * Created 07/22/2022 - https://payrailz.atlassian.net/browse/ON-1622
 *
 * Ravi defined this check to see if <CardInfo /> should be shown
 */
export const isDisburseTypeDebit = (disburseType) => {
  return isEqual(disburseType, DISBURSE_TYPE_P2P_DEBIT);
};

export const isP2P = (transactionType) => {
  return isEqual(transactionType, PERSON_TO_PERSON);
};

/**
 * Updated 07/22/2022 - https://payrailz.atlassian.net/browse/ON-1622
 *
 * Ravi defined this check to see if <RDFIInfo /> should be shown
 */
export const showRDFIInfo = ({ deliver, disburseType, transactionType }) => {
  return (
    isP2P(transactionType) &&
    (isDeliverElecStd(deliver) || isFIDirect(deliver, disburseType))
  );
};

/**
 * Created 08/17/2022 - https://payrailz.atlassian.net/browse/ON-1652
 *
 * Denise defined this check to see if <DisburseState /> should be shown
 */
export const isRefund = ({ deliver, status, transactionType }) =>
  [
    ACCOUNT_TO_ACCOUNT_INBOUND,
    ACCOUNT_TO_ACCOUNT_NEW,
    ACCOUNT_TO_ACCOUNT_OUTBOUND,
    PERSON_TO_PERSON,
    TRANSACTION_TYPE_BILL_PAY,
  ].includes(transactionType) &&
  [DELIVER_ELEC_NOW, DELIVER_ELEC_STD].includes(deliver) &&
  [MANUAL_REFUND, REFUNDED, REFUND_FAILED, REFUND_SENT].includes(status);
