import PropTypes from 'prop-types';
import { memo } from 'react';
import { p2pDirectEnabledSelector as selector } from 'selectors/userFspConfig';
import { useSelector } from 'react-redux';
import ContactsDirect from './ContactsDirect';
import ContactsPanel from './ContactsPanel';

const Contacts = ({ fspId, payerId }) => {
  const enabled = useSelector((state) => selector(state, fspId, payerId));

  return enabled ? (
    <ContactsDirect fspId={fspId} payerId={payerId} />
  ) : (
    <ContactsPanel fspId={fspId} payerId={payerId} />
  );
};

Contacts.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(Contacts);
