import PropTypes from 'prop-types';
import { selector, selectorId } from 'selectors/payALoan';
import TransactAsButton from 'components/modules/service/PayerDetail/TransactAsButton';
import { Mask } from 'components/common';
import { Stack } from '@mui/material';
import { unmask } from 'sdk/internalAccount';
import { ReadOnlyFormControl } from 'components/common/fields';
import { filterByDDA, TRANSACTION_TYPE_PAL } from 'consts';
import { useSelector } from 'react-redux';
import { transactOnBehalfPalOnSelector } from 'selectors/userFspConfig';
import PayALoanAudit from '../PayALoanAudit';

const ActionsRenderer = ({ item }) => {
  const showTransactOnBehalf = useSelector((state) =>
    transactOnBehalfPalOnSelector(state, item?.fspId, item?.payerId)
  );
  const stmtItems = useSelector((state) => selector(state));

  if (item !== null && stmtItems !== null) {
    const mappedList = filterByDDA(item, stmtItems);
    if (mappedList.length > 0) {
      const newItems = { ...item, stmtId: mappedList[0].id };
      Object.assign(item, newItems);
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <ReadOnlyFormControl
        inputNode={
          <Mask
            api={() => unmask({ id: item.id, fspId: item.fspId })}
            value={item.accountAchNbrMask}
          />
        }
        label="Loan #"
        value={item.accountAchNbrMask}
      />
      {showTransactOnBehalf && (
        <item>
          <TransactAsButton
            loanId={item.id}
            payerId={item.payerId}
            transactionType={TRANSACTION_TYPE_PAL}
            fspIdValue={item.fspId}
          />
        </item>
      )}
      <item>
        <PayALoanAudit item={item} selectorId={selectorId} />
      </item>
    </Stack>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
