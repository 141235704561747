import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { unreadCountSelector } from 'selectors/actionableAlert';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  secondaryHeading: {
    color: theme.palette.secondary.dark,
    marginLeft: theme.spacing(1),
  },
}));

const ActionableAlertsStartAdornment = (props) => {
  const classes = useStyles();
  const unreadCount = useSelector((state) => unreadCountSelector(state, props));

  return (
    <Grid className={classes.secondaryHeading} container spacing={1}>
      <Grid item>
        <Typography variant="body2">({unreadCount}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">unread)</Typography>
      </Grid>
    </Grid>
  );
};

export default ActionableAlertsStartAdornment;
