import { attr, Model } from 'redux-orm';
import {
  createAddConst,
  createAction,
  createLoadConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

export const derived = (denyList) => {
  return {
    denyData: {},
    ...denyList,
    createdAtDerived: dateAndTime(denyList?.createdAt),
    updatedAtDerived: dateAndTime(denyList?.updatedAt),
  };
};

export default class FspDenyList extends Model {
  static get modelName() {
    return 'FspDenyList';
  }

  static reducer({ type, data }, FspDenyList) {
    const id = data?.id;

    switch (type) {
      case ADD.SUCCESS: {
        // The following block of code is to add the newly item to the top of the list
        const records = FspDenyList.all().toRefArray();
        FspDenyList.delete();
        FspDenyList.upsert(derived(data.response));
        records.forEach((r) => {
          FspDenyList.upsert(r);
        });
        break;
      }

      case CLEAR.ACTION:
        FspDenyList.delete();
        break;

      case CHECK_ALL.ACTION:
        FspDenyList.update({ selected: data.selected });
        break;

      case CHECK.ACTION: {
        const fspDenyList = FspDenyList.withId(data.id);

        if (fspDenyList) {
          fspDenyList.selected = !fspDenyList.selected;

          fspDenyList.update(fspDenyList);
        }
        break;
      }

      case LOAD.SUCCESS:
      case UPLOAD.SUCCESS:
        FspDenyList.delete();
        data?.response?.forEach((r) => {
          FspDenyList.upsert(derived(r));
        });
        break;

      case LOAD.FAILURE:
        FspDenyList.delete();
        break;

      case REMOVE.ACTION:
        if (FspDenyList.idExists(id)) {
          FspDenyList.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        FspDenyList.upsert(derived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      offset: attr(),
      href: attr(),
      id: attr(),
      fspId: attr(),
      denyOfferingId: attr(),
      denyOfferingName: attr(),
      denyData: attr(),
      fspDenyListId: attr(),
      createdAt: attr(),
      insUserId: attr(),
      insUser: attr(),
      insAdminUserId: attr(),
      updatedAt: attr(),
      updUserId: attr(),
      updUser: attr(),
      updAdminUserId: attr(),
      selected: attr(), // derived
      createdAtDerived: attr(), // derived
      updatedAtDerived: attr(), // derived
    };
  }
}

const name = FspDenyList.modelName;

export const ADD = createAddConst(name);
export const CLEAR = createRequestConst(`${name}_clear`);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);
export const UPLOAD = createLoadConst(`${name}_upload`);

export const add = createAction(ADD);
export const clear = createAction(CLEAR);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
export const upload = createAction(UPLOAD);
