import { createAction, createAddConst, createDeleteConst } from 'util/actions';
import { reject, split, includes } from 'ramda';

const NAME = 'switchPayeesSelected';

const defaultChecked = ['name', 'uspsAddress.zip'];
const makeLike = ['street1', 'street2', 'city', 'state'];

export const ADD = createAddConst(NAME);
export const REMOVE = createDeleteConst(NAME);

export const add = createAction(ADD);
export const remove = createAction(REMOVE);

export const selector = (state, key) => state[NAME].includes(key);

export const selectorParams = (state, payee) => {
  const params = {};

  state[NAME].forEach((key) => {
    const entries = split('.', key);
    let paramKey = key;
    let value = '';

    if (entries.length > 1) {
      value = payee.uspsAddress[entries[1]];
      paramKey = entries[1];
    } else {
      value = payee[key];
    }

    if (value) {
      const keyOrKeyLike =
        value.includes('%') || includes(paramKey, makeLike)
          ? `${paramKey}Like`
          : paramKey;

      params[keyOrKeyLike] = value;
    }
  });

  return params;
};

export default (state = defaultChecked, { data = '', type }) => {
  switch (type) {
    case ADD.ACTION:
      return [...state, data];

    case REMOVE.ACTION:
      return reject((entry) => entry === data, state);

    default:
      return state;
  }
};
