import { saga } from 'util/saga';
import {
  createRequestConst,
  createAddConst,
  createDeleteConst,
  createUpdateConst,
  createAction,
} from 'util/actions';

const GROUP_ADMIN = 'groupadmin';
export const LOAD = createRequestConst(GROUP_ADMIN);
export const ADD = createAddConst(GROUP_ADMIN);
export const DELETE = createDeleteConst(GROUP_ADMIN);
export const UPDATE = createUpdateConst(GROUP_ADMIN);

// Creating actions
export const loadGroupAdmins = createAction(LOAD);
export const addGroupAdmin = createAction(ADD);
export const deleteGroupAdmin = createAction(DELETE);
export const updateGroupAdmin = createAction(UPDATE);

// Selectors
export const getGroupAdmins = (state) => state.groupAdmins;

export default saga({
  LOAD,
  ADD,
  DELETE,
  UPDATE,
});
