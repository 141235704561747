import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import Select from 'components/common/fields/selectv2';
import classNames from 'classnames';
import { CancelButton, CheckButton } from 'components/common/icon';
import { Grid, IconButton } from '@material-ui/core';
import { NA_VALUE } from 'consts';
import { always, find, isNil, prop, propEq } from 'ramda';
import { isUpdatingState } from 'reducers/updating';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../AlertImproved';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 48,
  },
  rootNoOnChange: {
    paddingTop: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    font: 'inherit',
    fontFamily: theme.typography.fontFamily,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  select: {
    marginTop: theme.spacing(1),
    width: 'auto',
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    marginLeft: 2,
    visibility: 'visible',
  },
}));

const SelectionAction = ({
  enabled,
  onChange,
  onCheck,
  actionType,
  cancelLabel,
  confirmMessageRenderer,
  continueLabel,
  displayAttr,
  data,
  value,
  valueAttr,
}) => {
  const classes = useStyles();
  const [alertCancelLabel, setAlertCancelLabel] = useState(cancelLabel);
  const [alertContinueLabel, setAlertContinueLabel] = useState(continueLabel);
  const [alertMessage, setAlertMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [keepAlertOpen, setKeepAlertOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [originalValue, setOriginalValue] = useState(value);
  const [showAlert, setShowAlert] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const isUpdating = useSelector(
    (state) => !!actionType && isUpdatingState(state, actionType.ACTION)
  );

  const display = prop(
    displayAttr,
    find(propEq(valueAttr, updatedValue || value), data)
  );

  const handleEditOpen = (event) => {
    event.stopPropagation();
    setEditMode(true);
  };
  const handleEditClose = (event) => {
    event.stopPropagation();
    setEditMode(false);
    setUpdatedValue(originalValue);
  };
  const handleMenuOpen = () => setMenuOpen(true);
  const handleMenuClose = () => setMenuOpen(false);
  const handleMenuChange = (event) => {
    event.stopPropagation();
    setUpdatedValue(event.target.value);
  };
  const handleCheck = (event) => {
    event.stopPropagation();

    const updatedLabels = onCheck(event, updatedValue) || {};

    setAlertCancelLabel(
      updatedLabels.cancelLabel ? updatedLabels.cancelLabel : cancelLabel
    );

    setAlertContinueLabel(
      updatedLabels.continueLabel ? updatedLabels.continueLabel : continueLabel
    );

    setAlertMessage(() =>
      confirmMessageRenderer(find(propEq(valueAttr, updatedValue), data))
    );

    setShowAlert(true);
  };
  const handleAlertClose = (event) => {
    event.stopPropagation();
    setShowAlert(false);
  };
  const handleAlertConfirm = (event) => {
    event.stopPropagation();
    setShowAlert(false);
    setKeepAlertOpen(true);
    setEditMode(false);
    setOriginalValue(updatedValue);
    onChange(updatedValue);
  };

  useEffect(() => {
    if (isUpdating) {
      if (showAlert) {
        setKeepAlertOpen(true);
      }
    } else {
      setKeepAlertOpen(false);
    }
  }, [showAlert, isUpdating]);

  useEffect(() => {
    setUpdatedValue(value);
    setOriginalValue(value);
    setEditMode(false);
  }, [value]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootNoOnChange]: isNil(onChange) || !enabled,
      })}
    >
      {editMode ? (
        <>
          <Select
            classes={{ root: classes.select }}
            data={data}
            displayAttr={displayAttr}
            onChange={handleMenuChange}
            onClose={handleMenuClose}
            onOpen={handleMenuOpen}
            open={editMode}
            t-i="selectInSelectionAction"
            value={updatedValue}
            valueAttr={valueAttr}
          />
          {originalValue !== updatedValue && (
            <CheckButton
              color="primary"
              data-testid="check"
              onClick={handleCheck}
              t-i="check"
            />
          )}
          {!menuOpen && (
            <CancelButton
              data-testid="cancel"
              onClick={handleEditClose}
              t-i="cancel"
            />
          )}
        </>
      ) : (
        <Grid alignItems="center" container wrap="nowrap">
          <Grid item zeroMinWidth>
            <span
              className={classNames(classes.label, classes.root)}
              data-t-i="spanInSelectAction"
            >
              {display || NA_VALUE}
            </span>
          </Grid>
          <Grid item>
            {enabled && onChange && (
              <IconButton
                aria-label="edit mode icon"
                className={classNames(
                  { [classes.visible]: data.length > 1 && !editMode },
                  { [classes.hidden]: !(data.length > 1 && !editMode) }
                )}
                data-testid="edit"
                disabled={!display}
                onClick={handleEditOpen}
                role="button"
              >
                <Edit />
              </IconButton>
            )}
          </Grid>
        </Grid>
      )}
      <Alert
        actionType={actionType}
        cancelLabel={alertCancelLabel}
        confirmLabel={alertContinueLabel}
        content={alertMessage}
        onClose={handleAlertClose}
        onConfirm={handleAlertConfirm}
        open={showAlert || keepAlertOpen}
      />
    </div>
  );
};

SelectionAction.propTypes = {
  actionType: PropTypes.object,
  cancelLabel: PropTypes.string,
  confirmMessageRenderer: PropTypes.func,
  continueLabel: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  displayAttr: PropTypes.string,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCheck: PropTypes.func,
  value: PropTypes.string,
  valueAttr: PropTypes.string,
};

SelectionAction.defaultProps = {
  actionType: {},
  cancelLabel: 'Cancel',
  confirmMessageRenderer: always,
  continueLabel: 'OK',
  data: [],
  displayAttr: 'name',
  enabled: true,
  onChange: always,
  onCheck: () => ({}),
  value: '',
  valueAttr: 'value',
};

export default SelectionAction;
