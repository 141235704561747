import { client, formatQueryParams } from 'util/sdk';

export const create = (data, auth) =>
  client.billerAliases.create(data, { adminUserId: auth.id });

export const load = (data) =>
  client.billerAliases.query(formatQueryParams(data));

export const remove = ({ id }, auth) =>
  client.billerAliases(id).delete({ adminUserId: auth.id });

export const update = ({ id, ...data }, auth) =>
  client.billerAliases(id).update(data, { adminUserId: auth.id });
