import {
  API_KEYS_REQUEST,
  BASIC,
  SCOPE_CODE,
  SCOPE_FILTER_ID,
  SCOPE_FILTER_TYPE,
} from 'consts';
import { client } from 'util/sdk';
import { GET, DELETE, POST } from 'util/api';
import { stringToInt } from 'util/consts';
import { omit, pick } from 'ramda';
import { getScopeData } from 'components/modules/implementation/ApiKey/List/Form/Fields/Chip/ChipScopes';

const keys = [
  'name',
  'owner',
  'type',
  'clientKey',
  'clientSecret',
  'accessExpSec',
  'refreshExpSec',
  'ipv4s',
  'cidrs',
  'scopes',
  'roles',
];
const returnModel = { name: 'apikey' };
const scopeCode = [SCOPE_CODE];

export const load = (params) => {
  const [scopeType, scopeId] = getScopeData(params?.scope);
  const paramsWithScope = {
    ...omit(scopeCode, params),
    ...(scopeId && {
      [SCOPE_FILTER_TYPE]: scopeType,
      [SCOPE_FILTER_ID]: scopeId,
    }),
  };
  return client.executeRequest(GET, `/${API_KEYS_REQUEST}`, {
    queryParams: paramsWithScope,
    returnModel,
  });
};

export const add = (data, auth) =>
  client.executeRequest(POST, `/${API_KEYS_REQUEST}`, {
    data: pick(keys, setValuesToSend(data)),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

const setValuesToSend = (data) => {
  const isBasic = data.type === BASIC;
  return {
    ...data,
    ipv4s: data.allAPIs?.filter((item) => !item.includes('/')) ?? [],
    cidrs: data.allAPIs?.filter((item) => item.includes('/')) ?? [],
    accessExpSec: !isBasic ? stringToInt(data.accessExpSec) : null,
    refreshExpSec: !isBasic ? stringToInt(data.refreshExpSec) : null,
  };
};

export const update = ({ id, ...data }, auth) =>
  client.executeRequest(POST, `/${API_KEYS_REQUEST}/${id}`, {
    data: pick(keys, setValuesToSend(data)),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const remove = ({ id }, auth) =>
  client.executeRequest(DELETE, `/${API_KEYS_REQUEST}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
