import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { selectorEBillAutopay } from 'selectors/autopayScheduled';
import { compose, withProps } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { load, LOAD } from 'model/autopayScheduled';
import { pageConnect, pageHandlers } from 'util/page';
import { makeStyles } from '@material-ui/core/styles';
import { TRANSACTION_TYPE_BILL_PAY } from 'consts';
import { columnMeta, detailsRender } from './consts';
import { ActionsRenderer, defaultPageSettings } from '../config';
import { AUTOPAY } from '../../Autopay/consts';

const useStyles = makeStyles((theme) => ({
  list: {
    color: theme.palette.text.primary,
    minHeight: 270,
    padding: 0,
    width: '100%',
  },
}));

const settingType = 'autopayScheduledEBill';

const EBillList = ({
  fspId,
  handleChange,
  handleSearch,
  order,
  orderBy,
  page,
  payerId,
  rowsPerPage,
}) => {
  const classes = useStyles();
  const items = useSelector((state) => selectorEBillAutopay(state, payerId));

  const columnMetaMemo = useMemo(() => columnMeta, []);

  useEffect(() => {
    handleSearch([]);
  }, [fspId, payerId, handleSearch]);

  return (
    <ExpansionList
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      actionType={LOAD.ACTION}
      className={classes.list}
      columnMeta={columnMetaMemo}
      data={items}
      detailsRender={detailsRender}
      emptyTitle={`No E-Bill ${AUTOPAY} Transactions found`}
      name={settingType}
      onChange={handleChange}
      order={order}
      orderBy={orderBy}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

EBillList.propTypes = {
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default compose(
  connect(null, { loadAction: load }),
  withProps(({ fspId, loadAction, payerId }) => ({
    actionType: LOAD.ACTION,
    defaultPageSettings,
    loadAction,
    loadParams: { fspId, payerId, transactionType: TRANSACTION_TYPE_BILL_PAY },
    noSearch: true,
    settingType,
  })),
  pageConnect,
  pageHandlers
)(EBillList);
