import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { load } from 'model/oneTimePasscodeInfo';
import { selector } from 'selectors/oneTimePasscodeInfo';
import { useDispatch, useSelector } from 'react-redux';
import { ReadOnlyFormControl } from 'components/common/fields';

const Info = ({ fspId, payerId, userId }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selector);

  useEffect(() => {
    dispatch(load({ fspId, payerId, userId }));
  }, [dispatch, fspId, payerId, userId]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <ReadOnlyFormControl
          capitalize
          label="Manual Verification"
          value={userInfo?.manualVerificationLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyFormControl
          capitalize
          label="Status"
          value={userInfo?.status}
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyFormControl
          label="Service Start On"
          value={userInfo?.serviceStartOn}
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyFormControl label="Email" value={userInfo?.email} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyFormControl
          label="Email Last Updated"
          value={userInfo?.emailLastUpdatedAtLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyFormControl
          label="Mobile Phone"
          value={userInfo?.mobilePhoneLabel}
        />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyFormControl
          label="Mobile Last Updated"
          value={userInfo?.mobilePhoneLastUpdatedAtLabel}
        />
      </Grid>
    </Grid>
  );
};

Info.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
};

export default Info;
