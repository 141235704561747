import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const ImportDeleteAction = ({ options }) => {
  const { itemIndex, removeAction } = options ?? {};

  const handleRemove = () => {
    if (removeAction) {
      removeAction(itemIndex);
    }
  };

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button onClick={handleRemove} variant="outlined">
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

ImportDeleteAction.propTypes = {
  options: PropTypes.shape({
    itemIndex: PropTypes.number.isRequired,
    removeAction: PropTypes.func.isRequired,
  }).isRequired,
};

export default ImportDeleteAction;
