import { Grid, Paper, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { SEARCH_PATH } from '../paths';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
  },
  paper: {
    display: 'grid',
    height: 150,
    margin: 'auto',
    width: 400,
  },
  root: {
    display: 'grid',
    height: '80vh',
  },
  typography: {
    margin: 'auto',
  },
}));

const PayerDetailInvalidRequest = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.typography} variant="subtitle1">
          <Grid alignItems="center" container direction="column">
            <Grid item>Invalid Request</Grid>
            <Grid item>
              <Grid alignItems="center" container spacing={1}>
                <Grid item>try</Grid>
                <Grid item>
                  <Link className={classes.link} to={SEARCH_PATH}>
                    searching
                  </Link>
                </Grid>
                <Grid item>for the payer</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Paper>
    </div>
  );
};

export default PayerDetailInvalidRequest;
