import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useState } from 'react';

const StartAdornment = ({ disabled, filters, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedKey, setSelectedKey] = useState(0);

  const buttonText = filters.find(({ key }) => key === selectedKey)?.label;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="filterMenu"
        aria-haspopup="true"
        disabled={disabled}
        endIcon={<ArrowDropDown />}
        onClick={handleClick}
      >
        {buttonText}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {filters.map(({ key, label, value, ...rest }) => (
          <MenuItem
            key={key}
            onClick={(event) => {
              setAnchorEl(null);
              setSelectedKey(key);
              onChange(event, { label, value, ...rest });
            }}
            selected={selectedKey === key}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

StartAdornment.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

StartAdornment.defaultProps = {
  disabled: false,
};

export default StartAdornment;
