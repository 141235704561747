import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { head } from 'ramda';
import { load } from 'model/recentPayer';
import { selectorLastViewed } from 'selectors/recentPayer';
import { useDispatch, useSelector } from 'react-redux';
import { withViewService } from 'components/common/permissions';
import { SEARCH_PATH } from './paths';

const defaultFirstEntry = { fspId: 0, location: undefined, payerId: 0 };

const useStyles = makeStyles(() => ({
  paper: {
    display: 'grid',
    height: 150,
    margin: 'auto',
    width: 400,
  },
  root: {
    display: 'grid',
    height: '80vh',
  },
  text: {
    margin: 'auto',
  },
}));

const DefaultDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const recent = useSelector(selectorLastViewed);

  const { location, payerId } = head(recent) || defaultFirstEntry;

  useEffect(() => {
    dispatch(load());
  }, [dispatch]);

  useEffect(() => {
    if (payerId) {
      history.replace(location);
    }
  }, [history, location, payerId]);

  return payerId ? null : (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="subtitle1">
          No recent payers, try <Link to={SEARCH_PATH}>searching</Link>
        </Typography>
      </Paper>
    </div>
  );
};

export default withViewService(DefaultDetail);
