import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { derivedSelectorPayerId } from 'selectors/eBillEnrollment';
import { compose, withProps } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { load, LOAD } from 'model/eBillEnrollment';
import Table from 'components/common/table/table';
import { pageConnect, pageHandlers } from 'util/page';
import { EBillEnrollmentAction, columnMeta, defaultOrderBy } from './config';

const EBillEnrollmentList = ({
  fspId,
  handleChange,
  handleSearch,
  order,
  orderBy,
  page,
  payerId,
  rowsPerPage,
}) => {
  const items = useSelector((state) => derivedSelectorPayerId(state, payerId));

  useEffect(() => {
    handleSearch([]);
  }, [fspId, payerId, handleSearch]);

  return (
    <Table
      actionConstant={LOAD}
      actions={[EBillEnrollmentAction]}
      columnMeta={columnMeta}
      data={items}
      emptyTitle="No eBill Enrollments found"
      onChange={handleChange}
      order={order}
      orderBy={orderBy}
      page={page}
      rowsPerPage={rowsPerPage}
    />
  );
};

EBillEnrollmentList.propTypes = {
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default compose(
  connect(null, { loadAction: load }),
  withProps(({ fspId, payerId }) => ({
    defaultPageSettings: { order: 'asc', orderBy: defaultOrderBy },
    loadParams: { fspId, payerId },
    noSearch: true,
    settingType: 'ebillenrollments',
  })),
  pageConnect,
  pageHandlers
)(
  memo(EBillEnrollmentList, (prevProps, nextProps) => {
    return (
      prevProps.fspId === nextProps.fspId &&
      prevProps.order === nextProps.order &&
      prevProps.orderBy === nextProps.orderBy &&
      prevProps.page === nextProps.page &&
      prevProps.payerId === nextProps.payerId &&
      prevProps.rowsPerPage === nextProps.rowsPerPage
    );
  })
);
