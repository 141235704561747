const between = (x, min, max) => {
  if (min && max) {
    return x >= min && x <= max;
  }

  if (min) {
    return x >= min;
  }

  if (max) {
    return x <= max;
  }

  return true;
};

export const getDisabled = (disabled, field, { touched }, { isSubmitting }) => {
  if (isSubmitting) {
    return isSubmitting;
  }

  if (touched) {
    return disabled;
  }

  return disabled;
};

export const getValue = (field) => field?.value ?? '';

export const getValueAsNumberOrString = (event, { max, min }) => {
  const valueAsNumber = event?.target?.valueAsNumber;
  const valueAsString = event?.target?.value;

  if (!Number.isNaN(valueAsNumber)) {
    if (between(valueAsNumber, min, max)) {
      return valueAsNumber;
    }

    return undefined;
  }

  return valueAsString === '' ? null : valueAsString;
};
