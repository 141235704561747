import ExpandablePanel from 'components/common/ExpandablePanel';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { LOAD } from 'model/eBillEnrollment';
import { SubscriptionsOutlined } from '@material-ui/icons';
import { selector } from 'selectors/busy';
import { useEBillEnabledSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import { useSelector } from 'react-redux';
import EBillEnrollmentsList from './EBillEnrollmentsList';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';

const EBillEnrollments = ({ fspId, payerId }) => {
  const isBusy = useSelector((state) => selector(state, LOAD));
  const isEBillEnabled = useEBillEnabledSelector(fspId, payerId);

  return isEBillEnabled ? (
    <ExpandablePanel
      heightOffset={HEIGHT_OFFSET}
      icon={SubscriptionsOutlined}
      id="ebills"
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title="eBill Enrollment"
    >
      <EBillEnrollmentsList fspId={fspId} payerId={payerId} />
    </ExpandablePanel>
  ) : null;
};

EBillEnrollments.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(EBillEnrollments);
