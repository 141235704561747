import ArrowBack from '@material-ui/icons/ArrowBack';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const AuthenticationBackButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Grid item>
      <Button onClick={handleBack}>
        <ArrowBack className={classes.icon} />
        Back to Ontrac
      </Button>
    </Grid>
  );
};

export default AuthenticationBackButton;
