import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  typography: {
    // This is the minimum width for the Skeleton
    minWidth: 200,
  },
}));

const TextTitle = ({ loaded, title }) => {
  const classes = useStyles();
  const titleClass = classNames({ [classes.typography]: !loaded });

  return loaded ? (
    <Typography className={titleClass} data-testid="title" variant="h5">
      {title}
    </Typography>
  ) : (
    title
  );
};

TextTitle.propTypes = {
  loaded: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default TextTitle;
