import PropTypes from 'prop-types';
import { getStatusText } from 'sdk';
import { Grid } from '@material-ui/core';
import { isNil } from 'ramda';
import { ReadOnlyFormControl } from 'components/common/fields';
import SelectionAction from './SelectionAction';

const propTypes = {
  showStatusOnly: PropTypes.bool,
  size: PropTypes.number,
  sizes: PropTypes.arrayOf(PropTypes.number),
  spacing: PropTypes.number,
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  statusNode: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  statusText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  statusUpdatedAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
};

const defaultProps = {
  showStatusOnly: false,
  size: 4,
  sizes: null,
  spacing: 0,
  status: null,
  statusText: null,
};

export const StatusGridNoContainer = ({
  showStatusOnly,
  size,
  sizes,
  spacing,
  status,
  statusNode,
  statusText,
  statusUpdatedAt,
  ...props
}) => {
  const hasSizes = !isNil(sizes) && sizes.length === 3;
  const inputNode = isNil(statusNode) ? (
    <SelectionAction {...props} value={status} />
  ) : (
    statusNode
  );

  return (
    <>
      <Grid item xs={hasSizes ? sizes[0] : size}>
        <ReadOnlyFormControl
          inputNode={inputNode}
          label="Status"
          value={status}
        />
      </Grid>
      {!showStatusOnly && (
        <>
          <Grid item xs={hasSizes ? sizes[1] : size}>
            <ReadOnlyFormControl
              label="Status Last Updated"
              value={statusUpdatedAt}
            />
          </Grid>
          <Grid item xs={hasSizes ? sizes[2] : size}>
            <ReadOnlyFormControl
              label="Status Updated By"
              value={getStatusText(statusText)}
            />
          </Grid>
        </>
      )}
    </>
  );
};

const StatusGrid = ({ spacing, ...props }) => {
  return (
    <Grid container spacing={spacing}>
      <StatusGridNoContainer {...props} />
    </Grid>
  );
};

StatusGridNoContainer.propTypes = propTypes;

StatusGridNoContainer.defaultProps = defaultProps;

StatusGrid.propTypes = propTypes;

StatusGrid.defaultProps = defaultProps;

export default StatusGrid;
