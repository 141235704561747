import { client } from 'util/sdk';

export const create = (data, auth) =>
  client.executeRequest('POST', 'ebillernetebillers', {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'ebillernetebiller' },
  });

export const load = (params) =>
  client.executeRequest('GET', `/ebillernetebillers`, {
    queryParams: { ...params, order: 'name:asc' },
    returnModel: { name: 'ebillernetebiller' },
  });

export const loadById = (id) =>
  client.executeRequest('GET', `/ebillernetebillers/${id}`, {
    returnModel: { name: 'ebillernetebiller' },
  });

export const loadSet = (id) =>
  client.executeRequest('GET', 'ebillernetebillerbillers', {
    returnModel: { name: 'billernetebillerbiller' },
    queryParams: { ebillernetebillerid: id },
  });

export const update = ({ id, ...data }, auth) =>
  client.executeRequest('POST', `ebillernetebillers/${id}`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'ebillernetebiller' },
  });

export const updateSet = (
  { id, ebillernetEbillerBillerId, ebillernetId, setId },
  auth
) => {
  const data = {
    billerSetId: setId,
    ebillernetEbillerId: id,
    ebillernetId,
    ebillernetEbillerStatus: 'active',
  };

  const route = `ebillernetebillerbillers${
    ebillernetEbillerBillerId ? `/${ebillernetEbillerBillerId}` : ''
  }`;

  return client.executeRequest('POST', route, {
    data,
    returnModel: { name: 'billernetebillerbiller' },
    queryParams: { adminUserId: auth.id },
  });
};
