import PropTypes from 'prop-types';
import Rule from './Rule';

const UppercaseRule = ({ password, ...props }) => {
  return (
    <Rule {...props} enabled={/[A-Z]/.test(password)} text="UPPERCASE letter" />
  );
};

UppercaseRule.propTypes = {
  password: PropTypes.string.isRequired,
};

export default UppercaseRule;
