import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { compose, withProps } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { DATA_KEY } from 'selectors/user';
import { load, LOAD } from 'model/payerUser';
import { makeStyles } from '@material-ui/core/styles';
import { pageConnect, pageHandlers } from 'util/page';
import { selectorPayerId } from 'selectors/payerUser';
import {
  ActionsRenderer,
  columnMeta,
  defaultOrderBy,
  detailsRender,
} from './config';

const useStyles = makeStyles((theme) => ({
  list: {
    color: theme.palette.text.primary,
    minHeight: 270,
    padding: 0,
    width: '100%',
  },
}));

const UsersList = ({
  handleChange,
  order,
  orderBy,
  page,
  payerId,
  renderOptions,
  rowsPerPage,
}) => {
  const classes = useStyles();
  const items = useSelector((state) => selectorPayerId(state, payerId));

  return (
    <ExpansionList
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      className={classes.list}
      columnMeta={columnMeta}
      data={items}
      detailsRender={detailsRender}
      name="payerUsersList"
      onChange={handleChange}
      order={order}
      orderBy={orderBy}
      page={page}
      renderOptions={renderOptions}
      rowsPerPage={rowsPerPage}
    />
  );
};

UsersList.propTypes = {
  handleChange: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  renderOptions: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default compose(
  connect(undefined, { load }),
  withProps(({ fspId, load, payerId }) => ({
    actionType: LOAD.ACTION,
    defaultPageSettings: { order: 'asc', orderBy: defaultOrderBy },
    loadAction: load,
    loadParams: { fspId, payerId },
    noSearch: true,
    renderOptions: { fspId, type: DATA_KEY, payerId },
    settingType: 'payerUsersList',
  })),
  pageConnect,
  pageHandlers
)(
  memo(UsersList, (prevProps, nextProps) => {
    return (
      prevProps.order === nextProps.order &&
      prevProps.orderBy === nextProps.orderBy &&
      prevProps.page === nextProps.page &&
      prevProps.payerId === nextProps.payerId &&
      prevProps.rowsPerPage === nextProps.rowsPerPage
    );
  })
);
