import { CORS_DOMAIN_CODE } from 'consts';
import { client } from 'util/sdk';
import { GET, DELETE, POST } from 'util/api';
import { always, isEmpty, pick, when } from 'ramda';

const keys = ['domainName', 'description', 'fspId'];
const returnModel = { name: CORS_DOMAIN_CODE };

const emptyToNull = when(isEmpty, always(null));

const setValuesToSend = (data) => {
  const { domainName } = data;
  const domainNameParsed = domainName.endsWith('/')
    ? domainName.substring(0, domainName.length - 1)
    : domainName;
  return {
    ...data,
    domainName: domainNameParsed,
    description: emptyToNull(data.description),
    fspId: emptyToNull(data.fspId),
  };
};

export const load = (params) => {
  return client.executeRequest(GET, `/${CORS_DOMAIN_CODE}`, {
    queryParams: params,
    returnModel,
  });
};

export const add = (data, auth) =>
  client.executeRequest(POST, `/${CORS_DOMAIN_CODE}`, {
    data: pick(keys, setValuesToSend(data)),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const update = ({ id, ...data }, auth) =>
  client.executeRequest(POST, `/${CORS_DOMAIN_CODE}/${id}`, {
    data: pick(keys, setValuesToSend(data)),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const remove = ({ id }, auth) =>
  client.executeRequest(DELETE, `/${CORS_DOMAIN_CODE}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
