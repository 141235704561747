import moment from 'moment';
import { isNil } from 'ramda';
import { useMemo, useState } from 'react';
import useInterval from './useInterval';

const formatMinSec = 'm[m] s[s]';
const formatHourMinSec = 'h[h] m[m] s[s]';

const useDuration = (startAt, endAt, test) => {
  const [now, setNow] = useState(moment());
  const start = useMemo(() => moment(startAt), [startAt]);
  const end = useMemo(() => moment(endAt), [endAt]);

  useInterval(() => {
    if (test && isNil(endAt)) {
      setNow(moment());
    }
  }, 1000);

  const finish = endAt ? end : now;
  const diff = finish.diff(start);
  const duration = moment.duration(diff);
  const format = duration.as('minutes') >= 60 ? formatHourMinSec : formatMinSec;

  return isNil(endAt) && test === false ? '' : duration.format(format);
};

export default useDuration;
