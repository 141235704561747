import { Button, CircularProgress, Divider } from '@material-ui/core';
import { LOAD_ROUTING_NUMBER } from 'model/rtnInfo';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  circularProgress: {
    position: 'absolute',
  },
  divider: {
    height: 18,
    marginLeft: 5,
  },
}));

const EndAdornmentButton = (props) => {
  const classes = useStyles();
  const busy = useSelector((state) => selector(state, LOAD_ROUTING_NUMBER));

  return (
    <>
      <Divider className={classes.divider} orientation="vertical" />
      <Button size="small" {...props}>
        {busy && (
          <CircularProgress className={classes.circularProgress} size={18} />
        )}
        Validate
      </Button>
    </>
  );
};

export default EndAdornmentButton;
