import { ON } from 'consts';
import { CURRENCY } from 'util/test';
import * as yup from 'yup';

export const initialValues = {
  billDueDate: null,
  billerContactEmail: '',
  billerContactName: '',
  billerContactPhone: '',
  billType: '',
  correctAccountNumberEnteredOffOn: '',
  description: '',
  newAccountNumberOffOn: '',
  paymentPostedToActiveBillerOffOn: '',
  payrailzIssue: '',
  reason: '',
};

// This is to get the form to only support reason and description fields
// until we have integration working with zoho
export const USE_NEW = false;

export const validationSchema = yup.object().shape({
  billerContactEmail: USE_NEW && yup.string().email('Invalid Email'),
  billerContactName: USE_NEW && yup.string().required('Required'),
  billerContactPhone:
    USE_NEW &&
    yup
      .string()
      .required('Required')
      .test(
        'len',
        'Must be 10 digits',
        (val) => (val || '').replace(/\D/g, '').length === 10
      ),
  description: !USE_NEW && yup.string().required('Required'),
  payrailzIssue:
    USE_NEW &&
    yup
      .string()
      .nullable()
      .when('reason', {
        is: (reason) => reason?.reason === DISPUTE_LATE_FEES,
        then: yup
          .string()
          .required('Required')
          .oneOf(
            [ON],
            'Payrailz is not responsible for the late fee. We apologize for the inconvenience.'
          ),
      }),
  postedAmount: yup.string().matches(CURRENCY, 'Invalid currency format'),
  ssnLast4: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be 4 digits')
    .max(4, 'Must be 4 digits'),
  reason: yup.object().nullable().required('Required'),
});

export const DISPUTE_LATE_FEES = 'Dispute late fees';
export const DUPLICATE_TRANSACTION_ERROR = 'Duplicate transaction error';
export const INCORRECT_AMOUNT_POSTED = 'Incorrect amount posted';
export const POSTED_TO_THE_WRONG_BILLER = 'Posted to the wrong biller';
export const TRANSACTION_NOT_POSTED_OR_MISSING =
  'Transaction not posted or is missing';
