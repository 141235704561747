import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FORMAT } from 'components/common/fields/range';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

export const MIN_DATE = '2000-01-01';

const useStyles = makeStyles({
  root: {
    maxHeight: 48,
    maxWidth: 310,
    minHeight: 0,
    width: '100%',
  },
});

const Date = ({ className, value, inputProps, label, ...props }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        animateYearScrolling
        className={classNames(classes.root, className)}
        clearable
        format={FORMAT}
        fullWidth
        label={label}
        minDate={MIN_DATE}
        placeholder="yyyy-mm-dd"
        value={value || null}
        {...props}
        inputProps={{
          ...inputProps,
          'aria-label': (inputProps && inputProps['aria-label']) ?? label,
        }}
      />
    </MuiPickersUtilsProvider>
  );
};
Date.propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

Date.defaultProps = {
  className: undefined,
  inputProps: undefined,
  label: undefined,
  value: null,
};

export default Date;
