import moment from 'moment';
import {
  ASC,
  DEFAULT_MODEL_REF,
  DEFAULT_MODEL_REF_ARRAY,
  IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS,
} from 'consts';
import { createSelector } from 'redux-orm';
import { createCachedSelector } from 're-reselect';
import { orm } from 'model';
import {
  byLimitId,
  getPayerLimit,
  getProductLimits,
  getRuleBasedOnServiceStart,
  isCustomLimitType,
} from './utils/limit';

export const selector = createSelector(orm.LimitRule);

const selectorByLimitIdBase = createSelector(
  orm,
  (state, props) => props,
  ({ LimitRule }, limitId) =>
    LimitRule.filter(byLimitId(limitId))
      .orderBy(IN_SERVICE_LESS_THAN_EQUAL_TO_MONTHS, ASC)
      .toRefArray() ?? DEFAULT_MODEL_REF_ARRAY
);

export const selectorByLimitIdCount = createSelector(
  orm,
  (state, props) => props,
  ({ LimitRule }, limitId) => LimitRule.filter(byLimitId(limitId)).count()
);

export const selectorByServiceStart = createSelector(
  orm,
  (state, props) => props,
  ({ LimitRule, Payer }, payerId) =>
    getRuleBasedOnServiceStart(LimitRule, Payer, payerId) || DEFAULT_MODEL_REF
);

export const selectorRows = createSelector(
  orm,
  (state, props) => props,
  ({ LimitRule, Payer }, { payerId, products }) => {
    const productLimits = [];
    const rule = getRuleBasedOnServiceStart(LimitRule, Payer, payerId);

    // We are given the limit rules based on time period or min, max.
    // But. in the UI we display rows by product.
    // This selector returns a row in product form not time.
    if (rule) {
      products.forEach((product, index) =>
        productLimits.push(getProductLimits(rule, product, index + 1))
      );
      productLimits.push(getPayerLimit(rule));
    }

    return productLimits;
  }
);

export const selectorRuleExpired = createSelector(
  orm,
  (state, props) => props,
  ({ LimitRule, Payer }, { limitType, payerId }) => {
    let ruleExpired = true; // assume the rule is expired

    if (!isCustomLimitType(limitType) || LimitRule.count() === 1) {
      // not a custom rule, it can't expire
      ruleExpired = false;
    } else if (LimitRule.count() === 2) {
      const orderedRules = LimitRule.orderBy(
        'inServiceLessThanEqualToMonths',
        'asc'
      );
      const rule = orderedRules.at(0).ref;
      const serviceStartOn = moment(Payer.withId(payerId).serviceStartOn);
      const monthsInService = moment().diff(serviceStartOn, 'months');

      if (monthsInService <= rule.inServiceLessThanEqualToMonths) {
        ruleExpired = false;
      }
    }

    return ruleExpired;
  }
);

export const selectorById = createCachedSelector(
  selector,
  (r) => r ?? DEFAULT_MODEL_REF
)((state, id) => id);

export const selectorByLimitId = createCachedSelector(
  selectorByLimitIdBase,
  (items) => items ?? DEFAULT_MODEL_REF_ARRAY
)((state, limitId) => limitId);
