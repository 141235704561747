import PropTypes from 'prop-types';
import { memo } from 'react';
import { DRAWER_WIDTH } from 'consts';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
    [theme.breakpoints.up('lg')]: {
      left: DRAWER_WIDTH,
    },
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
    marginBottom: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(1.5),
    position: 'fixed',
    textAlign: 'right',
  },
}));

const RecentListFooter = ({ count, position, subject, ...props }) => {
  const classes = useStyles();

  return (
    <ListItemText
      classes={{ root: classes.listItemText }}
      secondary={`${subject} ${position} of ${count}`}
      {...props}
    />
  );
};

RecentListFooter.propTypes = {
  count: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  subject: PropTypes.string.isRequired,
};

export default memo(RecentListFooter);
