import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.AutopayPal);

export const selectorPalAutopay = createSelector(
  orm,
  (state, payerId) => payerId,
  ({ AutopayPal }, payerId) => {
    const result = AutopayPal.filter(
      ({ payerId: autopayPalPayerId }) => autopayPalPayerId === payerId
    )?.first();

    return result?.ref || {};
  }
);
