import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { BUSY_DELAY } from 'sagas';
import { useDispatch } from 'react-redux';
import { PATH as LOGIN_PATH } from 'components/Authentication/Login';
import { Popper, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { clearUpdate } from 'reducers/authToken';
import Authentication from '../Authentication';
import PasswordRules from '../Password/PasswordUpdate/PasswordRules';
import Reset from '../Password/PasswordReset';
import ResetPost from '../Password/PasswordReset/PasswordResetPost';
import Update from './PasswordUpdate';
import PasswordVerify from '../Password/PasswordVerify';

export const PATH = '/updateProfile';

const TITLE = 'Change Password';

const useStyles = makeStyles(() => ({
  card: {
    height: 460,
  },
  tab: {
    display: 'none',
  },
}));

const Password = ({ passwordExpired }) => {
  const [byRuleDisabled, setByRuleDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [openPasswordRules, setOpenPasswordRules] = useState(false);
  const [password, setPassword] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [title, setTitle] = useState(TITLE);
  const [value, setValue] = useState(passwordExpired ? 1 : 0);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleTimedOut = useCallback(() => {
    if (passwordExpired) {
      dispatch(clearUpdate());
    } else {
      history.goBack();
    }
  }, [dispatch, history, passwordExpired]);

  const anchorEl = document.getElementById('popper-anchor');

  const handleAuthenticated = () => {
    setValue(1);
  };
  const handleChange = (event) => {
    if (event.target) {
      setPassword(event.target.value);
    }
  };
  const handleGo = () => {
    history.push(LOGIN_PATH);
  };
  const handleProgress = (value) => {
    setShowProgress(value);
  };
  const handleReset = ({ email = '' }) => {
    setEmail(email);
    setValue(3);
  };
  const handleRuleChange = (enabled) => {
    if (byRuleDisabled === enabled) {
      setByRuleDisabled(!enabled);
    }
  };
  const handleUpdated = () => {
    history.goBack();
  };
  const handleViewReset = () => {
    setValue(2);
  };
  const handleViewVerify = () => {
    setTitle(TITLE);
    setValue(0);
  };

  useEffect(() => {
    let timeoutId = 0;

    if (!openPasswordRules && value === 1) {
      timeoutId = setTimeout(() => {
        setOpenPasswordRules(true);
      }, BUSY_DELAY);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openPasswordRules, value]);

  return (
    <Authentication
      classes={{ card: classes.card }}
      progress={showProgress}
      showBack={!passwordExpired}
      title={title}
    >
      <Tabs classes={{ root: classes.tab }} value={value}>
        <Tab />
        <Tab />
        <Tab />
        <Tab />
      </Tabs>
      <SwipeableViews id="popper-anchor" index={value}>
        <PasswordVerify
          form="authenticateChange"
          isChange
          onAuthenticated={handleAuthenticated}
          onProgress={handleProgress}
          onViewChange={handleViewReset}
          visible={value === 0}
        />
        <div>
          <Update
            byRuleDisabled={byRuleDisabled}
            onChange={handleChange}
            onProgress={handleProgress}
            onTimedOut={handleTimedOut}
            onUpdated={handleUpdated}
            passwordExpired={passwordExpired}
            visible={value === 1}
          />
          <Popper
            anchorEl={anchorEl}
            open={openPasswordRules}
            placement="right"
          >
            <PasswordRules
              onRuleChange={handleRuleChange}
              password={password}
            />
          </Popper>
        </div>
        <Reset
          onProgress={handleProgress}
          onReset={handleReset}
          onViewChange={handleViewVerify}
          visible={value === 2}
        />
        <ResetPost email={email} onGo={handleGo} visible={value === 3} />
      </SwipeableViews>
    </Authentication>
  );
};

Password.propTypes = {
  passwordExpired: PropTypes.bool,
};

Password.defaultProps = {
  passwordExpired: false,
};

export default Password;
