import {
  ACCOUNT_TO_ACCOUNT,
  ACCOUNT_TO_ACCOUNT_V2,
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  ACTIVITY,
  BILL_PAY_CODE_V2,
  TRANSACTION_TYPE_BILL_PAY,
  DISBURSE_TYPE_P2P_DIRECT_CODE,
  PERSON_TO_PERSON,
  P2P_CODE_V2,
  TRANSACTION_TYPE_PERSON_TO_PERSON,
  SSO_CODE,
  V2,
  TRANSACTION_TYPE_PAL,
  PAL_CODE_V2,
} from 'consts';

export const SsoVersion = {
  [V2]: {
    scriptName: '/lib/v2/payrailz-web.min.js',
    html: '/v2TransactAs.html',
  },
  [SSO_CODE]: {
    scriptName: '/lib/payrailz-web-transact.min.js',
    html: '/transactAs.html',
  },
};

export const waitOn = (predicate, times, action, onError) => {
  if (predicate()) {
    action();
    return;
  }
  if (times === 0) {
    onError('could not find sso application');
    return;
  }
  setTimeout(() => waitOn(predicate, times - 1, action, onError), 100);
};

const isA2A = (transactionType) =>
  [
    ACCOUNT_TO_ACCOUNT_INBOUND,
    ACCOUNT_TO_ACCOUNT_NEW,
    ACCOUNT_TO_ACCOUNT_OUTBOUND,
  ].includes(transactionType);

export const isP2P = (transactionType) =>
  [
    TRANSACTION_TYPE_PERSON_TO_PERSON,
    DISBURSE_TYPE_P2P_DIRECT_CODE,
    P2P_CODE_V2,
  ].includes(transactionType);

export const isPAL = (transactionType) =>
  [TRANSACTION_TYPE_PAL, PAL_CODE_V2].includes(transactionType);

// Search in the SSO URI links to check if the type is present (returnType is ON)
// If the returnType flag is ON it means we should handle this specific SSO URI
const getSsoLinkType = (links, type) => {
  const link = links?.find((link) => link.type === type);
  return link?.type;
};

export const getLandingType = (
  links,
  recipientId,
  payeeId,
  transactionId,
  transactionType,
  loanId
) => {
  if (recipientId) {
    return getSsoLinkType(links, P2P_CODE_V2) ? P2P_CODE_V2 : PERSON_TO_PERSON;
  }
  if (payeeId) {
    return getSsoLinkType(links, BILL_PAY_CODE_V2)
      ? BILL_PAY_CODE_V2
      : TRANSACTION_TYPE_BILL_PAY;
  }
  if (transactionId) {
    if (getSsoLinkType(links, ACTIVITY) && !loanId) {
      // This is the value for sso v1
      return ACTIVITY;
    }
    // For v2 scheduled transactions, we should override with transaction type
    if (isA2A(transactionType)) {
      return ACCOUNT_TO_ACCOUNT_V2;
    }
    const typeWithV2 = `${V2}-${transactionType}`;
    return getSsoLinkType(links, typeWithV2);
  }
  if (loanId) {
    return getSsoLinkType(links, PAL_CODE_V2);
  }
  return getSsoLinkType(links, ACCOUNT_TO_ACCOUNT_V2) || ACCOUNT_TO_ACCOUNT;
};

const isAdd = (operation) => operation === 'add';

/*
  New transactions have operation value equals 'add'.
  Updating scheduled transactions could contain the following types: 
    - V1 Type: Activity
    - V2 Type: TransactionType as in v2-bp, v2-a2a or v2-p2p
    - Operation: only 'delete' or 'update'
*/
export const getSuccessMessage = (type, data, operation) => {
  if ([PERSON_TO_PERSON, P2P_CODE_V2].includes(type) && isAdd(operation)) {
    return 'Payment created';
  }
  if (
    [TRANSACTION_TYPE_BILL_PAY, BILL_PAY_CODE_V2].includes(type) &&
    isAdd(operation)
  ) {
    return 'Bill payment created';
  }
  if (
    [ACCOUNT_TO_ACCOUNT, ACCOUNT_TO_ACCOUNT_V2].includes(type) &&
    isAdd(operation)
  ) {
    return 'Transfer created';
  }
  return operation === 'delete' ? 'Transaction deleted' : 'Transaction updated';
};
