import PropTypes from 'prop-types';
import { Grid, Card, CardContent, makeStyles } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { TRANSACTION_TYPE_BILL_PAY } from 'consts';
import { AUTOPAY } from './consts';
import AutopayCustomRule from './AutopayCustomRule';
import AutopayEBillPayFrom from './AutopayEBillPayFrom';
import AutopayCreditPayFrom from './AutopayCreditPayFrom';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 600,
  },
}));

const Autopay = ({
  billGreaterThanAmountDerived,
  billGreaterThanCustomAmountDerived,
  billGreaterThanNotPayOffOn,
  ddaId,
  externalDdaId,
  fspId,
  frequency,
  payAmountDerived,
  payerId,
  payWhenDerived,
  statusDerived,
  transactionType,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <ReadOnlyFormControl label={AUTOPAY} value={statusDerived} />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyFormControl
              capitalize
              label="Frequency"
              value={frequency}
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyFormControl
              label="Pay Amount Option"
              value={payAmountDerived}
            />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyFormControl
              label="Pay Date Option"
              value={payWhenDerived}
            />
          </Grid>
          <Grid item xs={6}>
            {transactionType === TRANSACTION_TYPE_BILL_PAY ? (
              <AutopayEBillPayFrom
                ddaId={ddaId}
                fspId={fspId}
                payerId={payerId}
              />
            ) : (
              <AutopayCreditPayFrom
                externalDdaId={externalDdaId}
                fspId={fspId}
              />
            )}
          </Grid>
          {billGreaterThanAmountDerived && (
            <Grid item xs={6}>
              <AutopayCustomRule
                billGreaterThanAmountDerived={billGreaterThanAmountDerived}
                billGreaterThanCustomAmountDerived={
                  billGreaterThanCustomAmountDerived
                }
                billGreaterThanNotPayOffOn={billGreaterThanNotPayOffOn}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

Autopay.propTypes = {
  billGreaterThanAmountDerived: PropTypes.string,
  billGreaterThanCustomAmountDerived: PropTypes.string,
  billGreaterThanNotPayOffOn: PropTypes.string,
  ddaId: PropTypes.number,
  externalDdaId: PropTypes.number,
  fspId: PropTypes.number.isRequired,
  frequency: PropTypes.string,
  payAmountDerived: PropTypes.string,
  payerId: PropTypes.number.isRequired,
  payWhenDerived: PropTypes.string,
  statusDerived: PropTypes.string,
  transactionType: PropTypes.string.isRequired,
};

Autopay.defaultProps = {
  billGreaterThanAmountDerived: null,
  billGreaterThanCustomAmountDerived: null,
  billGreaterThanNotPayOffOn: null,
  ddaId: null,
  externalDdaId: null,
  frequency: null,
  payAmountDerived: null,
  payWhenDerived: null,
  statusDerived: null,
};

export default Autopay;
