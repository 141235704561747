import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  TRANSACTION_TYPE_BILL_PAY,
  DELETED,
  DELETED_CODE,
  ON_HOLD_LABEL,
  PERSON_TO_PERSON,
  SCHEDULE,
  SCHEDULED,
} from 'consts';
import { getType } from 'components/modules/service/PayerDetail/Transactions/config/transactions';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { ascend, prop, sortWith } from 'ramda';
import { date, formatCurrency, isOn } from '../util';

const getDerivedModelIdData = (item) => {
  item.set('amountDerived', formatCurrency(item.amount));
  item.set('deliverOnDerived', date(item.deliverOn));
  item.set('sendOnDerived', date(item.sendOn));

  return item.ref;
};
const getStatusDerived = (item) => {
  return item.status === SCHEDULE
    ? SCHEDULED
    : item.status === DELETED_CODE
    ? DELETED
    : ON_HOLD_LABEL;
};

export const setSuffix = (item) => {
  const suffix = item.dda ? item.dda.accountSuffix : item.ddaAccountSuffix;
  let destinationAccountSuffix = null;
  let fundingAccountSuffix = null;

  switch (item.transactionType) {
    case ACCOUNT_TO_ACCOUNT_INBOUND:
    case ACCOUNT_TO_ACCOUNT_NEW:
      destinationAccountSuffix = suffix;
      break;

    case ACCOUNT_TO_ACCOUNT_OUTBOUND:
    case TRANSACTION_TYPE_BILL_PAY:
    case PERSON_TO_PERSON:
      fundingAccountSuffix = suffix;
      break;

    default:
      break;
  }

  item.set('destinationAccountSuffix', destinationAccountSuffix);
  item.set('fundingAccountSuffix', fundingAccountSuffix);
};

const getDerivedTransactionData = (item) => {
  item.set('endDateDerived', isOn(item.foreverOffOn) ? 'No' : 'Yes');
  item.set('statusDerived', getStatusDerived(item));
  item.set('subtitle', `${date(item.sendOn)} - ${formatCurrency(item.amount)}`);
  item.set('title', getType(item.transactionType));
  item.set('transactionTypeDerived', getType(item.transactionType));

  setSuffix(item);

  return item.ref;
};

const theSelector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).scheduledTransactions.toModelArray()
      : []
);

const theSelectorId = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId, id }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId)
          .scheduledTransactions.toModelArray()
          .find((item) => item?.id === id) || {}
      : {}
);

const theModelIdSelector = createSelector(
  orm,
  (state, props) => props,
  ({ ScheduledTransaction }, id) =>
    sortWith([ascend(prop('sendOn'))])(
      ScheduledTransaction.filter(
        ({ modelId, isLoadedFromModelId }) =>
          modelId === id && isLoadedFromModelId
      ).toModelArray()
    ) || []
);

export const selector = createReselectSelector(theSelector, (items) =>
  items.map(getDerivedTransactionData)
);

export const selectorId = createReselectSelector(theSelectorId, (item) =>
  getDerivedTransactionData(item)
);

export const modelIdSelector = createReselectSelector(
  theModelIdSelector,
  (items) => items.map(getDerivedModelIdData)
);
