import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { derivedSelectorId } from 'selectors/paymentResearch';
import { loadById } from 'model/paymentResearch';

export default compose(
  setDisplayName('PaymentResearchRequestContainer'),
  setPropTypes({
    fspId: PropTypes.number.isRequired,
  }),
  withProps(({ id, trrId }) => ({ id: trrId || id })),
  connect(
    (state, props) => ({
      trr: derivedSelectorId(state, props),
    }),
    { loadById }
  ),
  lifecycle({
    componentDidMount() {
      const { trr, id } = this.props;

      if (trr.isEmpty && id) {
        const { fspId, loadById } = this.props;

        loadById({ fspId, id });
      }
    },
  }),
  pure
);
