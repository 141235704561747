import PropTypes from 'prop-types';
import { Button, DialogActions as MuiDialogActions } from '@material-ui/core';

const DialogActions = ({ disabled, onClose, onGenerate, passcode }) => {
  return (
    <MuiDialogActions>
      {passcode ? (
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      ) : (
        <>
          <Button color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" disabled={disabled} onClick={onGenerate}>
            Generate
          </Button>
        </>
      )}
    </MuiDialogActions>
  );
};

DialogActions.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerate: PropTypes.func.isRequired,
  passcode: PropTypes.string.isRequired,
};

export default DialogActions;
