import PropTypes from 'prop-types';

const FspType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  database: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  distributor: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
});

export default FspType;
