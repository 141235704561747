import { load } from 'model/fspConfig';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useDispatchLoadEffect = (
  fspId,
  fspConfig,
  shouldDispatch = true,
  shouldRedispatch = true
) => {
  const dispatch = useDispatch();
  const redispatch =
    shouldRedispatch === false ? Object.keys(fspConfig).length === 0 : true;

  useEffect(() => {
    if (shouldDispatch && redispatch) {
      dispatch(load({ fspId }));
    }
  }, [dispatch, fspId, shouldDispatch, redispatch]);
};

export default useDispatchLoadEffect;
