import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(
  orm,
  (state, props) => props,
  ({ PaymentResearch }, id) => {
    return PaymentResearch.idExists(id)
      ? PaymentResearch.withId(id).researchNotes.toRefArray()
      : [];
  }
);

export const selectorId = createSelector(orm.ResearchNote);
