import { formatOrderOffsetLimit } from 'util/page';
import { formatSearchBys } from 'components/common/searchByUtils';
import { useMemo } from 'react';
import useFSPId from '../useFSPId';
import useFSPIdFromQueryParams from '../useFSPIdFromQueryParams';

const usePaginationData = ({
  countSelectorProps,
  hasFspId,
  order,
  orderBy,
  page,
  refreshedAt,
  rowsPerPage,
  searchBys,
}) => {
  const fspIdParam = useFSPId();
  const fspIdQueryParam = useFSPIdFromQueryParams();
  const fspId = hasFspId ? fspIdParam || fspIdQueryParam : null;

  return useMemo(() => {
    const orderOffsetLimit = formatOrderOffsetLimit({
      order,
      orderBy,
      page,
      rowsPerPage,
    });
    const formattedSearchBys = formatSearchBys(searchBys);

    const data = {
      refreshedAt,
      ...countSelectorProps,
      ...orderOffsetLimit,
      ...formattedSearchBys,
    };

    if (hasFspId) {
      data.fspId = fspId;
    }

    return data;
  }, [
    countSelectorProps,
    fspId,
    hasFspId,
    order,
    orderBy,
    page,
    refreshedAt,
    rowsPerPage,
    searchBys,
  ]);
};

export default usePaginationData;
