import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { DATA_KEY, selector } from 'selectors/payee';
import { compose, withProps } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { insert } from 'ramda';
import { loadById, LOAD_ID } from 'model/payee';
import { makeStyles } from '@material-ui/core/styles';
import { useEBillEnabledSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import { deletedOnlyField, pageConnect, pageHandlers } from 'util/page';
import { toOnOff } from 'util/index';
import {
  ActionsRenderer,
  columnMeta,
  defaultOrderBy,
  detailsRender,
  eBillColumn,
} from './config';

const defaultPageSettings = { order: 'asc', orderBy: defaultOrderBy };
export const settingType = 'payees';

const useStyles = makeStyles((theme) => ({
  list: {
    color: theme.palette.text.primary,
    minHeight: 270,
    padding: 0,
    width: '100%',
  },
}));

const BillersList = ({
  fspId,
  handleChange,
  handleSearch,
  order,
  orderBy,
  page,
  payerId,
  renderOptions,
  rowsPerPage,
  showDeleted,
}) => {
  const classes = useStyles();
  const items = useSelector((state) => selector(state, payerId));
  const isEBillEnabled = useEBillEnabledSelector(fspId, payerId);
  const listColumMeta = useMemo(
    () =>
      isEBillEnabled ? insert(1, eBillColumn, columnMeta) : [...columnMeta],
    [isEBillEnabled]
  );

  const emptyTitle = `No ${showDeleted ? 'Deleted' : ' '} Billers Found`;

  useEffect(() => {
    handleSearch(showDeleted ? deletedOnlyField : []);
  }, [showDeleted, fspId, payerId, handleSearch]);

  return (
    <ExpansionList
      actionConstant={LOAD_ID}
      ActionsRenderer={ActionsRenderer}
      className={classes.list}
      columnMeta={listColumMeta}
      data={items}
      detailsRender={detailsRender}
      emptyTitle={emptyTitle}
      name="biller"
      onChange={handleChange}
      order={order}
      orderBy={orderBy}
      page={page}
      renderOptions={renderOptions}
      rowsPerPage={rowsPerPage}
    />
  );
};

BillersList.propTypes = {
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  renderOptions: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  showDeleted: PropTypes.bool,
};

BillersList.defaultProps = {
  showDeleted: false,
};

export default compose(
  connect(undefined, { load: loadById }),
  withProps(({ fspId, load, payerId, showDeleted }) => ({
    actionType: LOAD_ID.ACTION,
    defaultPageSettings,
    loadAction: load,
    loadParams: { deletedOnly: toOnOff(showDeleted), fspId, payerId },
    noSearch: true,
    renderOptions: {
      deletedOnly: toOnOff(showDeleted),
      fspId,
      type: DATA_KEY,
      payerId,
    },
    settingType,
  })),
  pageConnect,
  pageHandlers
)(BillersList);
