import { compose, concat, includes, keys, pickBy } from 'ramda';

const DEFAULT_LABEL = 'Update';
const DEFAULT_MESSAGE = 'Are you sure you want to update status?';

const getStatusConfig = (currentStatus, newStatus, statusConfig = {}) =>
  (statusConfig[currentStatus] && statusConfig[currentStatus][newStatus]) ??
  statusConfig.default;

/**
 * Available states are filtered by permissions, independently of the actual permission to edit state.
 */
export const getAvailableStates = (
  currentStatus,
  statusList,
  statusConfig,
  auth
) => {
  const stateAllowed = (config) => config.requiredPermission(auth);

  const keysData = compose(
    concat([currentStatus]),
    keys,
    pickBy(stateAllowed)
  )(statusConfig[currentStatus]);

  return statusList.filter((item) => includes(item.value, keysData));
};

export const getContinueLabel = (currentStatus, newStatus, statusConfig) => {
  const config = getStatusConfig(currentStatus, newStatus, statusConfig);

  return config?.label ?? DEFAULT_LABEL;
};

export const getConfirmMessage = (
  currentStatus,
  newStatus,
  statusConfig,
  extras
) => {
  const config = getStatusConfig(currentStatus, newStatus, statusConfig);
  const message = config?.message ?? DEFAULT_MESSAGE;

  if (typeof message === 'string') {
    return extras ? `${message} ${extras}` : message;
  }

  return message(extras);
};
