import { client, formatQueryParams } from 'util/sdk';
import { pick } from 'ramda';

const only = ['billerAccountNumber', 'name', 'uspsAddress'];

export const matchPayees = ({ fspId, ...params }) =>
  client.fsps(fspId).matchPayees.findit(pick(only, params));

export const switchBillerInfo = (params) =>
  client.switchBillerInfo.query(formatQueryParams(params));
