import { createCachedSelector } from 're-reselect';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { getSecondArgument } from 'consts';
import { orm } from 'model';
import {
  combinerSelectorObject,
  fraudEnabled,
  denyListEnabled,
} from './utils/fspFraudConfig';

const baseSelector = createSelector(orm.FspFraudConfig);

export const selector = createCachedSelector(
  baseSelector,
  combinerSelectorObject
)(getSecondArgument);

export const selectorFraudEnabled = createReselectSelector(
  selector,
  fraudEnabled
);

export const selectorDenyListEnabled = createReselectSelector(
  selector,
  denyListEnabled
);
