import { createAction, createLoadConst } from 'util/actions';

const initialState = null;
const name = 'otpGenerateButton';

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);

export const selector = (state) => state[name];

export default (state = initialState, { type }) => {
  switch (type) {
    case LOAD.ACTION:
      return { success: true, ...state };

    default:
      return state;
  }
};
