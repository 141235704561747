import DetailsRender from './DetailsRender';

export const columnMeta = [
  {
    id: 'createdAtLabel',
    label: 'Created At',
    xs: 3,
  },
  {
    context: true,
    id: 'iavStatus',
    label: 'IAV Status',
    xs: 3,
  },
  {
    capitalize: true,
    id: 'errorCode',
    label: 'Error Code',
    xs: 3,
  },
  {
    id: 'institutionName',
    label: 'Institution Name',
    xs: 3,
  },
];

export const defaultOrderBy = 'createdAt';

export const detailsRender = (item) => <DetailsRender item={item} />;
