import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Route extends Model {
  static get modelName() {
    return 'Route';
  }

  static reducer({ type, data }, Route) {
    switch (type) {
      case ADD.SUCCESS:
        Route.upsert(data);
        break;

      case REMOVE.SUCCESS:
        Route.withId(data.id).delete();
        break;

      case LOAD.SUCCESS:
        Route.delete();
        data.route.forEach((r) => Route.upsert(r));
        break;

      case LOAD_ID.SUCCESS:
        Route.upsert(data.route);
        break;

      case UPDATE.SUCCESS:
        Route.update(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

export const FORM_NAME = 'setupRoute';

export const ADD = createAddConst(Route.modelName);
export const LOAD = createRequestConst(Route.modelName);
export const LOAD_ID = createRequestConst(`${Route.modelName}_id`);
export const REMOVE = createDeleteConst(Route.modelName);
export const UPDATE = createUpdateConst(Route.modelName);

export const add = createAction(ADD);
export const load = createAction(LOAD);
export const loadId = createAction(LOAD_ID);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
