import PropTypes from 'prop-types';
import TextFieldNumeric from 'components/common/formik/TextFieldNumeric';

const localInputProps = { maxLength: 9 };

const TextFieldZip = ({ inputProps, label, name, ...props }) => {
  return (
    <TextFieldNumeric
      {...props}
      inputProps={{ ...inputProps, ...localInputProps }}
      label={label}
      name={name}
    />
  );
};

TextFieldZip.propTypes = {
  inputProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

TextFieldZip.defaultProps = {
  inputProps: undefined,
  label: undefined,
};

export default TextFieldZip;
