import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useOpen } from 'hooks';
import NoOptionsText from '../NoOptionsText';
import renderOption from './RenderOption';
import useRenderInput from '../RenderInput';
import defaultUseTypeAhead from './useTypeAhead';
import {
  defaultFilter,
  getOptionLabel,
  filters as defaultFilters,
} from './consts';
import {
  REASON_CLEAR,
  REASON_INPUT,
  REASON_SELECT_OPTION,
  NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ,
} from '../consts';

const defaultLabel = 'Find FSP By';
const text = 'No fsps matches found for ';

const Fsp = ({
  autoFocus,
  customFilter,
  disabled,
  filters,
  label,
  onChange,
  required,
  value: valueProp,
  open: isOpen,
  TextFieldProps,
  useTypeAhead,
  ...props
}) => {
  const [filterParam, setFilterParam] = useState(customFilter || defaultFilter);
  const [open, toggle, setOpen] = useOpen(isOpen);
  const [value, setValue] = useState(null);
  const [loading, options, throttledDispatch] = useTypeAhead(filterParam);
  const onChangeFilter = (event, value) => {
    setFilterParam(value);
    setOpen(false);
  };
  const renderInput = useRenderInput({
    autoFocus,
    disabled,
    filters,
    label,
    loading,
    open,
    onChangeFilter,
    placeholder: filterParam.placeholder,
    required,
    TextFieldProps,
  });
  const inputValueRef = useRef('');

  const handleChange = useCallback(
    (event, fsp, reason) => {
      const optionLabel = getOptionLabel(fsp);

      if (reason === REASON_CLEAR || reason === REASON_SELECT_OPTION) {
        inputValueRef.current = optionLabel;
        setValue(fsp);
      }
      onChange(event, {
        ...fsp,
        filterParam,
        optionLabel,
      });
    },
    [filterParam, onChange]
  );
  const handleInputChange = useCallback(
    (event, newInputValue, reason) => {
      if (reason === REASON_CLEAR || reason === REASON_INPUT) {
        inputValueRef.current = newInputValue;
        setValue(newInputValue);
        throttledDispatch(newInputValue);
      }
    },
    [throttledDispatch]
  );
  const handleOnOpen = useCallback(
    (event) => {
      if (
        filterParam.highlight ||
        event.currentTarget.type === 'button' ||
        inputValueRef.current?.length >= NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ
      ) {
        setOpen(true);
      }
    },
    [filterParam, setOpen]
  );

  useEffect(() => {
    if (valueProp && valueProp !== REASON_CLEAR) {
      // Intentionally this section is a little loose with comparisons
      // Why? an id can be a string or number
      // eslint-disable-next-line eqeqeq
      const fsp = options.find(({ id }) => id == valueProp);
      if (fsp) {
        inputValueRef.current = getOptionLabel(fsp);
        setValue(fsp);
      }
    }
  }, [options, valueProp]);

  return (
    <Autocomplete
      {...props}
      autoComplete
      autoHighlight
      clearOnEscape
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      inputValue={inputValueRef.current}
      loading={loading}
      noOptionsText={
        <NoOptionsText
          idSearch={!filterParam.highlight}
          text={text}
          value={inputValueRef.current}
        />
      }
      onChange={handleChange}
      {...(!isOpen && { onClose: toggle })}
      onInputChange={handleInputChange}
      onOpen={handleOnOpen}
      open={open}
      options={options}
      renderInput={renderInput}
      renderOption={
        filterParam.highlight ? renderOption(filterParam) : undefined
      }
      value={value}
    />
  );
};

Fsp.propTypes = {
  autoFocus: PropTypes.bool,
  customFilter: PropTypes.object,
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  required: PropTypes.bool,
  useTypeAhead: PropTypes.func,
  TextFieldProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Fsp.defaultProps = {
  autoFocus: true,
  customFilter: null,
  disabled: false,
  filters: defaultFilters,
  label: defaultLabel,
  onChange: always,
  open: false,
  required: false,
  useTypeAhead: defaultUseTypeAhead,
  TextFieldProps: null,
  value: null,
};

export default Fsp;
