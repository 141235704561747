import { createSelector } from 'redux-orm';
import { evolve, map } from 'ramda';
import { orm } from 'model';
import { date, formatCurrency } from '../util';

const transformation = {
  amountDue: formatCurrency,
  dueOn: date,
  minimumAmount: formatCurrency,
  outstandingBalance: formatCurrency,
};

const mapStatements = (statements) =>
  map(
    (s) => ({
      ...s,
      billOnView: date(s.billOn),
      amountDueView: formatCurrency(s.amountDue),
      dueOnView: date(s.dueOn),
      minimumAmountView: formatCurrency(s.minimumAmount),
      outstandingBalanceView: formatCurrency(s.outstandingBalance),
    }),
    statements
  );

export default createSelector(
  orm,
  (_, props) => props,
  ({ EBillAccount }, id) => {
    const item = EBillAccount.idExists(id) ? EBillAccount.withId(id) : null;

    if (!item) {
      return item;
    }

    return evolve({ ...transformation, statements: mapStatements }, item.ref);
  }
);
