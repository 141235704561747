import { client } from 'util/sdk';
import { GET } from 'util/api';

// eslint-disable-next-line import/prefer-default-export
export const loadFSPProfile = ({ fspId }) =>
  client.executeRequest(GET, `/fsps/${fspId}/profile`, {
    returnModel: {
      name: 'fspprofile',
    },
  });
