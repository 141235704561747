import { pick } from 'ramda';
import { client, formatQueryParams } from 'util/sdk';

export const load = (params) => client.fsps.query(formatQueryParams(params));

export const loadById = (id) => client.fsps(id).get();

export const create = ({ distId, ...params }, auth) =>
  client.distributors(distId).fsps.create(params, { adminUserId: auth.id });

export const update = ({ id, ...params }, auth) => {
  return client
    .fsps(id)
    .update(
      pick(
        [
          'contactEmail',
          'contactPhone',
          'description',
          'facebookUrl',
          'fspStatus',
          'fspType',
          'instagramUrl',
          'name',
          'shortName',
          'twitterUrl',
          'uspsAddress',
          'websiteUrl',
        ],
        params
      ),
      { adminUserId: auth.id }
    );
};
