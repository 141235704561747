import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';
import { date, formatCurrency } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';
import { NA_VALUE } from 'consts';

const Context = createContext(undefined);

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  gridLabel: {
    marginRight: theme.spacing(1),
  },
  label: {
    color: 'inherit',
    fontFamily: theme.typography.fontFamily,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 100,
  },
  empty: {
    color: theme.palette.text.disabled,
    fontStyle: 'italic',
  },
}));

const ExpansionListItemColumnProvider = ({
  children,
  columnId,
  isCurrency,
  isDate,
  renderer,
  renderOptions,
  row,
}) => {
  const classes = useStyles();
  const dataToRender = row[columnId] ? row[columnId] : renderer ? row : '';
  const detail = isDate
    ? date(dataToRender)
    : isCurrency
    ? formatCurrency(dataToRender)
    : typeof dataToRender !== 'object'
    ? dataToRender
    : NA_VALUE;
  const rendererData =
    renderer && typeof renderer === 'function'
      ? renderer(dataToRender, row, false, renderOptions, classes)
      : null;

  const value = useMemo(
    () => ({ classes, detail, rendererData }),
    [classes, detail, rendererData]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

ExpansionListItemColumnProvider.propTypes = {
  children: PropTypes.node.isRequired,
  columnId: PropTypes.string.isRequired,
  isCurrency: PropTypes.bool,
  isDate: PropTypes.bool,
  renderer: PropTypes.func,
  renderOptions: PropTypes.object,
  row: PropTypes.object.isRequired,
};

ExpansionListItemColumnProvider.defaultProps = {
  isCurrency: false,
  isDate: false,
  renderer: null,
  renderOptions: {},
};

const useExpansionListItemColumn = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useExpansionListItemColumn must be used within a ExpansionListItemColumnProvider'
    );
  }

  return context;
};

export { ExpansionListItemColumnProvider, useExpansionListItemColumn };
