import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.RtnInfo);

export const selectorRoutingNumber = createSelector(
  orm,
  (state, routingNumber) => routingNumber,
  ({ RtnInfo }, routingNumber) =>
    RtnInfo.filter(
      ({ routingNumber: existingRoutingNumber }) =>
        existingRoutingNumber === routingNumber
    ).first()?.ref
);

export const selectorCount = createSelector(orm, ({ RtnInfo }) =>
  RtnInfo.count()
);

export const selectorSelected = createSelector(orm, ({ RtnInfo }) =>
  RtnInfo.filter(({ selected }) => selected).toRefArray()
);
