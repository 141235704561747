import PropTypes from 'prop-types';

const UserType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  userLogin: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  mfaQuestion: PropTypes.string.isRequired,
  mobilePhone: PropTypes.string.isRequired,
  workPhone: PropTypes.string.isRequired,
  adminOrg: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    adminGroupId: PropTypes.number,
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
});

export default UserType;
