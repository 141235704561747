import PropTypes from 'prop-types';
import { DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { IconButtonWithTooltip } from 'components/common';
import { makeStyles } from '@material-ui/core/styles';
import { ZoomIn, ZoomOut, ZoomOutMap, Close } from '@material-ui/icons';

const scaleMax = 200;
export const scaleStep = 20;
export const scaleMin = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    paddingBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const PdfDialogTitle = ({
  accountNumberMask,
  billOn,
  name,
  onClose,
  onFitToWidth,
  onZoomIn,
  onZoomOut,
  scale,
}) => {
  const classes = useStyles();

  const title = `${name} (${accountNumberMask}) ${billOn}`;

  return (
    <DialogTitle className={classes.root}>
      <Typography component="div" variant="h6">
        {title}
      </Typography>
      <Grid container justifyContent="flex-end">
        <IconButtonWithTooltip
          disabled={scale === scaleMin}
          Icon={ZoomOutMap}
          onClick={onFitToWidth}
          testId="fitToWidth"
          tip="Fit To Width"
        />
        <IconButtonWithTooltip
          disabled={scale === scaleMin}
          Icon={ZoomOut}
          onClick={onZoomOut}
          testId="zoomOut"
          tip="Zoom Out"
        />
        <IconButtonWithTooltip
          disabled={scale === scaleMax}
          Icon={ZoomIn}
          onClick={onZoomIn}
          testId="zoomIn"
          tip="Zoom In"
        />
      </Grid>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <Close />
      </IconButton>
    </DialogTitle>
  );
};

PdfDialogTitle.propTypes = {
  accountNumberMask: PropTypes.string.isRequired,
  billOn: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onFitToWidth: PropTypes.func.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
};

export default PdfDialogTitle;
