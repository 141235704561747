import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { SkeletonTheme } from 'react-loading-skeleton';
import { always } from 'ramda';
import { makeStyles, Avatar, Grid, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  avatarColorDefault: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.text.primary} !important`,
  },
  avatarRoot: {
    '&:hover': {
      color: `${theme.palette.background.default}  !important`,
      backgroundColor: `${theme.palette.primary.main}  !important`,
    },
    height: `${22}px !important`,
    width: `${22}px !important`,
  },
  icon: {
    fontSize: 16,
  },
  item: {
    minWidth: 140,
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const BaseLabel = ({ LabelValue, onClose, showIcon, ...props }) => {
  const classes = useStyles();
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClose(event, { ...props });
  };

  return (
    <SkeletonTheme color="#bbb" highlightColor="#eee">
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={2}
        wrap="nowrap"
      >
        <Grid className={classes.item} item xs>
          <Tooltip enterDelay={1000} title={LabelValue}>
            <div className={classes.label}>{LabelValue}</div>
          </Tooltip>
        </Grid>
        {showIcon && (
          <Grid item>
            <Avatar
              classes={{
                colorDefault: classes.avatarColorDefault,
                root: classes.avatarRoot,
              }}
              onClick={handleClick}
            >
              <Close className={classes.icon} />
            </Avatar>
          </Grid>
        )}
      </Grid>
    </SkeletonTheme>
  );
};

BaseLabel.propTypes = {
  LabelValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  onClose: PropTypes.func,
  showIcon: PropTypes.bool,
};

BaseLabel.defaultProps = {
  onClose: always,
  showIcon: true,
};

export default BaseLabel;
