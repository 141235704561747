import { LOAD, ADD, DELETE, UPDATE, FORM_NAME } from 'model/role';
import { loadRoles, addRole, deleteRole, updateRole } from 'sdk/role';
import { takeEvery } from 'redux-saga/effects';
import { callAction } from '../util';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: addRole,
      formName: FORM_NAME,
      formatSuccessMessage: (data) => `${data.name} Role has been created.`,
      type: ADD,
    })
  );

  yield takeEvery(
    DELETE.ACTION,
    callAction({
      api: deleteRole,
      formatSuccessMessage: (data) => `${data.name} Role has been removed.`,
      type: DELETE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: loadRoles,
      type: LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: updateRole,
      formName: FORM_NAME,
      formatSuccessMessage: (data) => `${data.name} Role has been updated.`,
      type: UPDATE,
    })
  );
}
