import { client, formatQueryParams } from 'util/sdk';

export const create = (name, auth) =>
  client.billerSets.create({ name }, { adminUserId: auth.id });

export const load = (params) =>
  client.billerSets.query(formatQueryParams(params));

export const loadById = (id) => client.billerSets(id).get();

export const remove = (id, auth) =>
  client.billerSets(id).delete({ adminUserId: auth.id });

export const update = ({ id, ...data }, auth) =>
  client.billerSets(id).update(data, { adminUserId: auth.id });
