import ExpandablePanel from 'components/common/ExpandablePanel';
import { memo, useEffect, useState } from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { AttachMoney, DonutSmall, Receipt } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD } from 'model/autopayScheduled';
import { update } from 'model/pageSetting';
import { selector as busySelector } from 'selectors/busy';
import { selector as pageSettingsSelector } from 'selectors/pageSetting';
import { creditPayOnSelector } from 'selectors/userFspConfig';
import { useEBillEnabledSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import Spacer from 'components/common/spacer';
import { AUTOPAY } from '../Autopay';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';
import EBill from './EBill';
import Credit from './Credit';
import { pageSettingsName } from './config';

const EBILL = { label: 'E-Bill', value: 'eBill' };
const CREDIT = { label: 'Credit', value: 'credit' };

const useStyles = makeStyles({
  chip: {
    backgroundColor: 'transparent',
    fontSize: '0.95rem',
    fontWeight: 400,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },
});

const AutopayScheduled = ({ fspId, payerId }) => {
  const [type, setType] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const isEBillEnabled = useEBillEnabledSelector(fspId, payerId);
  const isCreditPayOn = useSelector((state) =>
    creditPayOnSelector(state, fspId, payerId)
  );
  const { autopayType } = useSelector((state) =>
    pageSettingsSelector(state, payerId, pageSettingsName)
  );

  const handleToggleChange = (event, value) => {
    if (value !== null) {
      dispatch(
        update({ autopayType: value, page: 0, payerId, type: pageSettingsName })
      );
    }
  };

  useEffect(() => {
    if (
      (autopayType === EBILL.value && isEBillEnabled) ||
      (autopayType === CREDIT.value && isCreditPayOn)
    ) {
      setType(autopayType);
    } else {
      setType(isEBillEnabled ? EBILL.value : CREDIT.value);
    }
  }, [autopayType, isCreditPayOn, isEBillEnabled]);

  return isEBillEnabled || isCreditPayOn ? (
    <ExpandablePanel
      endAdornment={
        isEBillEnabled && isCreditPayOn ? (
          <ToggleButtonGroup
            exclusive
            onChange={handleToggleChange}
            value={type}
          >
            <ToggleButton disabled={!isEBillEnabled} value={EBILL.value}>
              <Receipt />
              <Spacer />
              {EBILL.label}
            </ToggleButton>
            <ToggleButton disabled={!isCreditPayOn} value={CREDIT.value}>
              <DonutSmall />
              <Spacer />
              {CREDIT.label}
            </ToggleButton>
          </ToggleButtonGroup>
        ) : isEBillEnabled ? (
          <Chip
            classes={{ root: classes.chip }}
            icon={<Receipt />}
            label={EBILL.label}
          />
        ) : (
          <Chip
            classes={{ root: classes.chip }}
            icon={<DonutSmall />}
            label={CREDIT.label}
          />
        )
      }
      heightOffset={HEIGHT_OFFSET}
      icon={AttachMoney}
      id={pageSettingsName}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title={`${AUTOPAY} Scheduled`}
    >
      {type === EBILL.value ? (
        <EBill fspId={fspId} payerId={payerId} />
      ) : type === CREDIT.value ? (
        <Credit fspId={fspId} payerId={payerId} />
      ) : null}
    </ExpandablePanel>
  ) : null;
};

AutopayScheduled.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(AutopayScheduled);
