import { add, load, remove, update } from 'sdk/corsDomain';
import { ADD, LOAD, REMOVE, UPDATE } from 'model/corsDomain';
import { callAction, ORM } from 'util/index';
import {
  CORS_DOMAIN_CODE,
  CORS_DOMAIN_LABEL,
  formatSuccessMessage,
  ADDED,
  DELETED,
  UPDATED,
} from 'consts';
import { takeEvery } from 'redux-saga/effects';

const keyName = { key: CORS_DOMAIN_CODE, name: CORS_DOMAIN_LABEL };

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage(keyName, ADDED),
      type: ADD,
    })
  );
  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage(keyName, UPDATED),
      type: UPDATE,
    })
  );
  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: formatSuccessMessage(keyName, DELETED),
      type: REMOVE,
    })
  );
}
