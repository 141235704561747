import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import { useIsBusy } from 'hooks';

const useStyles = makeStyles(() => ({
  progress: {
    height: 2,
  },
}));

const TableContainerProgress = ({
  actionType,
  actionTypeUpdate,
  showProgress,
}) => {
  const classes = useStyles();
  const isBusy = useIsBusy(actionType);
  const isBusyUpdating = useIsBusy(actionTypeUpdate);

  return showProgress ? (
    isBusy || isBusyUpdating ? (
      <LinearProgress className={classes.progress} />
    ) : (
      <div className={classes.progress} />
    )
  ) : null;
};

TableContainerProgress.propTypes = {
  actionType: PropTypes.object.isRequired,
  actionTypeUpdate: PropTypes.object,
  showProgress: PropTypes.bool.isRequired,
};

TableContainerProgress.defaultProps = {
  actionTypeUpdate: {},
};

export default memo(TableContainerProgress);
