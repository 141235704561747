import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { LinearProgressBusy } from 'components/common';

const useStyles = makeStyles((theme) => ({
  details: {
    flexDirection: 'column',
    maxHeight: 300,
    overflowY: 'auto',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(13),
    marginBottom: theme.spacing(1),
  },
  root: {
    width: '100%',
  },
}));

const Accordion = ({ children, actionConst, title, subheader }) => {
  const classes = useStyles();

  return (
    <MuiAccordion className={classes.root}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {subheader && (
          <Typography className={classes.subHeading}>{subheader}</Typography>
        )}
        <Grid container direction="column">
          <Grid item>
            <LinearProgressBusy actionConst={actionConst} />
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  actionConst: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};

Accordion.defaultProps = {
  subheader: '',
};

export default Accordion;
