import { client, formatQueryParams } from 'util/sdk';
import {
  JOB_STATUS_CANCEL,
  JOB_STATUS_DONE,
  JOB_TYPE_SNAPSHOT,
} from 'model/util/automatchJob';
import { DESC } from 'consts';

const lastSnapshotParams = {
  limit: 1,
  orderById: DESC,
  status: JOB_STATUS_DONE,
  type: JOB_TYPE_SNAPSHOT,
};

export const cancel = (id, auth) =>
  client
    .automatchJobs(id)
    .update({ status: JOB_STATUS_CANCEL }, { adminUserId: auth.id });

export const create = (template, auth) =>
  client.automatchJobs.create(
    { templateName: template.name, type: template.lastJobType },
    { adminUserId: auth.id }
  );

export const createSnapshot = (template, auth) =>
  client.automatchJobs.create(
    { type: JOB_TYPE_SNAPSHOT },
    { adminUserId: auth.id }
  );

export const lastSnapshot = () =>
  client.automatchJobs.query(formatQueryParams(lastSnapshotParams));

export const load = (params) =>
  client.automatchJobs.query(formatQueryParams(params));

export const remove = (id, auth) =>
  client.automatchJobs(id).delete({ adminUserId: auth.id });
