import MomentUtils from '@date-io/moment';
import { useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { isNil } from 'ramda';
import { updateCustom } from 'model/limitRule';
import { useDispatch } from 'react-redux';
import { useLimits } from '../LimitsContext';

const LimitsExpire = () => {
  const { limitRule } = useLimits();
  const [disabled, setDisabled] = useState(true);
  const [noExpireDate, setNoExpireDate] = useState(
    isNil(limitRule.expireDateDerived)
  );
  const [selectedDate, setSelectedDate] = useState(
    limitRule.expireDateDerived || limitRule.nowDerived
  );
  const dispatch = useDispatch();

  const handleCheckboxChange = useCallback(
    (event, checked) => {
      setDisabled(checked);
      setNoExpireDate(checked);

      limitRule.expireMonth = checked ? null : (selectedDate?.month() ?? 0) + 1;
      limitRule.expireYear = checked ? null : selectedDate?.year();

      dispatch(updateCustom(limitRule));
    },
    [dispatch, limitRule, selectedDate]
  );
  const handleDateChange = useCallback(
    (value) => {
      setSelectedDate(value);

      limitRule.expireMonth = value.month() + 1;
      limitRule.expireYear = value.year();

      dispatch(updateCustom(limitRule));
    },
    [dispatch, limitRule]
  );
  const handleMouseUp = useCallback(() => {
    if (disabled) {
      setDisabled(false);
    }

    handleCheckboxChange(null, false);
    dispatch(updateCustom(limitRule));
  }, [disabled, dispatch, handleCheckboxChange, limitRule]);

  useEffect(() => {
    setDisabled(noExpireDate);
  }, [noExpireDate]);

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={noExpireDate}
              color="primary"
              onChange={handleCheckboxChange}
            />
          }
          label="No Expiration Date"
        />
      </Grid>
      <Grid item onMouseUp={handleMouseUp}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            disabled={disabled}
            disablePast
            helperText="Limit expires last day of the month"
            label="Expiration Date"
            onChange={handleDateChange}
            openTo="year"
            value={selectedDate}
            views={['year', 'month']}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default LimitsExpire;
