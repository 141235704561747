import { DESC } from 'consts';

export const columns = [
  {
    id: 'activeAtLabel',
    label: 'Activated Date',
    xs: 2,
  },
  {
    context: true,
    id: 'payeeName',
    label: 'Biller Name',
    xs: 2,
  },
  {
    capitalize: true,
    id: 'insightStatus',
    label: 'Status',
    xs: 2,
  },
  {
    id: 'renderedMessage',
    label: 'Message',
    xs: 6,
  },
];

export const defaultOrderBy = 'created';

export const pageSettingsName = 'missedPayment';

export const defaultPageSettings = {
  order: DESC,
  orderBy: defaultOrderBy,
};
