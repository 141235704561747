/* eslint-disable react/display-name */
const allowAll = () => true;

const style = { padding: 4 };

export const ACTIVE_TO_SUSPENDED_MSG = (extras) => () => {
  return (
    <>
      <div style={style}>
        Any transactions scheduled for processing during the suspension period
        will be marked as <strong>not processed due to suspension</strong>.
      </div>
      <div style={style}>
        Any scheduled transactions that fall outside the suspension period will
        be processed after the payer is re-activated.
      </div>
      <div style={style}>
        All payees, recipients, and external accounts will remain.
      </div>
      <div style={style}>Suspend {extras}</div>
    </>
  );
};

const ACTIVE_TO_INACTIVE_MSG = (extras) => () => {
  return (
    <>
      <div style={style}>Any scheduled transactions will be deleted.</div>
      <div style={style}>
        All payees, recipients, and external accounts will remain.
      </div>
      <div style={style}>Deactivate {extras}</div>
    </>
  );
};

const SUSPENDED_TO_ACTIVE_MSG = (extras) => () => {
  return (
    <>
      <div style={style}>
        Any transactions previously scheduled for processing during the
        suspension period has been marked as{' '}
        <strong>not processed due to suspension</strong>.
      </div>
      <div style={style}>
        Any remaining scheduled transactions will continue to process during the
        regular processing window.
      </div>
      <div style={style}>
        All payees, recipients, and external accounts remain.
      </div>
      <div style={style}>Activate {extras}</div>
    </>
  );
};

const SUSPENDED_TO_INACTIVE_MSG = (extras) => () => {
  return (
    <>
      <div style={style}>
        Any transactions previously scheduled for processing during the
        suspension period has been marked as{' '}
        <strong>not processed due to suspension</strong>.
      </div>
      <div style={style}>
        Any remaining scheduled transactions will be deleted.
      </div>
      <div style={style}>
        All payees, recipients, and external accounts remain.
      </div>
      <div style={style}>Access will be denied for this Payer.</div>
      <div style={style}>Deactivate {extras}</div>
    </>
  );
};

const INACTIVE_TO_ACTIVE_MSG = (extras) => () => {
  return (
    <>
      <div style={style}>
        Prior scheduled transactions were deleted during deactivation.
      </div>
      <div style={style}>
        All payees, recipients, and external accounts remain for future
        scheduling of transactions.
      </div>
      <div style={style}>Activate {extras}</div>
    </>
  );
};

export const statusList = [
  { key: 'payerProfileActive', name: 'Active', value: 'active' },
  { key: 'payerProfileSuspended', name: 'Suspended', value: 'suspended' },
  { key: 'payerProfileInactive', name: 'Inactive', value: 'inactive' },
];

export const statusConfig = {
  active: {
    suspended: {
      label: 'Suspend',
      message: ACTIVE_TO_SUSPENDED_MSG,
      requiredPermission: allowAll,
    },
    inactive: {
      label: 'Deactivate',
      message: ACTIVE_TO_INACTIVE_MSG,
      requiredPermission: allowAll,
    },
  },
  suspended: {
    active: {
      label: 'Activate',
      message: SUSPENDED_TO_ACTIVE_MSG,
      requiredPermission: allowAll,
    },
    inactive: {
      label: 'Deactivate',
      message: SUSPENDED_TO_INACTIVE_MSG,
      requiredPermission: allowAll,
    },
  },
  inactive: {
    active: {
      label: 'Activate',
      message: INACTIVE_TO_ACTIVE_MSG,
      requiredPermission: allowAll,
    },
  },
};
