import { attr, fk, Model } from 'redux-orm';
import { isNil } from 'ramda';
import moment from 'moment';
import Payee from 'model/payee';
import Payer from 'model/payer';
import { ACTIVE_LABEL, ON, NONE_LABEL } from 'consts';
import {
  createAction,
  createLoadConst,
  formatCurrency,
  formatMonth,
  date,
  dateAndTime,
} from '../util';

const payAmountMap = {
  fullBal: 'Full Balance',
  minAmt: 'Min Amount Due',
  twiceMinAmt: 'Twice Min Amount',
};

const autopayStatusMap = {
  created: 'Created',
  no_stmt: 'No Statement',
  skip: 'Skipped',
};

const payWhenMap = (autopay) => {
  const daysPriorDue = autopay?.daysPriorDue;

  return {
    onBillArrived: 'When Bill Arrives',
    onDueDate: 'On Due Date',
    onFixedDay: `On Day ${autopay?.dayOfMonth} of Each Month`,
    priorDueDate: `${daysPriorDue} ${
      daysPriorDue > 1 ? 'Days' : 'Day'
    } before Due Date`,
  };
};

export const derived = (autopay) => {
  const {
    amountDue,
    autopayId,
    autopayMonth,
    autopayStatus,
    autopayYear,
    billGreaterThanAmount,
    billGreaterThanCustomAmount,
    dueOn,
    foreverOffOn,
    minimumAmount,
    payAmount,
    payWhen,
    scheduleAmount,
    skipReason,
    scheduleCreatedAt,
    scheduleEndOn,
    suspendUntilOn,
  } = autopay;

  const suspendUntil = moment(suspendUntilOn);
  const statusDerived =
    !isNil(suspendUntil) && moment().diff(suspendUntil) < 0
      ? `Suspended (Until ${suspendUntil.format('MM-DD-YYYY')})`
      : 'Enabled';
  const billGreaterThanAmountDerived = billGreaterThanAmount
    ? formatCurrency(billGreaterThanAmount)
    : '';
  const billGreaterThanCustomAmountDerived = billGreaterThanCustomAmount
    ? formatCurrency(billGreaterThanCustomAmount)
    : '';

  let autopayStatusDerived =
    autopayStatusMap[autopayStatus] || 'Statement Pending';
  if (autopayStatus === 'skip' && skipReason) {
    autopayStatusDerived = `${autopayStatusDerived} (${skipReason})`;
  }

  return {
    ...autopay,
    amountDueDerived: formatCurrency(amountDue),
    autopayRuleStatusDerived: autopayId === null ? NONE_LABEL : ACTIVE_LABEL,
    autopayStatusDerived,
    dueOnDerived: date(dueOn),
    minimumAmountDerived: formatCurrency(minimumAmount),
    scheduleAmountDerived: formatCurrency(scheduleAmount),
    statementPeriodDerived: `${formatMonth(autopayMonth)}, ${autopayYear}`,
    billGreaterThanAmountDerived,
    billGreaterThanCustomAmountDerived,
    payAmountDerived: payAmountMap[payAmount],
    payWhenDerived: payWhenMap(autopay)[payWhen],
    statusDerived,
    scheduleCreatedAtDerived: dateAndTime(scheduleCreatedAt),
    scheduleEndOnDerived: foreverOffOn === ON ? 'Never' : date(scheduleEndOn),
    suspendUntilOnDerived: date(suspendUntilOn),
  };
};

export default class AutopayScheduled extends Model {
  static get modelName() {
    return 'AutopayScheduled';
  }

  static reducer({ type, data }, AutopayScheduled) {
    switch (type) {
      case LOAD.SUCCESS: {
        AutopayScheduled.delete();
        (data?.response || []).forEach((autopay) => {
          AutopayScheduled.upsert(derived(autopay));
        });
        break;
      }
      case LOAD_SCHED_ID.SUCCESS: {
        (data.response || []).forEach((autopay) => {
          AutopayScheduled.upsert(derived(autopay));
        });
        break;
      }

      case LOAD.FAILURE:
        AutopayScheduled.delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      amountDue: attr(),
      amountDueDerived: attr(), // derived
      autopayConfirmationNumber: attr(),
      autopayRuleStatusDerived: attr(), // derived
      autopayStatus: attr(),
      autopayStatusDerived: attr(), // derived
      billGreaterThanAmount: attr(),
      billGreaterThanCustomAmount: attr(),
      billGreaterThanNotPayOffOn: attr(),
      confirmationNumber: attr(),
      createdAt: attr(),
      dayOfMonth: attr(),
      daysPriorDue: attr(),
      ddaAccountName: attr(),
      ddaId: attr(),
      description: attr(),
      dueOn: attr(),
      dueOnDerived: attr(), // derived
      externalDdaId: attr(),
      foreverOffOn: attr(),
      frequency: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUserId: attr(),
      minimumAmount: attr(),
      minimumAmountDerived: attr(), // derived
      nextDeliverOn: attr(),
      nextSendOn: attr(),
      offset: attr(),
      payAmount: attr(),
      payAmountDerived: attr(), // derived
      payeeId: fk(Payee.modelName, 'autopayScheduled'),
      payeeName: attr(),
      payWhen: attr(),
      payWhenDerived: attr(), // derived
      payerId: fk(Payer.modelName, 'autopayScheduled'),
      scheduleAmount: attr(),
      scheduleAmountDerived: attr(), // derived
      scheduleCreatedAt: attr(),
      scheduleCreatedAtDerived: attr(), // derived
      scheduleEndOn: attr(),
      scheduleEndOnDerived: attr(), // derived
      schedId: attr(),
      skipReason: attr(),
      suspendUntilOn: attr(),
      suspendUntilOnDerived: attr(), // derived
      startOn: attr(),
      statementPeriodDerived: attr(), // derived
      times: attr(),
      updAdminUserId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = AutopayScheduled.modelName;

export const LOAD = createLoadConst(`${name}`);
export const LOAD_SCHED_ID = createLoadConst(`${name}_sched_id`);

export const load = createAction(LOAD);
export const loadBySchedId = createAction(LOAD_SCHED_ID);
