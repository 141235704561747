import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

const fix = (scope) => {
  if (scope.name.endsWith('Str')) {
    scope.displayName = scope.name.substring(0, scope.name.length - 3);
    // scope.description = 'test description for STR suffix';
  } else {
    scope.displayName = scope.name;
  }

  return scope;
};

export default class AutomatchScope extends Model {
  static get modelName() {
    return 'AutomatchScope';
  }

  static reducer({ type, data = [] }, AutomatchScope) {
    switch (type) {
      case LOAD.SUCCESS:
        AutomatchScope.delete();
        data.response.forEach((r) => AutomatchScope.create(fix(r)));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      description: attr(),
      displayName: attr(),
      name: attr(),
      type: attr(),
    };
  }
}

export const LOAD = createLoadConst(AutomatchScope.modelName);
export const load = createAction(LOAD);
