import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { otpConfig } from 'selectors/userFspConfig';
import { useSelector } from 'react-redux';
import { selectorGeneratedByPayerId as selector } from 'selectors/oneTimePasscode';
import Digit from './Digit';

const Digits = ({ fspId, payerId }) => {
  const config = useSelector((state) => otpConfig(state, fspId, payerId));
  const passcode = useSelector((state) => selector(state, payerId));

  const otpDigitLength = config?.otpDigit;
  const blankPasscode = ' '.repeat(otpDigitLength || 6);

  return (
    <Grid container spacing={1}>
      {Array.from(passcode || blankPasscode).map((digit, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={index} item>
          <Digit digit={digit} />
        </Grid>
      ))}
    </Grid>
  );
};

Digits.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default Digits;
