import LoadingSkeleton from 'react-loading-skeleton';
import SkeletonIcon from 'components/common/skeletonIcon';
import { BUSINESS_CODE, IS_EMPTY } from 'consts';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { makeStyles } from '@material-ui/core/styles';
import { orm } from 'model';
import { getLabel } from '../util';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const Skeleton = (props) => {
  const classes = useStyles();

  return (
    <p className={classes.root}>
      <LoadingSkeleton {...props} />
    </p>
  );
};

const SKELETON_15 = <Skeleton width="15%" />;
const SKELETON_25 = <Skeleton width="25%" />;
const SKELETON_32 = <Skeleton width="32%" />;
const SKELETON_45 = <Skeleton width="45%" />;
const SKELETON_55 = <Skeleton width="55%" />;
const SKELETON_70 = <Skeleton width="70%" />;
const SKELETON_LABEL = <Skeleton height={24} width={150} />;
const SKELETONS = {
  address: SKELETON_55,
  cityState: SKELETON_45,
  code: SKELETON_25,
  email: SKELETON_70,
  fspPayerIdMask: SKELETON_15,
  isEmpty: true,
  label: SKELETON_LABEL,
  labelWithId: SKELETON_45,
  payerIcon: SkeletonIcon,
  payerId: SKELETON_15,
  phone: SKELETON_32,
  serviceStartOnLabel: SKELETON_25,
  status: SKELETON_15,
  statusAtLabel: SKELETON_45,
  statusText: SKELETON_25,
};

export const selector = createSelector(orm, ({ Payer }) =>
  Payer.filter('_last').toRefArray()
);

const ormSelector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, arg) => {
    const id = typeof arg === 'object' ? arg.id : arg;
    let item;

    if (Payer.idExists(id)) {
      item = Payer.withId(id);
      item.set(IS_EMPTY, false);
    } else {
      item = Payer.upsert({ id });
      item.set(IS_EMPTY, true);
    }

    return item;
  }
);

export const selectorId = createSelector(orm.Payer);

export const selectorAsLabel = createReselectSelector(ormSelector, (item) =>
  getLabel(item.ref)
);

export const selectorAsName = ormSelector;

export const profileSelector = createReselectSelector(ormSelector, (item) =>
  item.isEmpty ? SKELETONS : item.ref
);

export const isBusinessSelector = createReselectSelector(
  selectorId,
  ({ payerType }) => payerType === BUSINESS_CODE
);

export const selectorFirstByFspId = createSelector(
  orm,
  (state, fspId) => fspId,
  ({ Payer }, fspId) => {
    return Payer.filter(({ zfspId }) => zfspId === fspId)?.first()?.ref;
  }
);

export const selectorByStatusOrType = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { status, type }) => {
    return Payer.filter((payer) => {
      if (status && type) {
        return payer.status === status && payer.payerType === type;
      }

      if (status) {
        return payer.status === status;
      }

      if (type) {
        return payer.payerType === type;
      }

      return true;
    }).toRefArray();
  }
);
