import utils from '@date-io/moment';
import PropTypes from 'prop-types';
import {
  TimePicker as TimePickerComponent,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const TimePicker = ({ value, label, ...props }) => {
  return (
    <MuiPickersUtilsProvider utils={utils}>
      <TimePickerComponent {...props} label={label} value={value || null} />
    </MuiPickersUtilsProvider>
  );
};

TimePicker.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

TimePicker.defaultProps = {
  label: undefined,
  value: null,
};

export default TimePicker;
