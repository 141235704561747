import PropTypes from 'prop-types';
import { always } from 'ramda';
import InputRefType from 'types/inputRef';
import { TextFieldNumber } from 'components/common/fields';

const ParamValueNumber = ({
  disabled,
  inputRef,
  onChange,
  onKeyPress,
  value,
}) => {
  return (
    <TextFieldNumber
      disabled={disabled}
      fullWidth
      inputRef={inputRef}
      label="Value"
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
    />
  );
};

ParamValueNumber.propTypes = {
  disabled: PropTypes.bool,
  inputRef: InputRefType,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ParamValueNumber.defaultProps = {
  disabled: false,
  inputRef: undefined,
  onKeyPress: always,
  value: '',
};

export default ParamValueNumber;
