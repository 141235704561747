import { client, formatQueryParams } from 'util/sdk';
import { ROWS_PER_PAGE_MAX } from '../consts';

export const load = (params, { id }) =>
  client.admin.users(id).groups.query(formatQueryParams(params));

export const loadByOrgId = ({ organizationId, ...props }) => {
  /** 04/05/2021 https://payrailz.atlassian.net/browse/ON-1125 */
  const params = { ...props, limit: ROWS_PER_PAGE_MAX };

  return client.admin
    .organizations(organizationId)
    .groups.query(formatQueryParams(params));
};

export const addGroup = (data, auth) =>
  client.admin.groups.create(data, { adminUserId: auth.id });

export const deleteGroup = ({ id }, auth) =>
  client.admin.groups(id).delete({ adminUserId: auth.id });

export const updateGroup = (
  { description, fspAccesses, groupAdmins, id, name, roles },
  auth
) =>
  client.admin
    .groups(id)
    .update(
      { description, fspAccesses, groupAdmins, name, roles },
      { adminUserId: auth.id }
    );
