import CardAddress from 'components/common/Card/CardAddress';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';

const BusinessAccount = ({ title, uspsAddress }) => {
  return title ? (
    <Grid item xs={2}>
      <ReadOnlyFormControl
        inputNode={
          <ReadOnlyInputWithMenu
            label={title}
            menuNode={
              <CardAddress
                subheader="Address on Account"
                title={title}
                uspsAddress={uspsAddress}
              />
            }
            tooltip="Address on Account"
          />
        }
        label="Name on Account"
        value={title}
      />
    </Grid>
  ) : null;
};

BusinessAccount.propTypes = {
  title: PropTypes.string,
  uspsAddress: PropTypes.object,
};

BusinessAccount.defaultProps = {
  title: null,
  uspsAddress: null,
};

export default BusinessAccount;
