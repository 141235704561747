import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { last } from 'ramda';
import { date, isSameDay, time } from 'util/dateAndTime';

const KEY = 'createdAt';

export const selectorCount = createSelector(
  orm,
  (state, props) => props,
  ({ SSOErrorLog }, { id }) =>
    SSOErrorLog.filter(({ payerId }) => payerId === id).count()
);

const selector = createSelector(
  orm,
  (state, props) => props,
  ({ SSOErrorLog }, { id }) =>
    SSOErrorLog.filter(({ payerId }) => payerId === id).toModelArray()
);

const convertToDays = (days, item = {}, index) => {
  item.set('time', time(item[KEY]));
  item.set('title', item.response);
  item.set('byAdminUserId', null);
  item.set('byUserId', null);

  if (index === 0 || !days.some((day) => isSameDay(day.value, item[KEY]))) {
    days.push({
      id: index,
      items: item && item.ref ? [item.ref] : [],
      title: date(item[KEY]),
      value: item[KEY],
    });
  } else {
    last(days).items.push(item && item.ref ? item.ref : {});
  }

  return days;
};

export const selectorGroupedByDays = createReselectSelector(selector, (items) =>
  items.reduce(convertToDays, [])
);
