import PropTypes from 'prop-types';
import { compose, withHandlers, withState } from 'recompose';

import {
  FormLabel,
  FormControl,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';

const styles = () => ({
  root: {},
});

const CheckContainer = ({
  title,
  label,
  checkedValue,
  disabled,
  classes,
  component: CheckComponent,
  handleChange,
  value,
  labelValue,
}) => {
  return (
    <FormControl className={classes.root} t-i="checkInput">
      {title && <FormLabel>{title}</FormLabel>}
      <FormControlLabel
        control={
          <CheckComponent
            checked={value === checkedValue}
            color="primary"
            disabled={disabled}
            onChange={handleChange}
            value={value}
          />
        }
        label={labelValue ? value : label || ''}
      />
    </FormControl>
  );
};

CheckContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  checkedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelValue: PropTypes.bool,
};

CheckContainer.defaultProps = {
  disabled: false,
  title: '',
  label: '',
  labelValue: false,
};

export default compose(
  withState('value', 'setValue', ({ uncheckedValue }) => uncheckedValue),
  withHandlers({
    handleChange:
      ({ checkedValue, uncheckedValue, setValue, onChange }) =>
      (event, checked) => {
        const value = checked ? checkedValue : uncheckedValue;
        setValue(value);
        if (onChange) {
          onChange(value);
        }
      },
  })
)(withStyles(styles)(CheckContainer));
