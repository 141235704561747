import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { compose, pure, setDisplayName, withProps } from 'recompose';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    color: 'rgba(113, 94, 94, 0.87)',
    fontSize: 14,
    fontWeight: 600,
    paddingRight: 20,
    paddingBottom: 0,
    height: 42,
    borderLeft: 0,
    width: 'auto',
  },
  selected: {
    color: `${theme.palette.primary.main}`,
    borderLeft: 0,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  selectedIcon: { color: `${theme.palette.primary.main}` },
});

const MenuItem = ({
  path,
  classes,
  iconComponent: IconComponent,
  name,
  selected,
  text,
}) => {
  return (
    <ListItem
      button
      className={classNames(classes.root, {
        [classes.selected]: selected,
      })}
      component={Link}
      t-i={`${name}Menu`}
      to={path}
    >
      {IconComponent && (
        <ListItemIcon>
          <IconComponent
            className={classNames({
              [classes.selectedIcon]: selected,
            })}
          />
        </ListItemIcon>
      )}
      <ListItemText primary={text} />
    </ListItem>
  );
};

MenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  iconComponent: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
  iconComponent: null,
};

export default compose(
  withStyles(styles),
  setDisplayName('MenuItem'),
  withProps(({ clickedItemName, name, location, path }) => ({
    selected: clickedItemName === name || location.pathname.indexOf(path) === 0,
  })),
  pure
)(MenuItem);
