import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import { isRefund } from '../consts';

const DisburseState = ({ transaction }) => {
  return isRefund(transaction) ? (
    <Grid item xs={2}>
      <ReadOnlyFormControl
        label="Disburse State"
        multiline
        value={transaction.disburseStateDerived}
      />
    </Grid>
  ) : null;
};

DisburseState.propTypes = {
  transaction: PropTypes.shape({
    disburseStateDerived: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default DisburseState;
