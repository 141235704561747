import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import { createSelector } from 'redux-orm';
import { makeStyles } from '@material-ui/core/styles';
import { orm } from 'model';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    marginTop: theme.spacing(2),
  },
}));

const Skeleton = (props) => {
  const classes = useStyles();

  return <MuiSkeleton className={classes.skeleton} {...props} />;
};

const defaultValue = {
  userInfo: {
    email: <Skeleton width={120} />,
    emailLastUpdatedAtLabel: <Skeleton width={80} />,
    manualVerificationLabel: <Skeleton width={80} />,
    manualVerificationRequired: <Skeleton width={150} />,
    mobilePhoneLabel: <Skeleton width={90} />,
    mobilePhoneLastUpdatedAtLabel: <Skeleton width={80} />,
    status: <Skeleton width={60} />,
    serviceStartOn: <Skeleton width={100} />,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const selector = createSelector(orm, ({ OneTimePasscodeInfo }) =>
  OneTimePasscodeInfo.count() ? OneTimePasscodeInfo.first().ref : defaultValue
);
