import { ON } from 'consts';
import { forEach } from 'ramda';
// eslint-disable-next-line import/prefer-default-export
export const addDerivedData = (item) => {
  item.byAdminUserId = item.updAdminUserId;
  item.bySystemUser = item.updSysUser;
  item.byUserId = item.updUserId;
  item.logAt = item.insAt;
  item.logOp = item.insUpdateOp;
  item.changelog.forEach((changelog) => {
    if (
      changelog.basicViewOffOn === undefined ||
      changelog.basicViewOffOn === null
    ) {
      changelog.basicViewOffOn = ON;
    }
  });

  return item;
};
