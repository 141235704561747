import { dateAndTime } from '../../util';

/* Job Statuses */
export const JOB_STATUS_CANCEL = 'cancel';
export const JOB_STATUS_DONE = 'done';
export const JOB_STATUS_EXEC = 'exec';
export const JOB_STATUS_START = 'start';
export const JOB_STATUS_WAIT = 'wait';

/* Job Types */
export const JOB_TYPE_PREVIEW = 'template_preview';
export const JOB_TYPE_SNAPSHOT = 'snapshot';
export const JOB_TYPE_UPDATE = 'template_update';

/* Active statuses */
export const JOB_STATUSES_ACTIVE = [
  JOB_STATUS_EXEC,
  JOB_STATUS_START,
  JOB_STATUS_WAIT,
];

/* Complete statuses */
export const JOB_STATUSES_COMPLETE = [JOB_STATUS_CANCEL, JOB_STATUS_DONE];

export const addDerived = (r) => {
  r.cancelAtLabel = dateAndTime(r.cancelAt);
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.finishAtLabel = dateAndTime(r.doneAt);
  r.startAtLabel = dateAndTime(r.startAt);

  switch (r.type) {
    case JOB_TYPE_UPDATE:
      r.typeLabel = 'Update';
      break;

    case JOB_TYPE_PREVIEW:
      r.typeLabel = 'Preview';
      break;

    case JOB_TYPE_SNAPSHOT:
      r.typeLabel = 'Snapshot';
      break;

    default:
      r.typeLabel = 'Unknown';
  }

  return r;
};
