import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DIALOG_URI } from 'components/common/Tabs/DialogRoutes';
import { always } from 'ramda';
import { getAuth } from 'util/index';
import { hasPermission } from 'util/rbac';
import { selectedFspIdSelector } from 'selectors/fsp';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, AppBar, Tabs as MdTabs } from '@material-ui/core';
import Label from './Label';
import Tab from './Tab';

const getPosition = (items, { pathname, state = {} }) =>
  items.findIndex(({ path, payerId, trrId }) => {
    let retVal = false;
    const localPathname = pathname.endsWith(DIALOG_URI)
      ? pathname.slice(0, pathname.length - DIALOG_URI.length)
      : pathname.slice();

    if (path === localPathname) {
      if (payerId || trrId) {
        if (payerId === state.id || trrId === state.id) {
          retVal = true;
        }
      } else {
        retVal = true;
      }
    }

    return retVal;
  });

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: theme.mixins.toolbar[theme.breakpoints.up('sm')].minHeight,
  },
  tabs: {
    backgroundColor: '#f1e4f1',
    padding: `0 ${theme.spacing(4) + theme.spacing(3)}px`,
  },
}));

const Tabs = ({ items, onChange, onRemove, onUpsert }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const fspId = useSelector(selectedFspIdSelector);
  const location = useLocation();
  const history = useHistory();
  const permissibleTabs = useMemo(() => {
    return items.filter(({ permissions }) =>
      hasPermission(auth, ...permissions)
    );
  }, [auth, items]);
  const [tabRemoved, setTabRemoved] = useState(false);
  const [value, setValue] = useState(0);
  const handleClose = useCallback(
    (event, tab) => {
      if (permissibleTabs.length - 1 === value) {
        setValue(value - 1);
      }
      setTabRemoved(true);
      onRemove(tab);
    },
    [permissibleTabs, onRemove, value]
  );
  // Why is this here? When a optional tab renders, it may change it's width
  // It must be reselected to get the bar to look like it's the correct width.
  // Setting it to zero will will be a change and useEffect will update with the correct value
  const handleChange = useCallback(() => {
    setValue(0);
  }, []);

  useEffect(() => {
    const listenerToRemove = history.listen((history) => {
      const { pathname, state = {} } = history;

      if (state.id) {
        onUpsert(pathname, state.id);
      }

      onChange(history);
    });

    return () => {
      listenerToRemove();
    };
  }, [history, onChange, onUpsert]);

  useEffect(() => {
    if (tabRemoved) {
      const { path, payerId, trrId } = permissibleTabs[value];

      history.push({
        pathname: path,
        state: { id: payerId || trrId },
      });

      setTabRemoved(false);
    } else {
      const pos = getPosition(permissibleTabs, location);

      if (pos !== value && pos !== -1) {
        setValue(pos);
      } else if (permissibleTabs.length === 2 && pos === -1 && value >= 1) {
        const { path, payerId, trrId } = permissibleTabs[0];

        setValue(0);

        history.push({
          pathname: path,
          state: { id: payerId || trrId },
        });
      }
    }
  }, [dispatch, location, history, permissibleTabs, tabRemoved, value]);

  return (
    <AppBar
      classes={{ positionSticky: classes.appBar }}
      color="inherit"
      elevation={2}
      position="sticky"
      role="banner"
    >
      <MdTabs
        aria-label="Scrollable tabs"
        className={classes.tabs}
        indicatorColor="primary"
        scrollButtons="auto"
        value={value}
        variant="scrollable"
      >
        {permissibleTabs.map(({ key, ...props }) => (
          <Tab
            key={key}
            label={
              <Label
                fspId={fspId}
                onChange={handleChange}
                onClose={handleClose}
                {...props}
              />
            }
            {...props}
          />
        ))}
      </MdTabs>
    </AppBar>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      MenuComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      menuComponentProps: PropTypes.object,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onUpsert: PropTypes.func,
};

Tabs.defaultProps = {
  onChange: always,
  onRemove: always,
  onUpsert: always,
};

export default Tabs;
