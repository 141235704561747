import PropTypes from 'prop-types';
import { memo } from 'react';
import { Grid, ListItem, Typography, makeStyles } from '@material-ui/core';
import { Mask, MaskPopover, StatusGrid } from 'components/common';
import { PERSON } from 'consts';
import {
  ReadOnlyInputWithMenu,
  ReadOnlyFormControl,
} from 'components/common/fields';
import { decryptFspPayerId, decryptPayerSSN } from 'sdk/payer';
import { formatSSN, formatTaxId } from 'util/format';
import { getAuth } from 'util/index';
import { profileSelector } from 'selectors/payer';
import { useSelector } from 'react-redux';
import Address from './Address';
import DetailsStatusSelector from './DetailsStatusSelector';

const useStyles = makeStyles(() => ({
  formControl: {
    height: 'auto',
  },
  listItem: {
    outline: 'none',
  },
}));

const Details = ({ fspId, id, ...props }) => {
  const auth = useSelector(getAuth);
  const classes = useStyles();
  const item = useSelector((state) => profileSelector(state, id));

  return (
    <Grid container {...props}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            inputNode={
              <MaskPopover
                api={() =>
                  decryptFspPayerId({ fspId, payerId: item.payerId }, auth)
                }
                value={item.fspPayerIdMask}
              />
            }
            label="FSP Payer ID"
            value={item.fspPayerIdMask}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Ontrac Payer ID" value={item.payerId} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="Service Started On"
            value={item.serviceStartOnLabel}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            inputNode={
              item.isPerson && (
                <ReadOnlyInputWithMenu
                  label={item.phone}
                  menuNode={
                    item.moreNumbers && item.moreNumbers.length ? (
                      item.moreNumbers.map((item) => (
                        <ListItem
                          key={item.key}
                          classes={{ root: classes.listItem }}
                        >
                          <ReadOnlyFormControl
                            classes={{ root: classes.formControl }}
                            label={item.label}
                            t-i="readOnlyPhone"
                            value={item.value}
                          />
                        </ListItem>
                      ))
                    ) : (
                      <ListItem classes={{ root: classes.listItem }}>
                        <Typography t-i="noPhoneNumbers" variant="body1">
                          No additional numbers available
                        </Typography>
                      </ListItem>
                    )
                  }
                  tooltip="Additional phone numbers"
                />
              )
            }
            label={item.phoneLabel || 'Phone / Mobile'}
            t-i="phone"
            value={item.phone}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Email" value={item.email} />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            inputNode={
              <Mask
                api={() =>
                  decryptPayerSSN({ fspId, payerId: item.payerId }, auth)
                }
                format={item.payerType === PERSON ? formatSSN : formatTaxId}
                value={item.idValue}
              />
            }
            label={item.idLabel || 'SSN / Tax ID'}
            t-i="ssnOrTaxID"
            value={item.idValue}
          />
        </Grid>
      </Grid>
      <Address id={id} />
      <StatusGrid
        spacing={2}
        status={item.status}
        statusNode={
          <DetailsStatusSelector
            currentStatus={item.status}
            fspId={fspId}
            payerId={item.payerId}
            payerLabel={item.label}
          />
        }
        statusText={item.statusText}
        statusUpdatedAt={item.statusAtLabel}
      />
    </Grid>
  );
};

Details.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default memo(Details);
