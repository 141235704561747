import Add from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';

const EndAdornmentAddButton = (props) => {
  return (
    <IconButton {...props}>
      <Add />
    </IconButton>
  );
};

export default EndAdornmentAddButton;
