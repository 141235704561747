import AuthType from 'types/auth';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getAuth } from 'util/index';
import { hasPermission } from 'util/rbac';

const Permission =
  (...permissions) =>
  (WrappedComponent) => {
    class WithPermission extends PureComponent {
      render() {
        const { auth } = this.props;

        return hasPermission(auth, ...permissions) ? (
          <WrappedComponent {...this.props} />
        ) : null;
      }
    }

    WithPermission.propTypes = {
      auth: AuthType.isRequired,
    };

    return connect((state) => ({
      auth: getAuth(state),
    }))(WithPermission);
  };

export default Permission;
