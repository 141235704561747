import Field from 'components/common/fields/Field';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import InputRefType from 'types/inputRef';

const useStyles = makeStyles({ root: { minWidth: 200 } });

const ParamValueText = ({
  disabled,
  inputRef,
  onChange,
  onKeyPress,
  value,
}) => {
  const classes = useStyles();

  return (
    <Field
      autoFocus
      classes={{ root: classes.root }}
      disabled={disabled}
      fullWidth
      inputRef={inputRef}
      label="Value"
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
    />
  );
};

ParamValueText.propTypes = {
  disabled: PropTypes.bool,
  inputRef: InputRefType,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ParamValueText.defaultProps = {
  disabled: false,
  inputRef: undefined,
  onKeyPress: always,
  value: '',
};

export default ParamValueText;
