import { client, formatQueryParams } from 'util/sdk';
import { getUpdatedStatus } from 'sdk';

export const load = ({ fspId, payerId, ...params }) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .recipients.query(formatQueryParams(params));

export const loadId = ({ fspId, recipientId }) =>
  client.fsps(fspId).recipients(recipientId).get();

export const update = ({ recipient, status }, auth) =>
  client
    .fsps(recipient.fspId)
    .recipients(recipient.id)
    .updateStatus(getUpdatedStatus(status, auth, 'recipientStatus'), {
      adminUserId: auth.id,
    });
