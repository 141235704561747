import { client, formatQueryParams } from 'util/sdk';
import { GET } from 'util/api';

export const cancel = ({ fspId, ids, reason }, auth) =>
  client
    .fsps(fspId)
    .cancelFspFraudTransaction(
      { fspschedtran: ids, cancelReason: reason },
      { adminUserId: auth.id }
    );

const getFraudTransUri = (fspId) => `/fsps/${fspId}/fspfraudtrans`;
const returnModel = { name: 'fspfraudtran' };

const exactValue = (field) => (value) => ({ [field]: value });
const rangeValues = (field) => (props) => {
  const [valueFrom, valueTo] = props;
  return {
    [`startwith(${field})`]: valueFrom,
    [`endwith(${field})`]: valueTo,
  };
};
const orderByValue = (field) => (value) => ({ order: `${field}:${value}` });

const formatQueryOperations = {
  offset: exactValue('offset'),
  limit: exactValue('limit'),
  onHoldFraudReview: exactValue('onholdfraudreview'),
  reviewed: exactValue('reviewed'),
  canceled: exactValue('canceled'),
  destination: exactValue('destination'),
  destinationLike: exactValue([`like(destination)`]),
  resultReason: exactValue('resultreason'),
  reviewedBy: exactValue('reviewedby'),
  reviewedOnRange: rangeValues('reviewedon'),
  amountRange: rangeValues('amount'),
  deliver: exactValue('deliver'),
  fspPayerId: exactValue('fsppayerid'),
  createdAtRange: rangeValues('createdat'),
  fraudScoreRange: rangeValues('fraudscore'),
  sendOnRange: rangeValues('sendon'),
  transactionType: exactValue('transactiontype'),
  canceledBy: exactValue('canceledby'),
  canceledOnRange: rangeValues('canceledon'),
  id: exactValue('id'),
  processedOnlyOffOn: exactValue('processedonlyoffon'),
  orderByDeliver: orderByValue('deliver'),
  orderByTransactionType: orderByValue('transactionType'),
  orderByFraudScore: orderByValue('fraudscore'),
  orderByAmount: orderByValue('amount'),
  orderByCreatedAt: orderByValue('createdAt'),
  orderBySendOn: orderByValue('sendon'),
};

const getQueryParams = (params) => {
  return params.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const load = ({ fspId, ...params }) => {
  const formatQueryArray = formatQueryParams({
    ...params,
    // Strict Rule, resultReason parameter must follow these rules in order to work:
    // 1. Just one item, do not send quotes, example: resultReason: cancel_ontrac
    // 2. More than one item, send json array format, example: resultReason: ["cancel_ontrac","cancel_batch"]
    resultReason:
      typeof params.resultReason === 'string'
        ? params.resultReason
        : JSON.stringify(params.resultReason),
  });

  return client.executeRequest(GET, getFraudTransUri(fspId), {
    queryParams: {
      // Spread all parameters needed by the API here
      ...getQueryParams(formatQueryArray(formatQueryOperations)),
    },
    returnModel,
  });
};
