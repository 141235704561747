import { client, formatQueryParams } from 'util/sdk';
import { getUpdatedStatusNoLabel } from './index';

export const load = async ({ fspId, payerId, ...props }) => {
  const user = await client
    .fsps(fspId)
    .payers(payerId)
    .users.query(formatQueryParams(props));

  return { user };
};

export const loadById = ({ fspId, id }) => client.fsps(fspId).users(id).get();

export const loadByPayerId = ({ fspId, payerId }) =>
  client.fsps(fspId).users.query(formatQueryParams({ payerId }));

export const unmask = ({ fspId, userId }) =>
  client.fsps(fspId).users(userId).decryptFspUserId();

export const update = ({ fspId, status, userId }, auth) =>
  client
    .fsps(fspId)
    .users(userId)
    .update(getUpdatedStatusNoLabel(status, auth), {
      adminUserId: auth.id,
    });
