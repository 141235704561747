import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  AccordionActions,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore, Lock, LockOpen } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ReadOnlyFormControl } from 'components/common/fields';
import { SCROLL_DELAY } from 'util/format';
import { useSelector } from 'react-redux';
import { selectorId } from 'selectors/paymentResearch';
import { getLocationFromTicket } from 'components/modules/paymentResearch/paths';

const useStyles = makeStyles(() => ({
  center: {
    top: '40%',
    left: '50%',
    position: 'fixed',
  },
  expansionPanel: {
    minWidth: 450,
  },
}));

const PaymentResearchListItem = ({ id, isLoading, expanded, scrollRef }) => {
  const classes = useStyles();
  const item = useSelector((state) => selectorId(state, id)) || {};

  const handlePanelChange = (event, expanded) => {
    if (expanded) {
      setTimeout(() => {
        if (scrollRef && scrollRef.current) {
          scrollIntoView(scrollRef.current);
        }
      }, SCROLL_DELAY);
    }
  };

  return (
    <Accordion
      className={classes.expansionPanel}
      defaultExpanded={expanded}
      onChange={handlePanelChange}
    >
      <AccordionSummary expandIcon={expanded ? <ExpandMore /> : null}>
        <Grid
          alignContent="center"
          alignItems="center"
          container
          justifyContent="flex-start"
          spacing={3}
        >
          <Grid item>
            {item?.status === 'closed' || item?.status === 'solved' ? (
              <Lock color="disabled" />
            ) : (
              <LockOpen color="primary" />
            )}
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{item.reasonName}</Typography>
            <Typography color="textSecondary" variant="body2">
              P{item.id}&nbsp;&nbsp;&nbsp;{item.createdAtLabel}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <div ref={scrollRef}>
          <Grid container>
            <Grid item xs={5}>
              <ReadOnlyFormControl
                capitalize
                label="Status"
                value={item.status}
              />
            </Grid>
            <Grid item xs={7}>
              <ReadOnlyFormControl
                capitalize
                label="Updated"
                value={item.statusAtLabel}
              />
            </Grid>
          </Grid>
          <ReadOnlyFormControl
            label="Additional Details"
            multiline
            value={item.description}
          />
        </div>
      </AccordionDetails>
      <AccordionActions>
        <Button
          color="primary"
          component={Link}
          to={getLocationFromTicket(id, item.fspId)}
        >
          Open Details
        </Button>
      </AccordionActions>
      {isLoading && <CircularProgress className={classes.center} />}
    </Accordion>
  );
};

PaymentResearchListItem.propTypes = {
  id: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  expanded: PropTypes.bool,
  scrollRef: PropTypes.object,
};

PaymentResearchListItem.defaultProps = {
  isLoading: false,
  expanded: true,
  scrollRef: undefined,
};

export default PaymentResearchListItem;
