import CurrencyTextField from 'components/common/fields/CurrencyTextField';
import PropTypes from 'prop-types';
import useFieldWithErrors from 'components/common/formik/hooks/useFieldWithErrors';
import { currencyNoSymbolNoNA } from 'util/format';
import { useFormikContext } from 'formik';
import { always } from 'ramda';

const TextFieldCurrency = ({ disabled, name, hasOnBlur, ...props }) => {
  const [field, , helpers, errorProps] = useFieldWithErrors(name);
  const { isSubmitting } = useFormikContext();

  const handleBlur = (event) => {
    const value = event.target.value;

    helpers.setValue(currencyNoSymbolNoNA()(value, ''));
    helpers.setTouched();
  };

  return (
    <CurrencyTextField
      {...props}
      {...field}
      {...errorProps}
      disabled={disabled || isSubmitting}
      onBlur={hasOnBlur ? handleBlur : always}
    />
  );
};

TextFieldCurrency.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  hasOnBlur: PropTypes.bool,
};

TextFieldCurrency.defaultProps = {
  disabled: false,
  hasOnBlur: true,
};

export default TextFieldCurrency;
