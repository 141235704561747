import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import { load as loadActionInsightEvents } from 'model/actionInsightEvent';
import { LOAD } from 'model/actionInsightReduceMyBill';
import { selectorFspId as insightEventSelectorFspId } from 'selectors/actionInsightEvent';
import { insightOnSelector } from 'selectors/userFspConfig';
import { selector } from 'selectors/busy';
import ExpandablePanel from 'components/common/ExpandablePanel';
import { BP_MISSED_PAYMENT, REDUCE_MY_BILL } from 'consts';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET, pageSettingsName } from './consts';
import MissedPayment from './MissedPayment';
import ReduceMyBill from './ReduceMyBill';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    transform: 'rotate(180deg)',
  },
}));

const Icon = () => {
  const classes = useStyles();

  return (
    <WbIncandescentOutlinedIcon className={classes.icon} variant="outlined" />
  );
};

const ActionInsight = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => selector(state, LOAD));
  const insightEnabled = useSelector((state) =>
    insightOnSelector(state, fspId, payerId)
  );
  const actionInsightEvents =
    useSelector((state) => insightEventSelectorFspId(state, fspId)) || [];

  const reduceMyBillEventExists = actionInsightEvents.some(
    ({ eventName }) => eventName.toUpperCase() === REDUCE_MY_BILL.toUpperCase()
  );

  const missedPaymentEventExists = actionInsightEvents.some(
    ({ eventName }) =>
      eventName.toUpperCase() === BP_MISSED_PAYMENT.toUpperCase()
  );

  useEffect(() => {
    dispatch(loadActionInsightEvents({ fspId }));
  }, [dispatch, fspId]);

  return insightEnabled ? (
    <ExpandablePanel
      heightOffset={HEIGHT_OFFSET}
      icon={Icon}
      id={pageSettingsName}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title="Action Insights"
    >
      <List>
        {reduceMyBillEventExists && (
          <ReduceMyBill fspId={fspId} payerId={payerId} />
        )}
        {missedPaymentEventExists && (
          <MissedPayment fspId={fspId} payerId={payerId} />
        )}
      </List>
    </ExpandablePanel>
  ) : null;
};

ActionInsight.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default ActionInsight;
