import {
  addIndex,
  all,
  any,
  append,
  chain,
  complement,
  compose,
  equals,
  filter,
  find,
  flatten,
  flip,
  groupBy,
  has,
  includes,
  isEmpty,
  isNil,
  length,
  lensProp,
  map,
  mergeAll,
  mergeRight,
  not,
  path,
  pathEq,
  pipe,
  prop,
  propEq,
  propSatisfies,
  replace,
  splitAt,
  values,
  view,
  when,
} from 'ramda';
import { YEAR_MONTH_DAY } from 'util/page';
import { TextField } from '@material-ui/core';
import { ParamSearchType } from './SearchTextField/Multiple/consts';

export const getSelectedField = (field, fields) =>
  find(pathEq(['field', 'value'], field))(fields);

export const getValueComponent = (operatorValue, operators) =>
  operators
    ? view(
        lensProp('component'),
        find(propEq('value', operatorValue), operators)
      )
    : TextField;

export const hasNoValue = (searchBys) =>
  all(
    propSatisfies((val) => isNil(val) || isEmpty(val), 'value'),
    searchBys || []
  );

const fixStringOnly = (f) => (s) => s ? f(s) : s;
const fixApiUnsupportedCharacters = fixStringOnly(
  pipe(replace(/\?/g, '*'), replace(/&/g, '\\&'))
);

// Replace all "," with empty. (ON-1466: Removing commas since API does not support amounts with commas).
const fixAmountFormat = (value) => (value ? replace(/,/g, '', value) : value);

// Replace all "?" with "*" and
// Replace all "&" with "\&" until API can handle these characters.
// Escape "_" and "%".
export const formatOutput = (value) => {
  return pipe(
    replace(/([%_])/g, '\\$1'),
    replace(/^([^*]+)$/, '%$1%'),
    replace(/\*/g, '%'),
    replace(/\?/g, '*'),
    replace(/&/g, '\\&')
  )(value);
};

// Replace all "?" with "*" and
// Replace all "&" with "\&" until API can handle these characters.
// Escape "_" and "%".
export const formatUserControlledOutput = (value) => {
  let retVal = value;

  if (typeof value === 'string') {
    retVal = pipe(
      replace(/\*/g, '%'),
      replace(/\?/g, '*'),
      replace(/&/g, '\\&')
    )(value);
  }

  return retVal;
};

export const applySearchBy = (id, searchBys, ...assoc) =>
  map(when(propEq('id', id), compose(...assoc)), searchBys);

// Replace all "?" with "*" and
// Replace all "&" with "\&" until API can handle these characters.
export const formatSearchBy = (field, operator, value) => {
  if (isNil(value)) return value;

  let formattedValue;
  const key = replace(/field((Exact)|(User))?/, field, operator);
  const isLike =
    equals('like(field)', operator) || equals('fieldLike', operator);
  const isUserControlledLike = equals('fieldUserLike', operator);

  if (isUserControlledLike) {
    formattedValue = formatUserControlledOutput(value);
  } else if (isLike) {
    formattedValue = formatOutput(value);
  } else if (Array.isArray(value)) {
    formattedValue = value.map((v) => {
      return fixApiUnsupportedCharacters(fixAmountFormat(v));
    });
  } else {
    formattedValue = fixApiUnsupportedCharacters(value.toString());
  }

  return { [key]: formattedValue };
};

const replaceIdWithApiValue = (valueOptions) => (valueId) =>
  valueOptions.find(({ id }) => id === valueId).apiValue ?? valueId;
const adaptMultiOptionValues = (valueOptions, values) =>
  JSON.stringify(chain(replaceIdWithApiValue(valueOptions), values));

export const formatSearchBys = (searchBys) =>
  mergeAll(
    map(
      ({ field, operator, value, defaultValue, type, valueOptions }) => {
        const values = Array.isArray(value)
          ? value
          : [value.format ? value.format(YEAR_MONTH_DAY) : value];
        const operators = operator.split(',');
        if (type === ParamSearchType.MultiOption) {
          return formatSearchBy(
            field,
            operators[0],
            adaptMultiOptionValues(valueOptions, values)
          );
        }
        if (
          length(values) === 2 &&
          not(isEmpty(values[0])) &&
          not(isEmpty(values[1]))
        ) {
          return {
            ...formatSearchBy(field, operators[0], values),
          };
        }
        if (
          length(values) === 2 &&
          isEmpty(values[0]) &&
          not(isEmpty(values[1]))
        ) {
          const operator = operators[1] || operators[0];
          return formatSearchBy(field, operator, [
            (defaultValue && defaultValue[0]) || values[0],
            values[1],
          ]);
        }
        return formatSearchBy(field, operators[0], values[0]);
      },
      filter(
        ({ field, operator, value }) => field && operator && value,
        searchBys
      )
    )
  );

const createPrioritySearchBy = ({ field: { value }, operators }) => ({
  field: value,
  operator: operators[0].value,
  value: '',
  defaultValue: operators[0].defaultValue,
});

export const getSelectableFields = (searchBys, fields) =>
  filter(
    compose(
      complement(flip(includes))(map(prop('field'), searchBys)),
      path(['field', 'value'])
    )
  )(fields);

export const hasItemWithoutField = (searchBys) =>
  not(any(complement(has('field')), searchBys));

export const initialize = (priorityIndex, fields = [], value) => {
  const [priorityFields] = splitAt(priorityIndex, fields);

  const prioritySearchBysByField = groupBy(
    prop('field'),
    map(createPrioritySearchBy)(priorityFields)
  );

  const searchBysByField = groupBy(prop('field'), value);

  let combineSearchBys = flatten(
    values(mergeRight(prioritySearchBysByField, searchBysByField))
  );

  const selectionFields = getSelectableFields(combineSearchBys, fields);

  if (length(selectionFields) > 0 && hasItemWithoutField(combineSearchBys)) {
    combineSearchBys = append({}, combineSearchBys);
  }

  return {
    selectionFields,
    updatedValue: addIndex(map)(
      (val, index) => ({ ...val, id: index }),
      combineSearchBys
    ),
  };
};
