import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

const size = 56;

const styles = () => ({
  root: {},
  absoluteCenter: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  center: {
    position: 'absolute',
    top: '50%',
    marginTop: -28,
  },
});

const Empty = ({ classes, isLoading, subheading, title }) => {
  return (
    <div className={classes.absoluteCenter}>
      <Grid
        alignItems="center"
        className={classes.root}
        container
        direction="column"
        justifyContent="center"
      >
        {isLoading && (
          <CircularProgress className={classes.center} size={size} />
        )}
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{subheading}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Empty.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  subheading: PropTypes.string,
  title: PropTypes.string,
};

Empty.defaultProps = {
  isLoading: false,
  subheading: '',
  title: '',
};

export default withStyles(styles)(Empty);
