import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadById } from 'model/externalAccount';
import { derivedSelectorId } from 'selectors/externalAccount';
import { unmask } from 'sdk/externalAccount';
import Skeleton from 'components/common/TextSkeleton';
import { Mask } from 'components/common';
import { ReadOnlyFormControl } from 'components/common/fields';

const AutopayCreditPayFrom = ({ externalDdaId, fspId }) => {
  const dispatch = useDispatch();
  const { accountNbrMask, ddaType } =
    useSelector((state) => derivedSelectorId(state, externalDdaId)) || {};

  useEffect(() => {
    if (externalDdaId && !accountNbrMask) {
      dispatch(loadById({ fspId, id: externalDdaId }));
    }
  }, [dispatch, accountNbrMask, externalDdaId, fspId]);

  return (
    <ReadOnlyFormControl
      inputNode={
        accountNbrMask ? (
          <Mask
            api={() => unmask({ fspId, id: externalDdaId })}
            prefix={ddaType}
            value={accountNbrMask}
          />
        ) : (
          <Skeleton />
        )
      }
      label="Pay From Account"
      value={accountNbrMask || <Skeleton />}
    />
  );
};

AutopayCreditPayFrom.propTypes = {
  externalDdaId: PropTypes.number.isRequired,
  fspId: PropTypes.number.isRequired,
};

export default AutopayCreditPayFrom;
