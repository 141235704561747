import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useDisabledPropsByPermission } from 'hooks';
import { FULL_ACCESS_API_KEY_MANAGEMENT } from 'components/common/permissions';
import TextField from './TextField';

const ApiKeyTextInput = ({ name, label, ...props }) => {
  const [field, meta] = useField({ name: name ?? 'name' });
  const { isSubmitting } = useFormikContext();
  const { disabled, classes } = useDisabledPropsByPermission([
    FULL_ACCESS_API_KEY_MANAGEMENT,
  ]);

  return (
    <TextField
      {...field}
      autoComplete="off"
      InputProps={{
        classes,
      }}
      isSubmitting={isSubmitting}
      label={label}
      meta={meta || {}}
      required
      {...props}
      disabled={disabled}
    />
  );
};

ApiKeyTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ApiKeyTextInput;
