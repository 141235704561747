import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createRef, useEffect, useState } from 'react';
import scrollIntoView from 'scroll-into-view';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  Typography,
} from '@material-ui/core';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { SCROLL_DELAY, SCROLL_OPTIONS } from 'util/format';

const useStyles = makeStyles((theme) => ({
  internalPanelsDetailsEmpty: {
    minHeight: '35vh',
  },
  list: {
    width: '100%',
  },
  secondaryHeading: {
    color: theme.palette.secondary.dark,
  },
}));

const ContactsAccordion = ({ children, empty, endAdornment, title }) => {
  const [expandedIds, setExpandedIds] = useQueryParam('epContact', ArrayParam);
  const [panelExpanded, setPanelExpanded] = useState(false);
  const classes = useStyles();
  const scrollRef = createRef();

  const handleChange = (event, expanded) => {
    const ids = expandedIds ? [...expandedIds] : [];
    const index = ids.indexOf(title);

    if (expanded) {
      setTimeout(
        () => scrollIntoView(scrollRef.current, SCROLL_OPTIONS),
        SCROLL_DELAY
      );
    }

    if (index === -1) {
      ids.push(title);
    } else {
      ids.splice(index, 1);
    }

    setExpandedIds(ids.length ? ids : undefined);
  };

  useEffect(() => {
    const pos = Array.isArray(expandedIds) ? expandedIds.indexOf(title) : -1;

    setPanelExpanded(pos !== -1);
  }, [expandedIds, title]);

  useEffect(() => {
    return () => {
      setExpandedIds(undefined);
    };
  }, [setExpandedIds]);

  return (
    <Accordion expanded={panelExpanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div ref={scrollRef} />
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Typography noWrap variant="h6">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Collapse in={panelExpanded}>{endAdornment}</Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        className={classNames({
          [classes.internalPanelsDetailsEmpty]: empty,
        })}
      >
        {panelExpanded ? children : null}
      </AccordionDetails>
    </Accordion>
  );
};

ContactsAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  empty: PropTypes.bool.isRequired,
  endAdornment: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default ContactsAccordion;
