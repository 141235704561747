import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  label: {
    whiteSpace: 'nowrap',
  },
}));

const ReadOnlyInputLabel = ({ label }) => {
  const classes = useStyles();

  return (
    <InputLabel className={classes.label} shrink>
      {label}
    </InputLabel>
  );
};

ReadOnlyInputLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ReadOnlyInputLabel;
