import { LOAD as LOAD_ROLE } from 'model/role';
import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class Permission extends Model {
  static get modelName() {
    return 'Permission';
  }

  static reducer({ type, data }, Permission) {
    switch (type) {
      case LOAD.SUCCESS:
        data.forEach((perm) => Permission.upsert(perm));
        break;

      case LOAD_ROLE.SUCCESS:
        data.forEach((group) =>
          group.actions.forEach((action) => Permission.upsert(action))
        );
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      action: attr(),
      createdAt: attr(),
      description: attr(),
      href: attr(),
      id: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createRequestConst(Permission.modelName);
export const loadPermissions = createAction(LOAD);
