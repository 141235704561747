import { client } from 'util/sdk';

// TODO: Copied from sdk, will remove this when no longer used
export const handleNoElements = (res) => {
  if (res && res.httpStatus === '404 Not Found') {
    return [];
  }
  throw res;
};

// TODO: Copied from sdk, will remove this when no longer used
export const adaptPromiseCatch =
  (f, cb = () => null) =>
  (error, data) => {
    if (!error) {
      cb(error, data);
    }
    try {
      cb(null, f(error));
    } catch (e) {
      cb(e, null);
    }
  };

export const getEBillAccount = async ({
  fspId,
  payerEbillerAccountId,
  payerId,
}) => {
  const account = await client
    .executeRequest(
      'GET',
      `/fsps/${fspId}/payers/${payerId}/payerebilleraccounts/${payerEbillerAccountId}`,
      {
        queryParams: { limit: 18, order: 'billon:desc' },
        cb: adaptPromiseCatch(handleNoElements),
        returnModel: {
          name: 'payerebilleraccount',
        },
      }
    )
    .catch(handleNoElements);

  account.statements = await client
    .executeRequest(
      'GET',
      `/fsps/${fspId}/payers/${payerId}/payerebilleraccounts/${payerEbillerAccountId}/statements`,
      {
        queryParams: { limit: 18, order: 'billon:desc' },
        cb: adaptPromiseCatch(handleNoElements),
        returnModel: {
          name: 'payeeebill',
        },
      }
    )
    .catch(handleNoElements);

  return account;
};

export const getEBillBlob = async ({ fspId, payeeEbillId, payerId }) =>
  client.executeRequest(
    'GET',
    `/fsps/${fspId}/payers/${payerId}/payeeebills/${payeeEbillId}/documents`,
    {
      returnModel: {
        name: 'payeeebill',
      },
    }
  );

export const getEBillUser = async ({
  fspId,
  payerEbillernetEbillerId,
  payerId,
}) =>
  client.executeRequest(
    'GET',
    `/fsps/${fspId}/payers/${payerId}/payerebillernetebillers/${payerEbillernetEbillerId}`,
    {
      returnModel: {
        name: 'payerzebillernetebiller',
      },
    }
  );

export const deleteStatement = ({ fspId, statementId }, auth) =>
  client.executeRequest('DELETE', `/fsps/${fspId}/payeeebills/${statementId}`, {
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'payeeebill' },
  });

export const updateStatement = ({ fspId, statementId, ...data }, auth) =>
  client.executeRequest('POST', `/fsps/${fspId}/payeeebills/${statementId}`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'payeeebill' },
  });
