import { GET, RTN, RTNS } from 'consts';
import { client } from 'util/sdk';

export const create = (data, auth) =>
  client.rtns.create(data, { adminUserId: auth.id });

export const load = (params, auth) => {
  return client.executeRequest(GET, `/${RTNS}`, {
    queryParams: { ...params, adminUserId: auth.id },
    returnModel: {
      name: RTN,
    },
  });
};

export const loadRoutingNumber = ({ routingNumber }, auth) =>
  client.rtns(routingNumber).get({ adminUserId: auth.id });

export const remove = ({ routingNumber }, auth) =>
  client.rtns(routingNumber).delete({ adminUserId: auth.id });

export const update = ({ id, name, routingNumber }, auth) =>
  client.rtns(id).update({ name, routingNumber }, { adminUserId: auth.id });
