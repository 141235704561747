import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { always } from 'ramda';
import { getAuth } from 'util/index';
import { hasPermission } from 'util/rbac';
import { UPDATE_ACCESS_STATUS } from 'components/common/permissions';
import { useSelector } from 'react-redux';
import SelectionAction from '../SelectionAction';
import {
  getAvailableStates,
  getConfirmMessage,
  getContinueLabel,
} from './consts';

const StatusSelectionAction = ({
  confirmMessageExtras,
  currentStatus,
  onChange,
  permission,
  statusConfig,
  statusList,
  ...props
}) => {
  const auth = useSelector(getAuth);

  const confirmMessageRenderer = ({ value }) => {
    return getConfirmMessage(
      currentStatus,
      value,
      statusConfig,
      confirmMessageExtras
    );
  };
  const data = statusConfig
    ? getAvailableStates(currentStatus, statusList, statusConfig, auth)
    : statusList;
  const handleOnChange = useMemo(() => {
    return hasPermission(auth, permission) ? onChange : null;
  }, [auth, onChange, permission]);
  const handleOnCheck = (_, newStatus) => ({
    continueLabel: getContinueLabel(currentStatus, newStatus, statusConfig),
  });

  return (
    <SelectionAction
      {...props}
      confirmMessageRenderer={confirmMessageRenderer}
      data={data}
      onChange={handleOnChange}
      onCheck={handleOnCheck}
      value={currentStatus}
    />
  );
};

StatusSelectionAction.propTypes = {
  confirmMessageExtras: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  currentStatus: PropTypes.string,
  onChange: PropTypes.func,
  permission: PropTypes.string,
  statusConfig: PropTypes.object,
  statusList: PropTypes.array.isRequired,
};

StatusSelectionAction.defaultProps = {
  currentStatus: null,
  confirmMessageExtras: null,
  onChange: always,
  permission: UPDATE_ACCESS_STATUS,
  statusConfig: null,
};

export default memo(StatusSelectionAction);
