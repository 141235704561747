import AlertDialog from 'components/common/AlertImproved';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withUpdatePaymentStatus } from 'components/common/permissions';
import {
  handleStopPropagation,
  MANUAL_REFUND,
  REFUNDED,
  RETURNED,
} from 'consts';
import {
  updateReturnedStatus,
  UPDATE_RETURN_STATUS,
} from 'model/transactionHistory';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { derivedSelectorId } from 'selectors/transactionHistory';
import { isOn } from 'util/index';
import {
  isCheck,
  isElecDelivered,
  isElecManualRefund,
  isElecRefundFailed,
  isInternalTransfer,
} from './consts';
import UpdateCheckPaymentStatusContent from './UpdatePaymentStatusContent';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const UpdatePaymentStatus = ({ id }) => {
  const [currentUpdatedAt, setCurrentUpdatedAt] = useState(null);
  const [open, setOpen] = useState(false);
  const transaction = useSelector((state) => derivedSelectorId(state, { id }));
  const check = isCheck(transaction);
  const converted = isOn(transaction?.conversionDataOffOn);
  const buttonText = `Update ${check ? 'Check ' : ''}Payment Status`;
  const elecDelivered = isElecDelivered(transaction);
  const elecManualRefund = isElecManualRefund(transaction);
  const elecRefundFailed = isElecRefundFailed(transaction);
  const internalTransfer = isInternalTransfer(transaction);
  const [networkResponseCodeStr, setNetworkResponseCodeStr] = useState(null);
  const show =
    !converted &&
    !internalTransfer &&
    (check || elecRefundFailed || elecDelivered || elecManualRefund);
  const status = check ? RETURNED : elecDelivered ? MANUAL_REFUND : REFUNDED;
  const titleSuffixKey = check ? 'deliveryMethodValue' : 'id';
  const titleSuffix = check
    ? transaction[titleSuffixKey]
    : `Transaction #${transaction[titleSuffixKey]}`;
  const title = `Update ${titleSuffix || ''}?`;
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = useCallback((value) => {
    setNetworkResponseCodeStr(value);
  }, []);
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      setNetworkResponseCodeStr(null);
    }
  };
  const handleConfirm = () => {
    const { fspId, updatedAt } = transaction;

    setCurrentUpdatedAt(updatedAt);

    dispatch(
      updateReturnedStatus({ fspId, id, networkResponseCodeStr, status })
    );
  };
  const handleOpen = (event) => {
    handleStopPropagation(event);
    setOpen(true);
  };

  useEffect(() => {
    const { updatedAt } = transaction;

    if (currentUpdatedAt && updatedAt !== currentUpdatedAt) {
      setOpen(false);
      setCurrentUpdatedAt(updatedAt);
    }
  }, [currentUpdatedAt, transaction]);

  return show ? (
    <>
      <Button
        className={classes.button}
        data-testid="button"
        onClick={handleOpen}
        variant="outlined"
      >
        {buttonText}
      </Button>
      <AlertDialog
        actionType={UPDATE_RETURN_STATUS}
        confirmDisabled={networkResponseCodeStr === null}
        confirmLabel="Update Status"
        content={
          <UpdateCheckPaymentStatusContent
            check={check}
            elecDelivered={elecDelivered}
            elecManualRefund={elecManualRefund}
            elecRefundFailed={elecRefundFailed}
            onChange={handleChange}
          />
        }
        onClick={handleStopPropagation}
        onClose={handleClose}
        onConfirm={handleConfirm}
        open={open}
        title={title}
      />
    </>
  ) : null;
};

UpdatePaymentStatus.propTypes = {
  id: PropTypes.number.isRequired,
};

export default withUpdatePaymentStatus(UpdatePaymentStatus);
