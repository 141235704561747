import {
  and,
  any,
  compose,
  includes,
  filter,
  find,
  flip,
  prop,
  propEq,
  propOr,
} from 'ramda';

const ID = 'id';
const ON = 'on';
const NAME = 'name';

// User action format
// { id: <id>
//   name: '<String>',
//   executePriv: 'on|off',
//   grantPriv: 'on|off',
//   updatePriv: 'on|off' }
const actionHasExecutePermissions = (action, permissions) =>
  and(includes(action.name, permissions), hasExecutePriv(action));

export const hasExecutePriv = (entity) => entity.executePriv === ON;

export const hasGrantPriv = (entity) => entity.grantPriv === ON;

export const getGroupsByOrg = (org = {}, groups) =>
  filter(
    compose(
      any(propEq('adminGroupId', org.adminGroupId)),
      propOr([], 'groupAdmins')
    )
  )(groups);

export const hasPermission = (auth, ...permissions) =>
  auth &&
  auth.actions &&
  find(
    (action) => actionHasExecutePermissions(action, permissions),
    auth.actions
  ) !== undefined;

export const getUserGroupsWithPermissions = (
  auth,
  groups,
  roles,
  ...permissions
) => {
  const { groups: userGroups = [] } = auth;
  const returnValues = [];
  userGroups.forEach((userGroup) => {
    const foundGroup = find(propEq(ID, userGroup.id), groups);
    if (foundGroup && foundGroup.roles) {
      const userRoleIds = foundGroup.roles
        .filter((role) => hasExecutePriv(role))
        .map((role) => role.id);
      const userRoles = filter(
        compose(flip(includes)(userRoleIds), prop(ID)),
        roles
      );
      if (userRoles.length > 0) {
        const userActions = userRoles.reduce(
          (accumulator, userRole) => accumulator.concat(userRole.actions),
          []
        );
        if (
          find(compose(flip(includes)(permissions), prop(NAME)), userActions)
        ) {
          returnValues.push(foundGroup);
        }
      }
    }
  });
  return returnValues;
};

export const createAdminPriviledge = (entity) => ({
  ...entity,
  updatePriv: ON,
  grantPriv: ON,
  executePriv: ON,
});

export const getInstitutions = (fsps, hasPriv) => ({
  systemIds: fsps
    .filter((fsp) => fsp.zsys && hasPriv(fsp))
    .map((fsp) => fsp.zsys),
  channelIds: fsps
    .filter((fsp) => fsp.zchan && hasPriv(fsp))
    .map((fsp) => fsp.zchan),
  distributorIds: fsps
    .filter((fsp) => fsp.zdis && hasPriv(fsp))
    .map((fsp) => fsp.zdis),
  fspIds: fsps.filter((fsp) => fsp.zfsp && hasPriv(fsp)).map((fsp) => fsp.zfsp),
});

export const getInstitutionsFromGroup = (group, hasPriv) =>
  getInstitutions(group.fsps, hasPriv);

export const getFspsByInstitutions = (
  fsps,
  channelIds,
  distributorIds,
  fspIds
) => {
  if (channelIds && channelIds.length > 0) {
    return fsps.filter((fsp) => includes(fsp.channel.id, channelIds));
  }
  if (distributorIds && distributorIds.length > 0) {
    return fsps.filter((fsp) => includes(fsp.distributor.id, distributorIds));
  }
  if (fspIds && fspIds.length > 0) {
    return fsps.filter((fsp) => includes(fsp.id, fspIds));
  }
  return fsps;
};
