import { Model } from 'redux-orm';
import { createAction, createDeleteConst, createRequestConst } from '../util';
import { fields } from './limitRule';
import addDerived from './util/limitRule';

export default class LimitRuleImport extends Model {
  static get modelName() {
    return 'LimitRuleImport';
  }

  static reducer({ type, data }, LimitRuleImport) {
    switch (type) {
      case LOAD.ACTION:
        LimitRuleImport.delete();
        (data ?? []).forEach((r) => {
          LimitRuleImport.create(addDerived(r));
        });
        break;

      case REMOVE.ACTION:
        if (LimitRuleImport.idExists(data)) {
          LimitRuleImport.withId(data).delete();
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return fields;
  }
}

const name = LimitRuleImport.modelName;

export const LOAD = createRequestConst(name);
export const REMOVE = createDeleteConst(name);

export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
