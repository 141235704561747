/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { find, includes, propEq, omit } from 'ramda';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  withStyles,
} from '@material-ui/core';

const styles = () => ({
  root: {
    marginTop: 20,
    marginBottom: 10,
    width: '100%',
    maxHeight: 300,
  },
  headerSection: {
    display: 'flex',
    width: '100%',
  },
  listSection: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  listBody: {
    width: 300,
  },
});

class MultiRowSelectionGroup extends Component {
  constructor(props) {
    super(props);
    const { data, dataIdAttr, value, idAttr, metaData, idOnly, anchorName } =
      props;

    let initialValues = [];
    if (value) {
      initialValues = value;
    }

    const mergedData = data.map((item) => {
      let selectedItem = null;
      if (idOnly) {
        if (includes(item[dataIdAttr], initialValues)) {
          selectedItem = item;
        }
      } else {
        selectedItem = find((selected) => {
          if (anchorName && selected[anchorName][idAttr]) {
            return selected[anchorName][idAttr] === item[dataIdAttr];
          }
          if (selected[idAttr]) {
            return selected[idAttr] === item[dataIdAttr];
          }
          return selected === item[dataIdAttr];
        }, initialValues);
      }

      if (selectedItem) {
        if (
          (anchorName && selectedItem[anchorName][idAttr]) ||
          selectedItem[idAttr]
        ) {
          return { ...selectedItem, selected: true };
        }
        return { [idAttr]: selectedItem, selected: true };
      }
      let unselectedItem = { [idAttr]: item[dataIdAttr] };

      metaData.forEach((meta) => {
        if (meta.valueAttr !== idAttr) {
          let value = meta.uncheckedValue;
          if (meta.defaultValue) {
            value = meta.defaultValue;
          }
          unselectedItem[meta.valueAttr] = value;
        }
      });
      if (anchorName) {
        unselectedItem = { [anchorName]: unselectedItem, selected: false };
      } else {
        unselectedItem.selected = false;
      }
      return unselectedItem;
    });

    this.state = { data: mergedData };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { metaData, onChange, idAttr, anchorName } = nextProps;
    const { data } = this.state;
    if (onChange) {
      metaData.forEach((meta) => {
        if (meta.valueAttr !== idAttr) {
          data.forEach((item) => {
            let value = meta.uncheckedValue;
            if (meta.defaultValue) {
              value = meta.defaultValue;
            }
            if (anchorName) {
              item[anchorName][meta.valueAttr] = value;
            } else {
              item[meta.valueAttr] = value;
            }
          });
        }
      });
      this.handleBulkChange(data);
    }
  }

  handleChange =
    (valueAttr, checkedValue, uncheckedValue, id) => (event, checked) => {
      const { idAttr, anchorName } = this.props;
      const { data } = this.state;
      const foundData = find((item) =>
        anchorName ? item[anchorName][idAttr] === id : item[idAttr] === id
      )(data);
      if (foundData) {
        if (idAttr === valueAttr) {
          foundData.selected = !!checked;
        } else if (checked) {
          foundData[valueAttr] = checkedValue;
        } else {
          foundData[valueAttr] = uncheckedValue;
        }
      }
      this.handleBulkChange(data);
    };

  handleBulkChange = (data) => {
    const { onChange, idOnly, idAttr } = this.props;
    this.setState({ data });
    let values = data.filter(propEq('selected', true)).map(omit(['selected']));
    if (idOnly) {
      values = values.map((item) => item[idAttr]);
    }
    if (onChange) {
      onChange(values);
    }
  };

  isChecked = (id, valueAttr, checkedValue) => {
    const { idAttr, anchorName } = this.props;
    const { data } = this.state;
    const foundData = find((item) =>
      anchorName ? item[anchorName][idAttr] === id : item[idAttr] === id
    )(data);
    if (foundData) {
      if (idAttr === valueAttr) {
        return foundData.selected;
      }
      return foundData[valueAttr] === checkedValue;
    }
    return false;
  };

  isDisabled = (id, valueAttr) => {
    const { idAttr } = this.props;
    const { data } = this.state;
    const foundData = find(propEq(idAttr, id), data);

    return !!(foundData && !foundData.selected && idAttr !== valueAttr);
  };

  render() {
    const { data, metaData, dataIdAttr, helperText, classes, disabled, error } =
      this.props;

    return (
      <FormControl
        className={classes.root}
        component="fieldset"
        disabled={disabled}
        error={error}
      >
        <div className={classes.headerSection} t-i="multiSelectHeader">
          {metaData.map((meta) => {
            const { label, style = {}, type } = meta;
            if (type !== 'hidden') {
              return (
                <FormLabel key={label} style={style}>
                  {label}
                </FormLabel>
              );
            }
            return '';
          }, this)}
        </div>
        <div className={classes.listSection} t-i="multiSelectBody">
          {data.map((item) => {
            const id = item[dataIdAttr];
            return (
              <div key={item[dataIdAttr]} className={classes.listBody}>
                {metaData.map((meta) => {
                  const {
                    label,
                    style = {},
                    type,
                    displayAttr,
                    valueAttr,
                    checkedValue,
                    uncheckedValue,
                  } = meta;
                  let ControlComponent = Checkbox;
                  if (type === 'switch') {
                    ControlComponent = Switch;
                  } else if (type === 'hidden') {
                    return null;
                  }

                  return (
                    <FormControlLabel
                      key={`${id}${label}`}
                      control={
                        <ControlComponent
                          checked={this.isChecked(id, valueAttr, checkedValue)}
                          color="primary"
                          disabled={this.isDisabled(id, valueAttr)}
                          onChange={this.handleChange(
                            valueAttr,
                            checkedValue,
                            uncheckedValue,
                            id
                          )}
                        />
                      }
                      label={displayAttr ? item[displayAttr] : ''}
                      style={style}
                    />
                  );
                }, this)}
              </div>
            );
          }, this)}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </div>
      </FormControl>
    );
  }
}

MultiRowSelectionGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  metaData: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataIdAttr: PropTypes.string.isRequired,
  idAttr: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  anchorName: PropTypes.string,
  idOnly: PropTypes.bool,
  helperText: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ])
    ),
  ]),
};

MultiRowSelectionGroup.defaultProps = {
  anchorName: null,
  idOnly: false,
  helperText: '',
  disabled: false,
  error: false,
  onChange: null,
  value: null,
};

export default withStyles(styles)(MultiRowSelectionGroup);
