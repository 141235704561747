import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class PayerUserAudit extends Model {
  static get modelName() {
    return 'PayerUserAudit';
  }

  static reducer({ type, data }, PayerUserAudit) {
    switch (type) {
      case LOAD.SUCCESS:
        PayerUserAudit.delete();
        if (data.response) {
          // Adding userId from request until API returns userId with response
          data.response.forEach((r) =>
            PayerUserAudit.upsert({ ...r, userId: data?.props?.userId })
          );
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      byAdminUserId: attr(),
      bySystemUser: attr(),
      byUserId: attr(),
      changelog: attr(),
      id: attr(),
      logAt: attr(),
      logId: attr(),
      logOp: attr(),
      time: attr(), // derived
      title: attr(), // derived
    };
  }
}

export const LOAD = createLoadConst(PayerUserAudit.modelName);
export const load = createAction(LOAD);
