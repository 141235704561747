import { attr, fk, Model } from 'redux-orm';
import Payer from 'model/payer';
import {
  createAction,
  createRequestConst,
  formatCurrency,
  date,
} from '../util';
import {
  getOfferStatusDetailLabel,
  offerStatus,
  getOfferStatusDates,
  STAGE_STATUS_COMPLETED_SAVINGS_ACHIEVED,
} from './util/actionInsightReduceMyBill';

const derived = (item) => {
  const totalSavingsLabel = `${formatCurrency(
    (item?.stage4Completed === STAGE_STATUS_COMPLETED_SAVINGS_ACHIEVED
      ? item?.negSavingsTotal
      : item?.estimatedTotalSavings) || 0
  )} over ${
    (item?.stage4Completed === STAGE_STATUS_COMPLETED_SAVINGS_ACHIEVED
      ? item?.negSavingsLength
      : item?.estimatedSavingsMonths) || 0
  } months`;

  return {
    ...item,
    activeAtLabel: date(item?.activeAt),
    totalSavingsLabel,
    expiredAtLabel: date(item?.expiredAt),
    firstViewOnLabel: date(item?.firstViewOn),
    lastViewOnLabel: date(item?.lastViewOn),
    offerExpiresOnLabel: date(item?.offerExpiresOn),
    offerStatusDates: getOfferStatusDates(item),
    statusDetailLabel: getOfferStatusDetailLabel({
      ...item,
      totalSavingsLabel,
    }),
    statusLabel: offerStatus[item?.status],
    stageLastUpdatedAtLabel: date(item?.stageLastUpdateAt),
  };
};

export default class ActionInsightReduceMyBill extends Model {
  static get modelName() {
    return 'ActionInsightReduceMyBill';
  }

  static reducer({ type, data }, ActionInsightReduceMyBill, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS: {
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).actionInsightReduceMyBill.delete();
        }

        if (data?.response) {
          data.response.forEach((r) =>
            ActionInsightReduceMyBill.upsert(derived(r))
          );
        }
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      acceptedAt: attr(),
      activeAt: attr(),
      activeAtLabel: attr(), // derived
      apiFailedAt: attr(),
      apiSentAt: attr(),
      billerId: attr(),
      billSavingBillerBillerId: attr(),
      billSavingBillerId: attr(),
      billSavingBillerName: attr(),
      canceledAt: attr(),
      category1: attr(),
      category2: attr(),
      category3: attr(),
      clickCountBillerCard: attr(),
      clickCountBpConfirmCard: attr(),
      clickCountInsightCard: attr(),
      clickedAt: attr(),
      createdAt: attr(),
      customerConversationId: attr(),
      customerId: attr(),
      declinedAt: attr(),
      displayForBiller: attr(),
      displayGlobal: attr(),
      dob: attr(),
      email: attr(),
      errResponseCode: attr(),
      estimatedMonthlySavings: attr(),
      estimatedSavingsMonths: attr(),
      estimatedTotalSavings: attr(),
      totalSavingsLabel: attr(), // derived
      expiredAt: attr(),
      expiredAtLabel: attr(), // derived
      expiredReason: attr(),
      firstName: attr(),
      firstViewOn: attr(),
      firstViewOnLabel: attr(), // derived
      fspId: attr(),
      id: attr(),
      inProgressAt: attr(),
      lastName: attr(),
      lastViewOn: attr(),
      lastViewOnLabel: attr(), // derived
      negNewContractEndDate: attr(),
      negNewContractStartDate: attr(),
      negotiationSuccessfulAt: attr(),
      negSavingsLength: attr(),
      negSavingsTotal: attr(),
      offerExpiresOn: attr(),
      offerExpiresOnLabel: attr(), // derived
      payeeId: attr(),
      payeeName: attr(),
      payerId: fk(Payer.modelName, 'actionInsightReduceMyBill'),
      promoCode: attr(),
      stage1New: attr(),
      stage2Assigned: attr(),
      stage3InProgress: attr(),
      stage4Completed: attr(),
      stageLastUpdateAt: attr(),
      stageLastUpdatedAtLabel: attr(), // derived
      status: attr(),
      statusDetailLabel: attr(), // derived
      statusLabel: attr(), // derived
      viewCount: attr(),
    };
  }
}

export const LOAD = createRequestConst(ActionInsightReduceMyBill.modelName);
export const load = createAction(LOAD);
