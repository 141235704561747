import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'reducers/errorMessages';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  grid: {
    maxWidth: 325,
  },
}));

const ErrorMessageCollapseDetail = ({ type }) => {
  const classes = useStyles();
  const message = useSelector(selector);

  return message[type] ? (
    <Grid className={classes.grid} item>
      <Grid alignItems="center" container spacing={2} wrap="nowrap">
        <Grid item>
          <Typography variant="body2">{type}:</Typography>
        </Grid>
        <Grid item>{message[type]}</Grid>
      </Grid>
    </Grid>
  ) : null;
};

ErrorMessageCollapseDetail.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ErrorMessageCollapseDetail;
