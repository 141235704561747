import Skeleton from '@material-ui/lab/Skeleton';
import { date } from 'util/index';
import { isEmpty, isNil } from 'ramda';
import { NA_VALUE } from 'consts';

export const WAITING = '...';

export const hasValue = (value) => !(isEmpty(value) || isNil(value));
export const getValue = (value, isDate) => {
  let retVal = value;

  if (value) {
    retVal = isDate ? date(value) : value;
  } else if (value === null) {
    retVal = NA_VALUE;
  } else if (value === undefined) {
    retVal = <Skeleton />;
  }

  return retVal;
};
