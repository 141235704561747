import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { DEFAULT_MODEL_REF_ARRAY } from 'consts';
import { ASC, ID } from '../consts';

export const selector =
  createSelector(orm.Distributor) || DEFAULT_MODEL_REF_ARRAY;

export const selectorCount = createSelector(orm, ({ Distributor }) =>
  Distributor.count()
);

export const selectorLatestEntry = createSelector(
  orm,
  ({ Distributor }) => Distributor.orderBy(ID, ASC)?.last()?.ref
);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ Distributor }, { id }) =>
    Distributor.idExists(id)
      ? Distributor.withId(id)
      : Distributor.upsert({ id: id || 0 })
);

export const selectorSelected = createSelector(orm, ({ Distributor }) =>
  Distributor.filter(({ selected }) => selected).toRefArray()
);
