/* eslint-disable react/destructuring-assignment, react/display-name */
import { useMemo } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import StartAdornment from './StartAdornment';

const useRenderInput = ({
  disabled,
  error,
  filters,
  helperText,
  label,
  loading,
  onChangeFilter,
  open,
  placeholder,
  required,
  TextFieldProps,
}) => {
  return useMemo(() => {
    return (params) => {
      const endAdornment = (
        <>
          {open && loading && <CircularProgress color="inherit" size={20} />}
          {params.InputProps.endAdornment}
        </>
      );
      const startAdornment = filters ? (
        <>
          <StartAdornment
            disabled={disabled}
            filters={filters}
            onChange={onChangeFilter}
          />
          {params.InputProps.startAdornment}
        </>
      ) : null;
      const InputProps = {
        ...params.InputProps,
        ...TextFieldProps?.InputProps,
        startAdornment,
        endAdornment,
      };

      return (
        <TextField
          {...params}
          {...TextFieldProps}
          disabled={disabled}
          error={error}
          helperText={helperText}
          InputProps={InputProps}
          label={label}
          placeholder={placeholder}
          required={required}
        />
      );
    };
  }, [
    disabled,
    error,
    filters,
    helperText,
    label,
    loading,
    onChangeFilter,
    open,
    placeholder,
    required,
    TextFieldProps,
  ]);
};

export default useRenderInput;
