import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import { useIsRequestCompleted } from 'hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    borderWidth: 0,
    paddingTop: theme.spacing(3),
  },
}));

const TableBodyNoContent = ({
  actionType,
  columnCount,
  count,
  noContentNode,
  text,
}) => {
  const classes = useStyles();
  const completed = useIsRequestCompleted(actionType);

  return completed && count === 0 ? (
    <TableRow>
      <TableCell colSpan={columnCount} className={classes.cell}>
        {noContentNode ?? (
          <Typography align="center" color="textSecondary" variant="subtitle1">
            {text}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  ) : null;
};

TableBodyNoContent.propTypes = {
  actionType: PropTypes.object.isRequired,
  columnCount: PropTypes.number.isRequired,
  count: PropTypes.number,
  noContentNode: PropTypes.node,
  text: PropTypes.string,
};

TableBodyNoContent.defaultProps = {
  count: undefined,
  noContentNode: undefined,
  text: 'No data',
};

export default TableBodyNoContent;
