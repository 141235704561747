import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

const derived = ({ accessExpSec, refreshExpSec, ...apiKey }) => ({
  ...apiKey,
  accessExpSec: accessExpSec ?? '',
  refreshExpSec: refreshExpSec ?? '',
  allAPIs: [...apiKey.ipv4s, ...apiKey.cidrs],
});

export default class ApiKey extends Model {
  static get modelName() {
    return 'ApiKey';
  }

  static reducer({ type, data }, ApiKey) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        ApiKey.create(data?.response);
        break;

      case LOAD.SUCCESS:
        ApiKey.delete();
        if (data?.response) {
          data.response.forEach((r) => ApiKey.upsert(derived(r)));
        }
        break;

      case LOAD.FAILURE:
        ApiKey.delete();
        break;

      case REMOVE.SUCCESS:
        if (ApiKey.idExists(id)) {
          ApiKey.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        ApiKey.upsert(derived(data.response));
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      accessExpSec: attr(),
      base64encode: attr(),
      clientKey: attr(),
      clientSecret: attr(),
      createdAt: attr(),
      id: attr(),
      ipv4s: attr(),
      cidrs: attr(),
      allAPIs: attr(), // derived
      name: attr(),
      offset: attr(),
      owner: attr(),
      refreshExpSec: attr(),
      roles: attr(),
      scopes: attr(),
      type: attr(),
      updatedAt: attr(),
    };
  }
}

const name = ApiKey.modelName;

export const ADD = createAddConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
