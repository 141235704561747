import { takeEvery } from 'redux-saga/effects';
import { load } from 'sdk/autopayAudit';
import { LOAD } from 'model/autopayAudit';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: load, dataName: ORM, type: LOAD })
  );
}
