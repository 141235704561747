import { memo } from 'react';
import { History as HistoryIcon } from '@material-ui/icons';
import { load, LOAD } from 'model/transactionHistory';
import { selector as busySelector } from 'selectors/busy';
import { selector } from 'selectors/transactionHistory';
import { useDispatch, useSelector } from 'react-redux';
import ActionsRenderer from './config/ActionsRenderer';
import Transactions from '../Transactions';
import { columnMeta, detailsRender, createSearchFields } from './config';

const defaultExpandablePanelProps = {
  heightOffset: 68,
  icon: HistoryIcon,
  id: 'history',
  scrollTopOffset: 0,
  title: 'Transaction History',
};

const History = (props) => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const items = useSelector((state) => selector(state, props));

  const clearAction = (props) => {
    dispatch({
      data: { props, response: [] },
      type: LOAD.SUCCESS,
    });
  };
  const loadAction = (params) => {
    dispatch(load(params));
  };

  return (
    <Transactions
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      actionType={LOAD.ACTION}
      clearAction={clearAction}
      columnMeta={columnMeta}
      countBack
      createSearchFields={createSearchFields}
      detailsRender={detailsRender}
      emptyTitle="No Transaction History Available"
      ExpandablePanelProps={{
        ...defaultExpandablePanelProps,
        showProgress: isBusy,
      }}
      isBusy={isBusy}
      items={items}
      loadAction={loadAction}
      name="history"
      priorityIndex={3}
      settingType="history"
      {...props}
    />
  );
};

export default memo(History);
