import { selectorCount } from 'selectors/org';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useInitialOrgCount = () => {
  const [count, setCount] = useState(null);
  const orgCount = useSelector(selectorCount);

  useEffect(() => {
    if (count === null && orgCount) {
      setCount(orgCount);
    }
  }, [count, orgCount]);

  return count;
};

export default useInitialOrgCount;
