import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { deleteMessage } from 'reducers/errorMessages';
import { useDispatch } from 'react-redux';

const ErrorAction = () => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(deleteMessage());
  };

  return (
    <IconButton aria-label="Close" onClick={handleClick}>
      <Close />
    </IconButton>
  );
};

export default ErrorAction;
