import PropTypes from 'prop-types';
import { useState } from 'react';
import ResizableDrawer from 'components/common/ResizableDrawer';
import NoteEditor from 'components/common/Note/NoteEditor';
import { RegularNoteType } from 'components/common/Note/consts';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { selectorId } from 'selectors/researchNote';
import { useSelector, shallowEqual } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  appBarPrimaryOverrideColor: {
    backgroundColor: theme.palette.error.light,
  },
  drawer: {
    height: '80%',
    width: '60%',
    margin: '100px',
    marginTop: '100px',
    marginBottom: '200px',
    border: '2px solid lightgrey',
  },
  editor: {
    marginTop: theme.spacing(3),
    overflowY: 'auto',
  },
  flex: {
    flex: '1 1 auto',
  },
}));

const NoteWriter = ({ fspId, onClose, open }) => {
  const item = useSelector((state) => selectorId(state), shallowEqual);
  const [noteType, setNoteType] = useState(item?.noteType);
  const classes = useStyles();

  const handleCancel = (event) => onClose(event, false);
  const handleChangeType = (value) => setNoteType(value);
  const handleClose = (event) => onClose(event, true);

  const isRegularNote = noteType === RegularNoteType;
  const appBarColor = isRegularNote ? 'default' : 'primary';
  const title = `Add ${isRegularNote ? '' : 'Consumer Notification'} Note`;

  const handleClick = (event) => {
    event.stopPropagation();
  };

  return (
    <ResizableDrawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      open={open}
      variant="persistent"
      onClick={handleClick}
    >
      {open && (
        <>
          <AppBar
            classes={{ colorPrimary: classes.appBarPrimaryOverrideColor }}
            color={appBarColor}
            position="static"
          >
            <Toolbar>
              <Typography className={classes.flex} color="inherit" variant="h6">
                {title}
              </Typography>
              <IconButton
                aria-label="Close Add Note Window"
                color="inherit"
                onClick={handleCancel}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className={classes.editor}>
            <NoteEditor
              fspId={fspId}
              onCancel={handleCancel}
              onChangeType={handleChangeType}
              onSubmit={handleClose}
              readOnly={!open} // fixes issue where editor toolbar displays when dragging or at certain window sizes
            />
          </div>
        </>
      )}
    </ResizableDrawer>
  );
};

NoteWriter.propTypes = {
  fspId: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

NoteWriter.defaultProps = {
  fspId: null,
  onClose: always,
  open: false,
};

export default NoteWriter;
