import { client } from 'util/sdk';
import { POST } from 'util/api';

const endPointUrlWithFspAndPayer = (fspId, payerId) =>
  `/fsps/${fspId}/payers/${payerId}/payerEbillerNotify`;
const returnModel = 'payerebillernotify';

export const load = ({ fspId, payerId, limit, offset }) =>
  client.executeRequest(
    'GET',
    `/fsps/${fspId}/payers/${payerId}/payerebillernetebillers`,
    {
      queryParams: { limit, offset },
      returnModel: { name: 'payerzebillernetebiller' },
    }
  );

export const refresh = ({ fspId, payerId, ...data }) => {
  const url = endPointUrlWithFspAndPayer(fspId, payerId);

  return client.executeRequest(POST, url, {
    data,
    returnModel,
  });
};
