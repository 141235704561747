import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import {
  PAL_ESCROW_DERIVED,
  PAL_INTEREST_DERIVED,
  PAL_PRINCIPAL_DERIVED,
  TRANSACTION_TYPE_PAL,
} from 'consts';
import usePayALoanTransaction from '../../usePayALoanTransaction';

const AppliedTo = ({ transaction }) => {
  return (
    usePayALoanTransaction(transaction.transactionType) && (
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Applied To"
          value={
            transaction.transactionType === PAL_PRINCIPAL_DERIVED
              ? 'PAL Principal'
              : transaction.transactionType === PAL_ESCROW_DERIVED
              ? 'PAL Escrow'
              : transaction.transactionType === PAL_INTEREST_DERIVED
              ? 'PAL Interest'
              : transaction.transactionType === TRANSACTION_TYPE_PAL
              ? 'PAL'
              : transaction.transactionType
          }
        />
      </Grid>
    )
  );
};

AppliedTo.propTypes = {
  transaction: PropTypes.shape({
    transactionType: PropTypes.string,
  }).isRequired,
};

export default AppliedTo;
