import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.ActionInsightEvent);

export const selectorFspId = createSelector(
  orm,
  (state, fspId) => fspId,
  ({ ActionInsightEvent }, fspId) =>
    ActionInsightEvent.filter(
      ({ fspId: eventFspId }) => eventFspId.toString() === fspId.toString()
    ).toRefArray()
);
