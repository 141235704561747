import PropTypes from 'prop-types';
import { memo } from 'react';
import { defaultTo } from 'ramda';
import {
  FormControl,
  FormHelperText,
  Input,
  makeStyles,
  InputLabel,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {},
});

const Field = ({
  autoFocus,
  component,
  disabled,
  endAdornment,
  error,
  fullWidth,
  helperText,
  id,
  label,
  onChange,
  onKeyPress,
  placeholder,
  testId,
  value,
  inputRef,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <FormControl
      classes={{ root: classes.root }}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      t-i={defaultTo(id, testId)}
    >
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <Input
        autoFocus={autoFocus}
        endAdornment={endAdornment}
        inputComponent={component}
        inputProps={{
          'aria-label': label,
          placeholder,
        }}
        inputRef={inputRef}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Field.propTypes = {
  autoFocus: PropTypes.bool,
  component: PropTypes.func,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,
};

Field.defaultProps = {
  autoFocus: false,
  disabled: false,
  endAdornment: undefined,
  error: false,
  fullWidth: undefined,
  helperText: '',
  inputRef: undefined,
  component: undefined,
  label: null,
  id: null,
  onKeyPress: null,
  placeholder: null,
  testId: null,
  value: null,
};

export default memo(Field);
