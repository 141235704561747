import { OPEN } from 'components/modules/paymentResearch/Status';
import { client, formatQueryParams } from 'util/sdk';

export const load = ({ fspId, filterByFsp, searchFSPId, ...params }) => {
  if (filterByFsp || searchFSPId) {
    params.fspId = searchFSPId || fspId;
  }

  return client
    .fsps(fspId)
    .transactionHistoryResearchRequests.query(formatQueryParams(params));
};

export const loadAssigned = ({ fspId, id, filterByFsp, ...restOfData }) =>
  client.fsps(fspId).transactionHistoryResearchRequests.query(
    formatQueryParams({
      assignedAdminUserId: id,
      status: OPEN,
      ...(filterByFsp && { fspId }),
      ...restOfData,
    })
  );

export const loadById = async ({ fspId, id }) => {
  const requests = await client
    .fsps(fspId)
    .transactionHistoryResearchRequests.query(
      formatQueryParams({
        id,
        expand: ['histtran'],
      })
    );
  return requests.length === 0 ? null : requests[0];
};

export const loadByHistId = ({ fspId, id }) =>
  client
    .fsps(fspId)
    .transactionHistoryResearchRequests.query((_) => [
      _.transactionHistoryId(id),
    ]);

export const create = ({ fspId, transactionHistoryId, ...rest }, auth) =>
  client
    .fsps(fspId)
    .transactionHistory(transactionHistoryId)
    .transactionHistoryResearchRequests.create(rest, { adminUserId: auth.id });

export const claim = ({ fspId, adminUserId }) =>
  client.fsps(fspId).claimTransactionResearchRequest({ adminUserId });

export const update = ({ fspId, id, obj }, auth) =>
  client
    .fsps(fspId)
    .transactionHistoryResearchRequests(id)
    .update(obj, { adminUserId: auth.id });

export const decryptAccountNumberOnBill = ({ fspId, id }) =>
  client
    .fsps(fspId)
    .transactionHistoryResearchRequests(id)
    .decryptAccountNumberOnBill();
