import { GET, authApi, postApi, resetApi, getApi } from 'util/api';
import { hashPassword } from 'util/hashPassword';
import { client } from 'util/sdk';

const AUTH = '/auth';
const NOAUTH = '/noauth';
const KEEP_ALIVE = `${AUTH}/keepAlive`;
const USER_AFTER_REFRESH = `${AUTH}/userAfterRefresh`;
const ALIVE = `${NOAUTH}/alive`;
const LOGIN = `${NOAUTH}/login`;
const LOGOUT = `${NOAUTH}/logout`;
const RESET = `${NOAUTH}/reset`;
const UPDATE = `${AUTH}/update`;
const VALIDATE = `${NOAUTH}/validate`;
const VERIFY_USER = `${AUTH}/verifyUser`;

export const alive = () => getApi(ALIVE);

export const keepAlive = (data) => authApi(KEEP_ALIVE, data, undefined, GET);

export const login = ({ otp, password, sendOTP, username }) => {
  const hash = hashPassword(password, username);

  // Bearer token is not cleared from browser - https://payrailz.atlassian.net/browse/ON-1127
  client.clearCache();

  return authApi(LOGIN, {
    otp,
    password: hash,
    sendOTP,
    username,
  });
};

export const logout = (data) => postApi(LOGOUT, data);

export const reset = (data) => resetApi(RESET, data);

export const update = ({ newPassword, token, userName }) => {
  const newPasswordHash = hashPassword(newPassword, userName);

  // token only has a value when a password is being reset.
  // For a "normal" password update this value will be null
  // as the user will already have a valid JWT session on the server
  return postApi(UPDATE, { newPassword: newPasswordHash, userName }, { token });
};

export const userAfterRefresh = (data) =>
  authApi(USER_AFTER_REFRESH, data, undefined, GET);

export const validate = (data) => postApi(VALIDATE, data);

export const verifyUser = ({ password, token, username }) => {
  const hash = hashPassword(password, username);

  return postApi(
    VERIFY_USER,
    {
      password: hash,
      username,
    },
    { token }
  );
};
