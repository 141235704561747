import { attr, Model } from 'redux-orm';
import { omit } from 'ramda';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  dateAndTime,
  createRequestConst,
} from '../util';

const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);

  return r;
};

export default class BillerSetBiller extends Model {
  static get modelName() {
    return 'BillerSetBiller';
  }

  static reducer({ type, data = [] }, BillerSetBiller, { Biller }) {
    switch (type) {
      case CHECK.ACTION: {
        const biller = BillerSetBiller.withId(data.id);
        if (biller) {
          biller.selected = !biller.selected;

          biller.update(biller);
        }
        break;
      }

      case CHECK_ALL.ACTION: {
        BillerSetBiller.update({ selected: data.selected });
        break;
      }

      case ADD.SUCCESS: {
        let biller = Biller.withId(data?.response?.billerId)?.ref;

        if (biller) {
          biller = {
            ...omit(['selected'], biller),
            billerSetId: data?.response?.billerSetId,
          };
        }
        BillerSetBiller.create(addDerived(biller || data.response));

        break;
      }

      case LOAD.SUCCESS: {
        BillerSetBiller.delete();
        (data?.response || []).forEach((billerSetBiller) => {
          BillerSetBiller.upsert(addDerived(billerSetBiller));
        });
        break;
      }

      case LOAD.FAILURE:
        BillerSetBiller.delete();
        break;

      case REMOVE.SUCCESS:
        BillerSetBiller.filter(({ selected }) => selected).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      billernetName: attr(),
      billerTypeId: attr(),
      billerLogoId: attr(),
      billerSet: attr(),
      billerSetId: attr(),
      billerStatus: attr(),
      createdAt: attr(),
      createdAtLabel: attr(),
      href: attr(),
      id: attr(),
      insBillernetId: attr(),
      insAliasForBillerNameOffOn: attr(),
      name: attr(),
      netIdStr: attr(),
      offset: attr(),
      rppsBillerId: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(),
      updBillernetId: attr(),
      updateSucceededDerived: attr(),
      zipMatchNeededOffOn: attr(),
    };
  }
}

const name = BillerSetBiller.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
