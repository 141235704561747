import { EP } from 'consts';
import { updateInLocation } from 'use-query-params';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const getExistingTypes = (search) => {
  const params = new URLSearchParams(search);

  return params.get(EP);
};
const getLocation = (location, type, subtype, id) => {
  const existingTypes = getExistingTypes(location?.search);
  const encodedQueryReplacements = {
    [EP]: existingTypes
      ? existingTypes.includes(type)
        ? existingTypes
        : `${existingTypes}_${type}`
      : type,
  };

  if (subtype) {
    encodedQueryReplacements[subtype] = id;
  }

  return updateInLocation(encodedQueryReplacements, location);
};

const useLinkTo = (type, subtype) => {
  const location = useLocation();

  return useCallback(
    (id) => getLocation(location, type, subtype, id),
    [location, subtype, type]
  );
};

export default useLinkTo;
