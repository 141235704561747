import { attr, fk, Model } from 'redux-orm';
import { isNil } from 'ramda';
import moment from 'moment';
import Payer from 'model/payer';
import { createAction, createLoadConst, formatCurrency } from '../util';

const payAmountPal = {
  fullBal: 'Full Balance',
  minAmt: 'Min Amount Due',
  twiceMinAmt: 'Twice Min Amount',
  amtDue: 'Amount Due',
};

export const derived = (autopayPal) => {
  const suspendUntil = moment(autopayPal.suspendUntilOn);
  const palStatusDerived =
    !isNil(suspendUntil) && moment().diff(suspendUntil) < 0
      ? `Suspended (Until ${suspendUntil.format('MM-DD-YYYY')})`
      : 'Enabled';
  const billGreaterThanAmountPalDerived = autopayPal.billGreaterThanAmount
    ? formatCurrency(autopayPal.billGreaterThanAmount)
    : '';
  const billGreaterThanCustomAmountPalDerived =
    autopayPal.billGreaterThanCustomAmount
      ? formatCurrency(autopayPal.billGreaterThanCustomAmount)
      : '';

  return {
    ...autopayPal,
    billGreaterThanAmountPalDerived,
    billGreaterThanCustomAmountPalDerived,
    payAmountPalDerived: payAmountPal[autopayPal.payAmount],
    palStatusDerived,
  };
};

export default class AutopayPal extends Model {
  static get modelName() {
    return 'AutopayPal';
  }

  static reducer({ type, data }, AutopayPal) {
    switch (type) {
      case LOAD.SUCCESS:
        (data?.response || []).forEach((autopayPal) => {
          AutopayPal.upsert(derived(autopayPal));
        });
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      billGreaterThanAmount: attr(),
      billGreaterThanCustomAmount: attr(),
      billGreaterThanNotPayOffOn: attr(),
      daysPriorDue: attr(),
      description: attr(),
      fromOneOf: attr(),
      fsp: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insertedAt: attr(),
      lastUpdatedAt: attr(),
      loanDdaId: attr(),
      loanTransactionType: attr(),
      palStatusDerived: attr(),
      payAmount: attr(),
      payAmountPalDerived: attr(),
      payer: attr(),
      payerId: fk(Payer.modelName, 'autopayPal'),
      scheduleEndOn: attr(),
      scheduleStartOn: attr(),
      suspendUntilOn: attr(),
      termsAndConditionsAccepted: attr(),
      updatedAt: attr(),
    };
  }
}

const name = AutopayPal.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
