import { client } from 'util/sdk';
import { GET } from 'util/api';

const returnModel = { name: 'denyOfferings' };
const queryParams = ({ id }) => ({ adminUserId: id });
const denyListOfferingsUri = (fspId) => `v2/fsps/${fspId}/deny-offerings`;

// eslint-disable-next-line import/prefer-default-export
export const loadDenyListOfferings = (fspId, auth) =>
  client.executeRequest(GET, denyListOfferingsUri(fspId), {
    queryParams: queryParams(auth),
    returnModel,
  });
