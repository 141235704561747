import PropTypes from 'prop-types';
import MuiTextFieldRoutingNumber from 'components/common/fields/TextFieldRoutingNumber';
import { useFormikContext } from 'formik';
import { getDisabled } from '../consts';
import { useFieldWithErrors } from '../hooks';

const TextFieldRoutingNumber = ({
  autoComplete,
  disabled,
  name,
  showValidateButton,
  ...props
}) => {
  const [field, meta, , errorProps] = useFieldWithErrors(name);
  const formikContext = useFormikContext();

  return (
    <MuiTextFieldRoutingNumber
      {...field}
      {...errorProps}
      autoComplete={autoComplete}
      disabled={getDisabled(disabled, field, meta, formikContext)}
      name={name}
      showValidateButton={showValidateButton}
      {...props}
    />
  );
};

TextFieldRoutingNumber.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showValidateButton: PropTypes.bool,
};

TextFieldRoutingNumber.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  showValidateButton: false,
};

export default TextFieldRoutingNumber;
