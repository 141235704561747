import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CancelButton, CheckButton } from 'components/common/icon';
import { Grid, IconButton, TextField } from '@material-ui/core';
import { always, isNil } from 'ramda';
import { selector } from 'selectors/request';
import { isUpdatingState } from 'reducers/updating';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../AlertImproved';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 48,
  },
  rootNoOnChange: {
    paddingTop: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.primary,
    font: 'inherit',
    fontFamily: theme.typography.fontFamily,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  alignButton: {
    minHeight: 70,
    paddingTop: 6,
  },
}));

const TextFieldAction = ({
  enabled,
  onChange,
  onCheck,
  actionType,
  label,
  cancelLabel,
  continueLabel,
  alertMessage,
  readOnlyNode,
  value,
  isValueRequired,
}) => {
  const emptyValueMessage = `${label} must contain a value and cannot include spaces.`;
  const classes = useStyles();
  const [alertCancelLabel, setAlertCancelLabel] = useState(cancelLabel);
  const [alertContinueLabel, setAlertContinueLabel] = useState(continueLabel);
  const [editMode, setEditMode] = useState(false);
  const [keepAlertOpen, setKeepAlertOpen] = useState(false);
  const [originalValue, setOriginalValue] = useState(value);
  const [originalMessage] = useState(alertMessage);
  const [updatedMessage, setUpdatedMessage] = useState(alertMessage);
  const [showAlert, setShowAlert] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const isUpdating = useSelector(
    (state) => !!actionType && isUpdatingState(state, actionType.ACTION)
  );
  const [completed, failed] = useSelector((state) =>
    selector(state, actionType)
  );

  const handleEditOpen = (event) => {
    event.stopPropagation();
    setEditMode(true);
  };
  const handleEditClose = (event) => {
    event.stopPropagation();
    setEditMode(false);
    setUpdatedValue(originalValue);
  };
  const handleMenuChange = (event) => {
    event.stopPropagation();
    setUpdatedValue(event.target.value);
  };
  const handleCheck = (event) => {
    event.stopPropagation();

    const isSpaceInUpdatedValue = updatedValue.includes(' ');
    if (isValueRequired && (!updatedValue || isSpaceInUpdatedValue)) {
      setUpdatedValue(originalValue);
      setUpdatedMessage(emptyValueMessage);
      setAlertCancelLabel('ok');
      setAlertContinueLabel('');
      setShowAlert(true);
      return;
    }

    setUpdatedMessage(originalMessage);
    const updatedLabels = onCheck(event, updatedValue) || {};

    setAlertCancelLabel(
      updatedLabels.cancelLabel ? updatedLabels.cancelLabel : cancelLabel
    );

    setAlertContinueLabel(
      updatedLabels.continueLabel ? updatedLabels.continueLabel : continueLabel
    );

    setShowAlert(true);
  };
  const handleAlertClose = (event) => {
    event.stopPropagation();
    setShowAlert(false);
  };
  const handleAlertConfirm = (event) => {
    event.stopPropagation();
    setShowAlert(false);
    setKeepAlertOpen(true);
    setEditMode(false);
    setOriginalValue(updatedValue);
    onChange(updatedValue);
  };

  useEffect(() => {
    if (isUpdating) {
      if (showAlert) {
        setKeepAlertOpen(true);
      }
    } else {
      setKeepAlertOpen(false);
    }
  }, [showAlert, isUpdating]);

  useEffect(() => {
    if (completed || failed) {
      setUpdatedValue(value);
      setOriginalValue(value);
    }
    setEditMode(false);
  }, [completed, failed, value]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootNoOnChange]: isNil(onChange) || !enabled,
      })}
    >
      {editMode ? (
        <>
          <TextField
            label={label}
            value={updatedValue}
            onChange={handleMenuChange}
          />
          {originalValue !== updatedValue && (
            <CheckButton
              color="primary"
              data-testid="check"
              onClick={handleCheck}
              t-i="check"
            />
          )}
          <CancelButton
            data-testid="cancel"
            onClick={handleEditClose}
            t-i="cancel"
          />
        </>
      ) : (
        <Grid alignItems="center" container wrap="nowrap">
          <Grid item zeroMinWidth>
            {readOnlyNode}
          </Grid>
          <Grid item className={classes.alignButton}>
            {enabled && onChange && (
              <IconButton
                aria-label="edit mode icon"
                data-testid="edit"
                onClick={handleEditOpen}
                role="button"
              >
                <Edit />
              </IconButton>
            )}
          </Grid>
        </Grid>
      )}
      <Alert
        actionType={actionType}
        cancelLabel={alertCancelLabel}
        confirmLabel={alertContinueLabel}
        content={updatedMessage}
        onClose={handleAlertClose}
        onConfirm={handleAlertConfirm}
        open={showAlert || keepAlertOpen}
      />
    </div>
  );
};

TextFieldAction.propTypes = {
  actionType: PropTypes.object,
  alertMessage: PropTypes.string,
  cancelLabel: PropTypes.string,
  continueLabel: PropTypes.string,
  label: PropTypes.string,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCheck: PropTypes.func,
  readOnlyNode: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  value: PropTypes.string,
  isValueRequired: PropTypes.bool,
};

TextFieldAction.defaultProps = {
  actionType: {},
  alertMessage: 'Are you sure you want to update this value?',
  cancelLabel: 'Cancel',
  continueLabel: 'OK',
  label: '',
  enabled: true,
  onChange: always,
  onCheck: () => ({}),
  value: '',
  isValueRequired: false,
};

export default TextFieldAction;
