import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { DATA_KEY } from 'model/util/recipient';
import { createSelector as createReselectSelector } from 'reselect';
import { DEFAULT_MODEL_REF_ARRAY, DEFAULT_MODEL_REF, ACTIVE } from '../consts';

export const selectorId = createSelector(orm.Recipient);

export const selector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, payerId) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId)[DATA_KEY].toRefArray()
      : DEFAULT_MODEL_REF_ARRAY
);

export const selectorCountByPayerId = createReselectSelector(
  selector,
  (items) => items.filter((item) => item.recipientStatus === ACTIVE).length
);

export const selectorPayerIdAndId = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { id, payerId }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId)
          [DATA_KEY].filter((recipient) => recipient.id === id)
          .first().ref
      : DEFAULT_MODEL_REF
);
