import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { selectorPayerId } from 'selectors/payerUser';
import { Autocomplete } from '@material-ui/lab';
import { load } from 'model/payerUser';
import { isBusinessSelector } from 'selectors/payer';
import { useDispatch, useSelector } from 'react-redux';
import { selector as pageSettingsSelector } from 'selectors/pageSetting';
import { update as updatePageSettings } from 'model/pageSetting';
import { pageSettingsName } from '../config';
import FilterByUsersAutocompleteRenderInput from './FilterByUsersAutocompleteRenderInput';

const getOptionLabel = ({ name }) => name;

const FilterByUsersAutocomplete = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const payerUsers = useSelector((state) => selectorPayerId(state, payerId));
  const isBusiness = useSelector((state) => isBusinessSelector(state, payerId));
  const pageSettings = useSelector((state) =>
    pageSettingsSelector(state, payerId, pageSettingsName)
  );

  const handleChange = (event, value) => {
    dispatch(
      updatePageSettings({
        ...pageSettings,
        payerId,
        type: pageSettingsName,
        userId: value?.userId,
      })
    );
  };

  useEffect(() => {
    if (isBusiness) {
      dispatch(load({ fspId, payerId }));
    }
  }, [dispatch, fspId, isBusiness, payerId]);

  return isBusiness ? (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      options={payerUsers}
      renderInput={FilterByUsersAutocompleteRenderInput}
      value={
        payerUsers && pageSettings?.userId
          ? payerUsers.find((user) => user.userId === pageSettings?.userId)
          : null
      }
    />
  ) : null;
};

FilterByUsersAutocomplete.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default FilterByUsersAutocomplete;
