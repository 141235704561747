import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export default class Org extends Model {
  static get modelName() {
    return 'Org';
  }

  static reducer({ type, data }, Org) {
    switch (type) {
      case LOAD.SUCCESS:
      case THROTTLED_LOAD.SUCCESS:
        Org.delete();
        data?.forEach((r) => Org.upsert(r));
        break;

      case ADD.SUCCESS:
        Org.upsert(data);
        break;

      case DELETE.SUCCESS:
        Org.withId(data.id).delete();
        break;

      case UPDATE.SUCCESS:
        Org.withId(data.id).update(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      adminGroupId: attr(),
      createdAt: attr(),
      description: attr(),
      failedLoginMaxTries: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      ipWhitelistOffOn: attr(),
      ipv4s: attr(),
      maxPasswordLength: attr(),
      minPasswordLength: attr(),
      name: attr(),
      offset: attr(),
      otpAgainAfterDays: attr(),
      otpDigit: attr(),
      otpExpireMinutes: attr(),
      otpMaxSendTries: attr(),
      otpMaxTries: attr(),
      otpOffOn: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const FORM_NAME = 'setupOrg';

export const ADD = createAddConst(Org.modelName);
export const DELETE = createDeleteConst(Org.modelName);
export const LOAD = createLoadConst(Org.modelName);

export const THROTTLED_LOAD = createLoadConst(`${Org.modelName}_throttled`);
export const UPDATE = createUpdateConst(Org.modelName);

export const addOrg = createAction(ADD);
export const deleteOrg = createAction(DELETE);
export const load = createAction(LOAD);
export const throttledLoad = createAction(THROTTLED_LOAD);
export const updateOrg = createAction(UPDATE);
