import PropTypes from 'prop-types';
import CreditList from './CreditList';

const Credit = ({ fspId, payerId }) => {
  return <CreditList fspId={fspId} payerId={payerId} />;
};

Credit.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default Credit;
