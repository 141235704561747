import DetailsRender from './DetailsRender';
import StatusSelector from './StatusSelector';

const renderer = (status, recipient) => (
  <StatusSelector currentStatus={status} recipient={recipient} />
);

export { default as ActionsRenderer } from './ActionsRenderer';

export const columnMeta = [
  {
    context: true,
    label: 'Name',
    id: 'name',
    sortId: 'name',
    xs: 3,
  },
  {
    id: 'nickName',
    label: 'Nickname',
    sortId: 'nickName',
    xs: 3,
  },
  {
    capitalize: true,
    id: 'status',
    sortId: 'status',
    label: 'Status',
    xs: 3,
    renderer,
  },
  {
    id: 'notificationMethodDerived',
    label: 'Notification Method',
    xs: 3,
  },
];

export const defaultOrderBy = 'name';

export const detailsRender = (recipient, options) => (
  <DetailsRender options={options} recipient={recipient} />
);
