import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
    width: 600,
  },
}));

const AuditDetailPaper = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.paper}>{children}</Paper>;
};

AuditDetailPaper.propTypes = {
  children: PropTypes.node,
};

AuditDetailPaper.defaultProps = {
  children: null,
};

export default AuditDetailPaper;
