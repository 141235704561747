import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(1),
    height: '100%',
    width: '100%',
  },
}));

const Features = ({ checkedIcon, disabled, features, title }) => {
  const classes = useStyles();

  return (
    <Card elevation={0}>
      <CardContent>
        <FormControl className={classes.form} component="fieldset">
          <FormLabel component="legend">{title}</FormLabel>
          <FormGroup className={classes.form}>
            {features.map(({ checked, key, label }) => (
              <FormControlLabel
                key={key}
                control={
                  disabled ? (
                    <Checkbox
                      checked={checked}
                      checkedIcon={checkedIcon}
                      color="primary"
                      disabled
                    />
                  ) : (
                    <Switch checked={checked} color="primary" />
                  )
                }
                label={label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </CardContent>
    </Card>
  );
};

Features.propTypes = {
  checkedIcon: PropTypes.node,
  disabled: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

Features.defaultProps = {
  checkedIcon: undefined,
  disabled: false,
  features: [],
  title: '',
};

export default Features;
