import { omit } from 'ramda';
import { compose, pure, withProps } from 'recompose';
import { withFormik } from 'formik';

export const formProps = [
  /**
   * local props
   */
  'action',
  'onValidation',
  /**
   * formik props
   */
  // withFormik props
  'resetForm',
  'setErrors',
  'setFieldError',
  'setFieldTouched',
  'setFieldValue',
  'setStatus',
  'setSubmitting',
  'setTouched',
  'setValues',
  // formik render methods and props
  'dirty',
  'errors',
  'getFieldHelpers',
  'getFieldMeta',
  'getFieldProps',
  'handleBlur',
  'handleChange',
  'handleReset',
  'handleSubmit',
  'initialErrors',
  'initialStatus',
  'isSubmitting',
  'initialTouched',
  'isValid',
  'isValidating',
  'resetForm',
  'setErrors',
  'setFieldError',
  'setFieldTouched',
  'setFieldValue',
  'setStatus',
  'setSubmitting',
  'setTouched',
  'setValues',
  'status',
  'submitCount',
  'submitForm',
  'touched',
  'validateField',
  'validateForm',
  'validateOnMount',
  'values',
  // formik props I found
  'initialValues',
  'registerField',
  'setError',
  'setFormikState',
  'unregisterField',
  'validateOnBlur',
  'validateOnChange',
];

export const unmaskValue = (mask) => mask.replace(/\D+/g, '');

export default compose(
  withFormik({
    enableReinitialize: true,
    handleSubmit: (
      values,
      { props: { action, data, unmask = false, id, omitted = [] } }
    ) => {
      if (unmask) {
        values[id] = unmaskValue(values[id]);
      }

      if (data[id] !== values[id]) {
        action(omit(omitted, values));
      }
    },
    validateOnMount: true,
    mapPropsToValues: ({ data }) => data,
    validate: (values, { id, onValidation = () => ({}) }) =>
      onValidation(values && values[id], id),
  }),
  withProps(({ errors, handleBlur, handleChange, id, touched, values }) => ({
    error: touched[id] && Boolean(errors[id]),
    helperText: touched[id] ? errors[id] : null,
    onBlur: handleBlur,
    onChange: handleChange,
    value: values && id ? values[id] : '',
  })),
  pure
);
