/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { throttle } from 'throttle-debounce';
import { useState, useRef, forwardRef } from 'react';
import { useEventListener } from 'hooks';

const useStyles = makeStyles((theme) => ({
  drag: {
    bottom: 0,
    cursor: 'ew-resize',
    left: 0,
    position: 'absolute',
    top: 0,
    width: 5,
    zIndex: 10,
  },
  highlight: {
    backgroundColor: theme.palette.text.disabled,
    width: 5,
  },
}));

const ResizableDrawer = forwardRef(
  (
    { children, maxWidth, minWidth, onResize, PaperProps, width, ...props },
    ref
  ) => {
    const [toggleHighlight, setToggleHighlight] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [style, setStyle] = useState({ width });
    const classes = useStyles();
    const throttledOnResize = useRef(throttle(250, onResize));

    const handleMouseDown = () => {
      setResizing(true);
    };
    const handleMouseMove = (event) => {
      if (resizing) {
        const { body } = document;
        const width = body.offsetWidth - (event.clientX - body.offsetLeft);

        // Don't highlight text while dragging
        event.preventDefault();

        if (width > minWidth) {
          setStyle({ maxWidth, width });
          throttledOnResize.current(event, width);
        }
      }
    };
    const handleMouseOut = () => {
      setToggleHighlight(false);
    };
    const handleMouseOver = () => {
      setToggleHighlight(true);
    };
    const handleMouseUp = () => {
      setResizing(false);
    };

    useEventListener('mousemove', handleMouseMove);
    useEventListener('mouseup', handleMouseUp);

    return (
      <Drawer {...props} ref={ref} PaperProps={{ ...PaperProps, style }}>
        <div
          className={classNames(classes.drag, {
            [classes.highlight]: toggleHighlight,
          })}
          onMouseDown={handleMouseDown}
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
          role="presentation"
        />
        {children}
      </Drawer>
    );
  }
);

ResizableDrawer.displayName = 'ResizableDrawer';

ResizableDrawer.propTypes = {
  children: PropTypes.node,
  PaperProps: PropTypes.object,
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onResize: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ResizableDrawer.defaultProps = {
  children: null,
  maxWidth: null,
  minWidth: 100,
  onResize: always,
  PaperProps: null,
  width: null,
};

export default ResizableDrawer;
