import { compose, pure, withHandlers } from 'recompose';
import { unmask } from 'sdk/payee';
import { Mask } from 'components/common';

export default compose(
  withHandlers({
    api:
      ({ item, deletedOnly }) =>
      () =>
        unmask(item, deletedOnly),
  }),
  pure
)(Mask);
