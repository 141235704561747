import {
  A2A_INTERNAL_OFF_ON,
  A2A_IN_OFF_ON,
  A2A_OUT_OFF_ON,
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_INTERNAL,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  BILL_PAY_LABEL,
  BP_OFF_ON,
  NONE,
  PAY_A_LOAN_LABEL,
  PAY_A_LOAN_OFF_ON,
  P2P_CONFIG,
  P2P_DIRECT_OFF_ON,
  P2P_OFF_ON,
  PERSON_PAY,
  PERSON_TO_PERSON,
  TRANSACTION_TYPE_BILL_PAY,
  TRANSACTION_TYPE_PAL,
  TRANSFER_MONEY_IN,
  TRANSFER_MONEY_INTERNAL,
  TRANSFER_MONEY_OUT,
  VENMO_DIRECT,
  VENMO_DIRECT_CODE,
} from 'consts';
import { isOn } from 'util/index';

/**
 * Transaction types:
 */
const types = [
  {
    always: true,
    name: NONE,
    value: '',
  },
  {
    name: BILL_PAY_LABEL,
    setting: BP_OFF_ON,
    value: TRANSACTION_TYPE_BILL_PAY,
  },
  {
    name: PAY_A_LOAN_LABEL,
    setting: PAY_A_LOAN_OFF_ON,
    value: TRANSACTION_TYPE_PAL,
  },
  {
    name: PERSON_PAY,
    setting: P2P_OFF_ON,
    value: PERSON_TO_PERSON,
  },
  {
    name: TRANSFER_MONEY_IN,
    setting: A2A_IN_OFF_ON,
    value: ACCOUNT_TO_ACCOUNT_INBOUND,
  },
  {
    name: TRANSFER_MONEY_OUT,
    setting: A2A_OUT_OFF_ON,
    value: ACCOUNT_TO_ACCOUNT_OUTBOUND,
  },
  {
    name: TRANSFER_MONEY_INTERNAL,
    setting: A2A_INTERNAL_OFF_ON,
    value: ACCOUNT_TO_ACCOUNT_INTERNAL,
  },
  {
    name: VENMO_DIRECT,
    setting: P2P_OFF_ON,
    subSetting: [P2P_CONFIG, P2P_DIRECT_OFF_ON],
    value: VENMO_DIRECT_CODE,
  },
  /**
   * For MVP and maybe past, we won't have support for expedited checks
   */
  // {
  //   name: 'Expedited Check Bill Pay',
  //   setting: 'bpOffOn',
  //   subSetting: ['bpConfig', 'bpExpediteOffOn'],
  //   value: 'ebp',
  // },
  /**
   * On hold for now - Horng to talk to Paul - 08/06/2018
   */
  // {
  //   name: 'Account Activation Credit',
  //   setting: 'unknown',
  //   value: 'a2aMicroCredit',
  // },
  // {
  //   name: 'Account Activation Debit',
  //   setting: ['a2aConfig', 'bpExpediteOffOn'],
  //   value: 'a2aMicroDebit',
  // },
];

// eslint-disable-next-line import/prefer-default-export
export const getTypes = (fspConfig, scheduled) => {
  return types.filter(({ always, setting, subSetting }) => {
    let retVal = false;

    if (always) {
      retVal = true;
    } else if (isOn(fspConfig[setting])) {
      if (subSetting) {
        retVal = isOn(fspConfig[subSetting[0]][subSetting[1]]) && !scheduled;
      } else {
        retVal = true;
      }
    }

    return retVal;
  });
};
