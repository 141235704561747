import { Button, InputAdornment, Tooltip, withStyles } from '@material-ui/core';
import { ENTER_DELAY } from 'sagas';
import { Error } from '@material-ui/icons';

const styles = (theme) => ({
  error: {
    color: theme.palette.error.main,
  },
});

const DollarSign = withStyles(styles)(({ classes, error }) => (
  <InputAdornment position="start">
    <span className={error ? classes.error : null}>$</span>
  </InputAdornment>
));

const ErrorIndicator = withStyles(styles)(({ classes, title }) => (
  <Tooltip title={title}>
    <Error className={classes.error} fontSize="small" />
  </Tooltip>
));

const Reset = (props) => {
  return (
    <Tooltip enterDelay={ENTER_DELAY} title="Reset to original value">
      <Button {...props} size="small">
        Reset
      </Button>
    </Tooltip>
  );
};

export { DollarSign, ErrorIndicator, Reset };
