import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { cents as getCents, decimal } from 'util/format';

const styles = (theme) => ({
  typography: {
    color: theme.palette.action.active,
    paddingLeft: 2,
  },
});

const TextFieldCurrencyReadOnlyCents = ({ classes, value }) => {
  const [cents, setCents] = useState('00');

  useEffect(() => {
    const decimalValue = decimal(value);

    setCents(getCents(decimalValue));
  }, [value]);

  return (
    <Typography className={classes.typography} variant="body2">
      <sup>{cents}</sup>
    </Typography>
  );
};

TextFieldCurrencyReadOnlyCents.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextFieldCurrencyReadOnlyCents.defaultProps = {
  value: 0,
};

export default withStyles(styles)(TextFieldCurrencyReadOnlyCents);
