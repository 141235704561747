import { takeEvery, takeLatest } from 'redux-saga/effects';
import { load, loadId, update } from 'sdk/recipient';
import { LOAD, LOAD_ID, UPDATE } from 'model/recipient';
import { getName } from 'model/util/recipient';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    LOAD_ID.ACTION,
    callAction({
      api: loadId,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `${getName(response)} has been successfully updated`,
      type: UPDATE,
    })
  );
}
