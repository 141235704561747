import PropTypes from 'prop-types';
import selector from 'selectors/eBillUser';
import { ButtonWithCircularProgress } from 'components/common';
import { Grid } from '@material-ui/core';
import { isLoadingState as disabledSelector } from 'reducers/loading';
import { refresh, REFRESH } from 'model/eBillEnrollment';
import { selector as busySelector } from 'selectors/busy';
import { useDispatch, useSelector } from 'react-redux';
import { withPaymentOps } from 'components/common/permissions';

const notifyType = 'newStatementDownloaded';

const RefreshStatementButton = ({ ebillEnrollAt, fspId, payerId, id }) => {
  const busy = useSelector((_) => busySelector(_, REFRESH));
  const disabled = useSelector((_) => disabledSelector(_, REFRESH.ACTION));
  const dispatch = useDispatch();
  const eBillUser = useSelector((state) => selector(state, id));

  const handleClick = (event) => {
    const { ebillerTrackingToken, netUserIdStr, netUserAccountIdStr } =
      eBillUser;

    event.stopPropagation();
    dispatch(
      refresh({
        ebillerTrackingToken,
        fspId,
        payerId,
        netUserAccountIdStr,
        netUserIdStr,
        notifyType,
      })
    );
  };

  return ebillEnrollAt ? (
    <Grid item>
      <ButtonWithCircularProgress
        busy={busy}
        disabled={disabled || !eBillUser.ebillerTrackingToken}
        onClick={handleClick}
        variant="outlined"
      >
        Refresh Statement
      </ButtonWithCircularProgress>
    </Grid>
  ) : null;
};

RefreshStatementButton.propTypes = {
  ebillEnrollAt: PropTypes.string,
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  id: PropTypes.number,
};

RefreshStatementButton.defaultProps = {
  ebillEnrollAt: null,
  id: null,
};

export default withPaymentOps(RefreshStatementButton);
