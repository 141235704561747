import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { compose, withProps } from 'recompose';
import { connect, shallowEqual, useSelector } from 'react-redux';
import { deletedOnlyField, pageConnect, pageHandlers } from 'util/page';
import { toOnOff } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';
import { OFF, ON } from 'consts';
import { ActionsRenderer, columnMeta, detailsRender } from './config';
import { defaultPageSettings, settingType, settingTypeDirect } from './consts';

const useStyles = makeStyles((theme) => ({
  list: {
    color: theme.palette.text.primary,
    minHeight: 270,
    padding: 0,
    width: '100%',
  },
}));

const ContactsList = ({
  actionTypeObject,
  fspId,
  handleChange,
  handleSearch,
  order,
  orderBy,
  page,
  payerId,
  renderOptions,
  rowsPerPage,
  selector,
  settingType,
  showDeleted,
}) => {
  const classes = useStyles();
  const items = useSelector((state) => selector(state, payerId), shallowEqual);

  useEffect(() => {
    handleSearch(showDeleted ? deletedOnlyField : []);
  }, [fspId, handleSearch, showDeleted]);

  return (
    <ExpansionList
      actionConstant={actionTypeObject}
      ActionsRenderer={ActionsRenderer}
      className={classes.list}
      columnMeta={columnMeta}
      data={items}
      detailsRender={detailsRender}
      emptyTitle="No Contacts Found"
      name={settingType}
      onChange={handleChange}
      order={order}
      orderBy={orderBy}
      page={page}
      renderOptions={renderOptions}
      rowsPerPage={rowsPerPage}
    />
  );
};

ContactsList.propTypes = {
  actionTypeObject: PropTypes.object.isRequired,
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  renderOptions: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  settingType: PropTypes.oneOf([settingType, settingTypeDirect]).isRequired,
  selector: PropTypes.func.isRequired,
  showDeleted: PropTypes.bool,
};

ContactsList.defaultProps = {
  showDeleted: false,
};

// This type of mapDispatchToProps function allows me to get the
// load method from props and also late bind to any props that
// are than passed later
const mapDispatchToPropsAndBind = (dispatch, { action }) => ({
  dispatch,
  ...bindActionCreators({ loadAction: action }, dispatch),
});

export default compose(
  connect(null, mapDispatchToPropsAndBind),
  withProps(
    ({
      actionTypeObject,
      fspId,
      loadAction,
      payerId,
      renderOptionsType,
      settingType,
      showDeleted,
    }) => ({
      actionType: actionTypeObject.ACTION,
      defaultPageSettings,
      loadAction,
      loadParams: {
        deletedOnly: toOnOff(showDeleted),
        fspId,
        p2pDirectOffOn: settingType === settingTypeDirect ? ON : OFF,
        payerId,
      },
      noSearch: true,
      renderOptions: { fspId, type: renderOptionsType, payerId },
    })
  ),
  pageConnect,
  pageHandlers
)(ContactsList);
