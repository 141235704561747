import { HTTP_STATUS_CODE_426 } from 'consts';
import { checkVersionEveryHour } from 'reducers/authToken';
import { getAuth } from 'util/index';
import { selector } from 'reducers/errorMessages';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const useErrorEffects = () => {
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const message = useSelector(selector);

  useEffect(() => {
    if (!auth?.isAuthenticated && message?.number === HTTP_STATUS_CODE_426) {
      window.location.reload();
    }
  }, [auth, message?.number]);

  useEffect(() => {
    dispatch(checkVersionEveryHour());
  }, [dispatch]);

  return [
    Object.keys(message).length !== 0,
    message,
    message?.number === HTTP_STATUS_CODE_426,
  ];
};

export default useErrorEffects;
