import { client, formatQueryParams } from 'util/sdk';

// eslint-disable-next-line import/prefer-default-export
export const load = (params) =>
  client.billerAddresses.query(
    formatQueryParams({
      ...params,
      orderByStreet1: 'asc',
    })
  );
