import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { convertToDays } from '../util';

const selectorUserId = createSelector(
  orm,
  (state, { userId }) => userId,
  ({ PayerUserAudit }, userId) =>
    PayerUserAudit.filter(
      (payerUser) => payerUser?.userId === userId
    ).toModelArray()
);

export const selectorUserIdCount = createReselectSelector(
  selectorUserId,
  (items) => items.length
);

export const selectorUserIdGroupedByDays = createReselectSelector(
  selectorUserId,
  (items) => items.reduce(convertToDays, [])
);
