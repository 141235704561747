import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ENTER_DELAY } from 'sagas';
import { ExpandMore } from '@material-ui/icons';
import { IconButton, Popover, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const anchorOrigin = { horizontal: 'right', vertical: 'center' };
const transformOrigin = { vertical: 'center', horizontal: 'left' };

const useStyles = makeStyles((theme) => {
  const transition = {
    duration: theme.transitions.duration.standard,
  };

  return {
    button: {
      position: 'relative',
    },
    opened: {
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', transition),
    },
    closed: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', transition),
    },
  };
});

const MorePopover = ({ children, tooltipProps }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(!open);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(!open);

    if (tooltipOpen) {
      setTooltipOpen(false);
    }
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        disableFocusListener
        enterDelay={ENTER_DELAY}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={tooltipOpen}
        {...tooltipProps}
      >
        <IconButton
          aria-label={tooltipProps.title}
          className={classes.button}
          data-testid="button"
          onClick={handleClick}
        >
          <ExpandMore
            className={classNames({
              [classes.closed]: !open,
              [classes.opened]: open,
            })}
          />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
      >
        {children}
      </Popover>
    </>
  );
};

MorePopover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  tooltipProps: PropTypes.object,
};

MorePopover.defaultProps = {
  children: null,
  tooltipProps: { title: '' },
};

export default MorePopover;
