import PropTypes from 'prop-types';
import { ENTER_DELAY } from 'sagas';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withPayerNotesAccess } from 'components/common/permissions';
import ButtonPopover from './ButtonPopover';

const anchorOrigin = { horizontal: 'center', vertical: 'top' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

const useStyles = makeStyles((theme) => ({
  fab: {
    bottom: theme.spacing(4),
    position: 'relative',
    left: `calc(100% - ${theme.spacing(10)}px)`,
  },
}));

const AddNote = ({ onAdd }) => {
  const classes = useStyles();
  return (
    <Tooltip
      enterDelay={ENTER_DELAY}
      title="Internal Note Only. A notification will not be sent to Payrailz Support."
    >
      <ButtonPopover
        anchorOrigin={anchorOrigin}
        aria-label="Add Note"
        className={classes.fab}
        onClick={onAdd}
        title="Notes"
        transformOrigin={transformOrigin}
        variant="outlined"
      />
    </Tooltip>
  );
};

AddNote.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default withPayerNotesAccess(AddNote);
