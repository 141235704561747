import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import DetailSummary from './DetailSummary';

const DetailAccount = ({ eBillAccount, isCreditCardAccountType, title }) => {
  const { dueOn, minimumAmount, amountDue } = eBillAccount;

  return (
    <>
      <Grid item xs={12}>
        <ReadOnlyFormControl label="Statement date" value={title} />
      </Grid>
      <DetailSummary
        amountDue={amountDue}
        dueOn={dueOn}
        isCreditCardAccountType={isCreditCardAccountType}
        minimumAmount={minimumAmount}
      />
    </>
  );
};

DetailAccount.propTypes = {
  eBillAccount: PropTypes.object.isRequired,
  isCreditCardAccountType: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default DetailAccount;
