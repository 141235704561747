import { SubmissionError } from 'redux-form';

export const buildInputProps = ({ id, inputProps }) => ({
  inputProps: {
    ...(inputProps || {}),
    ...(id && {
      'data-testid': id,
    }),
  },
});

export default (action, form) => (data, dispatch) => {
  try {
    dispatch(action(data, form));
  } catch (error) {
    throw new SubmissionError(error);
  }
};
