import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.secondary.dark,
    paddingBottom: theme.spacing(1),
  },
}));

const TextSubtitle = ({ subTitle }) => {
  const classes = useStyles();

  return subTitle ? (
    <Grid item>
      <Typography className={classes.subTitle} variant="body2">
        {subTitle}
      </Typography>
    </Grid>
  ) : null;
};

TextSubtitle.propTypes = {
  subTitle: PropTypes.string,
};

TextSubtitle.defaultProps = {
  subTitle: null,
};

export default TextSubtitle;
