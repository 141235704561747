import { useUserFspConfigSelector } from './selectors';
import { isOn } from '../util';
import usePayALoanOffOn from './usePayALoanOffOn';

const usePayALoanPayerOffOn = (fspId, payerId) => {
  const fspConfigUser = useUserFspConfigSelector(fspId || 0, payerId || 0);
  const isFspConfigUserPalOffOn = isOn(fspConfigUser?.palOffOn);
  const isPalOffOn = usePayALoanOffOn(fspId || 0);

  return isPalOffOn && isFspConfigUserPalOffOn;
};

export default usePayALoanPayerOffOn;
