const BEARER = 'Bearer';
const HEADER_AUTHORIZATION = 'Authorization';
const TOKEN_HEADER_KEY = '_token';
const TOKEN_STORAGE_KEY = 'pz_token';

export const clear = () => {
  window.sessionStorage.removeItem(TOKEN_STORAGE_KEY);
};

export const get = () => {
  return window.sessionStorage.getItem(TOKEN_STORAGE_KEY);
};

export const set = (headers) => {
  window.sessionStorage.setItem(TOKEN_STORAGE_KEY, headers[TOKEN_HEADER_KEY]);
};

export const getHeaderAuthorization = (requestToken) => {
  const token = requestToken || get();

  return token ? `${BEARER} ${token}` : null;
};

export const getSDKTokenHeader = () => ({
  headers: { [HEADER_AUTHORIZATION]: getHeaderAuthorization() },
});
