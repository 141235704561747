import { DESC } from 'consts';

export const columns = [
  {
    id: 'activeAtLabel',
    label: 'Offer Date',
    xs: 2,
  },
  {
    context: true,
    id: 'payeeName',
    label: 'Biller',
    xs: 4,
  },
  {
    id: 'totalSavingsLabel',
    label: 'Total Savings',
    xs: 4,
  },
  {
    id: 'statusLabel',
    label: 'Status',
    xs: 2,
  },
];

export const defaultOrderBy = 'created';

export const pageSettingsName = 'reduceMyBill';

export const defaultPageSettings = {
  order: DESC,
  orderBy: defaultOrderBy,
};
