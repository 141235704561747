import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { PAYER_USER_STATUS_INACTIVE, ROWS_PER_PAGE_MAX } from 'consts';
import { useFSPId } from 'hooks';
import { load } from 'model/payerUser';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selector as busySelector } from 'selectors/busy';
import { selectorId as payerSelectorId } from 'selectors/payer';
import { selectorPayerId } from 'selectors/payerUser';
import { isNilOrEmpty } from 'util/index';
import { isPayerActive } from 'util/payer';
import { makeStyles } from '@material-ui/core/styles';
import { PAYER_INACTIVE_MESSAGE } from '../consts';
import Menu from './Menu';
import { usePayerUserButton } from './PayerUserButtonContext';

const useStyles = makeStyles({
  text: {
    whiteSpace: 'nowrap',
  },
});

const PayerUserButton = ({
  action,
  fspId: fspIdProp,
  onClick,
  payerId,
  payerUser,
  selector,
  startIcon,
  submenu,
  text,
  transactAs,
}) => {
  const [anchorElLocal, setAnchorElLocal] = useState(null);
  const [disabledLocal, setDisabledLocal] = useState(false);
  const [userIdClicked, setUserIdClicked] = useState(0);
  const [isBusyAndUserIdClicked, setIsBusyAndUserIdClicked] = useState(false);
  const [target, setTarget] = useState();
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, action));
  const fspId = useFSPId(fspIdProp);
  const payerUsers = useSelector((state) => selectorPayerId(state, payerId));
  const payer = useSelector((state) => payerSelectorId(state, payerId));
  const possibleContext = usePayerUserButton();
  const item = useSelector((state) => selector(state, payerId));
  const active = isPayerActive(payer);

  const { anchorEl, disabled, setAnchorEl, setDisabled } = possibleContext;
  const anchorElToUse = anchorEl || anchorElLocal;
  const disabledToUse = disabled || disabledLocal;
  const icon =
    isNilOrEmpty(item) && isBusy && isBusyAndUserIdClicked ? (
      <CircularProgress size={20} thickness={5} />
    ) : (
      startIcon
    );
  const setAnchorElToUse = setAnchorEl || setAnchorElLocal;
  const setDisabledToUse = setDisabled || setDisabledLocal;
  const tooltipTitle = transactAs && !active ? PAYER_INACTIVE_MESSAGE : '';
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();

    if (isNilOrEmpty(item)) {
      setDisabledToUse(true);
    }

    if (payerUser) {
      if (payerUser.status === PAYER_USER_STATUS_INACTIVE) {
        setDisabledToUse(true);
      } else {
        setUserIdClicked(payerUser.userId);
        onClick(event, payerUser);
      }
    } else {
      setTarget(event.currentTarget);
      dispatch(load({ fspId, payerId, limit: ROWS_PER_PAGE_MAX }));
    }
  };
  const handleClose = useCallback(() => {
    setAnchorElToUse(null);
    setDisabledToUse(false);
  }, [setAnchorElToUse, setDisabledToUse]);

  useEffect(() => {
    if (payerUsers.length && !anchorElToUse && target) {
      setAnchorElToUse(target);
      setTarget(null);
    }
  }, [anchorElToUse, payerUsers, setAnchorElToUse, target]);

  useEffect(() => {
    const idsMatch = payerUser?.userId === userIdClicked;

    setIsBusyAndUserIdClicked(isBusy && idsMatch);

    return () => {
      if (isBusy && idsMatch) {
        setDisabledToUse(false);
        setUserIdClicked(0);
      }
    };
  }, [isBusy, payerUser, setDisabledToUse, userIdClicked]);

  useEffect(() => {
    if (item?.success) {
      handleClose();
    }
  }, [handleClose, item]);

  useEffect(() => {
    if (payerUser && payerUser.status === PAYER_USER_STATUS_INACTIVE) {
      setDisabledToUse(true);
    } else {
      setDisabledToUse(transactAs && !active);
    }
  }, [active, payerUser, setDisabledToUse, transactAs]);

  return (
    <>
      <Tooltip arrow title={tooltipTitle}>
        <span>
          <Button
            className={classes.text}
            disabled={disabledToUse}
            onClick={handleClick}
            role="button"
            startIcon={icon}
            variant="outlined"
          >
            {text}
          </Button>
        </span>
      </Tooltip>
      <Menu
        action={action}
        anchorEl={anchorElToUse}
        onClick={onClick}
        onClose={handleClose}
        open={Boolean(anchorElToUse)}
        payerId={payerId}
        selector={selector}
        submenu={submenu}
        transactAs={transactAs}
      />
    </>
  );
};

PayerUserButton.propTypes = {
  action: PropTypes.object.isRequired,
  fspId: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  payerId: PropTypes.number.isRequired,
  payerUser: PropTypes.object,
  selector: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  submenu: PropTypes.bool,
  text: PropTypes.string.isRequired,
  transactAs: PropTypes.bool,
};

PayerUserButton.defaultProps = {
  fspId: undefined,
  payerUser: null,
  startIcon: <Launch fontSize="small" />,
  submenu: false,
  transactAs: false,
};

export default PayerUserButton;
