import { client } from 'util/sdk';
import { pick } from 'ramda';
import { GET, POST } from 'util/api';
import {
  CORE_PROCESSOR_ID,
  FSPS,
  FSP_CORE_CONFIGS_CODE,
  FSP_CORE_CONFIG_CODE,
  PCE_CONFIG,
  PCE_OFF_ON,
} from 'consts';

const keys = [CORE_PROCESSOR_ID, PCE_OFF_ON, PCE_CONFIG];
const returnModel = { name: FSP_CORE_CONFIG_CODE };

const loadURL = (fspId) => `/${FSPS}/${fspId}/${FSP_CORE_CONFIGS_CODE}`;
const updateURL = (fspId, id) => `${loadURL(fspId)}/${id}`;

export const load = (fspId) =>
  client.executeRequest(GET, loadURL(fspId), { returnModel });

export const update = ({ fspId, id, ...data }, auth) =>
  client.executeRequest(POST, updateURL(fspId, id), {
    data: pick(keys, data),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
