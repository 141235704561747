import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { dropWhile, pluck } from 'ramda';
import { isLoadingState } from 'reducers/loading';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { selector as pageSettingsSelector } from 'selectors/pageSetting';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { update as updatePageSettings } from 'model/pageSetting';
import { useCallback, useEffect, useRef, useState } from 'react';
import EndAdornment from './EndAdornment';

const withoutSearchParamFields = (searchBys, searchParams) => {
  return dropWhile(
    ({ field }) => pluck('field', searchParams).includes(field),
    searchBys
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  inputRoot: {
    minHeight: 'auto',
  },
}));

const Single = ({
  action,
  pageSettingsId,
  pageSettingsName,
  searchParams,
  ...props
}) => {
  const [param, setParam] = useState(searchParams[0]);
  const inputRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) =>
    isLoadingState(state, action?.ACTION)
  );
  const { searchBys } =
    useSelector(
      (state) => pageSettingsSelector(state, 0, pageSettingsName),
      shallowEqual
    ) || {};

  const pageSettingsUpdate = useCallback(
    (settings) => {
      const data = {
        page: 0,
        payerId: pageSettingsId,
        type: pageSettingsName,
        ...settings,
      };

      dispatch(updatePageSettings(data));
    },
    [dispatch, pageSettingsId, pageSettingsName]
  );

  const focus = () => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  };
  const handleChange = (event) => {
    const text = event.target.value;
    setParam({ ...param, value: text });
  };
  const handleClear = () => {
    pageSettingsUpdate({
      searchBys: [
        { ...param, value: '' },
        ...withoutSearchParamFields(searchBys, searchParams),
      ],
    });
  };
  const handleParamChange = (param) => {
    // Need to use previous state so we don't lose the value
    setParam((prevParam) => ({ ...prevParam, ...param }));

    focus();
  };
  const handleKeyPress = (event) => {
    if (event.which === 13) {
      pageSettingsUpdate({
        searchBys: [
          param,
          ...withoutSearchParamFields(searchBys, searchParams),
        ],
      });
    }
  };
  const handleSelectClose = () => {
    focus();
  };

  useEffect(() => {
    if (searchBys?.length) {
      // We need to find the right param from the searchBys list as
      // it can contain other search params from different search fields
      const param = searchBys.find(({ field }) =>
        searchParams.some(({ field: possibleField }) => field === possibleField)
      );

      if (param) {
        setParam(param);
      }
    }
  }, [searchBys, searchParams]);

  return (
    <TextField
      autoFocus
      classes={{ root: classes.inputRoot }}
      disabled={isLoading}
      InputProps={{
        endAdornment: (
          <EndAdornment
            onChange={handleParamChange}
            onClear={handleClear}
            onClose={handleSelectClose}
            param={param}
            searchParams={searchParams}
          />
        ),
        startAdornment: <Search className={classes.icon} />,
      }}
      inputRef={inputRef}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      value={param?.value}
      {...props}
    />
  );
};

Single.propTypes = {
  action: PropTypes.object,
  pageSettingsId: PropTypes.number,
  pageSettingsName: PropTypes.string.isRequired,
  searchParams: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Single.defaultProps = {
  action: { ACTION: '' },
  pageSettingsId: 0,
};

export default Single;
