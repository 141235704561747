import PropTypes from 'prop-types';
import { useState } from 'react';
import ResizableDrawer from 'components/common/ResizableDrawer';
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { selectorId } from 'selectors/serviceNote';
import { useSelector, shallowEqual } from 'react-redux';
import { RegularNoteType } from 'components/common/Note/consts';
import NoteWriter from './NoteWriter';
import ButtonPopover from './ButtonPopover';

const anchorOrigin = { horizontal: 'center', vertical: 'top' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

const useStyles = makeStyles((theme) => ({
  appBarPrimaryOverrideColor: {
    backgroundColor: theme.palette.error.light,
  },
  drawer: {
    height: '50%',
    width: '60%',
    margin: '100px',
    marginTop: '100px',
    marginBottom: '200px',
    border: '2px solid lightgrey',
  },
  flex: {
    flex: '1 1 auto',
  },
  noNotesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
}));

const NoteDrawer = ({ fspId, onClose, open }) => {
  const item = useSelector((state) => selectorId(state), shallowEqual);
  const [noteType, setNoteType] = useState(item?.noteType);
  const classes = useStyles();

  const handleCancel = (event) => {
    event.stopPropagation();
    onClose(event, false);
  };

  const handleChangeType = (value) => {
    setNoteType(value);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    onClose(event, true);
  };

  const isRegularNote = noteType === RegularNoteType;
  const appBarColor = isRegularNote ? 'default' : 'primary';
  const title = `Notes`;
  const [openNoteWriter, setOpenNoteWriter] = useState(false);

  const onAdd = (event) => {
    event.stopPropagation();
    setOpenNoteWriter(true);
  };

  return (
    <ResizableDrawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      open={open}
      variant="persistent"
    >
      {open && (
        <>
          <AppBar
            classes={{
              colorPrimary: `${classes.whiteBackground}`,
            }}
            color="inherit"
            position="static"
          >
            <Toolbar>
              <Typography className={classes.flex} color="inherit" variant="h6">
                <span className={classes.whiteText}>{title}</span>
              </Typography>
              <div className={classes.buttonContainer}>
                <div className={classes.buttonContainer}>
                  <ButtonPopover
                    anchorOrigin={anchorOrigin}
                    aria-label="Cancel"
                    className={`${classes.fab}`}
                    onClick={handleCancel}
                    title="Cancel"
                    transformOrigin={transformOrigin}
                    variant="outlined"
                    style={{
                      backgroundColor: 'white',
                      color: 'purple',
                      marginRight: '10px',
                      top: '-10px',
                    }}
                  />
                  <ButtonPopover
                    anchorOrigin={anchorOrigin}
                    aria-label="Add Note"
                    className={`${classes.fab} ${classes.purpleButton}`}
                    onClick={onAdd}
                    title="Add Note"
                    transformOrigin={transformOrigin}
                    variant="outlined"
                    style={{
                      backgroundColor: 'purple',
                      color: 'white',
                      top: '-10px',
                    }}
                  />
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.noNotesContainer}>
            <Typography variant="h6"> No Notes </Typography>
          </div>
          <NoteWriter
            fspId={fspId}
            onClose={() => setOpenNoteWriter(false)}
            open={openNoteWriter}
          />
        </>
      )}
    </ResizableDrawer>
  );
};

NoteDrawer.propTypes = {
  fspId: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

NoteDrawer.defaultProps = {
  fspId: null,
  onClose: always,
  open: false,
};

export default NoteDrawer;
