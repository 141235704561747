import LinearProgress from '@material-ui/core/LinearProgress';
import { LOAD_ID } from 'model/payer';
import { LOAD_USER_FSP_CONFIG } from 'model/userFspConfig';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    height: theme.spacing(0.5),
  },
  progress: {
    width: '100%',
  },
}));

const busySelector = (state) => selector(state, LOAD_ID);
const busyConfigSelector = (state) => selector(state, LOAD_USER_FSP_CONFIG);

const PayerDetailSectionsProgress = () => {
  const classes = useStyles();
  const isBusy = useSelector(busySelector);
  const isBusyConfig = useSelector(busyConfigSelector);

  return isBusy || isBusyConfig ? (
    <LinearProgress className={classes.progress} />
  ) : (
    <div className={classes.placeholder} />
  );
};

export default PayerDetailSectionsProgress;
