import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { NA_VALUE } from 'consts';
import {
  TextFieldCurrency,
  TextFieldCurrencyReadOnly,
} from 'components/common/fields';
import { TableCell, TextField, makeStyles } from '@material-ui/core';
import { isNil } from 'ramda';
import { updateCustom } from 'model/limitRule';
import { useDispatch } from 'react-redux';
import { useLimits } from '../LimitsContext';

const useStyles = makeStyles((theme) => ({
  inputReadOnly: {
    color: theme.palette.action.active,
    direction: 'rtl',
  },
  root: {
    minHeight: 'auto',
  },
}));

const LimitsTableEditCell = ({
  edit,
  onChange,
  payerId,
  product,
  value,
  ...props
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const rootClass = useMemo(() => ({ root: classes.root }), [classes]);
  const InputProps = useMemo(
    () => ({
      classes: { input: classes.inputReadOnly },
      disableUnderline: true,
    }),
    [classes]
  );
  const { limitRule } = useLimits();

  const handleChange = (value, id) => {
    switch (id) {
      case 'max':
        limitRule.maximumTransaction[product] = value;
        break;

      case 'min':
        limitRule.minimumTransaction[product] = value;
        break;

      case 'daily':
        limitRule.dailyLimits[product] = value;
        break;

      case 'weekly':
        limitRule.weeklyLimits[product] = value;
        break;

      case 'monthly':
        limitRule.monthlyLimits[product] = value;
        break;

      default:
        break;
    }

    if (value) {
      dispatch(updateCustom(limitRule));
      onChange(value, id);
    }
  };

  const handleError = useCallback(
    (event, id) => {
      dispatch(
        updateCustom({
          id: limitRule.id,
          fieldError: { field: id, product, invalid: true },
        })
      );
    },
    [dispatch, limitRule.id, product]
  );

  const handleErrorClear = useCallback(
    (event, id) => {
      dispatch(
        updateCustom({
          id: limitRule.id,
          fieldError: { field: id, product, invalid: false },
        })
      );
    },
    [dispatch, limitRule.id, product]
  );

  return (
    <TableCell align="right">
      {!isNil(value) ? (
        edit ? (
          <TextFieldCurrency
            {...props}
            autoComplete="off"
            classes={rootClass}
            fullWidth
            mini
            onChange={handleChange}
            onError={handleError}
            onErrorClear={handleErrorClear}
            value={value}
          />
        ) : (
          <TextFieldCurrencyReadOnly
            {...props}
            classes={rootClass}
            fullWidth
            value={value}
          />
        )
      ) : (
        <TextField
          {...props}
          classes={rootClass}
          disabled
          fullWidth
          InputProps={InputProps}
          value={NA_VALUE}
        />
      )}
    </TableCell>
  );
};

LimitsTableEditCell.propTypes = {
  edit: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  payerId: PropTypes.number.isRequired,
  product: PropTypes.string.isRequired,
  value: PropTypes.number,
};

LimitsTableEditCell.defaultProps = {
  value: null,
};

export default memo(LimitsTableEditCell);
