import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Rtn extends Model {
  static get modelName() {
    return 'Rtn';
  }

  static reducer({ type, data }, Rtn) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        Rtn.create(data?.response);
        break;

      case LOAD.SUCCESS:
      case IMPORT.SUCCESS:
        if (data?.response) {
          Rtn.delete();
          data.response.forEach((r) => Rtn.create(r));
        }
        break;
      case LOAD_FRACTIONAL_NUMBER.SUCCESS:
        {
          const { fractionalRtn } = data.response;
          Rtn.upsert({
            id,
            fractRtn: fractionalRtn,
            generatedFractRtn: fractionalRtn,
          });
        }
        break;
      case REMOVE.SUCCESS:
        if (Rtn.idExists(id)) {
          Rtn.withId(id).delete();
        }
        break;

      case REMOVE_FRACTIONAL_NUMBER.SUCCESS:
        if (Rtn.idExists(id)) {
          Rtn.withId(id).update({
            generatedFractRtn: undefined,
          });
        }
        break;

      case UPDATE.SUCCESS:
        Rtn.upsert(data.response);
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      achRtn: attr(),
      createdAt: attr(),
      fractRtn: attr(),
      fractRtnValidation: attr(),
      fspId: attr(),
      generatedFractRtn: attr(), // derived
      href: attr(),
      id: attr(),
      insUserId: attr(),
      micrRtn: attr(),
      offset: attr(),
      updUserId: attr(),
      updatedAt: attr(),
      wireRtn: attr(),
    };
  }
}

const name = Rtn.modelName;

export const ADD = createAddConst(name);
export const IMPORT = createImportConst(name);
export const LOAD = createLoadConst(name);
export const LOAD_FRACTIONAL_NUMBER = createRequestConst(
  `${name}_fractional_number`
);
export const REMOVE_FRACTIONAL_NUMBER = createRequestConst(
  `${name}_remove_fractional_number`
);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const importAction = createAction(IMPORT);
export const load = createAction(LOAD);
export const loadFractionalNumber = createAction(LOAD_FRACTIONAL_NUMBER);
export const removeFractionalNumber = createAction(REMOVE_FRACTIONAL_NUMBER);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
