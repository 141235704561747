import { useIsBusy } from 'hooks';

const useBusy = (actionConst) => {
  let action0 = actionConst;
  let action1 = null;

  if (Array.isArray(actionConst)) {
    action0 = actionConst[0];
    action1 = actionConst[1];
  }

  const busy0 = useIsBusy(action0);
  const busy1 = useIsBusy(action1);

  return [busy0, busy1];
};

export default useBusy;
