import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import useIsBusy from 'hooks/useIsBusy';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isLoadingState } from 'reducers/loading';
import { selectorId } from 'selectors/payer';
import { isNilOrEmpty } from 'util/index';
import { isPayerActive } from 'util/payer';
import { PAYER_INACTIVE_MESSAGE } from '../consts';

const useStyles = makeStyles({
  text: {
    whiteSpace: 'nowrap',
  },
});

const PayerButton = ({
  action,
  onClick,
  payerId,
  selector,
  startIcon,
  text,
  transactAs,
  ...props
}) => {
  const classes = useStyles();
  const payer = useSelector((state) => selectorId(state, payerId)) ?? {};
  const isBusy = useIsBusy(action);
  const isLoading = useSelector((state) =>
    isLoadingState(state, action.ACTION)
  );
  const item = useSelector((state) => selector(state, payerId));

  const active = isPayerActive(payer);
  const disabled = isNilOrEmpty(item) && (isLoading || (transactAs && !active));
  const icon =
    isNilOrEmpty(item) && isBusy ? (
      <CircularProgress size={20} thickness={5} />
    ) : (
      startIcon
    );

  const handleClick = (event) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <Tooltip arrow title={transactAs && !active ? PAYER_INACTIVE_MESSAGE : ''}>
      <span>
        <Button
          className={classes.text}
          disabled={disabled}
          onClick={handleClick}
          role="button"
          startIcon={icon}
          variant="outlined"
          {...props}
        >
          {text}
        </Button>
      </span>
    </Tooltip>
  );
};

PayerButton.propTypes = {
  action: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  payerId: PropTypes.number,
  selector: PropTypes.func.isRequired,
  startIcon: PropTypes.element,
  text: PropTypes.string.isRequired,
  transactAs: PropTypes.bool,
};

PayerButton.defaultProps = {
  payerId: 0,
  startIcon: <Launch fontSize="small" />,
  transactAs: false,
};

export default PayerButton;
