import PropTypes from 'prop-types';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import { ON } from 'consts';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';

const useStyles = makeStyles(() => ({
  customRule: {
    minWidth: 300,
  },
}));

const AutopayCustomRule = ({
  billGreaterThanAmountDerived,
  billGreaterThanCustomAmountDerived,
  billGreaterThanNotPayOffOn,
}) => {
  const classes = useStyles();

  return (
    <ReadOnlyFormControl
      inputNode={
        billGreaterThanAmountDerived ? (
          <ReadOnlyInputWithMenu
            label={billGreaterThanAmountDerived ? 'Yes' : 'No'}
            menuNode={
              <Card className={classes.customRule}>
                <CardContent>
                  <ReadOnlyFormControl
                    label={`When Payment Amount over ${billGreaterThanAmountDerived}`}
                    value={
                      billGreaterThanNotPayOffOn === ON
                        ? 'Send Notification'
                        : `Pay custom amount of ${billGreaterThanCustomAmountDerived}`
                    }
                  />
                </CardContent>
              </Card>
            }
          />
        ) : null
      }
      label="Custom Rule"
      value={billGreaterThanAmountDerived ? 'Yes' : 'No'}
    />
  );
};

AutopayCustomRule.propTypes = {
  billGreaterThanAmountDerived: PropTypes.string,
  billGreaterThanCustomAmountDerived: PropTypes.string,
  billGreaterThanNotPayOffOn: PropTypes.string,
};

AutopayCustomRule.defaultProps = {
  billGreaterThanAmountDerived: '',
  billGreaterThanCustomAmountDerived: '',
  billGreaterThanNotPayOffOn: '',
};

export default AutopayCustomRule;
