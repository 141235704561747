import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { selector } from 'selectors/request';
import { useDispatch, useSelector } from 'react-redux';
import { useParamId } from 'hooks';
import AddButton from './AddButton';

const Add = ({ action, actionType, FormikForm, initialValues, ...props }) => {
  const [completed, failed] = useSelector((state) =>
    selector(state, actionType)
  );
  const dispatch = useDispatch();
  const fspId = useParamId();
  const resetFormRef = useRef();
  const setSubmittingRef = useRef();

  const handleSubmit = useCallback(
    (values, formik) => {
      resetFormRef.current = formik.resetForm;
      setSubmittingRef.current = formik.setSubmitting;
      dispatch(action({ fspId, ...values }));
    },
    [action, dispatch, fspId]
  );

  useEffect(() => {
    if (completed && resetFormRef.current && setSubmittingRef.current) {
      resetFormRef.current();
      setSubmittingRef.current(false);
      resetFormRef.current = null;
      setSubmittingRef.current = null;
    }

    if (failed && setSubmittingRef.current) {
      setSubmittingRef.current(false);
      setSubmittingRef.current = null;
    }
  }, [completed, failed]);

  return (
    <FormikForm initialValues={initialValues} onSubmit={handleSubmit}>
      <AddButton actionType={actionType} {...props} />
    </FormikForm>
  );
};

Add.propTypes = {
  action: PropTypes.func.isRequired,
  actionType: PropTypes.object.isRequired,
  FormikForm: PropTypes.elementType.isRequired,
  initialValues: PropTypes.object,
};

Add.defaultProps = {
  initialValues: undefined,
};

export default Add;
