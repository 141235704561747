import { DISTRIBUTOR_ID, DISTRIBUTOR_NAME_CODE } from 'consts';
import { load, throttledLoad, LOAD, THROTTLED_LOAD } from 'model/distributor';
import { selector } from 'selectors/distributor';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useIsLoading } from 'hooks';
import {
  INIT_LOAD_PARAMS,
  NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ,
} from '../consts';

const useTypeAhead = ({ value: filterParam }) => {
  const dispatch = useDispatch();
  const loading = useIsLoading(LOAD);
  const loadingThrottled = useIsLoading(THROTTLED_LOAD);
  const options = useSelector(selector);

  const dispatchWithParams = useMemo(() => {
    return (value) => {
      if (value && value.length >= NUM_OF_ID_CHARS_REQ_BEFORE_API_REQ) {
        const params = { ...INIT_LOAD_PARAMS };

        if (filterParam === DISTRIBUTOR_ID && !Number.isNaN(Number(value))) {
          params.id = value;
        }

        if (filterParam === DISTRIBUTOR_NAME_CODE) {
          params.nameLike = `${value}%`;
        }

        dispatch(throttledLoad(params));
      }
    };
  }, [dispatch, filterParam]);

  useEffect(() => {
    dispatch(load(INIT_LOAD_PARAMS));
  }, [dispatch]);

  return [loading || loadingThrottled, options, dispatchWithParams];
};

export default useTypeAhead;
