import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  spacer: {
    height: 5,
  },
}));

const AuthenticationProgress = ({ show }) => {
  const classes = useStyles();

  return show ? <LinearProgress /> : <div className={classes.spacer} />;
};

AuthenticationProgress.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default AuthenticationProgress;
