import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import usePayALoanTransaction from '../../../../History/config/DetailsRender/usePayALoanTransaction';

const FeeAmount = ({ item }) => {
  return (
    usePayALoanTransaction(item.transactionType) && (
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Fee Amount"
          value={item?.feeAmountDerived}
        />
      </Grid>
    )
  );
};
FeeAmount.propTypes = {
  item: PropTypes.shape({
    feeAmountDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};
export default FeeAmount;
