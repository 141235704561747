import PropTypes from 'prop-types';
import { always, update } from 'ramda';
import InputRefType from 'types/inputRef';
import { CurrencyTextField } from 'components/common/fields';

const ParamValueAmount = ({
  disabled,
  inputRef,
  onChange,
  onKeyPress,
  value,
}) => {
  const createOnChangeHandler = (valueIndex) => (event) => {
    const amount = event.target.value;
    const newValue = update(valueIndex, amount, value);
    onChange({ target: { value: newValue } });
  };

  const handleFromOnChange = createOnChangeHandler(0);
  const handleToOnChange = createOnChangeHandler(1);

  return (
    <>
      <CurrencyTextField
        disabled={disabled}
        fullWidth
        inputRef={inputRef}
        label="Value / From"
        onChange={handleFromOnChange}
        onKeyPress={onKeyPress}
        value={value[0]}
      />
      <CurrencyTextField
        disabled={disabled}
        fullWidth
        inputRef={inputRef}
        label="To"
        onChange={handleToOnChange}
        onKeyPress={onKeyPress}
        value={value[1]}
      />
    </>
  );
};

ParamValueAmount.propTypes = {
  disabled: PropTypes.bool,
  inputRef: InputRefType,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

ParamValueAmount.defaultProps = {
  disabled: false,
  inputRef: undefined,
  onKeyPress: always,
  value: ['', ''],
};

export default ParamValueAmount;
