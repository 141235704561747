/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import StatusSelectionAction from 'components/common/status_selection_action';
import { UPDATE, update } from 'model/externalAccount';
import { always } from 'ramda';
import { selectorAsLabel } from 'selectors/payer';
import { useDispatch, useSelector } from 'react-redux';

const SUSPEND_MSG = (extras) => () => {
  return (
    <span>
      Any transactions scheduled for processing during the suspension period
      will be marked as <strong>not processed due to suspension</strong>.
      <br />
      Any scheduled transactions that fall outside the suspension period will be
      processed after the external account is re-activated.
      <br />
      All payees, recipients, and external accounts will remain.
      <br />
      Suspend {extras}
    </span>
  );
};

const DISABLE_MSG = (extras) => () => {
  return (
    <span>
      Disabling this external account will result in the account not being
      displayed to the Payer and removing access to transact against this
      account.
      <br />
      Any and all existing scheduled transactions against this external account
      will be cancelled.
      <br />
      Notifications will be sent to the Payer.
      <br />
      Disable {extras}
    </span>
  );
};

const ACTIVATE_SUSPENDED_MSG = (extras) => () => {
  return (
    <span>
      Any transactions previously scheduled for processing during the suspension
      period has been marked as <strong>not processed due to suspension</strong>
      .
      <br />
      Any remaining scheduled transactions will continue to process during the
      regular processing window.
      <br />
      All payees, recipients, and external accounts remain.
      <br />
      Activate {extras}
    </span>
  );
};

const ACTIVATE_DISABLED_MSG = (extras) => () => {
  return (
    <span>
      Activating will allow the payer to access and transact against the
      external account.
      <br />
      Activate {extras}
    </span>
  );
};

const MICROPENDING_MSG = (extras) => () => {
  return (
    <span>
      Resetting this external account to <strong>Pending Micro-Deposits</strong>{' '}
      status will resend the micro-deposits for the Payer to verify in order to
      activate this account.
      <br />
      Reset {extras}
    </span>
  );
};

const statusConfig = {
  active: {
    suspended: {
      message: SUSPEND_MSG,
      label: 'Suspend',
      requiredPermission: always(true),
    },
    inactive: {
      message: DISABLE_MSG,
      label: 'Disable',
      requiredPermission: always(true),
    },
  },
  suspended: {
    active: {
      message: ACTIVATE_SUSPENDED_MSG,
      label: 'Activate',
      requiredPermission: always(true),
    },
    inactive: {
      message: DISABLE_MSG,
      label: 'Disable',
      requiredPermission: always(true),
    },
  },
  inactive: {
    active: {
      message: ACTIVATE_DISABLED_MSG,
      label: 'Activate',
      requiredPermission: always(true),
    },
  },
  microPending: {},
  microPendingAmt: {},
  microACHFailed: {
    microPending: {
      message: MICROPENDING_MSG,
      label: 'Reset',
      requiredPermission: always(true),
    },
  },
  microAmtFailed: {},
  activateExpired: {},
};

const statusList = [
  {
    key: 'extDdaActive',
    name: 'Active',
    value: 'active',
  },
  {
    key: 'extDdaSuspended',
    name: 'Suspended',
    value: 'suspended',
  },
  {
    key: 'extDdaDisabled',
    name: 'Disabled',
    value: 'inactive',
  },
  {
    key: 'extDdaMicroPending',
    name: 'Micro Deposit Pending',
    value: 'microPending',
  },
  {
    key: 'extDdaMicroPendingAmt',
    name: 'Micro Deposit Pending',
    value: 'microPendingAmt',
  },
  {
    key: 'extDdaMicroACHFailed',
    name: 'MicroACH Failed',
    value: 'microACHFailed',
  },
  {
    key: 'extDdaMicroAMTFailed',
    name: 'Micro Deposit Failed',
    value: 'microAmtFailed',
  },
  {
    key: 'extDdaActivateExpired',
    name: 'Activate Expired',
    value: 'activateExpired',
  },
  {
    // Added 12/10/2019 https://payrailz.atlassian.net/browse/ON-526
    key: 'extDdaClosed',
    name: 'Closed',
    value: 'closed',
  },
  {
    // Added 12/10/2019 https://payrailz.atlassian.net/browse/ON-526
    key: 'extDdaRemoved',
    name: 'Removed',
    value: 'removed',
  },
  {
    // Added 12/11/2019 https://payrailz.atlassian.net/browse/ON-526
    key: 'extDdaDeleted',
    name: 'Deleted',
    value: 'deleted',
  },
];

const StatusSelector = ({ account, options, ...props }) => {
  const dispatch = useDispatch();
  const payerLabel = useSelector((state) =>
    selectorAsLabel(state, options?.payerId)
  );
  const nickNameOrDescription = account.nickName
    ? account.nickName
    : account.description
    ? account.descriptionDerived
    : '';

  const confirmMessageExtras = `${payerLabel}'s external account (${nickNameOrDescription} ****${account.accountNbrMask})?`;

  const handleChange = (status) => {
    dispatch(update({ account, options, status }));
  };

  return (
    <StatusSelectionAction
      {...props}
      actionType={UPDATE}
      confirmMessageExtras={confirmMessageExtras}
      currentStatus={account.externalDdaStatus}
      onChange={handleChange}
      statusConfig={statusConfig}
      statusList={statusList}
    />
  );
};

StatusSelector.propTypes = {
  account: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default StatusSelector;
