import { client, formatQueryParams } from 'util/sdk';

export const cancel = ({ fspId, ids, reason, requestFrom }, auth) =>
  client
    .fsps(fspId)
    .cancelFspScheduledTransaction(
      { fspschedtran: ids, cancelReason: reason, requestFrom },
      { adminUserId: auth.id }
    );

export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).fspScheduledTransactions.query(formatQueryParams(params));

export const review = ({ fspId, ids, requestFrom }, auth) =>
  client
    .fsps(fspId)
    .reviewFspScheduledTransaction(
      { fspschedtran: ids, requestFrom },
      { adminUserId: auth.id }
    );
