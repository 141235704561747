import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { durationHumanized } from '../util';

const orderedSelector =
  (orderBy = 'lastViewed') =>
  ({ RecentPayer }) =>
    RecentPayer.orderBy(orderBy, 'desc')
      .toRefArray()
      .map((item) => {
        item.lastViewedLabel = durationHumanized(item.lastViewed);

        return item;
      });

export const selector = createSelector(orm, orderedSelector('id'));

export const selectorLastViewed = createSelector(orm, orderedSelector());

export const selectorPayerId = createSelector(
  orm,
  (state, payerId) => payerId,
  ({ RecentPayer }, payerId) =>
    RecentPayer.filter(
      ({ payerId: recentPayerId }) => recentPayerId === payerId
    ).first()?.ref
);
