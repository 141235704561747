import { CREATE, LOAD, LOAD_ID, REMOVE, UPDATE } from 'model/billerSet';
import { create, load, loadById, remove, update } from 'sdk/billerSet';
import { put, takeEvery } from 'redux-saga/effects';
import { LOAD as LOAD_BILLERSET_ALIAS } from 'model/billerSetAlias';
import { callAction, ORM } from '../util';

function* updateAndRefreshAlias(action) {
  const loginBillerSet = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: ({ response }) =>
      `BillerSet "${response.name}" was successfully updated`,
    type: UPDATE,
  });
  const data = yield loginBillerSet(action);

  if (data) {
    yield put({
      type: LOAD_BILLERSET_ALIAS.ACTION,
      data: { billerSetId: data.props.id },
    });
  }
}

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `BillerSet "${response.name}"  was successfully deleted`,
      type: REMOVE,
    })
  );

  yield takeEvery(UPDATE.ACTION, updateAndRefreshAlias);
}
