import { takeEvery } from 'redux-saga/effects';
import { create, load, loadId, remove, update } from 'sdk/route';
import { ADD, FORM_NAME, LOAD, LOAD_ID, REMOVE, UPDATE } from 'model/route';
import { callAction } from '../util';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      type: ADD,
      api: create,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.name} Route has been successfully created`,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      type: LOAD,
      api: load,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      type: LOAD_ID,
      api: loadId,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      type: REMOVE,
      api: remove,
      formatSuccessMessage: (data) =>
        `${data.name} Route has been successfully removed`,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      type: UPDATE,
      api: update,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.name} Route has been successfully updated`,
    })
  );
}
