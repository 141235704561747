import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class EBillUser extends Model {
  static get modelName() {
    return 'EBillUser';
  }

  static reducer({ type, data }, EBillUser) {
    if (type === LOAD.SUCCESS && data.response) {
      EBillUser.upsert(data.response);
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      netUserIdStr: attr(),
    };
  }
}

export const LOAD = createLoadConst(EBillUser.modelName);

export const load = createAction(LOAD);
