import AuditDetailPaper from 'components/common/Audit//AuditDetailPaper';
import IdToDisplayName from 'components/common/Audit/config/shared/IdToDisplayName';
import PropTypes from 'prop-types';
import { ENTER_DELAY } from 'sagas';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { RETURNED } from 'consts';

const AuditDetailRowRecord = ({
  byAdminUserId,
  bySystemUser,
  byUserId,
  subheader,
  time,
  title,
  tooltip,
  transactionState,
}) => {
  const prefix = byAdminUserId ? 'admin' : byUserId ? 'user' : 'system';

  return (
    <AuditDetailPaper>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid item>
          <Tooltip enterDelay={ENTER_DELAY} title={tooltip}>
            <Grid container direction="column">
              <Grid item>
                {transactionState &&
                transactionState.toLowerCase() === RETURNED ? (
                  <Grid alignItems="center" container>
                    <Grid item>
                      <Typography variant="body2">
                        {title} by {prefix}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IdToDisplayName
                        adminId={byAdminUserId}
                        systemUser={bySystemUser}
                        userId={byUserId}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography variant="body2">{title}</Typography>
                  </Grid>
                )}
              </Grid>
              {subheader && (
                <Grid item>
                  <Typography variant="body1">{subheader}</Typography>
                </Grid>
              )}
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item>
          <Typography variant="body2">{time}</Typography>
        </Grid>
      </Grid>
    </AuditDetailPaper>
  );
};

AuditDetailRowRecord.propTypes = {
  byAdminUserId: PropTypes.number,
  bySystemUser: PropTypes.string,
  byUserId: PropTypes.number,
  subheader: PropTypes.string,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  transactionState: PropTypes.string,
};

AuditDetailRowRecord.defaultProps = {
  byAdminUserId: null,
  bySystemUser: null,
  byUserId: null,
  subheader: null,
  transactionState: null,
};

export default AuditDetailRowRecord;
