import { attr, Model } from 'redux-orm';
import { NA_VALUE, ON } from '../consts';
import {
  createAction,
  createLoadConst,
  dateAndTime,
  getPhoneForDisplay,
} from '../util';

export const derived = (r) => {
  const { userInfo } = r;
  const manualVerificationRequired = userInfo?.manualVerificationOffOn === ON;

  const reasonRequired = manualVerificationRequired
    ? userInfo?.manualVerificationReason || NA_VALUE
    : '';
  const manualVerificationLabel = userInfo
    ? manualVerificationRequired
      ? `Required: ${reasonRequired}`
      : 'Not Required'
    : null;

  userInfo.emailLastUpdatedAtLabel = dateAndTime(userInfo?.emailLastUpdatedAt);
  userInfo.manualVerificationLabel = manualVerificationLabel;
  userInfo.mobilePhoneLabel = getPhoneForDisplay(userInfo?.mobilePhone);
  userInfo.mobilePhoneLastUpdatedAtLabel = dateAndTime(
    userInfo?.mobilePhoneLastUpdatedAt
  );

  return r;
};

export default class OneTimePasscodeInfo extends Model {
  static get modelName() {
    return 'OneTimePasscodeInfo';
  }

  static reducer({ type, data }, OneTimePasscodeInfo) {
    switch (type) {
      case LOAD.ACTION:
        // Clear between actions, info can be different for each user
        OneTimePasscodeInfo.delete();
        break;

      case LOAD.SUCCESS:
        OneTimePasscodeInfo.create(derived(data.response));
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      adminContactPhone: attr(),
      manualVerificationOffOn: attr(),
      manualVerificationReason: attr(),
      otpInfo: attr(),
      otpRequiredOffOn: attr(),
      userInfo: attr(),
    };
  }
}

export const LOAD = createLoadConst(OneTimePasscodeInfo.modelName);

export const load = createAction(LOAD);
