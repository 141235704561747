import { createRequestConst, createAction } from 'util/actions';

const initialState = null;
const name = 'upload';

export const UPLOAD = createRequestConst(name);

export const upload = createAction(UPLOAD);

export const selector = (state) => state[name];

export default (state = initialState, { type, data }) => {
  switch (type) {
    case UPLOAD.ACTION: {
      return {
        img: data,
      };
    }

    default:
      return state;
  }
};
