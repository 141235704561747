import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';
import { addDerived } from './util/notification';

export default class Notification extends Model {
  static get modelName() {
    return 'Notification';
  }

  static reducer({ type, data }, Notification, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).notifications.delete();
        }

        if (data && Array.isArray(data.response)) {
          data.response.forEach((r) => Notification.upsert(addDerived(r)));
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      createdAtDerived: attr(),
      deliverMethod: attr(),
      deliverMethodDerived: attr(),
      deliverToDerived: attr(),
      descriptionDerived: attr(),
      eventName: attr(),
      expireAt: attr(),
      externalDdaId: attr(),
      forwardLink: attr(),
      fsp: attr(),
      fspId: attr(),
      histId: attr(),
      href: attr(),
      id: attr(),
      link: attr(),
      lockAt: attr(),
      msgStatus: attr(),
      msgStatusDerived: attr(),
      msgType: attr(),
      nextRemindOn: attr(),
      nextRetryAt: attr(),
      offset: attr(),
      payeeId: attr(),
      payerId: fk(Payer.modelName, 'notifications'),
      recipientId: attr(),
      renderedMsg: attr(),
      renderedMsgDecoded: attr(), // derived
      renderedSubject: attr(),
      respondedAt: attr(),
      responseNeeded: attr(),
      responsePendingAt: attr(),
      schedId: attr(),
      sendPendingAt: attr(),
      sentAt: attr(),
      toEmail: attr(),
      toName: attr(),
      toSms: attr(),
      updatedAt: attr(),
      userId: attr(),
    };
  }
}

export const LOAD = createRequestConst(Notification.modelName);
export const load = createAction(LOAD);
