import { attr, Model } from 'redux-orm';
import { getLogoSrc } from '../consts';
import {
  createAction,
  createLoadConst,
  createRequestConst,
  dateAndTime,
} from '../util';

const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);
  r.base64 = getLogoSrc(r.base64);

  return r;
};

export default class BillerLogoChooser extends Model {
  static get modelName() {
    return 'BillerLogoChooser';
  }

  static reducer({ type, data = [] }, BillerLogoChooser, { BillerLogo }) {
    switch (type) {
      case CHECK.ACTION: {
        BillerLogoChooser.filter(
          ({ id, selected }) => selected && data.id !== id
        )
          .toModelArray()
          .forEach((billerLogo) => {
            billerLogo.selected = !billerLogo.selected;
            billerLogo.update(billerLogo);
          });

        if (BillerLogoChooser.idExists(data.id)) {
          const billerLogo = BillerLogoChooser.withId(data.id);

          billerLogo.selected = !billerLogo.selected;
          billerLogo.update(billerLogo);
        }
        break;
      }

      case CLEAR_CHECK.ACTION:
        BillerLogoChooser.all()
          .toModelArray()
          .forEach((billerLogo) => {
            billerLogo.selected = false;
            billerLogo.update(billerLogo);
          });
        break;

      case CLEAR_DATA.ACTION:
      case LOAD.FAILURE:
        BillerLogoChooser.delete();
        break;

      case LOAD.SUCCESS:
        BillerLogoChooser.delete();
        data.response.forEach((r) => BillerLogoChooser.upsert(addDerived(r)));
        break;

      case MARK_CHECK_AS_ADD.ACTION: {
        let selectedBiller = null;

        BillerLogoChooser.all()
          .toModelArray()
          .forEach((billerLogo) => {
            if (billerLogo.selected) {
              selectedBiller = billerLogo.ref;
            }

            billerLogo.selected = false;
            billerLogo.update(billerLogo);
          });

        if (selectedBiller) {
          BillerLogo.all()
            .toModelArray()
            .forEach((billerLogo) => {
              billerLogo.selected = false;
              billerLogo.update(billerLogo);
            });
          BillerLogo.upsert(selectedBiller);
        }
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      base64: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      href: attr(),
      id: attr(),
      name: attr(),
      selected: attr(), // derived
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
    };
  }
}

export const CHECK = createRequestConst(`${BillerLogoChooser.modelName}_check`);
export const CLEAR_CHECK = createRequestConst(
  `${BillerLogoChooser.modelName}_clear_check`
);
export const CLEAR_DATA = createRequestConst(
  `${BillerLogoChooser.modelName}_clear_data`
);
export const LOAD = createLoadConst(BillerLogoChooser.modelName);
export const MARK_CHECK_AS_ADD = createRequestConst(
  `${BillerLogoChooser.modelName}_mark_check_as_add`
);

export const check = createAction(CHECK);
export const clearCheck = createAction(CLEAR_CHECK);
export const clearData = createAction(CLEAR_DATA);
export const load = createAction(LOAD);
export const markCheckAsAdd = createAction(MARK_CHECK_AS_ADD);
