import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  grid: {
    backgroundColor: '#F1F1F0',
    height: '100vh',
  },
  image: {
    objectFit: 'contain',
  },
}));

const ErrorBoundaryView = () => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classes.grid}
      container
      justifyContent="center"
    >
      <Grid item>
        <img
          alt="Oops - Something went wrong"
          className={classes.image}
          src="/error.gif"
        />
      </Grid>
    </Grid>
  );
};

export default ErrorBoundaryView;
