import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { isNil } from 'ramda';
import { TableCell, withStyles } from '@material-ui/core';

const styles = () => ({
  root: { width: 48 },
});

const Action = ({
  classes,
  component: Component,
  row,
  show,
  showComponent,
  tableCellClasses,
  ...restOfProps
}) => {
  return (
    <TableCell classes={tableCellClasses} padding="none">
      {showComponent && <Component row={row} {...restOfProps} />}
    </TableCell>
  );
};

Action.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.func.isRequired,
  row: PropTypes.object,
  show: PropTypes.func,
  showComponent: PropTypes.bool.isRequired,
  tableCellClasses: PropTypes.object.isRequired,
};

Action.defaultProps = {
  row: null,
  show: null,
};

export default compose(
  withStyles(styles),
  withProps(({ classes, row = null, show = null }) => ({
    showComponent: isNil(show) || show(row),
    tableCellClasses: { root: classes.root },
  }))
)(Action);
