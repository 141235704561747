import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import usePayALoanTransaction from '../../usePayALoanTransaction';

const TotalAmount = ({ transaction }) => {
  return (
    usePayALoanTransaction(transaction.transactionType) && (
      <Grid>
        <ReadOnlyFormControl
          label="Total Amount"
          value={transaction.totalAmountDerived}
        />
      </Grid>
    )
  );
};

TotalAmount.propTypes = {
  transaction: PropTypes.shape({
    totalAmountDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};

export default TotalAmount;
