import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.OneTimePasscode);

export const selectorById = createSelector(
  orm,
  (state, item) => item,
  ({ OneTimePasscode }, { id }) =>
    OneTimePasscode.filter((item) => item.id === id).toRefArray()
);

export const selectorByPayerId = createSelector(
  orm,
  (state, id) => id,
  ({ OneTimePasscode }, id) =>
    OneTimePasscode.filter(({ payerId }) => payerId === id).toRefArray()
);

export const selectorGeneratedByPayerId = createReselectSelector(
  selectorByPayerId,
  (items) => items.find(({ _last }) => _last)?.otp || ''
);

export const selectorCount = createSelector(orm, ({ OneTimePasscode }) =>
  OneTimePasscode.count()
);
