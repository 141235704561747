import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import emailMask from 'text-mask-addons/dist/emailMask';
import { branch, renderComponent } from 'recompose';
import { TextField } from '@material-ui/core';
import { buildInputProps } from 'util/form';
import ReadOnlyField from './ReadOnly';
import { placeholderChar } from './consts';

const RenderEmail = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      {...props}
      mask={emailMask}
      placeholderChar={placeholderChar}
    />
  );
};

RenderEmail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

RenderEmail.defaultProps = {
  inputRef: null,
};

const EmailField = ({ InputProps, ...props }) => {
  return (
    <TextField
      InputProps={{ ...InputProps, inputComponent: RenderEmail }}
      {...props}
      {...buildInputProps(props)}
    />
  );
};

EmailField.propTypes = {
  InputProps: PropTypes.object,
};

EmailField.defaultProps = {
  InputProps: null,
};

export default branch(
  ({ readOnly }) => readOnly,
  renderComponent(ReadOnlyField)
)(EmailField);
