import { defaultOrg } from 'consts';
import { selector as selectedOrgSelector } from 'reducers/selectedOrgId';
import { selector } from 'selectors/org';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useInitialOrgCount from './useInitialOrgCount';
import useLoadOrgsEffect from './useLoadOrgsEffect';
import useLoadSelectedEffect from './useLoadSelectedEffect';
import useDispatchSetOrg from '../../components/Layout/AppBar/Org/useDispatchSetOrg';

const useOrganizations = () => {
  const initialCount = useInitialOrgCount();
  const orgs = useSelector(selector);
  const selectedOrg = useSelector(selectedOrgSelector);
  const setOrg = useDispatchSetOrg();

  useLoadOrgsEffect();
  useLoadSelectedEffect();

  return useMemo(() => {
    if (initialCount) {
      const org = orgs.find(({ id }) => id === selectedOrg?.id);

      if (!org && orgs.length && !selectedOrg) {
        setOrg(orgs[0]);
        return [orgs[0], initialCount];
      }

      const fallbackOrg = selectedOrg ?? defaultOrg;
      return [org ?? fallbackOrg, initialCount];
    }

    return [selectedOrg ?? defaultOrg, initialCount];
  }, [initialCount, orgs, selectedOrg, setOrg]);
};

export default useOrganizations;
