import PropTypes from 'prop-types';
import { useCallback } from 'react';
import Table from 'components/common/table';
import withPagingSupport from 'util/page';
import { Card, makeStyles } from '@material-ui/core';
import { compose, withProps } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { getLocationFromTicket } from 'components/modules/paymentResearch/paths';
import { handleStopPropagation } from 'consts';
import { LOAD, load as loadAction } from 'model/paymentResearch';
import { selector as isBusySelector } from 'selectors/busy';
import { selectorPayerId } from 'selectors/paymentResearch';
import { useHistory } from 'react-router-dom';
import { columnMeta } from './config';

const clearAction = (props) => ({
  type: LOAD.SUCCESS,
  data: { props, response: [] },
});

const actionType = LOAD.ACTION;
const defaultPageSettings = {
  order: 'desc',
  orderBy: 'createdAt',
  page: 0,
  rowsPerPage: 5,
  rowsPerPageOptions: [5],
};
const mapDispatchToProps = { clearAction, loadAction };
const noSearch = true;
const resetPageSettingsOnCleanup = true;
const settingType = 'TicketsByPayer';

const useStyles = makeStyles((theme) => ({
  // why such specific numbers for height / width
  // if the data was full, this would be the size,
  // so we always want it at the same size
  paper: {
    minHeight: 370,
    margin: theme.spacing(1),
    width: 600,
  },
}));

const TicketsContent = ({
  fspId,
  handleChange,
  order,
  orderBy,
  page,
  payerId,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const data = useSelector((state) => selectorPayerId(state, payerId));
  const isBusy = useSelector((state) => isBusySelector(state, LOAD));
  const handleRowClick = useCallback(
    ({ id }) => {
      const to = getLocationFromTicket(id, fspId);

      history.push(`${to.pathname}${to.search}`, to.state);
    },
    [fspId, history]
  );

  return (
    <Card
      className={classes.paper}
      elevation={0}
      onClick={handleStopPropagation}
    >
      <Table
        actionConstant={LOAD}
        columnMeta={columnMeta}
        data={data}
        emptyTitle="No tickets"
        highlight={(item) => item.isHighlighted}
        isLoading={isBusy}
        onChange={handleChange}
        onRowClick={handleRowClick}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </Card>
  );
};

TicketsContent.propTypes = {
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default compose(
  connect(null, mapDispatchToProps),
  withProps(({ fspId, payerId }) => ({
    actionType,
    defaultPageSettings,
    loadParams: {
      expand: ['histtran'],
      fspId,
      payerId,
    },
    noSearch,
    resetPageSettingsOnCleanup,
    settingType,
  })),
  ...withPagingSupport
)(TicketsContent);
