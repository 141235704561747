import { attr, Model } from 'redux-orm';
import { LOAD } from './switch';

export default class SwitchAlias extends Model {
  static get modelName() {
    return 'SwitchAlias';
  }

  static reducer({ type, data }, SwitchAlias) {
    switch (type) {
      case LOAD.SUCCESS:
        SwitchAlias.delete();

        if (data.response.switchbilleralias) {
          data.response.switchbilleralias.forEach((r) => SwitchAlias.upsert(r));
        }
        break;

      case LOAD.FAILURE:
        SwitchAlias.delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      alias: attr(),
      aliasType: attr(),
      billerId: attr(),
      billerIds: attr(), // derived
      id: attr(),
      name: attr(), // derived
      offset: attr(),
    };
  }
}
