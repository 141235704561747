import { client } from 'util/sdk';
import { GET, POST, PUT, DELETE } from 'util/api';

const endPointUrl = (fspId) => `/v2/fsps/${fspId}/processingfees`;
const endPointUrlWithId = (fspId, id) =>
  `/v2/fsps/${fspId}/processingfees/${id}`;
const returnModel = { name: 'FspProcessingFees' };
const singleReturnModel = { name: 'processingFee' };

export const load = ({ fspId }, auth) => {
  return client.executeRequest(GET, endPointUrl(fspId), {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
};

export const remove = ({ fspId, id, lastUpdatedAt }, auth) => {
  return client.executeRequest(DELETE, endPointUrlWithId(fspId, id), {
    queryParams: { adminUserId: auth.id, lastUpdatedAt },
    returnModel: singleReturnModel,
  });
};

export const add = ({ fspId, id, ...data }, auth) => {
  return client.executeRequest(POST, endPointUrl(fspId), {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: singleReturnModel,
  });
};

export const update = ({ fspId, id, lastUpdatedAt, data }, auth) => {
  return client.executeRequest(PUT, endPointUrlWithId(fspId, id), {
    data,
    queryParams: { adminUserId: auth.id, lastUpdatedAt },
    returnModel: singleReturnModel,
  });
};
