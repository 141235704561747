import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class CoreProcessor extends Model {
  static get modelName() {
    return 'CoreProcessor';
  }

  static reducer({ type, data }, CoreProcessor) {
    switch (type) {
      case ADD.SUCCESS:
        CoreProcessor.upsert(data.response);
        break;

      case CHECK.ACTION: {
        const processor = CoreProcessor.withId(data.id);
        if (processor) {
          processor.selected = !processor.selected;

          processor.update(processor);
        }
        break;
      }

      case CHECK_ALL.ACTION:
        CoreProcessor.update({ selected: data.selected });
        break;

      case REMOVE.SUCCESS:
        CoreProcessor.withId(data.props.id).delete();
        break;

      case LOAD.SUCCESS:
      case THROTTLED_LOAD.SUCCESS:
        CoreProcessor.delete();
        if (data?.response) {
          data.response.forEach((r) => CoreProcessor.upsert(r));
        }
        break;

      case LOAD.FAILURE:
        CoreProcessor.delete();
        break;

      case UPDATE.SUCCESS:
        CoreProcessor.update(data.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      fileName: attr(),
      fileSpecVersion: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      offset: attr(),
      processor: attr(),
      updatedAt: attr(),
      updUserId: attr(),
    };
  }
}

export const FORM_NAME = 'setupCoreProcessor';
const name = CoreProcessor.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD = createLoadConst(name);
export const THROTTLED_LOAD = createLoadConst(`${name}_throttled`);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const load = createAction(LOAD);
export const throttledLoad = createAction(THROTTLED_LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
