import { REDUCE_MY_BILL } from 'consts';
import { load, LOAD } from 'model/actionInsightReduceMyBill';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingState } from 'reducers/loading';
import { selectorProps } from 'selectors/actionInsightReduceMyBill';
import { selector as busySelector } from 'selectors/busy';
import ActionInsightList from '../ActionInsightList';
import { columns, pageSettingsName } from './consts';
import ReduceMyBillDetailsRenderer from './ReduceMyBillDetailsRenderer';

const DetailsRender = (item) => <ReduceMyBillDetailsRenderer item={item} />;

const ReduceMyBill = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const isLoading = useSelector((state) => isLoadingState(state, LOAD.ACTION));
  const items = useSelector((state) =>
    selectorProps(state, { fspId, payerId })
  );

  const loadAction = (props) => {
    dispatch(load({ fspId, payerId, ...props }));
  };

  useEffect(() => {
    dispatch(load({ fspId, payerId }));
  }, [dispatch, fspId, payerId]);

  return (
    <ActionInsightList
      actionConstant={LOAD}
      columnMeta={columns}
      detailsRender={DetailsRender}
      fspId={fspId}
      isBusy={isBusy}
      isLoading={isLoading}
      items={items}
      loadAction={loadAction}
      name={pageSettingsName}
      payerId={payerId}
      settingType={pageSettingsName}
      title={REDUCE_MY_BILL}
    />
  );
};

ReduceMyBill.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default ReduceMyBill;
