import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// If the type is whatever, there will never be a spinner
const useStyles = makeStyles((theme) => ({
  input: {
    // Hide the spinner, *nobody* likes it
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      margin: 0,
      marginRight: theme.spacing(0.5),
    },
  },
  root: {},
}));

const TextFieldNumeric = ({ InputProps, onKeyPress, TextField, ...props }) => {
  const classes = useStyles();

  const handleKeyPress = (event) => {
    if (!/^\d+$/.test(event.key)) {
      event.preventDefault();
    }
    onKeyPress(event);
  };

  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        classes: {
          ...InputProps?.classes,
          input: classNames(classes.input, {
            [InputProps?.classes?.input]:
              InputProps?.classes?.input !== undefined,
          }),
        },
      }}
      onKeyPress={handleKeyPress}
    />
  );
};

TextFieldNumeric.propTypes = {
  InputProps: PropTypes.object,
  onKeyPress: PropTypes.func,
  TextField: PropTypes.elementType,
};

TextFieldNumeric.defaultProps = {
  InputProps: undefined,
  onKeyPress: always,
  TextField,
};

export default TextFieldNumeric;
