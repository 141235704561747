import { isNil } from 'ramda';
import { useEffect, useState } from 'react';

const useItemUpdatedEffect = (item, effect, deps) => {
  const [previousUpdatedAt, setPreviousUpdatedAt] = useState();

  useEffect(() => {
    if (item?.createdAt && isNil(previousUpdatedAt)) {
      setPreviousUpdatedAt(item.updatedAt ?? item.createdAt);
    }

    if (
      previousUpdatedAt &&
      item?.updatedAt &&
      item.updatedAt !== previousUpdatedAt
    ) {
      effect(item.updatedAt);
      setPreviousUpdatedAt(item.updatedAt);
    }
  }, [deps, effect, item, previousUpdatedAt]);
};

export default useItemUpdatedEffect;
