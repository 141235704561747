import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createLoadConst,
  createUpdateConst,
  dateAndTime,
  getPhoneForDisplay,
} from '../util';

export const derived = (r, generated = false) => {
  return {
    _last: generated,
    createdAtLabel: dateAndTime(r.createdAt),
    deniedAtLabel: dateAndTime(r.deniedAt),
    expiresAtLabel: dateAndTime(r.expiresAt),
    notificationMethodLabel: r.toSms
      ? (r.sendTo || '').toUpperCase()
      : r.sendTo,
    sentToLabel: r.toEmail ? r.toEmail : getPhoneForDisplay(r.toSms),
    validatedAtLabel: dateAndTime(r.validatedAt),
    ...r,
  };
};

export default class OneTimePasscode extends Model {
  static get modelName() {
    return 'OneTimePasscode';
  }

  static reducer({ type, data }, OneTimePasscode) {
    switch (type) {
      case CLEAR_GENERATED.ACTION:
        OneTimePasscode.update({ _last: false });
        break;

      case GENERATE.SUCCESS:
        OneTimePasscode.create(derived(data.response, true));
        break;

      case LOAD.SUCCESS:
        OneTimePasscode.delete();
        data.response.forEach((r) => {
          OneTimePasscode.upsert(derived(r));
        });
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      authAt: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      deniedAt: attr(),
      deniedAtLabel: attr(), // derived
      expiresAt: attr(),
      expiresAtLabel: attr(), // derived
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUserId: attr(),
      lastUnauthCnt: attr(),
      notificationMethodLabel: attr(), // derived
      offset: attr(),
      otp: attr(),
      otpDigit: attr(),
      otpEvent: attr(),
      otpMaxTries: attr(),
      otpReason: attr(),
      otpStatus: attr(),
      payerId: attr(),
      sendTo: attr(),
      sentToLabel: attr(), // derived
      toEmail: attr(),
      toSms: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
      updUserId: attr(),
      userId: attr(),
      userInputOtp: attr(),
      validatedAt: attr(),
      validatedAtLabel: attr(), // derived
    };
  }
}

export const CLEAR_GENERATED = createUpdateConst(OneTimePasscode.modelName);
export const GENERATE = createAddConst(OneTimePasscode.modelName);
export const LOAD = createLoadConst(OneTimePasscode.modelName);

export const clearGenerated = createAction(CLEAR_GENERATED);
export const generate = createAction(GENERATE);
export const load = createAction(LOAD);
