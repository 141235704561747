import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class EBillEnrollment extends Model {
  static get modelName() {
    return 'EBillEnrollment';
  }

  static reducer({ type, data }, EBillEnrollment) {
    if (type === LOAD.SUCCESS && data.response) {
      data.response.forEach((r) => EBillEnrollment.upsert(r));
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      createdAtDerived: attr(),
      ebillernetEbillerId: attr(),
      ebillernetEbillerName: attr(),
      ebillerTrackingToken: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      netUserAccountIdStr: attr(),
      netUserIdStr: attr(),
      offset: attr(),
      payerId: attr(),
      status: attr(),
      updatedAt: attr(),
      updUserId: attr(),
      userLoginIdStr: attr(),
    };
  }
}

export const LOAD = createLoadConst(EBillEnrollment.modelName);
export const REFRESH = createLoadConst(`${EBillEnrollment.modelName}_REFRESH`);

export const load = createAction(LOAD);
export const refresh = createAction(REFRESH);
