import { set } from 'reducers/selectedOrgId';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useDispatchSetOrg = () => {
  const dispatch = useDispatch();

  return useCallback(
    (org) => {
      const { id } = org;

      if (id) {
        dispatch(set(org));
      }
    },
    [dispatch]
  );
};

export default useDispatchSetOrg;
