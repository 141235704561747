import PropTypes from 'prop-types';
import { always } from 'ramda';
import { compose, withHandlers } from 'recompose';

import {
  withStyles,
  FormLabel,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup as MuiRadioGroup,
  Typography,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {},
  formLabel: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
  formGroup: {},
  radio: {},
});

const RadioGroup = ({
  classes,
  handleChange,
  helperText,
  label,
  radios,
  value,
}) => {
  return (
    <FormControl className={classes.root}>
      {label && <FormLabel className={classes.formLabel}>{label}</FormLabel>}
      <MuiRadioGroup
        aria-label={label}
        className={classes.formGroup}
        name={label}
        onChange={handleChange}
        value={value}
      >
        {radios.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            control={
              <Radio classes={{ root: classes.radio }} color="primary" />
            }
            label={
              typeof label === 'string' ? (
                <Typography variant="body2">{label}</Typography>
              ) : (
                label
              )
            }
            value={value}
          />
        ))}
      </MuiRadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

RadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      value: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.string,
};

RadioGroup.defaultProps = {
  value: '',
  label: null,
  helperText: null,
};

export default compose(
  withStyles(styles),
  withHandlers({
    handleChange:
      ({ onChange = always }) =>
      (event, value) =>
        onChange(value),
  })
)(RadioGroup);
