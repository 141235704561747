import { createAction, createLoadConst } from 'util/actions';

const name = 'achCode';

export const LOAD = createLoadConst(name);
export const load = createAction(LOAD);

export const selectorChange = (state) =>
  state[name].filter(({ type }) => type === 'change');

export const selectorReturn = (state) =>
  state[name].filter(({ type }) => type === 'return');

export default (state = [], { data = [], type }) => {
  switch (type) {
    case LOAD.SUCCESS:
      return [...data];

    default:
      return state;
  }
};
