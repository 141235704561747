import PropTypes from 'prop-types';
import { memo } from 'react';
import {
  Amount,
  Date,
  DateRange,
  Phone,
  SSNMask,
  TextRange,
} from 'components/common/fields';
import { MIN_DATE } from 'components/common/fields/Date';
import { Grid, TextField } from '@material-ui/core';
import NameValueType from './name_value';

export const equalOperators = [
  {
    key: 'equalOperator',
    name: 'is equal to',
    value: 'field',
    component: TextField,
  },
];

export const equalPhoneOperators = [
  {
    key: 'equalPhoneOperator',
    name: 'is equal to',
    value: 'field',
    component: Phone,
  },
];

export const ssnMaskOperators = [
  {
    key: 'equalDigitsMaks',
    name: 'is equal to',
    value: 'field',
    component: SSNMask,
  },
];

export const numberOperators = [
  ...equalOperators,
  {
    key: 'numberOperator',
    name: 'start - end',
    value: 'fieldRange',
    component: TextRange,
  },
];

const SearchBy = (props) => {
  return (
    <DateRange
      {...props}
      endPlaceholder="yyyy-mm-dd"
      startPlaceholder="yyyy-mm-dd"
    />
  );
};

const dateRangeOperator = {
  key: 'dateOperatorRange',
  name: (
    <Grid container direction="column" justifyContent="flex-start" spacing={3}>
      <Grid item>start</Grid>
      <Grid item>end</Grid>
    </Grid>
  ),
  value: 'fieldRange',
  defaultValue: [MIN_DATE],
  component: memo(SearchBy),
};

export const dateOperator = [
  {
    key: 'dateOperatorEquals',
    name: 'is equal to',
    value: 'field',
    component: Date,
  },
];

export const dateRangeOperators = [dateRangeOperator];

export const amountOperators = [
  {
    key: 'amountOperator',
    name: 'is equal to',
    value: 'field',
    component: Amount,
  },
];

export const stringOperatorSdk = [
  {
    key: 'stringOperatorSdk',
    name: 'contains',
    value: 'fieldLike',
    component: TextField,
  },
];

export const stringOperatorsSdk = [...stringOperatorSdk, ...equalOperators];

const SearchFieldType = PropTypes.shape({
  field: NameValueType,
  operators: PropTypes.arrayOf(NameValueType),
});

export const SearchBysType = PropTypes.arrayOf(PropTypes.object);

export default SearchFieldType;
