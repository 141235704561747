import {
  ADD,
  DELETE,
  FORM_NAME,
  LOAD,
  LOAD_BY_ORG_ID,
  LOAD_TYPE_AHEAD,
  LOAD_USER,
  UPDATE,
  UPDATE_STATUS,
} from 'model/ontracUser';
import {
  addUser,
  deleteUser,
  loadByOrgId,
  loadTypeAhead,
  loadUser,
  loadUsers,
  updateUser,
  updateUserStatus,
} from 'sdk/ontracUser';
import { takeEvery } from 'redux-saga/effects';
import { callAction } from '../util';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: addUser,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.userLogin} User has been successfully created`,
      type: ADD,
    })
  );

  yield takeEvery(
    DELETE.ACTION,
    callAction({
      api: deleteUser,
      formatSuccessMessage: (data) =>
        `${data.userLogin} User has been successfully removed`,
      type: DELETE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: loadUsers,
      dataName: 'adminUsers',
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_BY_ORG_ID.ACTION,
    callAction({
      api: loadByOrgId,
      dataName: 'adminUsers',
      type: LOAD_BY_ORG_ID,
    })
  );

  yield takeEvery(
    LOAD_TYPE_AHEAD.ACTION,
    callAction({
      api: loadTypeAhead,
      dataName: 'adminUsers',
      type: LOAD_TYPE_AHEAD,
    })
  );

  yield takeEvery(
    LOAD_USER.ACTION,
    callAction({
      api: loadUser,
      dataName: 'adminUsers',
      type: LOAD_USER,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: updateUser,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.userLogin} User has been successfully updated`,
      type: UPDATE,
    })
  );

  yield takeEvery(
    UPDATE_STATUS.ACTION,
    callAction({
      api: updateUserStatus,
      formatSuccessMessage: (data) =>
        `${data.userLogin} status has been successfully updated`,
      type: UPDATE_STATUS,
    })
  );
}
