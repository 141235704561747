import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.background.paper,
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const ExpansionPanelTitleIcon = ({ Icon }) => {
  const classes = useStyles();

  return Icon ? (
    <Grid item>
      <Avatar className={classes.avatar}>
        <Icon className={classes.icon} />
      </Avatar>
    </Grid>
  ) : null;
};

ExpansionPanelTitleIcon.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

ExpansionPanelTitleIcon.defaultProps = {
  Icon: null,
};

export default ExpansionPanelTitleIcon;
