import { Mask } from 'components/common';
import { unmask } from 'sdk/externalAccount';
import DetailsRender from './DetailsRender';
import StatusSelector from './StatusSelector';

export { default as ActionsRenderer } from './ActionsRenderer';

export const columnMeta = [
  {
    id: 'externalFiName',
    label: 'Name',
    xs: 3,
  },
  {
    id: 'nickName',
    label: 'Nickname',
    xs: 3,
  },
  {
    capitalize: true,
    id: 'ddaTypeDerived',
    label: 'Account Type',
    xs: 2,
  },
  {
    context: true,
    id: 'accountNbrMask',
    label: 'Account Number',
    xs: 2,
    renderer: (
      value,
      { accountSuffix, id },
      displayedAsContext,
      { deletedOnly, fspId }
    ) => (
      <Mask
        api={() => unmask({ fspId, id }, deletedOnly)}
        icon={displayedAsContext}
        suffix={accountSuffix}
        value={value}
      />
    ),
  },
  {
    id: 'externalDdaStatus',
    label: 'Status',
    xs: 2,
    renderer: (status, account, context, options) => (
      <StatusSelector
        account={account}
        currentStatus={status}
        options={options}
      />
    ),
  },
];

export const defaultOrderBy = 'id';

export const detailsRender = (account, options) => (
  <DetailsRender account={account} options={options} />
);
