import PropTypes from 'prop-types';
import { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useInterval } from 'hooks';
import { INITIAL_PERCENT, INTERVALS, SECONDS_TO_SHOW_MASK } from '../Mask';

const INTERVAL_DELAY = (SECONDS_TO_SHOW_MASK * 1000) / INTERVALS;

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginTop: theme.spacing(0.5),
  },
}));

const MaskPopoverProgress = ({ show }) => {
  const classes = useStyles();
  const [progressValue, setProgressValue] = useState(INITIAL_PERCENT);

  const intervalDelay = show ? INTERVAL_DELAY : null;

  useInterval(() => {
    const percent = progressValue - 100 / INTERVALS;

    if (percent >= 0) {
      setProgressValue(percent);
    }
  }, intervalDelay);

  return (
    <CircularProgress
      className={classes.circularProgress}
      color={show ? 'primary' : 'secondary'}
      size={22}
      thickness={7}
      value={progressValue}
      variant="determinate"
    />
  );
};

MaskPopoverProgress.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default MaskPopoverProgress;
