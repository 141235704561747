import {
  ACCOUNT_CLOSED,
  ACCOUNT_NOT_FOUND,
  TRANSACTION_TYPE_BILL_PAY,
  DELIVERED,
  DELIVER_CHECK_EXPEDITE,
  DELIVER_CHECK_STD,
  DELIVER_ELEC_STD,
  MANUAL_REFUND,
  PERSON_TO_PERSON,
  PROCESSED,
  REFUND_FAILED,
  ZERO_DOLLAR_BALANCE,
  DELIVER_ELEC_NOW,
  ACCOUNT_TO_ACCOUNT_INTERNAL,
} from 'consts';

/**
 * Elec
 */
export const menuItemsForElec = [
  ACCOUNT_CLOSED,
  ACCOUNT_NOT_FOUND,
  ZERO_DOLLAR_BALANCE,
];

/**
 * Check
 */
export const MAIL_WAS_RETURNED_BY_THE_USPS =
  'Mail was returned by the USPS and/or FedEx';

export const menuItemsForChecks = [
  MAIL_WAS_RETURNED_BY_THE_USPS,
  ...menuItemsForElec,
];

/**
 * Helpers
 */

export const isCheckPayment = (deliver) =>
  [DELIVER_CHECK_STD, DELIVER_CHECK_EXPEDITE].includes(deliver);

export const isCheck = ({ deliver, status, transactionType }) =>
  [TRANSACTION_TYPE_BILL_PAY].includes(transactionType) &&
  isCheckPayment(deliver) &&
  [DELIVERED, PROCESSED].includes(status);

export const isElecDelivered = ({ deliver, status, transactionType }) =>
  [TRANSACTION_TYPE_BILL_PAY].includes(transactionType) &&
  [DELIVER_ELEC_STD].includes(deliver) &&
  [DELIVERED].includes(status);

export const isElecManualRefund = ({ deliver, status, transactionType }) =>
  [TRANSACTION_TYPE_BILL_PAY, PERSON_TO_PERSON].includes(transactionType) &&
  [DELIVER_ELEC_NOW, DELIVER_ELEC_STD].includes(deliver) &&
  [MANUAL_REFUND].includes(status);

export const isElecRefundFailed = ({ deliver, status, transactionType }) =>
  [TRANSACTION_TYPE_BILL_PAY, PERSON_TO_PERSON].includes(transactionType) &&
  [DELIVER_ELEC_NOW, DELIVER_ELEC_STD].includes(deliver) &&
  [REFUND_FAILED].includes(status);

export const isInternalTransfer = ({ transactionType }) =>
  transactionType === ACCOUNT_TO_ACCOUNT_INTERNAL;
