import { DATA_KEY_DIRECT } from 'model/util/recipient';
import { DEFAULT_MODEL_REF, DEFAULT_MODEL_REF_ARRAY } from 'consts';
import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, payerId) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId)[DATA_KEY_DIRECT].toRefArray()
      : DEFAULT_MODEL_REF_ARRAY
);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId, id }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId)
          [DATA_KEY_DIRECT].filter((recipient) => recipient.id === id)
          .first().ref
      : DEFAULT_MODEL_REF
);
