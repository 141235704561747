import { omit } from 'ramda';
import { client, formatQueryParams } from 'util/sdk';

const createParams = [
  'createdAt',
  'dirtyDescription',
  'dirtyScopes',
  'insUserId',
  'offset',
  'selected',
  'updUserId',
  'updatedAt',
];

const updateParams = ['name', ...createParams];

export const create = (template, auth) =>
  client.automatchTemplates.create(omit(createParams, template), {
    adminUserId: auth.id,
  });

export const load = (params) =>
  client.automatchTemplates.query(formatQueryParams(params));

export const update = ({ id, ...template }, auth) =>
  client
    .automatchTemplates(id)
    .update(omit(updateParams, template), { adminUserId: auth.id });

export const remove = (id, auth) =>
  client.automatchTemplates(id).delete({ adminUserId: auth.id });
