import crypto from 'crypto';

const algorithmHash = 'sha256';
const outputEncoding = 'hex';
const separator = ':_';

/**
 * This nodejs hashing algorithm will produce the same hash as
 * this one in mysql 5.7:
 *
 * select SHA2(text, 256)
 *
 * @param password - the text to produce a hash from
 * @param username - the username
 * @returns {object} the salt plus the hash representation of the text
 */
// eslint-disable-next-line import/prefer-default-export
export const hashPassword = (password, username) => {
  return crypto
    .createHash(algorithmHash)
    .update(username.toLowerCase() + separator + password)
    .digest(outputEncoding);
};
