import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import AddDialogActions from './AddDialogActions';

const useStyles = makeStyles(() => ({
  circularProgress: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: 'calc(50% - 25px)',
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
}));

const AddDialog = ({ actionType, Form, title, ...props }) => {
  const classes = useStyles();
  const isBusy = useSelector((state) => selector(state, actionType));

  return (
    <Dialog aria-labelledby="add-action" fullWidth maxWidth="md" {...props}>
      <CircularProgress
        className={classNames(classes.circularProgress, {
          [classes.visible]: isBusy,
        })}
        size={50}
      />
      <DialogTitle id="add-action">Add {title}</DialogTitle>
      <DialogContent>
        <Form />
      </DialogContent>
      <AddDialogActions {...props} />
    </Dialog>
  );
};

AddDialog.propTypes = {
  actionType: PropTypes.object.isRequired,
  Form: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
};

export default AddDialog;
