import { memo } from 'react';
import { load, LOAD } from 'model/scheduledTransaction';
import { Schedule } from '@material-ui/icons';
import { selector as busySelector } from 'selectors/busy';
import { selector } from 'selectors/scheduledTransaction';
import { useDispatch, useSelector } from 'react-redux';
import Transactions from '../Transactions';
import {
  ActionsRenderer,
  columnMeta,
  detailsRender,
  createSearchFields,
} from './config';

const defaultExpandablePanelProps = {
  heightOffset: 86,
  icon: Schedule,
  id: 'scheduled',
  scrollTopOffset: 34,
  title: 'Scheduled Transactions',
};

const Scheduled = (props) => {
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const dispatch = useDispatch();
  const items = useSelector((state) => selector(state, props));

  const clearAction = (params) => {
    dispatch({
      data: { params, response: [] },
      type: LOAD.SUCCESS,
    });
  };
  const loadAction = (args) => {
    dispatch(load(args));
  };

  return (
    <Transactions
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      actionType={LOAD.ACTION}
      clearAction={clearAction}
      columnMeta={columnMeta}
      countBack={false}
      createSearchFields={createSearchFields}
      detailsRender={detailsRender}
      emptyTitle="No Scheduled Transactions Found"
      ExpandablePanelProps={{
        ...defaultExpandablePanelProps,
        showProgress: isBusy,
      }}
      isBusy={isBusy}
      items={items}
      loadAction={loadAction}
      name="scheduled"
      priorityIndex={3}
      settingType="scheduledTransaction"
      supportsDeleted
      {...props}
    />
  );
};

export default memo(Scheduled);
