import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class RecentTicketPaymentOps extends Model {
  static get modelName() {
    return 'RecentTicketPaymentOps';
  }

  static reducer({ data, type }, RecentTicketPaymentOps) {
    switch (type) {
      case ADD.SUCCESS:
        RecentTicketPaymentOps.filter(
          ({ trrId }) => trrId === data.trrId
        ).delete();
        RecentTicketPaymentOps.create(data);
        break;

      case CLEAR.SUCCESS:
      case CLEAR_LOCATION.SUCCESS:
        RecentTicketPaymentOps.delete();
        break;

      case LOAD.SUCCESS:
        RecentTicketPaymentOps.delete();

        data.forEach((r) => RecentTicketPaymentOps.upsert(r));
        break;

      case REMOVE.SUCCESS:
        RecentTicketPaymentOps.withId(data.id).delete();
        break;

      case UPDATE.SUCCESS:
        RecentTicketPaymentOps.upsert(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      fspId: attr(),
      location: attr(),
      primary: attr(),
      secondary: attr(),
      tertiary: attr(),
      trrId: attr(),
    };
  }
}

export const ADD = createAddConst(RecentTicketPaymentOps.modelName);
export const CLEAR = createDeleteConst(
  `${RecentTicketPaymentOps.modelName}_clear`
);
export const CLEAR_LOCATION = createDeleteConst(
  `${RecentTicketPaymentOps.modelName}_clearLocation`
);
export const LOAD = createRequestConst(RecentTicketPaymentOps.modelName);
export const REMOVE = createDeleteConst(RecentTicketPaymentOps.modelName);
export const UPDATE = createUpdateConst(RecentTicketPaymentOps.modelName);

export const add = createAction(ADD);
export const clear = createAction(CLEAR);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
