import PropTypes from 'prop-types';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  moreArrow: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(2),
  },
  secondary: {
    textTransform: 'capitalize',
  },
}));

const MenuItemText = ({ payerUser, submenu }) => {
  const classes = useStyles();
  const primary = payerUser?.name;
  const secondary = `role: ${payerUser?.role}`;

  return (
    <>
      {submenu && <ChevronLeft className={classes.moreArrow} />}
      <ListItemText
        classes={{ secondary: classes.secondary }}
        primary={primary}
        secondary={secondary}
      />
    </>
  );
};

MenuItemText.propTypes = {
  payerUser: PropTypes.object,
  submenu: PropTypes.bool,
};

MenuItemText.defaultProps = {
  payerUser: null,
  submenu: false,
};

export default MenuItemText;
