import { Visibility, VisibilityOff } from '@material-ui/icons';
import { fieldPropTypes } from 'redux-form';
import { omit } from 'ramda';
import {
  compose,
  mapProps,
  pure,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Select from './Select';
import PhoneField from './phone';
import EmailField from './email';
import ReadOnlyField from './ReadOnly';
import MultiSelection from './multi_selection';
import SwitchField from './switch';
import CheckboxField from './checkbox_org';
import AmountField from './Amount';

const renderField = (Component) => {
  const renderComponent = ({
    meta: { touched, error = '' },
    input: { ...inputProps },
    ...props
  }) => (
    <Component
      error={touched && error.length > 0}
      helperText={touched ? error : ''}
      {...inputProps}
      {...props}
    />
  );

  renderComponent.propTypes = {
    ...fieldPropTypes,
  };

  return renderComponent;
};

export const renderTextField = renderField(
  withProps(({ autoComplete = 'off', id, name }) => ({
    autoComplete,
    inputProps: {
      'aria-describedby': id || name,
      'data-testid': id || name,
      title: id || name,
    },
  }))(TextField)
);

export const Password = renderField(
  compose(
    withState('showPassword', 'setShowPassword', false),
    withHandlers({
      handleClick:
        ({ setShowPassword, showPassword }) =>
        () =>
          setShowPassword(!showPassword),
    }),
    withProps(
      ({
        autoComplete = 'current-password',
        handleClick,
        showPassword,
        id,
        name,
      }) => ({
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={handleClick}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        },
        autoComplete,
        inputProps: {
          'aria-label': 'password',
          maxLength: 32,
          'data-testid': id || name,
        },
        type: showPassword ? 'text' : 'password',
      })
    ),
    mapProps((props) =>
      omit(['handleClick', 'showPassword', 'setShowPassword'], props)
    ),
    pure
  )(TextField)
);

export const renderSelectField = renderField(Select);

export const renderPhoneField = renderField(PhoneField);

export const renderEmailField = renderField(EmailField);

export const renderReadOnlyField = renderField(ReadOnlyField);

export const renderMultiSelectionField = renderField(MultiSelection);

export const renderSwitchField = renderField(SwitchField);

export const renderCheckboxField = renderField(CheckboxField);

export const renderAmountField = renderField(AmountField);
