import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import dJSON from 'dirty-json';
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { FULL_ACCESS_PAYMENT_RESEARCH } from 'components/common/permissions';
import { always } from 'ramda';
import { create, update } from 'model/researchNote';
import { getAuth } from 'util/index';
import { hasPermission } from 'util/rbac';
import { makeStyles } from '@material-ui/core/styles';
import { selectorId } from 'selectors/researchNote';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
} from '@material-ui/core';
import NoteEditorErrorSnackBar from './NoteEditorErrorSnackBar';
import { ConsumerNotificationNoteType, RegularNoteType } from './consts';

const PLACEHOLDER = 'Begin typing...';
const TOOLBAR_OPTIONS = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'list',
    'textAlign',
    'colorPicker',
    'link',
    'remove',
    'history',
  ],
};

const createWithContent = ({ formattedNote }) => {
  let content;

  try {
    content = convertFromRaw(JSON.parse(formattedNote));
  } catch (e) {
    try {
      content = convertFromRaw(dJSON.parse(formattedNote, false));
    } catch (e) {
      content = ContentState.createFromText('');
    }
  }

  return EditorState.createWithContent(content);
};

const useStyles = makeStyles((theme) => ({
  actionButtonContainer: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  editor: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    maxHeight: 300,
    padding: theme.spacing(1),
  },
  editorActive: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  editorActiveConsumerNotification: {},
  editorNew: {
    minHeight: 300,
  },
  formControl: {
    marginLeft: theme.spacing(2),
    minHeight: 'initial',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.typography.fontFamily,
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
  },
  toolbar: {
    backgroundColor: theme.palette.background.default,
  },
  wrapper: {
    width: '100%',
  },
}));

const NoteEditor = ({
  editorRef,
  fspId,
  id,
  onCancel,
  onChangeType,
  onSubmit,
  placeholder,
  readOnly,
  trrId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const item = useSelector((state) => selectorId(state, id)) || {};
  const [editorReference, setEditorReference] = useState();
  const [editorState, setEditorState] = useState(createWithContent(item));
  const [showError, setShowError] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(
    item.noteType === ConsumerNotificationNoteType
  );

  const handleCancel = (event) => onCancel(event);
  const handleCloseError = () => setShowError(false);
  const handleEditorReference = useCallback(
    (ref) => {
      editorRef(ref);
      setEditorReference(ref);
    },
    [editorRef]
  );
  const handleEditorStateChange = (value) => setEditorState(value);
  const handleToggleNoteType = (event, value) => {
    setSwitchChecked(value);
    editorReference.focus();
  };
  const handleSave = (event) => {
    try {
      const note = editorState.getCurrentContent().getPlainText(' ').trim();
      const formattedNote = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      const noteObj = {
        formattedNote,
        note,
        noteType: switchChecked
          ? ConsumerNotificationNoteType
          : RegularNoteType,
      };
      let action = create({ fspId, noteObj, trrId });

      if (id) {
        action = update({
          fspId,
          id,
          noteObj,
          onError: () => {
            setEditorState(createWithContent(item));
          },
        });
      } else {
        setEditorState(createWithContent({ formattedNote: {} }));
      }
      dispatch(action);
      onSubmit(event);
    } catch (e) {
      setEditorState(createWithContent(item));
      setShowError(true);
    }
  };

  const hasAddConsumerNotification = hasPermission(
    auth,
    FULL_ACCESS_PAYMENT_RESEARCH
  );
  const disabled =
    editorState.getCurrentContent().getPlainText(' ').trim().length === 0;
  const isEditing = Boolean(id);
  const submitButtonText = isEditing ? 'save' : 'add';

  useEffect(() => {
    if (!readOnly && editorReference) editorReference.focus();
  }, [editorReference, readOnly]);

  useEffect(() => {
    onChangeType(
      switchChecked ? ConsumerNotificationNoteType : RegularNoteType
    );
  }, [onChangeType, switchChecked]);

  return (
    <>
      <div className={classes.root}>
        <Editor
          editorClassName={classNames(classes.editor, {
            [classes.editorActive]: !readOnly,
            [classes.editorActiveConsumerNotification]: switchChecked,
            [classes.editorNew]: !id,
          })}
          editorRef={handleEditorReference}
          editorState={editorState}
          hashtag={{}}
          onEditorStateChange={handleEditorStateChange}
          placeholder={placeholder}
          readOnly={readOnly}
          toolbar={TOOLBAR_OPTIONS}
          toolbarClassName={classes.toolbar}
          toolbarHidden={readOnly}
          wrapperClassName={classes.wrapper}
        />
      </div>
      {!readOnly && (
        <Grid
          alignItems="center"
          container
          justifyContent={
            hasAddConsumerNotification && !isEditing
              ? 'space-between'
              : 'flex-end'
          }
          spacing={4}
        >
          {hasAddConsumerNotification && !isEditing && (
            <Grid item>
              <FormControl classes={{ root: classes.formControl }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={switchChecked}
                      color="primary"
                      onChange={handleToggleNoteType}
                    />
                  }
                  label="Consumer Notification"
                  labelPlacement="start"
                  value=""
                />
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  aria-label="Cancel Adding a Note"
                  className={classes.button}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid className={classes.actionButtonContainer} item>
                <Button
                  aria-label="Add/Update a Note"
                  className={classes.button}
                  color="primary"
                  disabled={disabled}
                  onClick={handleSave}
                  variant="outlined"
                >
                  {submitButtonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <NoteEditorErrorSnackBar
        buttonText={submitButtonText}
        onClose={handleCloseError}
        open={showError}
      />
    </>
  );
};

NoteEditor.propTypes = {
  editorRef: PropTypes.func,
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onChangeType: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  trrId: PropTypes.number.isRequired,
};

NoteEditor.defaultProps = {
  editorRef: always,
  id: null,
  onChangeType: always,
  placeholder: PLACEHOLDER,
  readOnly: false,
};

export default memo(NoteEditor);
