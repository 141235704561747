import { useField, useFormikContext } from 'formik';
import { isNilOrEmpty } from 'util/index';

export const getDisabled = (disabled, field, { isSubmitting }) => {
  if (isSubmitting || field.value === undefined) {
    return true;
  }

  return disabled;
};

/**
 * All other formik components in this folder *should* use this new hook as it
 * consolidates the proper setting of disabled and error, I just didn't have
 * time to convert the rest of the components
 */
const useFormikField = (props) => {
  const [field, meta, helpers] = useField(props);
  const formikContext = useFormikContext();

  const { disabled, helperText } = props;
  const disabledProp = getDisabled(disabled, field, formikContext);
  const errorProp = meta?.touched && !isNilOrEmpty(meta?.error);
  const helperTextProp = (meta?.touched && meta?.error) || helperText;
  const formikProps = {
    disabled: disabledProp,
    error: errorProp,
    helperText: helperTextProp,
  };
  const fieldProps = {
    ...field,
    /**
     * Fixes this warning:
     *   `value` prop on `input` should not be null. Consider using an empty string to
     *   clear the component or `undefined` for uncontrolled components.
     */
    value: field.value ?? '',
  };

  return [formikProps, fieldProps, helpers, meta];
};

export default useFormikField;
