import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect, useState } from 'react';
import {
  DEFAULT_VIEW,
  VIEW_DETAIL,
  VIEW_LIST,
  VIEW_SYSTEM_DETAILS,
  changeView,
  selector,
} from 'reducers/audit';
import { List, ListAlt, ViewList } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(3),
  },
}));

const AuditDrawerAppBarEndAdornment = ({ theme }) => {
  const classes = useStyles();
  const [enterDelay] = useState(theme.transitions.duration.standard * 1.5);
  const [view, setView] = useState(DEFAULT_VIEW);
  const audit = useSelector(selector);
  const dispatch = useDispatch();

  const handleView = (event, value) => {
    if (value) {
      setView(value);
      setTimeout(() => {
        dispatch(changeView(value));
      });
    }
  };

  useEffect(() => {
    setView(audit.view);
  }, [audit.view]);

  return (
    <ToggleButtonGroup
      className={classes.root}
      exclusive
      onChange={handleView}
      value={view}
    >
      <ToggleButton
        data-testid={`auditdrawer-toggle-${VIEW_LIST}`}
        value={VIEW_LIST}
      >
        <Tooltip enterDelay={enterDelay} title="Audit Log Entries">
          <List />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        data-testid={`auditdrawer-toggle-${VIEW_DETAIL}`}
        value={VIEW_DETAIL}
      >
        <Tooltip enterDelay={enterDelay} title="Expand All with Basic Details">
          <ViewList />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        data-testid={`auditdrawer-toggle-${VIEW_SYSTEM_DETAILS}`}
        value={VIEW_SYSTEM_DETAILS}
      >
        <Tooltip
          enterDelay={enterDelay}
          title="Expand All with Advance Details"
        >
          <ListAlt />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

AuditDrawerAppBarEndAdornment.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(AuditDrawerAppBarEndAdornment);
