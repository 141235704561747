import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

const ContentDescription = () => {
  return (
    <Field
      autoComplete="off"
      component={TextField}
      disabled={false}
      fullWidth
      id="description"
      label="Additional Details"
      maxRows={3}
      multiline
      name="description"
      required
    />
  );
};

export default ContentDescription;
