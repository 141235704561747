import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { DelimitedNumericArrayParam, useQueryParam } from 'use-query-params';
import { head } from 'ramda';
import ExpansionList from './ExpansionList';

const ExpansionListByQueryParam = ({ name, ...props }) => {
  const [expandedIds, setExpandedIds] = useQueryParam(
    name,
    DelimitedNumericArrayParam
  );
  const [expandedState, setExpandedState] = useState(true);
  const [idState, setIdState] = useState();

  const handleListItemChange = useCallback((id, expanded) => {
    setIdState(id);
    setExpandedState(expanded);
  }, []);

  useEffect(() => {
    const ids = expandedIds ? [...expandedIds] : [];
    const index = ids.indexOf(idState);

    if (expandedState && idState) {
      const id = idState || head(expandedIds ?? []);

      if (id && index === -1) {
        ids.push(id);
      }
    } else if (index > -1) {
      ids.splice(index, 1);
    }

    setExpandedIds(ids.length ? ids : undefined);
  }, [expandedIds, expandedState, idState, setExpandedIds]);

  useEffect(() => {
    return () => {
      setExpandedIds(undefined);
    };
  }, [setExpandedIds]);

  return (
    <ExpansionList
      expandedIds={expandedIds ?? []}
      name={name}
      onListItemChange={handleListItemChange}
      {...props}
    />
  );
};

ExpansionListByQueryParam.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ExpansionListByQueryParam;
