import { client } from 'util/sdk';

// eslint-disable-next-line import/prefer-default-export
export const load = ({ fspId, id, limit, offset }) =>
  client
    .fsps(fspId)
    .payerEbillernetEbillerAuditLogs.query((_) => [
      _.payerEbillernetEbillerId(id),
      _.limit(limit),
      _.offset(offset),
    ]);
