import { EmojiTransportation } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { memo } from 'react';
import usePayALoanPayerOffOn from 'hooks/usePayALoanPayerOffOn';
import { HEIGHT_OFFSET } from '../index';
import PayALoanList from './PayALoanList';
import PayerDetailPanel from '../PayerDetailPanel';

const PayALoan = ({ fspId, payerId }) => {
  const isPayerPalOffOn = usePayALoanPayerOffOn(fspId, payerId);

  return (
    isPayerPalOffOn && (
      <PayerDetailPanel
        fspId={fspId}
        payerId={payerId}
        fullHeight={false}
        scrollTopOffset={30}
        heightOffset={HEIGHT_OFFSET}
        icon={EmojiTransportation}
        title="Pay A Loan"
      >
        <PayALoanList fspId={fspId} payerId={payerId} />
      </PayerDetailPanel>
    )
  );
};

PayALoan.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};
export default memo(PayALoan);
