import { AccessTime } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.grey['200'],
    backgroundColor: theme.palette.grey['200'],
    marginRight: theme.spacing(2),
  },
}));

const SkeletonIcon = () => {
  const classes = useStyles();

  return <AccessTime className={classes.icon} />;
};

export default SkeletonIcon;
