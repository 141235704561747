import PropTypes from 'prop-types';
import { Button, DialogActions, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

const ImportDialogActions = ({ onCancel, section }) => {
  const { isValid, isSubmitting, handleSubmit, values } = useFormikContext();

  const disabled = !values[section]?.length || !isValid || isSubmitting;

  return (
    <DialogActions>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button onClick={onCancel}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={disabled}
            onClick={handleSubmit}
            variant="contained"
          >
            Import
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

ImportDialogActions.propTypes = {
  onCancel: PropTypes.func.isRequired,
  section: PropTypes.string.isRequired,
};

export default ImportDialogActions;
