import { add, importAndReset, load, remove, update } from 'sdk/userRole';
import { ADD, LOAD, REMOVE, UPDATE, IMPORT } from 'model/userRole';
import { callAction, ORM } from 'util/index';
import { takeEvery } from 'redux-saga/effects';
import {
  ADDED,
  formatSuccessMessage,
  IMPORTED,
  REMOVED,
  ROLE_CODE,
  UPDATED,
  USER_ROLE,
} from 'consts';
import { loadFSPProfileAction } from './export';

const keyName = { key: ROLE_CODE, name: USER_ROLE };

export const importUserRoles = () =>
  callAction({
    api: importAndReset,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, IMPORTED, true),
    type: IMPORT,
  });

function* addAndPopulateExport(action) {
  const addAction = callAction({
    api: add,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, ADDED),
    type: ADD,
  });

  if (yield addAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* importAndPopulateExport(action) {
  const importAndResetAction = importUserRoles();

  if (yield importAndResetAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* removeAndPopulateExport(action) {
  const removeAction = callAction({
    api: remove,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, REMOVED),
    type: REMOVE,
  });

  if (yield removeAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, UPDATED),
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(ADD.ACTION, addAndPopulateExport);

  yield takeEvery(IMPORT.ACTION, importAndPopulateExport);

  yield takeEvery(REMOVE.ACTION, removeAndPopulateExport);

  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);
}
