import { ADD, UPDATE } from 'model/ontracUser';

export const REQUESTING = 'requesting';

export const CLEAR = 'DUPLICATED_USER_CLEAR';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const USER_DUPLICATED = 'USER_DUPLICATED';

export const clear = () => ({ type: CLEAR });

export const isDuplicateUserError = (type, error) =>
  type === ADD.FAILURE && error.message.includes('already exists');

export default (state = null, { type, error }) => {
  switch (type) {
    case UPDATE.SUCCESS:
    case ADD.SUCCESS:
      return ADD_USER_SUCCESS;

    case ADD.ACTION:
      return REQUESTING;

    case ADD.FAILURE:
      return isDuplicateUserError(type, error) && USER_DUPLICATED;

    case CLEAR:
      return null;

    default:
      return state;
  }
};
