import { client, formatQueryParams } from 'util/sdk';

export const loadGroupAdmins = (data) =>
  client.admin.adminGroupAdmins.query(formatQueryParams(data));

export const addGroupAdmin = ({ adminUserId, ...data }) =>
  client.admin.adminGroupAdmins.create(data, { adminUserId });

export const deleteGroupAdmin = ({ groupAdminId, adminUserId }) =>
  client.admin.adminGroupAdmins(groupAdminId).delete({ adminUserId });

export const updateGroupAdmin = ({ groupAdminId, adminUserId, ...data }) =>
  client.admin.adminGroupAdmins(groupAdminId).update(data, { adminUserId });
