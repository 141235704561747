import { LOAD, UPDATE } from 'model/fspCoreConfig';
import { formatSuccessMessage, FSP_CORE_CONFIG_LABEL, UPDATED } from 'consts';
import { load, update } from 'sdk/fspCoreConfig';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';
import { loadFSPProfileAction } from './export';

const keyName = { key: '', name: FSP_CORE_CONFIG_LABEL };

export const updateFSPCoreConfig = () =>
  callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage(keyName, UPDATED, false),
    type: UPDATE,
  });

function* updateAndPopulateExport(action) {
  const updateAction = updateFSPCoreConfig();

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);
}
