import { FORM_NAME, LOAD, UPDATE } from 'model/userProfile';
import { load, update } from 'sdk/userProfile';
import { takeEvery } from 'redux-saga/effects';
import { callAction } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      type: LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      formName: FORM_NAME,
      formatSuccessMessage: () => 'Your account settings were updated',
      type: UPDATE,
    })
  );
}
