import PropTypes from 'prop-types';
import Multiple from './Multiple';
import Single from './Single';

const SearchTextField = ({ multiple, ...props }) => {
  return multiple ? <Multiple {...props} /> : <Single {...props} />;
};

SearchTextField.propTypes = {
  multiple: PropTypes.bool,
};

SearchTextField.defaultProps = {
  multiple: false,
};

export default SearchTextField;
