import { client } from 'util/sdk';
import { GET, POST, PUT } from 'util/api';
import { omit } from 'ramda';

const PROPS_TO_OMIT = [
  'fraudMonitor',
  'globalDenyListOffOn',
  'hasDLConfig',
  'fraudOffOn',
];
const returnModel = { name: 'fspDenyListItems' };
const queryParams = ({ id }) => ({ adminUserId: id });
const queryParamsForUpdate = ({ id }, lastUpdatedAt) => ({
  adminUserId: id,
  lastUpdatedAt,
});
const denyListConfigUri = (fspId) => `v2/fsps/${fspId}/deny-list-offerings`;

export const loadDenyListConfig = (fspId, auth) =>
  client.executeRequest(GET, denyListConfigUri(fspId), {
    queryParams: queryParams(auth),
    returnModel,
  });

export const createDenyListConfig = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, denyListConfigUri(fspId), {
    data: omit(PROPS_TO_OMIT, data),
    queryParams: queryParams(auth),
    returnModel,
  });

export const updateDenyListConfig = ({ fspId, lastUpdatedAt, ...data }, auth) =>
  client.executeRequest(PUT, denyListConfigUri(fspId), {
    data: omit(PROPS_TO_OMIT, data),
    queryParams: queryParamsForUpdate(auth, lastUpdatedAt),
    returnModel,
  });
