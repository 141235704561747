import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, Tooltip } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { selector, VIEW_SYSTEM_DETAILS } from 'reducers/audit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const tooltipText = (show) => `${show ? 'Hide' : 'Show more'} audit fields`;

const useStyles = makeStyles((theme) => {
  const transition = {
    duration: theme.transitions.duration.standard,
  };

  return {
    opened: {
      transform: 'rotate(-180deg)',
      transition: theme.transitions.create('transform', transition),
    },
    closed: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', transition),
    },
  };
});

const AuditDetailRowRecordExpandButton = ({ onClick, show, theme }) => {
  const classes = useStyles();
  const audit = useSelector(selector);
  const [delay] = useState(theme.transitions.duration.standard);
  const [opened, setOpened] = useState(audit.view === VIEW_SYSTEM_DETAILS);
  const [tooltip, setTooltip] = useState(tooltipText(opened));

  const handleClick = () => {
    setOpened(!opened);
    onClick(!opened);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTooltip(tooltipText(opened));
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay, opened]);

  useEffect(() => {
    setOpened(audit.view === VIEW_SYSTEM_DETAILS);
  }, [audit.view]);

  return show ? (
    <Grid data-testid="AuditDetailRowRecordExpandButton-expand-more" item>
      <Tooltip enterDelay={delay} title={tooltip}>
        <Button data-testid="more" fullWidth onClick={handleClick}>
          <ExpandMore
            className={classNames({
              [classes.closed]: !opened,
              [classes.opened]: opened,
            })}
            fontSize="small"
          />
        </Button>
      </Tooltip>
    </Grid>
  ) : null;
};

AuditDetailRowRecordExpandButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(AuditDetailRowRecordExpandButton);
