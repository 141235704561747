import authTokenSelector from 'selectors/authToken';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { always } from 'ramda';
import { LOGIN } from 'reducers/authToken';
import { selector } from 'selectors/busy';
import { shallowEqual, useSelector } from 'react-redux';
import OneTimePasscodeHeader from './OneTimePasscodeHeader';
import OneTimePasscodeContent from './OneTimePasscodeContent';
import OneTimePasscodeAction from './OneTimePasscodeAction';
import { OneTimePasscodeProvider } from './OneTimePasscodeContext';

const OneTimePasscode = ({ onAuthenticated, onProgress, visible }) => {
  const authToken = useSelector(authTokenSelector, shallowEqual);
  const isBusy = useSelector((state) => selector(state, LOGIN));

  useEffect(() => {
    if (visible) {
      onProgress(isBusy);
    }
  }, [isBusy, onProgress, visible]);

  useEffect(() => {
    if (authToken.isAuthenticated && visible) {
      onAuthenticated();
    }
  }, [authToken.isAuthenticated, onAuthenticated, visible]);

  return visible ? (
    <OneTimePasscodeProvider>
      <Card elevation={0}>
        <OneTimePasscodeHeader />
        <OneTimePasscodeContent />
        <OneTimePasscodeAction />
      </Card>
    </OneTimePasscodeProvider>
  ) : null;
};

OneTimePasscode.propTypes = {
  onAuthenticated: PropTypes.func,
  onProgress: PropTypes.func,
  visible: PropTypes.bool,
};

OneTimePasscode.defaultProps = {
  onAuthenticated: always,
  onProgress: always,
  visible: false,
};

export default OneTimePasscode;
