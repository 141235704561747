import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  TRANSACTION_TYPE_BILL_PAY,
  BILL_PAYMENT,
  PERSON_PAY,
  PERSON_TO_PERSON,
  TRANSFER_MONEY_IN,
  TRANSFER_MONEY_NEW,
  TRANSFER_MONEY_OUT,
  ACCOUNT_TO_ACCOUNT_INTERNAL,
  TRANSFER_MONEY_INTERNAL,
  TRANSACTION_TYPE_PAL,
  TRANSACTION_TYPE_PAL_ESCROW,
  TRANSACTION_TYPE_PAL_INTEREST,
  TRANSACTION_TYPE_PAL_PRINCIPAL,
  PAY_A_LOAN_LABEL,
  PAL_ESCROW_DERIVED,
  PAL_INTEREST_DERIVED,
  PAL_PRINCIPAL_DERIVED,
} from 'consts';

/**
 * Known system types
 */
const types = [
  { code: ACCOUNT_TO_ACCOUNT_OUTBOUND, value: TRANSFER_MONEY_OUT },
  { code: ACCOUNT_TO_ACCOUNT_INBOUND, value: TRANSFER_MONEY_IN },
  { code: ACCOUNT_TO_ACCOUNT_INTERNAL, value: TRANSFER_MONEY_INTERNAL },
  { code: ACCOUNT_TO_ACCOUNT_NEW, value: TRANSFER_MONEY_NEW },
  { code: TRANSACTION_TYPE_BILL_PAY, value: BILL_PAYMENT },
  { code: PERSON_TO_PERSON, value: PERSON_PAY },
  { code: TRANSACTION_TYPE_PAL, value: PAY_A_LOAN_LABEL },
  { code: TRANSACTION_TYPE_PAL_ESCROW, value: PAY_A_LOAN_LABEL },
  { code: TRANSACTION_TYPE_PAL_INTEREST, value: PAY_A_LOAN_LABEL },
  { code: TRANSACTION_TYPE_PAL_PRINCIPAL, value: PAY_A_LOAN_LABEL },
];

export const findByCode = (items = [], code) =>
  items.find((item) => item.code.toLowerCase() === code?.toLowerCase()) || {
    code,
    description: code,
    value: code,
  };

export const getType = (type) => {
  const item = findByCode(types, type);
  return item ? item.value : item;
};
