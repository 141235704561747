import { Button, CardActions, CardHeader, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PATH as LOGIN_PATH } from 'components/Authentication/Login';
import { VALIDATE } from 'reducers/validate';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import { validateSelector } from 'selectors/security';

const subheaderTypographyProps = { color: 'textSecondary', variant: 'body2' };
const titleTypographyProps = { color: 'textPrimary', variant: 'subtitle1' };

const getError = (error = {}) => {
  const { message, number } = error;
  const errorMessage = {
    title: number,
    details: message,
  };

  switch (number) {
    case '010005':
    case 404: {
      errorMessage.title = 'This email link is not valid';
      errorMessage.details = 'Contact your Administrator';
      break;
    }

    case 4119: {
      errorMessage.title =
        'This email link was already used, or, it has expired';
      errorMessage.details = 'Contact your Administrator';
      break;
    }

    default:
      break;
  }

  return errorMessage;
};

const ValidationErrors = () => {
  const busy = useSelector((state) => selector(state, VALIDATE));
  const link = useSelector(validateSelector);

  const { ran, success } = link;

  const error = ran && !success;
  const errorDetails = success ? '' : getError(error).details;
  const errorTitle = success ? '' : getError(error).title;

  return error ? (
    <>
      {busy && (
        <CardHeader
          title="Validating your email link..."
          titleTypographyProps={titleTypographyProps}
        />
      )}
      <CardHeader
        subheader={errorDetails}
        subheaderTypographyProps={subheaderTypographyProps}
        title={errorTitle}
        titleTypographyProps={titleTypographyProps}
      />
      <CardActions>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              component={Link}
              size="large"
              to={LOGIN_PATH}
              variant="contained"
            >
              Go to Login
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </>
  ) : null;
};

export default ValidationErrors;
