import Empty from 'components/common/Empty';
import PropTypes from 'prop-types';
import selector from 'selectors/notification';
import Table from 'components/common/table/table';
import { LOAD, load } from 'model/notification';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { isLoadingState } from 'reducers/loading';
import { pageConnect, pageHandlers, pageLifeCycle } from 'util/page';
import NotificationAction from './NotificationAction';

export const COLUMN_META = [
  {
    id: 'createdAtDerived',
    label: 'Date',
    sortId: 'createdAt',
  },
  {
    id: 'descriptionDerived',
    label: 'Description',
  },
  {
    capitalize: true,
    id: 'deliverMethodDerived',
    label: 'Delivery Method',
    sortId: 'deliverMethod',
  },
  {
    id: 'deliverToDerived',
    label: 'Deliver To',
  },
  {
    capitalize: true,
    id: 'msgStatusDerived',
    label: 'Status',
    sortId: 'msgStatus',
  },
];

const NotificationList = ({
  handleChange,
  items,
  order,
  orderBy,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  if (items.length) {
    return (
      <Table
        actions={[NotificationAction]}
        columnMeta={COLUMN_META}
        data={items}
        onChange={handleChange}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    );
  }
  return null;
};

NotificationList.propTypes = {
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default compose(
  connect(
    (state, props) => ({
      isExecuting: isLoadingState(state, LOAD.ACTION),
      items: selector(state, props),
    }),
    {
      clearAction: (props) => ({
        type: LOAD.SUCCESS,
        data: { props, response: [] },
      }),
      loadAction: load,
    }
  ),
  withProps(({ fspId, payerId }) => ({
    defaultPageSettings: { order: 'desc', orderBy: 'createdAt' },
    loadParams: { fspId, payerId },
    noSearch: true,
    settingType: 'notification',
  })),
  pageConnect,
  pageHandlers,
  pageLifeCycle,
  lifecycle({
    componentDidUpdate(prevProps) {
      const { payerId } = this.props;

      if (prevProps.payerId !== payerId) {
        const { handleSearch, searchBys } = this.props;

        handleSearch(searchBys);
      }
    },
  }),
  branch(
    ({ isExecuting, items }) => items.length === 0 && !isExecuting,
    renderComponent(() => (
      <Empty title="No Notifications or Alerts Available" />
    ))
  )
)(NotificationList);
