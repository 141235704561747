import selector from 'selectors/authToken';
import { LOAD, SET } from 'reducers/selectedOrgId';
import { SELECTED_ORG_ID_CODE } from 'consts';
import { head } from 'ramda';
import { put, select, takeEvery } from 'redux-saga/effects';
import { createActionSuccess, localStorage } from '../util';

export const KEY = SELECTED_ORG_ID_CODE;

/**
 * localStorage stores everything as an Array, but we only need a number;
 * All code dealing w/ Arrays is handled here
 */
export default function* saga() {
  yield takeEvery(LOAD.ACTION, load);
  yield takeEvery(SET.ACTION, set);

  function* load() {
    const user = yield select(selector);
    const data = yield head(localStorage.get(KEY, user?.id));

    yield put(createActionSuccess(LOAD)(data || 0));
  }

  function* set({ data }) {
    const user = yield select(selector);

    localStorage.clear(KEY, user?.id);
    yield put(createActionSuccess(SET)(localStorage.add(data, KEY, user?.id)));
  }
}
