import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class ProcessingWindow extends Model {
  static get modelName() {
    return 'ProcessingWindow';
  }

  static reducer({ type, data }, ProcessingWindow) {
    switch (type) {
      case ADD.SUCCESS:
        ProcessingWindow.upsert(data);
        break;

      case REMOVE.SUCCESS:
        ProcessingWindow.withId(data.id).delete();
        break;

      case LOAD.SUCCESS:
        ProcessingWindow.delete();
        data.forEach((r) => ProcessingWindow.upsert(r));
        break;

      case LOAD_ID.SUCCESS:
        ProcessingWindow.upsert(data);
        break;

      case UPDATE.SUCCESS:
        ProcessingWindow.update(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      a2aCutoffEasternTimezone: attr(),
      p2pCutoffEasternTimezone: attr(),
      bpCutoffEasternTimezone: attr(),
      bpExpeditedCutoffEasternTimezone: attr(),
      createdAt: attr(),
      description: attr(),
      href: attr(),
      id: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

export const name = ProcessingWindow.modelName;

export const ADD = createAddConst(name);
export const LOAD = createRequestConst(name);
export const LOAD_ID = createRequestConst(`${name}_id`);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const load = createAction(LOAD);
export const loadId = createAction(LOAD_ID);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
