import { compose, mapObjIndexed, pick, pickBy, toPairs } from 'ramda';
import { date, isNilOrEmpty } from '../../util';

export const INSIGHT_STATUS_ACCEPTED = 'accepted';
export const INSIGHT_STATUS_ACTIVE = 'active';
export const INSIGHT_STATUS_CLICKED = 'clicked';
export const INSIGHT_STATUS_DECLINED = 'declined';
export const INSIGHT_STATUS_EXPIRED = 'expired';

export const INSIGHT_ACCEPTED_AT = 'acceptedAt';
export const INSIGHT_ACTIVE_AT = 'activeAt';
export const INSIGHT_CLICKED_AT = 'clickedAt';
export const INSIGHT_DECLINED_AT = 'declinedAt';
export const INSIGHT_EXPIRED_AT = 'expiredAt';

export const insightStatus = {
  [INSIGHT_STATUS_ACCEPTED]: 'Accepted',
  [INSIGHT_STATUS_ACTIVE]: 'Active',
  [INSIGHT_STATUS_CLICKED]: 'Active',
  [INSIGHT_STATUS_DECLINED]: 'Declined',
  [INSIGHT_STATUS_EXPIRED]: 'Expired',
};

export const insightStatusDateMap = {
  [INSIGHT_ACCEPTED_AT]: 'Accepted Date',
  [INSIGHT_ACTIVE_AT]: 'Activated Date ',
  [INSIGHT_CLICKED_AT]: 'Clicked Date',
  [INSIGHT_DECLINED_AT]: 'Declined Date',
  [INSIGHT_EXPIRED_AT]: 'Expired Date',
};

export const getInsightStatusDates = (item) =>
  compose(
    toPairs,
    mapObjIndexed((val) => date(val)),
    pickBy((val) => !isNilOrEmpty(val)),
    pick([
      INSIGHT_ACTIVE_AT,
      INSIGHT_DECLINED_AT,
      INSIGHT_ACCEPTED_AT,
      INSIGHT_EXPIRED_AT,
    ])
  )(item);
