import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import scrollIntoView from 'scroll-into-view';
import {
  DelimitedArrayParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { EP, DEFAULT_ID, SHOW_CHILDREN_TIMEOUT } from 'consts';
import { always } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { upsert } from 'model/scrollRef';
import { useDispatch } from 'react-redux';
import Summary from './Summary';
import usePanelDetailStyles from './usePanelDetailStyles';

const useStyles = makeStyles((theme) => ({
  detailsVisible: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  disabled: {
    '&$disabled': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  div: {
    width: '100%',
  },
  notExpandable: {
    margin: 0,
  },
  root: {},
}));

/**
 * ExpandablePanel contain creation flows and allow lightweight editing of an element.
 *
 * @see  https://material.io/archive/guidelines/components/expansion-panels.html
 *
 * @param ExpansionPanelDetailsProps
 * @param children
 * @param defaultExpanded
 * @param endAdornment
 * @param expandable
 * @param fullHeight
 * @param heightOffset
 * @param icon
 * @param id
 * @param {boolean} [mountOnEnter=false] - By default the child component is mounted immediately along with
 * the parent Transition component. If you want to "lazy mount" the component on the first in={true} you can
 * set mountOnEnter. After the first enter - transition the component will stay mounted, even on "exited",
 * unless you also specify unmountOnExit.
 * @param name
 * @param onChange
 * @param scroll
 * @param scrollTopOffset
 * @param showExpandedBorder
 * @param showProgress
 * @param startAdornment
 * @param subTitle
 * @param title
 * @param {boolean} [unmountOnExit=true] - By default the child component stays mounted after it reaches the
 * 'exited' state. Set unmountOnExit if you'd prefer to unmount the component after it finishes exiting.
 * @param props
 *
 * @param props
 * @returns {function} ExpandablePanel
 */
const ExpandablePanel = ({
  ExpansionPanelDetailsProps,
  children,
  defaultExpanded,
  endAdornment,
  expandable,
  fullHeight,
  heightOffset,
  icon,
  id,
  mountOnEnter,
  name,
  onChange,
  scroll,
  scrollTopOffset,
  showExpandedBorder,
  showProgress,
  startAdornment,
  subTitle,
  title,
  unmountOnExit,
  ...props
}) => {
  const paramConfig = withDefault(
    DelimitedArrayParam,
    defaultExpanded && expandable ? [id] : undefined
  );
  const [expandedIds, setExpandedIds] = useQueryParam(name, paramConfig);
  const [initiallyExpanded, setInitiallyExpanded] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const expanded = !expandable || expandedIds?.indexOf(id) > -1;
  const panelDetailStyles = usePanelDetailStyles(fullHeight, heightOffset);
  const scrollRef = useRef();

  const handlePanelChange = useCallback(() => {
    const ids = expandedIds ? [...expandedIds] : [];
    const index = ids.indexOf(id);

    if (index === -1) {
      ids.push(id);

      if (scroll) {
        scrollIntoView(scrollRef.current, {
          align: { top: 0, topOffset: scrollTopOffset },
          isScrollable: (target, defaultIsScrollable) =>
            defaultIsScrollable(target) && !expanded,
          time: SHOW_CHILDREN_TIMEOUT,
        });
      }
    } else {
      ids.splice(index, 1);
    }

    onChange(!expanded);
    setExpandedIds(ids.length ? ids : undefined);
  }, [
    expanded,
    expandedIds,
    id,
    onChange,
    scroll,
    scrollTopOffset,
    setExpandedIds,
  ]);

  /**
   * We need to set the initial expanded state after the first render
   */
  useEffect(() => {
    if (initiallyExpanded === null) {
      if (expanded) {
        onChange(expanded);
      }

      setInitiallyExpanded(true);
    }
  }, [expanded, initiallyExpanded, onChange]);

  useEffect(() => {
    dispatch(upsert({ id, scrollRef }));
  }, [dispatch, id]);

  return (
    <Accordion
      {...props}
      classes={{
        disabled: classes.disabled,
        expanded: classNames({
          [classes.notExpandable]: !expandable,
          [classes.detailsVisible]: expanded && showExpandedBorder,
        }),
      }}
      className={classes.root}
      defaultExpanded={defaultExpanded}
      elevation={2}
      expanded={expanded}
      onChange={expandable ? handlePanelChange : undefined}
      TransitionProps={{ mountOnEnter, timeout: 0, unmountOnExit }}
    >
      <Summary
        endAdornment={endAdornment}
        expandable={expandable}
        expanded={expanded}
        icon={icon}
        id={id}
        scrollRef={scrollRef}
        showProgress={showProgress}
        startAdornment={startAdornment}
        subTitle={subTitle}
        title={title}
      />
      <AccordionDetails
        {...ExpansionPanelDetailsProps}
        style={panelDetailStyles}
      >
        <div className={classes.div}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

ExpandablePanel.propTypes = {
  ExpansionPanelDetailsProps: PropTypes.object,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  endAdornment: PropTypes.object,
  expandable: PropTypes.bool,
  fullHeight: PropTypes.bool,
  heightOffset: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  id: PropTypes.string,
  mountOnEnter: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  scroll: PropTypes.bool,
  scrollTopOffset: PropTypes.number,
  showProgress: PropTypes.bool,
  showExpandedBorder: PropTypes.bool,
  startAdornment: PropTypes.object,
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  unmountOnExit: PropTypes.bool,
};

ExpandablePanel.defaultProps = {
  ExpansionPanelDetailsProps: undefined,
  children: null,
  defaultExpanded: false,
  endAdornment: null,
  expandable: true,
  fullHeight: true,
  heightOffset: 0,
  icon: null,
  id: DEFAULT_ID,
  mountOnEnter: false,
  name: EP,
  onChange: always,
  scroll: true,
  scrollTopOffset: 0,
  showProgress: false,
  showExpandedBorder: false,
  startAdornment: null,
  subTitle: undefined,
  unmountOnExit: true,
};

export default memo(ExpandablePanel);
