import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class AiEvents extends Model {
  static get modelName() {
    return 'AiEvents';
  }

  static reducer({ type, data }, AiEvents) {
    switch (type) {
      case LOAD.SUCCESS:
        AiEvents.delete();
        data?.response?.forEach((r) => AiEvents.upsert(r));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      allUsersOffOn: attr(),
      callToAction1Action: attr(),
      callToAction1Button: attr(),
      callToAction2Action: attr(),
      callToAction2Button: attr(),
      createdAt: attr(),
      displayModule: attr(),
      eventIdStr: attr(),
      eventName: attr(),
      eventType: attr(),
      expireDays: attr(),
      expireOn: attr(),
      globalMessageOffOn: attr(),
      href: attr(),
      hrefUri: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUserId: attr(),
      messageBody: attr(),
      messageTitle: attr(),
      offset: attr(),
      updAdminUserId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = AiEvents.modelName;

export const LOAD = createRequestConst(name);

export const load = createAction(LOAD);
