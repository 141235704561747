import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.ActionInsightReduceMyBill);

export const selectorProps = createSelector(
  orm,
  (state, { fspId, payerId }) => ({ fspId, payerId }),
  ({ ActionInsightReduceMyBill }, { fspId, payerId }) =>
    ActionInsightReduceMyBill.filter(
      ({ fspId: aiFspId, payerId: aiPayerId }) =>
        aiFspId === fspId && aiPayerId === payerId
    ).toRefArray()
);
