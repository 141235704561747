import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ADD_CUSTOM, LOAD_CUSTOM as LOAD_RULES } from 'model/limitRule';
import { LOAD } from 'model/limit';
import { LOAD_ID } from 'model/payer';
import { LinearProgress, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selector as busySelector } from 'selectors/busy';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  cell: {
    borderBottom: 0,
    padding: 0,
  },
  hidden: {
    visibility: 'hidden',
  },
  progress: {
    height: 2,
  },
  visible: {
    visibility: 'visible',
  },
}));

const LimitsTable = ({ edit }) => {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const isBusyRuleAdd = useSelector((state) => busySelector(state, ADD_CUSTOM));
  const isBusyRules = useSelector((state) => busySelector(state, LOAD_RULES));
  const isBusyPayer = useSelector((state) => busySelector(state, LOAD_ID));

  useEffect(() => {
    let show = isBusy || isBusyRules || isBusyPayer;

    if (edit) {
      show = show || isBusyRuleAdd;
    }

    setVisible(show);
  }, [edit, isBusy, isBusyRules, isBusyPayer, isBusyRuleAdd]);

  return (
    <TableRow classes={{ root: classes.progress }}>
      <TableCell align="center" classes={{ root: classes.cell }} colSpan={6}>
        <LinearProgress
          classes={{ root: classes.progress }}
          className={classNames(classes.hidden, {
            [classes.visible]: visible,
          })}
        />
      </TableCell>
    </TableRow>
  );
};

LimitsTable.propTypes = {
  edit: PropTypes.bool.isRequired,
};

export default LimitsTable;
