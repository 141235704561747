import { Model, attr } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class FSPPaynet extends Model {
  static get modelName() {
    return 'FSPPaynet';
  }

  static reducer({ type, data }, FSPPaynet) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        FSPPaynet.create(data?.response);
        break;

      case LOAD.SUCCESS:
      case IMPORT.SUCCESS:
        if (data?.response) {
          FSPPaynet.delete();
          data.response.forEach((r) => FSPPaynet.create(r));
        }
        break;

      case REMOVE.SUCCESS:
        if (FSPPaynet.idExists(id)) {
          FSPPaynet.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        FSPPaynet.upsert(data.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      disburseType: attr(),
      displaySequence: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      offset: attr(),
      paynetId: attr(),
      requiredOffOn: attr(),
      transactionType: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = FSPPaynet.modelName;

export const ADD = createAddConst(name);
export const IMPORT = createImportConst(name);
export const LOAD = createRequestConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const importAction = createAction(IMPORT);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
