import { add, load, remove, update } from 'sdk/apiKey';
import { ADD, LOAD, REMOVE, UPDATE } from 'model/apiKey';
import { callAction, ORM } from 'util/index';
import { takeEvery } from 'redux-saga/effects';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      formatSuccessMessage: () => 'API Key was successfully added',
      type: ADD,
    })
  );
  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: () => 'API Key was updated',
      type: UPDATE,
    })
  );
  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: () => 'API Key was removed successfully',
      type: REMOVE,
    })
  );
}
