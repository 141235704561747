import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NA_VALUE } from 'consts';
import Text from './Text';
import ExpansionPanelTitleIcon from './ExpansionPanelTitleIcon';
import ExpansionPanelTitleEndAdornment from './ExpansionPanelTitleEndAdornment';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 48,
  },
}));

const ExpansionPanelTitle = ({
  Icon,
  endAdornment,
  expanded,
  subTitle,
  title,
}) => {
  const classes = useStyles();
  const loaded = typeof title !== 'object';

  return (
    <Grid alignItems="center" className={classes.root} container>
      <ExpansionPanelTitleIcon Icon={Icon} />
      <Text subTitle={subTitle} title={title} />
      <ExpansionPanelTitleEndAdornment
        endAdornment={endAdornment}
        expanded={expanded}
        loaded={loaded}
      />
    </Grid>
  );
};

ExpansionPanelTitle.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  endAdornment: PropTypes.object,
  expanded: PropTypes.bool,
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ExpansionPanelTitle.defaultProps = {
  Icon: null,
  endAdornment: null,
  expanded: true,
  subTitle: undefined,
  title: NA_VALUE,
};

export default ExpansionPanelTitle;
