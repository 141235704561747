import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { handleStopPropagation } from 'consts';

const InfoDialog = ({ message, onClose, open, title, ...props }) => {
  const handleClick = (event) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <Dialog
      aria-labelledby={title}
      fullWidth
      onClick={handleStopPropagation}
      onClose={onClose}
      open={open}
      {...props}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClick}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InfoDialog.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

InfoDialog.defaultProps = {
  message: '',
  title: 'Info',
};

export default InfoDialog;
