import MaskedInput from 'react-text-mask';
import { compose, mapProps, pure, withHandlers, withProps } from 'recompose';
import { withStyles } from '@material-ui/core';
import Field from './Field';
import { unmask } from './consts';

const idMask = [
  'P',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const styles = () => ({
  root: {},
});

const IDMask = compose(
  mapProps(({ inputRef, ...props }) => ({
    mask: idMask,
    placeholderChar: '\u000A',
    ...props,
  })),
  pure
)(MaskedInput);

export default compose(
  withStyles(styles),
  withHandlers({
    onChange:
      ({ onChange }) =>
      (event) =>
        onChange(unmask(event.target.value)),
  }),
  withProps(() => ({
    component: IDMask,
  })),
  pure
)(Field);
