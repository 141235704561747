import PropTypes from 'prop-types';
import { createContext, useContext, useState, useMemo } from 'react';

const Context = createContext(undefined);

const ExpansionListItemProvider = ({ children, item, options }) => {
  const [value, setValue] = useState({});

  const memoizedValue = useMemo(
    () => ({ item, options, value, setValue }),
    [item, options, value]
  );

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>;
};

ExpansionListItemProvider.propTypes = {
  children: PropTypes.node.isRequired,
  item: PropTypes.object.isRequired,
  options: PropTypes.object,
};

ExpansionListItemProvider.defaultProps = {
  options: null,
};

const useExpansionListItem = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useExpansionListItem must be used within a ExpansionListItemProvider'
    );
  }

  return context;
};

export { ExpansionListItemProvider, useExpansionListItem };
