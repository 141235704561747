import PropTypes from 'prop-types';
import EBillList from './EBillList';

const EBill = ({ fspId, payerId }) => {
  return <EBillList fspId={fspId} payerId={payerId} />;
};

EBill.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default EBill;
