import PropTypes from 'prop-types';
import { memo, useState, useEffect } from 'react';
import { Comment } from '@material-ui/icons';
import { LOAD, load, sortableFields } from 'model/serviceNotes';
import {
  Accordion,
  AccordionDetails,
  List,
  Typography,
} from '@material-ui/core';
import ExpandablePanelSummary from 'components/common/ExpandablePanel/Summary';
import { load as loadOrgs } from 'model/org';
import { makeStyles } from '@material-ui/core/styles';
import { selector as busySelector } from 'selectors/busy';
import { selector as orgSelector } from 'selectors/org';
import { selector } from 'selectors/serviceNote';
import { useDispatch, useSelector } from 'react-redux';
import NoteSortMenu from 'components/common/Note/NoteSortMenu';
import { NOTES } from 'components/modules/paymentResearch/consts';
import AddNote from './AddNote';
import Note from './Note';
import NoteDrawer from './NoteDrawer';

const emptyMessagePrefix = 'There are no notes for';
const title = 'Updates from Payrailz';

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
    marginTop: theme.spacing(1),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  list: {
    overflow: 'auto',
    maxHeight: '76vh',
    width: '100%',
  },
  progress: {
    height: 2,
    left: 0,
    top: 1,
    position: 'absolute',
    width: '100%',
  },
}));

const Notes = ({ fspId }) => {
  const [ok, setOk] = useState(false);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(sortableFields[0].value);
  const [shake, setShake] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const items = useSelector((state) => selector(state));
  const orgs = useSelector(orgSelector);

  const handleChange = (event, expanded) => {
    setExpanded(expanded);
  };

  const count = items.length > 1 ? `${items.length} ` : '';
  const emptyMessage = `${emptyMessagePrefix} ${'this payer'}.`;
  const isEmpty = items.length === 0;

  const handleAdd = (event) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = (event, ok) => {
    setOk(ok);
    setOpen(false);
  };

  useEffect(() => {
    if (ok) {
      setOk(false);
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 1000);
    }
  }, [items, ok, setOk, setShake]);

  useEffect(() => {
    if (fspId) {
      dispatch(load({ fspId, order }));
    }
  }, [dispatch, fspId, order]);

  useEffect(() => {
    if (orgs.length === 0) {
      dispatch(loadOrgs());
    }
  }, [dispatch, orgs.length]);

  return (
    <>
      <AccordionDetails style={{ marginTop: '-39.5px' }}>
        <List className={classes.list}>
          {items.map(({ id }, index) => (
            <Note key={id} fspId={fspId} id={id} index={index} shake={shake} />
          ))}
        </List>
      </AccordionDetails>
      <NoteDrawer fspId={fspId} onClose={handleClose} open={open} />
      <AddNote onAdd={handleAdd} />
    </>
  );
};

Notes.propTypes = {
  fspId: PropTypes.number,
};

Notes.defaultProps = {
  fspId: null,
};

export default memo(Notes);
