import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class ActionInsightEvent extends Model {
  static get modelName() {
    return 'ActionInsightEvent';
  }

  static reducer({ type, data }, ActionInsightEvent) {
    switch (type) {
      case LOAD.SUCCESS: {
        ActionInsightEvent.delete();
        if (data?.response) {
          data.response.forEach((event) => ActionInsightEvent.create(event));
        }
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      allUsersOffOn: attr(),
      createdAt: attr(),
      displayModule: attr(),
      eventName: attr(),
      expireOn: attr(),
      globalMessageOffOn: attr(),
      href: attr(),
      insAdminUserId: attr(),
      insightEventIdStr: attr(),
      insUserId: attr(),
      messageBody: attr(),
      messageTitle: attr(),
      offset: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
      updUserId: attr(),
    };
  }
}

export const LOAD = createRequestConst(ActionInsightEvent.modelName);
export const load = createAction(LOAD);
