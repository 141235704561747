import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { selectorId } from 'selectors/internalAccount';
import { load } from 'model/interrnalAccountAudit';
import {
  selectorCount,
  selectorGroupedByDays,
} from 'selectors/internalAccountAudit';
import Audit from 'components/common/Audit';
import AuditDetailRowRecord from 'components/common/Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from 'components/common/Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../../Audit/config/shared/AuditDrawerAppBarTitle';

const ActionsRenderer = ({ item }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Audit
          action={load}
          countSelector={selectorCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={item}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorGroupedByDays}
          selectorId={selectorId}
          Title={AuditDrawerAppBarTitle}
        />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
