import { Mask } from 'components/common';
import { unmask } from 'sdk/internalAccount';
import DetailsRender from './DetailsRender';
import StatusSelector from './DetailsRender/StatusSelector';

export { default as ActionsRenderer } from './ActionsRenderer';

const StatusRenderer = (status, account, _, options) => {
  return <StatusSelector item={account} options={options} />;
};

const MaskRenderer = (
  value,
  { accountSuffix, id, displayAccountNbrMaskLong },
  displayedAsContext,
  { deletedOnly, fspId }
) => {
  const handleAPI = () => unmask({ fspId, id }, deletedOnly);

  return (
    <Mask
      api={handleAPI}
      icon={displayedAsContext}
      unmaskSuffix={accountSuffix}
      value={displayAccountNbrMaskLong || value}
    />
  );
};

export const columnMeta = [
  {
    id: 'accountNameDerived',
    label: 'Name',
    xs: 4,
  },
  {
    capitalize: true,
    id: 'ddaTypeDerived',
    label: 'Account Type',
    xs: 2,
  },
  {
    context: true,
    id: 'accountNbrMask',
    label: 'Account Number',
    xs: 2,
    renderer: MaskRenderer,
  },
  {
    id: 'ddaStatus',
    label: 'Status',
    xs: 2,
    renderer: StatusRenderer,
  },
  {
    id: 'availBalanceDerived',
    label: 'Available Balance',
    xs: 2,
  },
];

export const defaultOrderBy = 'id';

export const detailsRender = (item, options) => (
  <DetailsRender item={item} options={options} />
);
