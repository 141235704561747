import { IconButton, Popover, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { useState } from 'react';
import classNames from 'classnames';
import { ENTER_DELAY } from 'sagas';
import OfferStatusDates from './OfferStatusDates';

const useStyles = makeStyles((theme) => {
  const transition = {
    duration: theme.transitions.duration.standard,
  };

  return {
    div: {
      marginTop: theme.spacing(0.5),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    label: {
      color: theme.palette.text.primary,
      font: 'inherit',
      fontFamily: theme.typography.fontFamily,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    },
    opened: {
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', transition),
    },
    closed: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', transition),
    },
  };
});

const ANCHOR_ORIGIN = { horizontal: 'right', vertical: 'center' };
const TRANSFORM_ORIGIN = { vertical: 'center', horizontal: 'left' };

const OfferStatus = ({ label, offerStatusDates }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const classes = useStyles();

  const handleTooltipClose = () => setTooltipOpen(false);
  const handleTooltipOpen = () => setTooltipOpen(!open);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(!open);

    if (tooltipOpen) {
      setTooltipOpen(false);
    }
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div className={classes.div}>
      <span className={classes.label}>{label}</span>
      <Tooltip
        disableFocusListener
        enterDelay={ENTER_DELAY}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={tooltipOpen}
        title="Show Status Dates"
      >
        <IconButton
          aria-label="Show Status Dates"
          data-testid="button"
          onClick={handleClick}
        >
          <ExpandMore
            className={classNames({
              [classes.closed]: !open,
              [classes.opened]: open,
            })}
          />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={ANCHOR_ORIGIN}
        onClose={handleClose}
        open={open}
        transformOrigin={TRANSFORM_ORIGIN}
      >
        <OfferStatusDates dates={offerStatusDates} />
      </Popover>
    </div>
  );
};

OfferStatus.propTypes = {
  label: PropTypes.string,
  offerStatusDates: PropTypes.array,
};

OfferStatus.defaultProps = {
  label: '',
  offerStatusDates: null,
};

export default OfferStatus;
