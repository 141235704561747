import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const TextFieldGeneric = ({ disabled, meta, isSubmitting, ...props }) => {
  return (
    <TextField
      autoComplete="off"
      disabled={disabled || isSubmitting}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      {...props}
    />
  );
};

TextFieldGeneric.propTypes = {
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

TextFieldGeneric.defaultProps = {
  disabled: false,
  meta: {},
  isSubmitting: false,
};

export default TextFieldGeneric;
