import PropTypes from 'prop-types';
import { always, isNil } from 'ramda';
import { Grid } from '@material-ui/core';
import { ParamSearchType } from 'components/common/SearchTextField/Multiple/consts';
import { useState, useRef } from 'react';
import ParamField from './ParamField';
import ParamValueText from './ParamValueText';
import ParamValueSelect from './ParamValueSelect';
import ParamValueDate from './ParamValueDate';
import ParamValueAmount from './ParamValueAmount';
import ParamValueNumber from './ParamValueNumber';
import ParamValueNumberRange from './ParamValueNumberRange';
import ParamValueBoolean from './ParamValueBoolean';
import ParamValueRadioWithSelect from './ParamValueRadioWithSelect';

const getParam = (searchParams, paramField) => {
  return searchParams.find(({ field }) => field === paramField);
};

const ParamValues = {
  [ParamSearchType.Amount]: ParamValueAmount,
  [ParamSearchType.Boolean]: ParamValueBoolean,
  [ParamSearchType.String]: ParamValueText,
  [ParamSearchType.Date]: ParamValueDate,
  [ParamSearchType.Number]: ParamValueNumber,
  [ParamSearchType.NumberRange]: ParamValueNumberRange,
  [ParamSearchType.Option]: ParamValueSelect,
  [ParamSearchType.MultiOption]: ParamValueSelect,
  [ParamSearchType.RadioWithOption]: ParamValueRadioWithSelect,
};

const Param = ({
  disabled,
  onKeyPress,
  onChange,
  param,
  searchParams,
  ...props
}) => {
  const [currentParam, setCurrentParam] = useState(param);
  const valueInputRef = useRef();

  const handleParamFieldChange = (event) => {
    const field = event.target.value;
    const param = getParam(searchParams, field);
    const latestParam = {
      ...param,
      field,
    };

    setCurrentParam(latestParam);
    onChange(latestParam);

    // The setTimeout seems to be necessary since
    // React seems to be doing something afterwards that makes
    // the input lose its focus
    setTimeout(() => {
      if (valueInputRef.current) {
        valueInputRef.current.focus();
      }
    }, 0);
  };
  const handleParamValueChange = (event, autocompleteValue) => {
    const value =
      autocompleteValue && currentParam.getValue
        ? currentParam.getValue(autocompleteValue)
        : event.target.value;

    const latestParam = {
      ...currentParam,
      originalValue: autocompleteValue ?? event.target.value,
      value,
    };

    setCurrentParam(latestParam);
    onChange(latestParam);
  };

  const component = currentParam?.component;
  const componentProps = currentParam?.componentProps ?? null;
  const type = currentParam?.type ?? ParamSearchType.String;
  const ParamValue = component || (ParamValues[type] ?? ParamValueText);

  return (
    <Grid
      alignItems="flex-start"
      container
      justifyContent="center"
      spacing={2}
      wrap="nowrap"
    >
      <Grid item xs={5}>
        <ParamField
          {...props}
          disabled={disabled}
          onChange={handleParamFieldChange}
          searchParams={searchParams}
          value={currentParam?.field}
        />
      </Grid>
      <Grid item xs={7}>
        <ParamValue
          {...componentProps}
          disabled={isNil(currentParam)}
          inputRef={valueInputRef}
          onChange={handleParamValueChange}
          onKeyPress={onKeyPress}
          options={currentParam?.valueOptions}
          searchParam={currentParam}
          value={currentParam?.value}
        />
      </Grid>
    </Grid>
  );
};

Param.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  param: PropTypes.object,
  searchParams: PropTypes.arrayOf(PropTypes.object),
};

Param.defaultProps = {
  disabled: false,
  onKeyPress: always,
  param: undefined,
  searchParams: null,
};

export default Param;
