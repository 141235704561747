import Date from 'components/common/fields/Date';
import PropTypes from 'prop-types';
import useFieldWithErrors from 'components/common/formik/hooks/useFieldWithErrors';
import { useFormikContext } from 'formik';

const DateField = ({ disabled, format, name, ...props }) => {
  const [field, , helpers, errorProps] = useFieldWithErrors(name);
  const { isSubmitting } = useFormikContext();

  const onChange = (date) => {
    if (!date) {
      helpers.setTouched(true);
      helpers.setValue(null);
    }
    if (date?.isValid()) {
      const value = date.format(format ?? 'YYYY-MM-DD');

      helpers.setValue(value);
    }
  };

  return (
    <Date
      {...field}
      {...props}
      {...errorProps}
      disabled={disabled || isSubmitting}
      onChange={onChange}
      format={format}
    />
  );
};

DateField.propTypes = {
  disabled: PropTypes.bool,
  format: PropTypes.string,
  name: PropTypes.string.isRequired,
};

DateField.defaultProps = {
  disabled: false,
  format: undefined,
};

export default DateField;
