import Input from '@material-ui/core/Input';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { getValue, hasValue } from './consts';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  input: {
    color: 'inherit',
    textOverflow: 'ellipsis',
  },
  inputBase: {
    lineHeight: '1.1875em',
    paddingTop: 6,
    paddingBottom: 7,
  },
  skeleton: {
    marginTop: theme.spacing(2.5),
  },
}));

const ReadOnlyInput = ({
  capitalize,
  inputNode,
  inputCustomizedClasses,
  isDate,
  label,
  multiline,
  value,
  endAdornment,
}) => {
  const classes = useStyles();
  const formattedValue = getValue(value, isDate);
  const isSkeleton = typeof value === 'object';
  const isNode = inputNode ? true : isSkeleton;
  const theNode = inputNode || value;

  const disabled = value === undefined;
  const inputClasses = {
    input: classNames(classes.input, { [classes.capitalize]: capitalize }),
    ...inputCustomizedClasses,
  };
  const inputPropsSet = {
    'aria-label': hasValue(label) ? label : 'readOnly',
  };

  return isNode && hasValue(value) ? (
    <div className={classes.inputBase}>{isSkeleton ? value : theNode}</div>
  ) : disabled ? (
    <p className={classes.skeleton} data-testid="skeleton">
      <Skeleton width={label.length * 5} />
    </p>
  ) : (
    <Input
      classes={inputClasses}
      disableUnderline
      endAdornment={endAdornment}
      fullWidth
      inputProps={inputPropsSet}
      maxRows={2}
      minRows={2}
      multiline={multiline}
      readOnly
      value={formattedValue}
    />
  );
};

ReadOnlyInput.propTypes = {
  capitalize: PropTypes.bool,
  inputCustomizedClasses: PropTypes.object,
  inputNode: PropTypes.node,
  isDate: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  endAdornment: PropTypes.node,
};

ReadOnlyInput.defaultProps = {
  capitalize: false,
  inputCustomizedClasses: null,
  inputNode: null,
  isDate: false,
  label: '',
  multiline: false,
  value: undefined,
  endAdornment: null,
};

export default ReadOnlyInput;
