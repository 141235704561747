import { formatCurrency } from '../../util';

// eslint-disable-next-line import/prefer-default-export
export const addDerived = ({ ...values } = {}) => {
  values.feeTransactOnBehalfDerived = formatCurrency(
    values?.feeTransactOnBehalf
  );
  return {
    ...values,
  };
};
