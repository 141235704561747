import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';
import { EMPTY_CODE } from 'consts';
import { isNilOrEmpty } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';
import { useField, useFormikContext } from 'formik';
import { always } from 'ramda';

const getValue = (field, required) => {
  const value = field?.value;

  if (value === null && required === false) {
    return EMPTY_CODE;
  }

  return value ?? '';
};

const menuItems = (items) => {
  return items?.map((r) => (
    <MenuItem key={r} value={r}>
      {r}
    </MenuItem>
  ));
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Select = ({
  InputLabelProps,
  MenuItems,
  autoComplete,
  disabled: disabledProp,
  helperText,
  items,
  label,
  name,
  onChange,
  required,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { isSubmitting } = useFormikContext();
  const classes = useStyles();

  const disabled = disabledProp || isSubmitting;
  const error = meta?.touched && !isNilOrEmpty(meta?.error);
  const value = getValue(field, required);

  const handleChange = (event) => {
    const value =
      event?.target?.value === EMPTY_CODE ? null : event.target.value;

    helpers.setValue(value);
    onChange(event);
  };

  return (
    <FormControl
      className={classes.root}
      disabled={disabled}
      error={error}
      required={required}
    >
      <InputLabel {...InputLabelProps}>{label}</InputLabel>
      <MuiSelect
        {...props}
        autoComplete={autoComplete}
        disabled={disabled}
        name={name}
        onBlur={field.onBlur}
        onChange={handleChange}
        value={value}
      >
        {required === false && field.value !== undefined && (
          <MenuItem value={EMPTY_CODE}>
            <em>No Value</em>
          </MenuItem>
        )}
        {MenuItems ? MenuItems(items) : menuItems(items)}
      </MuiSelect>
      {(error || helperText) && (
        <FormHelperText disabled={disabled} error={error}>
          {meta?.error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  InputLabelProps: PropTypes.object,
  MenuItems: PropTypes.func,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

Select.defaultProps = {
  InputLabelProps: undefined,
  MenuItems: undefined,
  autoComplete: 'off',
  disabled: false,
  helperText: undefined,
  items: undefined,
  onChange: always,
  required: undefined,
};

export default Select;
