import { takeEvery } from 'redux-saga/effects';
import { loadGroupAdmins, addGroupAdmin } from 'sdk/group_admin';
import { LOAD, ADD } from 'reducers/group_admin';
import { callAction } from '../util';

export const addGroupAdminCall = () =>
  callAction({ type: ADD, api: addGroupAdmin, dataName: 'groupAdmin' });

function* onGroupAdminSaga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({ type: LOAD, api: loadGroupAdmins, dataName: 'groupAdmins' })
  );
}

export default onGroupAdminSaga;
