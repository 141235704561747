import { amber } from '@material-ui/core/colors';
import { Grid, Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEventListener } from 'hooks';
import { useState } from 'react';
import { WifiOff } from '@material-ui/icons';
import SlideUp from '../SlideUp';

const anchorOrigin = { horizontal: 'left', vertical: 'bottom' };
const text = 'You are not connected to the internet';

const message = (
  <Grid alignItems="center" container spacing={2}>
    <Grid item>
      <WifiOff />
    </Grid>
    <Grid item>{text}</Grid>
  </Grid>
);

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: amber[400],
    color: theme.palette.text.primary,
  },
}));

const Offline = () => {
  const [offline, setOffline] = useState(false);
  const classes = useStyles();

  const handleOfflineEvent = () => {
    setOffline(true);
  };
  const handleOnlineEvent = () => {
    setOffline(false);
  };

  useEventListener('offline', handleOfflineEvent);
  useEventListener('online', handleOnlineEvent);

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={offline}
      TransitionComponent={SlideUp}
    >
      <SnackbarContent classes={{ root: classes.warning }} message={message} />
    </Snackbar>
  );
};

export default Offline;
