import MuiPopover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { cloneElement, useState, useEffect } from 'react';

const anchorOrigin = { horizontal: 'center', vertical: 'bottom' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

const Popover = ({
  anchorEl: anchorElProp,
  anchorOrigin,
  children,
  onClose,
  open: openProp,
  transformOrigin,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(anchorElProp);
  const [open, setOpen] = useState(openProp);

  const handleClose = (event) => {
    event.stopPropagation();

    setAnchorEl(null);
    setOpen(false);
    onClose(event);
  };

  useEffect(() => {
    setAnchorEl(anchorElProp);
  }, [anchorElProp]);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  return (
    <MuiPopover
      {...props}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={handleClose}
      open={open}
      transformOrigin={transformOrigin}
    >
      {children && cloneElement(children, { onClose: handleClose })}
    </MuiPopover>
  );
};

Popover.propTypes = {
  anchorEl: PropTypes.object,
  anchorOrigin: PropTypes.object,
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  transformOrigin: PropTypes.object,
};

Popover.defaultProps = {
  anchorEl: null,
  anchorOrigin,
  children: null,
  onClose: always,
  open: false,
  transformOrigin,
};

export default Popover;
