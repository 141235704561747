import { takeEvery } from 'redux-saga/effects';
import load from 'sdk/userFspConfig';
import { LOAD_USER_FSP_CONFIG } from 'model/userFspConfig';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD_USER_FSP_CONFIG.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD_USER_FSP_CONFIG,
    })
  );
}
