import { ADD, IMPORT, LOAD, REMOVE, UPDATE } from 'model/limit';
import { add, importAndReset, load, remove, update } from 'sdk/limit';
import { callAction, ORM } from 'util/index';
import { takeEvery } from 'redux-saga/effects';
import { loadFSPProfileAction } from './export';

const formatSuccessMessage = (str) => {
  return ({ response }) => {
    const { type } = response;

    return `Limit "${type}" was successfully ${str}`;
  };
};

function* addAndPopulateExport(action) {
  const addAction = callAction({
    api: add,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage('added'),
    type: ADD,
  });

  if (yield addAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
export const importLimits = () =>
  callAction({
    api: importAndReset,
    dataName: ORM,
    formatSuccessMessage: () => 'Limit(s) were imported',
    type: IMPORT,
  });
function* importAndPopulateExport(action) {
  const importAndResetAction = importLimits();

  if (yield importAndResetAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* removeAndPopulateExport(action) {
  const removeAction = callAction({
    api: remove,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage('removed'),
    type: REMOVE,
  });

  if (yield removeAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: formatSuccessMessage('updated'),
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(ADD.ACTION, addAndPopulateExport);

  yield takeEvery(IMPORT.ACTION, importAndPopulateExport);

  yield takeEvery(REMOVE.ACTION, removeAndPopulateExport);

  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);
}
