import PropTypes from 'prop-types';
import { InputAdornment, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EndAdornmentButton from './EndAdornmentButton';

const useStyles = makeStyles(() => ({
  bankName: {
    marginTop: 2,
  },
}));

const EndAdornment = ({ bankName, disabled, onClick, showButton }) => {
  const classes = useStyles();

  return (
    <InputAdornment position="end">
      <Typography className={classes.bankName} variant="caption">
        {bankName}
      </Typography>
      {showButton && (
        <EndAdornmentButton disabled={disabled} onClick={onClick} />
      )}
    </InputAdornment>
  );
};

EndAdornment.propTypes = {
  bankName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired,
};

export default EndAdornment;
