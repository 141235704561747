import { isNil } from 'ramda';
import { useParams } from 'react-router-dom';

const useFSPId = (fspIdArg, allowUndefined = false) => {
  const params = useParams();
  const idStr = params?.fspId;

  if (allowUndefined && isNil(fspIdArg) && idStr === undefined) {
    return undefined;
  }

  return fspIdArg ?? Number.parseInt(idStr, 10);
};

export default useFSPId;
