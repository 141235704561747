import { attr, Model } from 'redux-orm';
import { update } from 'ramda';
import {
  createAction,
  createDeleteConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export default class EBillAccount extends Model {
  static get modelName() {
    return 'EBillAccount';
  }

  static reducer({ type, data }, EBillAccount) {
    if (type === LOAD.SUCCESS && data.response) {
      EBillAccount.upsert(data.response);
    }
    if (type === DELETE_STATEMENT.SUCCESS && data.response) {
      const { payerEbillerAccountId, id: statementId } = data.response;
      const ebillerAccount = EBillAccount.withId(payerEbillerAccountId);
      ebillerAccount.statements = ebillerAccount.statements.filter(
        ({ id }) => id !== statementId
      );
      EBillAccount.upsert(ebillerAccount);
    }

    if (type === UPDATE_STATEMENT.SUCCESS && data.response) {
      const { payerEbillerAccountId, id: statementId } = data.response;
      const ebillerAccount = EBillAccount.withId(payerEbillerAccountId);
      const statementIndex = ebillerAccount.statements.findIndex(
        ({ id }) => id === statementId
      );
      ebillerAccount.statements = update(
        statementIndex,
        data.response,
        ebillerAccount.statements
      );
      EBillAccount.upsert(ebillerAccount);
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      amountDue: attr(),
      documentKey: attr(),
      dueOn: attr(),
      id: attr(),
      minimumAmount: attr(),
      outstandingBalance: attr(),
      statements: attr(),
    };
  }
}

export const LOAD = createLoadConst(EBillAccount.modelName);
export const DELETE_STATEMENT = createDeleteConst(
  `${EBillAccount.modelName}_STATEMENT`
);
export const UPDATE_STATEMENT = createUpdateConst(
  `${EBillAccount.modelName}_STATEMENT`
);

export const load = createAction(LOAD);
export const deleteStatement = createAction(DELETE_STATEMENT);
export const updateStatement = createAction(UPDATE_STATEMENT);
