import { attr, Model } from 'redux-orm';
import { getLogoSrc } from '../consts';
import {
  createAction,
  createAddConst,
  createLoadConst,
  createUpdateConst,
  date,
} from '../util';

const derived = (item) => ({
  ...item,
  createdAtLabel: date(item?.createdAt),
  updatedAtLabel: date(item?.updatedAt),
  billerLogo: getLogoSrc(item?.billerLogo),
});

export default class EBiller extends Model {
  static get modelName() {
    return 'EBiller';
  }

  static reducer({ type, data }, EBiller) {
    switch (type) {
      case CREATE.SUCCESS:
        EBiller.create(derived(data.response));
        break;

      case LOAD.SUCCESS: {
        EBiller.delete();
        if (data.response) {
          data.response.forEach((item) => {
            EBiller.create(derived(item));
          });
        }
        break;
      }

      case LOAD.FAILURE:
        EBiller.delete();
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        EBiller.upsert(derived(data.response));
        break;

      case LOAD_SET.SUCCESS:
      case UPDATE_SET.SUCCESS: {
        // Only take first set because there should only be one set per eBiller
        const {
          ebillernetebillerId: eBillerId,
          id: ebillernetEbillerBillerId,
          billersetId,
          billerSetName,
        } = type === LOAD_SET.SUCCESS
          ? (data?.response || [])[0] || {}
          : data?.response || {};

        if (eBillerId && EBiller.idExists(eBillerId)) {
          const eBiller = EBiller.withId(eBillerId).ref;
          EBiller.upsert(
            derived({
              ...eBiller,
              ebillernetEbillerBillerId,
              setId: billersetId,
              setName: billerSetName,
            })
          );
        }

        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      billerLogo: attr(),
      billerLogoId: attr(),
      billerSetBillerCount: attr(),
      createdAt: attr(),
      createdAtLabel: attr(),
      credentialFieldList: attr(),
      ebillernetEbillerBillerId: attr(), // derived (id of junction table)
      ebillernetId: attr(),
      ebillernetName: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      name: attr(),
      netIdStr: attr(),
      setId: attr(), // derived
      setName: attr(), // derived
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
      updUserId: attr(),
    };
  }
}

const name = EBiller.modelName;

export const CREATE = createAddConst(name);
export const LOAD = createLoadConst(name);
export const LOAD_ID = createLoadConst(`${name}_id`);
export const LOAD_SET = createLoadConst(`${name}_set`);
export const UPDATE = createUpdateConst(`${name}`);
export const UPDATE_SET = createUpdateConst(`${name}_set`);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const loadSet = createAction(LOAD_SET);
export const update = createAction(UPDATE);
export const updateSet = createAction(UPDATE_SET);
