import PropTypes from 'prop-types';
import Empty from 'components/common/Empty';
import Table from 'components/common/table/table';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { isLoadingState } from 'reducers/loading';
import { pageConnect, pageHandlers, pageLifeCycle } from 'util/page';

const TableConnected = ({
  handleChange,
  items,
  order,
  orderBy,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  columnMeta,
}) => {
  if (items.length) {
    return (
      <Table
        columnMeta={columnMeta}
        data={items}
        onChange={handleChange}
        order={order}
        orderBy={orderBy}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    );
  }
  return null;
};

TableConnected.propTypes = {
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  columnMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ({
  actionType,
  selector,
  successType,
  loadAction,
  noSearch = true,
  order,
  orderBy,
  settingType,
  emptyMessage,
  columnMeta,
}) =>
  compose(
    connect(
      (state, props) => ({
        isExecuting: isLoadingState(state, actionType),
        items: selector(state, props),
      }),
      {
        clearAction: (props) => ({
          type: successType,
          data: { props, response: [] },
        }),
        loadAction,
      }
    ),
    withProps(({ fspId, payerId }) => ({
      defaultPageSettings: { order, orderBy },
      loadParams: { fspId, payerId },
      noSearch,
      settingType,
      columnMeta,
    })),
    pageConnect,
    pageHandlers,
    pageLifeCycle,
    lifecycle({
      componentDidUpdate(prevProps) {
        const { payerId } = this.props;

        if (prevProps.payerId !== payerId) {
          const { handleSearch, searchBys } = this.props;

          handleSearch(searchBys);
        }
      },
    }),
    branch(
      ({ isExecuting, items }) => items.length === 0 && !isExecuting,
      renderComponent(() => <Empty title={emptyMessage} />)
    )
  )(TableConnected);
