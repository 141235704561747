import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { ENTER_DELAY } from 'sagas';
import { makeStyles } from '@material-ui/core/styles';
import { getIconAndTitle } from './consts';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(1),
  },
}));

const TransactionIcon = ({ disburseType, p2pDirectOffOn, type }) => {
  const classes = useStyles();
  const { Icon, title } = getIconAndTitle(disburseType, p2pDirectOffOn, type);

  return (
    <Tooltip enterDelay={ENTER_DELAY} title={title}>
      <Icon className={classes.icon} />
    </Tooltip>
  );
};

TransactionIcon.propTypes = {
  disburseType: PropTypes.string,
  p2pDirectOffOn: PropTypes.string,
  type: PropTypes.string.isRequired,
};

TransactionIcon.defaultProps = {
  disburseType: null,
  p2pDirectOffOn: null,
};

export default TransactionIcon;
