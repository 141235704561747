import { attr, Model } from 'redux-orm';
import { createAction, createAddConst } from '../util';

export default class ScrollRef extends Model {
  static get modelName() {
    return 'ScrollRef';
  }

  static reducer({ type, data }, ScrollRef) {
    switch (type) {
      case UPSERT.ACTION:
        ScrollRef.upsert(data);
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      id: attr(),
      scrollRef: attr(),
    };
  }
}

export const UPSERT = createAddConst(ScrollRef.modelName);

export const upsert = createAction(UPSERT);
