import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';
import {
  BP_EXPEDITE_FEE_AMOUNT,
  FSPS,
  FSP_PAYER_TYPE,
  FSP_PAYER_TYPES,
  OTP_HIGH_RISK_BILL_PAY_AMOUNT,
  OTP_HIGH_RISK_P2P_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT,
  OTP_HIGH_RISK_TRANSFER_TO_AMOUNT,
  P2P_REALTIME_FEE_AMOUNT,
  RESET_PAYER_TYPE,
} from 'consts';
import { evolve, pick } from 'ramda';
import { PayerTypeFormFields } from 'model/payerType';
import { convertToNumberEmptyAsNull } from 'util/general';

const transformation = {
  [BP_EXPEDITE_FEE_AMOUNT]: convertToNumberEmptyAsNull,
  [P2P_REALTIME_FEE_AMOUNT]: convertToNumberEmptyAsNull,
  [OTP_HIGH_RISK_BILL_PAY_AMOUNT]: convertToNumberEmptyAsNull,
  [OTP_HIGH_RISK_TRANSFER_TO_AMOUNT]: convertToNumberEmptyAsNull,
  [OTP_HIGH_RISK_TRANSFER_FROM_AMOUNT]: convertToNumberEmptyAsNull,
  [OTP_HIGH_RISK_P2P_AMOUNT]: convertToNumberEmptyAsNull,
};

const transform = (data) => {
  return evolve(transformation, data);
};

export const add = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${FSP_PAYER_TYPES}`, {
    data: transform(data),
    queryParams: { adminUserId: auth.id },
    returnModel: { name: FSP_PAYER_TYPE },
  });

export const load = ({ fspId }) =>
  client.executeRequest(GET, `/${FSPS}/${fspId}/${FSP_PAYER_TYPES}`, {
    returnModel: {
      name: FSP_PAYER_TYPE,
    },
  });

export const remove = ({ id, fspId }, auth) =>
  client.executeRequest(DELETE, `/${FSPS}/${fspId}/${FSP_PAYER_TYPES}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel: { name: FSP_PAYER_TYPE },
  });

export const update = ({ id, fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${FSP_PAYER_TYPES}/${id}`, {
    data: transform(pick(Object.values(PayerTypeFormFields), data)),
    queryParams: { adminUserId: auth.id },
    returnModel: { name: FSP_PAYER_TYPE },
  });

export const importAndReset = ({ fspId, fsppayertype }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${RESET_PAYER_TYPE}`, {
    data: {
      [FSP_PAYER_TYPE]: fsppayertype.map((r) =>
        transform(pick(Object.values(PayerTypeFormFields), r))
      ),
    },
    queryParams: { adminUserId: auth.id },
    returnModel: { name: FSP_PAYER_TYPE },
  });
