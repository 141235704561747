import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import PdfDialogContent from './PdfDialogContent';
import PdfDialogTitle, { scaleMin, scaleStep } from './PdfDialogTitle';

const PdfDialog = ({
  accountNumberMask,
  billOn,
  blob,
  name,
  onClose,
  open,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [scale, setScale] = useState(scaleMin);

  const handleFitToWidth = () => {
    setScale(scaleMin);
  };
  const handleLoadSuccess = (document) => {
    setNumberOfPages(document.numPages);
  };
  const handleZoomIn = () => {
    setScale(scale + scaleStep);
  };
  const handleZoomOut = () => {
    setScale(scale - scaleStep);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <PdfDialogTitle
        accountNumberMask={accountNumberMask}
        billOn={billOn}
        name={name}
        onClose={onClose}
        onFitToWidth={handleFitToWidth}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        scale={scale}
      />
      <PdfDialogContent
        blob={blob}
        numberOfPages={numberOfPages}
        onLoadSuccess={handleLoadSuccess}
        scale={scale}
      />
    </Dialog>
  );
};

PdfDialog.propTypes = {
  accountNumberMask: PropTypes.string.isRequired,
  billOn: PropTypes.string.isRequired,
  blob: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PdfDialog;
