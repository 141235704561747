import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { head } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { isNilOrEmpty } from 'util/index';

const useStyles = makeStyles((theme) => ({
  button: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(3),
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
}));

const EndAdornmentSelect = ({ onChange, onClose, param, searchParams }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [buttonText, setButtonText] = useState(head(searchParams).text);
  const classes = useStyles();

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    onClose(event);
  };
  const handleMenuItemClick = (param) => {
    setAnchorEl(null);
    onChange(param);
  };

  useEffect(() => {
    const text = param?.text;
    if (!isNilOrEmpty(text) && text !== buttonText) {
      setButtonText(text);
    }
  }, [buttonText, param]);

  return (
    <>
      <Button className={classes.button} onClick={handleButtonClick}>
        {buttonText}
        <ArrowDropDown />
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
        {searchParams.map((searchParam) => (
          <MenuItem
            key={searchParam.text}
            onClick={() => handleMenuItemClick(searchParam)}
            selected={buttonText === searchParam.text}
          >
            {searchParam.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

EndAdornmentSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  param: PropTypes.object,
  searchParams: PropTypes.arrayOf(PropTypes.object).isRequired,
};

EndAdornmentSelect.defaultProps = {
  param: null,
};

export default EndAdornmentSelect;
