import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import classNames from 'classnames';
import scrollIntoView from 'scroll-into-view';
import { SCROLL_REF_KEY } from 'util/format';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionListItemExpanded from './ExpansionListItemExpanded';
import ExpansionListItemRow from './ExpansionListItemRow';
import { ExpansionListItemColumnProvider } from './ExpansionListItemColumnContext';

const TransitionProps = { timeout: 0 };

const scroll = (ref) => ref && scrollIntoView(ref.current);

const useStyles = makeStyles((theme) => ({
  detailsVisible: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  hover: {
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.07)'
          : 'rgba(255, 255, 255, 0.14)',
    },
  },
  panelExpanded: {
    margin: '12px 0',
  },
  panelSummaryRoot: {
    minHeight: `${theme.spacing(3)}px`,
  },
  panelSummaryContent: {
    margin: '6px 0',
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  panelSummaryContentVisible: {
    margin: '6px 0',
  },
  summaryDetailsVisible: {
    borderBottom: '1px solid #d3d4d8',
  },
}));

const ExpansionListItem = ({
  ActionsRenderer,
  columnMeta,
  detailsRender,
  expanded,
  hover,
  idMeta,
  onListItemChange,
  renderOptions,
  row,
}) => {
  const classes = useStyles();
  const id = row[idMeta];

  const handleChange = useCallback(
    (event, inExpanded) => {
      event.stopPropagation();
      scroll(row[SCROLL_REF_KEY]);
      onListItemChange(id, inExpanded);
    },
    [id, onListItemChange, row]
  );

  return (
    <Accordion
      classes={{ expanded: classes.panelExpanded }}
      className={classNames({ [classes.detailsVisible]: expanded })}
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={TransitionProps}
    >
      <AccordionSummary
        classes={{
          root: classNames({
            [classes.panelSummaryRoot]: expanded,
            [classes.hover]: hover,
          }),
          content: classNames(classes.panelSummaryContent, {
            [classes.panelSummaryContentVisible]: expanded,
          }),
        }}
        className={classNames({
          [classes.summaryDetailsVisible]: expanded,
        })}
      >
        <div ref={row[SCROLL_REF_KEY]} />
        <Grid
          alignContent="space-between"
          alignItems="center"
          container
          spacing={1}
        >
          {columnMeta.map(({ id: columnId, capitalize, xs, ...column }) => (
            <ExpansionListItemColumnProvider
              key={`${id}${columnId}`}
              columnId={columnId}
              renderOptions={renderOptions}
              row={row}
              {...column}
            >
              <ExpansionListItemRow
                capitalize={capitalize}
                visible={expanded}
                xs={xs}
              />
              <ExpansionListItemExpanded visible={expanded} {...column} />
            </ExpansionListItemColumnProvider>
          ))}
          {expanded && ActionsRenderer && (
            <Grid item xs>
              <ActionsRenderer item={row} options={renderOptions} />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      {expanded && (
        <AccordionDetails>{detailsRender(row, renderOptions)}</AccordionDetails>
      )}
    </Accordion>
  );
};

ExpansionListItem.propTypes = {
  ActionsRenderer: PropTypes.elementType,
  columnMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
  detailsRender: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
  hover: PropTypes.bool,
  idMeta: PropTypes.string.isRequired,
  onListItemChange: PropTypes.func.isRequired,
  renderOptions: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};

ExpansionListItem.defaultProps = {
  ActionsRenderer: undefined,
  expanded: false,
  hover: false,
};

export default memo(ExpansionListItem);
