import { Grid } from '@material-ui/core';
import FilterByUsersAutocomplete from './FilterByUsersAutocomplete';
import GenerateButton from './GenerateButton';

const EndAdornment = (props) => {
  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item>
        <FilterByUsersAutocomplete {...props} />
      </Grid>
      <Grid item>
        <GenerateButton {...props} />
      </Grid>
    </Grid>
  );
};

export default EndAdornment;
