import PropTypes from 'prop-types';
import Skeleton from 'components/common/TextSkeleton';
import { Mask } from 'components/common';
import { ReadOnlyFormControl } from 'components/common/fields';
import { loadById } from 'model/internalAccount';
import { selectorId } from 'selectors/internalAccount';
import { unmaskAchNumber } from 'sdk/internalAccount';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const AutopayPalPayFrom = ({ ddaId, fspId, payerId }) => {
  const dispatch = useDispatch();
  const { accountNbrMask, ddaType } =
    useSelector((state) => selectorId(state, { payerId, id: ddaId })) || {};

  useEffect(() => {
    if (ddaId && !accountNbrMask) {
      dispatch(loadById({ fspId, id: ddaId }));
    }
  }, [dispatch, accountNbrMask, ddaId, fspId]);

  return (
    <ReadOnlyFormControl
      inputNode={
        accountNbrMask ? (
          <Mask
            api={() => unmaskAchNumber({ fspId, id: ddaId })}
            prefix={ddaType}
            value={accountNbrMask}
          />
        ) : (
          <Skeleton />
        )
      }
      label="Pay From Account"
      value={accountNbrMask || <Skeleton />}
    />
  );
};

AutopayPalPayFrom.propTypes = {
  ddaId: PropTypes.number.isRequired,
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default AutopayPalPayFrom;
