import authTokenSelector from 'selectors/authToken';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { always } from 'ramda';
import { getVerify, submit as submitAction } from 'util/index';
import { LOGIN, login } from 'reducers/authToken';
import { makeStyles } from '@material-ui/core/styles';
import { propTypes, reduxForm } from 'redux-form';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import { VERIFY, verify } from 'reducers/verify';
import PasswordVerifyActions from './PasswordVerifyActions';
import PasswordVerifyContent from './PasswordVerifyContent';
import PasswordVerifyHeader from './PasswordVerifyHeader';

const authenticated = (authToken, isChange, verify) => {
  return isChange ? verify.isAuthenticated : authToken.isAuthenticated;
};
const validate = ({ password, username }) => {
  const errors = {};

  if (!username) {
    errors.username = 'Enter your email address';
  }

  if (!password) {
    errors.password = 'Enter your password';
  }

  return errors;
};

const useStyles = makeStyles(() => ({
  form: {
    overflow: 'hidden',
  },
}));

const PasswordVerify = ({
  error,
  form,
  handleSubmit,
  initialize,
  isChange,
  onAuthenticated,
  onProgress,
  onViewChange,
  submitting,
  tokenExpired,
  visible,
}) => {
  const classes = useStyles();
  const authToken = useSelector(authTokenSelector);
  const isBusy = useSelector((s) => selector(s, isChange ? VERIFY : LOGIN));
  const verified = useSelector(getVerify);

  const submit = handleSubmit(submitAction(isChange ? verify : login, form));
  const { unauthorized } = authToken;

  useEffect(() => {
    onProgress(isBusy);
  }, [isBusy, onProgress]);

  useEffect(() => {
    if (error === 'false' && authenticated(authToken, isChange, verified)) {
      onAuthenticated();
    }
  }, [authToken, error, isChange, onAuthenticated, verified]);

  useEffect(() => {
    if (isChange && authToken.isAuthenticated) {
      initialize({ username: authToken.userLogin });
    }
  }, [authToken, initialize, isChange]);

  return visible ? (
    <form className={classes.form} onSubmit={submit}>
      <PasswordVerifyHeader
        tokenExpired={tokenExpired}
        unauthorized={unauthorized}
      />
      <PasswordVerifyContent isChange={isChange} submitting={submitting} />
      <PasswordVerifyActions
        isChange={isChange}
        onViewChange={onViewChange}
        submitting={submitting}
      />
    </form>
  ) : null;
};

PasswordVerify.propTypes = {
  isChange: PropTypes.bool,
  onProgress: PropTypes.func,
  onAuthenticated: PropTypes.func,
  onViewChange: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  tokenExpired: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  ...propTypes,
};

PasswordVerify.defaultProps = {
  isChange: false,
  onAuthenticated: always,
  onProgress: always,
  onViewChange: always,
  tokenExpired: false,
};

export default reduxForm({ validate })(PasswordVerify);
