import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class Switch extends Model {
  static get modelName() {
    return 'Switch';
  }

  static reducer({ type, data }, Switch) {
    switch (type) {
      case LOAD.SUCCESS:
        Switch.delete();
        if (data.response.switchbiller) {
          data.response.switchbiller.forEach((r) => {
            // have to add this so I can match two lists by same value
            r.billerId = r.id;
            Switch.upsert(r);
          });
        }
        break;

      case LOAD.FAILURE:
        Switch.delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      offset: attr(),
      rppsBillerId: attr(),
    };
  }
}

export const LOAD = createRequestConst(Switch.modelName);
export const load = createAction(LOAD);
