import { debounce } from 'throttle-debounce';
import { useCallback, useEffect } from 'react';
import useEventListener from './useEventListener';
import useHashParamState from './useHashParamState';

const delay = 400;
const initialState = 0;
const options = { capture: false, passive: true };
const type = 'scroll';

/**
 * useScrollState - only sets the scrollTop value
 *
 * @param name - the name that will be put on the url
 * @param domNode - the domNode, may need to be a callback ref - https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
 */
const useScrollState = (name, domNode) => {
  const [scrollTop, setScrollTop] = useHashParamState(name, initialState);

  const handleDebounce = useCallback(() => {
    setScrollTop(Math.floor(domNode.scrollTop));
  }, [domNode, setScrollTop]);
  const listener = debounce(delay, false, handleDebounce);

  useEffect(() => {
    let timeoutId = 0;

    if (domNode && domNode.scrollTop !== scrollTop) {
      // Why the timeout? If we don't have it, when the Accordion
      // completes it's expansion it changes the height of the dom element,
      // we need to wait until that happens for it to scroll to the correct position
      timeoutId = setTimeout(() => {
        if (scrollTop && domNode.scrollTop !== scrollTop) {
          domNode.scrollTop = Math.floor(scrollTop);
        }
      }, 0);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [domNode, scrollTop]);

  useEventListener(type, listener, domNode, options);
};

export default useScrollState;
