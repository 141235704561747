import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Mask, Skeleton } from 'components/common';
import { ReadOnlyFormControl } from 'components/common/fields';
import { loadById } from 'model/internalAccount';
import { makeStyles } from '@material-ui/core/styles';
import { selectorId } from 'selectors/internalAccount';
import { unmaskAchNumber } from 'sdk/internalAccount';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import AutopayCustomRule from '../../Autopay/AutopayCustomRule';
import { AUTOPAY } from '../../Autopay';

const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
  },
  grid: {
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({ autopayScheduled }) => {
  const classes = useStyles();
  const { ddaId, fspId, payerId } = autopayScheduled;
  const dda = useSelector((state) => selectorId(state, { payerId, id: ddaId }));
  const dispatch = useDispatch();

  const { accountNbrMask, ddaType } = dda;

  useEffect(() => {
    if (ddaId && !accountNbrMask) {
      dispatch(loadById({ fspId, id: ddaId }));
    }
  }, [dispatch, accountNbrMask, ddaId, fspId]);

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Statement Period"
            value={autopayScheduled.statementPeriodDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Due Date"
            value={autopayScheduled.dueOnDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Due Amount"
            value={autopayScheduled.amountDueDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Due Min Amount"
            value={autopayScheduled.minimumAmountDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label={`${AUTOPAY} Status`}
            value={autopayScheduled.autopayStatusDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Suspended Until"
            value={autopayScheduled.suspendUntilOnDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Pay Amount Option"
            value={autopayScheduled.payAmountDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Pay Date Option"
            value={autopayScheduled.payWhenDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              accountNbrMask ? (
                <Mask
                  api={() => unmaskAchNumber({ fspId, id: ddaId })}
                  prefix={ddaType}
                  value={accountNbrMask}
                />
              ) : (
                <Skeleton variant="text" width="50%" />
              )
            }
            label="Pay From Account"
            value={accountNbrMask || <Skeleton />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Schedule Ends On"
            value={autopayScheduled.scheduleEndOnDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <AutopayCustomRule
            billGreaterThanAmountDerived={
              autopayScheduled.billGreaterThanAmountDerived
            }
            billGreaterThanCustomAmountDerived={
              autopayScheduled.billGreaterThanCustomAmountDerived
            }
            billGreaterThanNotPayOffOn={
              autopayScheduled.billGreaterThanNotPayOffOn
            }
          />
        </Grid>
        {autopayScheduled.autopayStatus === 'created' && (
          <>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Schedule Id"
                value={autopayScheduled.schedId}
              />
            </Grid>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Schedule Amount"
                value={autopayScheduled.scheduleAmountDerived}
              />
            </Grid>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Schedule Date"
                value={autopayScheduled.scheduleCreatedAtDerived}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  autopayScheduled: PropTypes.object.isRequired,
};

export default DetailsRender;
