import PropTypes from 'prop-types';
import { always } from 'ramda';
import { loadRoutingNumber, LOAD_ROUTING_NUMBER } from 'model/rtnInfo';
import { numeric } from 'util/test';
import { RTN_LENGTH } from 'consts';
import { selectorRoutingNumber } from 'selectors/rtnInfo';
import { failed as failedSelector } from 'selectors/request';
import { TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import EndAdornment from './EndAdornment';
import StartAdornment from './StartAdornment';

const getLoadRequestObject = (value) => {
  // id is important to keep track of which routing fields have matching error and which don't
  // when we present a form with multiple routing number fields like inside fsp detail
  return { id: value, routingNumber: value };
};

const TextFieldRoutingNumber = ({
  error,
  helperText,
  label,
  onChange,
  onKeyPress,
  onPaste,
  showValidateButton,
  value: initialValue,
  verify,
  ...props
}) => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const { name: routingBankName, routingNumber } =
    useSelector((state) => selectorRoutingNumber(state, value)) ?? {};

  const failed = useSelector((state) =>
    failedSelector(state, LOAD_ROUTING_NUMBER, value)
  );

  const hasError = failed || error;

  const errorMessage = !hasError
    ? ''
    : failed
    ? 'Routing Number could not be matched'
    : helperText;
  const matched = value === routingNumber;
  const matchedBankName = matched ? routingBankName : '';

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
    onChange(event);
  };
  const handleClick = () => {
    if (value?.length) {
      dispatch(loadRoutingNumber(getLoadRequestObject(value)));
    }
  };
  const handleKeyPress = (event) => {
    if (!numeric(event.key)) {
      event.preventDefault();
    }

    onKeyPress(event);
  };
  const handlePaste = (event) => {
    if (!numeric(event.clipboardData.getData('Text'))) {
      event.preventDefault();
    }

    onPaste(event);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (
      verify &&
      (value ?? '').length === RTN_LENGTH &&
      value !== routingNumber
    ) {
      dispatch(loadRoutingNumber(getLoadRequestObject(value)));
    }
  }, [dispatch, routingNumber, value, verify]);

  return (
    <TextField
      {...props}
      autoComplete="off"
      error={hasError}
      fullWidth
      helperText={errorMessage}
      InputProps={{
        endAdornment: (
          <EndAdornment
            bankName={matchedBankName}
            disabled={(value || '').length < 9}
            onClick={handleClick}
            showButton={showValidateButton}
          />
        ),
        startAdornment: matched ? <StartAdornment /> : null,
        inputProps: { maxLength: 9 },
      }}
      label={label || 'Routing Number'}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onPaste={handlePaste}
      value={value}
    />
  );
};

TextFieldRoutingNumber.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  showValidateButton: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  verify: PropTypes.bool,
};

TextFieldRoutingNumber.defaultProps = {
  error: false,
  helperText: '',
  label: null,
  onChange: always,
  onKeyPress: always,
  onPaste: always,
  showValidateButton: true,
  value: '',
  verify: true,
};

export default TextFieldRoutingNumber;
