import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withEbillPDF } from 'components/common/permissions';
import ViewBillImage from './View/BillImage';

const useStyles = makeStyles(() => ({
  progress: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  viewBillButton: {
    border: '1px solid #ddd',
  },
  viewBillText: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    right: 0,
    top: 35,
  },
}));

const DetailStatementButton = ({ loading, onViewBill }) => {
  const classes = useStyles();

  return (
    <Button className={classes.viewBillButton} onClick={onViewBill}>
      <img alt="View Bill" src={ViewBillImage} />
      {loading ? (
        <CircularProgress
          className={classes.progress}
          color="primary"
          size={24}
        />
      ) : (
        <div className={classes.viewBillText}>ViewBill</div>
      )}
    </Button>
  );
};

DetailStatementButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onViewBill: PropTypes.func.isRequired,
};

export default withEbillPDF(DetailStatementButton);
