import PropTypes from 'prop-types';
import { useState } from 'react';
import { ENTER_DELAY } from 'sagas';
import { Sort } from '@material-ui/icons';
import { sortableFields } from 'model/researchNote';
import { Button, Menu, MenuItem, Tooltip, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(3),
  },
});

const NoteSortMenu = ({ classes, order, setOrder }) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const open = Boolean(menuAnchor);
  const handleOpen = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => setMenuAnchor(null);
  const handleClick = (value) => (event) => {
    event.stopPropagation();
    setOrder(value);
    handleClose();
  };
  const handleTooltipOpen = () => setTooltipOpen(true);
  const handleTooltipClose = () => setTooltipOpen(false);

  return (
    <>
      <Tooltip
        disableFocusListener
        enterDelay={ENTER_DELAY}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        open={tooltipOpen}
        title="Sort Notes By"
      >
        <Button className={classes.icon} onClick={handleOpen}>
          <Sort /> Sort By
        </Button>
      </Tooltip>
      <Menu anchorEl={menuAnchor} onClose={handleClose} open={open}>
        {sortableFields.map(({ name, value }) => (
          <MenuItem
            key={value}
            data-value={value}
            onClick={handleClick(value)}
            selected={value === order}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

NoteSortMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.string.isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default withStyles(styles)(NoteSortMenu);
