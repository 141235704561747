import PropTypes from 'prop-types';
import { memo, useEffect } from 'react';
import { TRANSACTION_TYPE_BILL_PAY, ON, PERSON_TO_PERSON } from 'consts';
import { decryptDestination, decryptFunding } from 'sdk/scheduledTransaction';
import { Grid } from '@material-ui/core';
import { loadById } from 'model/recipient';
import { loadBySchedId } from 'model/autopayScheduled';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { selectorId } from 'selectors/recipient';
import { selectorSchedId } from 'selectors/autopayScheduled';
import { Skeleton } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { isOn } from 'util/index';
import { useFraudEnabled } from 'hooks/useFSPFraudConfig';
import usePayALoanPayerOffOn from 'hooks/usePayALoanPayerOffOn';
import MaskDestination from '../../config/MaskDestination';
import MaskFunding from '../../config/MaskFunding';
import PaymentSeries from '../PaymentSeries';
import Type from '../../History/Type';
import { Autopay, AUTOPAY } from '../../../Autopay';
import AppliedTo from './PalAmounts/AppliedTo';
import Amount from './PalAmounts/Amount';
import FeeAmount from './PalAmounts/FeeAmount';
import TotalAmount from './PalAmounts/TotalAmount';

const useStyles = makeStyles(() => ({
  input: {
    textTransform: 'capitalize',
  },
}));

const ExpandedRow = ({ item = {} }) => {
  const autopay = useSelector((state) => selectorSchedId(state, item.id));
  const classes = useStyles();
  const dispatch = useDispatch();
  const recipient = useSelector((state) => selectorId(state, item.recipientId));

  const { autopayRuleId, fspId, id: schedId, recipientId } = item;
  const { statusDerived } = autopay;
  const fraudEnabled = useFraudEnabled();
  const palEnabled = usePayALoanPayerOffOn(fspId, item.payerId);

  useEffect(() => {
    if (!statusDerived && autopayRuleId) {
      dispatch(loadBySchedId({ fspId, schedId }));
    }
  }, [autopayRuleId, dispatch, fspId, schedId, statusDerived]);

  useEffect(() => {
    if (recipientId) {
      dispatch(loadById({ fspId, recipientId }));
    }
  }, [dispatch, fspId, recipientId]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            <Type item={item} />
          </Grid>
          <Grid item xs={2}>
            <ReadOnlyFormControl isDate label="Send Date" value={item.sendOn} />
          </Grid>
          <Grid item xs={recipientId ? 3 : 4}>
            <ReadOnlyFormControl
              inputNode={
                item.fundingMask && (
                  <MaskFunding decrypt={decryptFunding} icon item={item} />
                )
              }
              label="Funding Account"
              value={item.fundingMask}
            />
          </Grid>
          <Grid item xs={2}>
            <ReadOnlyFormControl label="Status" value={item.statusDerived} />
          </Grid>
          <Grid item xs={2}>
            <ReadOnlyFormControl
              isDate
              label="Deliver Date"
              value={item.deliverOn}
            />
          </Grid>
          {recipientId && (
            <Grid item xs={1}>
              <ReadOnlyFormControl
                label="Delivery Type"
                value={
                  recipient ? recipient?.disburseTypeDerived : <Skeleton />
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}>
            {autopayRuleId ? (
              <ReadOnlyFormControl
                inputNode={
                  statusDerived ? (
                    <ReadOnlyInputWithMenu
                      label={statusDerived}
                      menuNode={
                        <Autopay
                          transactionType={item?.transactionType}
                          {...autopay}
                        />
                      }
                    />
                  ) : (
                    <Skeleton />
                  )
                }
                label={AUTOPAY}
                value={statusDerived || <Skeleton />}
              />
            ) : (
              <ReadOnlyFormControl
                classes={{ input: classes.input }}
                inputNode={
                  item.modelId && (
                    <PaymentSeries
                      forever={isOn(item.foreverOffOn)}
                      fspId={item.fspId}
                      label={item.frequencyLabel}
                      modelId={item.modelId}
                      payerId={item.payerId}
                    />
                  )
                }
                label="Frequency"
                value={item.frequencyLabel}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <ReadOnlyFormControl label="Schedule ID" value={item.id} />
          </Grid>
          <Grid item xs={recipientId ? 3 : 4}>
            <ReadOnlyFormControl
              inputNode={
                item.transactionType !== PERSON_TO_PERSON && (
                  <MaskDestination
                    decrypt={decryptDestination}
                    icon
                    item={item}
                  />
                )
              }
              label="Destination"
              value={item.destination ? item.destination : item.destinationMask}
            />
          </Grid>
          <Grid item xs={2}>
            <ReadOnlyFormControl
              label="Confirmation #"
              value={item.confirmationNumber}
            />
          </Grid>
          {(item.transactionType === TRANSACTION_TYPE_BILL_PAY ||
            item.transactionType === PERSON_TO_PERSON) && (
            <Grid item xs={2}>
              <ReadOnlyFormControl
                label="Delivery Method"
                value={item.deliveryMethodDerived}
              />
            </Grid>
          )}
          {fraudEnabled && (
            <Grid item xs={2}>
              <ReadOnlyFormControl
                label="Fraud Score"
                value={item.fraudScore}
              />
            </Grid>
          )}
          {item?.ipayNetOffOn === ON && !!item?.ipayNetBillerId && (
            <Grid item xs={2}>
              <ReadOnlyFormControl
                label="iPay Merchant ID"
                value={item.ipayNetBillerId}
              />
            </Grid>
          )}
          {palEnabled && <AppliedTo item={item} />}
          {palEnabled && <Amount item={item} />}
          {palEnabled && <FeeAmount item={item} />}
          {palEnabled && <TotalAmount item={item} />}
        </Grid>
      </Grid>
    </Grid>
  );
};

ExpandedRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default memo(ExpandedRow);
