import { SSO_OVERWRITE } from 'consts';
import { evolve } from 'ramda';
import { convertToNumberEmptyAsNull } from 'util/general';

export const addDerived = ({ ssoConfig, ...data } = {}) => {
  const overwrite = ssoConfig?.[SSO_OVERWRITE];

  if (overwrite && typeof overwrite === 'string') {
    try {
      ssoConfig[SSO_OVERWRITE] = JSON.parse(overwrite);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return {
    ...data,
    ssoConfig,
  };
};

const importTransformations = {
  bpConfig: {
    bpExpediteFeeAmount: convertToNumberEmptyAsNull,
  },
  p2pConfig: {
    p2pRealtimeFeeAmount: convertToNumberEmptyAsNull,
  },
};

export const sanitizeForImport = (fspConfig) => {
  delete fspConfig?.id;
  delete fspConfig?.fspName;
  delete fspConfig?.products;
  delete fspConfig?.p2pConfig?.p2pPaynet;
  delete fspConfig?.checkConfig?.checkDeliverBizDays;

  // Only billSavingPercent is supported under billSavingConfig
  fspConfig.billSavingConfig = {
    billSavingPercent: fspConfig?.billSavingConfig?.billSavingPercent,
  };

  return evolve(importTransformations, fspConfig);
};
