import { takeEvery } from 'redux-saga/effects';
import {
  create,
  load,
  loadById,
  loadSet,
  update,
  updateSet,
} from 'sdk/eBiller';
import {
  CREATE,
  LOAD,
  LOAD_ID,
  LOAD_SET,
  UPDATE,
  UPDATE_SET,
} from 'model/eBiller';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: load, dataName: ORM, type: LOAD })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({ api: loadById, dataName: ORM, type: LOAD_ID })
  );

  yield takeEvery(
    LOAD_SET.ACTION,
    callAction({ api: loadSet, dataName: ORM, type: LOAD_SET })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: () => `eBiller was successfully updated`,
      type: UPDATE,
    })
  );

  yield takeEvery(
    UPDATE_SET.ACTION,
    callAction({
      api: updateSet,
      dataName: ORM,
      formatSuccessMessage: () =>
        `eBiller was successfully linked to the Biller Set`,
      type: UPDATE_SET,
    })
  );
}
