import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst, createUpdateConst } from '../util';

export default class FspFraudRuleThresholds extends Model {
  static get modelName() {
    return 'FspFraudRuleThresholds';
  }

  static reducer({ type, data }, FspFraudRuleThresholds) {
    switch (type) {
      case LOAD.SUCCESS:
        data?.response?.forEach((r) => FspFraudRuleThresholds.upsert(r));
        break;

      case UPDATE.SUCCESS:
        FspFraudRuleThresholds.upsert(data?.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      fspId: attr(),
      highAmountA2a: attr(),
      highAmountBp: attr(),
      highAmountP2p: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      lowAmountA2a: attr(),
      lowAmountBp: attr(),
      lowAmountP2p: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }

  /**
   * Use fspId as key
   * */
  static options = {
    idAttribute: 'fspId',
  };
}

const name = FspFraudRuleThresholds.modelName;

export const LOAD = createRequestConst(name);
export const UPDATE = createUpdateConst(name);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
