import { CallToAction } from '@material-ui/icons';
import { LOAD } from 'model/actionableAlert';
import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';
import ActionableAlertList from './ActionableAlertList';
import ActionableAlertsStartAdornment from './ActionableAlertsStartAdornment';
import PayerDetailPanel from '../PayerDetailPanel';

const ActionableAlerts = (props) => {
  const showProgress = useSelector((state) => selector(state, LOAD));

  return (
    <PayerDetailPanel
      heightOffset={HEIGHT_OFFSET}
      icon={CallToAction}
      id="alerts"
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={showProgress}
      startAdornment={<ActionableAlertsStartAdornment {...props} />}
      title="Actionable Alerts"
      {...props}
    >
      <ActionableAlertList {...props} />
    </PayerDetailPanel>
  );
};

export default ActionableAlerts;
