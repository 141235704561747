import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getDisplayValue } from '../../model';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  value: {
    color: theme.palette.primary.dark,
    fontFamily: 'monospace',
    fontSize: theme.typography.fontSize + 2,
    fontWeight: 500,
    paddingRight: theme.spacing(1),
  },
}));

const ParamChipLabel = ({ param }) => {
  const classes = useStyles();
  const { getChipLabel } = param;

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      container
      spacing={1}
      wrap="nowrap"
    >
      <Grid item>
        <Typography variant="body2">{param.displayName}:</Typography>
      </Grid>
      <Grid item>
        <Typography
          className={classNames(classes.value, {
            [classes.capitalize]: param.type === 'boolean',
          })}
        >
          {getChipLabel ? getChipLabel(param) : getDisplayValue(param)}
        </Typography>
      </Grid>
    </Grid>
  );
};

ParamChipLabel.propTypes = {
  param: PropTypes.object.isRequired,
};

export default ParamChipLabel;
