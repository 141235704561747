import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { createRef, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import scrollIntoView from 'scroll-into-view';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Collapse,
  Grid,
  Typography,
} from '@material-ui/core';
import { ArrayParam, useQueryParam } from 'use-query-params';
import { ExpandMore } from '@material-ui/icons';
import { SCROLL_DELAY, SCROLL_OPTIONS } from 'util/format';
import { always } from 'ramda';
import { compose, withProps } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { pageConnect, pageHandlers } from 'util/page';

const useStyles = makeStyles((theme) => ({
  internalPanelsDetailsEmpty: {
    minHeight: '35vh',
  },
  list: {
    width: '100%',
  },
  secondaryHeading: {
    color: theme.palette.secondary.dark,
  },
}));

const ActionInsightList = ({
  ActionsRenderer,
  actionConstant,
  columnMeta,
  endAdornment,
  emptyTitle,
  fspId,
  handleChange,
  isBusy,
  isLoading,
  items,
  name,
  onPanelChange,
  page,
  payerId,
  rowsPerPage,
  title,
  ...props
}) => {
  const [expandedIds, setExpandedIds] = useQueryParam('epAI', ArrayParam);
  const [panelExpanded, setPanelExpanded] = useState(false);
  const classes = useStyles();
  const scrollRef = createRef();

  const columnMetaMemo = useMemo(() => columnMeta, [columnMeta]);
  const countMessage = items?.length ? `(${items.length})` : emptyTitle;
  const params = { fspId, payerId };

  const handleAccordionChange = (event, expanded) => {
    const ids = expandedIds ? [...expandedIds] : [];

    if (expanded) {
      const index = ids.indexOf(title);

      if (index === -1) {
        ids.push(title);
      } else {
        ids.splice(index, 1);
      }

      setTimeout(
        () => scrollIntoView(scrollRef.current, SCROLL_OPTIONS),
        SCROLL_DELAY
      );
    } else {
      ids.splice(0, ids.length);
    }

    setExpandedIds(ids.length ? ids : undefined);
    onPanelChange(event, expanded);
  };

  useEffect(() => {
    const pos = Array.isArray(expandedIds) ? expandedIds.indexOf(title) : -1;

    setPanelExpanded(pos !== -1);
  }, [expandedIds, title]);

  useEffect(() => {
    return () => {
      setExpandedIds(undefined);
    };
  }, [setExpandedIds]);

  return (
    <Accordion expanded={panelExpanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div ref={scrollRef} />
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <Typography noWrap variant="h6">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Collapse in={!panelExpanded}>
                  {items?.length === 0 && isLoading ? (
                    <Skeleton width={25} />
                  ) : (
                    <Typography
                      className={classes.secondaryHeading}
                      variant="body2"
                    >
                      {countMessage}
                    </Typography>
                  )}
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
          {endAdornment && (
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Collapse in={panelExpanded}>{endAdornment}</Collapse>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        className={classNames({
          [classes.internalPanelsDetailsEmpty]: items?.length === 0,
        })}
      >
        {panelExpanded && (
          <ExpansionList
            actionConstant={actionConstant}
            ActionsRenderer={ActionsRenderer}
            actionType={actionConstant.ACTION}
            className={classes.list}
            columnMeta={columnMetaMemo}
            data={items}
            emptyTitle={isLoading ? '' : emptyTitle}
            loadParams={params}
            name={name}
            onChange={handleChange}
            page={page}
            renderOptions={params}
            rowsPerPage={rowsPerPage}
            {...props}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ActionInsightList.propTypes = {
  ActionsRenderer: PropTypes.func,
  actionConstant: PropTypes.object.isRequired,
  columnMeta: PropTypes.arrayOf(PropTypes.object).isRequired,
  emptyTitle: PropTypes.string,
  endAdornment: PropTypes.node,
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onPanelChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

ActionInsightList.defaultProps = {
  ActionsRenderer: undefined,
  emptyTitle: 'No records found',
  endAdornment: null,
  onPanelChange: always,
};

export default compose(
  withProps(({ actionConstant }) => ({
    actionType: actionConstant.ACTION,
    noSearch: true,
  })),
  pageConnect,
  pageHandlers
)(ActionInsightList);
