import PropTypes from 'prop-types';
import { Grid, Card, CardContent, makeStyles } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { TRANSACTION_TYPE_PAL } from 'consts';
import { AUTOPAY } from './consts';
import AutopayPalPayFrom from './AutopayPalPayFrom';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 600,
  },
}));

const AutopayPal = ({
  ddaId,
  fspId,
  palStatusDerived,
  payAmountPalDerived,
  payerId,
  transactionType,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <ReadOnlyFormControl label={AUTOPAY} value={palStatusDerived} />
          </Grid>
          <Grid item xs={6}>
            <ReadOnlyFormControl
              label="Pay Amount Option"
              value={payAmountPalDerived}
            />
          </Grid>
          <Grid item xs={6}>
            {transactionType === TRANSACTION_TYPE_PAL ? (
              <AutopayPalPayFrom
                ddaId={ddaId}
                fspId={fspId}
                payerId={payerId}
              />
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AutopayPal.propTypes = {
  ddaId: PropTypes.number,
  fspId: PropTypes.number.isRequired,
  palStatusDerived: PropTypes.string,
  payAmountPalDerived: PropTypes.string,
  payerId: PropTypes.number.isRequired,
  transactionType: PropTypes.string.isRequired,
};

AutopayPal.defaultProps = {
  ddaId: null,
  palStatusDerived: null,
  payAmountPalDerived: null,
};

export default AutopayPal;
