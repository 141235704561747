import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { convertToDays } from '../util';

export const selectorCount = createSelector(
  orm,
  (state, props) => props,
  ({ RecipientAudit }, { id }) =>
    RecipientAudit.filter(({ recipientId }) => recipientId === id).count()
);

const selector = createSelector(
  orm,
  (state, props) => props,
  ({ RecipientAudit }, { id }) =>
    RecipientAudit.filter(
      ({ recipientId }) => recipientId === id
    ).toModelArray()
);

export const selectorGroupedByDays = createReselectSelector(selector, (items) =>
  items.reduce(convertToDays, [])
);
