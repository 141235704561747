import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: theme.spacing(32),
  },
}));

const FilterByUsersAutocompleteRenderInput = (params) => {
  const classes = useStyles();

  return (
    <TextField
      {...params}
      className={classes.textField}
      label="Filter By Users"
      placeholder="Select"
    />
  );
};

export default FilterByUsersAutocompleteRenderInput;
