import PropTypes from 'prop-types';
import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectorSchedId as selector } from 'selectors/autopayScheduled';
import { Skeleton } from 'components/common';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { Autopay, AUTOPAY } from '../../../../../Autopay';

const useStyles = makeStyles(() => ({
  input: {
    textTransform: 'capitalize',
  },
}));

const AutoPayOrFrequency = ({ transaction }) => {
  const classes = useStyles();
  const autopay = useSelector((state) => selector(state, transaction.schedId));

  const { autopayRuleId } = transaction;
  const { statusDerived } = autopay;

  return autopayRuleId ? (
    <ReadOnlyFormControl
      inputNode={
        statusDerived ? (
          <ReadOnlyInputWithMenu
            label={statusDerived}
            menuNode={
              <Autopay
                transactionType={transaction.transactionType}
                {...autopay}
              />
            }
          />
        ) : (
          <Skeleton />
        )
      }
      label={AUTOPAY}
      value={statusDerived || <Skeleton />}
    />
  ) : (
    <ReadOnlyFormControl
      classes={{ input: classes.input }}
      label="Frequency"
      value={transaction.frequencyLabel}
    />
  );
};

AutoPayOrFrequency.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default memo(AutoPayOrFrequency);
