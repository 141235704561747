import { createSelector } from 'redux-orm';
import { orm } from 'model';

const defaultChannels = [];

export const selector = createSelector(orm.Channel) || defaultChannels;

export const selectorCount = createSelector(orm, ({ Channel }) =>
  Channel.count()
);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ Channel }, { id }) =>
    Channel.idExists(id) ? Channel.withId(id) : Channel.upsert({ id: id || 0 })
);

export const selectorSelected = createSelector(orm, ({ Channel }) =>
  Channel.filter(({ selected }) => selected).toRefArray()
);
