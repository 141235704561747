import { attr, fk, Model } from 'redux-orm';
import Biller from 'model/biller';
import {
  createAction,
  createAddConst,
  createLoadConst,
  dateAndTime,
  createRequestConst,
  createDeleteConst,
  createUpdateConst,
  isNilOrEmpty,
} from '../util';

export const derived = (billerAlias) => {
  return {
    ...billerAlias,
    createdAtDerived: dateAndTime(billerAlias?.createdAt),
    updatedAtDerived: dateAndTime(billerAlias?.updatedAt),
  };
};

export default class BillerAlias extends Model {
  static get modelName() {
    return 'BillerAlias';
  }

  static reducer({ type, data }, BillerAlias, { Biller }) {
    switch (type) {
      case CREATE.SUCCESS:
        BillerAlias.upsert(data.response);
        break;

      case CHECK_ALL.ACTION:
        BillerAlias.filter(
          ({ aliasType, insAdminUserId }) =>
            aliasType === 'biller_alias' && !isNilOrEmpty(insAdminUserId)
        ).update({ selected: data.selected });
        break;

      case CHECK.ACTION: {
        const alias = BillerAlias.withId(data.id);
        if (alias) {
          alias.selected = !alias.selected;

          alias.update(alias);
        }
        break;
      }

      case LOAD.SUCCESS:
        if (Biller.idExists(data?.props?.billerId)) {
          Biller.withId(data.props.billerId).billerAliases.delete();
        }
        (data?.response || []).forEach((billerAlias) => {
          BillerAlias.upsert(
            derived({ ...billerAlias, updateSucceededDerived: false })
          );
        });
        break;

      case LOAD.FAILURE:
        BillerAlias.delete();
        break;

      case REMOVE.SUCCESS: {
        if (BillerAlias.idExists(data?.props?.id)) {
          const alias = BillerAlias.withId(data.props.id);

          alias.delete();
        }
        break;
      }

      case UPDATE.ACTION: {
        if (BillerAlias.idExists(data.id)) {
          const alias = BillerAlias.withId(data.id);

          if (alias) {
            alias.updateSucceededDerived = false;
            BillerAlias.update(alias);
          }
        }

        break;
      }

      case UPDATE.SUCCESS:
        BillerAlias.update(
          derived({ ...data.response, updateSucceededDerived: true })
        );
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      alias: attr(),
      aliasType: attr(),
      billerAliasCount: attr(),
      billerId: fk(Biller.modelName, 'billerAliases'),
      billerSetAliasId: attr(),
      billerSetId: attr(),
      billerStatus: attr(),
      createdAt: attr(),
      creatdAtDerived: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insBillernetId: attr(),
      netIdStr: attr(),
      offset: attr(),
      searchStatus: attr(),
      selected: attr(), // derived
      updBillernetId: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(),
      updateSucceededDerived: attr(),
      zipMatchNeededOffOn: attr(),
    };
  }
}

const name = BillerAlias.modelName;

export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const CREATE = createAddConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
