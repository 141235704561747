import PropTypes from 'prop-types';
import { Mask } from 'components/common';

const MaskDestination = ({ decrypt, icon, item }) => {
  const { destination, destinationAccountSuffix, destinationMask } = item;

  const handleApi = () => {
    return decrypt(item);
  };

  return (
    <Mask
      api={handleApi}
      icon={icon}
      prefix={destination}
      suffix={destinationAccountSuffix}
      value={destinationMask}
    />
  );
};

MaskDestination.propTypes = {
  decrypt: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  item: PropTypes.object.isRequired,
};

MaskDestination.defaultProps = {
  icon: false,
};

export default MaskDestination;
