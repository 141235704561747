import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.Autopay);

export const selectorPayeeAutopay = createSelector(
  orm,
  (state, payeeId) => payeeId,
  ({ Autopay }, payeeId) => {
    const result = Autopay.filter(
      ({ payeeId: autopayPayeeId }) => autopayPayeeId === payeeId
    )?.first();

    return result?.ref || {};
  }
);

export const selectorAccountAutopay = createSelector(
  orm,
  (state, ddaId) => ddaId,
  ({ Autopay }, ddaId) => {
    const result = Autopay.filter(
      ({ ddaId: autopayDdaId }) => autopayDdaId === ddaId
    )?.first();

    return result?.ref || {};
  }
);
