import { Slide, Snackbar, SnackbarContent } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import ErrorAction from './ErrorAction';
import ErrorMessage from './ErrorMessage';
import UpdateRequired from './UpdateRequired';
import useErrorEffects from './useErrorEffects';

const anchorOrigin = { horizontal: 'right', vertical: 'top' };

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: amber[50],
    color: theme.palette.text.primary,
  },
}));

const Error = () => {
  const [open, message, upgradeRequired] = useErrorEffects();
  const classes = useStyles();

  return upgradeRequired ? (
    <UpdateRequired message={message} />
  ) : (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      TransitionComponent={Slide}
    >
      <SnackbarContent
        action={<ErrorAction />}
        className={classes.content}
        message={<ErrorMessage />}
      />
    </Snackbar>
  );
};

export default Error;
