import { attr, Model } from 'redux-orm';
import { LOAD } from './switch';

export default class SwitchMask extends Model {
  static get modelName() {
    return 'SwitchMask';
  }

  static reducer({ type, data }, SwitchMask) {
    switch (type) {
      case LOAD.SUCCESS:
        SwitchMask.delete();
        if (data.response.switchbillermask) {
          data.response.switchbillermask.forEach((r) => SwitchMask.upsert(r));
        }
        break;

      case LOAD.FAILURE:
        SwitchMask.delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      billerId: attr(),
      billerIds: attr(), // derived
      id: attr(),
      mask: attr(),
      maskLength: attr(),
      maskRegexp: attr(),
      name: attr(), // derived
      offset: attr(),
    };
  }
}
