import { Collapse, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'reducers/errorMessages';
import { useSelector } from 'react-redux';
import ErrorMessageCollapseDetail from './ErrorMessageCollapseDetail';

const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(2),
    maxWidth: 325,
  },
  paper: {
    backgroundColor: '#525252',
    color: '#fbfbfb',
    fontFamily: 'monospace',
    fontSize: 'larger',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
}));

const ErrorMessageCollapse = (props) => {
  const classes = useStyles();
  const { message } = useSelector(selector);

  return (
    <Collapse {...props}>
      <Paper className={classes.paper}>
        <div className={classes.message}>
          {message || 'no details provided'}
        </div>
        <Grid className={classes.message} container direction="column">
          <ErrorMessageCollapseDetail type="req" />
          <ErrorMessageCollapseDetail type="status" />
          <ErrorMessageCollapseDetail type="number" />
        </Grid>
      </Paper>
    </Collapse>
  );
};

export default ErrorMessageCollapse;
