import PropTypes from 'prop-types';
import { Button, DialogActions } from '@material-ui/core';
import { isNil } from 'ramda';
import { useEffect, useState } from 'react';
import useLoading from './useLoading';

const AlertImprovedActions = ({
  actionType,
  actionTypeSecond,
  cancelLabel,
  confirmDisabled,
  confirmLabel,
  onClose,
  onConfirm,
}) => {
  const [disabledCancel, setDisabledCancel] = useState(false);
  const [disabledConfirm, setDisabledConfirm] = useState(false);

  const handleClick = (event) => {
    setDisabledConfirm(true);
    onConfirm(event);
  };
  const handleClose = (event) => {
    setDisabledCancel(false);
    setDisabledConfirm(confirmDisabled);
    onClose(event);
  };

  const { isLoading, isLoadingSecond } = useLoading({
    actionType,
    actionTypeSecond,
  });

  useEffect(() => {
    setDisabledCancel(isLoading || isLoadingSecond);
    setDisabledConfirm(isLoading || isLoadingSecond);
  }, [isLoading, isLoadingSecond]);

  return (
    <DialogActions>
      {cancelLabel && (
        <Button
          autoFocus={isNil(confirmLabel) && !isNil(cancelLabel)}
          color="primary"
          disabled={disabledCancel}
          onClick={handleClose}
        >
          {cancelLabel}
        </Button>
      )}
      {confirmLabel && (
        <Button
          color="primary"
          disabled={confirmDisabled || disabledConfirm}
          onClick={handleClick}
        >
          {confirmLabel}
        </Button>
      )}
    </DialogActions>
  );
};

AlertImprovedActions.propTypes = {
  actionType: PropTypes.object.isRequired,
  actionTypeSecond: PropTypes.object.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmDisabled: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AlertImprovedActions;
