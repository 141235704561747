import PropTypes from 'prop-types';
import { Button, DialogActions } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useSubmitButtonDisabled } from 'components/common/formik';

const AddDialogActions = ({ onClose }) => {
  const disabled = useSubmitButtonDisabled();
  const { handleSubmit } = useFormikContext();

  return (
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button
        color="primary"
        disabled={disabled}
        onClick={handleSubmit}
        variant="contained"
      >
        Add
      </Button>
    </DialogActions>
  );
};

AddDialogActions.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddDialogActions;
