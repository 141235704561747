import { SELECTED_ORG_ID_CODE } from 'consts';
import { createLoadConst, createSelectConst, createAction } from 'util/actions';

const name = SELECTED_ORG_ID_CODE;

export const LOAD = createLoadConst(name);
export const SET = createSelectConst(name);

export const load = createAction(LOAD);
export const set = createAction(SET);

export const selector = (state) => state[name];

export default (state = null, { type, data }) => {
  switch (type) {
    case LOAD.SUCCESS:
    case SET.SUCCESS:
      return data;

    default:
      return state;
  }
};
