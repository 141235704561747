/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import StatusSelectionAction from 'components/common/status_selection_action';
import {
  always,
  cond,
  includes,
  flip,
  toPairs,
  map,
  equals,
  T,
  keys,
  reduce,
} from 'ramda';
import { update, UPDATE } from 'model/recipient';
import { useDispatch } from 'react-redux';

const SUSPEND_MSG = (extras) => () => {
  return (
    <span>
      By choosing to suspend this Recipient, any payments scheduled for
      processing will be cancelled and marked as
      <strong> not processed due to suspension</strong>.
      <br />
      This is only for payments that involve this recipient. All other scheduled
      payments will be processed.
      <br />
      <br />
      Suspend {extras}?
    </span>
  );
};

const ContactStatus = {
  activateExpired: 'Activation Expired',
  activateFailed: 'Activation Failed',
  active: 'Active',
  declined: 'Declined',
  deleted: 'Deleted',
  notifyPending: 'Notification Pending',
  notifySent: 'Notification Sent',
  onholdApprove: 'On Hold Awaiting Approval',
  onholdValidate: 'On Hold Awaiting Validation',
  suspended: 'Suspended',
};

export const statusList = map(
  ([value, name]) => ({ name, value, key: value }),
  toPairs(ContactStatus)
);

const UNSUSPEND_MSG = (extras) => () => {
  return (
    <span>
      By choosing to unsuspend this recipient, the recipient will return to the
      status they were in prior to the suspension and any payments that are
      eligible to be sent will be processed.
      <br />
      <br />
      Unsuspend {extras}?
    </span>
  );
};

const CAN_SUSPEND_STATUSES = [
  'notifyPending',
  'notifySent',
  'onholdApprove',
  'onholdValidate',
  'active',
];

const SUSPEND_LABEL = 'Suspend';
const UNSUSPEND_LABEL = 'Unsuspend';

const SUSPENDED_STATUS = 'suspended';

const getStatusConfig = (previousStatus) =>
  reduce(
    (accum, key) =>
      cond([
        [
          flip(includes)(CAN_SUSPEND_STATUSES),
          always({
            ...accum,
            [key]: {
              suspended: {
                message: SUSPEND_MSG,
                label: SUSPEND_LABEL,
                requiredPermission: always(true),
              },
            },
          }),
        ],
        [
          equals(SUSPENDED_STATUS),
          always({
            ...accum,
            [SUSPENDED_STATUS]: {
              [previousStatus]: {
                message: UNSUSPEND_MSG,
                label: UNSUSPEND_LABEL,
                requiredPermission: always(true),
              },
            },
          }),
        ],
        [T, always(accum)],
      ])(key),
    {},
    keys(ContactStatus)
  );

const StatusSelector = ({ recipient, ...props }) => {
  const dispatch = useDispatch();

  const handleChange = (status) => {
    dispatch(update({ recipient, status }));
  };

  const statusConfig = getStatusConfig(recipient.previousStatus);

  return (
    <StatusSelectionAction
      {...props}
      actionType={UPDATE}
      confirmMessageExtras={recipient.name}
      currentStatus={recipient.status}
      onChange={handleChange}
      statusConfig={statusConfig}
      statusList={statusList}
    />
  );
};

StatusSelector.propTypes = {
  recipient: PropTypes.object.isRequired,
};

export default StatusSelector;
