import { client, formatQueryParams } from 'util/sdk';
import { getUpdatedStatusNoLabel } from './index';

const expand = ['user'];

export const load = async ({ fspId, ...props }) => {
  const payeruser = await client
    .fsps(fspId)
    .payerUsers.query(formatQueryParams({ ...props, expand }));

  return { payeruser };
};
export const update = ({ fspId, id, status }, auth) =>
  client
    .fsps(fspId)
    .payerUsers(id)
    .updateStatus(getUpdatedStatusNoLabel(status, auth), {
      adminUserId: auth.id,
    });
