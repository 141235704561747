import LoadingSkeleton from 'react-loading-skeleton';
import { DEFAULT_MODEL_REF, DEFAULT_MODEL_REF_ARRAY } from 'consts';
import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import { makeStyles } from '@material-ui/core/styles';
import { orm } from 'model';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const Skeleton = (props) => {
  const classes = useStyles();

  return (
    <p className={classes.root}>
      <LoadingSkeleton {...props} />
    </p>
  );
};

const SKELETON_10 = <Skeleton width="10%" />;
const SKELETON_30 = <Skeleton width="30%" />;
const SKELETON_40 = <Skeleton width="40%" />;
const SKELETON_45 = <Skeleton width="45%" />;
const SKELETON_65 = <Skeleton width="65%" />;
const SKELETON_70 = <Skeleton width="70%" />;

const SKELETONS = {
  amountDerived: SKELETON_30,
  billerAccountNumberMask: SKELETON_45,
  confirmationNumber: SKELETON_70,
  deliverOnDerived: SKELETON_45,
  deliveryMethodValue: SKELETON_40,
  destination: SKELETON_65,
  frequency: SKELETON_30,
  fundingAccount: SKELETON_65,
  fundingMask: SKELETON_45,
  fundingResponse: SKELETON_40,
  frequencyLabel: SKELETON_45,
  id: SKELETON_10,
  isEmpty: true,
  sendOnDerived: SKELETON_45,
  statusDerived: SKELETON_65,
  transactionTypeDerived: SKELETON_65,
};

const getTransactionData = (item) => {
  return item.isEmpty ? SKELETONS : item.ref;
};

export const transactionData = (items = []) => items.map(getTransactionData);

const payerSelector = createSelector(
  orm,
  (state, props) => props,
  ({ Payer }, { payerId }) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).transactionHistory.all().toModelArray()
      : DEFAULT_MODEL_REF_ARRAY
);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ TransactionHistory }, { id }) => {
    let item;

    if (TransactionHistory.idExists(id)) {
      item = TransactionHistory.withId(id);
      item.set('isEmpty', false);
    } else {
      item = TransactionHistory.upsert({ id });
      item.set('isEmpty', true);
    }

    return item;
  }
);

export const selectorSchedId = createSelector(
  orm,
  (state, props) => props,
  ({ TransactionHistory }, schedId) =>
    TransactionHistory.filter((item) => item.schedId === schedId).first()
      ?.ref ?? DEFAULT_MODEL_REF
);

export const updateSucceededSelector = createReselectSelector(
  selectorId,
  (item) => item.updateSucceededDerived
);

export const derivedSelectorId = createReselectSelector(
  selectorId,
  getTransactionData
);

export const selector = createReselectSelector(payerSelector, transactionData);
