import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { loadPayeeAutopay } from 'model/autopay';
import { selectorPayeeAutopay } from 'selectors/autopay';
import {
  TRANSACTION_TYPE_BILL_PAY,
  NA_VALUE,
  ELECTRONIC_VC,
  CHECK,
  ELECTRONIC,
  ON,
} from 'consts';
import { Skeleton } from 'components/common';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { useEBillEnabledSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import { isOn } from 'util/index';
import Detail from '../eBill/Detail';
import DetailsRenderAddress from './DetailsRenderAddress';
import { Autopay, AUTOPAY } from '../../Autopay';

const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
  },
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const DetailsRender = ({
  address1,
  address2,
  autopayRuleAt,
  billerAccountNumberMask,
  deliverElecStd,
  deliverCheckStd,
  deliverElecVc,
  ebillEnrollAt,
  ebillStatus,
  fspId,
  fullAddress,
  ipayNetBillerId,
  ipayNetOffOn,
  id,
  name,
  nickName,
  payeeStatus,
  payerEbillerAccountId,
  payerEbillernetEbillerId,
  payerId,
  rppsBillerId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEBillEnabled = useEBillEnabledSelector(fspId, payerId);
  const autopay = useSelector((state) => selectorPayeeAutopay(state, id));

  const { statusDerived } = autopay;

  useEffect(() => {
    if (!statusDerived && autopayRuleAt) {
      dispatch(loadPayeeAutopay({ fspId, payeeId: id, payerId }));
    }
  }, [dispatch, autopayRuleAt, fspId, id, statusDerived, payerId]);

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl label="Name" value={name} />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl label="Nickname" value={nickName} />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              <DetailsRenderAddress
                address1={address1}
                address2={address2}
                fullAddress={fullAddress}
              />
            }
            label="Address"
            value={fullAddress}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Deliver Method"
            value={
              isOn(deliverElecVc)
                ? ELECTRONIC_VC
                : isOn(deliverElecStd)
                ? ELECTRONIC
                : isOn(deliverCheckStd)
                ? CHECK
                : undefined
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl label="Ontrac Biller ID" value={id} />
        </Grid>
        {ipayNetOffOn === ON && ipayNetBillerId && (
          <Grid item xs={3}>
            <ReadOnlyFormControl
              label="iPay Merchant ID"
              value={ipayNetBillerId}
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <ReadOnlyFormControl capitalize label="Status" value={payeeStatus} />
        </Grid>
        {isEBillEnabled && (
          <Grid item xs={3}>
            {ebillEnrollAt ? (
              <Detail
                billerAccountNumberMask={billerAccountNumberMask}
                fspId={fspId}
                name={name}
                payerEbillerAccountId={payerEbillerAccountId}
                payerEbillernetEbillerId={payerEbillernetEbillerId}
                payerId={payerId}
              />
            ) : (
              <ReadOnlyFormControl
                capitalize
                label="eBill"
                value={ebillStatus}
              />
            )}
          </Grid>
        )}
        {isEBillEnabled && (
          <Grid item xs={3}>
            {autopayRuleAt ? (
              <ReadOnlyFormControl
                inputNode={
                  statusDerived ? (
                    <ReadOnlyInputWithMenu
                      label={statusDerived}
                      menuNode={
                        <Autopay
                          transactionType={TRANSACTION_TYPE_BILL_PAY}
                          {...autopay}
                        />
                      }
                    />
                  ) : (
                    <Skeleton />
                  )
                }
                label={AUTOPAY}
                value={statusDerived || <Skeleton />}
              />
            ) : (
              <ReadOnlyFormControl label={AUTOPAY} value="Not Enabled" />
            )}
          </Grid>
        )}
        <Grid item xs={3}>
          <ReadOnlyFormControl label="RPPS ID" value={rppsBillerId} />
        </Grid>
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  autopayRuleAt: PropTypes.string,
  billerAccountNumberMask: PropTypes.string,
  deliverCheckStd: PropTypes.string,
  deliverElecStd: PropTypes.string,
  ebillEnrollAt: PropTypes.string,
  ebillStatus: PropTypes.string,
  deliverElecVc: PropTypes.string,
  fspId: PropTypes.number.isRequired,
  fullAddress: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  ipayNetBillerId: PropTypes.number,
  ipayNetOffOn: PropTypes.string,
  name: PropTypes.string.isRequired,
  nickName: PropTypes.string,
  payeeStatus: PropTypes.string.isRequired,
  payerEbillerAccountId: PropTypes.number,
  payerEbillernetEbillerId: PropTypes.number,
  payerId: PropTypes.number.isRequired,
  rppsBillerId: PropTypes.number,
};

DetailsRender.defaultProps = {
  autopayRuleAt: null,
  billerAccountNumberMask: null,
  deliverCheckStd: null,
  deliverElecStd: null,
  deliverElecVc: null,
  ebillEnrollAt: null,
  ebillStatus: NA_VALUE,
  ipayNetBillerId: null,
  ipayNetOffOn: null,
  nickName: null,
  payerEbillerAccountId: null,
  payerEbillernetEbillerId: null,
  rppsBillerId: null,
};

export default DetailsRender;
