import { GENERATE, LOAD } from 'model/oneTimePasscode';
import { generate, load } from 'sdk/oneTimePasscode';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    GENERATE.ACTION,
    callAction({
      api: generate,
      dataName: ORM,
      type: GENERATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );
}
