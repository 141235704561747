import PropTypes from 'prop-types';
import { Button, Tooltip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const EndAdornmentClearButton = ({ className, onClick }) => {
  return (
    <Tooltip disableFocusListener title="Clear Search">
      <Button className={className} onClick={onClick} size="small">
        <Clear fontSize="small" />
      </Button>
    </Tooltip>
  );
};

EndAdornmentClearButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EndAdornmentClearButton;
