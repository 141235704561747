import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import useRDFIInfo from './hooks';
import RDFInfoAccountNumberMask from './RDFInfoAccountNumberMask';

/**
 * RDFI - Receiving Deposit Financial Institution
 */
const RDFIInfo = ({ transaction }) => {
  const [show, mask, name, rtnNumber, isFIDirect] = useRDFIInfo(transaction);

  return show ? (
    <>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          inputNode={
            <RDFInfoAccountNumberMask
              fspId={transaction?.fspId}
              id={transaction?.id}
              isFIDirect={isFIDirect}
              value={mask}
            />
          }
          label="RDFI Account Mask"
          multiline
          value={mask}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="RDFI Name" multiline value={name} />
      </Grid>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="RDFI Routing Number"
          multiline
          value={rtnNumber}
        />
      </Grid>
    </>
  ) : null;
};

RDFIInfo.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default RDFIInfo;
