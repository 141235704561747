// eslint-disable-next-line camelcase
import { applyMiddleware, legacy_createStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import processPaginationMiddleware from 'middlewares/processPaginationMiddleware';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { fork, all } from 'redux-saga/effects';
import { persistStore } from 'redux-persist';
import getPersistedRootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const combineSagas = (sagas = []) => {
  return function* named() {
    yield all(sagas.map((saga) => fork(saga)));
  };
};

/**
 * Options
 * https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md
 */
const composeEnhancers = composeWithDevTools({
  latency: 750, // changing for performance of Redux DevTools - default is 500
  maxAge: 25, // changing for performance of Redux DevTools - default is 50
  shouldCatchErrors: true,
  trace: true,
  traceLimit: 25,
});

export default (options = {}) => {
  const {
    customOrmReducers = {},
    persistedState = {},
    reducers = {},
    sagas = [],
  } = options;

  // The root reducer must be created *before* the store is created
  const persistedRootReducer = getPersistedRootReducer(
    reducers,
    customOrmReducers
  );

  const store = legacy_createStore(
    persistedRootReducer,
    persistedState,
    composeEnhancers(
      applyMiddleware(sagaMiddleware, processPaginationMiddleware)
    )
  );

  sagaMiddleware.run(combineSagas(sagas));

  const persistor = persistStore(store);

  return { persistor, store };
};
