import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import { useEffect, useState } from 'react';
import { ON } from 'consts';
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { load } from 'model/oneTimePasscodeEvent';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/oneTimePasscodeEvent';
import { selectorGeneratedByPayerId as selectorGenerated } from 'selectors/oneTimePasscode';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {},
  menuItem: {
    '&:hover + $secondaryAction': {
      display: 'inherit',
    },
  },
  secondaryAction: {
    display: 'none',
    '&:hover': {
      display: 'inherit',
    },
  },
  select: {
    width: 300,
  },
}));

const Reasons = ({ onChange, payerId }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const { otp } = useSelector((state) => selectorGenerated(state, payerId));
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector(selector);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    dispatch(load());
  }, [dispatch]);

  return (
    <FormControl className={classes.root} fullWidth required>
      <InputLabel id="reasonSelectLabel">
        Choose a Reason for this OTP
      </InputLabel>
      <Select
        autoFocus
        className={classes.select}
        disabled={items.length === 0 || !isNil(otp)}
        fullWidth
        label="Issue OTP Reason"
        labelId="reasonSelectLabel"
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        required
        value={value}
      >
        {items.map(
          ({ description, id, otpEvent, otpOffOn }) =>
            otpOffOn === ON && (
              <MenuItem key={id} value={otpEvent}>
                <ListItemText primary={description} />
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};

Reasons.propTypes = {
  onChange: PropTypes.func.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default Reasons;
