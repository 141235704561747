import PropTypes from 'prop-types';
import {
  compose,
  lifecycle,
  pure,
  setPropTypes,
  setDisplayName,
  withProps,
} from 'recompose';
import { connect } from 'react-redux';
import { loadById } from 'model/payer';
import { profileSelector } from 'selectors/payer';

export default compose(
  setDisplayName('PayerContainer'),
  setPropTypes({
    fspId: PropTypes.number.isRequired,
  }),
  withProps(({ id, payerId }) => ({ id: payerId || id })),
  connect(
    (state, { id }) => ({
      payer: profileSelector(state, id),
    }),
    { loadById }
  ),
  lifecycle({
    componentDidMount() {
      const { payer, id } = this.props;

      if (payer.isEmpty && id) {
        const { fspId, loadById } = this.props;

        loadById({ fspId, id });
      }
    },
  }),
  pure
);
