import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { clear } from 'reducers/request';
import { selector } from 'selectors/request';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { useOpen } from 'hooks';
import ImportFormik from './ImportFormik';

const Import = ({
  ExpansionListProps,
  IMPORT,
  importAction,
  onImport,
  processFileImportData,
  section,
  title,
  validationSchema,
}) => {
  const [completed] = useSelector((state) => selector(state, IMPORT));
  const [loadDialog, setLoadDialog] = useState(false);
  const [open, handleToggleOpen] = useOpen();
  const dispatch = useDispatch();

  const handleMouseEnter = useCallback(() => {
    if (loadDialog === false) {
      setLoadDialog(true);
    }
  }, [loadDialog]);

  useEffect(() => {
    if (completed) {
      handleToggleOpen();
    }

    return () => {
      dispatch(clear(IMPORT));
    };
  }, [completed, dispatch, handleToggleOpen, IMPORT]);

  return (
    <>
      <Button
        onClick={handleToggleOpen}
        onMouseEnter={handleMouseEnter}
        variant="outlined"
      >
        Import
      </Button>
      <ImportFormik
        ExpansionListProps={ExpansionListProps}
        IMPORT={IMPORT}
        importAction={importAction}
        load={loadDialog}
        onImport={onImport}
        onRequestClose={handleToggleOpen}
        open={open}
        processFileImportData={processFileImportData}
        section={section}
        title={title}
        validationSchema={validationSchema}
      />
    </>
  );
};

Import.propTypes = {
  ExpansionListProps: PropTypes.object.isRequired,
  IMPORT: PropTypes.object.isRequired,
  importAction: PropTypes.func.isRequired,
  onImport: PropTypes.func,
  processFileImportData: PropTypes.func,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

Import.defaultProps = {
  onImport: undefined,
  processFileImportData: undefined,
};

export default Import;
