import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class RtnInfo extends Model {
  static get modelName() {
    return 'RtnInfo';
  }

  static reducer({ type, data }, RtnInfo) {
    switch (type) {
      case CHECK.ACTION: {
        const rtn = RtnInfo.withId(data.id);
        if (rtn) {
          rtn.selected = !rtn.selected;

          rtn.update(rtn);
        }
        break;
      }
      case CHECK_ALL.ACTION:
        RtnInfo.update({ selected: data.selected });
        break;
      case LOAD.SUCCESS:
        RtnInfo.delete();
        data.response.forEach((rtn) => RtnInfo.upsert(rtn));
        break;
      case LOAD_ROUTING_NUMBER.SUCCESS:
        RtnInfo.upsert(data.response);
        break;
      case LOAD.FAILURE:
        RtnInfo.delete();
        break;
      case REMOVE.SUCCESS:
        RtnInfo.withId(data.id).delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      href: attr(),
      id: attr(),
      name: attr(),
      routingNumber: attr(),
    };
  }
}

const name = RtnInfo.modelName;

export const ADD = createAddConst(name);
export const CHECK = createRequestConst(`${name}_check`);
export const CHECK_ALL = createRequestConst(`${name}_check_all`);
export const LOAD_ROUTING_NUMBER = createRequestConst(`${name}_routing_number`);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const check = createAction(CHECK);
export const checkAll = createAction(CHECK_ALL);
export const loadRoutingNumber = createAction(LOAD_ROUTING_NUMBER);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
