import PropTypes from 'prop-types';
import { memo } from 'react';
import { TableCell, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  stickyHeader: (hasPagination) => ({
    top: hasPagination ? 52 : 0,
  }),
});

const TableHeadCheckbox = ({
  checkAllAction,
  checkAllActionProps,
  hasPagination,
  selector,
  selectorProps,
}) => {
  const classes = useStyles(hasPagination);
  const dispatch = useDispatch();
  const items = useSelector((state) => selector(state, selectorProps)) || [];
  const itemsCount = items.length;
  const selectedItemsCount = items.filter(({ selected }) => selected).length;

  const { color, disabled } = checkAllActionProps;

  const handleChange = (event) => {
    dispatch(checkAllAction({ selected: event.target.checked }));
  };

  return checkAllAction ? (
    <TableCell classes={{ stickyHeader: classes.stickyHeader }}>
      <Checkbox
        {...checkAllActionProps}
        checked={itemsCount > 0 && selectedItemsCount === itemsCount}
        color={color ?? 'default'}
        disabled={itemsCount === 0 || disabled}
        indeterminate={
          selectedItemsCount > 0 && selectedItemsCount < itemsCount
        }
        onChange={handleChange}
      />
    </TableCell>
  ) : null;
};

TableHeadCheckbox.propTypes = {
  checkAllAction: PropTypes.func,
  checkAllActionProps: PropTypes.object.isRequired,
  hasPagination: PropTypes.bool,
  selector: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
};

TableHeadCheckbox.defaultProps = {
  checkAllAction: undefined,
  hasPagination: true,
  selectorProps: undefined,
};

export default memo(TableHeadCheckbox);
