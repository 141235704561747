import {
  always,
  compose,
  cond,
  equals,
  mapObjIndexed,
  pick,
  pickBy,
  toPairs,
} from 'ramda';
import { date, isNilOrEmpty } from '../../util';

export const OFFER_STATUS_ACCEPTED = 'accepted';
export const OFFER_STATUS_ACTIVE = 'active';
export const OFFER_STATUS_API_FAILED = 'apifailed';
export const OFFER_STATUS_API_SENT = 'apisent';
export const OFFER_STATUS_CANCELED = 'canceled';
export const OFFER_STATUS_CLICKED = 'clicked';
export const OFFER_STATUS_DECLINED = 'declined';
export const OFFER_STATUS_EXPIRED = 'expired';
export const OFFER_STATUS_IN_PROGRESS = 'inprogress';
export const OFFER_STATUS_NEGOTIATION_CANCELED = 'negotiationcancelled';
export const OFFER_STATUS_NEGOTIATION_FAILED = 'negotiationfailed';
export const OFFER_STATUS_NEGOTIATION_SUCCESSFUL = 'negotiationsuccessful';
export const OFFER_STATUS_SHARK_ASSIGNED = 'sharkassigned';

export const STAGE_STATUS_NEW = 'new';
export const STAGE_STATUS_ASSIGNED = 'assigned';
export const STAGE_STATUS_IN_PROGRESS = 'in_progress';
export const STAGE_STATUS_MISSING_DATA = 'missing_data';
export const STAGE_STATUS_ON_HOLD = 'on_hold';
export const STAGE_STATUS_DUPLICATE = 'duplicate';
export const STAGE_STATUS_REQUEST_CANCELED = 'request_cancelled';
export const STAGE_STATUS_INVALID_REQUEST = 'invalid_request';
export const STAGE_STATUS_COMPLETED_EXISTING_PROMOS =
  'completed_existing_promos';
export const STAGE_STATUS_COMPLETED_NO_SAVINGS = 'completed_no_savings';
export const STAGE_STATUS_COMPLETED_SAVINGS_ACHIEVED =
  'completed_savings_achieved';

export const OFFER_ACCEPTED_AT = 'acceptedAt';
export const OFFER_ACTIVE_AT = 'activeAt';
export const OFFER_API_FAILED_AT = 'apiFailedAt';
export const OFFER_API_SENT_AT = 'apiSentAt';
export const OFFER_CANCELED_AT = 'canceledAt';
export const OFFER_CLICKED_AT = 'clickedAt';
export const OFFER_DECLINED_AT = 'declinedAt';
export const OFFER_EXPIRED_AT = 'expiredAt';
export const OFFER_IN_PROGRESS_AT = 'inProgressAt';
export const OFFER_NEGOTIATION_CANCELED_AT = 'negotiationCanceledAt';
export const OFFER_NEGOTIATION_FAILED_AT = 'negotiationFailedAt';
export const OFFER_NEGOTIATION_SUCCESSFUL_AT = 'negotiationSuccessfulAt';
export const OFFER_SHARK_ASSIGNED_AT = 'sharkAssignedAt';

export const offerStatus = {
  [OFFER_STATUS_ACCEPTED]: 'Submitted',
  [OFFER_STATUS_ACTIVE]: 'Active',
  [OFFER_STATUS_API_FAILED]: 'Submitted',
  [OFFER_STATUS_API_SENT]: 'Submitted',
  [OFFER_STATUS_CANCELED]: 'Canceled',
  [OFFER_STATUS_CLICKED]: 'Active',
  [OFFER_STATUS_DECLINED]: 'Declined',
  [OFFER_STATUS_EXPIRED]: 'Expired',
  [OFFER_STATUS_IN_PROGRESS]: 'In Progress',
  [OFFER_STATUS_NEGOTIATION_CANCELED]: 'Negotiation Canceled',
  [OFFER_STATUS_NEGOTIATION_FAILED]: 'Negotiation Failed',
  [OFFER_STATUS_NEGOTIATION_SUCCESSFUL]: 'Negotiation Successful',
  [OFFER_STATUS_SHARK_ASSIGNED]: 'Assigned',
};

export const stageStatuses = [
  STAGE_STATUS_NEW,
  STAGE_STATUS_ASSIGNED,
  STAGE_STATUS_IN_PROGRESS,
  STAGE_STATUS_MISSING_DATA,
  STAGE_STATUS_ON_HOLD,
  STAGE_STATUS_DUPLICATE,
  STAGE_STATUS_REQUEST_CANCELED,
  STAGE_STATUS_INVALID_REQUEST,
  STAGE_STATUS_COMPLETED_EXISTING_PROMOS,
  STAGE_STATUS_COMPLETED_NO_SAVINGS,
  STAGE_STATUS_COMPLETED_SAVINGS_ACHIEVED,
];

const submittedDate = 'Submitted Date';
export const offerStatusDateMap = {
  [OFFER_ACCEPTED_AT]: submittedDate,
  [OFFER_ACTIVE_AT]: 'Offer Date',
  [OFFER_API_FAILED_AT]: submittedDate,
  [OFFER_API_SENT_AT]: submittedDate,
  [OFFER_CANCELED_AT]: 'Canceled Date',
  [OFFER_CLICKED_AT]: 'Clicked Date',
  [OFFER_DECLINED_AT]: 'Declined Date',
  [OFFER_EXPIRED_AT]: 'Expired Date',
  [OFFER_IN_PROGRESS_AT]: 'In Progress Date',
  [OFFER_NEGOTIATION_CANCELED_AT]: 'Negotiation Canceled Date',
  [OFFER_NEGOTIATION_FAILED_AT]: 'Negotiation Failed Date',
  [OFFER_NEGOTIATION_SUCCESSFUL_AT]: 'Negotiation Successful Date',
  [OFFER_SHARK_ASSIGNED_AT]: 'Assigned Date',
};

export const getOfferStatusDetailLabel = ({
  acceptedAt,
  canceledAt,
  negNewContractEndDate,
  negNewContractStartDate,
  negotiationCanceledAt,
  status,
  payeeName,
  stage1New,
  stage2Assigned,
  stage3InProgress,
  stage4Completed,
  totalSavingsLabel,
}) => {
  let detail = '';

  const lookupStageStatus =
    stage4Completed || stage3InProgress || stage2Assigned || stage1New;

  if (lookupStageStatus) {
    const status = stageStatuses.find((status) => status === lookupStageStatus);
    detail = cond([
      [
        equals(STAGE_STATUS_COMPLETED_SAVINGS_ACHIEVED),
        always(
          `Negotiation has been successfully completed. Payer saved ${totalSavingsLabel}. Savings start date ${date(
            negNewContractStartDate
          )}. Savings expiration date ${date(negNewContractEndDate)}`
        ),
      ],
      [
        equals(STAGE_STATUS_COMPLETED_NO_SAVINGS),
        always(
          `This bill was negotiated but no savings were available as the payer already has the best rate for their plan`
        ),
      ],
      [
        equals(STAGE_STATUS_COMPLETED_EXISTING_PROMOS),
        always(
          `This bill was negotiated but no savings were available as the payer already has the best rate for their plan`
        ),
      ],
      [
        equals(STAGE_STATUS_INVALID_REQUEST),
        always(
          `This bill negotiation order was for an unsupported bill type or biller. Billshark was unable to negotiate this bill. ` +
            `Payer needs to contact Billshark at 800-957-1710 or support@billshark.com for any additional details.`
        ),
      ],
      [
        equals(STAGE_STATUS_REQUEST_CANCELED),
        always(
          `This bill negotiation order has been canceled. ` +
            `Payer canceled it on ${date(
              canceledAt || negotiationCanceledAt
            )} ` +
            `OR Billshark canceled this bill negotiation request as they do not have additional information needed to complete this negotiation. ` +
            `Payer needs to contact Billshark at 800-957-1710 or support@billshark.com to restart the negotiation`
        ),
      ],
      [
        equals(STAGE_STATUS_DUPLICATE),
        always(
          'This bill negotiation order is a duplicate order. Billshark has already received or negotiated this bill. Payer needs to contact Billshark at 800-957-1710 or support@billshark.com'
        ),
      ],
      [
        equals(STAGE_STATUS_ON_HOLD),
        always(
          'This bill negotiation order is on hold for up to 30 days pending new promos. Awaiting further updates from Billshark. For additional details payer can contact Billshark at 800-957-1710 or support@billshark.com'
        ),
      ],
      [
        equals(STAGE_STATUS_MISSING_DATA),
        always(
          'Billshark is missing information needed for successfully completing this negotiation. Payer needs to call Billshark at 800-957-1710 or support@billshark.com'
        ),
      ],
      [
        equals(STAGE_STATUS_IN_PROGRESS),
        always(
          'Billshark is actively working on this bill negotiation request. Request is in progress'
        ),
      ],
      [
        equals(STAGE_STATUS_ASSIGNED),
        always(
          'Bill negotiation has been assigned for processing. Billshark assigned a shark to negotiate on behalf of the payer'
        ),
        [
          equals(STAGE_STATUS_NEW),
          always(
            `Payer placed an order on ${date(
              acceptedAt
            )} for Billshark to negotiate with ${payeeName}`
          ),
        ],
      ],
    ])(status);
  }

  if (isNilOrEmpty(detail)) {
    switch (status) {
      case OFFER_STATUS_ACTIVE:
      case OFFER_STATUS_CLICKED:
        detail = 'Payer has been shown offer but has not yet acted on it';
        break;
      case OFFER_STATUS_DECLINED:
        detail = 'Payer declined the offer';
        break;
      case OFFER_STATUS_EXPIRED:
        detail = 'Payer was shown offer but did not take action';
        break;
      case OFFER_STATUS_ACCEPTED:
      case OFFER_STATUS_API_SENT:
      case OFFER_STATUS_API_FAILED:
        detail = `Payer placed an order on ${date(
          acceptedAt
        )} for Billshark to negotiate with ${payeeName}`;
        break;
      default:
        detail = 'N/A';
        break;
    }
  }

  return detail;
};

export const getOfferStatusDates = (item) =>
  compose(
    toPairs,
    mapObjIndexed((val) => date(val)),
    pickBy((val) => !isNilOrEmpty(val)),
    pick([
      OFFER_ACTIVE_AT,
      OFFER_DECLINED_AT,
      OFFER_ACCEPTED_AT,
      OFFER_SHARK_ASSIGNED_AT,
      OFFER_CANCELED_AT,
      OFFER_NEGOTIATION_CANCELED_AT,
      OFFER_IN_PROGRESS_AT,
      OFFER_NEGOTIATION_SUCCESSFUL_AT,
      OFFER_NEGOTIATION_FAILED_AT,
      OFFER_EXPIRED_AT,
    ])
  )(item);
