import { client } from 'util/sdk';
import { sortableFields } from 'model/researchNote';

export const load = ({ fspId, trrId, order = sortableFields[0].value }) => {
  const [sortQuery, sortOrder] = sortableFields.find(
    ({ value }) => value === order
  ).query;

  return client
    .fsps(fspId)
    .researchNotes.query((_) => [
      _.transactionHistoryResearchRequestId(trrId),
      _[sortQuery](sortOrder),
    ]);
};

export const create = ({ fspId, noteObj, trrId }, auth) =>
  client
    .fsps(fspId)
    .transactionHistoryResearchRequests(trrId)
    .researchNotes.create(noteObj, { adminUserId: auth.id });

export const remove = ({ fspId, id }, auth) =>
  client.fsps(fspId).researchNotes(id).delete(auth);

export const update = ({ fspId, id, noteObj }, auth) =>
  client
    .fsps(fspId)
    .researchNotes(id)
    .update(noteObj, { adminUserId: auth.id });
