import {
  BILLER_MANAGEMENT_CODE,
  DUPLICATE_OFF_ON,
  FRAUD_MONITOR_CODE,
  OFF,
  ON,
  PAYEE_CODE,
  REMATCH_CODE,
  TRANSACTION_REVIEW_CODE,
} from 'consts';
import { createAction, createUpdateConst } from 'util/actions';
import { mergeDeepLeft } from 'ramda';

const initialBillerManagementState = {
  [BILLER_MANAGEMENT_CODE]: {
    [PAYEE_CODE]: { [DUPLICATE_OFF_ON]: ON },
    [REMATCH_CODE]: { [DUPLICATE_OFF_ON]: ON },
  },
};
const initialState = {
  ...initialBillerManagementState,
  [FRAUD_MONITOR_CODE]: {},
  [TRANSACTION_REVIEW_CODE]: {},
};

export const getDuplicatePersistedStateData = (event, section) => ({
  [BILLER_MANAGEMENT_CODE]: {
    [section]: { [DUPLICATE_OFF_ON]: event.target.checked ? ON : OFF },
  },
});
export const getDuplicateOffOn = (obj, section) => {
  return obj[BILLER_MANAGEMENT_CODE][section]?.[DUPLICATE_OFF_ON];
};

export const name = 'persistedState';
export const selector = (state) => state[name];
export const UPDATE = createUpdateConst(name);
export const update = createAction(UPDATE);

export default (state = initialState, { data, type }) => {
  switch (type) {
    case UPDATE.ACTION:
      return mergeDeepLeft(data, state);

    default:
      return state;
  }
};
