import { isLoadingState as loading } from 'reducers/loading';
import { useSelector } from 'react-redux';

export default ({ actionType, actionTypeSecond }) => {
  const loadingSelector = (s) => loading(s, actionType.ACTION);
  const loadingSecondSelector = (s) => loading(s, actionTypeSecond.ACTION);

  const isLoading = useSelector(loadingSelector);
  const isLoadingSecond = useSelector(loadingSecondSelector);

  return { isLoading, isLoadingSecond };
};
