import MuiTableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import TableBodyNoContent from './TableBodyNoContent';

const TableBody = ({
  NoContentProps,
  TableRow,
  TableRowProps,
  actionType,
  columnCount,
  selector,
  selectorProps,
}) => {
  const items = useSelector((state) => selector(state, selectorProps));

  return (
    <MuiTableBody>
      <TableBodyNoContent
        actionType={actionType}
        columnCount={columnCount}
        count={items?.length}
        {...NoContentProps}
      />
      {items?.map(({ id, ...props }) => (
        <TableRow key={id} id={id} {...TableRowProps} {...props} />
      ))}
    </MuiTableBody>
  );
};

TableBody.propTypes = {
  NoContentProps: PropTypes.object,
  TableRow: PropTypes.elementType.isRequired,
  TableRowProps: PropTypes.object,
  actionType: PropTypes.object.isRequired,
  columnCount: PropTypes.number.isRequired,
  selector: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
};

TableBody.defaultProps = {
  NoContentProps: undefined,
  TableRowProps: undefined,
  selectorProps: undefined,
};

export default memo(TableBody);
