import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { isNilOrEmpty } from 'util/index';
import EndAdornmentClearButton from './EndAdormentClearButton';
import EndAdornmentSelect from './EndAdormentSelect';

const useStyles = makeStyles(() => ({
  clearButton: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
}));

const EndAdornment = ({ onClear, param, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <EndAdornmentClearButton
        className={classNames(classes.clearButton, {
          [classes.visible]: !isNilOrEmpty(param?.value),
        })}
        onClick={onClear}
      />
      <EndAdornmentSelect param={param} {...props} />
    </>
  );
};

EndAdornment.propTypes = {
  onClear: PropTypes.func.isRequired,
  param: PropTypes.object,
};

EndAdornment.defaultProps = {
  param: null,
};

export default EndAdornment;
