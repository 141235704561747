import Payer from 'model/payer';
import { attr, fk, Model } from 'redux-orm';
import { addDerived, DATA_KEY } from './util/recipient';
import { createAction, createRequestConst, createUpdateConst } from '../util';

export default class Recipient extends Model {
  static get modelName() {
    return 'Recipient';
  }

  static reducer({ type, data }, Recipient, { Payer }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Payer.idExists(data?.props?.payerId)) {
          Payer.withId(data.props.payerId)[DATA_KEY].delete();
        }

        if (data?.response) {
          data.response.forEach((r) => Recipient.upsert(addDerived(r)));
        }
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        Recipient.upsert(addDerived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      accountType: attr(),
      coreAccountNumberMask: attr(),
      coreAccountNumberSuffix: attr(),
      coreAccountType: attr(),
      coreDisburseService: attr(),
      coreRtn: attr(),
      createdAt: attr(),
      dcAccountMask: attr(),
      dcAccountToken: attr(),
      dcAccountType: attr(),
      dcExpireMonth: attr(),
      dcExpireYear: attr(),
      dcFeeAmount: attr(),
      dcHolderName: attr(),
      ddaAchRtn: attr(),
      ddaNbrMask: attr(),
      deliver: attr(),
      deliverElecNowOffOn: attr(),
      deliverElecStdOffOn: attr(),
      deliverMethod: attr(),
      disburseType: attr(),
      disburseTypeDerived: attr(), // derived
      elecName: attr(),
      email: attr(),
      failReason: attr(),
      firstName: attr(),
      fsp: attr(),
      fspId: attr(),
      group: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      lastName: attr(),
      lastScheduleCreatedAt: attr(),
      lastTryCnt: attr(),
      lastTryCntAt: attr(),
      maxTries: attr(),
      microService: attr(),
      mobileDisplay: attr(), // derived
      mobilePhone: attr(),
      name: attr(), // derived
      nickName: attr(),
      notificationMethodDerived: attr(), // derived
      notifyEmailOffOn: attr(),
      notifyMobilePhoneOffOn: attr(),
      offset: attr(),
      p2pDirectOffOn: attr(),
      payer: attr(),
      payerId: fk(Payer.modelName, DATA_KEY),
      previousRecipientStatus: attr(),
      recipientStatus: attr(),
      recipientStatusAt: attr(),
      recipientStatusText: attr(),
      rtnFiName: attr(),
      securityAnswer: attr(),
      securityQuestion: attr(),
      status: attr(), // derived
      updUserId: attr(),
      updatedAt: attr(),
      validEmail: attr(),
      validFirstName: attr(),
      validLastName: attr(),
      validMobilePhone: attr(),
      validNotifyEmailOffOn: attr(),
      validNotifyMobilePhoneOffOn: attr(),
      venmoReceiver: attr(),
      venmoRecipientType: attr(),
    };
  }
}

const { modelName } = Recipient;
export const LOAD = createRequestConst(modelName);
export const LOAD_ID = createRequestConst(`${modelName}_id`);
export const UPDATE = createUpdateConst(modelName);

export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const update = createAction(UPDATE);
