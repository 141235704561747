import PropTypes from 'prop-types';
import Rule from './Rule';

const MinRule = ({ password, ...props }) => {
  return (
    <Rule
      {...props}
      enabled={password.length >= 8}
      text="at least 8 characters"
    />
  );
};

MinRule.propTypes = {
  password: PropTypes.string.isRequired,
};

export default MinRule;
