import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Lock, LockOpen } from '@material-ui/icons';
import { Avatar, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  enabled: {
    backgroundColor: 'darkgreen',
  },
  disabled: {
    backgroundColor: theme.palette.grey[200],
  },
  unlocked: {
    color: theme.palette.primary.contrastText,
  },
});

const PasswordRules = ({ classes, enabled }) => {
  return (
    <Avatar
      className={classNames({
        [classes.disabled]: !enabled,
        [classes.enabled]: enabled,
      })}
    >
      {enabled ? (
        <Lock className={classes.unlocked} />
      ) : (
        <LockOpen color="disabled" />
      )}
    </Avatar>
  );
};

PasswordRules.propTypes = {
  classes: PropTypes.object.isRequired,
  enabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PasswordRules);
