import PropTypes from 'prop-types';
import { createRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import scrollIntoView from 'scroll-into-view';
import { ExpandMore } from '@material-ui/icons';
import { NA_VALUE } from 'consts';
import { SCROLL_DELAY } from 'util/format';
import { useDispatch, useSelector } from 'react-redux';
import { selectorId } from 'selectors/serviceNote';
import { loadUser } from 'model/ontracUser';
import { selector as orgSelectorId } from 'selectors/org';
import { selectorId as userSelectorId } from 'selectors/ontracUser';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoteEditor from 'components/common/Note/NoteEditor';
import { ConsumerNotificationNoteType } from 'components/common/Note/consts';
import NoteEditButton from 'components/common/Note/NoteEditButton';

const subheaderTypographyProps = { color: 'textSecondary', variant: 'body1' };
const titleTypographyProps = { color: 'textPrimary', variant: 'h6' };

const useStyles = makeStyles((theme) => ({
  actionButton: {
    marginTop: theme.spacing(1),
  },
  card: {
    width: '100%',
  },
  cardIsPrivate: {
    borderLeft: `${theme.spacing(1)}px solid ${theme.palette.error.light}`,
  },
  expand: {
    marginRight: theme.spacing(4),
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  editor: {
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  consumerNotification: {
    color: theme.palette.error.light,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  shake: {
    width: '100%',
  },
}));

const Note = ({ fspId, id, index, shake }) => {
  const [expanded, setExpanded] = useState(true);
  const [noteType, setNoteType] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const item = useSelector((state) => selectorId(state, id)) || {};
  const user =
    useSelector((state) => userSelectorId(state, item.insAdminUserId)) ?? {};
  const org = useSelector((state) => orgSelectorId(state, user.organizationId));
  const classes = useStyles();
  const dispatch = useDispatch();

  const isConsumerNotification = noteType === ConsumerNotificationNoteType;
  const scrollRef = createRef();
  const subheader = item.createdAtDerived;
  const title = `${item.insAdminUserName ? item.insAdminUserName : NA_VALUE}, ${
    org?.name ? org?.name?.trim() : NA_VALUE
  }`;

  const handleEditorCancel = () => {
    setReadOnly(!readOnly);
    setNoteType(item.noteType);
  };
  const handleEditorSubmit = () => {
    setReadOnly(!readOnly);

    if (!expanded) {
      setExpanded(true);
    }
  };
  const handleEditorChangeType = (type) => {
    setNoteType(type);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setTimeout(() => {
        scrollIntoView(scrollRef.current);
      }, SCROLL_DELAY);
    }
  };

  useEffect(() => {
    if (item.insAdminUserId) {
      dispatch(loadUser({ id: item.insAdminUserId }));
    }
  }, [dispatch, item.insAdminUserId]);

  useEffect(() => {
    setNoteType(item.noteType);
  }, [item.noteType]);

  return (
    <ListItem dense>
      <div
        className={classNames(classes.shake, {
          [classes.highlightMe]: shake && index === 0,
        })}
      >
        <Card
          className={classNames(classes.card, {
            [classes.cardIsPrivate]: isConsumerNotification,
          })}
        >
          <CardHeader
            action={
              <Grid alignItems="center" container>
                {isConsumerNotification && (
                  <Grid item>
                    <Typography
                      className={classes.consumerNotification}
                      variant="subtitle1"
                    >
                      *Consumer Notification Note
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <NoteEditButton
                    item={item}
                    onClick={handleEditorSubmit}
                    readOnly={readOnly}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    aria-expanded={expanded}
                    aria-label="Show more"
                    className={classNames(
                      classes.actionButton,
                      classes.expand,
                      {
                        [classes.expandOpen]: expanded,
                      }
                    )}
                    onClick={handleExpandClick}
                  >
                    <ExpandMore />
                  </IconButton>
                </Grid>
              </Grid>
            }
            subheader={subheader}
            subheaderTypographyProps={subheaderTypographyProps}
            title={title}
            titleTypographyProps={titleTypographyProps}
          />
          <div ref={scrollRef} />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.editor}>
              <NoteEditor
                fspId={fspId}
                id={id}
                onCancel={handleEditorCancel}
                onChangeType={handleEditorChangeType}
                onSubmit={handleEditorSubmit}
                readOnly={readOnly}
              />
            </CardContent>
          </Collapse>
        </Card>
      </div>
    </ListItem>
  );
};

Note.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.node,
  index: PropTypes.number.isRequired,
  shake: PropTypes.bool,
};

Note.defaultProps = {
  id: null,
  shake: false,
};

export default Note;
