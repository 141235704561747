import {
  createAction,
  createLoadConst,
  createRequestConst,
} from 'util/actions';

const initialState = { open: true, show: true };
export const name = 'recentTicketsPaymentOps';

export const TOGGLE = createRequestConst(name);
export const TOGGLE_TIME = createLoadConst(name);

export const toggle = createAction(TOGGLE);
export const toggleTime = createAction(TOGGLE_TIME);

export const selector = (state) => state[name];

export default (state = initialState, { data, type }) => {
  switch (type) {
    case TOGGLE.ACTION:
      return { ...state, open: data };

    case TOGGLE_TIME.ACTION:
      return { ...state, show: data };

    default:
      return state;
  }
};
