import {
  AUTH_DENY,
  AUTH_FAIL,
  AUTHORIZED,
  CANCELED,
  DELIVERED,
  EXPIRED,
  INACTIVE,
  PAY_FAILED,
  PROCESSED,
  READY_TO_SEND,
  REFUNDED,
  RETURNED,
  USPS_RETURNED,
  CLEAR_FAILED,
} from 'consts';

/**
 * Values here are from terminal values in the
 * Transaction States Diagram 08/06/2018
 */
export default [
  { name: 'none', value: '' },
  { name: 'Processed', value: PROCESSED },
  { name: 'Inactive', value: INACTIVE },
  { name: 'Canceled', value: CANCELED },
  { name: 'Expired', value: EXPIRED },
  { name: 'Authorization Failed', value: AUTH_FAIL },
  { name: 'Authorization Denied', value: AUTH_DENY },
  { name: 'Pay Failed', value: PAY_FAILED },
  { name: 'Authorized', value: AUTHORIZED },
  { name: 'Delivered', value: DELIVERED },
  { name: 'Ready to send', value: READY_TO_SEND },
  { name: 'Returned', value: RETURNED },
  { name: 'USPS Returned', value: USPS_RETURNED },
  { name: 'Clear Failed', value: CLEAR_FAILED },
  { name: 'Refunded', value: REFUNDED }, // https://payrailz.atlassian.net/browse/ON-624
];
