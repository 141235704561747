import Snackbar from '@material-ui/core/Snackbar';
import { deleteMessage, selector } from 'reducers/successMessage';
import { isNilOrEmpty } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import SlideUp from '../SlideUp';

const anchorOrigin = { horizontal: 'left', vertical: 'bottom' };
const autoHideDuration = 5000; // 5 seconds

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
}));

const Success = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const messageValue = useSelector(selector);

  const error = messageValue?.error ?? false;
  const message = messageValue?.message ?? messageValue;
  const ContentProps = error ? { classes: { root: classes.error } } : undefined;

  const handleClose = () => {
    dispatch(deleteMessage());
    setOpen(false);
  };

  useEffect(() => {
    const shouldOpen = !isNilOrEmpty(message);

    if (shouldOpen) {
      setOpen(true);
    }
  }, [message]);

  return (
    <Snackbar
      key={messageValue}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      ContentProps={ContentProps}
      disableWindowBlurListener
      message={message}
      onClose={handleClose}
      open={open}
      TransitionComponent={SlideUp}
    />
  );
};

export default Success;
