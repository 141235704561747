import PropTypes from 'prop-types';
import { Mask } from 'components/common';
import { unmask } from 'sdk/user';

const MaskFSPUserId = ({ item, ...props }) => {
  return <Mask api={() => unmask(item)} {...props} />;
};

MaskFSPUserId.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MaskFSPUserId;
