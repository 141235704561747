import flattenObjects from 'util/flattenObjects';
import { addOrUpdate } from 'model/util/userRole';
import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';
import {
  FSPS,
  RESET_USER_ROLES,
  USER_ROLE_CODE,
  USER_ROLES_CODE,
} from 'consts';
import { compose, map, pick } from 'ramda';

// Do not change the order of fields because the api requires that fields are sent
// in this particular order when it comes to reset endpoints
const importFields = [
  'role',
  'bpPayeeGetPrivOffOn',
  'bpPayeeOnholdPrivOffOn',
  'bpPayeeReleasePrivOffOn',
  'bpPayeeAllPrivOffOn',
  'bpPaymentGetPrivOffOn',
  'bpPaymentOnholdPrivOffOn',
  'bpPaymentReleasePrivOffOn',
  'bpPaymentAllPrivOffOn',
  'a2aExternalDdaGetPrivOffOn',
  'a2aExternalDdaOnholdPrivOffOn',
  'a2aExternalDdaReleasePrivOffOn',
  'a2aExternalDdaAllPrivOffOn',
  'a2aTransferFromGetPrivOffOn',
  'a2aTransferFromOnholdPrivOffOn',
  'a2aTransferFromReleasePrivOffOn',
  'a2aTransferFromAllPrivOffOn',
  'a2aTransferToGetPrivOffOn',
  'a2aTransferToOnholdPrivOffOn',
  'a2aTransferToReleasePrivOffOn',
  'a2aTransferToAllPrivOffOn',
  'p2pRecipientGetPrivOffOn',
  'p2pRecipientOnholdPrivOffOn',
  'p2pRecipientReleasePrivOffOn',
  'p2pRecipientAllPrivOffOn',
  'p2pPaymentGetPrivOffOn',
  'p2pPaymentOnholdPrivOffOn',
  'p2pPaymentReleasePrivOffOn',
  'p2pPaymentAllPrivOffOn',
];
const returnModel = { name: USER_ROLE_CODE };

const flattenData = (objs) => ({
  [USER_ROLE_CODE]: compose(
    map(pick(importFields)),
    flattenObjects,
    map(addOrUpdate)
  )(objs),
});

const importURL = (fspId) => `/${FSPS}/${fspId}/${RESET_USER_ROLES}`;
const loadURL = (fspId) => `/${FSPS}/${fspId}/${USER_ROLES_CODE}`;
const removeOrUpdateURL = (id, fspId) =>
  `/${FSPS}/${fspId}/${USER_ROLES_CODE}/${id}`;

export const add = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${USER_ROLES_CODE}`, {
    data: addOrUpdate(data, false),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const load = ({ fspId }) =>
  client.executeRequest(GET, loadURL(fspId), { returnModel });

export const remove = ({ id, fspId }, auth) =>
  client.executeRequest(DELETE, removeOrUpdateURL(id, fspId), {
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const update = ({ id, fspId, ...data }, auth) =>
  client.executeRequest(POST, removeOrUpdateURL(id, fspId), {
    data: addOrUpdate(data, true),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });

export const importAndReset = ({ fspId, userrole }, auth) =>
  client.executeRequest(POST, importURL(fspId), {
    data: flattenData(userrole),
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
