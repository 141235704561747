import {
  ParamSearchType,
  onOffDescription,
} from 'components/common/SearchTextField/Multiple/consts';
import { formatCurrency } from 'util/index';
import { formatDateRange } from 'util/dateAndTime';

const getDisplayNameForOptions = (options, idValue) => {
  return options.find(({ id }) => id === idValue).displayName;
};

const getDisplayNameForDate = (value) => {
  const [from, to] = value ?? [];
  if (from && to) {
    return formatDateRange(from, to);
  }
  return from;
};

const getDisplayNameForAmount = (value) => {
  const [from, to] = value ?? [];
  if (from && to) {
    return `${formatCurrency(from)}–${formatCurrency(to)}`;
  }
  return formatCurrency(from);
};

const getDisplayNameForBoolean = (value) => onOffDescription[value].displayName;

const getDisplayNameForNumberRange = (value) => {
  const [from, to] = value ?? [];

  if (from && to) {
    return `${from} – ${to}`;
  }
  return from;
};

export const getDisplayValue = ({ value, type, valueOptions }) => {
  switch (type) {
    case ParamSearchType.Option:
      return getDisplayNameForOptions(valueOptions, value);

    case ParamSearchType.MultiOption:
      return value
        .map((idValue) => getDisplayNameForOptions(valueOptions, idValue))
        .join(', ');

    case ParamSearchType.Date:
      return getDisplayNameForDate(value);

    case ParamSearchType.Boolean:
      return getDisplayNameForBoolean(value);

    case ParamSearchType.Amount:
      return getDisplayNameForAmount(value);

    case ParamSearchType.NumberRange:
      return getDisplayNameForNumberRange(value);

    default:
      return value;
  }
};

export const isParamValid = ({ type, value }) => {
  switch (type) {
    case ParamSearchType.Amount:
    case ParamSearchType.Date:
    case ParamSearchType.NumberRange:
      return !!(value && value[0]);

    case ParamSearchType.MultiOption:
      return value && value.length > 0;

    default:
      return !!value;
  }
};
