import CheckIcon from '@material-ui/icons/Check';
import { withProps } from 'recompose';
import { withStyles } from '@material-ui/core';
import IconButton from './icon_button';

const styles = () => ({
  root: {
    '&:hover': {
      background: '#ddd',
    },
  },
  iconComponent: {},
  label: {},
});

export default withProps(() => ({ IconComponent: CheckIcon }))(
  withStyles(styles)(IconButton)
);
