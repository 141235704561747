import { loadDenyListOfferings } from 'sdk/fspDenyListOfferings';
import { LOAD_DENY_LIST_OFFERINGS } from 'model/fspDenyListOfferings';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD_DENY_LIST_OFFERINGS.ACTION,
    callAction({
      api: loadDenyListOfferings,
      dataName: ORM,
      type: LOAD_DENY_LIST_OFFERINGS,
    })
  );
}
