import { LOAD } from 'model/switch';
import { LOAD as LOAD_MATCH } from 'model/switchMatch';
import { switchBillerInfo, matchPayees } from 'sdk/switch';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: switchBillerInfo,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_MATCH.ACTION,
    callAction({
      api: matchPayees,
      dataName: ORM,
      type: LOAD_MATCH,
    })
  );
}
