import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class Report extends Model {
  static get modelName() {
    return 'Report';
  }

  static reducer({ type, data }, Report) {
    switch (type) {
      case LOAD.SUCCESS:
        Report.delete();
        data.report.forEach((r) => Report.upsert(r));
        break;

      case LOAD.FAILURE:
        Report.delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      adminOrgId: attr(),
      channelId: attr(),
      createdAt: attr(),
      displayName: attr(),
      distributorId: attr(),
      downloadFileName: attr(),
      format: attr(),
      frequency: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      name: attr(),
      offset: attr(),
      reportModelId: attr(),
      reportOn: attr(),
      reportOnLabel: attr(), // derived
      reportTypeId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createRequestConst(Report.modelName);
export const load = createAction(LOAD);
