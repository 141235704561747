import { omit } from 'ramda';
import { client } from 'util/sdk';

export default async ({ fspId, payerId, userId }) => {
  const [fspConfig] = await client
    .fsps(fspId)
    .users(userId)
    .fspConfigs.getAll();

  return { id: payerId, payerId, userId, ...omit(['id'], fspConfig) };
};
