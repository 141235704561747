import TableConnected from 'components/common/table/tableConnected';
import selector from 'selectors/actionableAlert';
import { LOAD, load } from 'model/actionableAlert';

export const COLUMN_META = [
  {
    id: 'createdAtDerived',
    label: 'Create On',
  },
  {
    id: 'eventName',
    label: 'Message Type',
  },
  {
    id: 'renderedMessage',
    label: 'Message Description',
  },
  {
    capitalize: true,
    id: 'statusDerived',
    label: 'Status',
  },
  {
    id: 'actionAt',
    label: 'Action On',
  },
  {
    id: 'expireDate',
    label: 'Expire Date',
  },
];

export default TableConnected({
  actionType: LOAD.ACTION,
  selector,
  successType: LOAD.SUCCESS,
  loadAction: load,
  settingType: 'actionableAlerts',
  columnMeta: COLUMN_META,
  order: 'desc',
  orderBy: 'id',
  emptyMessage: 'No Actionable Alerts Available',
});
