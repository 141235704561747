import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { isNilOrEmpty } from 'util/index';
import TextFieldRoutingNumber from 'components/common/fields/TextFieldRoutingNumber';
import { load as loadAchCodes, selectorReturn } from 'reducers/achCode';
import { always } from 'ramda';
import {
  nocCodes,
  INCORRECT_ACCOUNT_TYPE,
  INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
  INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
  INCORRECT_ACCOUNT_NUMBER,
  INCORRECT_ROUTING_NUMBER,
  INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
} from '../../config';
import { NOC } from '../consts';

const StepUpdate = ({
  changeType,
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}) => {
  const dispatch = useDispatch();
  const returnAchCodes = useSelector(selectorReturn);
  const [accountTypeRequired, setAccountTypeRequired] = useState(false);
  const [accountNumberRequired, setAccountNumberRequired] = useState(false);
  const [routingNumberRequired, setRoutingNumberRequired] = useState(false);

  const handleNocCodeChange = (event) => {
    handleChange(event);

    const value = event?.target?.value;

    setAccountTypeRequired(
      [
        INCORRECT_ACCOUNT_TYPE,
        INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
        INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
      ].includes(value)
    );

    setAccountNumberRequired(
      [
        INCORRECT_ACCOUNT_NUMBER,
        INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
        INCORRECT_ACCOUNT_NUMBER_AND_ACCOUNT_TYPE,
        INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
      ].includes(value)
    );

    setRoutingNumberRequired(
      [
        INCORRECT_ROUTING_NUMBER,
        INCORRECT_ACCOUNT_NUMBER_AND_ROUTING_NUMBER,
        INCORRECT_ACCOUNT_NUMBER_ROUTING_NUMBER_AND_ACCOUNT_TYPE,
      ].includes(value)
    );
  };

  useEffect(() => {
    dispatch(loadAchCodes());
  }, [dispatch]);

  return (
    <form>
      {changeType === NOC ? (
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="nocCode">NOC Code</InputLabel>
              <Select
                error={touched.nocCode && !isNilOrEmpty(errors.nocCode)}
                fullWidth
                inputProps={{ id: 'nocCode', name: 'nocCode' }}
                label="NOC Code"
                onBlur={handleBlur}
                onChange={handleNocCodeChange}
                required
                value={values.nocCode}
              >
                <MenuItem value={undefined}>None</MenuItem>
                {nocCodes.map((nocCode) => (
                  <MenuItem
                    key={nocCode.value}
                    value={nocCode.value}
                  >{`${nocCode.value} - ${nocCode.name}`}</MenuItem>
                ))}
              </Select>
              {touched.nocCode && errors.nocCode && (
                <FormHelperText error>{errors.nocCode}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {accountTypeRequired && (
            <Grid item>
              <FormControl fullWidth>
                <InputLabel htmlFor="accountType">Account Type</InputLabel>
                <Select
                  error={
                    touched.accountType && !isNilOrEmpty(errors.accountType)
                  }
                  fullWidth
                  inputProps={{
                    id: 'accountType',
                    name: 'accountType',
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={accountTypeRequired}
                  value={values.accountType || ''}
                >
                  <MenuItem value={undefined}>None</MenuItem>
                  <MenuItem value="checking">Checking</MenuItem>
                  <MenuItem value="savings">Savings</MenuItem>
                </Select>
                {touched.accountType && errors.accountType && (
                  <FormHelperText error>{errors.accountType}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {routingNumberRequired && (
            <Grid item>
              <TextFieldRoutingNumber
                error={
                  touched.routingNumber && !isNilOrEmpty(errors.routingNumber)
                }
                helperText={touched.routingNumber ? errors.routingNumber : ''}
                id="routingNumber"
                name="routingNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                required={routingNumberRequired}
              />
            </Grid>
          )}
          {accountNumberRequired && (
            <Grid item>
              <TextField
                error={
                  touched.accountNumber && !isNilOrEmpty(errors.accountNumber)
                }
                fullWidth
                helperText={touched.accountNumber ? errors.accountNumber : ''}
                id="accountNumber"
                label="Bank Account Number"
                name="accountNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                required={accountNumberRequired}
                value={values.accountNumber}
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container direction="column">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="returnedCode">NOR Code</InputLabel>
              <Select
                error={!isNilOrEmpty(errors.returnedCode)}
                fullWidth
                inputProps={{
                  id: 'returnedCode',
                  name: 'returnedCode',
                }}
                label="Return Code"
                onBlur={handleBlur}
                onChange={handleChange}
                required
                value={values.returnedCode}
              >
                <MenuItem value={undefined}>None</MenuItem>
                {returnAchCodes.map((achCode) => (
                  <MenuItem key={achCode.code} value={achCode.code}>
                    {`${achCode.code} - ${achCode.reason}`}
                  </MenuItem>
                ))}
              </Select>
              {errors.returnedCode && (
                <FormHelperText error>{errors.returnedCode}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

StepUpdate.propTypes = {
  changeType: PropTypes.string.isRequired,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
};

StepUpdate.defaultProps = {
  errors: null,
  handleBlur: always,
  handleChange: always,
  touched: null,
  values: null,
};

export default memo(StepUpdate);
