import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  circularProgress: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: 'calc(50% - 35px)',
  },
}));

const AlertImprovedProgress = ({ visible }) => {
  const classes = useStyles();

  return visible ? (
    <CircularProgress className={classes.circularProgress} size={50} />
  ) : null;
};

AlertImprovedProgress.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default AlertImprovedProgress;
