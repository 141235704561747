import PropTypes from 'prop-types';
import { Edit } from '@material-ui/icons';
import { withPayerNotesAccess } from 'components/common/permissions';
import { renderNothing, branch } from 'recompose';
import { connect } from 'react-redux';
import { getAuth } from 'util/index';
import { IconButton, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  actionButton: {
    marginTop: theme.spacing(1),
  },
});

const NoteEditButton = ({ classes, readOnly, onClick }) => {
  return (
    <IconButton
      aria-label="Edit"
      className={classes.actionButton}
      disabled={!readOnly}
      onClick={onClick}
    >
      <Edit />
    </IconButton>
  );
};

NoteEditButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

const ConnectedNoteEditButton = connect((state) => ({ auth: getAuth(state) }))(
  NoteEditButton
);
const StyledNoteEditButton = withStyles(styles)(ConnectedNoteEditButton);

const NoteEditButtonWithPayerNotePermission =
  withPayerNotesAccess(StyledNoteEditButton);

export default branch(
  ({ auth, item }) => auth && item.insAdminUserId !== auth.id,
  renderNothing
)(NoteEditButtonWithPayerNotePermission);
