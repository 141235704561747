import AuditContext from 'components/common/Audit/AuditContext';
import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';

const AuditDrawerAppBarTitle = () => {
  const {
    item: { autopayId },
  } = useContext(AuditContext);

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography color="inherit" variant="h6">
          Audit Details
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="body1">
          {`Autopay Id: ${autopayId}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AuditDrawerAppBarTitle;
