import { client, formatQueryParams } from 'util/sdk';

export const add = ({ billerSetId, ...params }, auth) =>
  client
    .billerSets(billerSetId)
    .billerSetAliases.create(params, { adminUserId: auth.id });

export const load = (params) => {
  const { orderByName, ...rest } = params;
  return client.billerSetAliases.query(formatQueryParams(rest));
};

export const remove = ({ id }, auth) =>
  client.billerSetAliases(id).delete({ adminUserId: auth.id });

export const update = ({ id, ...data }, auth) =>
  client.billerSetAliases(id).update(data, { adminUserId: auth.id });
