import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, Grid, Tab as MdTab } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    color: theme.palette.common.black,
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: 13,
    '&:hover': {
      backgroundColor: 'rgba(192, 179, 194, 0.5)',
    },
    whiteSpace: 'inherit',
  },
}));

const Tab = ({
  MenuComponent,
  label,
  menuComponentProps,
  path,
  payerId,
  trrId,
}) => {
  const classes = useStyles();
  const [disableRipple, setDisableRipple] = useState(false);
  const handleRipple = useCallback(
    () => setDisableRipple(!disableRipple),
    [disableRipple]
  );
  const labelComponent = useMemo(() => {
    return MenuComponent ? (
      <Grid alignItems="center" container justifyContent="center">
        <Grid item>{label}</Grid>
        <Grid item onClick={(event) => event.stopPropagation()}>
          <MenuComponent
            onMenuClose={handleRipple}
            onMenuOpen={handleRipple}
            {...menuComponentProps}
          />
        </Grid>
      </Grid>
    ) : (
      label
    );
  }, [MenuComponent, handleRipple, label, menuComponentProps]);
  const tabClasses = useMemo(() => {
    return {
      root: classes.tabRoot,
    };
  }, [classes]);
  const to = useMemo(() => {
    if (payerId || trrId) {
      return {
        pathname: path,
        state: { id: payerId || trrId },
      };
    }
    return path;
  }, [path, payerId, trrId]);

  return (
    <MdTab
      classes={tabClasses}
      component={Link}
      disableRipple={disableRipple}
      label={labelComponent}
      to={to}
    />
  );
};

Tab.propTypes = {
  MenuComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  menuComponentProps: PropTypes.object,
  path: PropTypes.string.isRequired,
  payerId: PropTypes.number,
  trrId: PropTypes.number,
};

Tab.defaultProps = {
  MenuComponent: null,
  menuComponentProps: {},
  payerId: null,
  trrId: null,
};

export default memo(Tab);
