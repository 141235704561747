import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export const addDerived = (r) => {
  if (r.description === null) {
    r.description = '';
  }
  return r;
};

export default class Limit extends Model {
  static get modelName() {
    return 'Limit';
  }

  static reducer({ type, data }, Limit) {
    const id = data?.props?.id;

    switch (type) {
      case ADD.SUCCESS:
        Limit.create(addDerived(data?.response));
        break;

      case LOAD.SUCCESS:
      case IMPORT.SUCCESS:
        if (data?.response) {
          Limit.delete();
          data.response.forEach((r) => Limit.create(addDerived(r)));
        }
        break;

      case REMOVE.SUCCESS:
        if (Limit.idExists(id)) {
          Limit.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        Limit.upsert(addDerived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      fsp: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      newIdDerived: attr(), // derived
      offset: attr(),
      refreshDerived: attr(), // derived
      type: attr(),
      updatedAt: attr(),
    };
  }
}

const name = Limit.modelName;

export const ADD = createAddConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);
export const IMPORT = createImportConst(name);

export const add = createAction(ADD);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
export const importAction = createAction(IMPORT);
