import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { NA_VALUE } from 'consts';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from './moment';

momentDurationFormatSetup(moment);

/**
 * Properties
 */
const DATE_AND_TIME_FORMAT = `${DEFAULT_DATE_FORMAT}, ${DEFAULT_TIME_FORMAT}`;
const MONTHS = {
  1: { MMM: 'Jan', MMMM: 'January' },
  2: { MMM: 'Feb', MMMM: 'February' },
  3: { MMM: 'Mar', MMMM: 'March' },
  4: { MMM: 'Apr', MMMM: 'April' },
  5: { MMM: 'May', MMMM: 'May' },
  6: { MMM: 'Jun', MMMM: 'June' },
  7: { MMM: 'Jul', MMMM: 'July' },
  8: { MMM: 'Aug', MMMM: 'August' },
  9: { MMM: 'Sep', MMMM: 'September' },
  10: { MMM: 'Oct', MMMM: 'October' },
  11: { MMM: 'Nov', MMMM: 'November' },
  12: { MMM: 'Dec', MMMM: 'December' },
};
const TIME_FORMAT = 'LTS [ET]';
const ZERO_TIME = '00:00:00.000000Z';

/**
 * Methods
 */
export const dateToMoment = (date, ignoreTimezoneIfTimeIsZero = false) => {
  // By default, Ontrac sets the timezone to 'America/New_York' when calculating all date and time formats.
  // However, sometimes the caller may wish to ignore the 'America/New_York' timezone, but, only when the
  // time is all zeros; '00:00:00.000000Z'.
  // See https://payrailz.atlassian.net/browse/ON-996
  return ignoreTimezoneIfTimeIsZero && date.endsWith(ZERO_TIME)
    ? moment.parseZone(date)
    : moment(date);
};

const dateOrTime = (
  date,
  justTime = false,
  justDate = false,
  ignoreTimezoneIfTimeIsZero = false
) => {
  let value = date;

  const theDateAsAMoment = dateToMoment(date, ignoreTimezoneIfTimeIsZero);

  // undefined will use moment.defaultFormat set in format util
  if (value) {
    const format = justTime
      ? TIME_FORMAT
      : justDate
      ? moment.defaultFormat
      : DATE_AND_TIME_FORMAT;

    value = theDateAsAMoment.format(format);
  } else {
    value = NA_VALUE;
  }

  return value;
};

export const date = (date, ignoreTimezoneIfTimeIsZero = false) => {
  return dateOrTime(date, false, true, ignoreTimezoneIfTimeIsZero);
};

export const dateAndTime = (date) => dateOrTime(date);

export const durationHumanized = (date) => {
  let value = date;

  if (value) {
    value = moment.duration(-moment().diff(moment(date))).humanize(true);
  }

  return value;
};

export const formatDateRange = (from, to) => {
  const dateFrom = moment(from);
  const dateTo = moment(to);

  if (dateFrom.year() === dateTo.year()) {
    if (dateFrom.month() === dateTo.month()) {
      return `${dateFrom.format('MMMM D')}–${dateTo.format('D, YYYY')}`;
    }
    return `${dateFrom.format('MMM D')}–${dateTo.format('MMM D, YYYY')}`;
  }

  return `${dateFrom.format('MMM D, YYYY')}–${dateTo.format('MMM D, YYYY')}`;
};

export const formatMonth = (month, format) => {
  const monthValue = MONTHS[month];

  return monthValue ? monthValue[format] || monthValue.MMM : 'N/A';
};

export const formatSortedDate = (date) => moment(date).format('YYYY-MM-DD');

export const isSameDay = (v1, v2) => moment(v1).isSame(v2, 'day');

export const time = (date, ignoreTimezoneIfTimeIsZero) => {
  return dateOrTime(date, true, false, ignoreTimezoneIfTimeIsZero);
};

export const toAMPM = (time, format = 'hh:mm A z') => {
  return moment(time, ['HH.mm.ss']).format(format);
};
