import { createSelector as createReselectSelector } from 'reselect';
import { createSelector } from 'redux-orm';
import orm from 'model';
import { dateAndTime } from '../util';

export const selector = createSelector(orm.EBillEnrollment);

export const selectorId = createSelector(
  orm,
  (state, id) => id,
  ({ EBillEnrollment }, id) =>
    EBillEnrollment.idExists(id) ? EBillEnrollment.withId(id) : {}
);

const derivedItems = (items) => {
  return items.map((item) => {
    const { createdAt } = item;

    item.set('createdAtDerived', dateAndTime(createdAt));

    return item.ref;
  });
};

export const selectorPayerId = createSelector(
  orm,
  (state, payerId) => payerId,
  ({ EBillEnrollment }, payerId) =>
    EBillEnrollment.filter(
      ({ payerId: enrollmentPayerId }) => enrollmentPayerId === payerId
    )?.toModelArray() || []
);

export const derivedSelectorPayerId = createReselectSelector(
  selectorPayerId,
  derivedItems
);
