import PropTypes from 'prop-types';
import { CircularProgress, DialogContent } from '@material-ui/core';
import { Document, pdfjs, Page } from 'react-pdf';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  center: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: '50%',
  },
  dialogContent: {
    minWidth: 220,
    minHeight: 300,
  },
}));

const PdfDialogContent = ({ blob, numberOfPages, onLoadSuccess, scale }) => {
  const classes = useStyles();

  pdfjs.GlobalWorkerOptions.workerSrc = '/lib/pdf.worker.min.js';

  return (
    <DialogContent classes={{ root: classes.dialogContent }}>
      <Document
        file={blob}
        loading={<CircularProgress className={classes.center} />}
        onLoadSuccess={onLoadSuccess}
      >
        {Array.from(new Array(numberOfPages), (_, index) => (
          <Page
            key={`page${index + 1}`}
            pageNumber={index + 1}
            scale={scale / 100}
          />
        ))}
      </Document>
    </DialogContent>
  );
};

PdfDialogContent.propTypes = {
  blob: PropTypes.object.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onLoadSuccess: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
};

export default PdfDialogContent;
