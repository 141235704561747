import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class OneTimePasscodeEvent extends Model {
  static get modelName() {
    return 'OneTimePasscodeEvent';
  }

  static reducer({ type, data }, OneTimePasscodeEvent) {
    switch (type) {
      case LOAD.SUCCESS:
        data.response.forEach((r) => {
          OneTimePasscodeEvent.upsert(r);
        });
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      description: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUserId: attr(),
      offset: attr(),
      otpEvent: attr(),
      otpOffOn: attr(),
      updAdminUserId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createLoadConst(OneTimePasscodeEvent.modelName);

export const load = createAction(LOAD);
