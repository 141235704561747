import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { makeStyles } from '@material-ui/core/styles';
import Status from './Status';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(1),
  },
  card: {
    maxWidth: 400,
  },
  typography: {
    whiteSpace: 'pre-wrap',
  },
}));

const MissedPaymentDetailsRenderer = ({ item }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Activated Date"
          value={item?.activeAtLabel}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="Biller" value={item?.payeeName} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Scheduled Payment ID"
          value={item?.schedId}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          inputNode={
            item?.insightStatusDates && (
              <Status
                label={item?.insightStatusLabel}
                statusDates={item?.insightStatusDates}
              />
            )
          }
          label="Status"
          value={item?.insightStatusLabel}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl label="View Count" value={item?.viewCnt} />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="First View Date"
          value={item?.firstViewOnLabel}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          label="Last View Date"
          value={item?.lastViewOnLabel}
        />
      </Grid>
      <Grid item xs={4}>
        <ReadOnlyFormControl
          inputNode={
            <div className={classes.card}>
              <ReadOnlyInputWithMenu
                capitalize={false}
                label={item.renderedMessage}
                menuNode={
                  <Card className={classes.card} elevation={0}>
                    <CardContent>
                      <Typography
                        className={classes.typography}
                        variant="body2"
                      >
                        {item.renderedMessage}
                      </Typography>
                    </CardContent>
                  </Card>
                }
                tooltip="Show all details"
              />
            </div>
          }
          label="Message"
          value={item.renderedMessage}
        />
      </Grid>
    </Grid>
  );
};

MissedPaymentDetailsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default MissedPaymentDetailsRenderer;
