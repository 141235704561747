import { client } from 'util/sdk';
import { GET } from 'util/api';
import { FSPS } from 'consts';

const returnModel = { name: 'paymentDeliveredCount' };

const getQueryParams = ({ id }, queryParams) => ({
  adminUserId: id,
  ...queryParams,
});
const loadURL = (fspId, payerId) =>
  `v2/${FSPS}/${fspId}/payers/${payerId}/transaction-delivered-counts`;

// eslint-disable-next-line import/prefer-default-export
export const load = async ({ fspId, payerId, queryParams }, auth) => {
  let result;
  try {
    result = await client.executeRequest(GET, loadURL(fspId, payerId), {
      queryParams: getQueryParams(auth, queryParams),
      returnModel,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
  return result;
};
