import PropTypes from 'prop-types';
import { Contacts } from '@material-ui/icons';
import { LOAD, load } from 'model/recipient';
import { selector } from 'selectors/busy';
import { selector as contactsSelector } from 'selectors/recipient';
import { DATA_KEY } from 'model/util/recipient';
import { useSelector } from 'react-redux';
import ContactsList from './ContactsList';
import PayerDetailPanel from '../PayerDetailPanel';
import ToggleCurrentDeleted from '../ToggleCurrentDeleted';
import { HEIGHT_OFFSET, SCROLL_TOP_OFFSET } from '../index';
import useToggleCurrentDeleted from '../useToggleCurrentDeleted';
import { settingType, title } from './consts';

const ContactsPanel = ({ fspId, payerId }) => {
  const [showDeleted, onHandleChange] = useToggleCurrentDeleted(
    settingType,
    payerId
  );
  const isBusy = useSelector((state) => selector(state, LOAD));

  return (
    <PayerDetailPanel
      endAdornment={
        <ToggleCurrentDeleted
          action={LOAD}
          onChange={onHandleChange}
          settingId={payerId}
          settingType={settingType}
        />
      }
      fspId={fspId}
      heightOffset={HEIGHT_OFFSET}
      icon={Contacts}
      id={settingType}
      payerId={payerId}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      showProgress={isBusy}
      title={title}
    >
      <ContactsList
        action={load}
        actionTypeObject={LOAD}
        fspId={fspId}
        payerId={payerId}
        renderOptionsType={DATA_KEY}
        selector={contactsSelector}
        settingType={settingType}
        showDeleted={showDeleted}
      />
    </PayerDetailPanel>
  );
};

ContactsPanel.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default ContactsPanel;
