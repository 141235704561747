import PropTypes from 'prop-types';
import { Card, CardContent, makeStyles } from '@material-ui/core';
import { ON } from 'consts';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';

const useStyles = makeStyles(() => ({
  customRule: {
    minWidth: 300,
  },
}));

const AutopayPalCustomRule = ({
  billGreaterThanAmountPalDerived,
  billGreaterThanCustomAmountPalDerived,
  billGreaterThanNotPayOffOn,
}) => {
  const classes = useStyles();

  return (
    <ReadOnlyFormControl
      inputNode={
        billGreaterThanAmountPalDerived ? (
          <ReadOnlyInputWithMenu
            label={billGreaterThanAmountPalDerived ? 'Yes' : 'No'}
            menuNode={
              <Card className={classes.customRule}>
                <CardContent>
                  <ReadOnlyFormControl
                    label={`When Payment Amount over ${billGreaterThanAmountPalDerived}`}
                    value={
                      billGreaterThanNotPayOffOn === ON
                        ? 'Send Notification'
                        : `Pay custom amount ${billGreaterThanCustomAmountPalDerived}`
                    }
                  />
                </CardContent>
              </Card>
            }
          />
        ) : null
      }
      label="Custom Rule"
      value={billGreaterThanAmountPalDerived ? 'Yes' : 'No'}
    />
  );
};

AutopayPalCustomRule.propTypes = {
  billGreaterThanAmountPalDerived: PropTypes.string,
  billGreaterThanCustomAmountPalDerived: PropTypes.string,
  billGreaterThanNotPayOffOn: PropTypes.string,
};

AutopayPalCustomRule.defaultProps = {
  billGreaterThanAmountPalDerived: '',
  billGreaterThanCustomAmountPalDerived: '',
  billGreaterThanNotPayOffOn: '',
};

export default AutopayPalCustomRule;
