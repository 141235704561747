import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Grid, ListItem, Typography } from '@material-ui/core';
import { add, selector } from 'reducers/tableEmptyMessage';
import { isLoadingState } from 'reducers/loading';
import { useDispatch, useSelector } from 'react-redux';

const ExpansionListItemEmpty = ({
  actionConstant,
  count,
  subHeading,
  title,
}) => {
  const dispatch = useDispatch();
  const actions = useSelector(selector);
  const executing = useSelector((state) =>
    isLoadingState(state, actionConstant.ACTION)
  );

  const hasNotBeenHereYet = !actions.includes(actionConstant.ACTION);

  useEffect(() => {
    if (executing && hasNotBeenHereYet) {
      dispatch(add(actionConstant.ACTION));
    }
  }, [actionConstant.ACTION, dispatch, hasNotBeenHereYet, executing]);

  return executing || hasNotBeenHereYet || count !== 0 ? null : (
    <ListItem>
      <Grid alignItems="center" container direction="column">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{subHeading}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

ExpansionListItemEmpty.propTypes = {
  actionConstant: PropTypes.object.isRequired,
  count: PropTypes.number,
  subHeading: PropTypes.string,
  title: PropTypes.string,
};

ExpansionListItemEmpty.defaultProps = {
  count: 0,
  subHeading: '',
  title: '',
};

export default ExpansionListItemEmpty;
