import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { LOAD, load } from 'model/internalAccount';
import { selectorLoans } from 'selectors/internalAccount';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect } from 'react';
import { useIsBusy, useIsLoading } from 'hooks';
import { columnMeta, detailsRender, defaultOrderBy } from './config';
import ActionsRenderer from './config/ActionsRenderer';

const PayALoanList = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useIsBusy(LOAD);
  const isLoading = useIsLoading(LOAD);
  const items = useSelector((state) => selectorLoans(state, payerId));

  useEffect(() => {
    dispatch(load({ fspId, payerId }));
  }, [dispatch, fspId, payerId]);

  return (
    <ExpansionList
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      actionType={LOAD.ACTION}
      columnMeta={columnMeta}
      data={items}
      detailsRender={detailsRender}
      emptyTitle="No Available Loans"
      fspId={fspId}
      isBusy={isBusy}
      isLoading={isLoading}
      name="payALoan"
      orderBy={defaultOrderBy}
      payerId={payerId}
    />
  );
};

PayALoanList.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default memo(PayALoanList);
