import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { CheckCircle, Close } from '@material-ui/icons';
import { always } from 'ramda';
import { withStyles, Grid, ListItem, Typography } from '@material-ui/core';

const styles = () => ({
  disabled: {
    color: 'darkred',
    paddingLeft: 2,
    paddingTop: 2,
  },
  enabled: {
    color: 'darkgreen',
  },
  icon: {
    minHeight: 36,
    minWidth: 36,
  },
  typography: {
    marginBottom: 5,
  },
});

const Rule = ({ classes, enabled, onChange, text }) => {
  useEffect(() => {
    onChange(enabled, text);
  }, [enabled, onChange, text]);

  return (
    <ListItem>
      <Grid alignItems="center" container spacing={1}>
        <Grid className={classes.icon} item>
          {enabled ? (
            <CheckCircle className={classes.enabled} />
          ) : (
            <Close
              className={classes.disabled}
              color="disabled"
              fontSize="small"
            />
          )}
        </Grid>
        <Grid item>
          <Typography
            className={classes.typography}
            color={enabled ? 'textPrimary' : 'textSecondary'}
            variant="body1"
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

Rule.propTypes = {
  classes: PropTypes.object.isRequired,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
};

Rule.defaultProps = {
  enabled: false,
  onChange: always,
  text: '',
};

export default withStyles(styles)(Rule);
