import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: 30,
  },
}));

const SelectTransactionTypeProgress = () => {
  const classes = useStyles();

  return <LinearProgress className={classes.root} />;
};

export default SelectTransactionTypeProgress;
