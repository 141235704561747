import { always } from 'ramda';
import { handleStopPropagation } from 'consts';
import { selector } from 'selectors/request';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useOpenCloseRequestComplete = (
  action = {},
  callback = always,
  initialValue = false,
  id = 0
) => {
  const [completed] = useSelector((state) => selector(state, action, id));
  const [open, setOpen] = useState(initialValue);

  const toggleOpen = (event) => {
    callback(event);
    handleStopPropagation(event);
    setOpen(!open);
  };

  useEffect(() => {
    if (completed) {
      setOpen(false);
    }
  }, [completed, setOpen]);

  return [open, toggleOpen];
};

export default useOpenCloseRequestComplete;
