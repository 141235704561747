import { useSelector } from 'react-redux';
import { selector } from 'selectors/fspConfig';
import { selectedFspIdSelector } from 'selectors/fsp';
import useDispatchLoadEffect from './useDispatchLoadEffect';
import useFSPId from '../useFSPId';

/**
 * @param fspId - the fspId
 * @param load - if true, an fspconfig API will be made
 * @param reload - if fspconfig already has values in the store, passing false
 * will *not* issue another API call, but passing true will call the API again
 *
 * @returns {*} - fspconfig data
 */
const useFSPConfig = (fspId, load = true, reload = true) => {
  // TODO: review useFspId because it only looks at query params to find fspId. Sometimes fspId can be within path.
  //  It could be safer to always used the one passed in to the hook which is fspId.
  const fspIdParam = useFSPId(fspId, true);
  const fspIdSelected = useSelector(selectedFspIdSelector);
  const fspIdValue = fspIdParam ?? fspIdSelected;
  const fspConfig = useSelector((state) => selector(state, fspIdValue));

  useDispatchLoadEffect(fspIdValue, fspConfig, load, reload);

  return fspConfig;
};

export default useFSPConfig;
