import PropTypes from 'prop-types';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { isLoadingState } from 'reducers/loading';
import { selector as isBusySelector } from 'selectors/busy';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  div: {
    height: theme.spacing(0.5),
  },
}));

const LoadingAndBusy = ({ busyType, type }) => {
  const classes = useStyles();
  const isBusy = useSelector((state) =>
    isBusySelector(state, { BUSY: busyType })
  );
  const isLoading = useSelector((state) => isLoadingState(state, type));

  return isLoading && isBusy ? (
    <LinearProgress />
  ) : (
    <div className={classes.div} />
  );
};

LoadingAndBusy.propTypes = {
  busyType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default LoadingAndBusy;
