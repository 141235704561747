import { withProps, compose, pure, toClass } from 'recompose';
import MaskedInput from 'react-text-mask';
import Field from 'components/common/fields/Field';
import { placeholderChar } from './consts';

const ssnMask = [/\d/, /\d/, /\d/, /\d/];

const SSNMaskInput = toClass(({ inputRef, ...props }) => (
  <MaskedInput {...props} mask={ssnMask} placeholderChar={placeholderChar} />
));

export default compose(
  withProps(() => ({
    component: SSNMaskInput,
  })),
  pure
)(Field);
