import { add, load, remove, update, upload } from 'sdk/fspDenyList';
import { ADD, LOAD, REMOVE, UPDATE, UPLOAD } from 'model/fspDenyList';
import { ADDED, REMOVED, UPDATED } from 'consts';
import { takeLatest, takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    ADD.ACTION,
    callAction({
      api: add,
      dataName: ORM,
      formatSuccessMessage: () => `Item successfully ${ADDED}`,
      type: ADD,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: () => `Item(s) successfully ${REMOVED}`,
      type: REMOVE,
    })
  );

  yield takeLatest(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: () => `Item successfully ${UPDATED}`,
      type: UPDATE,
    })
  );

  yield takeLatest(
    UPLOAD.ACTION,
    callAction({
      api: upload,
      dataName: ORM,
      type: UPLOAD,
    })
  );
}
