import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { DEFAULT_MODEL_REF_ARRAY } from 'consts';

export const selector = createSelector(orm.PayerUser);

export const selectorUserIdFirst = createSelector(
  orm,
  (state, { userId }) => userId,
  ({ PayerUser }, userId) =>
    PayerUser.filter(
      ({ userId: payerUserId }) => payerUserId === userId
    ).first()
);

export const selectorPayerId = createSelector(
  orm,
  (state, payerId) => payerId,
  ({ Payer, PayerUser }, payerId) =>
    Payer.idExists(payerId)
      ? Payer.withId(payerId).payerUsers.all().toRefArray() ??
        DEFAULT_MODEL_REF_ARRAY
      : PayerUser.filter(
          (payerUser) => payerUser.payerId === payerId
        ).toRefArray() || DEFAULT_MODEL_REF_ARRAY
);

export const selectorIdPayerId = createSelector(
  orm,
  (state, { id, payerId }) => ({
    id,
    payerId,
  }),
  ({ Payer, PayerUser }, { id, payerId }) => {
    const payerUsers = Payer.idExists(payerId)
      ? Payer.withId(payerId).payerUsers.filter(
          ({ id: payerUserId }) => payerUserId === id
        )
      : PayerUser.filter(
          ({ id: payerUserId, payerId: payerUserPayerId }) =>
            payerUserId === id && payerUserPayerId === payerId
        );

    return (payerUsers ?? DEFAULT_MODEL_REF_ARRAY).length
      ? payerUsers.first().toRef()
      : {};
  }
);
