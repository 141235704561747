import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selector } from 'selectors/busy';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { userAfterRefresh, USER_AFTER_REFRESH } from 'reducers/authToken';

const useStyles = makeStyles(() => ({
  absoluteCenter: {
    left: '50%',
    position: 'absolute',
    top: '45%',
  },
}));

const busySelector = (state) => selector(state, USER_AFTER_REFRESH);

/**
 * This component is used when the user does a hard refresh in their browser
 *
 * At this point the user should have a valid token, but, the all the user information
 * returned from the login call does not yet exist in redux store.
 *
 * After this component finishes dispatching a successful user info request,
 * the route handling will kick in and everything should just work
 *
 */
const UserAfterRefresh = () => {
  const busy = useSelector(busySelector);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAfterRefresh());
  }, [dispatch]);

  return busy ? (
    <CircularProgress className={classes.absoluteCenter} size={90} />
  ) : null;
};

export default UserAfterRefresh;
