import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import {
  Grid,
  Table,
  TableContainer as MuiTableContainer,
} from '@material-ui/core';
import { omit } from 'ramda';
import TableBody from '../TableBody';
import TableContainerProgress from './TableContainerProgress';
import TableHead, { propTypes as TableHeadPropTypes } from '../TableHead';
import TablePagination, {
  propTypes as TablePaginationPropTypes,
} from '../TablePagination';

const useStyles = makeStyles((theme) => ({
  pagination: {
    backgroundColor: theme.palette.background.default,
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
  progress: {
    height: 2,
  },
  root: {},
}));

const TableContainer = ({
  defaultPageSettings,
  pageSettingsName,
  selector,
  selectorProps,
  showProgress,
  NoContentProps,
  StartAdornment,
  TableHeadProps,
  TablePaginationProps,
  TableProps,
  TableRow,
  TableRowProps,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiTableContainer className={classes.root} {...props}>
      <Grid
        classes={{ root: classes.pagination }}
        container
        justifyContent="flex-end"
      >
        {StartAdornment && (
          <Grid item xs>
            {StartAdornment}
          </Grid>
        )}
        <Grid item>
          <TablePagination
            component="div"
            defaultPageSettings={defaultPageSettings}
            pageSettingsName={pageSettingsName}
            selectorProps={selectorProps}
            {...TablePaginationProps}
          />
        </Grid>
      </Grid>
      <TableContainerProgress
        actionType={TablePaginationProps.actionType}
        actionTypeUpdate={TablePaginationProps.actionTypeUpdate}
        showProgress={showProgress}
      />
      <Table {...TableProps}>
        <TableHead
          defaultPageSettings={defaultPageSettings}
          pageSettingsName={pageSettingsName}
          {...TableHeadProps}
        />
        <TableBody
          actionType={TablePaginationProps.actionType}
          columnCount={
            TableHeadProps.columns.length +
            (TableHeadProps.checkAllAction ? 1 : 0)
          }
          NoContentProps={NoContentProps}
          selector={selector}
          selectorProps={selectorProps}
          TableHeadProps={TableHeadProps}
          TableRow={TableRow}
          TableRowProps={TableRowProps}
        />
      </Table>
    </MuiTableContainer>
  );
};

TableContainer.propTypes = {
  defaultPageSettings: PropTypes.object,
  NoContentProps: PropTypes.object,
  pageSettingsName: PropTypes.string.isRequired,
  selector: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
  showProgress: PropTypes.bool,
  StartAdornment: PropTypes.object,
  TableHeadProps: PropTypes.shape(
    omit(['defaultPageSettings', 'pageSettingsName'], TableHeadPropTypes)
  ).isRequired,
  TablePaginationProps: PropTypes.shape(
    omit(['pageSettingsName'], TablePaginationPropTypes)
  ).isRequired,
  TableProps: PropTypes.object,
  TableRow: PropTypes.elementType.isRequired,
  TableRowProps: PropTypes.object,
};

TableContainer.defaultProps = {
  defaultPageSettings: {},
  NoContentProps: undefined,
  StartAdornment: undefined,
  TableProps: null,
  TableRowProps: undefined,
  selectorProps: undefined,
  showProgress: false,
};

export default memo(TableContainer);
