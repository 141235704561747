import Grid from '@material-ui/core/Grid';
import Digit from './Digit';
import { useOneTimePasscode } from '../OneTimePasscodeContext';

const Digits = () => {
  const { state } = useOneTimePasscode();

  return (
    <Grid container spacing={1} wrap="nowrap">
      {state?.passcode.map((value, position) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={position} item>
          <Digit position={position} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Digits;
