import { add, importAndReset, load, remove, update } from 'sdk/ssoUri';
import { ADD, LOAD, REMOVE, UPDATE, IMPORT } from 'model/ssoUri';
import { callAction, ORM } from 'util/index';
import { takeEvery } from 'redux-saga/effects';
import { loadFSPProfileAction } from './export';

export const importSSOURIs = () =>
  callAction({
    api: importAndReset,
    dataName: ORM,
    formatSuccessMessage: () => 'SSO URIs were successfully imported',
    type: IMPORT,
  });

function* addAndPopulateExport(action) {
  const addAction = callAction({
    api: add,
    dataName: ORM,
    formatSuccessMessage: () => 'SSO URI successfully added',
    type: ADD,
  });

  if (yield addAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

function* importAndPopulateExport(action) {
  const importAndResetAction = importSSOURIs();

  if (yield importAndResetAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* removeAndPopulateExport(action) {
  const removeAction = callAction({
    api: remove,
    dataName: ORM,
    formatSuccessMessage: () => 'SSO URI was successfully removed',
    type: REMOVE,
  });

  if (yield removeAction(action)) {
    yield loadFSPProfileAction(action);
  }
}
function* updateAndPopulateExport(action) {
  const updateAction = callAction({
    api: update,
    dataName: ORM,
    formatSuccessMessage: () => 'SSO URI was successfully updated',
    type: UPDATE,
  });

  if (yield updateAction(action)) {
    yield loadFSPProfileAction(action);
  }
}

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(ADD.ACTION, addAndPopulateExport);

  yield takeEvery(IMPORT.ACTION, importAndPopulateExport);

  yield takeEvery(REMOVE.ACTION, removeAndPopulateExport);

  yield takeEvery(UPDATE.ACTION, updateAndPopulateExport);
}
