import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.ResearchReason);

export const selectorTransactionType = createSelector(
  orm,
  (state, transactionType) => transactionType,
  ({ ResearchReason }, transactionType) => {
    return ResearchReason.filter(
      ({ transactionType: researchTransactionType }) =>
        researchTransactionType === transactionType
    ).toRefArray();
  }
);
