import {
  CANCEL,
  CREATE,
  CREATE_SNAPSHOT,
  LAST_SNAPSHOT,
  LOAD_ACTIVE,
  LOAD_COMPLETE,
  REMOVE,
} from 'model/automatchJob';
import { update } from 'model/pageSetting';
import { buffers } from 'redux-saga';
import {
  cancel,
  create,
  createSnapshot,
  lastSnapshot,
  load,
  remove,
} from 'sdk/automatchJob';
import { pageSettingsName as activeJbsPageSettingsName } from 'components/modules/paymentOps/BillerManagement/Automatch/Jobs/ActiveJobs/consts';
import { pageSettingsName as completeJbsPageSettingsName } from 'components/modules/paymentOps/BillerManagement/Automatch/Jobs/CompleteJobs/consts';
import { JOB_TYPE_UPDATE } from 'model/util/automatchJob';
import { actionChannel, put, delay, take, takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `A ${
          response.type === JOB_TYPE_UPDATE ? 'update' : 'preview'
        } job was created from the "${response.templateName}" template`,
      type: CREATE,
    })
  );

  yield takeEvery(
    CREATE_SNAPSHOT.ACTION,
    callAction({
      api: createSnapshot,
      dataName: ORM,
      formatSuccessMessage: () => 'A snapshot job was created',
      type: CREATE,
    })
  );

  yield takeEvery(
    LAST_SNAPSHOT.ACTION,
    callAction({
      api: lastSnapshot,
      dataName: ORM,
      type: LAST_SNAPSHOT,
    })
  );

  yield takeEvery(
    LOAD_ACTIVE.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD_ACTIVE,
    })
  );

  yield takeEvery(
    LOAD_COMPLETE.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD_COMPLETE,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: () => `Job(s) were deleted successfully`,
      type: REMOVE,
    })
  );

  yield takeEvery(
    CANCEL.ACTION,
    callAction({
      api: cancel,
      dataName: ORM,
      formatSuccessMessage: () => 'Job was cancelled',
      type: CANCEL,
    })
  );

  // Watch the create and delete actions, if they are dispatched, do something in response
  const jobChannel = yield actionChannel(
    [CANCEL.ACTION, CREATE.ACTION, CREATE_SNAPSHOT.ACTION, REMOVE.ACTION],
    buffers.dropping(1)
  );

  while (true) {
    // Wait for new job was created in the channel
    yield take(jobChannel);

    // Wait for the automatch server to get some work done
    yield delay(1500);

    // Refresh both active and complete jobs
    // (must be done via a pageSetting to keep current sort)
    yield put(
      update({
        refreshedAt: Date.now(),
        type: activeJbsPageSettingsName,
      })
    );
    yield put(
      update({
        refreshedAt: Date.now(),
        type: completeJbsPageSettingsName,
      })
    );
  }
}
