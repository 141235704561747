import {
  NONE,
  EXPEDITED,
  DELIVER_CHECK_EXPEDITE,
  CHECK,
  DELIVER_CHECK_STD,
  DELIVER_ELEC_NOW,
  DELIVER_ELEC_NOW_LABEL,
  DELIVER_ELEC_TODAY,
  DELIVER_ELEC_TODAY_LABEL,
  DELIVER_ELEC_STD,
  DELIVER_ELEC_STD_LABEL,
} from 'consts';

/**
 * Delivery Methods:
 */
const methods = [
  {
    always: true,
    name: NONE,
    value: '',
  },
  {
    name: EXPEDITED,
    value: DELIVER_CHECK_EXPEDITE,
  },
  {
    name: CHECK,
    value: DELIVER_CHECK_STD,
  },
  {
    name: DELIVER_ELEC_NOW_LABEL,
    value: DELIVER_ELEC_NOW,
  },
  {
    name: DELIVER_ELEC_TODAY_LABEL,
    value: DELIVER_ELEC_TODAY,
  },
  {
    name: DELIVER_ELEC_STD_LABEL,
    value: DELIVER_ELEC_STD,
  },
];

// eslint-disable-next-line import/prefer-default-export
export const getMethods = () => methods;
