import { branch, compose, renderComponent } from 'recompose';
import PayerLabel from './PayerLabel';
import PaymentResearchLabel from './PaymentResearchLabel';

export default compose(
  branch(
    ({ removable = false }) => !removable,
    renderComponent(({ label }) => label)
  ),
  branch(
    ({ payerId }) => payerId,
    renderComponent(PayerLabel),
    renderComponent(PaymentResearchLabel)
  )
)();
