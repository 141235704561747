import PropTypes from 'prop-types';
import { always } from 'ramda';
import InputRefType from 'types/inputRef';
import {
  booleanOptions,
  SearchParamPropType,
} from 'components/common/SearchTextField/Multiple/consts';
import ParamValueSelect from './ParamValueSelect';

const ParamValueBoolean = ({
  disabled,
  inputRef,
  onChange,
  onKeyPress,
  searchParam,
  value,
}) => {
  return (
    <ParamValueSelect
      disabled={disabled}
      inputRef={inputRef}
      onChange={onChange}
      onKeyPress={onKeyPress}
      options={booleanOptions}
      searchParam={searchParam}
      value={value}
    />
  );
};

ParamValueBoolean.propTypes = {
  disabled: PropTypes.bool,
  inputRef: InputRefType,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  searchParam: SearchParamPropType.isRequired,
  value: PropTypes.string,
};

ParamValueBoolean.defaultProps = {
  disabled: false,
  inputRef: undefined,
  onKeyPress: always,
  value: '',
};

export default ParamValueBoolean;
