import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { InputAdornment, TextField, withStyles } from '@material-ui/core';
import { currencyNoSymbol, decimal, formatCurrency } from 'util/format';
import { currency as currencyTest } from 'util/test';
import { always, omit } from 'ramda';
import { DollarSign, ErrorIndicator, Reset } from './consts';

const isNumberOrSep = /([0-9]|[.,])/;

const styles = (theme) => ({
  input: {
    color: theme.palette.primary.main,
  },
  inputReadOnly: {
    color: theme.palette.action.active,
    direction: 'rtl',
  },
  root: {},
});

/**
 * deprecated - use CurrencyTextField instead
 */
const TextFieldCurrency = ({
  classes,
  id,
  InputProps,
  maximum,
  mini,
  minimum,
  onBlur,
  onChange,
  onError,
  onErrorClear,
  onKeyPress,
  showReset,
  value: initialValue,
  ...props
}) => {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [onBlurEvent, setOnBlurEvent] = useState(false);
  const [value, setValue] = useState(currencyNoSymbol(initialValue));
  const [wasChanged, setWasChanged] = useState(false);

  const errorHandler = useCallback(
    (amount) => {
      if (!amount || decimal(amount) === 0) {
        setError(true);
        setHelperText('An amount is required');
      } else if (!currencyTest(amount)) {
        setError(true);
        setHelperText('Invalid currency format');
      } else if (maximum || minimum) {
        const valueAsDecimal = decimal(amount);
        let currencyText;
        let text;

        if (maximum && valueAsDecimal > maximum) {
          currencyText = formatCurrency(maximum);
          text = `The ${id || ''} value must be less than ${currencyText}`;
        } else if (minimum && valueAsDecimal < minimum) {
          currencyText = formatCurrency(minimum);
          text = `The ${id || ''} value must be greater than ${currencyText}`;
        }

        if (text) {
          setError(true);
          setHelperText(text);
        } else {
          setError(false);
          setHelperText('');
        }
      }
    },
    [id, maximum, minimum]
  );
  const handleBlur = (event) => {
    setOnBlurEvent(event);
    onBlur(event);
  };
  const handleChange = (event) => {
    if (error) {
      errorHandler(event.target.value);
    }

    setValue(event.target.value);
    setWasChanged(event.target.value !== currencyNoSymbol(initialValue));

    if (currencyTest(event.target.value) || event.target.value === '') {
      onChange(decimal(event.target.value), id);
    }
  };
  const handleKeyPress = (event) => {
    if (!isNumberOrSep.test(event.key)) {
      event.preventDefault();
    }

    onKeyPress(event);
  };
  const handleReset = () => {
    errorHandler(initialValue);
    setWasChanged(false);
    setValue(currencyNoSymbol(initialValue));
    onChange(decimal(initialValue), id);
  };

  const CustomInputProps = {
    classes:
      wasChanged && error
        ? { input: classes.error }
        : wasChanged
        ? { input: classes.input }
        : null,
    endAdornment:
      wasChanged || (error && mini) ? (
        <InputAdornment position="end">
          {showReset && wasChanged && <Reset onClick={handleReset} />}
          {error && mini && <ErrorIndicator title={helperText} />}
        </InputAdornment>
      ) : null,
    startAdornment: <DollarSign error={error} />,
  };

  useEffect(() => {
    if (onBlurEvent) {
      // Why the useEffect?
      // If user enters in a invalid currency and the user clicks the
      // reset button, handleReset would never be called because error was
      // immediately set to true
      errorHandler(value);
      setOnBlurEvent(null);
    }
  }, [errorHandler, onBlurEvent, value]);

  useEffect(() => {
    if (error) {
      onError(error, id);
    } else {
      onErrorClear(error, id);
    }
  }, [error, id, onErrorClear, onError]);

  return (
    <TextField
      {...props}
      classes={omit(['input', 'inputReadOnly', 'onError'], classes)}
      error={error}
      helperText={mini ? null : helperText}
      id={id}
      InputProps={{ ...InputProps, ...CustomInputProps }}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      value={value}
    />
  );
};

TextFieldCurrency.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  InputProps: PropTypes.object,
  maximum: PropTypes.number,
  mini: PropTypes.bool,
  minimum: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onErrorClear: PropTypes.func,
  onKeyPress: PropTypes.func,
  showReset: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextFieldCurrency.defaultProps = {
  id: null,
  InputProps: { classes: {} },
  maximum: null,
  mini: false,
  minimum: null,
  onBlur: always,
  onChange: always,
  onError: always,
  onErrorClear: always,
  onKeyPress: always,
  showReset: false,
  value: null,
};

export default withStyles(styles)(TextFieldCurrency);
