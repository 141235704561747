import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputRefType from 'types/inputRef';

const amountMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: true,
  allowDecimal: true,
  decimalLimit: 2,
});

const CurrencyTextFieldMasked = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      ref={(input) => {
        inputRef.current = input?.inputElement;
      }}
      guide={false}
      mask={amountMask}
      {...props}
    />
  );
};
CurrencyTextFieldMasked.propTypes = {
  inputRef: InputRefType,
};
CurrencyTextFieldMasked.defaultProps = {
  inputRef: undefined,
};

export default CurrencyTextFieldMasked;
