import { Redirect, Route, Switch } from 'react-router-dom';
import { withViewService } from 'components/common/permissions';
import DefaultDetail from './DefaultDetail';
import PayerDetail from './PayerDetail';
import Payers from './Payers';
import { BASE_PATH, DETAIL_PATH, SEARCH_PATH } from './paths';

const Service = () => {
  return (
    <Switch>
      <Route component={Payers} path={SEARCH_PATH} />
      <Route component={PayerDetail} path={`${DETAIL_PATH}/:fspId/:payerId`} />
      <Route component={DefaultDetail} path={DETAIL_PATH} />
      <Redirect from={BASE_PATH} to={SEARCH_PATH} />
    </Switch>
  );
};

export default withViewService(Service);
