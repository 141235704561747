import Check from '@material-ui/icons/Check';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  check: {
    color: 'green',
  },
}));

const StartAdornment = () => {
  const classes = useStyles();

  return (
    <InputAdornment position="start">
      <Check className={classes.check} fontSize="small" />
    </InputAdornment>
  );
};

export default StartAdornment;
