import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst, createUpdateConst } from '../util';
import { derived } from './util/oneTimePasscodeEventConfig';

export default class OneTimePasscodeEventConfig extends Model {
  static get modelName() {
    return 'OneTimePasscodeEventConfig';
  }

  static reducer({ type, data }, OneTimePasscodeEventConfig) {
    switch (type) {
      case LOAD.SUCCESS:
      case UPDATE.SUCCESS:
        OneTimePasscodeEventConfig.upsert(derived(data.response));
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      addA2aExternalDdaOffOn: attr(),
      addBpCheckPayeeOffOn: attr(),
      addBpElecPayeeOffOn: attr(),
      createdAt: attr(),
      createP2pContactOffOn: attr(),
      highRiskA2aTransferOffOn: attr(),
      highRiskBillPaymentOffOn: attr(),
      highRiskP2pPaymentOffOn: attr(),
      href: attr(),
      insAdminUserId: attr(),
      insUserId: attr(),
      offset: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
      updateP2pContactEmailPhoneOffOn: attr(),
      updUserId: attr(),
    };
  }
}

const name = OneTimePasscodeEventConfig.modelName;

export const LOAD = createLoadConst(name);
export const UPDATE = createUpdateConst(name);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
