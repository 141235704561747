import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { selector } from 'selectors/externalAccountIAV';
import { isLoadingState } from 'reducers/loading';
import { load, LOAD } from 'model/externalAccountIAV';
import { selector as busySelector } from 'selectors/busy';
import { useDispatch, useSelector } from 'react-redux';
import AccountList from '../AccountsList';
import { columnMeta, detailsRender } from './config';

const SETTING_TYPE = 'externalIAVAccount';

const ExternalIAV = ({ fspId, payerId }) => {
  const dispatch = useDispatch();
  const isBusy = useSelector((state) => busySelector(state, LOAD));
  const isLoading = useSelector((state) => isLoadingState(state, LOAD.ACTION));
  const items = useSelector((state) => selector(state, payerId));

  const loadAction = (props) => {
    dispatch(load({ fspId, payerId, ...props }));
  };

  useEffect(() => {
    dispatch(load({ fspId, payerId }));
  }, [dispatch, fspId, payerId]);

  return (
    <AccountList
      actionConstant={LOAD}
      columnMeta={columnMeta}
      detailsRender={detailsRender}
      fspId={fspId}
      isBusy={isBusy}
      isLoading={isLoading}
      items={items}
      loadAction={loadAction}
      name="externalIAV"
      payerId={payerId}
      settingType={SETTING_TYPE}
      title="External Verification Attempts"
    />
  );
};

ExternalIAV.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default ExternalIAV;
