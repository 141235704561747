/* eslint-disable react/no-array-index-key */
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FSP_ID, NA_VALUE } from 'consts';
import RenderOptionPart from '../../RenderOptionPart';

const options = { insideWords: true };

const RenderOption =
  ({ value }) =>
  (fsp, { inputValue }) => {
    const name = fsp?.name ?? NA_VALUE;
    const fspDisplayValue = value === FSP_ID ? `${fsp?.id} ` : `${fsp?.name} `;
    const text = `${fspDisplayValue} ${name} (${fsp?.id})`;
    const matches = match(fspDisplayValue, inputValue, options);
    const parts = parse(text, matches);

    return parts.map(({ highlight, text }, index) => (
      <RenderOptionPart key={index} highlight={highlight} text={text} />
    ));
  };

export default RenderOption;
