import AuditContext from 'components/common/Audit/AuditContext';
import { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const LoginFailuresDrawerAppBarTitle = () => {
  const { item, selectorId } = useContext(AuditContext);
  const { name, nickName } = useSelector((state) => selectorId(state, item));

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography color="inherit" variant="h6">
          Login Failures - {name || nickName || 'No Name Defined'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginFailuresDrawerAppBarTitle;
