import {
  ACTIVITY,
  BUSINESS_CODE,
  DDA_ID_CODE,
  FSP_CODE,
  FSP_PAYER_ID,
  ORGANIZATION,
  PERSON,
  READ_ONLY,
} from 'consts';
import { client, formatQueryParams } from 'util/sdk';
import {
  compose,
  fromPairs,
  find,
  head,
  isNil,
  omit,
  propEq,
  zip,
} from 'ramda';
import {
  cleanDDA,
  cleanPayer,
  getUpdatedStatus,
  accountTitleCheck,
} from './index';
import { isOn } from '../util';

const prefix = 'status';
const expand = [FSP_CODE];
export const ddaAttributes = ['accountnbr', 'fspddaid'];

const removeNewTargetFieldProperties = (obj) => {
  delete obj.insUserId;
  delete obj.cardInternal;
  delete obj.cardType;
  delete obj.ddaPalFromOffOn;
  delete obj.insAdminUserId;
  delete obj.updAdminUserId;

  return obj;
};

export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).payers.query(formatQueryParams({ expand, ...params }));

export const loadById = async (params) => head(await load(params));

export const decryptPayerSSN = ({ fspId, payerId }) =>
  client.fsps(fspId).payers(payerId).decryptTaxId();

export const decryptFspPayerId = ({ fspId, payerId }) =>
  client.fsps(fspId).payers(payerId).decryptFspPayerId();

export const merge = ({ fspId, ...data }, auth) =>
  client.executeRequest('POST', `fsps/${fspId}/mergeuserprofile`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: 'payer' },
  });

export const update = ({ fspId, payerId, status, requestFrom }, auth) =>
  client
    .fsps(fspId)
    .payers(payerId)
    .updateStatus(getUpdatedStatus(status, auth, prefix, { requestFrom }), {
      adminUserId: auth.id,
    });

export const registerPayer = async ({
  fspId,
  payerId,
  payerUser = {},
  sso = READ_ONLY,
  userId = undefined,
}) => {
  const getDDAs = async () => {
    const [ddas, userDdas] = await Promise.all([
      client.fsps(fspId).payers(payerId).ddas.getAll(),
      client
        .fsps(fspId)
        .userDdas.query((_) => [_.userId(userId), _.payerId(payerId)]),
    ]);

    return Promise.all(
      ddas
        .filter((dda) => !isNil(find(propEq(DDA_ID_CODE, dda.id), userDdas)))
        .map(transformDDA(userDdas))
    );
  };
  const getPayer = async () => {
    const payer = cleanPayer(await client.fsps(fspId).payers(payerId).get());

    if (payer.taxIdMask) {
      payer.taxId = await client.fsps(fspId).payers(payerId).decryptTaxId();
    }

    // Why do we make this call? It seems on the surface to be duplicate call.
    // There is a configuration parameter:
    //   fspconfig.fspPayerIdConfidentialOffOn === "on|off"
    // Where the fspPayerId parameter won't be returned by the payer call if
    // the FI deems that information confidential.
    if (isNil(payer.fspPayerId)) {
      // This call will always return the fspPayerId parameter regardless of the
      // fspPayerIdConfidentialOffOn setting
      const [payerConfidential] = await client
        .fsps(fspId)
        .payers.query((_) => [_.id(payerId), _.attributes([FSP_PAYER_ID])]);

      payer.fspPayerId = payerConfidential.fspPayerId;
    }

    return omit(
      [payer.payerType === BUSINESS_CODE ? PERSON : ORGANIZATION],
      payer
    );
  };

  const transformDDA = (userDdas) => async (dda) => {
    const target = cleanDDA(dda);
    const userDda = find(propEq(DDA_ID_CODE, dda.id), userDdas);
    const [ddaClear] = await client
      .fsps(fspId)
      .ddas.query((_) => [_.id(dda.id), _.attributes(ddaAttributes)]);

    const source = {
      accountNbr: ddaClear.accountNbr,
      fspDdaId: ddaClear.fspDdaId,
      userBillPayFromOffOn: userDda.payFromPrivOffOn,
      userDefaultBillPayDdaOffOn: userDda.defaultBillPay,
      userP2PPayFromOffOn: userDda.p2pFromPrivOffOn,
      userTransferFromOffOn: userDda.transferFromPrivOffOn,
      userTransferToOffOn: userDda.transferToPrivOffOn,
    };

    /**
     * Business Entitlements - Liberty Bank
     * https://payrailz.atlassian.net/browse/ON-1399
     */
    if (accountTitleCheck(dda)) {
      source.accountTitle = dda.accountTitle;
      source.uspsAddress = dda.uspsAddress;
    }

    const targetReturnValue = removeNewTargetFieldProperties(target);

    return { dda: Object.assign(targetReturnValue, source) };
  };

  const ssoData = compose(
    fromPairs,
    zip(['payer', 'ddas'])
  )(await Promise.all([getPayer(), getDDAs()]));

  if (ssoData.payer.payerType === BUSINESS_CODE) {
    const { firstName, lastName, mobilePhone } = await client
      .fsps(fspId)
      .users(userId)
      .get();

    const userClear = await client.fsps(fspId).users(userId).decryptFspUserId();

    ssoData.payerUser = {
      role: payerUser.role,
      userType: payerUser.userType,
      mobilePhone,
    };
    ssoData.user = { firstName, lastName, fspUserId: userClear };
  }

  return client.fsps(fspId).payers.register(ssoData, { sso });
};

export const ssoClientUrl = async ({
  fspId,
  payerId,
  payerUser = {},
  userId,
}) => {
  let type = ACTIVITY;
  const params = {
    fspId,
    payerId,
    payerUser,
    userId,
    sso: READ_ONLY,
  };
  const { sso } = await registerPayer(params);
  const { links, ssoToken, uri } = sso;
  const defaultedLinks = links.filter(({ defaultUri }) => isOn(defaultUri));

  if (defaultedLinks.length === 1) {
    type = head(defaultedLinks)?.type;
  }

  return `${uri}?ssoToken=${ssoToken}&fspId=${fspId}&payerId=${payerId}&type=${type}`;
};
