import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { MenuList as MuiMenuList } from '@material-ui/core';
import MenuItem from './MenuItem';

const MenuList = forwardRef(({ anchorEl, buttons, onMenuItemClick }, ref) => (
  <MuiMenuList ref={ref}>
    {buttons.map(({ pos, ...props }) => (
      <MenuItem
        {...props}
        key={pos}
        anchorEl={anchorEl}
        onClick={onMenuItemClick}
        pos={pos}
      />
    ))}
  </MuiMenuList>
));

MenuList.displayName = 'MenuList';

MenuList.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
};

export default MenuList;
