import { TextField } from '@material-ui/core';
import { DestinationMask, FundingMask } from 'components/common/fields';
import { AUTOPAY } from '../Autopay';
import TransactionsAutopaySelect from './TransactionsAutopaySelect';
import { stringOperatorsSdk } from '../../../../../types/search_by';

export const getLoanTypeName = (show) =>
  show
    ? [
        {
          field: {
            name: `Loan Name`,
            value: 'loanName',
          },
          operators: stringOperatorsSdk,
        },
      ]
    : [];

export const getPayeeNameBiller = (show) =>
  show
    ? [
        {
          field: {
            name: `Payee Name (Biller)`,
            value: 'destination',
          },
          operators: stringOperatorsSdk,
        },
      ]
    : [];

export const getContact = (show) =>
  show
    ? [
        {
          field: {
            name: `Contact`,
            value: 'destination',
          },
          operators: stringOperatorsSdk,
        },
      ]
    : [];

export const getA2AName = (show) =>
  show
    ? [
        {
          field: {
            name: `A2A Name`,
            value: 'destination',
          },
          operators: stringOperatorsSdk,
        },
      ]
    : [];

export const getA2ATraceNumber = (show) =>
  show
    ? [
        {
          field: {
            name: `A2A Trace Number`,
            value: 'a2atracenumber',
          },
          operators: [
            {
              name: 'is equal to',
              value: 'field',
              component: TextField,
            },
          ],
        },
      ]
    : [];

export const getFundingAccountFilter = () => [
  {
    field: { name: `Funding Account (last 4)`, value: 'fundingMask' },
    operators: [
      {
        name: 'is equal to',
        value: 'field',
        component: FundingMask,
      },
    ],
  },
];

export const getDestinationAccountFilter = () => [
  {
    field: {
      name: `Destination Account (last 4)`,
      value: 'destinationMask',
    },
    operators: [
      {
        name: 'is equal to',
        value: 'field',
        component: DestinationMask,
      },
    ],
  },
];

export const getAutopayFilter = (show) =>
  show
    ? [
        {
          field: { name: `${AUTOPAY} Only`, value: 'autopayOffOn' },
          operators: [
            {
              name: 'is equal to',
              value: 'field',
              component: TransactionsAutopaySelect,
            },
          ],
        },
      ]
    : [];

export const getLoanNumberFilter = (show) =>
  show
    ? [
        {
          field: { name: `Loan Number`, value: 'loanNumber' },
          operators: [
            {
              key: 'equalOperator',
              name: 'is equal to',
              value: 'field',
              component: TextField,
            },
          ],
        },
      ]
    : [];

export const getStatusFilter = (show) =>
  show
    ? [
        {
          field: { name: `Status`, value: 'status' },
          operators: [
            {
              key: 'equalOperator',
              name: 'is equal to',
              value: 'field',
              component: TextField,
            },
          ],
        },
      ]
    : [];

export const getLoanNameFilter = (show) =>
  show
    ? [
        {
          field: { name: `Loan Name`, value: 'loanName' },
          operators: [
            {
              key: 'equalOperator',
              name: 'is equal to',
              value: 'field',
              component: TextField,
            },
          ],
        },
      ]
    : [];
