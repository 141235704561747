import { CREATE, LOAD, LOAD_ID, REMOVE, UPDATE } from 'model/billerLogo';
import { create, load, loadById, remove, update } from 'sdk/billerLogo';
import { takeEvery } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Biller Logo "${response.name}"  was created`,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ID.ACTION,
    callAction({
      api: loadById,
      dataName: ORM,
      type: LOAD_ID,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Biller Logo "${response.name}"  was successfully deleted`,
      type: REMOVE,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Biller Logo "${response.name}"  was updated`,
      type: UPDATE,
    })
  );
}
