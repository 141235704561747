import ExpansionList from 'components/common/ExpansionList';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { selectorByPayerId } from 'selectors/oneTimePasscode';
import { compose, withProps } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { load, LOAD } from 'model/oneTimePasscode';
import { pageConnect, pageHandlers } from 'util/page';
import { selector as pageSettingsSelector } from 'selectors/pageSetting';
import {
  ActionsRenderer,
  columnMeta,
  defaultPageSettings,
  detailsRender,
  pageSettingsName,
} from './config';

const getUserIdField = (value) => {
  return value
    ? [
        {
          defaultValue: null,
          field: 'userId',
          operator: 'field',
          value,
        },
      ]
    : [];
};

const OneTimePasscodeList = ({
  fspId,
  handleChange,
  handleSearch,
  order,
  orderBy,
  page,
  payerId,
  renderOptions,
  rowsPerPage,
}) => {
  const items = useSelector((state) => selectorByPayerId(state, payerId));
  const { userId } = useSelector((state) =>
    pageSettingsSelector(state, payerId, pageSettingsName)
  );

  useEffect(() => {
    handleSearch(getUserIdField(userId));
  }, [fspId, handleSearch, userId, payerId]);

  return (
    <ExpansionList
      actionConstant={LOAD}
      ActionsRenderer={ActionsRenderer}
      columnMeta={columnMeta}
      data={items}
      detailsRender={detailsRender}
      emptyTitle="No One Time Passcodes Found"
      name={pageSettingsName}
      onChange={handleChange}
      order={order}
      orderBy={orderBy}
      page={page}
      renderOptions={renderOptions}
      rowsPerPage={rowsPerPage}
    />
  );
};

OneTimePasscodeList.propTypes = {
  fspId: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  renderOptions: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default compose(
  connect(null, { loadAction: load }),
  withProps(({ fspId, loadAction, payerId }) => ({
    actionType: LOAD.ACTION,
    defaultPageSettings,
    loadAction,
    loadParams: { fspId, payerId },
    noSearch: true,
    renderOptions: {},
    settingType: pageSettingsName,
  })),
  pageConnect,
  pageHandlers
)(OneTimePasscodeList);
