import { formatCurrency, dateAndTime } from '../../util';

// eslint-disable-next-line import/prefer-default-export
export const addDerivedData = (item) => {
  item.outstandingBalanceDerived = formatCurrency(item.outstandingBalance);
  item.amtDueDerived = formatCurrency(item.amtDue);
  item.minAmtDerived = formatCurrency(item.minAmt);
  item.updatedAtDerived = dateAndTime(item.updatedAt);

  return item;
};
