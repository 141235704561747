import { selector } from 'selectors/busy';
import { useSelector } from 'react-redux';

export default ({ actionType, actionTypeSecond, confirmActionType }) => {
  const busySelector = (s) => selector(s, actionType);
  const busySecondSelector = (s) => selector(s, actionTypeSecond);
  const confirmSelector = (s) => selector(s, confirmActionType);

  const isBusy = useSelector(busySelector);
  const isBusySecond = useSelector(busySecondSelector);
  const isConfirmActionBusy = useSelector(confirmSelector);

  return {
    isBusy,
    isBusySecond,
    isConfirmActionBusy,
  };
};
