import { client } from 'util/sdk';
import { GET, POST } from 'util/api';
import { FSPS } from 'consts';

const returnModel = { name: 'fraudrulethreshold' };

const loadURL = (fspId) => `/${FSPS}/${fspId}/fraudrulethresholds`;
const updateURL = (fspId, id) => `${loadURL(fspId)}${id ? `/${id}` : ''}`;

export const load = (fspId) =>
  client.executeRequest(GET, loadURL(fspId), { returnModel });

export const update = ({ fspId, id, ...data }, auth) =>
  client.executeRequest(POST, updateURL(fspId, id), {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel,
  });
