import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MASK, NA_VALUE } from 'consts';
import { WAITING } from 'components/common/fields';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  withHandlers,
  withState,
  withProps,
  branch,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import { createRequestConst } from 'util/index';
import { isEmpty, isNil } from 'ramda';
import {
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  withStyles,
} from '@material-ui/core';

export const DECRYPT = createRequestConst('decrypt');
export const EMPTY = '';
export const INITIAL_PERCENT = 100;
export const INTERVALS = 40;
export const SECONDS_TO_SHOW_MASK = 10;

export const getValue = (input, maskSuffix) => {
  let value = WAITING;

  if (input) {
    value = `${MASK}${input}${maskSuffix}`;
  } else if (input === null) {
    value = NA_VALUE;
  }

  return value;
};

const styles = (theme) => ({
  root: {
    minHeight: 48,
  },
  label: {
    color: theme.palette.text.primary,
    font: 'inherit',
    fontFamily: theme.typography.fontFamily,
    userSelect: 'auto',
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  prefix: {
    marginRight: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  progress: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  noIcon: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
  displayNone: {
    display: 'none',
  },
});

const Mask = ({
  VisibilityIcon,
  classes,
  handleOnClick,
  handleUnmask,
  hide,
  maskClassName,
  maskValue,
  prefix,
  prefixClassName,
  progressClassName,
  progressValue,
  unMaskClassName,
  unMaskValue,
}) => {
  return (
    <Grid alignItems="center" className={classes.root} container wrap="nowrap">
      <Grid className={prefixClassName} item t-i="maskTextPrefix">
        {prefix}
      </Grid>
      <Grid
        className={maskClassName}
        item
        onClick={handleOnClick}
        t-i="maskText"
      >
        {unMaskValue || maskValue}
      </Grid>
      <Grid item>
        <IconButton
          aria-label="Visibility On/Off"
          className={unMaskClassName}
          disabled={hide}
          onClick={handleUnmask}
        >
          <VisibilityIcon />
        </IconButton>
      </Grid>
      <Grid item xs>
        <CircularProgress
          className={progressClassName}
          color="primary"
          size={20}
          thickness={7}
          value={progressValue}
          variant="determinate"
        />
      </Grid>
    </Grid>
  );
};

Mask.propTypes = {
  VisibilityIcon: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func,
  handleUnmask: PropTypes.func.isRequired,
  hide: PropTypes.bool.isRequired,
  maskClassName: PropTypes.string.isRequired,
  maskValue: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefixClassName: PropTypes.string.isRequired,
  progressClassName: PropTypes.string.isRequired,
  progressValue: PropTypes.number.isRequired,
  unMaskClassName: PropTypes.string.isRequired,
  unMaskValue: PropTypes.string,
};

Mask.defaultProps = {
  handleOnClick: null,
  prefix: EMPTY,
  unMaskValue: null,
};

export default compose(
  withStyles(styles),
  setDisplayName('Mask'),
  branch(
    ({ value }) =>
      !isNil(value) && typeof value !== 'string' && typeof value !== 'number',
    renderComponent(() => <Typography variant="body2">{NA_VALUE}</Typography>)
  ),
  connect(), // needed for dispatch()
  withState('intervalId', 'setIntervalId', null),
  withState('progressValue', 'setProgressValue', INITIAL_PERCENT),
  withState('timeoutId', 'setTimeoutId', null),
  withState('unMaskValue', 'setUnMaskValue', null),
  withProps(({ suffix, value }) => ({
    hide: isNil(value),
    maskSuffix: isEmpty(suffix) || isNil(suffix) ? EMPTY : `-${suffix}`,
  })),
  withProps(
    ({
      classes,
      hide,
      icon = true,
      maskSuffix,
      prefix,
      unMaskValue,
      value,
    }) => ({
      inlineWithValue: !icon && !isNil(value),
      maskClassName: classNames(classes.label, {
        [classes.noIcon]: !icon && !isNil(value),
      }),
      maskValue: getValue(value, maskSuffix),
      prefixClassName: classNames(classes.label, classes.prefix, {
        [classes.displayNone]: isEmpty(prefix) || isNil(prefix),
      }),
      progressClassName: classNames(classes.hidden, classes.progress, {
        [classes.visible]: unMaskValue,
      }),
      unMaskClassName: classNames(classes.iconButton, {
        [classes.displayNone]: hide || !icon,
      }),
      VisibilityIcon: unMaskValue ? VisibilityOff : Visibility,
    })
  ),
  withHandlers({
    decrement:
      ({ intervalId, progressValue, setIntervalId, setProgressValue }) =>
      () => {
        const percent = progressValue - 100 / INTERVALS;

        if (percent !== 0) {
          setProgressValue(percent);
        } else {
          clearInterval(intervalId);
          setIntervalId(null);
          setProgressValue(INITIAL_PERCENT);
        }
      },
    unmask:
      ({
        intervalId,
        setIntervalId,
        setProgressValue,
        setTimeoutId,
        setUnMaskValue,
        timeoutId,
      }) =>
      () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        setIntervalId(null);
        setProgressValue(INITIAL_PERCENT);
        setTimeoutId(null);
        setUnMaskValue(null);
      },
  }),
  withHandlers({
    handleUnmask:
      ({
        api,
        decrement,
        dispatch,
        format,
        maskSuffix,
        setIntervalId,
        setTimeoutId,
        setUnMaskValue,
        timeoutId,
        unmask,
        unmaskSuffix,
      }) =>
      async (event) => {
        event.stopPropagation();

        if (!timeoutId) {
          try {
            const data = await api();
            const suffix = unmaskSuffix ? `-${unmaskSuffix}` : maskSuffix;

            setIntervalId(
              setInterval(decrement, (SECONDS_TO_SHOW_MASK * 1000) / INTERVALS)
            );
            setTimeoutId(setTimeout(unmask, SECONDS_TO_SHOW_MASK * 1000));
            setUnMaskValue(
              isNil(data)
                ? NA_VALUE
                : `${format ? format(data) : data}${suffix}`
            );
          } catch (error) {
            dispatch({
              type: DECRYPT.FAILURE,
              error: { message: 'Unable to decrypt', error },
            });
          }
        } else {
          unmask();
        }
      },
  }),
  withProps(({ handleUnmask, inlineWithValue }) => ({
    handleOnClick: inlineWithValue ? handleUnmask : undefined,
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { maskValue } = this.props;

      if (maskValue !== prevProps.maskValue) {
        const { unmask } = this.props;

        unmask();
      }
    },
    componentWillUnmount() {
      const { intervalId, timeoutId } = this.props;

      clearInterval(intervalId);
      clearTimeout(timeoutId);
    },
  }),
  pure
)(Mask);
