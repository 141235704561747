import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { LOAD } from 'model/userProfile';
import { LOAD_ID } from 'model/user';
import { makeStyles } from '@material-ui/core/styles';
import { useIsBusy } from 'hooks';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginLeft: theme.spacing(1),
  },
}));

const IdToDisplayNameProgress = ({ buttonRef, target = {} }) => {
  const classes = useStyles();
  const isBusy = useIsBusy(LOAD_ID);
  const isBusyAdmin = useIsBusy(LOAD);

  return isBusy || (isBusyAdmin && target?.parentElement === buttonRef) ? (
    <CircularProgress className={classes.circularProgress} size={20} />
  ) : null;
};

IdToDisplayNameProgress.propTypes = {
  buttonRef: PropTypes.object,
  target: PropTypes.object,
};

IdToDisplayNameProgress.defaultProps = {
  buttonRef: null,
  target: null,
};

export default IdToDisplayNameProgress;
