import {
  ADD,
  DELETE,
  FORM_NAME,
  LOAD,
  LOAD_BY_ORG_ID,
  UPDATE,
} from 'model/group';
import { createAdminPriviledge } from 'util/rbac';
import {
  addGroup,
  deleteGroup,
  load,
  loadByOrgId,
  updateGroup,
} from 'sdk/group';
import { omit } from 'ramda';
import { takeEvery } from 'redux-saga/effects';
import { callAction } from '../util';
import { addGroupAdminCall } from './group_admin';
import { updateOrgCall } from './org';

function* postAddGroup({ data, result }) {
  const { orgId, manageOther, orgAdminGroupId } = data;
  const postResult = { ...result };
  const { id } = postResult;

  if (orgAdminGroupId === 'self') {
    const orgUpdateData = {
      id: orgId,
      adminGroupId: id,
    };
    yield updateOrgCall()({ data: orgUpdateData });
  }
  if (orgAdminGroupId === 'self' || manageOther === 'true') {
    const groupAdminData = createAdminPriviledge({
      groupId: id,
      adminGroupId: id,
    });

    const addGroupAdminResult = yield addGroupAdminCall()({
      data: groupAdminData,
    });
    const restOfData = omit(
      ['id', 'offset', 'href', 'createdAt', 'updatedAt'],
      addGroupAdminResult
    );
    postResult.groupAdmins.push(restOfData);
  }
  return postResult;
}

const prepareData = (data) =>
  omit(['orgId', 'orgAdminGroupId', 'manageOther', 'managedBy'], data);

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: 'adminGroup',
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_BY_ORG_ID.ACTION,
    callAction({
      api: loadByOrgId,
      dataName: 'adminGroup',
      type: LOAD_BY_ORG_ID,
    })
  );

  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: addGroup,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.name} Group has been successfully added.`,
      postAction: postAddGroup,
      prepareData,
      type: ADD,
    })
  );

  yield takeEvery(
    DELETE.ACTION,
    callAction({
      api: deleteGroup,
      formatSuccessMessage: (data) =>
        `${data.name} Group has been successfully removed.`,
      type: DELETE,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: updateGroup,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.name} Group has been successfully updated.`,
      prepareData,
      type: UPDATE,
    })
  );
}
