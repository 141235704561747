import PropTypes from 'prop-types';
import { ASC, DESC } from 'consts';
import { makeStyles } from '@material-ui/core/styles';
import { memo, useEffect, useState } from 'react';
import { selector as pageSettingSelector } from 'selectors/pageSetting';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { update } from 'model/pageSetting';

const useStyles = makeStyles({
  stickyHeader: (hasPagination) => ({
    top: hasPagination ? 52 : 0,
  }),
});

const TableHeadColumns = ({
  columns,
  defaultPageSettings,
  hasPagination,
  pageSettingsName,
  payerId,
}) => {
  const pageSettings = useSelector(
    (state) =>
      pageSettingSelector(
        state,
        payerId,
        pageSettingsName,
        defaultPageSettings
      ),
    shallowEqual
  );
  const [order, setOrder] = useState(pageSettings.order);
  const [orderBy, setOrderBy] = useState(pageSettings.orderBy);
  const classes = useStyles(hasPagination);
  const dispatch = useDispatch();

  const handleSort = (property) => () => {
    const isAsc = orderBy === property && order === ASC;
    const theOrder = isAsc ? DESC : ASC;

    dispatch(
      update({
        order: theOrder,
        orderBy: property,
        payerId: 0,
        type: pageSettingsName,
      })
    );
  };

  /**
   * If the page setting for order or orderBy gets changed external to this
   * component using an effect will keep the arrows and arrow directions in sync
   */
  useEffect(() => {
    setOrder(pageSettings.order);
    setOrderBy(pageSettings.orderBy);
  }, [pageSettings.order, pageSettings.orderBy]);

  return columns.map(({ align, id, label, label2, orderId, sort }) => {
    const orderById = orderId ?? id;
    const isOrderedById = orderBy === orderById;
    const direction = isOrderedById ? order : ASC;
    const sortDirection = isOrderedById ? order : false;

    return (
      <TableCell
        key={id}
        align={align}
        classes={{ stickyHeader: classes.stickyHeader }}
        sortDirection={sortDirection}
      >
        {sort ? (
          <TableSortLabel
            active={isOrderedById}
            direction={direction}
            onClick={handleSort(orderById)}
          >
            {label}
            {label2 && <br />}
            {label2}
          </TableSortLabel>
        ) : (
          <span>
            {label}
            {label2 && <br />}
            {label2}
          </span>
        )}
      </TableCell>
    );
  });
};

TableHeadColumns.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSettings: PropTypes.object.isRequired,
  hasPagination: PropTypes.bool,
  pageSettingsName: PropTypes.string.isRequired,
  payerId: PropTypes.number,
};

TableHeadColumns.defaultProps = {
  hasPagination: true,
  payerId: 0,
};

export default memo(TableHeadColumns);
