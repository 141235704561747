/* eslint-disable react/no-array-index-key */
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { CHANNEL_ID, NA_VALUE } from 'consts';
import RenderOptionPart from '../../RenderOptionPart';

const options = { insideWords: true };

const RenderOption =
  ({ value }) =>
  (channel, { inputValue }) => {
    const name = channel?.name ?? NA_VALUE;
    const channelDisplayValue =
      value === CHANNEL_ID ? `${channel?.id} ` : `${channel?.name} `;
    const text = `${channelDisplayValue} ${name} (${channel?.id})`;
    const matches = match(channelDisplayValue, inputValue, options);
    const parts = parse(text, matches);

    return parts.map(({ highlight, text }, index) => (
      <RenderOptionPart key={index} highlight={highlight} text={text} />
    ));
  };

export default RenderOption;
