import { Button } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { head } from 'ramda';
import { useRef } from 'react';

const fileToObject = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = () => {
      try {
        resolve(JSON.parse(reader.result));
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = reject;
  });
};

const ImportUploadButton = ({ onSuccess, onError }) => {
  const formRef = useRef();
  const ref = useRef();

  const handleClick = () => ref.current?.click();

  const handleChange = async (event) => {
    const files = Array.from(event.target.files);
    const fileAsObj = await fileToObject(head(files)).catch((error) => {
      if (onError) {
        onError(error);
      }
    });
    if (fileAsObj && onSuccess) {
      onSuccess(fileAsObj);
    }

    // cleanup
    formRef.current.reset();
  };

  return (
    <form ref={formRef}>
      <input
        ref={ref}
        accept="application/json"
        hidden
        onChange={handleChange}
        type="file"
      />
      <Button onClick={handleClick} startIcon={<Publish />} variant="outlined">
        Upload JSON File
      </Button>
    </form>
  );
};

ImportUploadButton.propTypes = {
  onError: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
};

ImportUploadButton.defaultProps = {
  onError: null,
};

export default ImportUploadButton;
