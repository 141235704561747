import PropTypes from 'prop-types';
import { TableCell, Tooltip, withTheme } from '@material-ui/core';

const LimitsTableHeadCell = ({
  className,
  edit,
  theme,
  title,
  title2,
  tooltip,
}) => {
  const align = edit ? 'left' : 'right';
  const enterDelay = theme.transitions.duration.standard;

  return (
    <TableCell align={align} className={className}>
      <Tooltip enterDelay={enterDelay} title={tooltip}>
        <div>
          {title2 ? (
            <>
              {title}
              <br />
              {title2}
            </>
          ) : (
            title
          )}
        </div>
      </Tooltip>
    </TableCell>
  );
};

LimitsTableHeadCell.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  title2: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
};

LimitsTableHeadCell.defaultProps = {
  className: undefined,
  title2: null,
};

export default withTheme(LimitsTableHeadCell);
