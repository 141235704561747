import PropTypes from 'prop-types';
import Rule from './Rule';

const LowercaseRule = ({ password, ...props }) => {
  return (
    <Rule {...props} enabled={/[a-z]/.test(password)} text="lowercase letter" />
  );
};

LowercaseRule.propTypes = {
  password: PropTypes.string.isRequired,
};

export default LowercaseRule;
