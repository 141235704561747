import PropTypes from 'prop-types';
import email from 'email-validator';
import { isNilOrEmpty } from 'util/index';
import { TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { always } from 'ramda';
import { getDisabled } from '../consts';

const validate = (value) =>
  value && !email.validate(value) ? 'Invalid Email' : null;

const TextFieldEmail = ({
  autoComplete,
  disabled,
  name,
  onChange,
  required,
  ...props
}) => {
  const [field, meta] = useField({ name, validate });
  const value = field.value ?? '';
  const formikContext = useFormikContext();

  const error = meta?.touched && !isNilOrEmpty(meta?.error);

  return (
    <TextField
      {...field}
      {...props}
      autoComplete={autoComplete}
      disabled={getDisabled(disabled, field, meta, formikContext)}
      error={error}
      helperText={meta?.touched ? meta.error : ''}
      required={required}
      value={value}
    />
  );
};

TextFieldEmail.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

TextFieldEmail.defaultProps = {
  autoComplete: 'off',
  disabled: false,
  onChange: always,
  required: false,
};

export default TextFieldEmail;
