import { AUTOPAY } from '../../Autopay';
import DetailsRender from './DetailsRender';

export { default as ActionsRenderer } from './ActionsRenderer';

export const columnMeta = [
  {
    context: true,
    id: 'payeeName',
    label: 'Biller Name',
    xs: 2,
  },
  {
    id: 'statementPeriodDerived',
    label: 'Statement Period',
    xs: 2,
  },
  {
    id: 'dueOnDerived',
    label: 'Due Date',
    xs: 2,
  },
  {
    id: 'amountDueDerived',
    label: 'Due Amount',
    xs: 2,
  },
  {
    capitalize: true,
    id: 'autopayStatusDerived',
    label: `${AUTOPAY} Status`,
    xs: 2,
  },
];

export const defaultPageSettings = { order: 'desc', orderBy: 'id' };

export const pageSettingsName = 'autopayScheduled';

export const detailsRender = (autopayScheduled) => (
  <DetailsRender autopayScheduled={autopayScheduled} />
);
