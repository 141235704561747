import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import UpdateRequiredTitle from './UpdateRequiredTitle';
import UpdateRequiredVersion from './UpdateRequiredVersion';

const UpdateRequired = ({ message }) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      event.stopPropagation();
    }
  };

  return (
    <Dialog onClose={handleClose} open>
      <UpdateRequiredTitle />
      <DialogContent>
        <DialogContentText>
          Ontrac needs to be upgraded to a newer version. For this upgrade to
          take effect you will need to refresh your browser or close this
          browser tab and open up a new one.
        </DialogContentText>
        <UpdateRequiredVersion versionRequired={message.versionRequired} />
      </DialogContent>
    </Dialog>
  );
};

UpdateRequired.propTypes = {
  message: PropTypes.shape({
    message: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    versionRequired: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpdateRequired;
