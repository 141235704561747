import PropTypes from 'prop-types';
import authTokenSelector from 'selectors/authToken';
import { Password } from 'components/common/fields/render';
import { UPDATE, update, logout } from 'reducers/authToken';
import { always } from 'ramda';
import {
  branch,
  compose,
  lifecycle,
  pure,
  renderNothing,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import { submit as submitAction } from 'util/index';
import { propTypes, reduxForm, Field } from 'redux-form';
import { selector } from 'selectors/busy';
import { validateSelector } from 'selectors/security';
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

import usePasswordUpdateIdle from '../../Password/usePasswordUpdateIdle';

const validate = ({ confirmPassword, newPassword }) => {
  const errors = {};

  if (confirmPassword && newPassword) {
    if (confirmPassword !== newPassword) {
      errors.confirmPassword = "Passwords don't match";
    }

    if (newPassword.length < 8) {
      errors.newPassword = 'Please choose a longer password';
    }
  }

  return errors;
};

const PasswordUpdate = ({
  buttonText,
  byRuleDisabled,
  confirmHasValue,
  handleChangeConfirm,
  invalid,
  onChange,
  onTimedOut,
  passwordExpired,
  pristine,
  submit,
  submitting,
}) => {
  usePasswordUpdateIdle(onTimedOut);

  return (
    <form onSubmit={submit}>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          {passwordExpired && (
            <Grid item>
              <Typography>
                Your password has expired. Please change your password to access
                Ontrac.
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Field
              autoComplete="new-password"
              autoFocus
              component={Password}
              disabled={submitting}
              fullWidth
              label="New password"
              name="newPassword"
              onChange={onChange}
              type="password"
            />
          </Grid>
          <Grid item>
            <Field
              autoComplete="new-password"
              component={Password}
              disabled={submitting}
              fullWidth
              label="Confirm new password"
              name="confirmPassword"
              onChange={handleChangeConfirm}
              type="password"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="center">
          <Grid item>
            <Button
              color="primary"
              disabled={
                byRuleDisabled ||
                !confirmHasValue ||
                invalid ||
                pristine ||
                submitting
              }
              size="large"
              type="submit"
              variant="contained"
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </form>
  );
};

PasswordUpdate.propTypes = {
  buttonText: PropTypes.string,
  byRuleDisabled: PropTypes.bool,
  confirmHasValue: PropTypes.bool.isRequired,
  handleChangeConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onTimedOut: PropTypes.func.isRequired,
  passwordExpired: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  ...propTypes,
};

PasswordUpdate.defaultProps = {
  buttonText: 'Change Password',
  byRuleDisabled: false,
  onChange: always,
  passwordExpired: false,
};

export default compose(
  setDisplayName('PasswordUpdate'),
  branch(({ visible }) => !visible, renderNothing),
  connect((state) => ({
    authToken: authTokenSelector(state),
    isBusy: selector(state, UPDATE),
    passwordResetValidation: validateSelector(state),
  })),
  reduxForm({
    form: 'auth',
    validate,
    onSubmitSuccess: submitAction(logout),
  }),
  withProps(({ form, handleSubmit }) => ({
    submit: handleSubmit(submitAction(update, form)),
  })),
  withState('confirmHasValue', 'setConfirmHasValue', false),
  withHandlers({
    handleChangeConfirm:
      ({ setConfirmHasValue }) =>
      () =>
        setConfirmHasValue(true),
  }),
  lifecycle({
    componentDidMount() {
      const { authToken, initialize, passwordResetValidation } = this.props;

      // If this is a password update, the user will be logged in
      // and authToken have data.
      // Or, they could be resetting their password in which case the
      // passwordResetValidation object will have the necessary data.
      const userName =
        authToken.userLogin ?? passwordResetValidation?.data?.userName;

      initialize({
        userName,
        token: passwordResetValidation?.data?.token,
      });
    },
    componentDidUpdate(prevProps) {
      const { authToken, error, isBusy } = this.props;

      if (prevProps.isBusy !== isBusy) {
        const { onProgress = always } = this.props;

        onProgress(isBusy);
      }

      if (error === 'false' && authToken.updated) {
        const { onUpdated = always } = this.props;

        onUpdated(authToken);
      }
    },
  }),
  pure
)(PasswordUpdate);
