import axios from 'axios';
import https from 'https';
import { omit } from 'ramda';
import { VERSION } from 'consts';
import { client } from './sdk';
import { getHeaderAuthorization, getSDKTokenHeader, set } from './token';

const HEADER_AUTHORIZATION = 'Authorization';
const NO_CACHE = 'no-cache';
const OMITTED_PROPS = [
  'offset',
  'href',
  'createdAt',
  'updatedAtdata',
  'updatedAt',
];
const POST_UPDATE = 'P0ST_UPDATE';

export const DELETE = 'DELETE';
export const GET = 'GET';
export const ONTRAC_VERSION_HEADER = 'Ontrac-Version';
export const POST = 'POST';
export const PUT = 'PUT';

const api = (uri, method, data, reqOptions) => {
  const options = getOptions(uri, method, data, reqOptions);

  return axios(options).then(
    (res) => res.data,
    (error) => Promise.reject(error.response ? error.response : error)
  );
};
const authUrl = (uri) => `${uri}`;
const getUrl = (uri) => `${uri}`;

const headers = { [ONTRAC_VERSION_HEADER]: VERSION };
const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
  requestCert: false,
});

export const authApi = (
  uri,
  reqData,
  reqOptions,
  method = POST,
  reset = false
) => {
  const options = getOptions(authUrl(uri), method, reqData, reqOptions);

  return axios(options).then(({ data, headers }) => {
    set(headers);

    if (!reset) {
      client.setExtraRequestHttpOptions(getSDKTokenHeader());
    }

    return data;
  });
};

export const resetApi = (uri, data, reqOptions) =>
  authApi(uri, data, reqOptions, POST, true);

export const deleteApi = (uri, data, reqOptions) =>
  api(getUrl(uri), DELETE, data, reqOptions);

export const getApi = (uri, data, reqOptions) =>
  api(getUrl(uri), GET, data, reqOptions);

export const getOptions = (url, method, data, reqOptions) => {
  const options = {
    headers,
    httpsAgent,
    method,
    params: {},
    url,
    withCredentials: true,
  };
  const arg = reqOptions ? reqOptions.token : undefined;
  const headerAuthorization = getHeaderAuthorization(arg);

  if (headerAuthorization) {
    options.headers[HEADER_AUTHORIZATION] = headerAuthorization;
  }

  if (method === GET) {
    options.headers.Pragma = NO_CACHE;
  }

  if (data) {
    if (method === POST) {
      options.data = data;
    } else if (method === DELETE) {
      const { id } = data;
      options.url = `${url}/${id}`;
    } else if (method === POST_UPDATE) {
      const { id, ...restOfData } = omit(OMITTED_PROPS, data);
      options.url = `${url}/${id}`;
      options.data = restOfData;
      options.method = POST;
    } else {
      options.params = data;
    }
  }

  if (reqOptions) {
    const { userId, responseType } = reqOptions;

    if (userId) {
      options.params.adminuserid = userId;
    }

    if (responseType) {
      options.responseType = responseType;
    }
  }

  return options;
};

export const postApi = (uri, data, reqOptions) =>
  api(getUrl(uri), POST, data, reqOptions);

export const postUpdateApi = (uri, data, reqOptions) =>
  api(getUrl(uri), POST_UPDATE, data, reqOptions);
