import PropTypes from 'prop-types';
import { memo, useCallback, useEffect } from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { equals, isNil, always } from 'ramda';
import { selector as pageSettingSelector } from 'selectors/pageSetting';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { update as updatePageSetting } from 'model/pageSetting';

export const DEFAULT_DAYS = 30;
const CONTAINED = 'contained';
const DEFAULT = 'default';
const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const TEXT = 'text';

const useStyles = makeStyles(() => ({
  progress: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const DaysFilter = ({
  busy,
  hasData,
  loading,
  onChange,
  settingId,
  settingType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { daysFilter: days } = useSelector(
    (state) => pageSettingSelector(state, settingId || 0, settingType),
    shallowEqual
  );

  const handleOnClick = useCallback(
    (value) => {
      const data = {
        payerId: settingId || 0,
        type: settingType,
        daysFilter: value,
      };

      dispatch(updatePageSetting(data));
      onChange(value);
    },
    [dispatch, onChange, settingId, settingType]
  );

  useEffect(() => {
    if (isNil(days)) {
      const data = {
        payerId: settingId || 0,
        type: settingType,
        daysFilter: DEFAULT_DAYS,
      };

      dispatch(updatePageSetting(data));
    }
  }, [dispatch, days, settingId, settingType]);

  const renderButton = useCallback(
    (id) => {
      const isEqual = equals(days, id);
      return (
        <Button
          key={`days${id}`}
          color={isEqual ? PRIMARY : DEFAULT}
          disabled={loading}
          disableRipple={busy}
          onClick={() => handleOnClick(id)}
          t-i={`days${id}`}
          variant={isEqual ? CONTAINED : TEXT}
        >
          {busy && hasData && isEqual && (
            <CircularProgress
              className={classes.progress}
              color={loading ? PRIMARY : SECONDARY}
              size={24}
            />
          )}
          {`${id} days`}
        </Button>
      );
    },
    [busy, classes.progress, days, handleOnClick, hasData, loading]
  );

  return [7, 30, 60, 90].map((value) => renderButton(value));
};

DaysFilter.propTypes = {
  busy: PropTypes.bool,
  hasData: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  settingId: PropTypes.number,
  settingType: PropTypes.string.isRequired,
};

DaysFilter.defaultProps = {
  busy: false,
  hasData: false,
  loading: false,
  onChange: always,
  settingId: 0,
};

export default memo(DaysFilter);
