import {
  equals,
  find,
  includes,
  indexOf,
  reject,
  slice,
  startsWith,
} from 'ramda';
import { ACTION, beginUpdate, endUpdate } from 'util/actions';

export const isUpdatingState = (state, type) => includes(type, state.updating);

export default (state = [], { type }) => {
  if (beginUpdate(type) && !includes(type, state)) {
    return [...state, type];
  }
  if (endUpdate(type)) {
    const foundItem = find(
      (item) => startsWith(slice(0, indexOf(ACTION, item), item), type),
      state
    );
    return reject(equals(foundItem), state);
  }
  return state;
};
