import { alpha } from '@material-ui/core/styles';
import { createTheme as createThemeV4 } from '@material-ui/core';
import { createTheme as createThemeV5 } from '@mui/material/styles';

export const fraud = {
  circleRatio: 0.6,
  high: '#FF0000',
  low: '#34C759',
  medium: '#FF9500',
  rotation: 0.7,
  strokeLinecap: 'round',
  strokeWidth: 10,
  textColor: '#000',
  textSize: 28,
  trailColor: '#eee',
};

const theme = createThemeV4({
  typography: {},
});

/**
 * A palette is a collection of colors, i.e. hues and their shades.
 * Material-UI provides all colors from the Material Design guidelines.
 *
 * See these for reference
 * 1. Material-UI - Color: https://material-ui-next.com/style/color/
 * 2. Material Design Color Tool: https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=732282&secondary.color=F3E5F5
 * 3. https://en.wikipedia.org/wiki/Web_colors
 */
const palette = {
  type: 'light',
  primary: {
    main: '#732282',
    light: '#a452b2',
    dark: '#440055',
    contrastText: '#ffffff',
  },
  fraud,
  secondary: {
    main: '#f3e5f5',
    light: '#ffffff',
    dark: '#c0b3c2',
    contrastText: '#000000',
  },
  text: {
    primary: '#555555',
  },
  background: {
    watermark: '#f5f5f5',
  },

  /**
   * Keeping this here for future reference
   */
  // background: {
  //   default: '#fafafa',
  //   paper: '#ffffff',
  // },
  // text: {
  //   disabled: 'rgba(0, 0, 0, 0.38)',
  //   hint: 'rgba(0, 0, 0, 0.38)',
  //   primary: 'rgba(0, 0, 0, 0.87)',
  //   secondary: 'rgba(0, 0, 0, 0.54)',
  // },
};

/**
 * Too many type sizes and styles at once can spoil any layout.
 * A typographic scale has a limited set of type sizes that work well
 * together along with the layout grid.
 */
const typography = {
  fontSize: 14, // default: 14 - it's only here to be referenced in other overrides
  subtitle1: {
    fontSize: 18, // default: "1rem"
  },
  caption: {
    fontSize: 12, // default: "0.75rem"
  },
  suppressWarning: true,
};

/**
 * All global component overrides go here.
 * Don't forget to reference the source file when looking to make a change
 */
const overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        letterSpacing: 'normal',
        // We need to reset the default font size since in v4 by default
        // applies 0.875rem apparently because of the body1/body2 swap
        // See tickets:
        //   https://github.com/mui-org/material-ui/issues/18442
        //   https://github.com/mui-org/material-ui/issues/17100
        fontSize: '1rem',
      },
    },
  },
  /**
   * More style overrides here:
   * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js
   */
  MuiButton: {
    contained: {
      boxShadow: 'none', // default: `0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)`
    },
  },
  MuiSkeleton: {
    root: {
      backgroundColor: '#ccc',
    },
  },
  MuiAccordionSummary: {
    root: {
      '&$focused': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      paddingRight: theme.spacing(2),
    },
    expandIcon: {
      /**
       * Per Jason - it is just too close to the right edge
       */
      right: theme.spacing(2),
    },
  },
  /**
   * More style overrides here:
   * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Input/Input.js
   */
  MuiFormControl: {
    root: {
      minHeight: 70,
    },
  },
  /**
   * More style overrides here:
   * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TableCell/TableCell.js
   */
  MuiTableCell: {
    root: {
      padding: '4px 12px 2px 8px',
    },
    head: {
      fontSize: 13, // default: theme.typography.pxToRem(12),
    },
    body: {
      fontSize: 14, // default: theme.typography.pxToRem(13),
    },
  },
  MuiTableRow: {
    head: {
      height: theme.spacing(5) + theme.spacing(2),
    },
    root: {
      height: theme.spacing(5) + theme.spacing(1),
      '&$selected, &$selected:hover': {
        backgroundColor: alpha(
          'rgba(243, 0, 255, 0.08)',
          theme.palette.action.selectedOpacity
        ),
      },
    },
    // head: {
    //   height: defaultTheme.spacing.unit * 5,
    //   borderBottom: '1px solid #000',
    // },
  },
  MuiFormLabel: {
    root: {
      '&$error': {
        color: palette.primary.dark,
      },
    },
  },
  MuiInput: {
    underline: {
      '&$error:after': {
        backgroundColor: palette.primary.dark,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 12,
    },
  },
  /**
   * More style overrides here:
   * https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/List/ListItemText.js
   */
  MuiPopover: {
    paper: {
      maxHeight: '100vh',
    },
  },
  MuiSnackbarContent: {
    message: {
      fontSize: theme.typography.pxToRem(16), // default: theme.typography.pxToRem(14),
    },
  },
};

export const themeV4 = createThemeV4({
  overrides,
  palette,
  typography,
});

export const themeV5 = createThemeV5({
  overrides,
  palette,
  typography,
});
