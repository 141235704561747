import delay from '@redux-saga/delay-p';
import { ADD } from 'reducers/metrics';
import { buffers } from 'redux-saga';
import { call, flush, actionChannel } from 'redux-saga/effects';
import { client } from '../util/sdk';

const DELAY = parseInt(process.env.REACT_APP_METRICS_UPLOAD_DELAY, 10) || 2000;

// Enabled by default
const metricsEnabled = () =>
  process.env.REACT_APP_METRICS_ENABLED.toLowerCase() === 'true';

const createEnabledMetricsSaga = ({
  client,
  createDelay = () => delay(DELAY),
}) =>
  function* named() {
    const channel = yield actionChannel(ADD.ACTION, buffers.expanding());

    while (true) {
      yield createDelay();

      const metrics = yield flush(channel);

      if (metrics.length > 0) {
        try {
          const metricsData = metrics.map(({ data }) => data);
          yield call(client.metrics.create, metricsData);
        } catch (e) {
          // Metrics couldn't be uploaded, discard them.
        }
      }
    }
  };

export const createMetricsSagaBase = (args) =>
  metricsEnabled()
    ? createEnabledMetricsSaga(args)
    : function* named() {
        yield [];
      };

export default createMetricsSagaBase({ client });
