import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { TRANSACTION_TYPE_PAL } from 'consts';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { Skeleton } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { payALoanOnSelector } from 'selectors/userFspConfig';
import { load } from 'model/autopayPal';
import { useEffect } from 'react';
import AutopayPal from '../AutopayPal/AutopayPal';
import { AUTOPAY } from '../AutopayPal';

const DetailsRenderPalAutoPay = ({
  autopay,
  autopayPal,
  item,
  palStatusDerived,
}) => {
  const dispatch = useDispatch();
  const { autopayRuleAt, fspId, payerId } = item;
  const isPayALoanOn = useSelector((state) =>
    payALoanOnSelector(state, fspId, payerId)
  );

  useEffect(() => {
    if (autopayRuleAt && !palStatusDerived) {
      dispatch(load({ fspId, payerId }));
    }
  }, [autopayRuleAt, dispatch, fspId, payerId, palStatusDerived]);

  return isPayALoanOn ? (
    <Grid item xs={3}>
      {autopayRuleAt ? (
        <ReadOnlyFormControl
          inputNode={
            palStatusDerived ? (
              <ReadOnlyInputWithMenu
                label={palStatusDerived}
                menuNode={
                  <AutopayPal
                    transactionType={TRANSACTION_TYPE_PAL}
                    {...autopay}
                    {...autopayPal}
                  />
                }
                tooltip={`${AUTOPAY} Details`}
              />
            ) : (
              <Skeleton />
            )
          }
          label={AUTOPAY}
          value={palStatusDerived || <Skeleton />}
        />
      ) : (
        <ReadOnlyFormControl label={AUTOPAY} value="Not Enabled" />
      )}
    </Grid>
  ) : null;
};

DetailsRenderPalAutoPay.propTypes = {
  autopay: PropTypes.object,
  autopayPal: PropTypes.object,
  item: PropTypes.object.isRequired,
  palStatusDerived: PropTypes.string,
};

DetailsRenderPalAutoPay.defaultProps = {
  autopay: null,
  autopayPal: null,
  palStatusDerived: null,
};

export default DetailsRenderPalAutoPay;
