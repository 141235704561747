import PropTypes from 'prop-types';
import Tabs from 'components/common/Tabs';
import { Route, Switch, Redirect } from 'react-router-dom';
import { always, head } from 'ramda';

const ModuleRoutes = ({ modules, onChange, onRemove, onUpsert }) => {
  const defaultPath = head(modules) ? head(modules).path : '/';

  return (
    <>
      <Tabs
        items={modules}
        onChange={onChange}
        onRemove={onRemove}
        onUpsert={onUpsert}
      />
      <Switch>
        {modules.map(({ key, ...props }) => (
          <Route key={key} {...props} />
        ))}
        <Redirect to={defaultPath} />
      </Switch>
    </>
  );
};

ModuleRoutes.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onUpsert: PropTypes.func,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        .isRequired,
    })
  ).isRequired,
};

ModuleRoutes.defaultProps = {
  onChange: always,
  onRemove: always,
  onUpsert: always,
};

export default ModuleRoutes;
