import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogTitle,
} from '@material-ui/core';
import { GENERATE, clearGenerated, generate } from 'model/oneTimePasscode';
import { LOAD as LOAD_EVENTS } from 'model/oneTimePasscodeEvent';
import { LOAD as LOAD_INFO } from 'model/oneTimePasscodeInfo';
import { makeStyles } from '@material-ui/core/styles';
import { selectUserFspConfigByFspIdPayerId } from 'selectors/userFspConfig';
import { selector as busySelector } from 'selectors/busy';
import { selectorGeneratedByPayerId as selector } from 'selectors/oneTimePasscode';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from './DialogActions';
import DialogContent from './DialogContent';

const useStyles = makeStyles(() => ({
  circularProgress: {
    left: 'calc(50% - 25px)',
    position: 'absolute',
    top: 'calc(50% - 25px)',
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
}));

const Dialog = ({ fspId, payerId, userId, ...props }) => {
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(true);
  const [otpEvent, setOtpEvent] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isGenerateBusy = useSelector((state) => busySelector(state, GENERATE));
  const isLoadEventsBusy = useSelector((state) =>
    busySelector(state, LOAD_EVENTS)
  );
  const isLoadInfoBusy = useSelector((state) => busySelector(state, LOAD_INFO));
  const passcode = useSelector((state) => selector(state, payerId));
  const config = useSelector((state) =>
    selectUserFspConfigByFspIdPayerId(state, fspId, payerId)
  );

  const handleClose = () => {
    setOpen(false);
  };
  const handleGenerate = () => {
    setDisabled(true);
    dispatch(
      generate({
        data: { otpEvent },
        fspId,
        payerId,
        userId: userId ?? config.userId,
      })
    );
  };
  const handleReasonChange = (event) => {
    setDisabled(false);
    setOtpEvent(event.target.value);
  };

  useEffect(() => {
    dispatch(clearGenerated());
  }, [dispatch]);

  return (
    <MuiDialog {...props} fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Generate New OTP</DialogTitle>
      <CircularProgress
        className={classNames(classes.circularProgress, {
          [classes.visible]:
            isGenerateBusy || isLoadEventsBusy || isLoadInfoBusy,
        })}
        size={50}
      />
      <DialogContent
        fspId={fspId}
        onChange={handleReasonChange}
        payerId={payerId}
        userId={userId ?? config.userId}
      />
      <DialogActions
        disabled={disabled}
        onClose={handleClose}
        onGenerate={handleGenerate}
        passcode={passcode}
      />
    </MuiDialog>
  );
};

Dialog.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
  userId: PropTypes.number,
};

Dialog.defaultProps = {
  userId: null,
};

export default Dialog;
