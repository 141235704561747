import PropTypes from 'prop-types';
import { ON, OFF } from 'consts';

export const ParamSearchType = {
  Amount: 'amount',
  Boolean: 'boolean',
  Date: 'date',
  Number: 'number',
  NumberRange: 'numberRange',
  String: 'string',
  Option: 'option',
  MultiOption: 'multi-option',
  RadioWithOption: 'radio-with-option',
};

export const onOffDescription = {
  [ON]: { displayName: 'Yes', id: ON },
  [OFF]: { displayName: 'No', id: OFF },
};
export const booleanOptions = Object.values(onOffDescription);

export const SearchParamPropType = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  valueOptions: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      apiValue: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
    })
  ),
});
