import { createSelector } from 'reselect';
import { FAILURE, SUCCESS } from 'util/actions';

const getRequest = (state, action, id = 0) => {
  const requests = state.request[action.ROOT] ?? [];
  return requests.find((r) => r.id === id || r.result?.id === id) ?? {};
};

const getStatus = (state, action, id) => getRequest(state, action, id).status;

const getResult = (state, action, id = 0) =>
  getRequest(state, action, id).result;

const getError = (state, action, id = 0) => getRequest(state, action, id).error;

export const completed = (state, action = {}, id = 0) =>
  getStatus(state, action, id) === SUCCESS;

export const failed = (state = {}, action = {}, id = 0) =>
  getStatus(state, action, id) === FAILURE;

export const error = (state = {}, action = {}, id = 0) =>
  getError(state, action, id);

export const result = (state = {}, action = {}, id = 0) =>
  getResult(state, action, id);

export const selector = createSelector(
  completed,
  failed,
  result,
  (completed, failed, result) => [completed, failed, result]
);

export const selectorCompletedOrFailed = createSelector(
  completed,
  failed,
  (completed, failed) => [completed, failed]
);
