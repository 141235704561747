import ExpandablePanel from 'components/common/ExpandablePanel';
import PropTypes from 'prop-types';
import { Contacts } from '@material-ui/icons';
import { List } from '@material-ui/core';
import { load as loadDirect, LOAD as LOAD_DIRECT } from 'model/recipientDirect';
import { load as loadP2P, LOAD as LOAD_P2P } from 'model/recipient';
import { selector as contactsSelector } from 'selectors/recipient';
import { selector as contactsSelectorDirect } from 'selectors/recipientDirect';
import { DATA_KEY, DATA_KEY_DIRECT } from 'model/util/recipient';
import ContactsAccordion from './ContactsAccordion';
import ContactsList from './ContactsList';
import ToggleCurrentDeleted from '../ToggleCurrentDeleted';
import useToggleCurrentDeleted from '../useToggleCurrentDeleted';
import {
  settingType,
  settingTypeDirect,
  settingTypeP2P,
  title,
} from './consts';

export const HEIGHT_OFFSET = 68;
export const SCROLL_TOP_OFFSET = 28;

const ContactsDirect = ({ fspId, payerId }) => {
  const [showDeletedDirect, onHandleChangeDirect] = useToggleCurrentDeleted(
    settingTypeDirect,
    payerId
  );
  const [showDeletedP2P, onHandleChangeP2P] = useToggleCurrentDeleted(
    settingTypeP2P,
    payerId
  );

  return (
    <ExpandablePanel
      heightOffset={HEIGHT_OFFSET}
      icon={Contacts}
      id={settingType}
      scrollTopOffset={SCROLL_TOP_OFFSET}
      title={title}
    >
      <List>
        <ContactsAccordion
          empty
          endAdornment={
            <ToggleCurrentDeleted
              action={LOAD_P2P}
              onChange={onHandleChangeP2P}
              settingId={payerId}
              settingType={settingTypeP2P}
            />
          }
          title="P2P"
        >
          <ContactsList
            action={loadP2P}
            actionTypeObject={LOAD_P2P}
            fspId={fspId}
            payerId={payerId}
            renderOptionsType={DATA_KEY}
            selector={contactsSelector}
            settingType={settingType}
            showDeleted={showDeletedP2P}
          />
        </ContactsAccordion>
        <ContactsAccordion
          empty
          endAdornment={
            <ToggleCurrentDeleted
              action={LOAD_DIRECT}
              onChange={onHandleChangeDirect}
              settingId={payerId}
              settingType={settingTypeDirect}
            />
          }
          title="Venmo Direct"
        >
          <ContactsList
            action={loadDirect}
            actionTypeObject={LOAD_DIRECT}
            fspId={fspId}
            payerId={payerId}
            renderOptionsType={DATA_KEY_DIRECT}
            selector={contactsSelectorDirect}
            settingType={settingTypeDirect}
            showDeleted={showDeletedDirect}
          />
        </ContactsAccordion>
      </List>
    </ExpandablePanel>
  );
};

ContactsDirect.propTypes = {
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default ContactsDirect;
