import { NA_VALUE } from 'consts';
import { isNil } from 'ramda';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const COUNTRY_CODE = 'US';
const NULL_STRING = 'null';

export const getPhoneForDisplay = (number) => {
  let displayPhone;

  try {
    const phoneNumber = parsePhoneNumberFromString(number, COUNTRY_CODE);
    displayPhone = phoneNumber ? phoneNumber.formatNational() : number;
  } catch (e) {
    displayPhone = number;
  }

  if (isNil(displayPhone)) {
    displayPhone = NA_VALUE;
  } else if (typeof displayPhone === 'string') {
    displayPhone = displayPhone.trim();

    if (
      displayPhone.length === 0 ||
      displayPhone.toLowerCase() === NULL_STRING
    ) {
      displayPhone = NA_VALUE;
    }
  }

  return displayPhone;
};

export const isPhoneNumberValid = (number) => {
  let isValid;

  try {
    const phoneNumber = parsePhoneNumberFromString(number, COUNTRY_CODE);
    isValid = phoneNumber.isValid();
  } catch (e) {
    isValid = false;
  }

  return isValid;
};
