import PropTypes from 'prop-types';
import { Features } from 'components/common';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { StatusGridNoContainer } from 'components/common/StatusGrid';
import StatusSelector from './StatusSelector';
import { isTrustlyProvider } from './consts';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const features = (account) => [
  {
    checked: true,
    key: `${account.id}ei1`,
    label: 'Transfer From',
  },
  {
    checked: true,
    key: `${account.id}ei2`,
    label: 'Transfer To',
  },
];

const DetailsRender = ({ account, options }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            inputNode={
              <ReadOnlyInputWithMenu
                label={
                  account.ddaTypeDerived
                    ? account.ddaTypeDerived
                    : account.externalDdaType
                }
                menuNode={
                  <Features
                    disabled
                    features={features(account)}
                    title="Account Features"
                  />
                }
              />
            }
            label="External DDA Type"
            value={account.ddaTypeDerived}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="External FI Name"
            value={account.externalFiName}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl label="Nickname" value={account.nickName} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="External FSP DDA ID"
            value={account.externalDdaIdStrDerived}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="External Ontrac DDA ID"
            value={account.id}
          />
        </Grid>
        <Grid item xs={4}>
          <ReadOnlyFormControl
            label="External ACH RTN"
            value={account.achRtn}
          />
        </Grid>
      </Grid>
      {isTrustlyProvider(account.iavProvider) && (
        <Grid container>
          <Grid item xs={4}>
            <ReadOnlyFormControl
              capitalize
              label="IAV Provider"
              value={account.iavProvider}
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyFormControl
              label="External Customer ID"
              value={account.iavAccessToken}
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyFormControl
              label="External Transaction ID"
              value={account.iavAccountId}
            />
          </Grid>
        </Grid>
      )}
      <Grid container>
        <StatusGridNoContainer
          sizes={[4, 4, 4]}
          status={account.externalDdaStatus}
          statusNode={<StatusSelector account={account} options={options} />}
          statusText={account.externalDdaStatusText}
          statusUpdatedAt={account.externalDdaStatusAtLabel}
        />
      </Grid>
    </Grid>
  );
};

DetailsRender.propTypes = {
  account: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default DetailsRender;
