import PropTypes from 'prop-types';
import { createContext, useContext, useState, useMemo } from 'react';

const Context = createContext(undefined);

const PayerUserButtonProvider = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const value = useMemo(
    () => ({
      anchorEl,
      disabled,
      setAnchorEl,
      setDisabled,
    }),
    [anchorEl, disabled]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

PayerUserButtonProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const usePayerUserButton = () => {
  return useContext(Context) || {};
};

export { PayerUserButtonProvider, usePayerUserButton };
