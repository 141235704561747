import { ON } from 'consts';
import { client, formatQueryParams } from 'util/sdk';
import { getUpdatedStatus } from './index';

export const load = ({ fspId, ...params }) =>
  client.fsps(fspId).ddas.query(formatQueryParams(params));

export const loadById = ({ fspId, id }) => client.fsps(fspId).ddas(id).get();

export const update = ({ account, options, status }, auth) =>
  client
    .fsps(options.fspId)
    .ddas(account.id)
    .updateStatus(getUpdatedStatus(status, auth), {
      adminUserId: auth.id,
    });

export const updateFspDdaId = ({ payload, options }, auth) =>
  client.fsps(options.fspId).updateFspIdStr(payload, {
    adminUserId: auth.id,
  });

export const unmask = ({ id, fspId }, deletedOnly = undefined) =>
  client
    .fsps(fspId)
    .ddas(id, deletedOnly === ON ? { deletedOnly: ON } : undefined)
    .decryptAccountNumber();

export const unmaskAchNumber = ({ id, fspId }, deletedOnly = undefined) =>
  client
    .fsps(fspId)
    .ddas(id, deletedOnly === ON ? { deletedOnly: ON } : undefined)
    .decryptAccountAchNumber();

export const unmaskMicrNumber = ({ id, fspId }, deletedOnly = undefined) =>
  client
    .fsps(fspId)
    .ddas(id, deletedOnly === ON ? { deletedOnly: ON } : undefined)
    .decryptAccountMicrNumber();

export const unmaskFspDdaId = ({ id, fspId }, deletedOnly = undefined) =>
  client
    .fsps(fspId)
    .ddas(id, deletedOnly === ON ? { deletedOnly: ON } : undefined)
    .decryptFspDdaId();
