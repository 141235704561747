import { forwardRef } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';

const IconButtonWithTooltip = (props, ref) => {
  // Fixes warning "forwardRef render functions do not support propTypes or defaultProps..."
  // eslint-disable-next-line react/prop-types
  const { disabled, Icon, onClick, testId, tip } = props;

  return (
    <Tooltip title={tip}>
      <span>
        <IconButton
          ref={ref}
          aria-label={tip}
          data-testid={testId}
          disabled={disabled}
          onClick={onClick}
        >
          <Icon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default forwardRef(IconButtonWithTooltip);
