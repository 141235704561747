import { attr, Model } from 'redux-orm';
import { createAction, createLoadConst } from '../util';

export default class SSOErrorLog extends Model {
  static get modelName() {
    return 'SSOErrorLog';
  }

  static reducer({ type, data }, SSOErrorLog) {
    switch (type) {
      case LOAD.SUCCESS:
        SSOErrorLog.delete();
        if (data.response) {
          data.response.forEach((r) => SSOErrorLog.upsert(r));
        }
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      createdAt: attr(),
      fspId: attr(),
      insUserId: attr(),
      offset: attr(),
      payerId: attr(),
      requestBody: attr(),
      requestMethod: attr(),
      requestUri: attr(),
      response: attr(),
      time: attr(), // derived
      title: attr(), // derived
      updUserId: attr(),
      updatedAt: attr(),
      userId: attr(),
      value: attr(),
      zhttpqId: attr(),
    };
  }
}

export const LOAD = createLoadConst(SSOErrorLog.modelName);
export const load = createAction(LOAD);
