import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReadOnlyFormControl } from 'components/common/fields';
import { makeStyles } from '@material-ui/core/styles';
import { unmask } from 'sdk/externalAccount';
import { derivedSelectorId } from 'selectors/externalAccount';
import { loadById } from 'model/externalAccount';
import { Mask, Skeleton } from 'components/common';
import { AUTOPAY } from '../../Autopay';
import { AUTOPAY_RULE_STATUS } from '../../../../../../consts';

const useStyles = makeStyles((theme) => ({
  button: {
    float: 'right',
  },
  grid: {
    marginTop: theme.spacing(1),
  },
}));

const CreditDetail = ({ autopayScheduled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { externalDdaId, fspId } = autopayScheduled;

  const { accountNbrMask, ddaType } =
    useSelector((state) => derivedSelectorId(state, externalDdaId)) || {};

  useEffect(() => {
    if (externalDdaId && !accountNbrMask) {
      dispatch(loadById({ fspId, id: externalDdaId }));
    }
  }, [accountNbrMask, dispatch, externalDdaId, fspId]);

  return (
    <Grid className={classes.grid} container>
      <Grid container>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Statement Period"
            value={autopayScheduled.statementPeriodDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Due Date"
            value={autopayScheduled.dueOnDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Due Amount"
            value={autopayScheduled.amountDueDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Due Min Amount"
            value={autopayScheduled.minimumAmountDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label={`${AUTOPAY} Status`}
            value={autopayScheduled.autopayStatusDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Suspended Until"
            value={autopayScheduled.suspendUntilOnDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Pay Amount Option"
            value={autopayScheduled.payAmountDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Pay Date Option"
            value={autopayScheduled.payWhenDerived}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            inputNode={
              accountNbrMask ? (
                <Mask
                  api={() => unmask({ fspId, id: externalDdaId })}
                  prefix={ddaType}
                  value={accountNbrMask}
                />
              ) : (
                <Skeleton variant="text" width="50%" />
              )
            }
            label="Pay From Account"
            value={accountNbrMask || <Skeleton />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label="Schedule Ends On"
            value={autopayScheduled.scheduleEndOnDerived}
          />
        </Grid>
        {autopayScheduled.autopayStatus === 'created' && (
          <>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Schedule Id"
                value={autopayScheduled.schedId}
              />
            </Grid>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Schedule Amount"
                value={autopayScheduled.scheduleAmountDerived}
              />
            </Grid>
            <Grid item xs={3}>
              <ReadOnlyFormControl
                label="Schedule Date"
                value={autopayScheduled.scheduleCreatedAtDerived}
              />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <ReadOnlyFormControl
            label={`${AUTOPAY_RULE_STATUS}`}
            value={autopayScheduled.autopayRuleStatusDerived}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CreditDetail.propTypes = {
  autopayScheduled: PropTypes.object.isRequired,
};

export default CreditDetail;
