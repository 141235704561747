import { client, formatQueryParams } from 'util/sdk';
import { ON } from 'consts';
import { GET } from 'util/api';

const returnModel = { name: 'schedtran' };
const schedTransURL = (fspId) => `/fsps/${fspId}/schedtrans`;

const formatQueryOperations = {
  offset: (value) => ({ offset: value }),
  limit: (value) => ({ limit: value }),
  orderByTransactionType: (value) => ({ order: `transactiontype:${value}` }),
  orderBySendOn: (value) => ({ order: `sendon:${value}` }),
  orderByAmount: (value) => ({ order: `amount:${value}` }),
  orderByDeliverOn: (value) => ({ order: `deliveron:${value}` }),
  sendOnRange: (props) => {
    const [value1, value2] = props;
    return {
      'startwith(sendon)': value1,
      'endwith(sendon)': value2,
    };
  },
  deletedOnly: (value) => ({ deletedOnly: value }),
  manualDeletedOnly: (value) => ({ manualDeletedOnly: value }),
  suspendedp2p: (value) => ({ suspendedP2p: value }),
  expand: (value) => ({ expand: value }),
  confirmNumber: (value) => ({ confirmnbr: value }),
  status: (value) => ({ status: value }),
  transactionType: (value) => ({ transactionType: value }),
  destinationLike: (value) => ({ 'like(destination)': value }),
  fundingmask: (value) => ({ fundingMask: value }),
  destinationMask: (value) => ({ destinationmask: value }),
  amount: (value) => ({ amount: value }),
  loanNameLike: (value) => ({ 'like(loanname)': value }),
  loanNumber: (value) => ({ loannumber: value }),
  autopayOffOn: (value) => ({ autopayoffon: value }),
};

const getQueryParams = (params) => {
  return params.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const load = async ({ fspId, payerId, ...params }) => {
  if (params.sendOnRange && params.sendOnRange[0] === '') {
    params.sendOnRange[0] = undefined;
  }

  let result;
  if (params.deletedOnly !== undefined && params.deletedOnly === ON) {
    params.manualDeletedOnly = ON;
  }
  const formatQueryArray = formatQueryParams(params);
  try {
    result = await client.executeRequest(GET, schedTransURL(fspId), {
      queryParams: {
        ...getQueryParams(formatQueryArray(formatQueryOperations)),
        payerid: payerId,
      },
      returnModel,
    });
  } catch (error) {
    return [];
  }
  return result;
};

export const decryptDestination = async (transaction) =>
  client
    .fsps(transaction.fspId)
    .scheduledTransactions(transaction.id)
    .decryptDestinationAccountNumber(transaction);

export const decryptFunding = async (transaction) =>
  client
    .fsps(transaction.fspId)
    .scheduledTransactions(transaction.id)
    .decryptFundingAccountNumber(transaction);
