import { attr, fk, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

export const sortableFields = [
  {
    name: 'Updated At',
    value: 'updatedAt',
    query: ['orderByUpdatedAt', 'desc'],
  },
  {
    name: 'Created At',
    value: 'createdAt',
    query: ['orderByCreatedAt', 'desc'],
  },
];

export default class ServiceNote extends Model {
  static get modelName() {
    return 'ServiceNote';
  }

  static reducer({ type, data }, ServiceNote, { PayerUser }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (PayerUser.idExists(data.props.payerId)) {
          PayerUser.withId(data.props.payerId).serviceNotes.delete();
        }
        if (data.response) {
          data.response.forEach((r) => {
            r.createdAtDerived = dateAndTime(r.createdAt);

            ServiceNote.upsert(r);
          });
        }
        break;

      case CREATE.SUCCESS:
        ServiceNote.create(data.response);
        break;

      case UPDATE.SUCCESS:
        ServiceNote.withId(data.response.id).update(data.response);
        break;

      case REMOVE.SUCCESS:
        ServiceNote.withId(data.response.id).delete();
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      createdAtDerived: attr(), // derived
      fsp: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insAdminUserLogin: attr(),
      insAdminUserName: attr(),
      insUserId: attr(),
      insUserName: attr(),
      note: attr(),
      noteType: attr(),
      payer: attr(),
      payerId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const CREATE = createAddConst(ServiceNote.modelName);
export const LOAD = createRequestConst(ServiceNote.modelName);
export const REMOVE = createDeleteConst(ServiceNote.modelName);
export const UPDATE = createUpdateConst(ServiceNote.modelName);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
