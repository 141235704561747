import * as Yup from 'yup';
import { omit } from 'ramda';
import {
  FRAUD_MONITOR_CODE,
  DELIVER_ELEC_NOW,
  PERSON_TO_PERSON,
  REQUIRED,
} from 'consts';
import { FRAUD_MONITOR_CLIENT_FACING_CODE } from '../../../common/consts';

export const MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW = 'onholdMinutesMax';
export const MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW =
  'onholdNonRealTimeMinutesMax';

export const MANUAL_REVIEW_DURATIONS_CODE = `${FRAUD_MONITOR_CLIENT_FACING_CODE}durations`;
export const MANUAL_REVIEW_DURATIONS_LABEL = 'Manual Review Durations';

export const Fields = {
  MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW: {
    name: MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW,
    label: 'Max Minutes to Review Real-Time Transactions',
    inputProps: {
      max: 9999,
      min: 0,
    },
    required: true,
    TooltipProps: {
      title:
        'Maximum number of minutes a real-time transaction will kept be on-hold for review',
    },
  },
  MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW: {
    name: MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW,
    label: 'Max Minutes to Review Non Real-Time Transactions',
    inputProps: {
      max: 9999,
      min: 0,
    },
    required: true,
    TooltipProps: {
      title:
        'Maximum number of minutes a batch transaction will be kept on-hold for review',
    },
  },
};

export const getFraudOverrideValuesForApi = (values) => {
  return {
    ...omit([MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW], values),
    transactionType: PERSON_TO_PERSON,
    deliver: DELIVER_ELEC_NOW,
  };
};

export const getFraudConfigValuesForApi = (values) => {
  return {
    [FRAUD_MONITOR_CODE]: {
      [MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW]:
        values[MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW],
    },
  };
};

export const validationSchema = Yup.object().shape({
  [MAX_MINUTES_REAL_TIME_ON_HOLD_REVIEW]: Yup.number()
    .required(REQUIRED)
    .min('30', 'Minimum value is 30')
    .max('1440', 'Maximum value is 1440')
    .typeError('A value is required'),
  [MAX_MINUTES_NON_REAL_TIME_TRANSACTION_ON_HOLD_REVIEW]: Yup.number()
    .required(REQUIRED)
    .min('1440', 'Minimum value is 1440')
    .max('9999', 'Maximum value is 9999')
    .typeError('A value is required'),
});
