import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useExpansionListItemColumn } from './ExpansionListItemColumnContext';

const ExpansionListItemExpanded = ({ context, label, visible }) => {
  const { classes, detail, rendererData } = useExpansionListItemColumn();

  const labelDisplay = label ? `${label}:` : null;

  return context && visible ? (
    <Grid item>
      <Grid alignItems="center" container>
        <Grid item>
          <Typography className={classes.gridLabel} variant="caption">
            {labelDisplay}
          </Typography>
        </Grid>
        <Grid item xs>
          {rendererData || <span className={classes.label}>{detail}</span>}
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

ExpansionListItemExpanded.propTypes = {
  context: PropTypes.bool,
  label: PropTypes.string,
  visible: PropTypes.bool,
};

ExpansionListItemExpanded.defaultProps = {
  context: false,
  label: null,
  visible: false,
};

export default ExpansionListItemExpanded;
