import { callAction, ORM } from 'util/index';
import { ADD, LOAD, LOAD_ROUTING_NUMBER, REMOVE, UPDATE } from 'model/rtnInfo';
import { create, load, loadRoutingNumber, remove, update } from 'sdk/rtnInfo';
import { takeEvery } from 'redux-saga/effects';

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      type: ADD,
      api: create,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `${
          data?.response?.name ?? ''
        } Routing Number has been successfully created.`,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    LOAD_ROUTING_NUMBER.ACTION,
    callAction({
      api: loadRoutingNumber,
      dataName: ORM,
      type: LOAD_ROUTING_NUMBER,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      type: UPDATE,
      api: update,
      dataName: ORM,
      formatSuccessMessage: (data) =>
        `${
          data?.response?.name ?? ''
        } Routing Number has been successfully updated`,
    })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      type: REMOVE,
      api: remove,
      formatSuccessMessage: () =>
        'One or more Routing Numbers have been successfully removed',
    })
  );
}
