import PropTypes from 'prop-types';
import SearchFieldType from 'types/search_by';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { hasNoValue, initialize } from 'components/common/searchByUtils';

const useStyles = makeStyles(() => ({
  button: {
    whiteSpace: 'nowrap',
  },
}));

const SearchByButtons = ({
  fields,
  onSearch,
  priorityIndex,
  searchBys,
  updateSearchBys,
}) => {
  const classes = useStyles();

  const disabled = hasNoValue(searchBys);

  const handleClearAll = () => {
    const { updatedValue } = initialize(priorityIndex, fields, []);

    updateSearchBys(updatedValue);
    onSearch(updatedValue);
  };
  const handleSearch = () => {
    if (!hasNoValue(searchBys)) {
      onSearch(searchBys);
    }
  };

  return (
    <Grid container justifyContent="center" spacing={1} wrap="nowrap">
      <Grid item>
        <Button
          color="primary"
          disabled={disabled}
          onClick={handleSearch}
          t-i="searchByContinue"
          variant="contained"
        >
          search
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          onClick={handleClearAll}
          t-i="searchByClearAll"
        >
          Clear All
        </Button>
      </Grid>
    </Grid>
  );
};

SearchByButtons.propTypes = {
  fields: PropTypes.arrayOf(SearchFieldType).isRequired,
  onSearch: PropTypes.func.isRequired,
  priorityIndex: PropTypes.number.isRequired,
  searchBys: PropTypes.array.isRequired,
  updateSearchBys: PropTypes.func.isRequired,
};

export default SearchByButtons;
