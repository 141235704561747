import {
  createRequestConst,
  createAction,
  addOrUpdateSuccessful,
} from 'util/actions';

const OPEN_DIALOG = 'openDialog';
const CLOSE_DIALOG = 'closeDialog';

export const OPEN = createRequestConst(OPEN_DIALOG);
export const CLOSE = createRequestConst(CLOSE_DIALOG);

export const openDialog = createAction(OPEN);
export const closeDialog = createAction(CLOSE);

const initialState = { name: '' };

export default (state = initialState, { type, data }) => {
  switch (type) {
    case OPEN.ACTION:
      return data;
    case CLOSE.ACTION:
      return initialState;
    default:
      if (addOrUpdateSuccessful(type)) {
        return initialState;
      }
      return state;
  }
};
