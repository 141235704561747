import { executePrivFspsSelector, selectedFspIdSelector } from 'selectors/fsp';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NumberParam, useQueryParam } from 'use-query-params';

/**
 * useFSPId attempts to retrieve the "correct" fspId to use
 *
 * How does it determine the fspId to return?
 *
 * Does an fspId query parameter exist? i.e. "?fspId=2000"
 *   Yes
 *     Is it a valid fspId and one the user has "access" to use?
 *       Yes
 *         return that value
 *       No
 *         return the user selected fspId
 *   No
 *     return the user selected fspId
 *
 * @returns {boolean} Returns the fspId to use
 */
const useFSPIdFromQueryParams = () => {
  const [trustedId, setTrustedId] = useState();
  const [untrustedId] = useQueryParam('fspId', NumberParam);
  const allIds = useSelector(executePrivFspsSelector);
  const selectedId = useSelector(selectedFspIdSelector);

  useEffect(() => {
    if (untrustedId) {
      const verifiedId = allIds.find(({ id }) => id === untrustedId)?.id;

      setTrustedId(verifiedId);
    }
  }, [allIds, untrustedId]);

  return trustedId || selectedId;
};

export default useFSPIdFromQueryParams;
