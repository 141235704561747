import { client } from 'util/sdk';
import { FSPS } from 'consts';
import { GET } from 'util/api';

const SCHED_TRAN_FRAUD = 'schedtranfraud';
const returnModel = { name: SCHED_TRAN_FRAUD };

const loadOrUpdateURL = ({ fspId, id }) =>
  `/${FSPS}/${fspId}/${SCHED_TRAN_FRAUD}s/${id}`;

// eslint-disable-next-line import/prefer-default-export
export const loadId = (props) =>
  client.executeRequest(GET, loadOrUpdateURL(props), { returnModel });
