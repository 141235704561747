import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { StatusSelectionAction } from 'components/common';
import { update, UPDATE } from 'model/payer';
import { useDispatch } from 'react-redux';
import { statusConfig, statusList } from '../consts';

const DetailsStatusSelector = ({
  currentStatus,
  fspId,
  payerId,
  payerLabel,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleOnChange = useCallback(
    (status) => {
      dispatch(update({ fspId, payerId, status }));
    },
    [dispatch, fspId, payerId]
  );

  return (
    <StatusSelectionAction
      actionType={UPDATE}
      confirmMessageExtras={`${payerLabel}?`}
      currentStatus={currentStatus}
      onChange={handleOnChange}
      statusConfig={statusConfig}
      statusList={statusList}
      {...props}
    />
  );
};

DetailsStatusSelector.propTypes = {
  currentStatus: PropTypes.string,
  fspId: PropTypes.number.isRequired,
  payerId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  payerLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DetailsStatusSelector.defaultProps = {
  currentStatus: '',
  payerId: null,
  payerLabel: null,
};

export default memo(DetailsStatusSelector);
