import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { ReadOnlyFormControl } from 'components/common/fields';
import { NOC, NOR } from '../consts';

const StepConfirmation = ({
  changeType,
  nocCode,
  returnedCode,
  accountType,
  accountNumber,
  routingNumber,
}) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h6">Please confirm the changes: </Typography>
      </Grid>
      {changeType === NOC ? (
        <Grid item>
          <Grid container>
            <Grid item>
              <ReadOnlyFormControl label="Change Type" value={changeType} />
            </Grid>
            {nocCode && (
              <Grid item>
                <ReadOnlyFormControl label="NOC Code" value={nocCode} />
              </Grid>
            )}
            {accountType && ['C05', 'C06', 'C07'].includes(nocCode) && (
              <Grid item>
                <ReadOnlyFormControl
                  capitalize
                  label="Account Type"
                  value={accountType}
                />
              </Grid>
            )}
            {accountNumber &&
              ['C01', 'C03', 'C06', 'C07'].includes(nocCode) && (
                <Grid item>
                  <ReadOnlyFormControl
                    label="Account Number"
                    value={accountNumber}
                  />
                </Grid>
              )}
            {routingNumber && ['C02', 'C03', 'C07'].includes(nocCode) && (
              <Grid item>
                <ReadOnlyFormControl
                  label="Routing Number"
                  value={routingNumber}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Grid container>
            <Grid item>
              <ReadOnlyFormControl label="Change Type" value={changeType} />
            </Grid>
            {returnedCode && changeType === NOR && (
              <Grid>
                <ReadOnlyFormControl
                  label="Returned Code"
                  value={returnedCode}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

StepConfirmation.propTypes = {
  accountType: PropTypes.string,
  accountNumber: PropTypes.string,
  changeType: PropTypes.string,
  nocCode: PropTypes.string,
  returnedCode: PropTypes.string,
  routingNumber: PropTypes.string,
};

StepConfirmation.defaultProps = {
  accountType: null,
  accountNumber: null,
  changeType: null,
  nocCode: null,
  returnedCode: null,
  routingNumber: null,
};

export default StepConfirmation;
