import { LinearProgressLoadingAndBusy } from 'components/common';
import { useContext } from 'react';
import { Drawer, makeStyles } from '@material-ui/core';
import AppBar from 'components/common/Audit/AuditDrawerAppBar';
import AuditContext from 'components/common/Audit/AuditContext';
import AuditDetails from 'components/common/Audit/AuditDetails';
import AuditPagination from 'components/common/Audit/AuditPagination';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 755,
  },
}));

const AuditDrawer = () => {
  const classes = useStyles();
  const context = useContext(AuditContext);

  const { actionBusyType, actionType, close, isOpen, item } = context;

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={close}
      open={isOpen}
      variant="temporary"
    >
      <AppBar />
      {item.id && (
        <div>
          <LinearProgressLoadingAndBusy
            busyType={actionBusyType}
            type={actionType}
          />
        </div>
      )}
      <AuditPagination />
      <AuditDetails />
    </Drawer>
  );
};

export default AuditDrawer;
