import {
  PAL_INTERNAL_ACCOUNT,
  PAL_EXTERNAL_ACCOUNT,
  PAL_DEBIT_CARD,
  PAL_FUNDING_TYPE_INTERNAL_ACCOUNT,
  PAL_FUNDING_TYPE_EXTERNAL_ACCOUNT,
  PAL_FUNDING_TYPE_DEBIT_CARD,
  PAL_DISBURSE_TRANS_TYPE_ACH,
  PAL_DISBURSE_TRANS_TYPE_CORE_DC,
  P2P_DISBURSE_TRANS_TYPE_RTP,
  P2P_DISBURSE_TRANS_TYPE_FEDNOW,
  PAL_ACH,
  PAL_CORE_DC,
  P2P_RTP,
  P2P_FEDNOW,
  DDA,
  P2P_DDA,
} from 'consts';
import { formatCurrency } from '../../util';

const BuildDerivedId = (fundingType) => {
  let derivedValue = '';

  switch (fundingType) {
    case PAL_FUNDING_TYPE_INTERNAL_ACCOUNT:
      derivedValue = PAL_INTERNAL_ACCOUNT;
      break;
    case PAL_FUNDING_TYPE_EXTERNAL_ACCOUNT:
      derivedValue = PAL_EXTERNAL_ACCOUNT;
      break;
    case PAL_FUNDING_TYPE_DEBIT_CARD:
      derivedValue = PAL_DEBIT_CARD;
      break;
    case DDA:
      derivedValue = P2P_DDA;
      break;
    default:
      break;
  }
  return derivedValue;
};

const BuilddisburseTransactionTypeIdentifierDerivedId = (
  disburseTransactionTypeIdentifier
) => {
  let derivedValue = '';

  switch (disburseTransactionTypeIdentifier) {
    case PAL_DISBURSE_TRANS_TYPE_ACH:
      derivedValue = PAL_ACH;
      break;
    case PAL_DISBURSE_TRANS_TYPE_CORE_DC:
      derivedValue = PAL_CORE_DC;
      break;
    case P2P_DISBURSE_TRANS_TYPE_RTP:
      derivedValue = P2P_RTP;
      break;
    case P2P_DISBURSE_TRANS_TYPE_FEDNOW:
      derivedValue = P2P_FEDNOW;
      break;
    default:
      break;
  }
  return derivedValue;
};

// eslint-disable-next-line import/prefer-default-export
export const addDerived = ({ ...item } = {}) => {
  item.feesAmountDerived = formatCurrency(item?.feesAmount);
  item.fundingTypeDerived = BuildDerivedId(item?.fundingType);
  item.disburseTransactionTypeIdentifierDerived =
    BuilddisburseTransactionTypeIdentifierDerivedId(
      item?.disburseTransactionTypeIdentifier
    );
  return {
    ...item,
  };
};
