import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import ExpansionPanelTitle from '../../ExpansionPanelTitle';
import EndAdornment from './EndAdornment';
import Progress from './Progress';

const IconButtonProps = { 'data-testid': 'toggle' };

const useStyles = makeStyles(() => ({
  root: {},
  summaryContent: {
    '& > :last-child': {
      paddingRight: 0,
    },
  },
  summaryRoot: {
    '&:hover': {
      cursor: 'default !important',
    },
  },
}));

const Summary = ({
  endAdornment,
  expandable,
  expanded,
  icon,
  id,
  scrollRef,
  showProgress,
  startAdornment,
  subTitle,
  title,
}) => {
  const classes = useStyles();
  const expandIcon = expandable ? <ExpandMore /> : null;

  const panelSummaryClasses = expandable
    ? { root: classes.root }
    : { content: classes.summaryContent, root: classes.summaryRoot };

  return (
    <AccordionSummary
      classes={panelSummaryClasses}
      expandIcon={expandIcon}
      IconButtonProps={IconButtonProps}
      id={id}
    >
      <Progress expanded={expanded} showProgress={showProgress} />
      <div ref={scrollRef} data-testid="ref" />
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={2}
        wrap="nowrap"
      >
        <Grid item>
          <ExpansionPanelTitle
            endAdornment={startAdornment}
            expanded={expanded}
            Icon={icon}
            subTitle={subTitle}
            title={title}
          />
        </Grid>
        <EndAdornment expanded={expanded} endAdornment={endAdornment} />
      </Grid>
    </AccordionSummary>
  );
};

Summary.propTypes = {
  expanded: PropTypes.bool.isRequired,
  endAdornment: PropTypes.object,
  expandable: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  id: PropTypes.string,
  scrollRef: PropTypes.object,
  showProgress: PropTypes.bool,
  startAdornment: PropTypes.object,
  subTitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

Summary.defaultProps = {
  endAdornment: null,
  expandable: true,
  icon: null,
  id: null,
  scrollRef: null,
  showProgress: false,
  startAdornment: null,
  subTitle: undefined,
};

export default memo(Summary);
