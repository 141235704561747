import { makeStyles } from '@material-ui/core/styles';
import useHasPermission from './useHasPermission';

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: `${theme.palette.text.primary} !important`, // This is the only way to override MuiInputBase-input
  },
}));

const useDisabledPropsByPermission = (actions = []) => {
  const classes = useStyles();
  const hasFullAccess = useHasPermission(actions);

  if (hasFullAccess) {
    return {
      disabled: false,
      classes: {},
    };
  }
  return {
    disabled: true,
    classes: {
      disabled: classes.disabled,
    },
  };
};

export default useDisabledPropsByPermission;
