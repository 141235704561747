import scrollIntoView from 'scroll-into-view';
import { DEFAULT_MODEL_REF, SHOW_CHILDREN_TIMEOUT } from 'consts';
import { selector } from 'selectors/scrollRef';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useScrollTo = (type) => {
  const { scrollRef } =
    useSelector((state) => selector(state, type)) ?? DEFAULT_MODEL_REF;

  return useCallback(() => {
    if (scrollRef?.current) {
      scrollIntoView(scrollRef?.current, {
        align: { top: 0, topOffset: 30 },
        time: SHOW_CHILDREN_TIMEOUT,
      });
    }
  }, [scrollRef]);
};

export default useScrollTo;
