import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { InputAdornment, TextField, withStyles } from '@material-ui/core';
import { decimal, formatCurrency } from 'util/format';
import { omit } from 'ramda';
import TextFieldCurrencyReadOnlyCents from './TextFieldCurrencyReadOnlyCents';

const styles = (theme) => ({
  inputEndAdornment: {
    marginRight: 0,
  },
  inputReadOnly: {
    color: theme.palette.action.active,
  },
  justifyRight: {
    color: theme.palette.action.active,
    direction: 'rtl',
  },
  root: {},
});

const omittedClasses = ['inputEndAdornment', 'inputReadOnly', 'justifyRight'];

const TextFieldCurrencyReadOnly = ({
  centsAsSuperscript,
  classes,
  InputProps,
  justifyRight,
  value,
  ...props
}) => {
  const [displayValue, setDisplayValue] = useState('');
  const [valueAsDecimal, setValueAsDecimal] = useState(decimal(value));

  const CustomInputProps = {
    classes: {
      input: justifyRight ? classes.justifyRight : classes.inputReadOnly,
    },
    disabled: true,
    disableUnderline: true,
    endAdornment:
      centsAsSuperscript && valueAsDecimal >= 1 ? (
        <InputAdornment
          classes={{ positionStart: classes.inputEndAdornment }}
          position="start"
        >
          <TextFieldCurrencyReadOnlyCents value={value} />
        </InputAdornment>
      ) : null,
  };

  useEffect(() => {
    const valueAsDecimal = decimal(value);
    let valueToUse = formatCurrency(value);

    if (centsAsSuperscript && valueAsDecimal >= 1) {
      valueToUse = valueToUse.split('.')[0];
    }

    setDisplayValue(valueToUse);
    setValueAsDecimal(valueAsDecimal);
  }, [centsAsSuperscript, value]);

  return (
    <TextField
      {...props}
      classes={omit(omittedClasses, classes)}
      InputProps={{ ...InputProps, ...CustomInputProps }}
      value={displayValue}
    />
  );
};

TextFieldCurrencyReadOnly.propTypes = {
  centsAsSuperscript: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
  justifyRight: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

TextFieldCurrencyReadOnly.defaultProps = {
  centsAsSuperscript: false,
  InputProps: { classes: {} },
  justifyRight: true,
};

export default withStyles(styles)(TextFieldCurrencyReadOnly);
