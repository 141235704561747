import Permission from 'model/permission';
import { attr, Model, many } from 'redux-orm';
import { mergeRight, pluck } from 'ramda';
import {
  createRequestConst,
  createAddConst,
  createDeleteConst,
  createUpdateConst,
  createAction,
} from '../util';

export default class Role extends Model {
  static get modelName() {
    return 'Role';
  }

  static reducer({ type, data }, Role) {
    switch (type) {
      case LOAD.SUCCESS:
        Role.delete();
        data.forEach((role) =>
          Role.upsert(mergeRight(role, { actions: pluck('id', role.actions) }))
        );
        break;
      case ADD.SUCCESS:
        Role.upsert(mergeRight(data, { actions: pluck('id', data.actions) }));
        break;
      case DELETE.SUCCESS:
        Role.withId(data.id).delete();
        break;
      case UPDATE.SUCCESS:
        Role.update(data);
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      actions: many(Permission.modelName, 'roles'),
      createdAt: attr(),
      description: attr(),
      href: attr(),
      id: attr(),
      name: attr(),
      offset: attr(),
      updatedAt: attr(),
    };
  }
}

export const FORM_NAME = 'setupRole';

export const LOAD = createRequestConst(Role.modelName);
export const ADD = createAddConst(Role.modelName);
export const DELETE = createDeleteConst(Role.modelName);
export const UPDATE = createUpdateConst(Role.modelName);

// Creating actions
export const loadRoles = createAction(LOAD);
export const addRole = createAction(ADD);
export const deleteRole = createAction(DELETE);
export const updateRole = createAction(UPDATE);
