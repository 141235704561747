import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createLoadConst,
  createUpdateConst,
  dateAndTime,
} from '../util';

const addDerived = (r) => {
  r.createdAtLabel = dateAndTime(r.createdAt);
  r.updatedAtLabel = dateAndTime(r.updatedAt);

  return r;
};

export default class FSP extends Model {
  static get modelName() {
    return 'FSP';
  }

  static reducer({ type, data }, FSP) {
    switch (type) {
      case LOAD.SUCCESS:
      case THROTTLED_LOAD.SUCCESS:
        FSP.delete();
        if (data.response) {
          data.response.forEach((r) => FSP.upsert(addDerived(r)));
        }
        break;

      case LOAD_ID.SUCCESS:
        FSP.upsert(addDerived(data.response));
        break;

      case CREATE.SUCCESS:
        FSP.create(addDerived(data.response));
        break;

      case UPDATE.SUCCESS:
        FSP.withId(data.response.id).update(addDerived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      channel: attr(),
      channelId: attr(),
      createdAt: attr(),
      createdAtLabel: attr(), // derived
      contactEmail: attr(),
      contactPhone: attr(),
      database: attr(),
      description: attr(),
      distributor: attr(),
      distributorId: attr(),
      erouteId: attr(),
      facebookUrl: attr(),
      fspStatus: attr(),
      href: attr(),
      id: attr(),
      instagramUrl: attr(),
      name: attr(),
      offset: attr(),
      routeId: attr(),
      shortName: attr(),
      twitterUrl: attr(),
      updatedAt: attr(),
      updatedAtLabel: attr(), // derived
      uspsAddress: attr(),
      websiteUrl: attr(),
    };
  }
}

export const CREATE = createAddConst(FSP.modelName);
export const LOAD = createLoadConst(FSP.modelName);
export const LOAD_ID = createLoadConst(`${FSP.modelName}_id`);
export const THROTTLED_LOAD = createLoadConst(`${FSP.modelName}_throttled`);
export const UPDATE = createUpdateConst(FSP.modelName);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const throttledLoad = createAction(THROTTLED_LOAD);
export const update = createAction(UPDATE);
