import { client, formatQueryParams } from 'util/sdk';

export const create = ({ channelId, ...data }, auth) =>
  client
    .channels(channelId)
    .distributors.create(data, { adminUserId: auth.id });

export const loadId = (id) => client.distributors(id).get();

export const load = (params) =>
  client.distributors.query(
    formatQueryParams({ expand: ['channel'], ...params })
  );

export const remove = ({ id }, auth) =>
  client.distributors(id).delete({ adminUserId: auth.id });

export const update = ({ description, id, name }, auth) =>
  client
    .distributors(id)
    .update({ name, description }, { adminUserId: auth.id });
