import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { load } from 'model/autopayAudit';
import { selectorCount, selectorGroupedByDays } from 'selectors/autopayAudit';
import Audit from 'components/common/Audit';
import AuditDetailRowRecord from 'components/common/Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from 'components/common/Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../Audit/config/autopay/AuditDrawerAppBarTitle';

const ActionsRenderer = ({ item }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Audit
          action={load}
          countSelector={selectorCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={item}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorGroupedByDays}
          selectorProps={{ autopayId: item.autopayId }}
          Title={AuditDrawerAppBarTitle}
        />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
