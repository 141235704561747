import PropTypes from 'prop-types';

const DistributorType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
});

export default DistributorType;
