import { findIndex, mergeDeepRight } from 'ramda';
import { createAction, createLoadConst } from 'util/actions';
import { UPLOAD_FSP_PROFILE } from 'model/export';
import {
  FSPRTN,
  FSP_ON_HOLD_RULES,
  FSP_PAYER_TYPE,
  FSP_PAYNETS_CODE,
  LIMIT_CODE,
  LIMIT_RULE_CODE,
  SSO_URI,
  USER_ROLE_CODE,
} from 'consts';

const ARRAY_SECTIONS = [
  FSPRTN,
  FSP_ON_HOLD_RULES,
  FSP_PAYER_TYPE,
  FSP_PAYNETS_CODE,
  LIMIT_CODE,
  LIMIT_RULE_CODE,
  SSO_URI,
  USER_ROLE_CODE,
];

export const initialState = {
  fsp: {},
  fspconfig: {},
  fspcoreconfig: {},
  fsponholdrules: [],
  fspotpevent: {},
  fsppayertype: [],
  fsppaynets: [],
  fsprtn: [],
  limit: [],
  limitrule: [],
  ssouri: [],
  userrole: [],
};
export const name = 'fspImport';
export const LOAD = createLoadConst(name);
export const load = createAction(LOAD);

export const selector = (state) => state[name];

export default (state = initialState, { data = {}, type }) => {
  switch (type) {
    case LOAD.ACTION: {
      const { key = 'id', section, values } = data;
      if (ARRAY_SECTIONS.includes(section)) {
        const existing = state[section] ?? [];
        const index = findIndex((item) => item[key] === values[key], existing);
        if (index > -1) {
          existing.splice(index, 1, values);
        } else {
          existing.push(values);
        }

        return { ...state, [section]: existing };
      }

      return { ...state, [section]: mergeDeepRight(state[section], values) };
    }

    case UPLOAD_FSP_PROFILE.ACTION: {
      const {
        fsp,
        fspconfig,
        fspcoreconfig,
        fsponholdrules = [],
        fspotpevent,
        fsppayertype = [],
        fsppaynets = [],
        fsprtn = [],
        limit = [],
        limitrule = [],
        ssouri = [],
        userrole = [],
      } = data.response ?? {};

      return {
        fsp,
        fspconfig,
        fspcoreconfig,
        fsponholdrules,
        fspotpevent,
        fsppayertype,
        fsppaynets,
        fsprtn,
        limit,
        limitrule,
        ssouri,
        userrole,
      };
    }

    default:
      return state;
  }
};
