import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { withMaskPhone } from 'components/common/fields/TextFieldPhone/TextFieldPhone';

const PhoneField = withMaskPhone(TextField);

const ContentPhone = () => {
  return (
    <Field
      autoComplete="off"
      component={PhoneField}
      disabled={false}
      fullWidth
      id="billerContactPhone"
      label="Contact Phone for Biller"
      name="billerContactPhone"
      required
    />
  );
};

export default ContentPhone;
