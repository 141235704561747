import PaymentResearchContainer from 'containers/PaymentResearchContainer';
import { PREFIX } from 'selectors/paymentResearch';
import { always } from 'ramda';
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  withProps,
  withState,
} from 'recompose';
import BaseLabel from './BaseLabel';

export default compose(
  setDisplayName('PaymentResearchLabel'),
  PaymentResearchContainer,
  withProps(({ trr, trrId }) => ({
    LabelValue: (
      <>
        #{`${PREFIX}${trrId}`} ({trr.status})
      </>
    ),
  })),
  withState('onChangeRaised', 'setOnChangeRaised', false),
  lifecycle({
    componentDidUpdate() {
      const { onChangeRaised, trr } = this.props;

      if (!onChangeRaised && trr.status !== undefined) {
        const { onChange = always, setOnChangeRaised } = this.props;

        setOnChangeRaised(true);

        // debounce
        setTimeout(onChange, 250);
      }
    },
  }),
  pure
)(BaseLabel);
