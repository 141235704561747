import { takeEvery } from 'redux-saga/effects';
import { create, load, remove, update } from 'sdk/researchNote';
import { CREATE, LOAD, REMOVE, UPDATE } from 'model/researchNote';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Note was added to Payment Research #${response.trrId}`,
      type: CREATE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({ api: load, dataName: ORM, type: LOAD })
  );

  yield takeEvery(
    REMOVE.ACTION,
    callAction({
      api: remove,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Note was removed from Payment Research #${response.trrId}`,
      type: REMOVE,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      formatSuccessMessage: ({ response }) =>
        `Note was updated on Payment Research #${response.trrId}`,
      type: UPDATE,
    })
  );
}
