import { client, formatQueryParams } from 'util/sdk';
import { GET, DELETE, POST, PUT } from 'util/api';

const returnModel = { name: 'frauddenylist' };

const denyListUri = 'frauddenylists';
const updateURL = (id) => `${denyListUri}${id ? `/${id}` : ''}`;
const queryParams = ({ id }) => ({ adminUserId: id });

const formatQueryOperations = {
  offset: (value) => ({ offset: value }),
  limit: (value) => ({ limit: value }),
  orderByEmail: (value) => ({ order: `email:${value}` }),
  orderByPhone: (value) => ({ order: `phone:${value}` }),
  orderByRouting: (value) => ({ order: `routing:${value}` }),
  orderByAccount: (value) => ({ order: `account:${value}` }),
  orderByBin: (value) => ({ order: `bin:${value}` }),
  orderByCreatedAtDerived: (value) => ({ order: `createdAt:${value}` }),
  orderByUpdatedAtDerived: (value) => ({ order: `updatedAt:${value}` }),
};

const getQueryParams = (params) => {
  return params.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const load = async ({ fspId, offeringName, ...params }) => {
  let result;
  const formatQueryArray = formatQueryParams(params);
  try {
    result = await client.executeRequest(GET, denyListUri, {
      queryParams: {
        fspId,
        offeringName,
        ...getQueryParams(formatQueryArray(formatQueryOperations)),
      },
      returnModel,
    });
  } catch (error) {
    // We're returning empty array here because the API returns a 404 error when there are no results
    // Behaving like the SDK does it
    return [];
  }
  return result;
};

export const add = (data, auth) =>
  client.executeRequest(POST, denyListUri, {
    data,
    queryParams: queryParams(auth),
    returnModel,
  });

export const remove = ({ id }, auth) =>
  client.executeRequest(DELETE, updateURL(id), {
    queryParams: queryParams(auth),
    returnModel,
  });

export const update = ({ id, ...data }, auth) =>
  client.executeRequest(POST, updateURL(id), {
    data,
    queryParams: queryParams(auth),
    returnModel,
  });

export const upload = async (data, auth) => {
  let result;
  try {
    result = await client.executeRequest(POST, denyListUri, {
      data,
      queryParams: queryParams(auth),
      returnModel,
    });
  } catch (error) {
    // We're returning empty array here because the API returns a 404 error when there are no results
    // Behaving like the SDK does it
    return [];
  }

  return [result];
};
