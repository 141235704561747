import { Avatar, makeStyles } from '@material-ui/core';

// Lighthouse report - Set an explicit width and height on image elements to reduce layout shifts and improve CLS
const imgProps = { height: 60, width: 60 };

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 60,
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
    textAlign: 'center',
    width: 60,
  },
}));

const AuthenticationHeaderAvatar = () => {
  const classes = useStyles();

  return (
    <Avatar
      alt="Ontrac logo"
      aria-label="Ontrac"
      className={classes.avatar}
      imgProps={imgProps}
      size={60}
      src="/pz.webp"
    />
  );
};

export default AuthenticationHeaderAvatar;
