import MaskDestination from 'components/modules/service/PayerDetail/Transactions/config/MaskDestination';
import { decryptDestination } from 'sdk/transactionHistory';

const renderer = (value, id, item) => (
  <MaskDestination
    decrypt={decryptDestination}
    icon={false}
    item={item?.histtran}
  />
);

// eslint-disable-next-line import/prefer-default-export
export const columnMeta = [
  {
    id: 'id',
    label: 'Ticket#',
    sortId: 'id',
  },
  {
    capitalize: true,
    id: 'status',
    label: 'Status',
    sortId: 'status',
  },
  {
    id: 'destination',
    label: 'Biller',
    renderer,
  },
  {
    id: 'sendOnLabel',
    label: 'Send On',
  },
  {
    id: 'amount',
    isCurrency: true,
    label: 'Amount',
    sortId: 'amount',
  },
];
