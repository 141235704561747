import { useEffect, useState } from 'react';
import useIsLoading from '../useIsLoading';

const defaultBack = { 'aria-label': 'Previous Page' };
const defaultNext = { 'aria-label': 'Next Page' };

const useIconButtonProps = (actionType, hasMoreItems, page) => {
  const [backIconButtonProps, setBackIconButtonProps] = useState(defaultBack);
  const [nextIconButtonProps, setNextIconButtonProps] = useState(defaultNext);
  const isLoading = useIsLoading(actionType);

  useEffect(() => {
    setBackIconButtonProps({
      ...defaultBack,
      disabled: isLoading || page === 0,
    });
    setNextIconButtonProps({
      ...defaultNext,
      disabled: isLoading || !hasMoreItems,
    });
  }, [hasMoreItems, isLoading, page]);

  return [backIconButtonProps, nextIconButtonProps, isLoading];
};

export default useIconButtonProps;
