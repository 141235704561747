import { createAction, ADD_MESSAGE, DELETE_MESSAGE } from 'util/actions';

const initialState = '';

export const addMessage = createAction(ADD_MESSAGE);
export const deleteMessage = createAction(DELETE_MESSAGE);

export const selector = ({ successMessage }) => successMessage;

export default (state = initialState, { type, data }) => {
  switch (type) {
    case ADD_MESSAGE.ACTION:
      return data;

    case DELETE_MESSAGE.ACTION:
      return initialState;

    default:
      return state;
  }
};
