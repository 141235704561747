import { attr, Model } from 'redux-orm';
import {
  addDerivedData,
  createAction,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class Payer extends Model {
  static get modelName() {
    return 'Payer';
  }

  static reducer({ type, data = {} }, Payer) {
    switch (type) {
      case DECRYPT.SUCCESS:
        Payer.upsert({
          id: data?.props?.payerId,
          fspPayerIdDerived: data.response,
        });
        break;

      case LOAD.SUCCESS:
      case THROTTLED_LOAD.SUCCESS:
        if (data.response) {
          data.response.forEach((r) => Payer.upsert(addDerivedData(r)));
        }
        break;

      case LOAD_ID.SUCCESS:
        Payer.upsert({ _last: false, ...addDerivedData(data.response) });
        break;

      case LOAD_ID.FAILURE: {
        Payer.upsert({
          _last: false,
          id: data.id,
          isEmpty: false,
        });
        break;
      }

      case MERGE.SUCCESS:
      case UPDATE.SUCCESS:
        Payer.upsert(addDerivedData(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      _last: attr(), // Derived
      createdAt: attr(),
      fsp: attr(),
      fspPayerIdMask: attr(),
      fspPayerIdDerived: attr(), // Derived
      href: attr(),
      id: attr(),
      internationalAddress: attr(),
      isEmpty: attr(), // Derived
      limitType: attr(),
      label: attr(), // Derived
      labelWithId: attr(), // Derived
      name: attr(), // Derived
      offset: attr(),
      organization: attr(),
      payerIcon: attr(), // Derived
      payerType: attr(),
      person: attr(),
      serviceStartOn: attr(),
      serviceStartOnLabel: attr(), // Derived
      status: attr(),
      statusAt: attr(),
      statusAtLabel: attr(), // Derived
      statusText: attr(),
      taxIdMask: attr(),
      updatedAt: attr(),
      uspsAddress: attr(),
      zfspId: attr(),

      /**
       * Relational entries you'll have access to, but that are not defined here
       *
       * externalAccounts       See ExternalAccount.Model payerId fk()
       * internalAccounts       See InternalAccount.Model payerId fk()
       * payees                 See Payee.Model payerId fk()
       * payerUsers             See PayerUser.Model payerId fk()
       * recipients             See Recipient.Model payerId fk()
       * scheduledTransactions  See ScheduledTransaction.Model payerId fk()
       * transactionHistory     See TransactionHistory.Model payerId fk()
       * users                  See User.Model payerId fk()
       * viewAs                 See ViewAs.Model payerId fk()
       */
    };
  }
}

export const DECRYPT = createRequestConst(Payer.modelName);
export const LOAD = createLoadConst(Payer.modelName);
export const LOAD_ID = createLoadConst(`${Payer.modelName}_id`);
export const MERGE = createUpdateConst(`${Payer.modelName}_merge`);
export const THROTTLED_LOAD = createLoadConst(`${Payer.modelName}_throttled`);
export const UPDATE = createUpdateConst(Payer.modelName);

export const decrypt = createAction(DECRYPT);
export const load = createAction(LOAD);
export const loadById = createAction(LOAD_ID);
export const merge = createAction(MERGE);
export const throttledLoad = createAction(THROTTLED_LOAD);
export const update = createAction(UPDATE);
