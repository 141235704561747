import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';
import {
  FSPS,
  PAYER_FRAUD_OVERRIDES,
  RESET_PAYER_FRAUD_OVERRIDES,
} from 'consts';
import { pick } from 'ramda';

const keys = ['maximumTransactionAmount', 'overrideExpiresAt'];
const returnModel = { name: 'overridelist' };

const addURL = (fspId, payerId) =>
  `/${FSPS}/${fspId}/payers/${payerId}/${PAYER_FRAUD_OVERRIDES}`;
const loadURL = (fspId) => `/${FSPS}/${fspId}/${PAYER_FRAUD_OVERRIDES}`;
const importURL = (fspId) => `/${FSPS}/${fspId}/${RESET_PAYER_FRAUD_OVERRIDES}`;
const queryParams = ({ id }) => ({ adminUserId: id });
const removeOrUpdateURL = (id, fspId) => {
  return `${loadURL(fspId)}/${id}`;
};

export const add = ({ fspId, payerId, ...data }, auth) => {
  return client.executeRequest(POST, addURL(fspId, payerId), {
    data,
    queryParams: queryParams(auth),
    returnModel,
  });
};

export const importAndReset = ({ fspId, fsppaynets }, auth) => {
  return client.executeRequest(POST, importURL(fspId), {
    data: { [PAYER_FRAUD_OVERRIDES]: fsppaynets.map((r) => pick(keys, r)) },
    queryParams: queryParams(auth),
    returnModel,
  });
};

export const load = ({ fspId, ...params }) => {
  return client.executeRequest(GET, loadURL(fspId), {
    queryParams: params,
    returnModel,
  });
};

export const remove = ({ id, fspId }, auth) => {
  return client.executeRequest(DELETE, removeOrUpdateURL(id, fspId), {
    queryParams: queryParams(auth),
    returnModel,
  });
};

export const update = ({ id, fspId, ...data }, auth) => {
  return client.executeRequest(POST, removeOrUpdateURL(id, fspId), {
    data: pick(keys, data),
    queryParams: queryParams(auth),
    returnModel,
  });
};
