import { omit } from 'ramda';
import {
  createAction,
  createActionSuccess,
  createActionFailure,
  createRequestConst,
} from 'util/actions';

const CLEAR = 'CLEAR';
export const clear = (action) => ({ type: CLEAR, data: action.ROOT });

const initialState = {};
const name = ''; // The only one allowed to be empty
export const REQUEST = createRequestConst(name);

export const requestBegin = createAction(REQUEST);
export const requestEnd = createActionSuccess(REQUEST);
export const requestFailure = createActionFailure(REQUEST);

export default (state = initialState, { type, data }) => {
  switch (type) {
    case REQUEST.ACTION:
    case REQUEST.FAILURE:
    case REQUEST.SUCCESS: {
      const { error, id, request, result, status } = data;
      const requests = state[request] || [];
      const newEntry = [
        {
          error,
          id,
          result,
          status,
        },
      ];

      state[request] = requests.filter((r) => r.id !== id).concat(newEntry);

      return { ...state };
    }

    case CLEAR:
      return omit([data], state);

    default:
      break;
  }

  return state;
};
