import { takeEvery } from 'redux-saga/effects';
import { load, refresh } from 'sdk/eBillEnrollment';
import { LOAD, REFRESH } from 'model/eBillEnrollment';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeEvery(
    REFRESH.ACTION,
    callAction({
      api: refresh,
      dataName: ORM,
      type: REFRESH,
      formatSuccessMessage: () =>
        'A statement refresh request was successfully submitted',
    })
  );
}
