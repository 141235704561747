import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst, createUpdateConst } from '../util';

export default class DeliveryCountByType extends Model {
  static get modelName() {
    return 'DeliveryCountByType';
  }

  static reducer({ type, data }, DeliveryCountByType) {
    switch (type) {
      case LOAD.SUCCESS:
        DeliveryCountByType.upsert({
          id: 0,
          count: data?.response,
        });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      paymentDeliveredCount: attr(),
    };
  }
}

const name = DeliveryCountByType.modelName;

export const LOAD = createRequestConst(name);

export const load = createAction(LOAD);
