import { isNil } from 'ramda';
import { useMemo } from 'react';
import { usePageSettings } from 'hooks';

const addDisabledToSearchParams = (pageSettings = {}, searchParams = []) => {
  return searchParams.map((searchBy) => {
    const matchingField = ({ field }) => field === searchBy?.field;
    const settingInUse = !isNil(pageSettings.searchBys?.find(matchingField));

    return { ...searchBy, disabled: settingInUse };
  });
};

const useMenuItems = (pageSettingsName, searchParams) => {
  const pageSettings = usePageSettings(pageSettingsName);

  return useMemo(() => {
    return addDisabledToSearchParams(pageSettings, searchParams);
  }, [pageSettings, searchParams]);
};

export default useMenuItems;
