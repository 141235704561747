import { attr, fk, Model } from 'redux-orm';
import Biller from 'model/biller';
import { createAction, createLoadConst, dateAndTime } from '../util';

export const derived = (billerContact) => {
  const [phone1Derived, phone2Derived, phone3Derived] =
    billerContact.phones || [];

  return {
    ...billerContact,
    createdAtDerived: dateAndTime(billerContact?.createdAt),
    updatedAtDerived: dateAndTime(billerContact?.updatedAt),
    zipDerived: (billerContact.zip || '').replace(/(\d{5})(\d{4})/, '$1-$2'),
    phone1Derived,
    phone2Derived,
    phone3Derived,
  };
};

export default class BillerContact extends Model {
  static get modelName() {
    return 'BillerContact';
  }

  static reducer({ type, data }, BillerContact, { Biller }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Biller.idExists(data?.props?.billerId)) {
          Biller.withId(data.props.billerId).billerContacts.delete();
        }
        (data?.response || []).forEach((billerContact) => {
          BillerContact.upsert(derived(billerContact));
        });
        break;

      case LOAD.FAILURE:
        BillerContact.delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      billerId: fk(Biller.modelName, 'billerContacts'),
      billerContactCount: attr(),
      city: attr(),
      createdAt: attr(),
      createdAtDerived: attr(),
      cstype: attr(),
      email: attr(),
      firstName: attr(),
      href: attr(),
      id: attr(),
      insBillernetId: attr(),
      lastName: attr(),
      netIdStr: attr(),
      offset: attr(),
      organization: attr(),
      phone1Derived: attr(),
      phone2Derived: attr(),
      phone3Derived: attr(),
      phones: attr(),
      state: attr(),
      street1: attr(),
      street2: attr(),
      title: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(),
      updBillernetId: attr(),
      zip: attr(),
      zipDerived: attr(),
    };
  }
}

const name = BillerContact.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
