import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { load } from 'model/payerUserAudit';
import { selectorUserIdFirst } from 'selectors/payerUser';
import {
  selectorUserIdCount,
  selectorUserIdGroupedByDays,
} from 'selectors/payerUserAudit';
import Audit from 'components/common/Audit';
import AuditDetailRowRecord from 'components/common/Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from 'components/common/Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../Audit/config/shared/AuditDrawerAppBarTitle';
import ViewAs from '../../Profile/ViewAs';

const ActionsRenderer = ({ item }) => {
  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      <Grid item>
        <ViewAs
          payerId={item.payerId}
          payerType={item.payerType || item.user?.payerType}
          payerUser={item}
        />
      </Grid>
      <Grid item>
        <Audit
          action={load}
          actionParams={{ userId: item?.userId }}
          countSelector={selectorUserIdCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={item}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorUserIdGroupedByDays}
          selectorId={selectorUserIdFirst}
          selectorProps={{ userId: item?.userId }}
          Title={AuditDrawerAppBarTitle}
        />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
