import Payer from 'model/payer';
import { attr, fk } from 'redux-orm';
import BaseModel from './baseModel';
import {
  createAction,
  createRequestConst,
  createUpdateConst,
  dateAndTime,
  fullName,
} from '../util';

export const derived = (user, payerId) => ({
  ...user,
  name: fullName(user),
  payerId,
  statusAtDerived: dateAndTime(user?.statusAt),
});

export default class User extends BaseModel {
  static get modelName() {
    return 'User';
  }

  static reducer({ type, data = { response: [] } }, User) {
    switch (type) {
      case LOAD_PAYER_ID.SUCCESS:
        data.response.forEach((r) =>
          User.upsert(derived(r, data.props.payerId))
        );
        break;

      case LOAD_ID.SUCCESS:
      case UPDATE.SUCCESS:
        User.upsert(derived(data.response));
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      email: attr(),
      firstName: attr(),
      fsp: attr(),
      fspUserIdMask: attr(),
      href: attr(),
      id: attr(),
      insUser: attr(),
      lastName: attr(),
      middleName: attr(),
      mobilePhone: attr(),
      name: attr(), // derived
      offset: attr(),
      payerId: fk(Payer.modelName, 'users'), // derived, payerId is added
      payerType: attr(),
      status: attr(),
      statusAt: attr(),
      statusText: attr(),
      updUser: attr(),
      updatedAt: attr(),
      zfspId: attr(),
    };
  }
}

export const LOAD_ID = createRequestConst(`${User.modelName}_id`);
export const LOAD_PAYER_ID = createRequestConst(`${User.modelName}_payerId`);
export const UPDATE = createUpdateConst(User.modelName);

export const loadById = createAction(LOAD_ID);
export const loadByPayerId = createAction(LOAD_PAYER_ID);
export const update = createAction(UPDATE);
