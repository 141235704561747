import { attr, fk, Model } from 'redux-orm';
import Biller from 'model/biller';
import { createAction, createLoadConst, dateAndTime } from '../util';

export const derived = (billerMask) => {
  return {
    ...billerMask,
    createdAtDerived: dateAndTime(billerMask?.createdAt),
    updatedAtDerived: dateAndTime(billerMask?.updatedAt),
  };
};

export default class BillerMask extends Model {
  static get modelName() {
    return 'BillerMask';
  }

  static reducer({ type, data }, BillerMask, { Biller }) {
    switch (type) {
      case LOAD.SUCCESS:
        if (Biller.withId(data?.props?.billerId)) {
          Biller.withId(data.props.billerId).billerMasks.delete();
        }
        (data?.response || []).forEach((billerMask) => {
          BillerMask.upsert(derived(billerMask));
        });
        break;

      case LOAD.FAILURE:
        BillerMask.delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      biller: attr(),
      billerId: fk(Biller.modelName, 'billerMasks'),
      billerMaskCount: attr(),
      createdAt: attr(),
      createdAtDerived: attr(),
      hint: attr(),
      href: attr(),
      id: attr(),
      insBillernetId: attr(),
      mask: attr(),
      maskLength: attr(),
      maskRegexp: attr(),
      netIdStr: attr(),
      offset: attr(),
      updBillernetId: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(),
    };
  }
}

const name = BillerMask.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
