import PropTypes from 'prop-types';
import { CardHeader, makeStyles } from '@material-ui/core';
import { TITLE, VERSION } from 'consts';
import AuthenticationHeaderAvatar from './AuthenticationHeaderAvatar';

const subheaderTypographyProps = { color: 'textSecondary', variant: 'body2' };
const titleTypographyProps = { color: 'textPrimary', variant: 'h5' };

const useStyles = makeStyles({
  cardHeader: {
    justifyContent: 'center',
  },
});

const AuthenticationHeader = ({ title }) => {
  const classes = useStyles();
  const subheader = `${title === TITLE ? '' : TITLE} ${VERSION}`;

  return (
    <CardHeader
      avatar={<AuthenticationHeaderAvatar />}
      className={classes.cardHeader}
      subheader={subheader}
      subheaderTypographyProps={subheaderTypographyProps}
      title={title}
      titleTypographyProps={titleTypographyProps}
    />
  );
};

AuthenticationHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AuthenticationHeader;
