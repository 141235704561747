import { attr, Model } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class FSPDatabase extends Model {
  static get modelName() {
    return 'FSPDatabase';
  }

  static reducer({ type, data }, FSPDatabase) {
    switch (type) {
      case LOAD.SUCCESS:
        FSPDatabase.delete();
        data.response.forEach((r) =>
          FSPDatabase.upsert({ ...r, value: r.databaseName })
        );
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      databaseName: attr(),
      description: attr(),
      href: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }

  static options = { idAttribute: 'databaseName' };
}

const name = FSPDatabase.name;

export const LOAD = createRequestConst(name);

export const load = createAction(LOAD);
