import PropTypes from 'prop-types';
import Select from 'components/common/fields/Select';
import { useFSPConfig } from 'hooks';
import { useUserFspConfigSelector } from 'hooks/selectors/useUserFspConfigSelectors';
import SelectTransactionTypeProgress from './SelectTransactionTypeProgress';
import { getTypes } from './consts';

const SelectTransactionType = ({ fspId, payerId, scheduled, ...props }) => {
  const fspConfig = useFSPConfig(fspId, true, false);
  const fspConfigUser = useUserFspConfigSelector(fspId, payerId);

  const data = getTypes(payerId ? fspConfigUser : fspConfig, scheduled);

  return Object.keys(fspConfig).length === 0 ? (
    <SelectTransactionTypeProgress />
  ) : (
    <Select
      data={data}
      displayAttr="name"
      fullWidth
      valueAttr="value"
      {...props}
    />
  );
};

SelectTransactionType.propTypes = {
  fspId: PropTypes.number,
  payerId: PropTypes.number,
  scheduled: PropTypes.bool,
};

SelectTransactionType.defaultProps = {
  fspId: null,
  payerId: 0,
  scheduled: true,
};

export default SelectTransactionType;
