import PropTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';
import { Button, ButtonGroup } from '@material-ui/core';
import { always, omit } from 'ramda';
import { useRef, useState, useEffect } from 'react';
import { useOpen } from 'hooks';
import Popper from './Popper';

const getButtonByPosition = (pos) => {
  return (button) => button.pos === pos;
};
const getTextFromButtons = (buttons) => {
  return (
    buttons.find(({ displayAsMenuItem }) => !displayAsMenuItem)?.text ?? ''
  );
};
const getUpdatedButtons = (buttons, pos) => {
  return buttons.map((button) => {
    button.displayAsMenuItem = button.pos !== pos;
    return button;
  });
};
const omittedObject = ['staticContext'];

const ToolbarButtonGroup = ({
  buttons,
  classes,
  onButtonOrderChange,
  onClick,
  PopperProps,
  startIcon,
  ...props
}) => {
  const [buttonText, setButtonText] = useState('');
  const [open, handleToggle, setOpen] = useOpen();
  const ref = useRef();

  const handleClick = (event) => {
    onClick(event, buttonText);
  };
  const handleClose = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleMenuItemClick = (event, pos) => {
    const buttonText = buttons.find(getButtonByPosition(pos)).text;

    setOpen(false);
    setButtonText(buttonText);
    onButtonOrderChange(event, getUpdatedButtons(buttons, pos));
    onClick(event, buttonText);
  };

  useEffect(() => {
    setButtonText(getTextFromButtons(buttons));
  }, [buttons]);

  return (
    <>
      <ButtonGroup ref={ref} {...omit(omittedObject, props)}>
        <Button
          className={classes.button}
          onClick={handleClick}
          startIcon={startIcon}
        >
          {buttonText}
        </Button>
        <Button onClick={handleToggle} size="small">
          <ArrowDropDown fontSize="small" titleAccess="Show choices" />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={ref.current}
        buttons={buttons}
        onClose={handleClose}
        onMenuItemClick={handleMenuItemClick}
        open={open}
        PopperProps={PopperProps}
      />
    </>
  );
};

ToolbarButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      displayAsMenuItem: PropTypes.bool.isRequired,
      pos: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  classes: PropTypes.object,
  onButtonOrderChange: PropTypes.func,
  onClick: PropTypes.func,
  PopperProps: PropTypes.object,
  startIcon: PropTypes.element,
};

ToolbarButtonGroup.defaultProps = {
  classes: {},
  onButtonOrderChange: always,
  onClick: always,
  PopperProps: { placement: 'bottom-start' },
  startIcon: null,
};

export default ToolbarButtonGroup;
