import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { selector as busySelector } from 'selectors/busy';
import classNames from 'classnames';
import { Field } from 'formik';
import { always, omit } from 'ramda';
import { connect } from 'react-redux';
import {
  compose,
  mapProps,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { withStyles, ClickAwayListener, TextField } from '@material-ui/core';
import InputAdornment, { CANCEL_ID } from './InputAdornmentInlineEdit';
import TextFieldForm, { formProps } from './TextFieldForm';

const omittedProps = [
  'adornmentClass',
  'classes',
  'hasFocus',
  'highlight',
  'isBusy',
  'onFieldBlur',
  'onFieldFocus',
  'reset',
  'setHasFocus',
  'setHighlight',
  'submit',
  'unmask',
  'skeletonWidth',
  ...formProps,
];

const styles = (theme) => ({
  highlight: {
    backgroundColor: theme.palette.action.hover,
  },
  root: {},
});

const FieldRefWrapper = forwardRef((props, ref) => (
  <Field {...props} innerRef={ref} />
));

FieldRefWrapper.displayName = 'FieldRefWrapper';

const TextFieldInlineEdit = ({ handleClickAway, ...props }) => {
  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
      <FieldRefWrapper {...props} />
    </ClickAwayListener>
  );
};

TextFieldInlineEdit.propTypes = {
  handleClickAway: PropTypes.func.isRequired,
};

export const withInlineEdit = compose(
  connect((state, { actionType }) => ({
    isBusy: busySelector(state, actionType),
  })),
  withStyles(styles),
  setDisplayName('TextFieldInlineEdit'),
  TextFieldForm,
  withState('hasFocus', 'setHasFocus', false),
  withState('highlight', 'setHighlight', false),
  withHandlers({
    reset:
      ({ onFieldBlur = always, resetForm, setHasFocus }) =>
      (event) => {
        if (onFieldBlur(event) !== true) {
          setHasFocus(false);
          resetForm();
          event.target.blur();
        }
      },
    submit:
      ({ onFieldBlur = always, setHasFocus, submitForm }) =>
      (event) => {
        if (onFieldBlur(event) !== true) {
          setHasFocus(false);
          submitForm();
          event.target.blur();
        }
      },
  }),
  withHandlers({
    handleClickAway:
      ({ hasFocus, submit }) =>
      (event) => {
        if (hasFocus) {
          const result = submit(event);

          // this is intentional - do not correct
          if (result === true) {
            event.preventDefault();
          }
        }
      },
    onClick:
      ({
        onClick = always,
        onFieldFocus = always,
        setHasFocus,
        setHighlight,
      }) =>
      (event) => {
        if (
          event.target.nodeName === 'DIV' ||
          event.target.nodeName === 'INPUT'
        ) {
          setHasFocus(true);
          setHighlight(false);
          onFieldFocus(event);
        }

        onClick(event);
      },
    onFocus: () => (event) => event.target.select(),
    onKeyUp:
      ({ isValid, reset, submit }) =>
      (event) => {
        if (event.which === 13 && isValid) submit(event);
        if (event.which === 27) reset(event);
      },
    onKeyDown: () => (event) => {
      if (event.which === 9) {
        // Handle the tab pressed
        //  Users can only tab within the active field
        //  They are blocked from leaving the field with the tab key
        const id = event.target.getAttribute('id');
        const hasModifier = event.getModifierState('Shift');

        if (
          (!hasModifier && id === CANCEL_ID) || // tab forward
          (hasModifier && id !== CANCEL_ID && id !== null) // tab backward
        ) {
          event.preventDefault();
        }
      }
    },
    onMouseEnter:
      ({ hasFocus, setHighlight }) =>
      () =>
        !hasFocus && setHighlight(true),
    onMouseLeave:
      ({ hasFocus, setHighlight }) =>
      () =>
        !hasFocus && setHighlight(false),
  }),
  withProps(
    ({
      InputProps = {},
      actionType = false,
      classes,
      component = TextField,
      hasFocus,
      highlight,
      isSubmitting,
      isBusy,
      isValid,
      reset,
      submit,
    }) => ({
      InputProps: {
        ...InputProps,
        className: classNames({
          [classes.highlight]: highlight,
        }),
        endAdornment: (
          <InputAdornment
            actionType={actionType}
            hasFocus={!isBusy && hasFocus}
            isSubmitting={isSubmitting}
            isValid={isValid}
            reset={reset}
            submit={submit}
          />
        ),
      },
      component,
      disabled: isBusy,
    })
  ),
  mapProps(({ propsToOmit = [], ...props }) =>
    omit([...omittedProps, ...propsToOmit], props)
  ),
  pure
);

export default withInlineEdit(TextFieldInlineEdit);
