import ExpandablePanel from 'components/common/ExpandablePanel';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { SkeletonIcon } from 'consts';
import { useUserFspConfigLoaded } from 'hooks/selectors';

const PayerDetailPanel = ({
  children,
  fspId,
  icon,
  payerId,
  title,
  ...props
}) => {
  const loaded = useUserFspConfigLoaded({ fspId, payerId });
  const skeletonTitleWidth = title.length * 12.5;

  return (
    <ExpandablePanel
      disabled={!loaded}
      icon={loaded ? icon : SkeletonIcon}
      title={
        loaded ? title : <Skeleton variant="rect" width={skeletonTitleWidth} />
      }
      {...props}
    >
      {children}
    </ExpandablePanel>
  );
};

PayerDetailPanel.propTypes = {
  children: PropTypes.node.isRequired,
  fspId: PropTypes.number.isRequired,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  payerId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(PayerDetailPanel);
