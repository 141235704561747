import PropTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';
import { MenuItem, Select } from '@material-ui/core';
import { REFUNDED } from 'consts';
import { makeStyles } from '@material-ui/core/styles';
import { replace, toUpper } from 'ramda';
import { useEffect, useState } from 'react';
import { menuItemsForChecks, menuItemsForElec } from './consts';

const capitalize = replace(/^./, toUpper);

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.primary,
  },
  select: {
    width: 280,
  },
}));

const UpdatePaymentStatusContentControl = ({
  check,
  elecDelivered,
  elecManualRefund,
  elecRefundFailed,
  onChange,
}) => {
  const [IconComponent, setIconComponent] = useState(ArrowDropDown);
  const [disabled, setDisabled] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [value, setValue] = useState('');
  const classes = useStyles();

  const handleChange = (event) => {
    const status = event.target.value;

    event.stopPropagation();
    setValue(status);
    onChange(status);
  };

  useEffect(() => {
    let items = [];

    if (check) {
      items = menuItemsForChecks;
    } else if (elecDelivered) {
      items = menuItemsForElec;
    } else if (elecManualRefund || elecRefundFailed) {
      const refunded = capitalize(REFUNDED);

      items = [refunded];
      setDisabled(true);
      setIconComponent('div');
      setValue(refunded);
      onChange();
    }

    setMenuItems(items);
  }, [check, elecDelivered, elecManualRefund, elecRefundFailed, onChange]);

  return (
    <Select
      classes={{ disabled: classes.disabled }}
      className={classes.select}
      disabled={disabled}
      IconComponent={IconComponent}
      labelId="status-label"
      onChange={handleChange}
      value={value}
      variant="outlined"
    >
      {menuItems.map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

UpdatePaymentStatusContentControl.propTypes = {
  check: PropTypes.bool.isRequired,
  elecDelivered: PropTypes.bool.isRequired,
  elecManualRefund: PropTypes.bool.isRequired,
  elecRefundFailed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UpdatePaymentStatusContentControl;
