import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { branch, compose, pure, renderComponent } from 'recompose';
import { TextField } from '@material-ui/core';
import { buildInputProps } from 'util/form';
import { phoneMask, phonePlaceholderChar, unmask } from './consts';
import ReadOnlyField from './ReadOnly';

const Mask = ({ inputRef, ...props }) => {
  return (
    <MaskedInput
      {...props}
      mask={phoneMask}
      placeholderChar={phonePlaceholderChar}
    />
  );
};

Mask.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.any,
};

Mask.defaultProps = {
  inputRef: null,
};

const Phone = ({ InputProps, InputLabelProps, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange(unmask(event.target.value));
  };

  // It seems that the phone mask cause a confusion in material-ui
  // InputLabel shrink handling. As stated in the docs, this might
  // happen on some (undefined) cases:
  // https://material-ui.com/components/text-fields/#limitations
  // Perhaps this can be removed after updating/changing
  // the react-text-mask package
  const [isFocused, setIsFocused] = useState(false);
  const handleFocusEvents = {
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
  };

  const { value } = props;
  const shrink = isFocused || Boolean(value);
  return (
    <TextField
      {...props}
      InputLabelProps={{ ...InputLabelProps, shrink }}
      InputProps={{ ...InputProps, inputComponent: Mask, ...handleFocusEvents }}
      {...buildInputProps(props)}
      onChange={handleChange}
    />
  );
};

Phone.propTypes = {
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Phone.defaultProps = {
  InputProps: null,
  InputLabelProps: null,
  onChange: () => null,
  value: '',
};

export default compose(
  branch(({ readOnly }) => readOnly, renderComponent(ReadOnlyField)),
  pure
)(Phone);
