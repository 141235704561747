import PropTypes from 'prop-types';
import { createRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectorHistId } from 'selectors/paymentResearch';
import { selector as busySelector } from 'selectors/busy';
import { selectedFspIdSelector } from 'selectors/fsp';
import { loadByHistId, LOAD_HIST_ID } from 'model/paymentResearch';
import { CircularProgress, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SCROLL_REF_KEY } from 'util/format';
import PaymentResearchListItem from './PaymentResearchListItem';

const useStyles = makeStyles(() => ({
  progressContainer: {
    minHeight: 305,
  },
  progress: {
    left: '45%',
    position: 'absolute',
    top: '42%',
  },
  list: {
    maxHeight: 450,
    position: 'relative',
    overflow: 'auto',
  },
  paper: {
    maxHeight: 450,
    width: 465,
  },
}));

const PaymentResearchList = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fspId = useSelector(selectedFspIdSelector);
  const isBusy = useSelector((state) => busySelector(state, LOAD_HIST_ID));
  const tickets = useSelector((state) => selectorHistId(state, id));

  useEffect(() => {
    dispatch(loadByHistId({ fspId, id }));
  }, [dispatch, fspId, id]);

  return (
    <div className={classes.paper}>
      {isBusy || !tickets?.length ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : tickets?.length ? (
        <List className={classes.list} disablePadding>
          {tickets.map(({ id }) => (
            <PaymentResearchListItem
              key={id}
              expanded={tickets?.length === 1}
              id={id}
              scrollRef={createRef(SCROLL_REF_KEY)}
            />
          ))}
        </List>
      ) : null}
    </div>
  );
};

PaymentResearchList.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PaymentResearchList;
