import { attr, fk, Model } from 'redux-orm';
import Payer from 'model/payer';
import { createAction, createRequestConst } from '../util';

export default class ActionableAlert extends Model {
  static get modelName() {
    return 'ActionableAlert';
  }

  static reducer({ type, data }, ActionableAlert, { Payer }) {
    switch (type) {
      case LOAD.ACTION:
        ActionableAlert.upsert({
          id: 0,
          isEmpty: true,
        });
        break;

      case LOAD.SUCCESS: {
        if (Payer.idExists(data.props.payerId)) {
          Payer.withId(data.props.payerId).actionableAlerts.delete();
          ActionableAlert.upsert({
            id: 0,
            isEmpty: false,
          });
        }

        if (data && Array.isArray(data.response)) {
          data.response.forEach((r) => ActionableAlert.upsert(r));
        }
        break;
      }

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      completedAt: attr(),
      createdAt: attr(),
      dismissedAt: attr(),
      displayModule: attr(),
      eventName: attr(),
      expireAt: attr(),
      fspId: attr(),
      id: attr(),
      payerId: fk(Payer.modelName, 'actionableAlerts'),
      renderedMessage: attr(),
      status: attr(),
    };
  }
}

export const LOAD = createRequestConst(ActionableAlert.modelName);
export const load = createAction(LOAD);
