import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { DEBOUNCE_DELAY } from 'consts';
import { useCallback, useState, useEffect } from 'react';
import { useEventListener } from 'hooks';

const TIMEOUT = 120000;

const eventsToDebounce = ['keydown', 'mousedown'];

const usePasswordUpdateIdle = (onTimedOut) => {
  const [startTime, setStartTime] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const timedOut = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    onTimedOut();
  }, [onTimedOut, timeoutId]);

  const clearAndResetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };
  const handleEvent = (event) => {
    const diff = moment().diff(moment(startTime));

    if (
      (event.type !== 'visibilitychange' ||
        document.visibilityState !== 'hidden') &&
      diff >= TIMEOUT
    ) {
      timedOut();
    } else {
      clearAndResetTimeout();
    }
  };

  useEffect(() => {
    if (!timeoutId) {
      setStartTime(new Date());
      setTimeoutId(setTimeout(timedOut, TIMEOUT));
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timedOut, timeoutId]);

  useEventListener(eventsToDebounce, debounce(DEBOUNCE_DELAY, handleEvent));
  useEventListener('visibilitychange', handleEvent);
};

export default usePasswordUpdateIdle;
