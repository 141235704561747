import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { always } from 'ramda';

const anchorOrigin = { horizontal: 'right', vertical: 'center' };
const transformOrigin = { vertical: 'center', horizontal: 'left' };

const useStyles = makeStyles(() => ({ root: {} }));

const ButtonPopover = ({
  anchorOrigin,
  children,
  classes: classesProp,
  onClick,
  onClose,
  title,
  transformOrigin,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    onClick(event);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setOpen(false);
    onClose(event);
  };

  return (
    <>
      <Button
        {...props}
        className={classes.root}
        data-testid="button"
        onClick={handleClick}
      >
        {title}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        classes={classesProp}
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
      >
        {children}
      </Popover>
    </>
  );
};

ButtonPopover.propTypes = {
  anchorOrigin: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  transformOrigin: PropTypes.object,
};

ButtonPopover.defaultProps = {
  anchorOrigin,
  children: null,
  classes: undefined,
  onClick: always,
  onClose: always,
  title: '',
  transformOrigin,
};

export default ButtonPopover;
