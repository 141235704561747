import { useState } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import { FSP_CODE } from 'consts';
import RadioGroup from 'components/common/fields/radio_group';
import useAutocompleteComponent from 'components/modules/implementation/ApiKey/List/Form/Fields/hooks/useAutocompleteComponent';
import { SearchParamPropType } from 'components/common/SearchTextField/Multiple/consts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formGroup: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(1),
  },
}));

const getInitRadio = (value) => {
  if (!value) {
    return [FSP_CODE, ''];
  }
  const items = value.split('_');
  if (items[0] === FSP_CODE) {
    return [FSP_CODE, items[1]];
  }
  return [items[0].slice(0, -1), items[1]];
};

const ParamValueRadioWithSelect = ({
  onChange,
  searchParam,
  separator,
  options,
  value,
  ...props
}) => {
  const [initType, initValue] = getInitRadio(value);
  const [localValue, setLocalValue] = useState(initValue);
  const [radioType, setRadioType] = useState(initType);
  const buildTypeId = `${radioType}${separator[radioType]}`;
  const classes = useStyles();

  const handleChange = (event, value) => {
    setLocalValue(value?.id ? value.id : '');
    const eventReturn = {
      target: {
        value: value?.id ? `${buildTypeId}${value.id}` : undefined,
      },
    };
    onChange(eventReturn);
  };

  const handleChangeRadioTypeChange = (type) => {
    setRadioType(type);
    // Clear previous selected id everytime radio changes
    setLocalValue('');
  };

  const AutocompleteComponent = useAutocompleteComponent({
    type: radioType,
    onChange: handleChange,
    value: localValue,
    ...omit(['inputRef'], props),
  });

  return (
    <>
      <RadioGroup
        classes={classes}
        label="Type"
        onChange={handleChangeRadioTypeChange}
        radios={options}
        value={radioType}
      />
      {AutocompleteComponent}
    </>
  );
};

ParamValueRadioWithSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  searchParam: SearchParamPropType.isRequired,
  separator: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

ParamValueRadioWithSelect.defaultProps = {
  options: [],
  separator: '_',
  value: '',
};

export default ParamValueRadioWithSelect;
