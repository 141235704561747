import { useEffect } from 'react';

const loadEvent = 'load';
const errorEvent = 'error';

const scriptElement = 'script';

const useIframeLoadScript = (
  isIframeLoaded,
  iframeRef,
  scriptUri,
  handleScriptLoad,
  handleScriptError
) =>
  useEffect(() => {
    let script;
    if (isIframeLoaded) {
      const iframeDocument = iframeRef.current.contentDocument;
      script = iframeDocument.createElement(scriptElement);
      script.src = scriptUri;
      script.async = true;

      script.addEventListener(loadEvent, handleScriptLoad);
      script.addEventListener(errorEvent, handleScriptError);

      iframeDocument.body.appendChild(script);
    }
    return () => {
      if (isIframeLoaded) {
        script.removeEventListener(loadEvent, handleScriptLoad);
        script.removeEventListener(errorEvent, handleScriptError);
      }
    };
  }, [
    isIframeLoaded,
    iframeRef,
    scriptUri,
    handleScriptLoad,
    handleScriptError,
  ]);

export default useIframeLoadScript;
