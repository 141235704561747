import { createSelector } from 'reselect';
import {
  createDeleteConst,
  createAction,
  addOrUpdateSuccessful,
} from 'util/actions';

const REDIRECT = 'redirect';

export const CLEAR = createDeleteConst(REDIRECT);

export const clearRedirect = createAction(CLEAR);

const getRedirect = (state) => state.redirect;
const getRedirectType = (state, props) => props.redirectType;

export const isRedirect = createSelector(
  [getRedirect, getRedirectType],
  (redirect, redirectType) => redirect.type === redirectType
);

export const getRedirectWhen = createSelector(
  [getRedirect, getRedirectType],
  (redirect, redirectType) =>
    redirect.type === redirectType ? redirect : undefined
);

const initialState = { type: '' };

export default (state = initialState, { type, data }) => {
  switch (type) {
    case CLEAR.ACTION:
      return initialState;
    default:
      if (addOrUpdateSuccessful(type)) {
        return { type, data };
      }
      return state;
  }
};
