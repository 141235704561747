import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst } from '../util';

export default class Reason extends Model {
  static get modelName() {
    return 'Reason';
  }

  static reducer({ type, data }, Reason) {
    switch (type) {
      case LOAD.SUCCESS:
        data?.response?.forEach((r) => {
          Reason.create(r);
        });
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      createdAt: attr(),
      externalReason: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      offset: attr(),
      reason: attr(),
      type: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

export const LOAD = createRequestConst(Reason.modelName);

export const load = createAction(LOAD);
