import {
  createAction,
  createAddConst,
  createDeleteConst,
  createLoadConst,
  createRequestConst,
  createUpdateConst,
} from 'util/actions';

export const MODEL = 'authToken';

export const CHECK_VERSION_EVERY_HOUR = createRequestConst(
  `${MODEL}CHECK_VERSION_EVERY_HOUR`
);
export const CLEAR_UPDATE = createRequestConst('clearAuthToken');
export const KEEP_ALIVE = createUpdateConst(MODEL);
export const LOGIN = createRequestConst(MODEL);
export const LOGOUT = createDeleteConst(MODEL);
export const UPDATE = createAddConst(MODEL);
export const USER_AFTER_REFRESH = createLoadConst(MODEL);

export const checkVersionEveryHour = createAction(CHECK_VERSION_EVERY_HOUR);
export const clearUpdate = createAction(CLEAR_UPDATE);
export const keepAlive = createAction(KEEP_ALIVE);
export const login = createAction(LOGIN);
export const logout = createAction(LOGOUT);
export const update = createAction(UPDATE);
export const userAfterRefresh = createAction(USER_AFTER_REFRESH);

const initialState = { isAuthenticated: false, hasRan: false, updated: false };

export default (
  state = initialState,
  { type, data = {}, error, reduxActionData = {} }
) => {
  switch (type) {
    case KEEP_ALIVE.SUCCESS:
      if (!data.user) {
        /**
         * We only need to return a value if authentication failed.
         * If we do return something here, we force the entire app to re-render!
         * (because auth is such a basic building block of the app)
         *
         * This condition should *only* occur when the user is logged in and
         * the user manually deletes the token from session storage
         */
        return {
          ...state,
          hasRan: true,
          id: 0,
          isAuthenticated: false,
          userLogin: {},
        };
      }
      break;

    case LOGIN.SUCCESS:
    case USER_AFTER_REFRESH.SUCCESS: {
      const { passwordExpired, ...authData } = data;
      const isDataEmpty = Array.isArray(data) && data.length === 0;

      if (passwordExpired || isDataEmpty) {
        return {
          ...state,
          ...authData,
          isAuthenticated: false,
          redirectLocation: isDataEmpty ? '/' : undefined,
        };
      }

      return { ...authData, hasRan: true, isAuthenticated: true };
    }

    case UPDATE.SUCCESS:
      return { hasRan: true, updated: true, redirectLocation: '/' };

    /**
     * Do not return a value if there is a KEEP_ALIVE.FAILURE
     * The scenario is this, if for whatever reason the keepAlive API request fails,
     * we log the user out.
     *
     * We are going to change it so that the only way the user is logged out is
     * if the <Idle /> component is on the screen and  it's timer runs out.
     *
     * The keepAlive request will only be used for just that,
     * keeping the token's expire timeout value refreshed.
     */
    case LOGIN.FAILURE:
    case LOGOUT.FAILURE:
    case USER_AFTER_REFRESH.FAILURE:
    case LOGOUT.SUCCESS:
      return {
        expired: reduxActionData.expired,
        hasRan: true,
        isAuthenticated: false,
        error,
        updated: false,
      };

    case CLEAR_UPDATE.ACTION:
      return {
        ...initialState,
        redirectLocation: '/',
      };

    default:
      break;
  }

  return state;
};
