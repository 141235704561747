import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { isOn } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';
import { TRANSACTION_TYPE_BILL_PAY, DELIVERED, DELIVER_ELEC_STD } from 'consts';
import { useState } from 'react';
import Alert from 'components/common/AlertImproved';
import InfoDialog from 'components/common/InfoDialog';
import CreateDialog from './CreateDialog';
import { useHasPermission } from '../../../../../../../hooks';
import { ACCESS_CREATE_PAYMENT_RESEARCH } from '../../../../../../common/permissions';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const PaymentResearchCreate = ({ transaction }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showNoContact, setShowNoContact] = useState(false);
  const classes = useStyles();
  const hasPerm = useHasPermission([ACCESS_CREATE_PAYMENT_RESEARCH]);

  // See tickets:
  //   https://payrailz.atlassian.net/browse/ON-355
  //   https://payrailz.atlassian.net/browse/ON-425
  //   https://payrailz.atlassian.net/browse/ON-545
  const transactionMeetsCriteria =
    transaction?.businessDaysSinceDeliverOnDerived > 2 &&
    !isOn(transaction?.conversionDataOffOn) &&
    transaction?.deliver === DELIVER_ELEC_STD &&
    transaction?.status === DELIVERED &&
    transaction?.transactionType === TRANSACTION_TYPE_BILL_PAY &&
    hasPerm;

  const handleClick = (event) => {
    event.stopPropagation();
    setShowConfirmation(!showConfirmation);
  };

  const handleConfirmationConfirm = (event) => {
    event.stopPropagation();
    setShowConfirmation(false);
    setShowCreateDialog(true);
  };

  const handleConfirmationClose = (event) => {
    event.stopPropagation();
    setShowConfirmation(false);
    setShowNoContact(true);
  };

  const handleCreateDialogClose = () => {
    setShowCreateDialog(false);
  };

  return transactionMeetsCriteria ? (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        variant="outlined"
      >
        Create Payment Research
      </Button>
      {showConfirmation && (
        <Alert
          cancelLabel="No"
          confirmLabel="Yes"
          onClose={handleConfirmationClose}
          onConfirm={handleConfirmationConfirm}
          open
          title="Did the user contact the biller to inquire about the payment?"
        />
      )}
      {showNoContact && (
        <InfoDialog
          message={
            `Please advise the user to contact the biller directly. ` +
            `While it might seem inconvenient to request that the user ` +
            `contact the biller, it is the fastest way to get a resolution. ` +
            `Billers are reluctant to talk to us as a third party regarding that user's payments.`
          }
          onClose={() => setShowNoContact(false)}
          open
        />
      )}
      {showCreateDialog && (
        <CreateDialog
          fspId={transaction.fspId}
          id={transaction?.id}
          onClose={handleCreateDialogClose}
        />
      )}
    </>
  ) : null;
};

PaymentResearchCreate.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default PaymentResearchCreate;
