import PropTypes from 'prop-types';
import TrendingFlat from '@material-ui/icons/TrendingFlat';
import classNames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { isNil } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(0.5),
  },
  italic: {
    fontStyle: 'italic',
  },
  mono: {
    fontFamily: 'monospace',
    fontSize: '1.3rem',
  },
}));

const AuditDetailRowRecordEntry = ({
  columnName,
  displayName,
  logOp,
  newValueDerived,
  oldValueDerived,
}) => {
  const classes = useStyles();
  const newValueIsNumber = !Number.isNaN(Number(newValueDerived));
  const oldValueIsNumber = !Number.isNaN(Number(oldValueDerived));
  const newDisplayValue = newValueIsNumber
    ? newValueDerived
    : `"${newValueDerived}"`;
  const oldDisplayValue = oldValueIsNumber
    ? oldValueDerived
    : `"${oldValueDerived}"`;

  return (
    <Grid alignItems="center" container spacing={1} wrap="nowrap">
      <Grid item>
        <Typography color="primary" data-testid="name" variant="body1">
          {displayName || columnName}:
        </Typography>
      </Grid>
      {logOp !== 'ins' && (
        <>
          <Grid item>
            <Typography
              className={classNames({
                [classes.italic]: isNil(oldValueDerived),
                [classes.mono]: oldValueIsNumber,
              })}
              data-testid="old"
              variant="body2"
            >
              {oldValueDerived ? oldDisplayValue : 'empty'}
            </Typography>
          </Grid>
          <Grid className={classes.icon} item>
            <TrendingFlat data-testid="arrow" />
          </Grid>
        </>
      )}
      <Grid item>
        <Typography
          className={classNames({
            [classes.mono]: newValueIsNumber,
          })}
          data-testid="new"
          variant="body2"
        >
          {newDisplayValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

AuditDetailRowRecordEntry.propTypes = {
  columnName: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  logOp: PropTypes.string.isRequired,
  newValueDerived: PropTypes.string,
  oldValueDerived: PropTypes.string,
};

AuditDetailRowRecordEntry.defaultProps = {
  displayName: null,
  oldValueDerived: null,
  newValueDerived: null,
};

export default AuditDetailRowRecordEntry;
