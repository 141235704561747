import delay from '@redux-saga/delay-p';
import {
  ACTION,
  addAction,
  BUSY,
  deleteAction,
  FAILURE,
  importAction,
  loadAction,
  requestAction,
  SUCCESS,
  updateAction,
} from 'util/actions';
import { anyPass, endsWith, equals, or, split } from 'ramda';
import { put, race, take, takeEvery } from 'redux-saga/effects';
import { BUSY_DELAY } from './consts';

const matchRequestAction = (actionType, requestAction, type) => {
  return equals(
    split(`_${ACTION}`, requestAction),
    split(`_${actionType}`, type)
  );
};
const requestFinished = (requestAction, type) => {
  return or(
    matchRequestAction(SUCCESS, requestAction, type),
    matchRequestAction(FAILURE, requestAction, type)
  );
};
const contestants = (action) => ({
  request: take(({ type }) => requestFinished(action.type, type)),
  slowRequest: delay(BUSY_DELAY),
});
const endsWithType = [
  endsWith(addAction),
  endsWith(deleteAction),
  endsWith(importAction),
  endsWith(loadAction),
  endsWith(requestAction),
  endsWith(updateAction),
];

function* requestVsTimer(action) {
  const { slowRequest } = yield race(contestants(action));

  if (slowRequest) {
    const type = action.type.replace(`_${ACTION}`, `_${BUSY}`);
    const busyAction = { type };

    yield put(busyAction);
  }
}

export default function* watch() {
  yield takeEvery(({ type }) => anyPass(endsWithType)(type), requestVsTimer);
}
