import { createAction, createLoadConst } from 'util/actions';

export const NAME = 'audit';

export const VIEW_LIST = 'list';
export const VIEW_DETAIL = 'details';
export const VIEW_SYSTEM_DETAILS = 'systemDetails';
export const DEFAULT_VIEW = VIEW_LIST;

export const VIEW = createLoadConst(NAME);

export const changeView = createAction(VIEW);

export const selector = (state) => state[NAME];

const defaultState = { view: DEFAULT_VIEW };

export default (state = defaultState, { type, data }) => {
  switch (type) {
    case VIEW.ACTION:
      return { ...state, view: data };

    default:
      return state;
  }
};
