import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { PAYRAILZ_ADMIN_ORG_ID } from 'sdk';

export const selectorId = createSelector(orm.OntracUser);

export const selectorByOrgId = createSelector(
  orm,
  (state, props) => props,
  ({ OntracUser }, id) =>
    OntracUser.filter(
      ({ organizationId }) => organizationId === id
    ).toRefArray()
);

export const selectorPayrailz = createSelector(orm, ({ OntracUser }) =>
  OntracUser.all()
    .toRefArray()
    .filter(({ organizationId }) => organizationId === PAYRAILZ_ADMIN_ORG_ID)
);

export const adminAddUser = ({ adminAddUser }) => adminAddUser;
