import { Grid } from '@material-ui/core';
import PayerDetailSections from './PayerDetailSections';
import RecentList from '../RecentList';

const PayerDetail = () => {
  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <RecentList />
      </Grid>
      <Grid item xs>
        <PayerDetailSections />
      </Grid>
    </Grid>
  );
};

export default PayerDetail;
