import * as Yup from 'yup';
import { FRAUD_MONITOR_CODE, OFF, ON } from 'consts';

export const SCORE_VALUE_DEFAULT = 101;
export const CANCEL_SCORE_OFF_ON = 'cancelScoreOffOn';
export const CANCEL_SCORE_VALUE = 'cancelScoreGreaterThanEqual';
export const CANCEL_SUSPEND_SCORE_OFF_ON = 'cancelSuspendScoreOffOn';
export const CANCEL_SUSPEND_SCORE_VALUE = 'suspendPayerScoreGreaterThanEqual';
export const SCHEDULE_SCORE_VALUE = 'scheduleScoreLessThanEqual';
export const SCORE_THRESHOLD_ONHOLD = 'onholdScoreRange';
export const FINAL_CANCEL_SCORE_VALUE = 'finalCancelScoreGreaterThanEqual';

export const Fields = {
  SCORE_THRESHOLD_CANCEL_SCORE_OFF_ON: {
    name: `${FRAUD_MONITOR_CODE}.${CANCEL_SCORE_OFF_ON}`,
    label: 'Cancel Score',
    TooltipProps: {
      title:
        'Disabling this setting is NOT recommended as it can result in a higher rate of fraudulent transactions',
    },
  },
  SCORE_THRESHOLD_CANCEL_SCORE_VALUE: {
    name: `${FRAUD_MONITOR_CODE}.${CANCEL_SCORE_VALUE}`,
    label: 'Value',
    inputProps: {
      max: 9999,
      min: 0,
    },
    required: true,
    TooltipProps: {
      title:
        'Transactions scored at or above this value will be automatically canceled',
    },
  },
  SCORE_THRESHOLD_CANCEL_SUSPEND_SCORE_OFF_ON: {
    name: `${FRAUD_MONITOR_CODE}.${CANCEL_SUSPEND_SCORE_OFF_ON}`,
    label: 'Cancel & Suspend Score',
    TooltipProps: {
      title:
        'Disabling this setting is NOT recommended as it can result in a higher rate of fraudulent transactions',
    },
  },
  SCORE_THRESHOLD_CANCEL_SUSPEND_SCORE_VALUE: {
    name: `${FRAUD_MONITOR_CODE}.${CANCEL_SUSPEND_SCORE_VALUE}`,
    label: 'Value',
    inputProps: {
      max: 9999,
      min: 0,
    },
    required: true,
    TooltipProps: {
      title:
        'Transactions scored at or above this value will be automatically canceled AND the payer will also be suspended',
    },
  },
  SCORE_THRESHOLD_SCHEDULE_SCORE_VALUE: {
    name: `${FRAUD_MONITOR_CODE}.${SCHEDULE_SCORE_VALUE}`,
    label: 'Schedule Score',
    inputProps: {
      max: 9999,
      min: 0,
    },
    required: true,
    TooltipProps: {
      title: 'Transactions scored at or below this value will be scheduled',
    },
  },
  SCORE_THRESHOLD_ONHOLD: {
    name: SCORE_THRESHOLD_ONHOLD,
    label: 'On-Hold Score Range',
    required: false,
    TooltipProps: {
      title:
        'Transactions with a score in this range will be placed on hold for review',
    },
  },
  SCORE_FINAL_CANCEL_SCORE_VALUE: {
    name: `${FRAUD_MONITOR_CODE}.${FINAL_CANCEL_SCORE_VALUE}`,
    label: 'Final Cancel Score',
    inputProps: {
      max: 9999,
      min: 0,
    },
    required: true,
    TooltipProps: {
      title: `Transactions scored at or above this value will be canceled based on criteria below. Transactions below this value will be automatically scheduled based on the criteria below.\r\n
        For batch transactions: applies 30 minutes prior to cutoff time.\r\n
        For real-time transactions: applies when the max on-hold minutes has been reached.`,
    },
  },
};

// This logic is reused on Product-Level Overrides Panel
export const valuesForApi = (values) => {
  const fspFraudMonitor = values ? values[FRAUD_MONITOR_CODE] : {};
  return {
    ...values,
    [FRAUD_MONITOR_CODE]: {
      ...fspFraudMonitor,
      [CANCEL_SCORE_VALUE]:
        fspFraudMonitor?.[CANCEL_SCORE_OFF_ON] === OFF
          ? SCORE_VALUE_DEFAULT
          : fspFraudMonitor?.[CANCEL_SCORE_VALUE],
      [CANCEL_SUSPEND_SCORE_VALUE]:
        fspFraudMonitor?.[CANCEL_SUSPEND_SCORE_OFF_ON] === OFF
          ? SCORE_VALUE_DEFAULT
          : fspFraudMonitor?.[CANCEL_SUSPEND_SCORE_VALUE],
    },
  };
};

function yupTestMinCancelScore(value) {
  return value < this.parent[SCHEDULE_SCORE_VALUE] + 19
    ? this.createError({
        message: `Must be larger than ${
          this.parent[SCHEDULE_SCORE_VALUE] + 19
        }`,
      })
    : true;
}

function yupTestMinCancelSuspendScore(value) {
  return value <= this.parent[CANCEL_SCORE_VALUE] && value < 101
    ? this.createError({
        message: `Must be larger than ${this.parent[CANCEL_SCORE_VALUE]}`,
      })
    : true;
}

function yupTestMaxScheduleScore(value) {
  return value > this.parent[CANCEL_SCORE_VALUE] - 19
    ? this.createError({
        message: `Must be lower than ${this.parent[CANCEL_SCORE_VALUE] - 19}`,
      })
    : true;
}

function yupTestRequiredCancelScoreValueIfEnabled(value) {
  return !value && this.parent[CANCEL_SCORE_OFF_ON] === ON
    ? this.createError({
        message: 'A value is required',
      })
    : true;
}

function yupTestRequiredCancelSuspendScoreValueIfEnabled(value) {
  return !value && this.parent[CANCEL_SUSPEND_SCORE_OFF_ON] === ON
    ? this.createError({
        message: 'A value is required',
      })
    : true;
}

// This object is reused on Product-Level Overrides Panel
export const commonValidationSchema = {
  [CANCEL_SCORE_VALUE]: Yup.number()
    .test('min', yupTestMinCancelScore)
    .max('101', 'Maximum value is 101')
    .test('required', yupTestRequiredCancelScoreValueIfEnabled)
    .typeError('A value is required'),
  [CANCEL_SUSPEND_SCORE_VALUE]: Yup.number()
    .test('min', yupTestMinCancelSuspendScore)
    .test('required', yupTestRequiredCancelSuspendScoreValueIfEnabled)
    .max('101', 'Maximum value is 101')
    .typeError('A value is required'),
  [SCHEDULE_SCORE_VALUE]: Yup.number()
    .min('0', 'Minimum value is 0')
    .test('max', yupTestMaxScheduleScore)
    .typeError('A value is required'),
  [FINAL_CANCEL_SCORE_VALUE]: Yup.number()
    .test('min', yupTestMinCancelScore)
    .max('95', 'Maximum value is 95')
    .typeError('A value is required'),
};

export const validationSchema = Yup.object().shape({
  [FRAUD_MONITOR_CODE]: Yup.object().shape(commonValidationSchema),
});
