import {
  BOTH_CODE,
  CHECK_CODE,
  CHECK_ONLY_CODE,
  ELEC_CODE,
  ELEC_ONLY_CODE,
  ELEC_OR_CHECK_CODE,
} from 'consts';

export const defaultPageSettings = {
  order: 'asc',
  orderBy: 'name',
};

export const pageSettingsName = 'billerPayee';

export const searchParams = [
  {
    displayName: 'Name',
    field: 'name',
    operator: 'fieldExactLike',
    type: 'string',
  },
  {
    displayName: 'Status',
    field: 'payeeStatus',
    operator: 'field',
    type: 'option',
    valueOptions: [
      { displayName: 'Active', id: 'active' },
      { displayName: 'Inactive', id: 'inactive' },
      { displayName: 'Undeliverable', id: 'undeliverable' },
    ],
  },
  {
    displayName: 'Elec Or Check',
    field: ELEC_OR_CHECK_CODE,
    operator: 'field',
    type: 'option',
    valueOptions: [
      { displayName: 'Elec', id: ELEC_CODE },
      { displayName: 'Elec Only', id: ELEC_ONLY_CODE },
      { displayName: 'Check', id: CHECK_CODE },
      { displayName: 'Check Only', id: CHECK_ONLY_CODE },
      { displayName: 'Both', id: BOTH_CODE },
    ],
  },
];
