import PropTypes from 'prop-types';
import { TimePicker } from 'components/common/fields';
import useFieldWithErrors from 'components/common/formik/hooks/useFieldWithErrors';

const TimePickerField = ({ name, ...props }) => {
  const [field, , helpers, errorProps] = useFieldWithErrors(name);

  const onChange = (date) => {
    if (!date) {
      helpers.setTouched(true);
      helpers.setValue(null);
    }
    if (date?.isValid()) {
      const value = date.format('YYYY-MM-DD HH:mm');
      helpers.setValue(value);
    }
  };

  return (
    <TimePicker {...field} {...props} {...errorProps} onChange={onChange} />
  );
};

TimePickerField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TimePickerField;
