import {
  equals,
  find,
  includes,
  lastIndexOf,
  reject,
  slice,
  startsWith,
} from 'ramda';
import { ACTION, endLoading, startLoading } from 'util/actions';

export const isLoadingState = (state, type) =>
  includes(type, state.loading ?? []);

export default (state = [], { type }) => {
  if (startLoading(type) && !includes(type, state ?? [])) {
    return [...state, type];
  }
  if (endLoading(type)) {
    const foundItem = find(
      (item) => startsWith(slice(0, lastIndexOf(ACTION, item), item), type),
      state
    );
    return reject(equals(foundItem), state);
  }
  return state;
};
