import PropTypes from 'prop-types';
import { useCallback } from 'react';
import StatusSelectionAction from 'components/common/status_selection_action';
import { selectorAsLabel } from 'selectors/payer';
import { UPDATE, update } from 'model/internalAccount';
import { useDispatch, useSelector } from 'react-redux';
import { statusConfig, statusList } from './const';

const StatusSelector = ({ item, options, ...props }) => {
  const dispatch = useDispatch();
  const payerLabel = useSelector((state) =>
    selectorAsLabel(state, options?.payerId)
  );
  const nickNameOrDescription = item?.nickName
    ? item?.nickName
    : item?.description
    ? item?.descriptionDerived
    : '';

  const confirmMessageExtras = `${payerLabel}'s ${nickNameOrDescription} ****${item?.accountNbrMask}?`;

  const handleChange = useCallback(
    (status) => {
      dispatch(update({ account: item, options, status }));
    },
    [item, dispatch, options]
  );

  return (
    <StatusSelectionAction
      {...props}
      actionType={UPDATE}
      confirmMessageExtras={confirmMessageExtras}
      currentStatus={item?.ddaStatus}
      onChange={handleChange}
      statusConfig={statusConfig}
      statusList={statusList}
    />
  );
};

StatusSelector.propTypes = {
  item: PropTypes.object,
  options: PropTypes.object,
};

StatusSelector.defaultProps = {
  item: null,
  options: null,
};

export default StatusSelector;
