import { client } from 'util/sdk';
import { DELETE, GET, POST } from 'util/api';
import { FSPS, SSO_URI, SSO_URIS, RESET_SSO_URIS } from 'consts';
import { pick } from 'ramda';

export const add = ({ fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${SSO_URIS}`, {
    data,
    queryParams: { adminUserId: auth.id },
    returnModel: { name: SSO_URI },
  });

export const load = ({ fspId }) =>
  client.executeRequest(GET, `/${FSPS}/${fspId}/${SSO_URIS}`, {
    returnModel: {
      name: SSO_URI,
    },
  });

export const remove = ({ id, fspId }, auth) =>
  client.executeRequest(DELETE, `/${FSPS}/${fspId}/${SSO_URIS}/${id}`, {
    queryParams: { adminUserId: auth.id },
    returnModel: { name: SSO_URI },
  });

export const update = ({ id, fspId, ...data }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${SSO_URIS}/${id}`, {
    data: pick(['type', 'returnTypeOffOn', 'uri', 'defaultUriOffOn'], data),
    queryParams: { adminUserId: auth.id },
    returnModel: { name: SSO_URI },
  });

export const importAndReset = ({ fspId, ssouri }, auth) =>
  client.executeRequest(POST, `/${FSPS}/${fspId}/${RESET_SSO_URIS}`, {
    data: {
      ssouri: ssouri.map(({ type, returnTypeOffOn, uri, defaultUriOffOn }) => ({
        type,
        returnTypeOffOn,
        uri,
        defaultUriOffOn,
      })),
    },
    queryParams: { adminUserId: auth.id },
    returnModel: { name: SSO_URI },
  });
