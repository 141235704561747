import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import usePayALoanTransaction from '../../../../History/config/DetailsRender/usePayALoanTransaction';

const Amount = ({ item }) => {
  return (
    usePayALoanTransaction(item.transactionType) && (
      <Grid item xs={2}>
        <ReadOnlyFormControl label="Amount" value={item?.amountDerived} />
      </Grid>
    )
  );
};
Amount.propTypes = {
  item: PropTypes.shape({
    amountDerived: PropTypes.string,
    transactionType: PropTypes.string,
  }).isRequired,
};
export default Amount;
