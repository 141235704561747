import { LOAD, UPDATE, CREATE } from 'model/fspConfigPayALoan';
import { load, update, create } from 'sdk/fspConfigPayALoan';
import { takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export default function* saga() {
  yield takeLatest(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: ORM,
      type: LOAD,
    })
  );

  yield takeLatest(
    CREATE.ACTION,
    callAction({
      api: create,
      dataName: ORM,
      type: CREATE,
    })
  );

  yield takeLatest(
    UPDATE.ACTION,
    callAction({
      api: update,
      dataName: ORM,
      type: UPDATE,
      formatSuccessMessage: () => `Pay A Loan Config successfully updated`,
    })
  );
}
