import MaskAccount from './MaskAccount';
import Renderer from './DetailsRender';

export { default as ActionsRenderer } from './ActionsRenderer';

export const columnMeta = [
  {
    id: 'name',
    label: 'Name',
    sortId: 'name',
    xs: 3,
  },
  {
    id: 'nickName',
    label: 'Nickname',
    sortId: 'nickName',
    xs: 3,
  },
  {
    context: true,
    id: 'billerAccountNumberMask',
    label: 'Account Number',
    xs: 2,
    renderer: (value, item, icon, props) => (
      <MaskAccount icon={icon} item={item} value={value} {...props} />
    ),
  },
  {
    capitalize: true,
    id: 'payeeStatus',
    label: 'Status',
    xs: 1,
  },
  {
    id: 'payeeStatusAtLabel',
    label: 'Status Last Updated',
    xs: 2,
  },
];

export const eBillColumn = {
  capitalize: true,
  id: 'ebillStatus',
  label: 'eBill',
  xs: 1,
};

export const defaultOrderBy = 'name';

export const detailsRender = (props) => <Renderer {...props} />;
