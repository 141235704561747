import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  isPathnameStillCurrent,
  wouldUrlBeDifferent,
  parse,
  getUpdatedUrlHashParams,
} from './hashParamStateUtils';

const defaults = { method: 'replace' };

/**
 * useHashParamState stores its state in both a local useState hook *plus* it is written
 * out to the URL's fragment identifier or "hash" using the provided name and method
 *
 * This type of hook is needed because people share URLs.
 * It allows us to construct the scroll portion of state from just the URL.
 *
 * @param name
 * @param {any} [initialState]
 * @param {object} [options={method="replace"}]
 *    method - either "replace" or "push"
 * @returns [state, setState] Returns a stateful value, and a function to update it.
 *   During the initial render, the returned state (state) is the same as the value passed as the first argument (initialState).
 *   The setState function is used to update the state. It accepts a new state value and enqueues a re-render of the component.
 * @see {@link https://github.com/ReactTraining/history/blob/master/docs/Navigation.md}
 */
const useHashParamState = (name, initialState, options = {}) => {
  const [value, setValue] = useState(initialState);
  const history = useHistory();
  const location = useLocation();
  const nextLocationRef = useRef();
  const updateUrlRef = useRef();
  const setQueryParamValue = useCallback((value) => {
    setValue(value);
    updateUrlRef.current(value);
  }, []);

  options = { ...defaults, ...options };

  /**
   * Register listener
   *   With react-router the useLocation hook returns back the *current* location,
   *   Sometimes we need access to the *next* location *before* navigation occurs
   */
  useEffect(() => {
    const removeListener = history.listen((location) => {
      nextLocationRef.current = location;
    });

    return () => {
      removeListener();
    };
  }, [history]);

  /**
   * Read
   *   any URL hash params's and "set" the local value
   */
  useEffect(() => {
    const parsedValue = parse(location.hash, name);

    if (value !== parsedValue) {
      setValue(parsedValue);
    }
  }, [location.hash, name, value]);

  /**
   * Write
   *   (add or delete) the named state as a URL hash param
   *   The method is stored as ref so that it's content are always up to date
   *   with the current state shared between other instances of this hook
   */
  useEffect(() => {
    updateUrlRef.current = (param) => {
      // Is the caller still on the same path or have they clicked away?
      if (
        param !== undefined &&
        isPathnameStillCurrent(location, nextLocationRef?.current)
      ) {
        // Yes, same path, it is safe to store the param on the URL
        const { pathname, search } = location;
        const hash = getUpdatedUrlHashParams({
          location,
          name,
          nextLocation: nextLocationRef.current,
          param,
        });
        const to = { hash, pathname, search };

        if (wouldUrlBeDifferent(location, to)) {
          history[options.method](to);
        }
      }
    };
  }, [history, location, options.method, name]);

  /**
   * Cleanup
   *   This hook stores its state in two places; locally and in the URL
   *   remove any entries stored in the URL when it is destroyed
   */
  useEffect(() => {
    return () => {
      updateUrlRef.current();
    };
  }, []);

  return [value, setQueryParamValue];
};

export default useHashParamState;
