import ExpansionPanelTitle from 'components/common/ExpansionPanelTitle';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { profileSelector } from 'selectors/payer';
import { useSelector } from 'react-redux';
import Details from './Details';
import ProfileButtons from './ProfileButtons';

const useStyles = makeStyles((theme) => ({
  details: {
    color: theme.palette.text.primary,
  },
}));

const Profile = ({ fspId, id }) => {
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles();
  const item = useSelector((state) => profileSelector(state, id));

  const handleChange = (event, expanded) => {
    setExpanded(expanded);
  };

  return (
    <Accordion expanded={expanded} id="profile" onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <ExpansionPanelTitle
              expanded={expanded}
              Icon={item.payerIcon}
              title={item.label}
            />
          </Grid>
          <Grid item xs>
            <ProfileButtons expanded={expanded} fspId={fspId} id={id} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Details fspId={fspId} id={id} />
      </AccordionDetails>
    </Accordion>
  );
};

Profile.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default Profile;
