import { load } from 'model/fspFraudConfig';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const useDispatchLoadEffect = (fspId, shouldDispatch = true) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldDispatch) {
      dispatch(load(fspId));
    }
  }, [dispatch, fspId, shouldDispatch]);
};

export default useDispatchLoadEffect;
