import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { handleStopPropagation } from 'consts';
import AlertImprovedActions from './AlertImprovedActions';
import AlertImprovedProgress from './AlertImprovedProgress';
import useBusy from './useBusy';
import useContent from './useContent';

// See https://material.io/design/components/dialogs.html#anatomy
const AlertImproved = ({
  actionType,
  actionTypeSecond,
  cancelLabel,
  children,
  confirmActionType,
  confirmDisabled,
  confirmLabel,
  content: Content,
  onClose,
  onConfirm,
  open,
  title,
  ...props
}) => {
  const actionObj = { actionType, actionTypeSecond, confirmActionType };
  const dialogContent = useContent(Content);
  const { isBusy, isBusySecond, isConfirmActionBusy } = useBusy(actionObj);

  return (
    <Dialog
      aria-labelledby={title}
      fullWidth
      onClick={handleStopPropagation}
      onClose={onClose}
      open={open}
      {...props}
    >
      {open && title && <DialogTitle>{title}</DialogTitle>}
      {open && (dialogContent || children) && (
        <DialogContent>
          <AlertImprovedProgress
            visible={isBusy || isBusySecond || isConfirmActionBusy}
          />
          {dialogContent || children}
        </DialogContent>
      )}
      {open && (
        <AlertImprovedActions
          actionType={actionType}
          actionTypeSecond={actionTypeSecond}
          cancelLabel={cancelLabel}
          confirmDisabled={confirmDisabled}
          confirmLabel={confirmLabel}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
    </Dialog>
  );
};

AlertImproved.propTypes = {
  actionType: PropTypes.object,
  actionTypeSecond: PropTypes.object,
  cancelLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.object,
  ]),
  confirmActionType: PropTypes.object,
  confirmDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

AlertImproved.defaultProps = {
  actionType: { ACTION: 'empty' },
  actionTypeSecond: { ACTION: 'empty' },
  cancelLabel: 'Cancel',
  children: null,
  confirmActionType: { ACTION: 'empty' },
  confirmDisabled: false,
  confirmLabel: 'OK',
  content: null,
  title: null,
};

export default AlertImproved;
