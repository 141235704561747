import { LOAD } from 'reducers/achCode';
import { load } from 'sdk/achCode';
import { takeEvery } from 'redux-saga/effects';
import { callAction } from '../util';

export default function* saga() {
  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: 'achcode',
      type: LOAD,
    })
  );
}
