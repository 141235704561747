import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { anyPass, equals } from 'ramda';
import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  TRANSACTION_TYPE_BILL_PAY,
  PERSON_TO_PERSON,
  SCHEDULE,
  TRANSACTION_TYPE_PAL,
} from 'consts';
import {
  transactOnBehalfA2AOnSelector,
  transactOnBehalfBPOnSelector,
  transactOnBehalfP2POnSelector,
  transactOnBehalfPalOnSelector,
} from 'selectors/userFspConfig';
import { selectorId } from 'selectors/scheduledTransaction';
import TransactAsButton from 'components/modules/service/PayerDetail/TransactAsButton';
import ScheduledAudit from '../ScheduledAudit';

const ActionsRenderer = ({ item }) => {
  const { fspId, payerId, status, transactionType } = item;

  const transactA2AOn = useSelector((state) =>
    transactOnBehalfA2AOnSelector(state, fspId, payerId)
  );
  const transactBPOn = useSelector((state) =>
    transactOnBehalfBPOnSelector(state, item?.fspId, item?.payerId)
  );
  const transactP2POn = useSelector((state) =>
    transactOnBehalfP2POnSelector(state, item?.fspId, item?.payerId)
  );

  const transactPalOn = useSelector((state) =>
    transactOnBehalfPalOnSelector(state, item?.fspId, item?.payerId)
  );

  // Transact on behalf is not yet supported for internal transfer. https://payrailz.atlassian.net/browse/ON-1226
  const isA2A = [
    ACCOUNT_TO_ACCOUNT_INBOUND,
    ACCOUNT_TO_ACCOUNT_NEW,
    ACCOUNT_TO_ACCOUNT_OUTBOUND,
  ].includes(transactionType);

  const showTransactOnBehalf =
    equals(status, SCHEDULE) &&
    anyPass([
      () => isA2A && transactA2AOn,
      () => equals(TRANSACTION_TYPE_BILL_PAY, transactionType) && transactBPOn,
      () => equals(PERSON_TO_PERSON, transactionType) && transactP2POn,
      () => equals(TRANSACTION_TYPE_PAL, transactionType) && transactPalOn,
    ])();

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      {showTransactOnBehalf && (
        <Grid item>
          <TransactAsButton
            payerId={item.payerId}
            transactionId={item.id}
            loanId={item.loanDdaId}
            transactionType={transactionType}
            fspIdValue={fspId}
          />
        </Grid>
      )}
      <Grid item>
        <ScheduledAudit item={item} selectorId={selectorId} />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
