import PropTypes from 'prop-types';
import privilege from './general';

export const GroupFspType = PropTypes.oneOfType([
  PropTypes.shape({
    id: PropTypes.number,
    zsys: PropTypes.number.isRequired,
    ...privilege,
  }),
  PropTypes.shape({
    id: PropTypes.number,
    zchan: PropTypes.number.isRequired,
    ...privilege,
  }),
  PropTypes.shape({
    id: PropTypes.number,
    zdis: PropTypes.number.isRequired,
    ...privilege,
  }),
  PropTypes.shape({
    id: PropTypes.number,
    zfsp: PropTypes.number.isRequired,
    ...privilege,
  }),
]);

export const GroupFspsType = PropTypes.arrayOf(GroupFspType);

const GroupType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      ...privilege,
    })
  ),
  fsps: GroupFspsType,
  groupAdmins: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.number.isRequired,
      adminGroupId: PropTypes.number.isRequired,
      ...privilege,
    })
  ),
});

export default GroupType;
