import PropTypes from 'prop-types';
import { Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { handleStopPropagation } from 'consts';
import { useState } from 'react';
import PaymentResearchList from './PaymentResearchList';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const anchorOrigin = { horizontal: 'left', vertical: 'center' };
const transformOrigin = { horizontal: 'right', vertical: 'center' };

const PaymentResearchView = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        variant="outlined"
      >
        Payment Research
      </Button>
      {open && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          onClick={handleStopPropagation}
          onClose={handleClose}
          open
          transformOrigin={transformOrigin}
        >
          <PaymentResearchList id={id} />
        </Popover>
      )}
    </>
  );
};

PaymentResearchView.propTypes = {
  id: PropTypes.number.isRequired,
};

export default PaymentResearchView;
