import { useCallback, useState } from 'react';
import ExpansionList from './ExpansionList';

const ExpansionListByState = (props) => {
  const [expandedIds, setExpandedIds] = useState([]);

  const handleListItemChange = useCallback(
    (id, expanded) => {
      setExpandedIds(
        expanded
          ? [...expandedIds, id]
          : expandedIds.filter((existingId) => existingId !== id)
      );
    },
    [expandedIds]
  );

  return (
    <ExpansionList
      expandedIds={expandedIds}
      onListItemChange={handleListItemChange}
      {...props}
    />
  );
};

export default ExpansionListByState;
