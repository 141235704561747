import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { loadByModelId } from 'model/scheduledTransaction';
import { makeStyles } from '@material-ui/core/styles';
import { modelIdSelector } from 'selectors/scheduledTransaction';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PaymentSeriesTable from './PaymentSeriesTable';

const MAX = 100;

const useStyles = makeStyles(() => ({
  content: {
    height: 320,
    overflowY: 'auto',
    width: 560,
  },
  header: {
    paddingBottom: 0,
  },
}));

const PaymentSeriesContent = ({ forever, fspId, modelId, payerId }) => {
  const [subheader, setSubheader] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const items = useSelector((state) => modelIdSelector(state, modelId));

  useEffect(() => {
    const data = {
      alltrans: 'on',
      fspId,
      limit: forever ? 12 : MAX,
      modelId,
      payerId,
    };

    dispatch(loadByModelId(data));
  }, [dispatch, forever, fspId, modelId, payerId]);

  useEffect(() => {
    let message = 'More than 100 future payments...';

    if (forever) {
      message = `Series has no end date, showing the next ${
        items.length || '#'
      } payments`;
    } else if (items.length === 1) {
      message = 'Series will end on ...';
    } else if (items.length && items.length < MAX) {
      message = `Series will end on ${items[items.length - 1].sendOnDerived}`;
    }

    setSubheader(message);
  }, [forever, items]);

  return (
    <Card onClick={(event) => event.stopPropagation()}>
      <CardHeader
        classes={{ root: classes.header }}
        data-testid="cardHeader"
        subheader={subheader}
        title="Payment Series"
      />
      <CardContent className={classes.content}>
        <PaymentSeriesTable modelId={modelId} />
      </CardContent>
    </Card>
  );
};

PaymentSeriesContent.propTypes = {
  forever: PropTypes.bool.isRequired,
  fspId: PropTypes.number.isRequired,
  modelId: PropTypes.number.isRequired,
  payerId: PropTypes.number.isRequired,
};

export default PaymentSeriesContent;
