import {
  DEFAULT_MODEL_REF,
  DEFAULT_MODEL_REF_ARRAY,
  getSecondArgument,
} from 'consts';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import {
  addLimitRulesToLimits,
  byFSPIdAndType,
  byFSPIdPayerPrefix,
} from './utils/limit';

export const selector = createSelector(orm.Limit);

export const selectorFspId = createSelector(
  orm,
  getSecondArgument,
  ({ Limit }, fspId) =>
    Limit.filter(byFSPIdPayerPrefix(fspId)).toRefArray() ??
    DEFAULT_MODEL_REF_ARRAY
);

export const selectorByFSPIdWithLimitRules = createSelector(
  orm,
  getSecondArgument,
  ({ Limit, LimitRule }, fspId) =>
    addLimitRulesToLimits(Limit.filter(byFSPIdPayerPrefix(fspId)), LimitRule)
);

export const selectorProps = createSelector(
  orm,
  getSecondArgument,
  ({ Limit }, { fspId, type }) =>
    Limit.filter(byFSPIdAndType(fspId, type))?.first()?.ref ?? DEFAULT_MODEL_REF
);
