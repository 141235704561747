import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import { useFraudEnabled } from 'hooks/useFSPFraudConfig';

const FraudScore = ({ transaction }) => {
  const fraudEnabled = useFraudEnabled();

  return (
    transaction.fraudScore !== null &&
    fraudEnabled && (
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Fraud Score"
          value={transaction.fraudScore}
        />
      </Grid>
    )
  );
};

FraudScore.propTypes = {
  transaction: PropTypes.shape({
    fraudScore: PropTypes.number,
  }).isRequired,
};

export default FraudScore;
