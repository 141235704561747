import { Model, attr } from 'redux-orm';
import { createAction, createRequestConst, createUpdateConst } from '../util';

export default class FspCoreConfig extends Model {
  static get modelName() {
    return 'FspCoreConfig';
  }

  static reducer({ type, data }, FspCoreConfig) {
    switch (type) {
      case LOAD.SUCCESS:
      case UPDATE.SUCCESS:
        FspCoreConfig.upsert(data.response);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      coreProcessorId: attr(),
      createdAt: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      offset: attr(),
      pceConfig: attr(),
      pceOffOn: attr(),
      updAdminUserId: attr(),
      updUser: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = FspCoreConfig.modelName;

export const LOAD = createRequestConst(name);
export const UPDATE = createUpdateConst(name);

export const load = createAction(LOAD);
export const update = createAction(UPDATE);
