import { DialogTitle, Grid, Typography } from '@material-ui/core';
import ImportUploadButton from 'components/modules/implementation/Fsp/Import/ImportUploadButton';
import PropTypes from 'prop-types';

const ImportDialogTitle = ({ onImport, title }) => {
  return (
    <DialogTitle>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Import and Replace {title}</Typography>
        </Grid>
        <Grid item>
          <ImportUploadButton onSuccess={onImport} />
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

ImportDialogTitle.propTypes = {
  onImport: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ImportDialogTitle;
