import moment from 'moment';
import { SELECTED_ORG_ID_CODE } from 'consts';
import { nth, remove as removeR } from 'ramda';

const get = (name, id) => {
  return JSON.parse(window.localStorage[name] || '{}')[id] || [];
};

const save = (name, id, records) => {
  window.localStorage[name] = JSON.stringify({
    ...JSON.parse(window.localStorage[name] || '{}'),
    [id]: records,
  });
};

const getPosition = (needle, haystack, keyName) => {
  return haystack.findIndex((straw) => straw[keyName] === needle[keyName]);
};

const remove = (record, name, id, keyName) => {
  let objectRemoved = {};
  const storedRecords = get(name, id);
  const pos = getPosition(record, storedRecords, keyName);

  if (pos !== -1) {
    objectRemoved = nth(pos, storedRecords);
    save(name, id, removeR(pos, 1, storedRecords));
  }

  return objectRemoved;
};

export default {
  get,
  add: (record, name, id, keyName) => {
    remove(record, name, id, keyName);
    const isObject = typeof record === 'object';
    const storedRecords = get(name, id);

    if (isObject && name !== SELECTED_ORG_ID_CODE) {
      record.id = new Date().valueOf();
      record.lastViewed = moment().toISOString();
    }

    if (storedRecords.length > 9) {
      storedRecords.pop();
    }

    save(name, id, [record, ...storedRecords]);

    return record;
  },
  clear: (name, id) => {
    save(name, id, []);
  },
  remove,
  upsert: (record, name, id, keyName, updateLastViewed = true) => {
    const storedRecords = get(name, id);
    const pos = getPosition(record, storedRecords, keyName);

    if (pos === -1) {
      // create
      record.id = new Date().valueOf();
      record.lastViewed = moment().toISOString();

      if (storedRecords.length > 9) {
        storedRecords.pop();
      }

      save(name, id, [record, ...storedRecords]);

      return record;
    }
    // update
    const lastViewed = updateLastViewed
      ? moment().toISOString()
      : record?.lastViewed
      ? record?.lastViewed
      : moment().toISOString();
    const updated = {
      ...storedRecords[pos],
      ...record,
      lastViewed,
    };
    storedRecords[pos] = updated;
    save(name, id, storedRecords);

    return updated;
  },
};
