import { attr, fk, Model } from 'redux-orm';
import { isNil } from 'ramda';
import moment from 'moment';
import Payee from 'model/payee';
import Payer from 'model/payer';
import { createAction, createLoadConst, formatCurrency } from '../util';

const payAmount = {
  fullBal: 'Full Balance',
  minAmt: 'Min Amount Due',
  twiceMinAmt: 'Twice Min Amount',
};

const payWhen = (autopay) => {
  const daysPriorDue = autopay?.daysPriorDue;

  return {
    onBillArrived: 'When Bill Arrives',
    onDueDate: 'On Due Date',
    onFixedDay: `On Day ${autopay?.dayOfMonth} of Each Month`,
    priorDueDate: `${daysPriorDue} ${
      daysPriorDue > 1 ? 'Days' : 'Day'
    } before Due Date`,
  };
};

export const derived = (autopay) => {
  const suspendUntil = moment(autopay.suspendUntilOn);
  const statusDerived =
    !isNil(suspendUntil) && moment().diff(suspendUntil) < 0
      ? `Suspended (Until ${suspendUntil.format('MM-DD-YYYY')})`
      : 'Enabled';
  const palStatusDerived =
    !isNil(suspendUntil) && moment().diff(suspendUntil) < 0
      ? `Suspended (Until ${suspendUntil.format('MM-DD-YYYY')})`
      : 'Enabled';
  const billGreaterThanAmountDerived = autopay.billGreaterThanAmount
    ? formatCurrency(autopay.billGreaterThanAmount)
    : '';
  const billGreaterThanCustomAmountDerived = autopay.billGreaterThanCustomAmount
    ? formatCurrency(autopay.billGreaterThanCustomAmount)
    : '';

  return {
    ...autopay,
    billGreaterThanAmountDerived,
    billGreaterThanCustomAmountDerived,
    payAmountDerived: payAmount[autopay.payAmount],
    payWhenDerived: payWhen(autopay)[autopay.payWhen],
    statusDerived,
    palStatusDerived,
  };
};

export default class Autopay extends Model {
  static get modelName() {
    return 'Autopay';
  }

  static reducer({ type, data }, Autopay) {
    switch (type) {
      case LOAD_PAYEE_AUTOPAY.SUCCESS:
      case LOAD_ACCOUNT_AUTOPAY.SUCCESS:
        (data?.response || []).forEach((autopay) => {
          Autopay.upsert(derived(autopay));
        });
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      billGreaterThanAmount: attr(),
      billGreaterThanCustomAmount: attr(),
      billGreaterThanNotPayOffOn: attr(),
      confirmationNumber: attr(),
      createdAt: attr(),
      dayOfMonth: attr(),
      daysPriorDue: attr(),
      ddaId: attr(),
      description: attr(),
      externalDdaId: attr(),
      foreverOffOn: attr(),
      frequency: attr(),
      fromOneOf: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insertedAt: attr(),
      insUserId: attr(),
      lastUpdatedAt: attr(),
      nextDeliverOn: attr(),
      nextSendOn: attr(),
      offset: attr(),
      payAmount: attr(),
      payAmountDerived: attr(),
      payWhen: attr(),
      payWhenDerived: attr(),
      payeeId: fk(Payee.modelName, 'autopay'),
      payerId: fk(Payer.modelName, 'autopays'),
      scheduleEndOn: attr(),
      suspendUntilOn: attr(),
      startOn: attr(),
      statusDerived: attr(),
      termsAndConditionsAccepted: attr(),
      times: attr(),
      updAdminUserId: attr(),
      updUserId: attr(),
      updatedAt: attr(),
    };
  }
}

const name = Autopay.modelName;

export const LOAD_PAYEE_AUTOPAY = createLoadConst(`${name}_payee_autopay`);
export const LOAD_ACCOUNT_AUTOPAY = createLoadConst(`${name}_account_autopay`);

export const loadPayeeAutopay = createAction(LOAD_PAYEE_AUTOPAY);
export const loadAccountAutopay = createAction(LOAD_ACCOUNT_AUTOPAY);
