import { attr, Model } from 'redux-orm';
import { addDerived } from './util/ddaMask';
import { createAction, createLoadConst } from '../util';

export default class DDAMask extends Model {
  static get modelName() {
    return 'DDAMask';
  }

  static reducer({ type, data }, DDAMask) {
    const { fspId, type: ddaMaskType } = data?.props ?? {};
    switch (type) {
      case LOAD.SUCCESS: {
        DDAMask.filter(
          ({ fspIdDerived, maskTypeDerived }) =>
            fspIdDerived === fspId && maskTypeDerived === ddaMaskType
        ).delete();
        (data?.response || []).forEach((ddaMask) => {
          DDAMask.upsert(addDerived(ddaMask, ddaMaskType, fspId));
        });
        break;
      }

      case LOAD.FAILURE:
        // Just deleting the records where fetching failed
        DDAMask.filter(
          ({ fspIdDerived, maskTypeDerived }) =>
            fspIdDerived === fspId && maskTypeDerived === ddaMaskType
        ).delete();
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      customFspId: attr(),
      customOffOn: attr(),
      ddaMaskName: attr(),
      ddaMaskSample: attr(),
      ddaMaskType: attr(),
      description: attr(),
      fspIdDerived: attr(), // derived
      href: attr(),
      id: attr(),
      insAdminUserId: attr(),
      insUser: attr(),
      insUserId: attr(),
      maskTypeDerived: attr(), // derived
      offset: attr(),
      updAdminUserId: attr(),
      updatedAt: attr(),
      updUser: attr(),
      updUserId: attr(),
    };
  }
}

const name = DDAMask.modelName;

export const LOAD = createLoadConst(name);

export const load = createAction(LOAD);
