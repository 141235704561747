import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  compose as rCompose,
  equals,
  filter,
  find,
  flip,
  includes,
  isNil,
  map,
  prop,
  propEq,
  always,
} from 'ramda';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select as MUISelect,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const getValue = (isMulti, value) =>
  isMulti ? (Array.isArray(value) ? value : []) : value || '';
const isEmpty = (value) => value === [] || value === '';

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: theme.palette.text.disabled,
  },
  root: {},
}));

const Select = ({
  data,
  disabled,
  displayAttr,
  error,
  field,
  fullWidth,
  helperText,
  id,
  label,
  name,
  onChange,
  placeholder,
  readOnly,
  renderer,
  selectOnly,
  stopPropagation,
  type,
  valueAttr,
  value,
  open,
  onClose,
  onOpen,
  ...props
}) => {
  const isMulti = equals(type, 'multiple');
  const newValue = value || field?.value;
  const classes = useStyles(props);
  const [selectedValue, setSelectedValue] = useState(
    getValue(isMulti, newValue)
  );

  const getDisplay = (item) => {
    return isNil(item)
      ? ''
      : displayAttr
      ? item[displayAttr]
      : renderer
      ? renderer(item)
      : '';
  };
  const handleChange = (event) => {
    if (stopPropagation) event.stopPropagation();
    if (onChange) onChange(event);
    if (field?.onChange) {
      field.onChange(event);
    }

    setSelectedValue(event.target.value);
  };
  const handleClick = (event) => {
    if (stopPropagation) event.stopPropagation();
  };
  const renderDisplay = (selected) => {
    return selected
      ? isMulti
        ? map(
            getDisplay,
            filter(rCompose(flip(includes)(selected), prop(valueAttr)), data)
          ).join(', ')
        : getDisplay(find(propEq(valueAttr, selected), data))
      : '';
  };

  useEffect(() => {
    const value = getValue(isMulti, newValue);

    // equals is necessary to support multi default value
    // which is an array
    if (!equals(value, selectedValue)) {
      setSelectedValue(value);
    }
  }, [isMulti, newValue, selectedValue]);

  return readOnly || (data?.length === 1 && !selectOnly) ? (
    <span
      className={classNames({
        [classes.disabled]: disabled,
      })}
    >
      {renderDisplay(
        !selectedValue || isEmpty(selectedValue)
          ? Array.isArray(data) && data.length === 1
            ? data[0][valueAttr]
            : null
          : selectedValue
      )}
    </span>
  ) : (
    <FormControl
      classes={{ root: classes.root }}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      t-i="selectInput"
    >
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <MUISelect
        {...field}
        displayEmpty={placeholder && isEmpty(selectedValue)}
        input={<Input id={id} name={name} />}
        multiple={isMulti}
        onChange={handleChange}
        onClick={handleClick}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        renderValue={renderDisplay}
        SelectDisplayProps={{
          'aria-label': label,
          'data-testid': `${name || id}-open`,
        }}
        value={selectedValue}
      >
        {placeholder && (
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {(data || []).map((item) => {
          const primary = getDisplay(item);

          return item[valueAttr] === '' ? (
            <MenuItem key={primary} value="">
              <em>{getDisplay(item)}</em>
            </MenuItem>
          ) : (
            <MenuItem
              key={primary}
              data-testid={`${id}-${item[valueAttr]}`}
              value={item[valueAttr]}
            >
              {isMulti && (
                <Checkbox
                  checked={selectedValue.indexOf(item[valueAttr]) > -1}
                  color="primary"
                />
              )}
              <ListItemText primary={primary} />
            </MenuItem>
          );
        })}
      </MUISelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Select.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  displayAttr: PropTypes.string,
  error: PropTypes.bool,
  field: PropTypes.object,
  fullWidth: PropTypes.bool,
  handleClick: PropTypes.func,
  helperText: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  renderer: PropTypes.func,
  selectOnly: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    PropTypes.string,
  ]),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  valueAttr: PropTypes.string,
};

Select.defaultProps = {
  data: [],
  disabled: false,
  displayAttr: '',
  error: false,
  field: undefined,
  fullWidth: false,
  handleClick: always,
  helperText: null,
  id: null,
  label: null,
  name: null,
  onChange: null,
  open: undefined,
  onClose: undefined,
  onOpen: undefined,
  placeholder: null,
  readOnly: false,
  renderer: undefined,
  selectOnly: false,
  stopPropagation: false,
  type: '',
  value: null,
  valueAttr: null,
};

export default Select;
