import PropTypes from 'prop-types';
import RadioGroup from 'components/common/fields/radio_group';
import { FSP_CODE } from 'consts';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import {
  SCOPE_LEVEL_SEPARATOR,
  scopeTypesRadioOptions,
} from 'components/modules/implementation/ApiKey/consts';
import useAutocompleteComponent from '../hooks/useAutocompleteComponent';
import ApiKeyTextInput from '../ApiKeyTextInput';
import { options } from '../../../../consts';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 440,
  },
  formGroup: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(1),
  },
}));

export const getScopeData = (value) => {
  if (!value) {
    return [FSP_CODE, ''];
  }
  const items = value.split('_');
  if (items[0] === FSP_CODE) {
    return [FSP_CODE, items[1]];
  }
  return [items[0].slice(0, -1), items[1]];
};

const ChipScopes = ({ onChange, name, chipValue, ...props }) => {
  const [initType, initValue] = getScopeData(chipValue);
  const [value, setValue] = useState(initValue);
  const [scopeType, setScopeType] = useState(initType);
  const buildScopeId = `${scopeType}${SCOPE_LEVEL_SEPARATOR[scopeType]}`;
  const label = options[name]?.label || 'Type';
  const classes = useStyles();

  const handleChange = (event, value) => {
    setValue(value?.id ? value.id : '');
    onChange(value?.id ? `${buildScopeId}${value.id}` : undefined);
  };

  const handleChangeScopeTypeChange = (type) => {
    setScopeType(type);
    // Clear previous selected id
    setValue('');
  };

  const AutocompleteComponent = useAutocompleteComponent({
    type: scopeType,
    onChange: handleChange,
    value,
    ...props,
  });

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup
        classes={{
          formGroup: classes.formGroup,
        }}
        label={label}
        onChange={handleChangeScopeTypeChange}
        radios={scopeTypesRadioOptions}
        value={scopeType}
      />
      {AutocompleteComponent}
      <ApiKeyTextInput
        disabled
        fullWidth
        label="Scope"
        name="scopeLevel"
        value={`${buildScopeId}${value}`}
        variant="outlined"
      />
    </FormControl>
  );
};

ChipScopes.propTypes = {
  onChange: PropTypes.func.isRequired,
  chipValue: PropTypes.string,
  name: PropTypes.string.isRequired,
};

ChipScopes.defaultProps = {
  chipValue: '',
};

export default ChipScopes;
