import PropTypes from 'prop-types';
import { createContext, useContext, useMemo } from 'react';
import {
  selectorByServiceStart,
  selectorRuleExpired,
} from 'selectors/limitRule';
import { selectorProps } from 'selectors/limit';
import { useSelector } from 'react-redux';

const Context = createContext(undefined);

const LimitsProvider = ({ fspId, children, limitType, payerId }) => {
  const expired = useSelector((state) =>
    selectorRuleExpired(state, { limitType, payerId })
  );
  const limit = useSelector(
    (state) => selectorProps(state, { fspId, type: limitType }) || {}
  );
  const limitRule = useSelector((state) =>
    selectorByServiceStart(state, payerId)
  );
  const value = useMemo(
    () => ({
      expired,
      fspId,
      limit,
      limitRule,
      limitType,
      payerId,
    }),
    [expired, fspId, limit, limitRule, limitType, payerId]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

LimitsProvider.propTypes = {
  fspId: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  limitType: PropTypes.string.isRequired,
  payerId: PropTypes.number.isRequired,
};

const useLimits = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useLimits must be used within a LimitsProvider');
  }

  return context;
};

export { LimitsProvider, useLimits };
