import PropTypes from 'prop-types';
import { memo } from 'react';
import { TableHead as MuiTableHead, TableRow } from '@material-ui/core';
import TableHeadCheckbox from './TableHeadCheckbox';
import TableHeadColumns from './TableHeadColumns';

const TableHead = ({
  checkAllAction,
  checkAllActionProps,
  hasPagination,
  columns,
  selector,
  selectorProps,
  ...props
}) => {
  return (
    <MuiTableHead>
      <TableRow>
        <TableHeadCheckbox
          checkAllAction={checkAllAction}
          checkAllActionProps={checkAllActionProps}
          hasPagination={hasPagination}
          selector={selector}
          selectorProps={selectorProps}
        />
        <TableHeadColumns
          columns={columns}
          hasPagination={hasPagination}
          {...props}
        />
      </TableRow>
    </MuiTableHead>
  );
};

export const propTypes = {
  checkAllAction: PropTypes.func,
  checkAllActionProps: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultPageSettings: PropTypes.object.isRequired,
  hasPagination: PropTypes.bool,
  pageSettingsName: PropTypes.string.isRequired,
  payerId: PropTypes.number,
  selector: PropTypes.func.isRequired,
  selectorProps: PropTypes.object,
};

TableHead.propTypes = propTypes;

TableHead.defaultProps = {
  checkAllAction: undefined,
  checkAllActionProps: {},
  hasPagination: true,
  payerId: 0,
  selectorProps: undefined,
};

export default memo(TableHead);
