/**
 * 08/22/2022
 * https://payrailz.atlassian.net/browse/ON-1659
 */
// eslint-disable-next-line import/prefer-default-export
export const resultReasons = [
  {
    id: 'cancel_based_on_score',
    name: 'Canceled Based on Score',
    visible: true,
    value: 'cancel_based_on_score',
    tooltip:
      'These are automatically canceled by the fraud engine based on the score threshold configured by your financial institution',
  },
  {
    id: 'cancel_batch',
    name: 'Canceled Outside Manual Review Batch',
    visible: true,
    value: 'cancel_batch',
    tooltip:
      'These are canceled based on rule threshold score during time when the transaction occurs outside the manual review window',
  },
  {
    id: 'cancel_max_minutes',
    name: 'Canceled Outside Manual Review Max Minutes',
    visible: true,
    value: 'cancel_max_minutes',
    tooltip:
      'These are canceled based on rule threshold score during time when the transaction occurs outside the manual review window',
  },
  {
    id: 'cancel_ontrac',
    name: 'Canceled By Fraud Manager',
    visible: true,
    value: 'cancel_ontrac',
    tooltip: 'These are canceled manually in Ontrac by a fraud manager',
  },
  {
    id: 'cancel_based_on_denylist',
    name: 'Canceled Based on Deny List',
    visible: true,
    value: 'cancel_based_on_denylist',
    tooltip: 'These are canceled by the fraud engine based on the deny list',
  },
  {
    id: 'schedule_based_on_override',
    name: 'Scheduled Based On Override',
    visible: true,
    value: 'schedule_based_on_override',
    tooltip:
      'These are automatically allowed by the fraud engine based on the override configured by your financial institution',
  },
  {
    id: 'schedule_based_on_score',
    name: 'Scheduled Based On Score',
    visible: true,
    value: 'schedule_based_on_score',
    tooltip:
      'These are automatically allowed by the fraud engine based on the score threshold configured by your financial institution',
  },
  {
    id: 'schedule_batch',
    name: 'Scheduled Outside Manual Review Batch',
    visible: true,
    value: 'schedule_batch',
    tooltip:
      'These are allowed based on rule threshold score during time when the transaction occurs outside the manual review window',
  },
  {
    id: 'schedule_max_minutes',
    name: 'Scheduled Outside Manual Review Max Minutes',
    visible: true,
    value: 'schedule_max_minutes',
    tooltip:
      'These are allowed based on rule threshold score during time when the transaction occurs outside the manual review window',
  },
  {
    id: 'schedule_ontrac',
    name: 'Scheduled By Fraud Manager',
    visible: true,
    value: 'schedule_ontrac',
    tooltip: 'These are allowed manually in Ontrac by a fraud manager',
  },
];
