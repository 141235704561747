import {
  Block,
  Payment,
  Person,
  SwapHoriz,
  EmojiTransportation,
} from '@material-ui/icons';
import {
  ACCOUNT_TO_ACCOUNT_INBOUND,
  ACCOUNT_TO_ACCOUNT_NEW,
  ACCOUNT_TO_ACCOUNT_OUTBOUND,
  TRANSACTION_TYPE_BILL_PAY,
  BILL_PAYMENT,
  PERSON_PAY,
  PERSON_TO_PERSON,
  TRANSFER_MONEY,
  UNKNOWN,
  VENMO,
  ACCOUNT_TO_ACCOUNT_INTERNAL,
  PAY_A_LOAN_LABEL,
  TRANSACTION_TYPE_PAL_ESCROW,
  TRANSACTION_TYPE_PAL_INTEREST,
  TRANSACTION_TYPE_PAL_PRINCIPAL,
  TRANSACTION_TYPE_PAL,
} from 'consts';
import { isOn } from 'util/index';
import Venmo from './VenmoIcon';

const a2a = { Icon: SwapHoriz, title: TRANSFER_MONEY };
const bp = { Icon: Payment, title: BILL_PAYMENT };
const pal = { Icon: EmojiTransportation, title: PAY_A_LOAN_LABEL };
const p2p = { Icon: Person, title: PERSON_PAY };
const unknown = { Icon: Block, title: UNKNOWN };
const venmo = { Icon: Venmo, title: VENMO };

// eslint-disable-next-line import/prefer-default-export
export const getIconAndTitle = (disburseType, p2pDirectOffOn, type) => {
  switch (type) {
    case ACCOUNT_TO_ACCOUNT_INBOUND:
    case ACCOUNT_TO_ACCOUNT_INTERNAL:
    case ACCOUNT_TO_ACCOUNT_NEW:
    case ACCOUNT_TO_ACCOUNT_OUTBOUND:
      return a2a;

    case TRANSACTION_TYPE_BILL_PAY:
      return bp;

    case TRANSACTION_TYPE_PAL:
    case TRANSACTION_TYPE_PAL_ESCROW.toLowerCase():
    case TRANSACTION_TYPE_PAL_INTEREST.toLowerCase():
    case TRANSACTION_TYPE_PAL_PRINCIPAL.toLowerCase():
      return pal;

    case PERSON_TO_PERSON:
      return disburseType && isOn(p2pDirectOffOn) ? venmo : p2p;

    default:
      return unknown;
  }
};
