import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { isNilOrEmpty } from 'util/index';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  address: {
    color: 'inherit',
    font: 'inherit',
    fontFamily: theme.typography.fontFamily,
    paddingTop: 6,
  },
  rootMarginTop: {
    marginTop: theme.spacing(1),
  },
}));

const DetailsRenderAddress = ({ address1, address2, fullAddress }) => {
  const classes = useStyles();

  const emptyAddress = isNilOrEmpty(address1) && isNilOrEmpty(address2);

  return (
    <Paper
      classes={{ root: classes.rootMarginTop }}
      className={classes.address}
      elevation={0}
    >
      {emptyAddress ? (
        <div>{fullAddress}</div>
      ) : (
        <>
          <div>{address1}</div>
          <div>{address2}</div>
        </>
      )}
    </Paper>
  );
};

DetailsRenderAddress.propTypes = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  fullAddress: PropTypes.string.isRequired,
};

export default DetailsRenderAddress;
