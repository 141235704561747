import { Model } from 'redux-orm';
import { isEmpty, mergeRight, omit } from 'ramda';

export default class BaseModel extends Model {
  get isEmpty() {
    return isEmpty(omit(['id'], super.ref));
  }

  get ref() {
    return mergeRight(super.ref, { isEmpty: this.isEmpty });
  }
}
