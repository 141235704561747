import { PAYRAILZ_ADMIN_ORG_ID } from './index';
import { client, formatQueryParams } from '../util/sdk';

export default (params, auth) =>
  client.reportTypes.query(
    formatQueryParams({
      ...params,
      adminUserId: auth.id,
      adminOrgId: params.fspId ? undefined : PAYRAILZ_ADMIN_ORG_ID,
    })
  );
