import { PERSON } from 'consts';
import { decryptPayerSSN } from 'sdk/payer';
import {
  businessColumnMeta,
  businessSearchFields,
  personColumnMeta,
  personSearchFields,
} from './config/payers';

export const formatPayerTypeSearchBy = (payerType) => ({
  field: 'type',
  operator: 'field',
  hide: true,
  value: payerType,
});

export const getColumnMeta = ({ auth, fspId, payerType }) => {
  return payerType === PERSON
    ? personColumnMeta(fspId, decryptPayerSSN, auth)
    : businessColumnMeta(fspId, decryptPayerSSN, auth);
};

export const getSearchFields = (payerType) => {
  return payerType === PERSON ? personSearchFields : businessSearchFields;
};
