import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextFieldNumber as CommonTextFieldNumber } from '../../fields';

const TextFieldNumber = ({ name, ...props }) => {
  const [field] = useField(name);
  return <CommonTextFieldNumber {...props} {...field} TextField={TextField} />;
};

TextFieldNumber.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TextFieldNumber;
