import { lensPath, set, view } from 'ramda';
import { endsWithLoadSuccess } from 'util/actions';

const getDataLens = (action) =>
  lensPath(action.data?.response ? ['data', 'response'] : ['data']);
const setHasMoreItems = (action, moreItems) =>
  set(lensPath(['meta', 'hasMoreItems']), moreItems, action);
const setData = (action, data) => set(getDataLens(action), data, action);
const getData = (action) => view(getDataLens(action), action);

const hasMoreItems = (action) =>
  getData(action).length === action.reduxActionData.limit;

const comesFromPaginationRequest = (action) =>
  action?.reduxActionMeta?.pageSettingsName;
const isPaginationResponseAction = (action) =>
  endsWithLoadSuccess(action.type) && comesFromPaginationRequest(action);

const processPaginationMiddleware = () => (next) => (action) => {
  if (isPaginationResponseAction(action)) {
    const moreItems = hasMoreItems(action);
    const data = getData(action);

    const newData = moreItems ? data.slice(0, -1) : data;
    const newAction = setHasMoreItems(setData(action, newData), moreItems);

    return next(newAction);
  }
  return next(action);
};

export default processPaginationMiddleware;
