import PropTypes from 'prop-types';
import { useEffect } from 'react';
import srcDoc from 'srcdoc-polyfill';
import { CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  content: {
    height: 330,
    width: 600,
  },
  iframe: {
    border: 0,
    height: 300,
    width: 570,
  },
}));

const NotificationCardContent = ({ isEmail, message }) => {
  const classes = useStyles();

  useEffect(() => {
    const iframes = document.getElementsByTagName('iframe');
    let n = iframes.length;
    // eslint-disable-next-line no-plusplus
    while (n--) srcDoc.set(iframes[n]);
  }, [isEmail, message]);

  return (
    <CardContent className={classes.content}>
      {isEmail ? (
        <iframe className={classes.iframe} srcDoc={message} title="Message" />
      ) : (
        <Typography variant="h6">{message}</Typography>
      )}
    </CardContent>
  );
};

NotificationCardContent.propTypes = {
  isEmail: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default NotificationCardContent;
