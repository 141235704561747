import { DEFAULT_MODEL_REF } from 'consts';
import { createSelector } from 'redux-orm';
import { orm } from 'model';

export const selector = createSelector(orm.PayALoan);

export const selectorId = createSelector(
  orm,
  (state, props) => props,
  ({ PayALoan }, { id, ddaId }) =>
    PayALoan.idExists(ddaId)
      ? PayALoan.withId(ddaId)
          .filter((itemsById) => itemsById.ddaId === ddaId)
          .first()?.ref ?? DEFAULT_MODEL_REF
      : DEFAULT_MODEL_REF
);

export const selectorCount = createSelector(orm, ({ PayALoan }) =>
  PayALoan.count()
);
