import {
  ADD,
  DELETE,
  FORM_NAME,
  LOAD,
  THROTTLED_LOAD,
  UPDATE,
} from 'model/org';
import { addOrg, deleteOrg, load, updateOrg } from 'sdk/org';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { callAction, ORM } from '../util';

export const updateOrgCall = (formName, formatSuccessMessage) =>
  callAction({
    api: updateOrg,
    formName,
    formatSuccessMessage,
    type: UPDATE,
  });

export default function* saga() {
  yield takeEvery(
    ADD.ACTION,
    callAction({
      api: addOrg,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.name} organization was successfully created`,
      type: ADD,
    })
  );

  yield takeEvery(
    DELETE.ACTION,
    callAction({
      api: deleteOrg,
      formName: FORM_NAME,
      formatSuccessMessage: (data) =>
        `${data.name} organization was successfully removed`,
      type: DELETE,
    })
  );

  yield takeEvery(
    LOAD.ACTION,
    callAction({
      api: load,
      dataName: 'adminOrg',
      type: LOAD,
    })
  );

  yield takeLatest(
    THROTTLED_LOAD.ACTION,
    callAction({
      api: load,
      dataName: 'adminOrg',
      type: THROTTLED_LOAD,
    })
  );

  yield takeEvery(
    UPDATE.ACTION,
    updateOrgCall(
      FORM_NAME,
      (data) => `${data.name} organization was successfully updated`
    )
  );
}
