import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ReadOnlyFormControl } from 'components/common/fields';
import { isDisburseTypeDebit } from '../consts';

const CardInfo = ({ transaction }) => {
  return isDisburseTypeDebit(transaction.disburseType) ? (
    <>
      <Grid item xs={1}>
        <ReadOnlyFormControl
          label="Card Type"
          multiline
          value={transaction.dcType}
        />
      </Grid>
      <Grid item xs={5}>
        <ReadOnlyFormControl
          label="Card Holder Name"
          multiline
          value={transaction.dcHolderName}
        />
      </Grid>
      <Grid item xs={2}>
        <ReadOnlyFormControl
          label="Card Mask"
          multiline
          value={transaction.dcNumberMask}
        />
      </Grid>
    </>
  ) : null;
};

CardInfo.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default CardInfo;
