import { defaultPayerId } from 'model/pageSetting';
import { selector } from 'selectors/pageSetting';
import { shallowEqual, useSelector } from 'react-redux';

const usePageSettings = (
  name = '',
  payerId = defaultPayerId,
  defaultPageSettings = {}
) => {
  return useSelector(
    (state) => selector(state, payerId, name, defaultPageSettings),
    shallowEqual
  );
};

export default usePageSettings;
