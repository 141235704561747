import Popover from 'components/common/Popover';
import PropTypes from 'prop-types';
import { always } from 'ramda';
import { useOpen } from 'hooks';
import Card from './Card';

const PopoverCard = ({ anchorEl, onClose, open, ...props }) => {
  const [openComplete, toggle] = useOpen(open);

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      TransitionProps={{
        onEntered: toggle,
        onExited: toggle,
      }}
    >
      <Card {...props} openComplete={openComplete} />
    </Popover>
  );
};

PopoverCard.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

PopoverCard.defaultProps = {
  anchorEl: null,
  onClose: always,
  open: false,
};

export default PopoverCard;
