import MaskPopover from 'components/common/MaskPopover';
import PropTypes from 'prop-types';
import { decryptRDFIAccountNumber } from 'sdk/transactionHistory';
import { head } from 'ramda';

const NO_VALUE = 'No RDFI Account Number';
const anchorOrigin = { horizontal: 'right', vertical: 'center' };
const transformOrigin = { vertical: 'center', horizontal: 'right' };
const PopoverProps = { anchorOrigin, transformOrigin };

const RDFInfoAccountNumberMask = ({ fspId, id, isFIDirect, value }) => {
  const handleApi = () => {
    return decryptRDFIAccountNumber({ fspId, id });
  };
  const handleFormat = (value) => {
    return (
      head(value)?.[isFIDirect ? 'coreAccountNumber' : 'extDdaAccountNbr'] ??
      NO_VALUE
    );
  };

  return (
    <MaskPopover
      api={handleApi}
      format={handleFormat}
      icon
      PopoverProps={PopoverProps}
      value={value}
    />
  );
};

RDFInfoAccountNumberMask.propTypes = {
  fspId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isFIDirect: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

RDFInfoAccountNumberMask.defaultProps = {
  value: '',
};

export default RDFInfoAccountNumberMask;
