import authTokenSelector from 'selectors/authToken';
import { useEffect } from 'react';
import {
  CardContent,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  OTP_ACCT_LOCKED_ERROR,
  OTP_COMPARE_WRONG_VALUE,
  OTP_EXPIRED_CODE,
  OTP_INCORRECT_CODE,
  OTP_MAX_TRIES_ERROR,
  OTP_REQUIRED_ERROR,
} from 'consts';
import { useSelector } from 'react-redux';
import Digits from './Digits';
import {
  clear,
  updateActivePosition,
  useOneTimePasscode,
} from './OneTimePasscodeContext';

const getErrorMessage = ({ number }, { previousPasscode }) => {
  switch (number) {
    case OTP_ACCT_LOCKED_ERROR:
      return 'Your account has been locked. Please contact the administrator to unlock your account.';

    case OTP_REQUIRED_ERROR:
      // otp required, nothing to do
      return '';

    case OTP_COMPARE_WRONG_VALUE:
      // send otp compares the wrong value
      return '';

    case OTP_EXPIRED_CODE:
    case OTP_MAX_TRIES_ERROR:
      return 'That code is now expired. Request a new code.';

    case OTP_INCORRECT_CODE:
      return `${previousPasscode ?? 'Code'} was not the correct code`;

    default:
      return '';
  }
};

const useStyles = makeStyles((theme) => ({
  formHelperTextRoot: {
    fontSize: theme.typography.fontSize + 2,
  },
  placeholder: {
    height: 23,
    marginBottom: 0,
    marginTop: 3,
  },
}));

const OneTimePasscodeContent = () => {
  const authToken = useSelector(authTokenSelector);
  const classes = useStyles();
  const { dispatch, state } = useOneTimePasscode();

  const errorMessage = getErrorMessage(authToken.error ?? {}, state);
  const error = errorMessage.length !== 0;

  const handleClickAway = () => {
    dispatch(updateActivePosition(-1));
  };

  useEffect(() => {
    if (error) {
      dispatch(clear());
    }
  }, [authToken, dispatch, error]); // do not remove authToken!

  return (
    <CardContent>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid container justifyContent="center">
          <Grid item>
            <FormControl>
              <Digits />
              {error ? (
                <FormHelperText
                  classes={{ root: classes.formHelperTextRoot }}
                  error
                >
                  {errorMessage}
                </FormHelperText>
              ) : (
                <p className={classes.placeholder} />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </ClickAwayListener>
    </CardContent>
  );
};

export default OneTimePasscodeContent;
