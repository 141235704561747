import { client } from 'util/sdk';
import { GET } from 'util/api';

const returnModel = { name: 'disbursetype' };

// eslint-disable-next-line import/prefer-default-export
export const load = () =>
  client.executeRequest(GET, `/disbursetypes`, {
    returnModel,
  });
