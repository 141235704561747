import PropTypes from 'prop-types';
import classNames from 'classnames';
import { branch, renderNothing } from 'recompose';
import { Grid } from '@material-ui/core';
import { useExpansionListItemColumn } from './ExpansionListItemColumnContext';

const ExpansionListItemRow = ({ capitalize, xs }) => {
  const { classes, detail, rendererData } = useExpansionListItemColumn();

  return (
    <Grid
      className={classNames(classes.label, {
        [classes.capitalize]: capitalize,
      })}
      item
      xs={xs}
      zeroMinWidth
    >
      {rendererData || detail}
    </Grid>
  );
};

ExpansionListItemRow.propTypes = {
  capitalize: PropTypes.bool,
  xs: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

ExpansionListItemRow.defaultProps = {
  capitalize: false,
  xs: false,
};

export default branch(
  ({ visible }) => visible,
  renderNothing
)(ExpansionListItemRow);
