import RadioGroup from 'components/common/fields/radio_group';
import { NOC, NOR } from '../consts';
import StepChangeTypeStep from './StepChangeTypeLabel';

const changeTypes = [
  {
    value: NOC,
    label: (
      <StepChangeTypeStep description="Notification of Change" label="NOC" />
    ),
  },
  {
    value: NOR,
    label: (
      <StepChangeTypeStep description="Notification of Return" label="NOR" />
    ),
  },
];

const StepChangeType = (props) => {
  return <RadioGroup name="changeType" radios={changeTypes} {...props} />;
};

export default StepChangeType;
