import PropTypes from 'prop-types';
import TransactAsButton from 'components/modules/service/PayerDetail/TransactAsButton';
import { Grid } from '@material-ui/core';
import { isOn } from 'util/index';
import { load } from 'model/recipientAudit';
import { P2P_DIRECT_OFF_ON, RECIPIENT_STATUS_ACTIVE } from 'consts';
import { selectorCount, selectorGroupedByDays } from 'selectors/recipientAudit';
import { selectorId as selectorIdDirect } from 'selectors/recipientDirect';
import { selectorPayerIdAndId } from 'selectors/recipient';
import { transactOnBehalfP2POnSelector } from 'selectors/userFspConfig';
import { useSelector } from 'react-redux';
import Audit from 'components/common/Audit';
import AuditDetailRowRecord from 'components/common/Audit/config/shared/AuditDetailRowRecord';
import AuditDrawerAppBarEndAdornment from 'components/common/Audit/config/shared/AuditDrawerAppBarEndAdornment';
import AuditDrawerAppBarTitle from '../../../Audit/config/shared/AuditDrawerAppBarTitle';

const ActionsRenderer = ({ item }) => {
  const transactP2POn = useSelector((state) =>
    transactOnBehalfP2POnSelector(state, item?.fspId, item?.payerId)
  );

  const showTransactOnBehalf =
    item?.recipientStatus === RECIPIENT_STATUS_ACTIVE && transactP2POn;

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      {showTransactOnBehalf && !isOn(item[P2P_DIRECT_OFF_ON]) && (
        <Grid item>
          <TransactAsButton
            payerId={item.payerId}
            recipientId={item.id}
            fspIdValue={item.fspId}
          />
        </Grid>
      )}
      <Grid item>
        <Audit
          action={load}
          countSelector={selectorCount}
          DrawerEndAdornment={AuditDrawerAppBarEndAdornment}
          item={item}
          Row={AuditDetailRowRecord}
          selectorGroupedByDays={selectorGroupedByDays}
          selectorId={
            isOn(item?.p2pDirectOffOn) ? selectorIdDirect : selectorPayerIdAndId
          }
          Title={AuditDrawerAppBarTitle}
        />
      </Grid>
    </Grid>
  );
};

ActionsRenderer.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ActionsRenderer;
