import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createImportConst,
  createLoadConst,
  createUpdateConst,
} from '../util';

export default class SSOUri extends Model {
  static get modelName() {
    return 'SSOUri';
  }

  static reducer({ type, data }, SSOUri) {
    const id = data?.props?.id;
    switch (type) {
      case ADD.SUCCESS:
        SSOUri.create(data?.response);
        break;

      case LOAD.SUCCESS:
      case IMPORT.SUCCESS:
        if (data?.response) {
          SSOUri.delete();
          data.response.forEach((r) => SSOUri.create(r));
        }
        break;

      case REMOVE.SUCCESS:
        if (SSOUri.idExists(id)) {
          SSOUri.withId(id).delete();
        }
        break;

      case UPDATE.SUCCESS:
        SSOUri.upsert(data.response);
        break;

      default:
        break;
    }
  }

  static get fields() {
    return {
      createdAt: attr(),
      defaultUriOffOn: attr(),
      fspId: attr(),
      href: attr(),
      id: attr(),
      insUserId: attr(),
      returnTypeOffOn: attr(),
      type: attr(),
      updatedAt: attr(),
      uri: attr(),
      updUserId: attr(),
    };
  }
}

const name = SSOUri.modelName;

export const ADD = createAddConst(name);
export const IMPORT = createImportConst(name);
export const LOAD = createLoadConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const importAction = createAction(IMPORT);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
