import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FULL_ACCESS_PAYMENT_RESEARCH } from 'components/common/permissions';
import { KeyboardArrowDown } from '@material-ui/icons';
import { UPDATE, update } from 'model/paymentResearch';
import {
  compose,
  lifecycle,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { connect } from 'react-redux';
import { derivedSelectorId } from 'selectors/paymentResearch';
import { getAuth } from 'util/index';
import { selector as isBusySelector } from 'selectors/busy';
import { isUpdatingState } from 'reducers/updating';
import { hasPermission } from 'util/rbac';
import { head } from 'ramda';
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import StatusChangeModal from './StatusChangeModal';

const CONTAINED = 'contained';
const DEFAULT = 'default';
const OUTLINED = 'outlined';
const PRIMARY = 'primary';

export const CLOSED = 'closed';
export const NEW = 'new';
export const OPEN = 'open';
export const SOLVED = 'solved';

const ALL_STATUS = [NEW, OPEN, SOLVED];
const IN_PROGRESS_STATUS = [OPEN, SOLVED];

const styles = (theme) => ({
  [NEW]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#1976d2',
    '&:hover': {
      backgroundColor: '#004ba0',
    },
    '&:disabled': {
      color: theme.palette.primary.contrastText,
      boxShadow: head(theme.shadows),
      backgroundColor: '#1976d2',
    },
  },
  [OPEN]: {
    color: theme.palette.common.black,
    backgroundColor: '#fdd835',
    '&:hover': {
      backgroundColor: '#c6a700',
    },
    '&:disabled': {
      color: theme.palette.common.black,
      boxShadow: head(theme.shadows),
      backgroundColor: '#fdd835',
    },
  },
  [SOLVED]: {
    color: theme.palette.primary.contrastText,
    backgroundColor: '#2E7D32',
    '&:hover': {
      backgroundColor: '#005005',
    },
    '&:disabled': {
      color: theme.palette.primary.contrastText,
      boxShadow: head(theme.shadows),
      backgroundColor: '#2E7D32',
    },
  },
  button: {
    minWidth: 105,
  },
  buttonRo: {
    minWidth: 81,
  },
  display: {
    display: 'inline',
  },
  empty: {
    height: 32,
  },
  grid: {
    cursor: 'default',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
  none: {
    display: 'none',
  },
  statusText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const Status = ({
  anchorEl,
  buttonClasses,
  circularProgressClasses,
  classes,
  color,
  handleClose,
  handleMenuClick,
  handleOpen,
  hideDownArrow,
  hideProgress,
  item,
  noPerms,
  open,
  stateDisabled,
  statusList,
  variant,
  showModal,
  setShowModal,
  handleModalSubmit,
}) => {
  return (
    <>
      <Button
        classes={buttonClasses}
        className={classNames(classes.button, {
          [classes.buttonRo]: noPerms,
        })}
        color={color}
        disabled={stateDisabled}
        onClick={handleOpen}
        size="small"
        variant={variant}
      >
        <span className={classes.statusText}>{item.status}</span>
        <KeyboardArrowDown
          className={classNames({
            [classes.none]: hideDownArrow,
          })}
        />
        <CircularProgress
          classes={circularProgressClasses}
          className={classNames({
            [classes.none]: hideProgress,
          })}
          size={24}
          thickness={4}
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        disabled={stateDisabled}
        onClose={handleClose}
        open={open}
      >
        {statusList.map((statusEntry) => (
          <MenuItem
            key={statusEntry}
            className={classes.menuItem}
            data-value={statusEntry}
            onClick={handleMenuClick}
            selected={item.status === statusEntry}
          >
            {statusEntry}
          </MenuItem>
        ))}
      </Menu>
      <StatusChangeModal
        item={item}
        onSubmit={handleModalSubmit}
        open={showModal}
        setOpen={setShowModal}
      />
    </>
  );
};

Status.propTypes = {
  anchorEl: PropTypes.object,
  buttonClasses: PropTypes.object,
  circularProgressClasses: PropTypes.object,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  hideDownArrow: PropTypes.bool.isRequired,
  hideProgress: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  noPerms: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stateDisabled: PropTypes.bool.isRequired,
  statusList: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
};

Status.defaultProps = {
  anchorEl: null,
  buttonClasses: null,
  circularProgressClasses: null,
  noPerms: false,
};

export default compose(
  withStyles(styles),
  setDisplayName('Status'),
  connect(
    (state, props) => ({
      auth: getAuth(state),
      isBusy: isBusySelector(state, UPDATE),
      isUpdating: isUpdatingState(state, UPDATE.ACTION),
      item: derivedSelectorId(state, props),
    }),
    { update }
  ),
  withState('anchorEl', 'setAnchorEl', null),
  withState('locallyActive', 'setLocallyActive', false),
  withState('showModal', 'setShowModal', false),
  withProps(
    ({
      anchorEl,
      auth,
      classes,
      disabled,
      isBusy,
      item,
      isUpdating,
      locallyActive,
    }) => ({
      buttonClasses:
        locallyActive && isUpdating
          ? null
          : { root: classes.empty, containedPrimary: classes[item.status] },
      circularProgressClasses:
        locallyActive && isUpdating
          ? null
          : { colorPrimary: classes[item.status] },
      color: item.isEmpty ? DEFAULT : PRIMARY,
      hideProgress: !isBusy || !locallyActive,
      noPerms:
        !hasPermission(auth, FULL_ACCESS_PAYMENT_RESEARCH) ||
        !item.status ||
        disabled,
      open: Boolean(anchorEl),
      statusList:
        item.assignedAdminUserId ||
        item.closedAdminUserId ||
        item.solvedAdminUserId
          ? IN_PROGRESS_STATUS
          : ALL_STATUS,
      variant: item.isEmpty ? OUTLINED : CONTAINED,
    })
  ),
  withProps(({ locallyActive, isBusy, isUpdating, item, noPerms }) => ({
    stateDisabled: noPerms || (locallyActive && isUpdating) || item.isEmpty,
    hideDownArrow: noPerms || (locallyActive && isBusy) || item.isEmpty,
  })),
  withHandlers({
    handleClose:
      ({ setAnchorEl }) =>
      () =>
        setAnchorEl(null),
  }),
  withHandlers({
    handleOpen:
      ({ setAnchorEl }) =>
      (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
      },
    handleMenuClick:
      ({
        handleClose,
        item: { fspId, id, status },
        setLocallyActive,
        update,
        setShowModal,
      }) =>
      (event) => {
        event.stopPropagation();
        const updatedStatus = event.target.dataset.value;
        const obj = { status: updatedStatus };

        if (updatedStatus !== status) {
          if (updatedStatus === 'solved') {
            setShowModal(true);
          } else {
            update({ fspId, id, obj });
            setLocallyActive(true);
          }
        }
        handleClose();
      },
    handleModalSubmit:
      ({ item: { fspId, id }, setLocallyActive, update }) =>
      () => {
        update({ fspId, id, obj: { status: 'solved' } });
        setLocallyActive(true);
      },
  }),
  lifecycle({
    componentDidUpdate(prev) {
      const { item } = this.props;

      if (prev.item.status !== item.status) {
        const { setLocallyActive } = this.props;

        setLocallyActive(false);
      }
    },
  }),
  pure
)(Status);
