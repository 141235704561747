import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { Field, useField } from 'formik';
import { Autocomplete as FormikAutocomplete } from 'formik-material-ui-lab';

const Autocomplete = ({ label, name, options, required, ...props }) => {
  const [field, { error, touched }, { setTouched, setValue }] = useField(name);

  const hasError = touched && !!error;

  const handleChange = (event, value) => {
    setValue(value);
    setTouched(true);
  };

  return (
    <Field
      {...field}
      {...props}
      component={FormikAutocomplete}
      error={error}
      label={label}
      onChange={handleChange}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError}
          helperText={hasError ? error : ''}
          label={label}
          required={required}
        />
      )}
      required={required}
    />
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
};

Autocomplete.defaultProps = {
  label: undefined,
  options: undefined,
  required: undefined,
};

export default Autocomplete;
