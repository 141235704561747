import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose, pure, withProps } from 'recompose';
import { connect } from 'react-redux';
import { selector } from 'selectors/busy';
import {
  withStyles,
  CircularProgress,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';

export const CANCEL_ID = 'cancelButton';

const styles = (theme) => ({
  circular: {
    position: 'absolute',
    right: theme.spacing(2),
  },
  hidden: {
    visibility: 'hidden',
  },
  visible: {
    visibility: 'visible',
  },
});

const InputAdornmentInlineEdit = ({
  adornmentClass,
  circularClass,
  enterDelay,
  isValid,
  reset,
  submit,
}) => {
  return (
    <InputAdornment position="end">
      <Tooltip
        className={adornmentClass}
        enterDelay={enterDelay}
        title="Accept changes"
      >
        <div>
          <IconButton color="primary" disabled={!isValid} onClick={submit}>
            <Done fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip
        className={adornmentClass}
        enterDelay={enterDelay}
        title="Discard changes"
      >
        <IconButton id={CANCEL_ID} onClick={reset}>
          <Close fontSize="small" />
        </IconButton>
      </Tooltip>
      <CircularProgress className={circularClass} size={20} />
    </InputAdornment>
  );
};

InputAdornmentInlineEdit.propTypes = {
  adornmentClass: PropTypes.string.isRequired,
  circularClass: PropTypes.string.isRequired,
  enterDelay: PropTypes.number,
  isValid: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
};

InputAdornmentInlineEdit.defaultProps = {
  enterDelay: 500,
};

export default compose(
  withStyles(styles),
  connect((state, { actionType }) => ({
    isBusy: actionType ? selector(state, actionType) : false,
  })),
  withProps(({ classes, hasFocus, isBusy, isSubmitting }) => ({
    adornmentClass: classNames(classes.hidden, {
      [classes.visible]: hasFocus,
    }),
    circularClass: classNames(classes.circular, classes.hidden, {
      [classes.visible]: isBusy && isSubmitting,
    }),
  })),
  pure
)(InputAdornmentInlineEdit);
