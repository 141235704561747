import PropTypes from 'prop-types';
import TableColumn from './tableColumn';

const TableColumns = ({ columns, row }) =>
  columns.map((column) => (
    <TableColumn key={`${row.id}${column.id}`} row={row} {...column} />
  ));

TableColumns.propTypes = {
  columns: PropTypes.array.isRequired,
  row: PropTypes.object.isRequired,
};

export default TableColumns;
