import MorePopover from 'components/common/MorePopover';
import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';
import { Grid, MenuItem, makeStyles } from '@material-ui/core';
import { NA_VALUE } from 'consts';

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  label: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    paddingTop: 2,
  },
}));

const ReadOnlyInputWithMenu = ({ capitalize, label, menuNode, tooltip }) => {
  const [tooltipProps] = useState({ title: tooltip });
  const classes = useStyles();
  return (
    <Grid alignItems="center" className={classes.root} container wrap="nowrap">
      <Grid
        className={classNames(classes.label, {
          [classes.capitalize]: capitalize,
        })}
        item
        zeroMinWidth
      >
        {label}
      </Grid>
      <Grid item>
        <MorePopover tooltipProps={tooltipProps}>{menuNode}</MorePopover>
      </Grid>
    </Grid>
  );
};

ReadOnlyInputWithMenu.propTypes = {
  capitalize: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  menuNode: PropTypes.node,
  tooltip: PropTypes.string,
};

ReadOnlyInputWithMenu.defaultProps = {
  capitalize: true,
  label: NA_VALUE,
  menuNode: <MenuItem>No menuNode</MenuItem>,
  tooltip: '',
};

export default ReadOnlyInputWithMenu;
