import PropTypes from 'prop-types';
import { Mask } from 'components/common';

const MaskFunding = ({ decrypt, icon, item }) => {
  const {
    fundingAccount,
    fundingAccountSuffix,
    fundingMask,
    hostRespCode, // schedTrans don't have this field
  } = item;

  const handleApi = () => {
    return decrypt(item);
  };

  return (
    <Mask
      api={handleApi}
      icon={icon}
      prefix={fundingAccount}
      suffix={hostRespCode === undefined ? fundingAccountSuffix : undefined}
      unmaskSuffix={fundingAccountSuffix}
      value={fundingMask}
    />
  );
};

MaskFunding.propTypes = {
  decrypt: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  item: PropTypes.object.isRequired,
};

MaskFunding.defaultProps = {
  icon: false,
};

export default MaskFunding;
