import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { offerStatusDateMap } from 'model/util/actionInsightReduceMyBill';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 400,
  },
  text: {
    fontSize: theme.typography.body1.fontSize,
  },
}));

const OfferStatusDates = ({ dates }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader title="Status Dates" />
      <CardContent>
        <Table>
          <TableBody>
            {(dates || []).map((date) => (
              <TableRow key={date[0]}>
                <TableCell className={classes.text}>
                  {offerStatusDateMap[date[0]]}
                </TableCell>
                <TableCell className={classes.text}>{date[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

OfferStatusDates.propTypes = {
  dates: PropTypes.array,
};

OfferStatusDates.defaultProps = {
  dates: null,
};

export default OfferStatusDates;
