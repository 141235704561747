import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ACCOUNT_TO_ACCOUNT_INBOUND } from 'consts';
import {
  ReadOnlyFormControl,
  ReadOnlyInputWithMenu,
} from 'components/common/fields';
import { Skeleton } from 'components/common';
import { creditPayOnSelector } from 'selectors/userFspConfig';
import { useSelector } from 'react-redux';
import { AUTOPAY, Autopay } from '../../../../Autopay';

const DetailsRenderCreditPay = ({ autopay, item, statusDerived }) => {
  const { autopayRuleAt, fspId, payerId } = item;
  const isCreditPayOn = useSelector((state) =>
    creditPayOnSelector(state, fspId, payerId)
  );

  return isCreditPayOn ? (
    <Grid item xs={2}>
      {autopayRuleAt ? (
        <ReadOnlyFormControl
          inputNode={
            statusDerived ? (
              <ReadOnlyInputWithMenu
                label={statusDerived}
                menuNode={
                  <Autopay
                    transactionType={ACCOUNT_TO_ACCOUNT_INBOUND}
                    {...autopay}
                  />
                }
              />
            ) : (
              <Skeleton />
            )
          }
          label={AUTOPAY}
          value={statusDerived || <Skeleton />}
        />
      ) : (
        <ReadOnlyFormControl label={AUTOPAY} value="Not Enabled" />
      )}
    </Grid>
  ) : null;
};

DetailsRenderCreditPay.propTypes = {
  autopay: PropTypes.object,
  item: PropTypes.object.isRequired,
  statusDerived: PropTypes.string,
};

DetailsRenderCreditPay.defaultProps = {
  autopay: null,
  statusDerived: null,
};

export default DetailsRenderCreditPay;
