import {
  combinerArray,
  getSecondArgument,
  DEFAULT_MODEL_REF_ARRAY,
  IS_EMPTY,
} from 'consts';
import { createSelector as createReselectSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'redux-orm';
import { orm } from 'model';
import { isOn } from '../util';

const empty = { [IS_EMPTY]: true, uspsAddress: {} };
const byBillerId = ({ Payee }, billerId) => {
  return Payee.filter((payee) => payee.billerId === billerId).toRefArray();
};
const getBillerId = (firstArg, { billerId }) => billerId;
const isCheck = ({ deliverCheckExpedite, deliverCheckStd }) => {
  return isOn(deliverCheckExpedite) || isOn(deliverCheckStd);
};
const isElec = ({ deliverElecNow, deliverElecStd, deliverElecToday }) => {
  return isOn(deliverElecNow) || isOn(deliverElecStd) || isOn(deliverElecToday);
};
const selected = ({ selected }) => selected;

export const DATA_KEY = 'payees';

export const selector = createSelector(
  orm,
  (state, id) => id,
  ({ Payer }, id) =>
    Payer.idExists(id)
      ? Payer.withId(id).payees.toRefArray()
      : DEFAULT_MODEL_REF_ARRAY
);

export const selectorCountByPayerId = createReselectSelector(
  selector,
  (items) => items.length
);

export const selectorNoPayerId = createSelector(
  orm,
  ({ Payee }) => Payee.all().toRefArray() ?? DEFAULT_MODEL_REF_ARRAY
);

export const selectorIdWithLocation = createSelector(
  orm,
  (state, props) => {
    if (props.location) {
      const searchParams = new URLSearchParams(props.location.search);
      const id = searchParams.get('id');

      return { id, ...props };
    }
    return props;
  },
  ({ Payee }, { id }) => Payee.withId(id)?.ref ?? empty
);

export const selectorId = createSelector(
  orm,
  getSecondArgument,
  ({ Payee }, id) => Payee.withId(id)?.ref ?? empty
);

export const selectorNoPayerIdCount = createReselectSelector(
  selectorNoPayerId,
  (items) => items.length
);

const baseSelector = createSelector(orm, getSecondArgument, byBillerId);
const baseSelectorObj = createSelector(orm, getBillerId, byBillerId);

export const selectorBillerIdObj = createCachedSelector(
  baseSelectorObj,
  combinerArray
)(getBillerId);

export const selectorBillerIdCount = createCachedSelector(
  selectorBillerIdObj,
  (items) => items.length
)(getBillerId);

const selectorBillerId = createCachedSelector(
  baseSelector,
  combinerArray
)(getSecondArgument);

export const selectorByBillerIdSelected = createCachedSelector(
  selectorBillerId,
  (items) => items.filter(selected)
)(getSecondArgument);

export const selectorByBillerIdSelectedCount = createCachedSelector(
  selectorByBillerIdSelected,
  (items) => items.length
)(getSecondArgument);

export const selectorByBillerIdBothElecAndCheck = createCachedSelector(
  selectorBillerId,
  (items) => items.filter((item) => isCheck(item) && isElec(item))
)(getSecondArgument);

export const selectorNoPayerIdAndNoiPayNetBillers = createSelector(
  orm,
  ({ Payee }) => Payee.all().toRefArray() ?? DEFAULT_MODEL_REF_ARRAY
);

export const selectorNoPayerIdCountAndNoiPayNetBillers = createReselectSelector(
  selectorNoPayerIdAndNoiPayNetBillers,
  (items) => items.length
);
