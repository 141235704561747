import { attr, Model } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createDeleteConst,
  createRequestConst,
  createUpdateConst,
} from '../util';

export default class RecentFSP extends Model {
  static get modelName() {
    return 'RecentFSP';
  }

  static reducer({ data, type }, RecentFSP) {
    switch (type) {
      case ADD.SUCCESS:
        RecentFSP.filter(({ fspId }) => fspId === data.fspId).delete();
        RecentFSP.create(data);
        break;

      case CLEAR.SUCCESS:
      case CLEAR_LOCATION.SUCCESS:
        RecentFSP.delete();
        break;

      case LOAD.SUCCESS:
        RecentFSP.delete();
        data.forEach((r) => RecentFSP.upsert(r));
        break;

      case REMOVE.SUCCESS:
        RecentFSP.withId(data.id).delete();
        break;

      case UPDATE.SUCCESS:
        RecentFSP.upsert(data);
        break;

      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      fspId: attr(),
      location: attr(),
      primary: attr(),
      secondary: attr(),
      tertiary: attr(),
    };
  }
}

const name = RecentFSP.modelName;

export const ADD = createAddConst(name);
export const CLEAR = createDeleteConst(`${name}_clear`);
export const CLEAR_LOCATION = createDeleteConst(`${name}_clearLocation`);
export const LOAD = createRequestConst(name);
export const REMOVE = createDeleteConst(name);
export const UPDATE = createUpdateConst(name);

export const add = createAction(ADD);
export const clear = createAction(CLEAR);
export const load = createAction(LOAD);
export const remove = createAction(REMOVE);
export const update = createAction(UPDATE);
