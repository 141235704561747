import { Model, attr, fk } from 'redux-orm';
import {
  createAction,
  createAddConst,
  createRequestConst,
  createUpdateConst,
} from '../util';
import { addDerived } from './util/fspConfigPayALoan';

export default class FspConfigPayALoan extends Model {
  static get modelName() {
    return 'FspConfigPayALoan';
  }

  static reducer({ type, data }, FspConfigPayALoan) {
    switch (type) {
      case LOAD.SUCCESS:
        FspConfigPayALoan.delete();
        FspConfigPayALoan.create(addDerived(data?.response));
        break;

      case CREATE.SUCCESS:
        FspConfigPayALoan.create(addDerived(data?.response));
        break;

      case UPDATE.SUCCESS:
        FspConfigPayALoan.upsert(addDerived(data?.response));
        break;

      case LOAD.FAILURE:
        FspConfigPayALoan.delete();
        break;
      default:
        break;
    }
  }

  /**
   * Declaring all data fields is recommended as the library doesn't have to
   * redefine getters and setters when instantiating Models
   * */
  static get fields() {
    return {
      id: attr(),
      href: attr(),
      fspId: attr(),
      minAuthQuestion: attr(),
      maxAuthQuestion: attr(),
      feeBundleOffOn: attr(),
      debitCardOffOn: attr(),
      creditCardOffOn: attr(),
      ssoUserOffOn: attr(),
      enrolledUserOffOn: attr(),
      guestUserOffOn: attr(),
      statementImportFrom: attr(),
      partialImportOffOn: attr(),
      paymentPurposeOffOn: attr(),
      paymentPurposeCoreOffOn: attr(),
      holdAccountRouting: attr(),
      holdAccountNumber: attr(),
      holdAccountNumberSuffix: attr(),
      holdAccountType: attr(),
      externalDdaOffOn: attr(),
      feeHoldingAccountRouting: attr(),
      feeHoldingAccountNumber: attr(),
      feeHoldingAccountSuffix: attr(),
      feeHoldingAccountType: attr(),
      feeTransactOnBehalf: attr(),
      feeTransactOnBehalfDerived: attr(),
      format: attr(),
      createdAt: attr(),
      insAdminUserId: attr(),
      recurringPaymentFeeOffOn: attr(),
      singlePaymentFeeOffOn: attr(),
      updatedAt: attr(),
      updatedAtDerived: attr(),
      updUserId: attr(),
      updAdminUserId: attr(),
      fsp: attr(),
    };
  }
}

const name = FspConfigPayALoan.modelName;

export const CREATE = createAddConst(name);
export const LOAD = createRequestConst(name);
export const UPDATE = createUpdateConst(name);

export const create = createAction(CREATE);
export const load = createAction(LOAD);
export const update = createAction(UPDATE);
